import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import type {Activity, Applicant} from '~/src/models/applicant'
import {useAppSelector} from '~/src/store/store-hooks'
import MspCandidateActivityMain from '../candidate-details/candidate-activity/MspCandidateActivityMain'
import {DashboardMainStyles} from '../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

const JobOrderSummaryActivity = ({
  handleSubmit,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
}: IProps) => {
  const {id} = useParams()
  const {currentUser} = useAppSelector((state) => state.root)

  const createActivityItem = () =>
    oldApplicant &&
    currentUser && {
      activityType: 'General Notes',
      date: new Date(),
      jobOrderId: id,
      modifiedBy: currentUser.emailAddress,
      notes: '',
      applicantIds: [oldApplicant.id],
      entityId: currentUser.id,
      entityType: 'User',
      systemActivity: false,
    }

  const activityValues = oldApplicant
    ? oldApplicant.activities.map((i: Activity) => {
        return {
          applicantIds: i.applicantIds,
          notes: i.notes,
          date: i.date,
          modifiedBy: i.modifiedBy,
          entityId: i.entityId,
          entityType: i.entityType,
          activityType: i.activityType,
          systemActivity: i.systemActivity,
        }
      })
    : []

  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,
    // activity info
    activities: activityValues,
    newActivity: createActivityItem(),
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          newActivity: Yup.object({
            applicantIds: Yup.array().of(Yup.string()),
            notes: Yup.string().required('Required').nullable(),
            date: Yup.date(),
            modifiedBy: Yup.string(),
            entityId: Yup.string(),
            entityType: Yup.string(),
            activityType: Yup.string(),
          }),

          activities: Yup.array().of(
            Yup.object({
              applicantIds: Yup.array().of(Yup.string()),
              notes: Yup.string().required('Required'),
              date: Yup.date(),
              modifiedBy: Yup.string(),
              entityId: Yup.string(),
              entityType: Yup.string(),
              activityType: Yup.string(),
            }),
          ),
        })}>
        {({isSubmitting, setFieldValue, values, resetForm, errors}) => {
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <DashboardMainStyles>
                  <JobOrderCandidateNameHeader
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    oldApplicant={oldApplicant}
                    singleApplicant={values}
                  />

                  <MspCandidateActivityMain
                    errors={errors}
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </DashboardMainStyles>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}

export default React.memo(JobOrderSummaryActivity)
