import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import {styled} from '@mui/material/styles'
import {FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import React from 'react'
import type {IAgencyBase} from '~/src/models'
import type { IBaseAddress } from '~/src/models/baseAddress'

const CloseButton = styled(IconButton)({
  display: 'none',
  height: 28,
})

const Container = styled(Grid)(({theme}) => ({
  position: 'relative',

  '& + &': {
    marginTop: theme.spacing(4),
  },

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
}))

const Field = styled(Grid)({
  position: 'relative',
})

interface IProps {
  activePlatform: string
  remove?: (index: number) => void
  setFieldValue: (field: string, value: unknown) => void
  address: IBaseAddress | string | number | boolean | null | undefined
  initialEditValues?: IAgencyBase
  name: string
}

export default function AddressAutoComplete(props: IProps) {
  const {setFieldValue, address} = props

  return (
    <Container item container xs={12} columnSpacing={2} rowSpacing={4}>
      <Field item xs={12}>
        <FormikGooglePlacesAutocomplete
          address={address}
          name={props.name}
          initialEditValues={props.initialEditValues}
          setFieldValue={setFieldValue}
        />
      </Field>
    </Container>
  )
}
