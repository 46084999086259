import {Alert, Box, Button, Snackbar} from '@mui/material'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React, {useCallback, useState} from 'react'
import * as Yup from 'yup'
import type {Applicant} from '~/src/models/applicant'
import {useUpdateApplicantMutation} from '~/src/store/apis/applicants-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {selectApplicantsState} from '~/src/store/slices/applicants-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import MspCandidateAvailabilityEdit from '../manage-job-orders/job-order-summary/candidate-details/candidate-profile/CandidateProfileDisplayandEdit/MspCandidateAvailabilityEdit'
import MspCandidateProfileContactEdit from '../manage-job-orders/job-order-summary/candidate-details/candidate-profile/CandidateProfileDisplayandEdit/MspCandidateDetailsProfileContactEdit'

interface DataCleanupFormProps {
  oldApplicant: Applicant
  onConfirmContact: () => void
  onConfirmAvailability: () => void
  onConfirmSkills: () => void
}

const DataCleanupForm = ({
  oldApplicant,
  onConfirmContact,
  onConfirmAvailability,
  onConfirmSkills,
}: DataCleanupFormProps) => {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updateApplicant] = useUpdateApplicantMutation()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const {currentUser} = useCurrentUser()
  const {applicant} = useAppSelector(selectApplicantsState)

  const initialFormValues: Applicant = oldApplicant && {
    ...oldApplicant,
  }

  const handleConfirmContact = async (values) => {
    await updateApplicant({applicant: values})
    onConfirmContact()
    setSnackbarOpen(true)
  }

  const handleConfirmAvailability = async (values) => {
    await updateApplicant({applicant: values})
    onConfirmAvailability()
    setSnackbarOpen(true)
  }

  const handleConfirmSkills = async () => {
    if (applicant.skills.length === 0) {
      setIsOpen(true)
    } else {
      onConfirmSkills()
      setSnackbarOpen(true)
    }
  }

  const handleCancel = () => {
    /*no op*/
  }

  const handleClick = () => {
    /*no op*/
  }

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarOpen(false)
  }, [])

  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return (
    <Formik
      onSubmit={() => undefined}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={Yup.object({
        id: Yup.string(),
        branch: Yup.string(),
        employeeID: Yup.string(),
        ssn: Yup.string(),
        employeeStatus: Yup.string(),
        dateofBirth: Yup.string(),
        gender: Yup.string(),
        userStaffingRep: Yup.string(),
        agencyId: Yup.string(),
        email: Yup.string().email('Email address is invalid.').required('Required'),
        modifiedDate: Yup.string(),
      })}>
      {({setFieldValue, values}) => {
        return (
          <LoadingBoundary isLoading={false}>
            <FormikForm>
              <>
                <BasicSuccessDialog
                  isOpen={isOpen}
                  isIconVisible={false}
                  header="Skills Needed"
                  bigBlurb="Please Add Skills"
                  confirmButtonLabel="OK"
                  handleSubmit={() => {
                    setIsOpen(false)
                  }}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                  <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                    {`Confirmed by ${currentUser?.emailAddress}, ${formattedDate}`}
                  </Alert>
                </Snackbar>

                <Box sx={{marginBottom: '100px'}}>
                  <MspCandidateProfileContactEdit
                    handleCancel={handleCancel}
                    handleClick={handleClick}
                    oldApplicant={oldApplicant}
                    setFieldValue={setFieldValue}
                    values={values}
                    isDataCleanup={true}
                  />
                  <Button
                    style={{marginTop: 10}}
                    color="primary"
                    variant="contained"
                    onClick={() => handleConfirmContact(values)}>
                    Confirm
                  </Button>
                  <MspCandidateAvailabilityEdit
                    allValues={values}
                    handleCancel={handleCancel}
                    handleClick={handleClick}
                    handleSubmit={handleClick}
                    setFieldValue={setFieldValue}
                    values={values?.availability}
                    isDataCleanup={true}
                  />
                  <Button
                    style={{marginTop: 10}}
                    color="primary"
                    variant="contained"
                    onClick={() => handleConfirmAvailability(values)}>
                    Confirm
                  </Button>
                  <div
                    style={{
                      marginTop: '50px',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}>
                    The Skills for this candidate have been properly reviewed and updated
                  </div>
                  <Button
                    style={{marginTop: 10}}
                    color="primary"
                    variant="contained"
                    onClick={handleConfirmSkills}>
                    Confirm
                  </Button>
                </Box>
              </>
            </FormikForm>
          </LoadingBoundary>
        )
      }}
    </Formik>
  )
}

export default DataCleanupForm
