import {createApi} from '@reduxjs/toolkit/query/react'
import type {TAgenciesPayload, TAgencyNameAndId} from '~/src/models'
import type {EntityAccessList, MemberAccess} from '~/src/models/memberAccess'
import {baseQuery} from '../base-query'

export const memberAccessApi = createApi({
  reducerPath: 'MemberAccessApi',
  tagTypes: ['MemberAccess', 'Agency'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getMemberAccessByMemberId: builder.query<MemberAccess, string>({
      providesTags: ['MemberAccess'],
      query: (memberId) => ({
        url: `/memberAccess/getMemberAccessByMemberId/${memberId}`,
        method: 'GET',
      }),
    }),

    getGaAgencyNamesAndIds: builder.query<TAgencyNameAndId[], string>({
      providesTags: ['MemberAccess', 'Agency'],
      // The type error for queryFn looks complicated and possibly RTK's problem
      async queryFn(memberId, _queryApi, _extraOptions, fetchWithBQ) {
        const rawMemberAccessData = await fetchWithBQ({
          url: `/memberAccess/getMemberAccessByMemberId/${memberId}`,
          method: 'GET',
        })
        const memberAccessData = rawMemberAccessData.data as MemberAccess
        if (
          memberAccessData.entityAccessList.length === 1 &&
          memberAccessData.entityAccessList[0]?.allAccess
        ) {
          const rawAgencyData = await fetchWithBQ({
            url: `/agency/getAgencies`,
            method: 'GET',
            params: {pageNumber: 1, pageSize: 99999999},
          })
          const agencyData = rawAgencyData.data as TAgenciesPayload
          const nameAndIdData: TAgencyNameAndId[] = agencyData.agencies
            .map((agency) => {
              return {id: agency.id, name: agency.name}
            })
            .sort((a, b) => a.name.localeCompare(b.name))
          return {data: nameAndIdData}
        } else {
          const rawAgencyNamesByIdsData = await fetchWithBQ({
            url: `/agency/getAgencyNamesByIds`,
            method: 'POST',
            body: memberAccessData.entityAccessList.map((datum) => datum.entityId),
          })
          const agencyNamesByIdsData = rawAgencyNamesByIdsData.data as TAgencyNameAndId[]
          return {data: agencyNamesByIdsData.sort((a, b) => a.name.localeCompare(b.name))}
        }
      },
    }),

    getAllMemberAccessesByRole: builder.query<MemberAccess, string>({
      providesTags: ['MemberAccess'],
      query: (role) => ({
        url: `/memberAccess/getAllMemberAccessesByRole/${role}`,
        method: 'GET',
      }),
    }),

    createMemberAccess: builder.mutation<MemberAccess, EntityAccessList>({
      invalidatesTags: ['MemberAccess'],
      query: (body) => ({
        url: `/memberAccess/createMemberAccess`,
        method: 'POST',
        body: body,
      }),
    }),

    updateMemberAccess: builder.mutation<MemberAccess, EntityAccessList>({
      invalidatesTags: ['MemberAccess'],
      query: (entityAccessList) => ({
        url: `/memberAccess/updateMemberAccess`,
        method: 'PUT',
        body: entityAccessList,
      }),
    }),
  }),
})

export const {
  useLazyGetMemberAccessByMemberIdQuery,
  useLazyGetGaAgencyNamesAndIdsQuery,
  useGetAllMemberAccessesByRoleQuery,
  useCreateMemberAccessMutation,
  useUpdateMemberAccessMutation,
} = memberAccessApi
