import JobOrderCard from '@ui/card/JobOrderCard'
import useWindowDimensions from '@ui/custom-hook-height/CustomHeightHook'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {ReactElement, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import type {IJobOrder} from '~/src/models/jobOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetJobOrdersQuery} from '~/src/store/apis/job-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const ContentContainer = styled('div')({
  maxWidth: '100%',
  width: '100%',
})

const JobOrderContainer = styled('div')({
  display: 'flex',
  maxWidth: '100%',
  width: '100%',
})

const JobOrdersLabel = styled('div')({
  fontWeight: 600,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '24px',
})

const FlexSpaceBetweenContainer = styled('div')`
  display: flex;
  max-width: 1109px;
  width: 1109px;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (min-width: 2000px) {
    width: 1385px;
    max-width: 1385px;
  }
`

interface IProps {
  isMsp?: boolean
}

export default function JobOrderDashboard({isMsp}: IProps): ReactElement {
  const dispatch = useDispatch()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const widthDimensions = useWindowDimensions()

  const {data, refetch, error, isLoading, isFetching} = useGetJobOrdersQuery({
    pageNumber: 1,
    pageSize: widthDimensions.width > 1999 ? 5 : 4,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })

  const handleListIconDirect = (showDetails: boolean, jobOrderId: string) => {
    if (isMsp) {
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${
            SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
          }/${encodeURIComponent(jobOrderId)}?showDetails=${showDetails}`,
        ),
      )
    } else {
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY}/${encodeURIComponent(
            jobOrderId,
          )}?showDetails=${showDetails}`,
        ),
      )
    }
  }

  const seeAllDirect = () => {
    if (isMsp) {
      return (
        <div>
          <NavLink
            style={{
              fontSize: '15px',
              verticalAlign: '-webkit-baseline-middle',
              color: 'grey',
            }}
            data-testid="to-job-orders"
            to={{
              pathname: `${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.SERVICE_ORDERS}`,
            }}>
            See All
          </NavLink>
        </div>
      )
    } else {
      return (
        <div>
          <NavLink
            style={{
              fontSize: '15px',
              verticalAlign: '-webkit-baseline-middle',
              color: 'grey',
            }}
            data-testid="to-job-orders"
            to={{
              pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.JOB_ORDERS}`,
            }}>
            See All
          </NavLink>
        </div>
      )
    }
  }

  useEffect(() => {
    void refetch()
  }, [refetch])

  return (
    <>
      <LoadingBoundary isLoading={isLoading}>
        <ContentContainer>
          <FlexSpaceBetweenContainer>
            <JobOrdersLabel>Job Orders</JobOrdersLabel>
            {seeAllDirect()}
          </FlexSpaceBetweenContainer>
          <JobOrderContainer>
            {data?.jobOrders.map((jobOrder: IJobOrder, index: number) => {
              const totalCandidatesNeeded = jobOrder.agencyDistributions
                .map(
                  (distribution, indexOne: number) =>
                    distribution.agencyCandidateDistributions.requested,
                )
                .reduce((prev: number, curr: number) => prev + curr, 0)

              const totalCandidatesFilled = jobOrder.agencyDistributions
                .map(
                  (distribution, indexOne: number) =>
                    distribution.agencyCandidateDistributions.fulfilled,
                )
                .reduce((prev: number, curr: number) => prev + curr, 0)

              return (
                <JobOrderCard
                  key={index}
                  currentlyfilled={totalCandidatesFilled}
                  jobOrder={jobOrder}
                  onListIconClick={(showDetails: boolean) =>
                    handleListIconDirect(showDetails, jobOrder.id)
                  }
                  totalrequested={totalCandidatesNeeded}
                />
              )
            })}
          </JobOrderContainer>
        </ContentContainer>
      </LoadingBoundary>
    </>
  )
}
