import {ConnectedRouter} from 'connected-react-router'
import registerStore from 'cypress-redux/registerStore'
import React from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import ReactDom from 'react-dom'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import App from './App'
import UiThemeProvider from './providers/theme'
import {history, persistor, store} from './store'

ReactDom.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <UiThemeProvider>
          <DndProvider backend={HTML5Backend}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </DndProvider>
        </UiThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

registerStore(store)
