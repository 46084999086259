export const Clerical_Administrative = 'Clerical/Administrative'
export const General_Warehouse = 'General Warehouse'
export const Manufacturing_Industrial = 'Manufacturing/Industrial'
export const Resturant_Hospitality = 'Resturant/Hospitality'
export const Drivers_Commercial = 'Drivers/Commercial'
export const Language = 'Language'

export interface ISkillOption {
  label: string
  value: string
  category: string
}

export const Clerical_Administrative_Options = [
  'Accounting',
  'Accounts Payables',
  'Accounts Receivables',
  'Bookkeeping, General Ledger, Reconcilation',
  'Quickbooks',
  'SAP',
  'Customer Service',
  'Sales Telemarketing, Cold Calls',
  'Canvasing',
  'Sales - Lead & Business Development',
  'Receptionist, Inbound/Outbound/Dialer Systems',
  'Cashier',
  'Microsoft Office Suite',
  'Type 35+ WPM',
]

export function getClericalSkillOptions(): ISkillOption[] {
  return Clerical_Administrative_Options.map((skillValue) => {
    let labelValue = skillValue
    if (['Cashier'].includes(skillValue)) {
      labelValue = `${labelValue} - ${Clerical_Administrative}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: Clerical_Administrative,
    }
  })
}

export const General_Warehouse_Options = [
  'Shipping Clerk',
  'Receiving Clerk',
  'Pallet Jack',
  'Document Handling',
  'Hazardous Material',
  'Loading & Unloading',
  'Forklift: Sit Down, Stand Up, Cherry Picker, Clamps, Scissors',
  'Packer/Picking/Sorting',
  'Labeling',
  'Conveyer Belt/Production Line',
  'Janitorial, Cleaning, Sanitation',
  'Quality Control',
  'Online Shipping (UPS, USPS, FedEx)',
  'Bill of Lading',
  'RF Scanner',
]

export function getGeneralSkillOptions(): ISkillOption[] {
  return General_Warehouse_Options.map((skillValue) => {
    let labelValue = skillValue
    if (
      [
        'Pallet Jack',
        'Document Handling',
        'Hazardous Material',
        'Loading & Unloading',
        'Conveyer Belt/Production Line',
        'Janitorial, Cleaning, Sanitation',
        'Quality Control',
      ].includes(skillValue)
    ) {
      labelValue = `${labelValue} - ${General_Warehouse}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: General_Warehouse,
    }
  })
}

export const Manufacturing_Industrial_Options = [
  'Machine Operator',
  'Pallet Jack',
  'Document Handling',
  'Hazardous Material',
  'Loading & Unloading',
  'Quality Control',
  'Food Production',
  'Conveyer Belt/Production Line',
  'Butcher',
  'Janitorial, Cleaning, Sanitation',
  'Maintenance Mechanic',
  'Injection Molding',
  'Garment Manufacturing (Cover Stitch, Single Needle, Double Sitch, Overlock, Trimmer, Cutter, Ironing, Caballo, Pocket Stitch)',
  'Welder - ARC, MIG, TIG',
]

export function getManufacturingSkillOptions(): ISkillOption[] {
  return Manufacturing_Industrial_Options.map((skillValue) => {
    let labelValue = skillValue
    if (
      [
        'Pallet Jack',
        'Document Handling',
        'Hazardous Material',
        'Loading & Unloading',
        'Quality Control',
        'Conveyer Belt/Production Line',
        'Janitorial, Cleaning, Sanitation',
      ].includes(skillValue)
    ) {
      labelValue = `${labelValue} - ${Manufacturing_Industrial}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: Manufacturing_Industrial,
    }
  })
}

export const Resturant_Hospitality_Options = [
  'Waiter',
  'Cooking',
  'Bartending',
  'Busboy',
  'Dishwashing',
  'Waitress',
  'Cashier',
  'Hospitality',
  'Housekeeping',
]

export function getResturantSkillOptions(): ISkillOption[] {
  return Resturant_Hospitality_Options.map((skillValue) => {
    let labelValue = skillValue
    if (['Cashier'].includes(skillValue)) {
      labelValue = `${labelValue} - ${Resturant_Hospitality}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: Resturant_Hospitality,
    }
  })
}

export const Drivers_Commercial_Options = ['Class A', 'Class B', 'CDL / Class C']

export function getDriversCommercialSkillOptions(): ISkillOption[] {
  return Drivers_Commercial_Options.map((skillValue) => {
    let labelValue = skillValue
    if ([''].includes(skillValue)) {
      labelValue = `${labelValue} - ${Drivers_Commercial}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: Drivers_Commercial,
    }
  })
}

export const Language_Options = ['English Only', 'Spanish Only', 'Bilingual']

export function getLanguageSkillOptions(): ISkillOption[] {
  return Language_Options.map((skillValue) => {
    let labelValue = skillValue
    if ([''].includes(skillValue)) {
      labelValue = `${labelValue} - ${Language}`
    }

    return {
      label: labelValue,
      value: skillValue,
      category: Language,
    }
  })
}
