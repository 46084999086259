import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import {format} from 'date-fns'
import React from 'react'

const MonthText = styled(Box)(
  (props: {
    $isSelected?: boolean
    $colorUnselected: string
    $colorSelected: string
    $isdashboard?: boolean
  }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    border-bottom: ${props.$isSelected
      ? `2px solid ${props.$colorSelected}`
      : `2px solid ${props.$colorUnselected}`};
    color: ${props.$isSelected ? props.$colorSelected : props.$colorUnselected};
    font: ${props.$isdashboard ? 'normal 600 10px Open Sans' : 'normal 600 14px Open Sans'};
    width: ${props.$isdashboard ? '100%' : 'inherit'};
  `,
)

const DayText = styled('div')(
  (props: {$isSelected?: boolean; $colorUnselected: string; $colorSelected: string}) => css`
    font: normal 700 18px 'Open Sans';
    color: ${props.$isSelected ? props.$colorSelected : props.$colorUnselected};
  `,
)

const ContainerBox = styled(Box)(
  (props: {$isSelected?: boolean; $colorUnselected: string; $colorSelected: string}) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 48px;
    width: 48px;
    border: ${props.$isSelected
      ? `2px solid ${props.$colorSelected}`
      : `2px solid ${props.$colorUnselected}`};
    border-radius: 8px;
    cursor: pointer;
  `,
)

interface IProps {
  date?: Date | undefined
  colorUnselected?: string
  colorSelected?: string
  isSelected?: boolean
  boxProps?: object
  boxSxProps?: object
  onClick?: () => void
  isdashboard?: boolean
}

export default function CalendarDayGraphic({
  date,
  isSelected = false,
  colorUnselected = '#424242',
  colorSelected = 'white',
  boxProps,
  isdashboard,
  boxSxProps,
  onClick = () => {},
}: IProps) {
  const month = date ? format(date, 'MMM') : 'MM'
  const day = date ? format(date, 'dd') : 'DD'

  return (
    <ContainerBox
      $isSelected={isSelected}
      $colorUnselected={colorUnselected}
      $colorSelected={colorSelected}
      onClick={() => {
        onClick()
      }}
      {...boxProps}
      sx={boxSxProps}>
      <MonthText
        $colorUnselected={colorUnselected}
        $colorSelected={colorSelected}
        $isSelected={isSelected}
        $isdashboard={isdashboard}>
        {month}
      </MonthText>
      <DayText
        $isSelected={isSelected}
        $colorUnselected={colorUnselected}
        $colorSelected={colorSelected}>
        {day}
      </DayText>
    </ContainerBox>
  )
}
