import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function PaperclipIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='10'
			height='18'
			viewBox='0 0 10 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8 4.5V13.125C8 14.7825 6.6575 16.125 5 16.125C3.3425 16.125 2 14.7825 2 13.125V3.75C2 2.715 2.84 1.875 3.875 1.875C4.91 1.875 5.75 2.715 5.75 3.75V11.625C5.75 12.0375 5.4125 12.375 5 12.375C4.5875 12.375 4.25 12.0375 4.25 11.625V4.5H3.125V11.625C3.125 12.66 3.965 13.5 5 13.5C6.035 13.5 6.875 12.66 6.875 11.625V3.75C6.875 2.0925 5.5325 0.75 3.875 0.75C2.2175 0.75 0.875 2.0925 0.875 3.75V13.125C0.875 15.405 2.72 17.25 5 17.25C7.28 17.25 9.125 15.405 9.125 13.125V4.5H8Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
