import {format} from 'date-fns'

export const timezoneDateFunction = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return null
  } else {
    const newDate = new Date(date)
    const correctDateDisplay = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000)

    return correctDateDisplay
  }
}

//from SHR
export const newTimezoneDateFunction = (date: Date) => {
  const formattedDate = new Date(date.replace(/-/g, '/').replace(/T.+/, '')).toISOString()

  const correctDateDisplay = format(new Date(formattedDate), 'MM/dd/yyyy')

  return correctDateDisplay
}
