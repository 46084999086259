// Use `as const` instead of if you don't need any TS enum features
// prettier-ignore
export const BASE_ROUTES = {
  AGENCY: '/agency',
  AGENCY_ADMIN: '/agency-admin',
  COMPANY_ADMIN: '/company-admin',
  CREATE_AGENCY: '/create-agency',
  CREATE_ACCOUNT_PORTAL: '/create-account-portal',
  CREATE_COMPANY: '/create-company',
  DEV_GUIDE: '/dev',
  NO_MATCH: '*',
  NOT_AUTHORIZED: '/404',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_CONFIRMATION: '/password-confirmation',
  PLATFORM_ADMIN: '/platform-admin',
  PROFILE: '/profile',
  REGISTRATION: '/registration',
  REGISTRATION_SUCCESS: '/create-company/success',
  REQUEST_RESET: '/request-reset',
  ROLE_SELECT: '/role-select',
  ROOT: '/',
  SIGNIN: '/signin',
  SOCIAL_SIGNIN: '/social-signin',
  SOCIAL_SIGNUP: '/social-signup',
} as const

export const SIDEBAR_ROUTES = {
  ADD_ADMIN: '/add-admin',
  ADD_ORDER: '/manage-orders/add-order',
  ADD_POSITION: '/manage-positions/add-position',
  ADD_TEMPLATE: '/manage-templates/add-template',
  CANDIDATES: '/candidates',
  COMPANY_CANDIDATE_LIST: '/manage-orders/candidate-list',
  CLIENT_POSITIONS: '/client-positions',
  COMPLETED_SERVICE_ORDERS: '/manage-orders/completedOrders',
  DASHBOARD: '/dashboard',
  DATA_CLEANUP: '/data-cleanup/:cardId',
  EDIT_ADMIN: '/edit-admin',
  EDIT_POSITION: '/manage-positions/edit-position',
  EDIT_SERVICEORDER: '/manage-orders/edit-service-order',
  EDIT_TEMPLATE: '/manage-templates/edit-template',
  FILES: '/files',
  GENERAL_USERS: '/general-users',
  GENERAL_ADMINS: '/general-admins',
  IMPORT: '/import',
  JOB_ORDERS: '/manage-job-orders',
  JOB_ORDER_DETAILS: '/manage-job-orders/:orderId',
  JOB_ORDER_DETAILS_SUMMARY: '/manage-job-orders/summary',
  MAIL: '/mail',
  MANAGE_AGENCY: '/manage-agency',
  MANAGE_AGENCIES: '/manage-agencies',
  MANAGE_COMPANY: '/manage-company',
  MANAGE_COMPANIES: '/manage-companies',
  MANAGE_COMPANIES_DETAILS: '/manage-companies/:companyName',
  MANAGE_MSP_AGENCY: '/manage-msp-agency',
  MANAGE_PROFILE: '/manage-profile',
  MANAGE_TEMPLATES: '/manage-templates',
  REQUISITION_TEMPLATE: '/requisition-template',
  MODULES: '/modules',
  ONBOARDING_AGENCY: '/onboarding-agency',
  ONBOARDING_COMPANY: '/onboarding-company',
  PENDING_SERVICE_ORDERS: '/manage-orders/pending',
  POSITIONS: '/manage-positions',
  PREFILL: '/prefill/:prefillCardId',
  PREFILL_ADD: '/prefill-add',
  PREFILL_EDIT: '/prefill-edit',
  PREFILL_JOB_ORDER: '/jobOrderPrefill',
  SERVICE_ORDERS: '/manage-orders',
  SERVICE_ORDER_DETAILS: '/manage-orders/:orderId',
  SERVICE_ORDER_PENDING_DETAILS: '/manage-orders/pending/:serviceOrderId',
  SERVICE_ORDER_DETAILS_SUMMARY: '/manage-orders/summary',
  TEMPLATE_DETAILS: '/manage-templates-details/',
  USERS_AND_ROLES: '/users-and-roles',
} as const
