import type {FormikErrors} from 'formik'
import React from 'react'
import type {Applicant, Assignment} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateAssignHistDisplay from './MspCandidateAssignHistDisplay'
import MspCandidateEmptyFormAssignHist from './MspCandidateEmptyFormAssignHist'

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  resetForm(obj: Assignment | undefined): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  oldApplicant: Applicant
  values: Applicant
  singleApplicantRefetch(): void
}

export default function MspCandidateActivityMain({
  errors,
  handleSubmit,
  resetForm,
  setFieldValue,
  oldApplicant,
  values,
  singleApplicantRefetch,
}: IProps) {
  return (
    <>
      <DashboardMainStyles>
        <MspCandidateEmptyFormAssignHist
          errors={errors}
          handleSubmit={handleSubmit}
          resetForm={resetForm}
          setFieldValue={setFieldValue}
          values={values}
          singleApplicantRefetch={singleApplicantRefetch}
        />
        <MspCandidateAssignHistDisplay
          handleSubmit={handleSubmit}
          oldApplicant={oldApplicant}
          resetForm={resetForm}
          setFieldValue={setFieldValue}
          values={values}
          newAssignmentsArray={values.assignments}
        />
      </DashboardMainStyles>
    </>
  )
}
