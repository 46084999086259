/* eslint-disable complexity */
import {Box} from '@mui/material'
import {LoadingBoundary} from '@ui/components'
import React, {useState} from 'react'
import type {Applicant, ISkillsByCategory} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import ReusableSkillSection from './ResusableSkillSection'
import {
  Clerical_Administrative,
  Clerical_Administrative_Options,
  Drivers_Commercial,
  Drivers_Commercial_Options,
  General_Warehouse,
  General_Warehouse_Options,
  Language,
  Language_Options,
  Manufacturing_Industrial,
  Manufacturing_Industrial_Options,
  Resturant_Hospitality,
  Resturant_Hospitality_Options,
} from './SkillTypes'
import {HeaderText, SkillMainContainer} from './styles/SkillStyles.styles'

export type TCheckboxData = {
  isChecked: boolean
  label: string
}

const updateIsChecked = (arr: TCheckboxData[], index: number): TCheckboxData[] => {
  return arr.map((item, i) => {
    if (i == index) {
      return {...item, isChecked: !item.isChecked}
    } else {
      return item
    }
  })
}

const createSkill = (arr: TCheckboxData[], category: string): ISkillsByCategory => {
  const skill: ISkillsByCategory = {category: category, categorySkills: []}
  arr.forEach((item) => {
    if (item.isChecked) {
      skill.categorySkills.push(item.label)
    }
  })
  return skill
}

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  resetForm(): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
  initialValues: Applicant
}

export default function CandidateSkillsForm({
  handleSubmit,
  resetForm,
  setFieldValue,
  values,
  initialValues,
}: IProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  //intiial values for reset
  const clericalAdministrativeCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === Clerical_Administrative
  })
  const generalWarehouseCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === General_Warehouse
  })

  const manufacturingIndustrialCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === Manufacturing_Industrial
  })

  const resturantHospitalityCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === Resturant_Hospitality
  })

  const driverCommercialCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === Drivers_Commercial
  })
  const languageCategoryInitialValues = initialValues?.find((skill) => {
    return skill.category === Language
  })

  // real values
  const clericalAdministrativeCategory = values.skills?.find((skill) => {
    return skill.category === Clerical_Administrative
  })
  const generalWarehouseCategory = values.skills?.find((skill) => {
    return skill.category === General_Warehouse
  })

  const manufacturingIndustrialCategory = values.skills?.find((skill) => {
    return skill.category === Manufacturing_Industrial
  })

  const resturantHospitalityCategory = values.skills?.find((skill) => {
    return skill.category === Resturant_Hospitality
  })

  const driverCommercialCategory = values.skills?.find((skill) => {
    return skill.category === Drivers_Commercial
  })
  const languageCategory = values.skills?.find((skill) => {
    return skill.category === Language
  })

  const calculateInit = (labels: string[], category: ISkillsByCategory | undefined): TCheckboxData[] => {
    const retValues = labels.map((label) => {
      return {isChecked: false, label: label} as TCheckboxData
    })
    if (category === undefined) {
      return retValues
    } else {
      return retValues
        .map((item) => {
          const foundItem = category.categorySkills.find((skill) => {
            return skill === item.label
          })
          if (foundItem) {
            return {...item, isChecked: true}
          } else {
            return item
          }
        })
        .sort((x, y) => Number(y.isChecked) - Number(x.isChecked))
    }
  }

  const [isClericalAdministrativeEnabled, setIsClericalAdministrativeEnabled] = useState(
    clericalAdministrativeCategory !== undefined,
  )
  const [clericalAdminArray, setClericalAdminArray] = useState<TCheckboxData[]>(
    calculateInit(Clerical_Administrative_Options, clericalAdministrativeCategory),
  )
  const [isGeneralWarehouseEnabled, setIsGeneralWarehouseEnabled] = useState(
    generalWarehouseCategory !== undefined,
  )
  const [generalWarehouseArray, setGeneralWarehouseArray] = useState<TCheckboxData[]>(
    calculateInit(General_Warehouse_Options, generalWarehouseCategory),
  )

  const [isManufacturingEnabled, setIsManufacturingEnabled] = useState(
    manufacturingIndustrialCategory !== undefined,
  )
  const [manufacturingArray, setManufacturingArray] = useState<TCheckboxData[]>(
    calculateInit(Manufacturing_Industrial_Options, manufacturingIndustrialCategory),
  )

  const [resturantHospitalityEnabled, setIsResturantHospitalityEnabled] = useState(
    resturantHospitalityCategory !== undefined,
  )
  const [resturantArray, setResturantArray] = useState<TCheckboxData[]>(
    calculateInit(Resturant_Hospitality_Options, resturantHospitalityCategory),
  )

  const [isDriverCategoryEnabled, setIsDriverCategoryEnabled] = useState(
    driverCommercialCategory !== undefined,
  )
  const [driverCategoryArray, setDriverCategoryArray] = useState<TCheckboxData[]>(
    calculateInit(Drivers_Commercial_Options, driverCommercialCategory),
  )

  const [isLanguageEnabled, setIsLanguageEnabled] = useState(languageCategory !== undefined)
  const [languageArray, setLanguageArray] = useState<TCheckboxData[]>(
    calculateInit(Language_Options, languageCategory),
  )

  const handleSave = async () => {
    setIsLoading(true)
    const skills: ISkillsByCategory[] = []

    if (clericalAdminArray.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(clericalAdminArray, Clerical_Administrative))
      setIsClericalAdministrativeEnabled(true)
    } else {
      setIsClericalAdministrativeEnabled(false)
    }

    if (generalWarehouseArray.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(generalWarehouseArray, General_Warehouse))
      setIsGeneralWarehouseEnabled(true)
    } else {
      setIsGeneralWarehouseEnabled(false)
    }

    if (manufacturingArray.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(manufacturingArray, Manufacturing_Industrial))
      setIsManufacturingEnabled(true)
    } else {
      setIsManufacturingEnabled(false)
    }

    if (resturantArray.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(resturantArray, Resturant_Hospitality))
      setIsResturantHospitalityEnabled(true)
    } else {
      setIsResturantHospitalityEnabled(false)
    }

    if (driverCategoryArray?.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(driverCategoryArray, Drivers_Commercial))
      setIsDriverCategoryEnabled(true)
    } else {
      setIsDriverCategoryEnabled(false)
    }
    if (languageArray.filter((i) => i.isChecked).length > 0) {
      skills.push(createSkill(languageArray, Language))
      setIsLanguageEnabled(true)
    } else {
      setIsLanguageEnabled(false)
    }
    const candidateCopy = JSON.parse(JSON.stringify(values)) as Applicant
    candidateCopy.skills = skills
    await handleSubmit(candidateCopy)

    setFieldValue('skills', skills)
    setIsLoading(false)
    setIsEdit(false)
  }

  const handleEdit = async (value: boolean, newReset?: () => void) => {
    if (value) {
      setIsEdit(value)
      setIsClericalAdministrativeEnabled(value)

      setIsGeneralWarehouseEnabled(value)
      setIsManufacturingEnabled(value)
      setIsResturantHospitalityEnabled(value)
      setIsDriverCategoryEnabled(value)
      setIsLanguageEnabled(value)
    } else {
      newReset()
      setIsClericalAdministrativeEnabled(clericalAdministrativeCategoryInitialValues !== undefined)
      setClericalAdminArray(
        calculateInit(Clerical_Administrative_Options, clericalAdministrativeCategoryInitialValues),
      )
      setIsGeneralWarehouseEnabled(generalWarehouseCategoryInitialValues !== undefined)
      setGeneralWarehouseArray(
        calculateInit(General_Warehouse_Options, generalWarehouseCategoryInitialValues),
      )

      setIsManufacturingEnabled(manufacturingIndustrialCategoryInitialValues !== undefined)
      setManufacturingArray(
        calculateInit(
          Manufacturing_Industrial_Options,
          manufacturingIndustrialCategoryInitialValues,
        ),
      )

      setIsResturantHospitalityEnabled(resturantHospitalityCategoryInitialValues !== undefined)
      setResturantArray(
        calculateInit(Resturant_Hospitality_Options, resturantHospitalityCategoryInitialValues),
      )

      setIsDriverCategoryEnabled(driverCommercialCategoryInitialValues !== undefined)
      setDriverCategoryArray(
        calculateInit(Drivers_Commercial_Options, driverCommercialCategoryInitialValues),
      )
      setIsLanguageEnabled(languageCategoryInitialValues !== undefined)
      setLanguageArray(calculateInit(Language_Options, languageCategoryInitialValues))

      setIsEdit(value)
    }
  }

  return (
    <>
      <DashboardMainStyles>
        <SkillMainContainer>
          <InformationCardHeaderPadding>
            <InformationCardHeaderTextTitle>Your Skills</InformationCardHeaderTextTitle>

            {isEdit ? (
              <div
                style={{
                  display: 'flex',
                  width: '100px',
                  justifyContent: 'space-between',
                }}>
                <HeaderText
                  aria-label="cancel-region-information"
                  data-testid="cancel-region-information"
                  onClick={() => handleEdit(false, resetForm)}>
                  Cancel
                </HeaderText>
                <HeaderText
                  aria-label="save-region-information"
                  data-testid="save-region-information"
                  onClick={() => handleSave(values)}>
                  Save
                </HeaderText>
              </div>
            ) : (
              <HeaderText
                aria-label="edit-region-information"
                data-testid="edit-region-information"
                onClick={() => handleEdit(true)}>
                Edit
              </HeaderText>
            )}
          </InformationCardHeaderPadding>
          {isLoading ? (
            <LoadingBoundary isLoading={isLoading} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
                marginRight: '20px',
              }}>
              {/* clerical admin */}
              {isClericalAdministrativeEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={isClericalAdministrativeEnabled}
                  isEdit={isEdit}
                  setArray={setClericalAdminArray}
                  setEnabled={setIsClericalAdministrativeEnabled}
                  mainArray={clericalAdminArray}
                  header={Clerical_Administrative}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}
              {/* general warehouse */}
              {isGeneralWarehouseEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={isGeneralWarehouseEnabled}
                  isEdit={isEdit}
                  setArray={setGeneralWarehouseArray}
                  setEnabled={setIsGeneralWarehouseEnabled}
                  mainArray={generalWarehouseArray}
                  header={General_Warehouse}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}

              {/* manufacturing */}

              {isManufacturingEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={isManufacturingEnabled}
                  isEdit={isEdit}
                  setArray={setManufacturingArray}
                  setEnabled={setIsManufacturingEnabled}
                  mainArray={manufacturingArray}
                  header={Manufacturing_Industrial}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}

              {/* resturant */}
              {resturantHospitalityEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={resturantHospitalityEnabled}
                  isEdit={isEdit}
                  setArray={setResturantArray}
                  setEnabled={setIsResturantHospitalityEnabled}
                  mainArray={resturantArray}
                  header={Resturant_Hospitality}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}

              {/* driver */}
              {isDriverCategoryEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={isDriverCategoryEnabled}
                  isEdit={isEdit}
                  setArray={setDriverCategoryArray}
                  setEnabled={setIsDriverCategoryEnabled}
                  mainArray={driverCategoryArray}
                  header={Drivers_Commercial}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}

              {/* language */}
              {isLanguageEnabled || isEdit ? (
                <ReusableSkillSection
                  isEnabled={isLanguageEnabled}
                  isEdit={isEdit}
                  setArray={setLanguageArray}
                  setEnabled={setIsLanguageEnabled}
                  mainArray={languageArray}
                  header={Language}
                  updateChecked={updateIsChecked}
                />
              ) : (
                <div />
              )}
            </Box>
          )}
        </SkillMainContainer>
      </DashboardMainStyles>
    </>
  )
}
