import React from 'react'
import PasswordField from './PasswordFormikField'
import StandardField from './StandardFormikField'
import type {ITextField} from './typings'

export default function TextField(props: ITextField) {
  switch (props.type) {
    case 'password':
      return <PasswordField {...props} />
    default:
      return <StandardField {...props} />
  }
}
