/* eslint-disable no-await-in-loop */
import {MenuItem} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {errorFunction} from '@utils/errorFunction'
import {Form as FormikForm, Formik} from 'formik'
import React, {useContext, useState} from 'react'
import {FileUploader} from 'react-drag-drop-files'
import * as Yup from 'yup'
import {Red} from '~/src/components/msp/manage-job-orders/job-order-summary/job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import ReusableSnackbar from '~/src/components/Snackbar/ReusableSnackbar'
import {DocumentApplicantContext} from '~/src/store/context/DocumentApplicantContext'
import {ReactComponent as XButton} from '../../../../assets/images/closeIcon.svg'
import {ReactComponent as FolderIconSmall} from '../../../../assets/images/FolderIconSmall.svg'
import {ReactComponent as UploadIcon} from '../../../../assets/images/UploadFileSvg.svg'
import {usePutUploadFileMutation} from '../../../../store/apis/document-api'
import AddFilesList from '../AddFilesList'
import CheckIcon from '../Check'
import {
  Body,
  ButtonBox,
  HeaderContainer,
  HeaderText,
  ListContainer,
  SaveToContainer,
  SaveToContainerMargin,
  SaveToContainerSpaceBetween,
  SaveToContainerText,
  StyledButton,
} from './styles/AddDocumentDialogStyles'

export const fileTypes = [
  'JPEG',
  'PNG',
  'GIF',
  'PDF',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'docx',
  'csv',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'txt',
]

interface IProps {
  buttonSubmitting: boolean
  documentId: string | undefined
  folderList: [{name: string; files: []}] | undefined
  handleCancel(): void
  isInFolder: boolean
  isOpen: boolean
  setButtonSubmitting: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  setSelectedFolder: React.Dispatch<React.SetStateAction<undefined>>
}

export default function AddDocumentDialog({
  buttonSubmitting,
  documentId,
  isInFolder,
  isOpen,
  folderList,
  handleCancel,
  setIsSubmitting,
  setButtonSubmitting,
  setSelectedFolder,
}: IProps): React.ReactElement {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])

  const documentContext = useContext(DocumentApplicantContext)

  const [putUploadFile] = usePutUploadFileMutation()
  const handleChange = (newFile) => {
    // this package (when multiple option is true) return object of objects.
    // removing an object from object of objects is quite difficult so i manipulate it into an array here for easy removal
    const turnKeyIntoArray = Object.keys(newFile).map((label, index) => {
      return newFile[index]
    })

    setUploadedFiles((prevState) => [...prevState, ...turnKeyIntoArray])
  }

  const handleRemoveFiles = (value: number) => {
    uploadedFiles.splice(value, 1)
    setUploadedFiles([...uploadedFiles])
  }

  const initialFormValues = {
    documentId: documentId,
    folderName: isInFolder ? folderList?.name : '',
  }

  const stack2 = (
    <div
      className="container"
      style={{
        height: '240px',
        width: '526px',
        borderRadius: '4px',
        backgroundRepeat: 'no-repeat',
        borderColor: '#e0e0e0',
        borderWidth: '5px',
        borderStyle: 'dashed',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}>
      <div>
        <div>
          <UploadIcon style={{marginRight: 20, marginTop: 5}} />
        </div>
      </div>
      <div>
        <div style={{fontSize: 14}}>
          Drop files here to upload or click <Red>*</Red>{' '}
        </div>
      </div>
    </div>
  )

  const handleSubmit = async (values) => {
    if (isInFolder) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const body = uploadedFiles[i]

        await putUploadFile({
          agencyId: documentContext?.loggedInAgencyId,
          documentId: values.documentId,
          folderName: values.folderName,
          body: body,
        })
          .unwrap()
          .then((res) => {
            const matchResponseFile = res.folders.find(
              (folderInfo) => folderInfo.name === values.folderName,
            )
            setSelectedFolder(matchResponseFile)
            setUploadedFiles([])
            setIsSnackbarOpen(false)
            setButtonSubmitting(false)
            handleCancel()
          })
          .catch((err) => {
            errorFunction(err, setIsSnackbarOpen)
            setButtonSubmitting(false)
          })
      }
    } else {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const body = uploadedFiles[i]

        await putUploadFile({
          agencyId: documentContext?.loggedInAgencyId,
          documentId: values.documentId,
          folderName: values.folderName,
          body: body,
        })
          .unwrap()
          .then(() => {
            handleCancel()
            setUploadedFiles([])
            setButtonSubmitting(false)
            setIsSnackbarOpen(false)
            handleCancel()
          })
          .catch((err) => {
            setButtonSubmitting(false)
            errorFunction(err, setIsSnackbarOpen)
          })
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {minWidth: 660, width: 660, height: 697, overflow: ' hidden'},
      }}>
      <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />

      <HeaderContainer style={{minHeight: '72px', maxHeight: '72px'}}>
        <HeaderText style={{fontWeight: 600, fontSize: '16px'}}>Upload Files</HeaderText>
        <XButton
          aria-label="addDocument-closeDialog-x-button"
          data-testid="addDocument-closeDialog-x-button"
          onClick={() => {
            setIsSnackbarOpen(false)
            setUploadedFiles([])
            handleCancel()
          }}
          style={{cursor: 'pointer'}}
        />
      </HeaderContainer>

      <Body>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={Yup.object({
            documentId: Yup.string(),
            folderName: Yup.string().required('Required'),
          })}>
          {({values}) => {
            return (
              <FormikForm>
                <FileUploader
                  /* eslint-disable */
                  children={stack2}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  maxSize={500}
                  multiple={true}
                />

                <ListContainer>
                  {uploadedFiles?.length !== undefined ? (
                    uploadedFiles.map((fileInfo, fileIndex) => {
                      return (
                        <AddFilesList
                          key={fileIndex}
                          fileName={fileInfo.name}
                          handleDelete={() => handleRemoveFiles(fileIndex)}
                        />
                      )
                    })
                  ) : (
                    <div />
                  )}
                </ListContainer>

                <SaveToContainer>
                  <SaveToContainerMargin>
                    <SaveToContainerText style={{fontWeight: 500, marginTop: 10}}>
                      Save to...
                    </SaveToContainerText>
                    {!isInFolder ? (
                      <SaveToContainerSpaceBetween>
                        <SaveToContainerText style={{marginTop: 17}}>
                          <FolderIconSmall style={{marginRight: 3}} height={24} width={24} />
                          Employee Uploads
                        </SaveToContainerText>
                        <SaveToContainerText>
                          <FormikTextField
                            sx={{
                              marginTop: 1,
                              width: '100px',
                              border: 'none',
                              '& fieldset': {border: 'none'},
                              ' & .MuiFormHelperText-root': {
                                marginTop: '-10px',
                              },
                            }}
                            select
                            value="Change"
                            InputLabelProps={{shrink: false, size: 'small', marginBottom: -10}}
                            label="Change"
                            name="folderName">
                            {folderList?.map((folderInfo, folderInfoIndex) => {
                              return (
                                <MenuItem
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                  key={folderInfoIndex}
                                  value={folderInfo.name}>
                                  {folderInfo.name}
                                  {values.folderName === folderInfo.name ? (
                                    <CheckIcon
                                      style={{width: '12px', height: '12px', color: 'green'}}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </MenuItem>
                              )
                            })}
                          </FormikTextField>
                        </SaveToContainerText>
                      </SaveToContainerSpaceBetween>
                    ) : (
                      <SaveToContainerSpaceBetween>
                        <SaveToContainerText style={{marginTop: 17}}>
                          <FolderIconSmall style={{marginRight: 3}} height={24} width={24} />
                          {folderList?.name}
                        </SaveToContainerText>
                      </SaveToContainerSpaceBetween>
                    )}
                  </SaveToContainerMargin>
                </SaveToContainer>
                <ButtonBox>
                  <StyledButton
                    disabled={buttonSubmitting}
                    data-testid="addDocument-cancel-button"
                    aria-label="addDocument-cancel-button"
                    style={{marginRight: '25px'}}
                    onClick={() => {
                      setIsSnackbarOpen(false)
                      setUploadedFiles([])
                      handleCancel()
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button">
                    Cancel
                  </StyledButton>
                  <StyledButton
                    disabled={buttonSubmitting}
                    data-testid="addDocument-saveFiles-button"
                    aria-label="addDocument-saveFiles-button"
                    sx={{color: 'white'}}
                    variant="contained"
                    color="primary"
                    type="submit">
                    Save
                  </StyledButton>
                </ButtonBox>
              </FormikForm>
            )
          }}
        </Formik>
      </Body>
    </Dialog>
  )
}
