import styled from 'styled-components'

const Layout = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    padding-top: 0px;
    overflow-y: scroll;
  `,

  // changed min-width to 1111px to center pages but if we need more like in the dashboard we can adjust
  Main: styled.main`
    overflow-y: scroll;
    height: 100%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    min-width: 1111px;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
      `,
}

export default Layout
