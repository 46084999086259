/* eslint-disable complexity */
import CircularProgress from '@mui/material/CircularProgress'
import {styled} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Icon from '@ui/icons'
import {extractAddressComponent} from '@utils'
import {useField} from 'formik'
import React, {useEffect, useState} from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import type {IAgencyBase, ICompanyBase} from '~/src/models'
import type {IBaseAddress} from '~/src/models/baseAddress'
import StyledAutocomplete from './StyledAutocomplete'

const CircularProgressContainer = styled('div')({
  position: 'absolute',
  right: 9,
  display: 'flex',
})

interface IProps {
  isCityStateBoolean: boolean
  name: string
  setFieldValue: (field: string, value: unknown) => void
  address: IBaseAddress | string | number | boolean | null | undefined
  initialEditValues: IAgencyBase | ICompanyBase | undefined
  disabled?: boolean
}

export default function FormikGooglePlacesAutocomplete({
  isCityStateBoolean,
  name,
  setFieldValue,
  address,
  initialEditValues,
  disabled = false,
}: IProps) {
  const isCityStateType = isCityStateBoolean ? ['(cities)'] : ['address']
  const isCityStateField = isCityStateBoolean
    ? undefined
    : ['formatted_address', 'street_address', 'name']
  const {placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading} =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
      debounce: 500,
      options: {
        componentRestrictions: {country: 'us'},
        fields: isCityStateField,
        strictBounds: false,
        types: isCityStateType,
      },
    })

  const [userHasSelected, setUserHasSelected] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [details, setDetails] = useState<ReturnType<typeof placesService.getDetails>>()

  const [field, meta, helpers] = useField({
    name: `${name}.display`,
  })
  const [{value: addressObj}] = useField({name})

  useEffect(() => {
    if (details) {
      const {adr_address, geometry} = details
      const lng = geometry?.location?.toString().split(',')[1].split(')')[0]
      const lat = geometry.location.toString().split('(')[1].split(',')[0]
      const coordinates = [parseFloat(lng), parseFloat(lat)]
      const location = {type: 'Point', coordinates}
      // Synchronize individual address properties with Formik display field

      setFieldValue(name, {
        line1: extractAddressComponent('street-address', adr_address),
        city: extractAddressComponent('locality', adr_address),
        state: extractAddressComponent('region', adr_address),
        zipCode: extractAddressComponent('postal-code', adr_address),
        display: field.value,
        id: addressObj.id,
        ...(addressObj?.name && {name: addressObj.name}),
        location,
      })
    }
  }, [addressObj?.id, addressObj?.name, details, field.value, name, setFieldValue])

  useEffect(() => {
    // Fetch place details after selecting a place from autocomplete dropdown
    if (userHasSelected && placePredictions.length > 0) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
          fields: ['adr_address', 'geometry'],
        },
        (placeDetails: unknown) => setDetails(placeDetails),
      )
      // Unset target details after user starts typing again
    } else if (
      !userHasSelected &&
      placePredictions.length > 0 &&
      placePredictions[0]?.description === field.value
    ) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
          fields: ['adr_address', 'geometry'],
        },
        (placeDetails: unknown) => setDetails(placeDetails),
      )
    } else {
      setDetails(null)
    }
  }, [userHasSelected, placePredictions, placesService, field.value])
  useEffect(() => {
    // Display autocomplete dropdown after user starts typing
    if (
      placePredictions.length > 0 &&
      placePredictions[0]?.description === field.value &&
      initialEditValues
    ) {
      setIsOpen(false)
    } else if (!userHasSelected && placePredictions[0]?.description === field.value) {
      setIsOpen(false)
    } else if (!userHasSelected && placePredictions.length > 0) {
      setIsOpen(true)
    }
  }, [userHasSelected, placePredictions, field.value, initialEditValues])

  const helperTextSwitch =
    isCityStateBoolean && meta.touched && meta.error
      ? meta.error
      : !isCityStateBoolean && meta.touched && meta.error
      ? meta.error
      : !isCityStateBoolean && meta.touched && addressObj.line1 === undefined
      ? 'Required'
      : null

  const errorTextSwitch =
    (isCityStateBoolean && meta.error && meta.touched && Boolean(meta.error)) ||
    (!isCityStateBoolean && meta.touched && Boolean(meta.error)) ||
    (!isCityStateBoolean && meta.touched && addressObj?.line1 === undefined) ||
    (!isCityStateBoolean && meta.touched && addressObj?.line1 === '')
  return (
    <StyledAutocomplete
      freeSolo
      value={addressObj?.display}
      disablePortal
      options={
        isCityStateBoolean
          ? placePredictions.map((p: {description: string; terms: Array}) =>
              p.terms.length < 2 ? '' : `${p.terms?.[0]?.value}, ${p.terms?.[1]?.value}`,
            )
          : placePredictions.map((p: {description: string; terms: Array}) =>
              p.terms.length < 4 ? '' : p.description,
            )
      }
      onBlur={() => {
        helpers.setTouched(true)
        setIsOpen(false)
      }}
      onChange={(e, value: unknown) => {
        helpers.setTouched(true)
        helpers.setValue(value ?? '')
        setUserHasSelected(true)
        setIsOpen(false)
      }}
      onInputChange={(e, value: unknown) => {
        helpers.setValue(value ?? '')
        setUserHasSelected(false)
        getPlacePredictions({input: value})
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      clearIcon={<Icon icon="close" />}
      popupIcon={null}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={field.name}
          error={errorTextSwitch}
          helperText={helperTextSwitch}
          placeholder={isCityStateBoolean ? 'City, State' : '123 Sample Street'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            ...(isPlacePredictionsLoading && {
              endAdornment: !disabled && (
                <CircularProgressContainer>
                  <CircularProgress size={24} />
                </CircularProgressContainer>
              ),
            }),
          }}
        />
      )}
    />
  )
}
