import React from 'react'
import {FieldArray, FormikValues} from 'formik'
import AddressField from './AddressField'

interface IProps {
  values?: FormikValues
  activePlatform: string
  setFieldValue: (field: string, value: unknown) => void
}

export default function CompanyAddressListField(props: IProps) {
  const addresses = props.values.address ?? {}

  return (
    <FieldArray name="address">
      {({push, remove}) => (
        <React.Fragment>
          <AddressField
            key={addresses.id}
            address={addresses}
            remove={remove}
            setFieldValue={props.setFieldValue}
            activePlatform={props.activePlatform}
          />
        </React.Fragment>
      )}
    </FieldArray>
  )
}
