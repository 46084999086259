import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {FieldArray, FormikValues} from 'formik'
import React from 'react'
import type {IAgencyBase, ICompanyBase} from '~/src/models'
import createAddressItem from '../../components/platform/onboarding/helpers/createAddress'
import CompanyAddressSignInItem from './AddressFieldSignInItem'

interface IProps {
  values?: FormikValues
  activePlatform: string
  setFieldValue: (field: string, value: unknown) => void
  initialEditValues?: IAgencyBase | ICompanyBase | undefined
}

export default function CompanyAddressSignInList(props: IProps) {
  const {addresses} = props.values ?? {}

  return (
    <FieldArray name="addresses">
      {({push, remove}) => (
        <React.Fragment>
          {addresses.length > 0 &&
            addresses.map((address: {id: string}, index: number) => (
              <CompanyAddressSignInItem
                key={address.id}
                addresses={addresses}
                initialEditValues={props.initialEditValues}
                index={index}
                remove={remove}
                setFieldValue={props.setFieldValue}
                activePlatform={props.activePlatform}
              />
            ))}

          <Grid item xs={3} />

          <Grid item xs={9}>
            <Button
              variant="text"
              size="small"
              sx={{color: 'primary.dark', p: 0, mt: 4}}
              onClick={() => push(createAddressItem())}>
              + Add Another Location
            </Button>
          </Grid>
        </React.Fragment>
      )}
    </FieldArray>
  )
}
