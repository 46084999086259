import {Box} from '@mui/system'
import React from 'react'
import styled from 'styled-components'
import type {ISubMenuItem} from '../../layout/MenuItems'
import NavMenuItem from './NavMenuItem'

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.16);
`

interface SubMenuProps {
  items: ISubMenuItem[]
  activeSubLink: number
  closeMenu: (event: React.MouseEvent<HTMLElement>) => void
}

const SubMenu = ({items, closeMenu, activeSubLink}: SubMenuProps) => {
  return (
    <Container>
      {items.map((page) => (
        <NavMenuItem
          id={page.id.toString()}
          key={page.id}
          text={page.text}
          link={page.link}
          active={activeSubLink == page.id}
          handleSetActive={closeMenu}
        />
      ))}
    </Container>
  )
}

export default SubMenu
