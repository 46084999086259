import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function CheckboxCheckedIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props} 
			width="20" 
			height="20" 
			viewBox="0 0 20 20" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg">
			<rect width="20" height="20" rx="4" fill="#03A9FC"/>
			<path d="M6.52352 10.4016C6.35219 10.261 6.12893 10.1847 5.89854 10.188C5.66815 10.1914 5.44765 10.2741 5.28129 10.4195C5.11493 10.565 5.015 10.7624 5.00156 10.9723C4.98811 11.1821 5.06214 11.3889 5.20879 11.551L7.23566 13.4918C7.32052 13.573 7.42249 13.6377 7.53545 13.6821C7.64841 13.7265 7.77003 13.7496 7.89302 13.75C8.01535 13.7506 8.13658 13.7289 8.2495 13.6859C8.36243 13.643 8.46475 13.5798 8.55038 13.5001L14.7405 7.6695C14.8245 7.59074 14.8906 7.49767 14.9351 7.39561C14.9796 7.29355 15.0016 7.18449 14.9999 7.07466C14.9982 6.96482 14.9728 6.85637 14.9252 6.75549C14.8775 6.65461 14.8086 6.56328 14.7223 6.48672C14.6359 6.41015 14.5339 6.34984 14.4221 6.30924C14.3102 6.26864 14.1906 6.24854 14.0703 6.25008C13.9499 6.25163 13.831 6.27479 13.7204 6.31825C13.6098 6.36171 13.5097 6.42462 13.4258 6.50337L7.90215 11.7176L6.52352 10.4016Z" fill="white"/>
		</Svg>
	);
}

