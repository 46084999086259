import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function CheckboxUncheckedIcon (props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width="20" 
			height="20" 
			viewBox="0 0 20 20" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#B3B3B3"/>
		</Svg>
	);
}

