import {Box} from '@mui/material'
import {styled, css} from '@mui/material/styles'
import Icon from '@ui/icons'

const Title = styled('div')(
  ({theme}) => css`
    text-align: center;
    font-size: ${theme.typography.h2.fontSize};
  `,
)

const BrandName = styled('h2')`
  font-weight: 300;
`

const Container = styled(Box)(
  ({theme}) => css`
    color: ${theme.palette.background.paper};
    background-image: ${theme.gradients.primary};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: ;
  `,
)

const Logo = styled(Icon)`
  height: 100px;
  margin: 1.5rem 0;
  width: 100px;
  text-align: center;
`

export default {
  Container,
  Title,
  Logo,
  BrandName,
}
