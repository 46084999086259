import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

const Svg = styled.svg``

export default function EmailIcon(props: HTMLAttributes<unknown>): JSX.Element {
  return (
    <Svg
      {...props}
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0.5H1.5C0.8125 0.5 0.25625 1.0625 0.25625 1.75L0.25 9.25C0.25 9.9375 0.8125 10.5 1.5 10.5H11.5C12.1875 10.5 12.75 9.9375 12.75 9.25V1.75C12.75 1.0625 12.1875 0.5 11.5 0.5ZM1.49996 2.99998L6.49996 6.12498L11.5 2.99998V9.24998H1.49996V2.99998ZM1.49996 1.75002L6.49996 4.87502L11.5 1.75002H1.49996Z"
        fill="currentColor"
      />
    </Svg>
  )
}
