import CheckIcon from '@mui/icons-material/Check'
import Update from '@mui/icons-material/Update'
import {Alert, Button, IconButton, Snackbar, Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import {Formik, Form as FormikForm} from 'formik'
import React, {useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import type {IOrder} from '~/src/models/order'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {
  useGetSingleServiceOrderQuery,
  useSubmitServiceOrderMutation,
} from '~/src/store/apis/service-order-api'
import {useAppDispatch} from '~/src/store/store-hooks'
import Title from '../../../layout/Title'
import OrderDetailsTable from './OrderDetailsTable'

const Container = styled.div`
  position: relative;
  flex: 1;
`
const TableInfo = styled.p`
  margin-right: 24px;
  padding: 8px 0;
`

interface IUpdateProps {
  initialValues: IInitialServiceOrderValues
}
interface IParams {
  orderId: string
}
export interface IFormUpdateValues {
  id: string
  status: string
  mspStatus?: string
  orderOwner: string
  orderNumber: string
  agencyId: string
  versionKey: number
  orders: IOrder[]
}

interface IState {
  openSnackbar?: boolean
  orderNumber?: number
  type?: string
  pendingOrder?: boolean
  isCreated?: boolean
}

export default function OrderDetails({initialValues}: IUpdateProps) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const locationState = location.state as IState | undefined
  const [open, setOpen] = useState(locationState?.openSnackbar ? locationState.openSnackbar : false)
  const [openPendingOrderDialog, setOpenPendingOrderDialog] = useState(
    locationState?.pendingOrder ? locationState.pendingOrder : false,
  )

  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [confirmationApprovedDialog, setConfirmationApprovedDialog] = useState(false)
  const [acceptConfirmationDialog, setAcceptConfirmationDialog] = useState(false)
  const [acceptConfirmationApprovedDialog, setAcceptConfirmationApprovedDialog] = useState(false)
  const {orderId}: IParams = useParams()
  const [submitOrderToAgency] = useSubmitServiceOrderMutation()

  const {
    data: serviceOrder,
    error: serviceOrderError,
    isLoading: serviceOrderLoading,
    isSuccess: serviceOrderIsSuccess,
  } = useGetSingleServiceOrderQuery({
    id: orderId,
  })

  const statusString = serviceOrder?.companyStatus

  const totalQuantity = serviceOrder?.orders
    .map((order) => Number(order.quantity))
    .reduce((prev, curr) => prev + curr, 0)

  const initialFormValues = {
    id: serviceOrder?.id,
    orderOwner: serviceOrder?.orderOwner,
    orderNumber: serviceOrder?.orderNumber,
    agencyId: serviceOrder?.agencyId,
    versionKey: serviceOrder?.versionKey,
    orders: serviceOrder?.orders,
  } as unknown as IFormUpdateValues

  const handleSubmitPlaced = async () => {
    await submitOrderToAgency({serviceOrderId: orderId})
      .unwrap()
      .then(() => {
        return setConfirmationApprovedDialog(true)
      })
      .catch((error) => console.error('rejected', error))
  }

  const handleSubmitAccepted = async () => {
    await submitOrderToAgency({serviceOrderId: orderId})
      .unwrap()
      .then(() => {
        return setAcceptConfirmationApprovedDialog(true)
      })
      .catch((error) => console.error('rejected', error))
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handlePendingOrderDialog = () => {
    setOpenPendingOrderDialog(false)
  }

  //history(goBack()) is not wanted, they want you to go back to the manage order page everytime not to the last visited page.
  const handleGoBack = () => {
    dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`))
  }

  // hover transparent makes the background grey so changed it to white to match the table color
  const projectStatusSwitch = () => {
    switch (statusString) {
      case 'Pending Submit':
        return (
          <div style={{display: 'flex'}}>
            <p style={{marginTop: -2}}>
              <IconButton
                sx={{
                  color: '#03a9fc',
                  '&:hover': {
                    backgroundColor: 'white',
                    cursor: 'default',
                  },
                }}>
                <Update />
              </IconButton>
            </p>
            <p style={{marginTop: 9}}>{statusString}</p>
          </div>
        )
      case 'Processing Order':
        return (
          <div style={{display: 'flex'}}>
            <p style={{marginTop: -2}}>
              <IconButton
                sx={{
                  '&:hover': {
                    backgroundColor: 'white',
                    cursor: 'default',
                  },
                  color: '#3DB429',
                }}>
                <CheckIcon />
              </IconButton>
            </p>
            <p style={{marginTop: 9}}>{statusString}</p>
          </div>
        )
      case 'Filled':
        return (
          <div>
            <IconButton
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#3DB429',
                  cursor: 'default',
                },
              }}>
              <CheckIcon />
            </IconButton>
            Order Filled
          </div>
        )
      // this should cover statuses Confirming Order, Reviewing Order, and Filling Order
      default:
        return (
          <div style={{display: 'flex'}}>
            <p style={{marginTop: -2}}>
              <IconButton
                sx={{
                  '&:hover': {
                    backgroundColor: 'white',
                    cursor: 'default',
                  },
                  color: '#03a9fc',
                }}>
                <Update />
              </IconButton>
            </p>
            <p style={{marginTop: 9}}>{statusString}</p>
          </div>
        )
    }
  }
  return (
    <>
      {locationState?.openSnackbar && (
        <Snackbar
          aria-label="Service Order Edit Success Popup"
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! Service Order {locationState.orderNumber} has been {locationState.isCreated ? 'created!' : 'edited!'}
          </Alert>
        </Snackbar>
      )}
      {locationState?.pendingOrder && (
        <BasicSuccessDialog
          isOpen={openPendingOrderDialog}
          header="Pending Order"
          bigBlurb="Please submit your existing order"
          smallBlurb="You already have an order that is pending, please submit current order before opening
        another"
          confirmButtonLabel="Continue"
          handleSubmit={handlePendingOrderDialog}
        />
      )}
      <Formik
        onSubmit={handleSubmitPlaced}
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          status: Yup.string().required(),
          id: Yup.string().required(),
          orderOwner: Yup.string().required(),
          versionKey: Yup.string().required(),
          orders: Yup.array().of(
            Yup.object({
              orderType: Yup.string().required('Required'),
              positionId: Yup.string().required('Required'),
              payRate: Yup.string().required('Required'),
              distributionStartDate: Yup.string().required('Required').nullable(),
              distributionEndDate: Yup.string().required('Required').nullable(),
              quantity: Yup.string()
                .required('Required')
                .max(50, 'Quantity must be 50 characters or less.'),
              note: Yup.string(),
              orderDistribution: Yup.object({
                orderQuantityDistributions: Yup.array().of(
                  Yup.object({
                    date: Yup.string(),
                    quantity: Yup.number(),
                  }),
                ),
                agencyDistributions: Yup.array().of(
                  Yup.object({
                    agencyId: Yup.string(),
                    agencyQuantityDistributions: Yup.array().of(
                      Yup.object({
                        date: Yup.string(),
                        pledged: Yup.number(),
                        requested: Yup.number(),
                      }),
                    ),
                  }),
                ),
              }),
            }),
          ),
        })}>
        {({isSubmitting, values}) => {
          return (
            <FormikForm>
              <Container>
                <Title pageTitle="Service Order Details" />
                <LoadingBoundary isLoading={serviceOrderLoading}>
                  {serviceOrder && (
                    <ErrorBoundary error={serviceOrderError}>
                      <BasicSuccessDialog
                        isOpen={confirmationDialog && confirmationApprovedDialog}
                        header="Order Successfully Placed"
                        bigBlurb="Order Successfully Placed"
                        smallBlurb="Your Service Order has been sent! Please wait until the MSP reviews the order."
                        confirmButtonLabel="Done"
                        handleSubmit={() => {
                          setConfirmationDialog(false)
                          dispatch(
                            push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`),
                          )
                        }}
                      />
                      <BasicConfirmationDialog
                        isOpen={confirmationDialog && !confirmationApprovedDialog}
                        header="Order will be Sent to MSP"
                        bigBlurb="Please Confirm Order"
                        smallBlurb="Are you ready to submit?"
                        cancelButtonLabel="Back"
                        handleCancel={() => {
                          setConfirmationDialog(false)
                        }}
                        handleSubmit={handleSubmitPlaced}
                      />
                      <BasicSuccessDialog
                        isOpen={acceptConfirmationDialog && acceptConfirmationApprovedDialog}
                        header="Order Successfully Accepted"
                        bigBlurb="Order Successfully Accepted"
                        smallBlurb="Your Service Order has been Accepted"
                        confirmButtonLabel="Done"
                        handleSubmit={() => {
                          setAcceptConfirmationDialog(false)
                          dispatch(
                            push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`),
                          )
                        }}
                      />
                      <BasicConfirmationDialog
                        isOpen={acceptConfirmationDialog && !acceptConfirmationApprovedDialog}
                        header="Are You Ready To Accept This Order?"
                        bigBlurb="Please Accept Commitment"
                        smallBlurb="Are you ready to Accept?"
                        cancelButtonLabel="Back"
                        handleCancel={() => {
                          setAcceptConfirmationDialog(false)
                        }}
                        handleSubmit={handleSubmitAccepted}
                      />
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 20px 10px 16px',
                            fontWeight: '600',
                            backgroundColor: '#FFFFFF',
                            fontStyle: 'normal',
                            fontSize: '15px',
                            lineHeight: '20px',
                            marginTop: '63px',
                          }}>
                          <div style={{display: 'flex', justifyItems: 'center'}}>
                            <TableInfo>
                              Service Order{' '}
                              <span style={{color: '#03a9fc'}}>#{serviceOrder.orderNumber}</span>
                            </TableInfo>
                            <TableInfo>Total Quantity ({totalQuantity?.toString()})</TableInfo>
                            {projectStatusSwitch()}
                          </div>
                          <div>
                            <Button
                              onClick={handleGoBack}
                              variant="outlined"
                              color="secondary"
                              style={{marginRight: '18px'}}>
                              Back
                            </Button>
                            {statusString === 'Submitting Order' ? (
                              <Button
                                onClick={() => setConfirmationDialog(true)}
                                type="button"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Submit
                              </Button>
                            ) : statusString === 'Processing Order' ||
                              statusString === 'Confirming Order' ||
                              statusString === 'Reviewing Order' ||
                              statusString === 'Filling Order' ? (
                              <Tooltip title="This order has been submitted and waiting to be filled.">
                                <span>
                                  <Button
                                    aria-label="This button is disabled because the order has been submitted and waiting to be filled."
                                    variant="contained"
                                    color="primary"
                                    disabled>
                                    Submit
                                  </Button>
                                </span>
                              </Tooltip>
                            ) : statusString === 'Filled' ? (
                              <>
                                <Button variant="outlined" style={{marginRight: '18px'}}>
                                  Reject
                                </Button>
                                <Button
                                  onClick={() => setAcceptConfirmationDialog(true)}
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  disabled={isSubmitting}>
                                  Accept
                                </Button>
                              </>
                            ) : null}
                          </div>
                        </Box>
                        <OrderDetailsTable values={values} serviceOrder={serviceOrder} />
                      </>
                    </ErrorBoundary>
                  )}
                </LoadingBoundary>
              </Container>
            </FormikForm>
          )
        }}
      </Formik>
    </>
  )
}
