import React from 'react'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'
import {nanoid} from 'nanoid/non-secure'
import OrderForm from '../job-orders/OrderForm'
import Title from '../../layout/Title'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useAppSelector} from '~/src/store/store-hooks'
import {useLocation} from 'react-router-dom'
import {createValuesFromTemplate} from '../../company/manage-orders/order-form/templateHelper'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'

interface IProps {
  onOpenOrderModal(): void
}

export const createOrderDistributions = () => ({
  orderQuantityDistributions: [],
})

export const createOrderItem = () => ({
  id: nanoid(),
  orderType: '',
  positionId: '',
  shift: '',
  onsiteFacility: '',
  departmentName: '',
  quantity: '',
  payRate: '',
  distributionStartDate: '',
  distributionEndDate: '',
  candidateDistribution: 'string',
  note: undefined,
  orderDistribution: createOrderDistributions(),
})

export default function AddServiceOrder({onOpenOrderModal}: IProps): React.ReactElement {
  const {currentUser} = useAppSelector((state) => state.root)

  const {data: companyData} = useGetOneCompanyQuery(currentUser ? currentUser.companyId : '')
  const agencyId = companyData?.agencyId

  const location = useLocation()
  const isTemplate = location.state?.isTemplate
  const isMsp = location.state?.isMsp

  const isMspTemplateId = location?.state?.id

  const templateValues = location.state

  const {data: position} = useGetPositionByIdQuery({
    id: templateValues?.orders[0].positionId,
  })

  let initialValues: IInitialServiceOrderValues = isTemplate
    ? {
        id: '',
        orderType: '',
        orderNumber: '',
        orderOwner: '',
        agencyId: agencyId ?? '',
        position: {
          positionName: position?.name ?? '',
          positionCompensationCodeName: position?.compensationCode ?? '',
          positionEndTime: position?.endTime ?? '',
          positionJobDescription: position?.jobDescription ?? '',
          positionManager: position?.manager ?? '',
          positionStartTime: position?.startTime ?? '',
        },
        facility: [
          {
            name: position?.facilityName,
            address: position?.facilityAddress.display,
          },
        ],
        departmentName: [{id: position?.departmentId, name: position?.departmentName}],
        orders: createValuesFromTemplate(templateValues),
      }
    : {
        position: {
          positionName: '',
          positionCompensationCodeName: '',
          positionEndTime: '',
          positionJobDescription: '',
          positionManager: '',
          positionStartTime: '',
        },
        orders: [createOrderItem()],
      }

  return (
    <>
      <Title pageTitle="New Service Order" />
      <OrderForm
        onOpenOrderModal={onOpenOrderModal}
        initialValues={initialValues}
        isMsp={isMsp}
        isMspTemplateId={isMspTemplateId}
        isTemplate={isTemplate}
        index={0}
      />
    </>
  )
}
