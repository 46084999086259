import {Box, Button, Typography} from '@mui/material'
import styled from 'styled-components'

export const HeaderBox = styled(Box)`
  display: flex;
  background: linear-gradient(180deg, #03a9fc 0%, #03cffc 100%);
  border-radius: 9px 9px 0px 0px;
  color: #fff;
  width: 100%;
  height: 186px;
  padding: 20px 32px;
  align-items: center;
  justify-content: space-between;
`
export const HeaderText = styled(Typography)`
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`

export const SubmitButton = styled(Button)`
  color: #1f1f1f;
  min-width: 248px;
  background: #ffb946;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  &:hover {
    color: #1f1f1f;
    background: #ffa525;
  }
  &.Mui-disabled {
    color: #6b6969;
    background: #f7d9a8;
  }
` as typeof Button

export const CardContainer = styled(Box)`
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
  margin-top: -54px;
  padding-left: 18px;
  padding-right: 18px;
`

export const Wrapper = styled(Box)`
  min-width: 1111px;
`
