import {createSlice, isAnyOf, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit'
import type {MemberAccess} from '~/src/models/memberAccess'
import type {TRootState} from '~/src/store'
import type {
  ICompanyTypeMaps,
  IRole,
  IUserBase as TFullUser,
  TPartialUser,
  TUserAttributes,
} from '../../models'
import {authApi} from '../apis/auth-api'
import {roleApi} from '../apis/role-api'
import {userApi} from '../apis/user-api'
import {getCognitoUserInfo as getPartialUser} from '../thunks/auth-thunks'
import {memberAccessApi} from './../apis/member-access-api'

export interface IRootState {
  userAttributes?: TUserAttributes
  oldPassword?: string
  redirectTo: string | null
  partialUser: TPartialUser | null
  currentUser: TFullUser | null
  onSignIn?: boolean
  role: IRole | null
  token: string | null
  appIsLoaded: boolean
  loadingUser?: boolean
  companyTypeMaps: ICompanyTypeMaps | null
  loggedInAgencyId: string
  gaMemberAccess?: MemberAccess
}

const tempRole = window.localStorage.getItem('cognitoRole') ?? 'null'

const initialState: IRootState = {
  appIsLoaded: false,
  currentUser: null,
  loadingUser: true,
  partialUser: null,
  redirectTo: null,
  token: null,
  companyTypeMaps: null,
  onSignIn: false,
  loggedInAgencyId: '',
  // !important we don't actually want to do this. Remove after demo.
  role: JSON.parse(tempRole),
}

const rootSlice = createSlice({
  name: 'rootSlice',
  initialState,
  reducers: {
    redirect: (state) => {
      state.redirectTo = null
    },
    updateUserSignIn: (state, {payload}) => {
      const {data, index} = payload
      state.onSignIn = data
    },
    updateLoggedInAgencyId: (state, {payload}) => {
      state.loggedInAgencyId = payload
    },
    updateCurrentUser: (state, {payload}) => {
      state.currentUser = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getPartialUser), (state, {payload}) => {
        state.partialUser = payload
        state.token = payload.token
      })
      .addMatcher(authApi.endpoints.signin.matchFulfilled, (state, {payload}) => {
        if ('oldPassword' in payload) {
          const {oldPassword, ...rest} = payload
          state.userAttributes = rest
          state.oldPassword = oldPassword
        }

        if ('token' in payload) {
          state.token = payload.token
        }
      })
      .addMatcher(authApi.endpoints.signout.matchFulfilled, (state) => {
        state.currentUser = null
      })
      .addMatcher(isRejected(getPartialUser), (state) => {
        state.appIsLoaded = true
      })
      .addMatcher(userApi.endpoints.getUserByEmail.matchFulfilled, (state, {payload}) => {
        state.appIsLoaded = true
      })
      .addMatcher(userApi.endpoints.getUserByEmail.matchRejected, (state) => {
        state.appIsLoaded = true
      })
      .addMatcher(roleApi.endpoints.getRole.matchFulfilled, (state, {payload}) => {
        state.role = payload.role
      })
      .addMatcher(
        isAnyOf(isPending(getPartialUser), userApi.endpoints.getUserByEmail.matchPending),
        (state) => {
          state.loadingUser = true
        },
      )
      .addMatcher(
        isAnyOf(
          isRejected(getPartialUser),
          isFulfilled(getPartialUser),
          userApi.endpoints.getUserByEmail.matchFulfilled,
          userApi.endpoints.getUserByEmail.matchRejected,
        ),
        (state) => {
          state.loadingUser = false
        },
      )
      .addMatcher(
        memberAccessApi.endpoints.getMemberAccessByMemberId.matchFulfilled,
        (state, {payload}) => {
          state.gaMemberAccess = payload
        },
      )
  },
})

export const {redirect, updateUserSignIn, updateLoggedInAgencyId, updateCurrentUser} = rootSlice.actions
export default rootSlice.reducer
export const selectRoot = (state: TRootState) => state.root
