import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, {useState} from 'react'
import styled from 'styled-components'
import {ReactComponent as XButton} from '~/src/assets/images/FlagCloseIcon.svg'
import {ReactComponent as GreenFlag} from '~/src/assets/images/GreenFlag.svg'
import {ReactComponent as RedFlag} from '~/src/assets/images/RedFlag.svg'
import {ReactComponent as YellowFlag} from '~/src/assets/images/YellowFlag.svg'
import {useUpdateApplicantFlagMutation} from '~/src/store/apis/applicants-api'
import ReusableSnackbar from '../../Snackbar/ReusableSnackbar'

const StyledMenuItem = styled(MenuItem)`
  height: 36px;
  font-weight: 600;
  font-size: 14px;
`

const ParenthesisText = styled('span')`
  font-weight: 400;
  font-size: 14px;
`

const CandidateFlagHeader = styled('div')`
  font-weight: 600;
  font-size: 13px;
  color: #8a8a8a;
`

const BoldWordMargin = styled('span')`
  margin-left: 13px;
  margin-right: 13px;
`

interface IProps {
  agencyId: string
  anchorEl: HTMLElement | null
  candidateId: string
  handleClose: () => void
  isOpen: boolean
}
export default function FlagDropdown({
  agencyId,
  anchorEl,
  candidateId,
  handleClose,
  isOpen,
}: IProps) {
  const [updateApplicantFlag] = useUpdateApplicantFlagMutation()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const handleSubmit = async (flag: string) => {
    await updateApplicantFlag({applicantId: candidateId, flag: flag, agencyId: agencyId})
      .unwrap()
      .then((payload) => handleClose())
      .catch((error) => setIsSnackbarOpen(true))
  }

  return (
    <div>
      <Menu
        sx={{minWidth: '285px', minHeight: '187px'}}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
        <StyledMenuItem
          sx={{
            height: '27px',
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'default',
            },
          }}>
          <CandidateFlagHeader>Candidate Flags</CandidateFlagHeader>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleSubmit('Do Not Use')}>
          <RedFlag />
          <BoldWordMargin>Red</BoldWordMargin>
          <ParenthesisText>(Do not use)</ParenthesisText>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleSubmit('Ready For Assignment')}>
          <GreenFlag />
          <BoldWordMargin>Green</BoldWordMargin>
          <ParenthesisText>(Ready for Assignment)</ParenthesisText>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleSubmit('Incomplete Application')}>
          <YellowFlag />
          <BoldWordMargin>Yellow</BoldWordMargin>
          <ParenthesisText>(Incomplete Application)</ParenthesisText>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleSubmit('No Flag')}>
          <XButton />
          <ParenthesisText style={{marginLeft: '13px'}}>Remove Flag</ParenthesisText>
        </StyledMenuItem>
      </Menu>
    </div>
  )
}
