import AddCircleIcon from '@mui/icons-material/AddCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {css, styled, useTheme} from '@mui/material/styles'
import {push} from 'connected-react-router'
import React from 'react'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useAppDispatch} from '~/src/store/store-hooks'

const ButtonsBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 16px;
    height: 82px;
    width: 260px;
  `,
) as typeof Box

const CustomizedButton = styled(Button)`
  &.MuiButton-root {
    font-size: '14px';
    color: #333333;
    font-weight: 400;
  }
` as typeof Button

interface IProps {
  sx?: object
  onEnter: () => void
  onLeave: () => void
}

export default function CreateOrderSplitButtons({sx, onEnter, onLeave}: IProps) {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  return (
    <div onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <ButtonsBox sx={{...sx}}>
        <CustomizedButton
          variant="text"
          fullWidth
          sx={{justifyContent: 'flex-start'}}
          startIcon={
            <AddCircleIcon
              style={{
                color: theme.palette.primary.main,
                position: 'relative',
                left: '4px',
                height: '20px',
                width: '20px',
              }}
            />
          }
          onClick={() => {
            dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.ADD_ORDER}`))
          }}>
          New Service Order
        </CustomizedButton>
        <CustomizedButton
          variant="text"
          fullWidth
          sx={{borderTop: '1px solid #f1f1f1', borderRadius: '0px', justifyContent: 'flex-start'}}
          startIcon={
            <AddCircleIcon
              style={{
                color: theme.palette.primary.main,
                position: 'relative',
                left: '4px',
                height: '20px',
                width: '20px',
              }}
            />
          }
          onClick={() => {
            dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`))
          }}>
          Starting from a Template
        </CustomizedButton>
      </ButtonsBox>
    </div>
  )
}
