import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import {push} from 'connected-react-router'
import {ErrorBoundary, LoadingBoundary} from '@ui/components'
import {TableInfo as S} from '~/src/components/common/styles/TabsStyles'
import {useGetUsersSortedByNameQuery} from '~/src/store/apis/user-api'
import EditMemberDialog from './EditMemberDialog'
import UserTable from '~/src/components/common/users-and-roles/UserTable'

import type {IUserBase} from '~/src/models'
import Title from '../../layout/Title'
import AddButton from '../../ui/buttons/AddButton'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes/constants'
import AddMemberDialog from '@ui/dialog/AddMemberDialog'

export default function GeneralAdmins(): React.ReactElement {
  const dispatch = useAppDispatch()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [targetUser, setTargetUser] = useState<IUserBase>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, isLoading, isFetching, refetch, error} = useGetUsersSortedByNameQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    role: 'general_admin',
    matchingRoles: true,
    agencyId: loggedInAgencyId,
  })

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  return (
    <S.Container>
      <Title
        pageTitle="General Admins"
        button={
          <AddButton
            onClick={() =>
              dispatch(
                push(
                  `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_ADMINS}${SIDEBAR_ROUTES.ADD_ADMIN}`,
                ),
              )
            }>
            Add New Member
          </AddButton>
        }
      />
      <ErrorBoundary error={error}>
        <S.Body>
          <LoadingBoundary isLoading={isLoading || isFetching}>
            {data && (
              <UserTable
                data={data}
                onOpenEditModal={(user) => {
                  setTargetUser(user)
                  dispatch(
                    push(
                      `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_ADMINS}${SIDEBAR_ROUTES.EDIT_ADMIN}`,
                      user,
                    ),
                  )
                }}
                currentPageNumber={currPageIdx}
                onPaginationChange={handlePaginationChange}
              />
            )}
          </LoadingBoundary>
        </S.Body>

        <AddMemberDialog
          modalOpen={addModalVisible}
          onModalClose={() => {
            setAddModalVisible(false)
          }}
        />

        <EditMemberDialog
          modalOpen={editModalVisible && Boolean(targetUser)}
          targetUser={targetUser}
          onModalClose={() => {
            setEditModalVisible(false)
            setTargetUser(undefined)
          }}
        />
      </ErrorBoundary>
    </S.Container>
  )
}
