import {createTheme, ThemeOptions} from '@mui/material/styles'
import {typography} from './font-faces'
import cssBaseline from './css-baseline'

const theme: Readonly<ThemeOptions> = {
  boxShadows: [
    'none',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
    '0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  ],
  breakpoints: {
    values: {
      lg: 1024,
      md: 720,
      sm: 414,
      xl: 1440,
      xs: 0,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
        },
        endAdornment: {
          '& .MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.23)',
          },
        },
        root: {
          '&:focus-within .MuiIconButton-root svg': {
            color: '#03A9FC',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          padding: '0.5rem 1rem',
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontWeight: 400,
          textTransform: 'none',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          '& svg': {
            color: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: cssBaseline,
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.23)',
          display: 'inline-block',
          fontSize: '0.6875rem',
          margin: 0,
          marginBlockEnd: 0,
          marginBlockStart: 0,
          textAlign: 'right',
          height: 0,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#242424',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-error': {
            outlineColor: '#FF6760',

            [`&.MuiInputBase-inputAdornedEnd,
              & .MuiIconButton-root svg,
               & .MuiOutlinedInput-input,
              & .MuiOutlinedInput-input::placeholder`]: {
              color: '#FF6760',
            },
          },
          //     color: 'rgba(0, 0, 0, 0.23)',
          //     fontSize: '0.6875rem',
          //     margin: 0,
          //     maxWidth: 'initial',
          //     transform: 'initial',
          //     transformOrigin: 'initial',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingBottom: 10,
          paddingTop: 10,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },

        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },

          '&.Mui-error': {
            outlineColor: '#FF6760',
            [`&.MuiInputBase-inputAdornedEnd,
              & .MuiIconButton-root svg,
               & .MuiOutlinedInput-input,
              & .MuiOutlinedInput-input::placeholder`]: {
              color: '#FF6760',
            },
          },
          // '&:focus-within': {
          //   backgroundColor: 'rgba(36, 36, 36, 0.02)',
          //   outlineColor: '#03A9FC',
          //   outlineWidth: 2,

          //   '& .MuiIconButton-root svg': {
          //     color: '#03A9FC',
          //   },
          // },
        },
      },
    },
    //  MUIRichTextEditor: {
    //     styleOverrides: {
    //       root: {
    //         border: '1px solid #C4C4C4',
    //         borderRadius: 4,
    //       },
    //       toolbar: {
    //         borderRadius: 4,
    //         borderBottom: '1px solid #C4C4C4',
    //         backgroundColor: '#F0F0F0',
    //         marginTop: -8,
    //         color: '#F0F0F0',
    //       },
    //       editor: {
    //         padding: 10,
    //         height: '300px',
    //         maxHeight: '300px',
    //         overflow: 'auto',
    //       },
    //     },
    //   },
    MuiPaginationItem: {
      styleOverrides: {
        rounded: {},
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {},
      },
    },
  },
  gradients: {
    primary: 'linear-gradient(156.23deg, #03A9FC 4.93%, #2b74b5 96.05%)',
  },
  palette: {
    action: {
      activatedOpacity: 0.24,
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selectedOpacity: 0.16,
    },
    background: {
      default: '#F5F6F8',
      paper: '#FFFFFF',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    contrastThreshold: 3,
    error: {
      '50': '#FFF0F1',
      '100': '#FFDBDE',
      '200': '#FFBDC2',
      '300': '#FF99A2',
      '400': '#FF7A86',
      '500': '#FF505F',
      '600': '#EB0014',
      '700': '#C70011',
      '800': '#94000D',
      '900': '#570007',
      contrastText: '#FFFFFF',
      dark: '#C70011',
      light: '#FF99A2',
      main: '#FF6760',
    },
    grey: {
      '50': '#FAFAFA',
      '100': '#F0F0F0',
      '200': '#EBEBEB',
      '300': '#E0E0E0',
      '400': '#C7C7C7',
      '500': '#B3B3B3',
      '600': '#949494',
      '700': '#8A8A8A',
      '800': '#616161',
      '900': '#424242',
      A100: '#3D3D3D',
      A200: '#292929',
      A400: '#1F1F1F',
      A700: '#0F0F0F',
    },
    info: {
      contrastText: '#000000',
      dark: '#FAFAFA',
      main: '#FFFFFF',
    },
    primary: {
      '50': '#F0F7FF',
      '100': '#C2E0FF',
      '200': '#99CCF3',
      '300': '#66B2FF',
      '400': '#3399FF',
      '500': '#007FFF',
      '600': '#0072E5',
      '700': '#0059B2',
      '800': '#004C99',
      '900': '#003A75',
      contrastText: '#FFFFFF',
      dark: '#005599',
      light: '#66B2FF',
      main: '#03A9FC',
    },
    secondary: {
      '50': '#FAFAFA',
      '100': '#F0F0F0',
      '200': '#EBEBEB',
      '300': '#E0E0E0',
      '400': '#C7C7C7',
      '500': '#B3B3B3',
      '600': '#949494',
      '700': '#8A8A8A',
      '800': '#616161',
      '900': '#424242',
      contrastText: '#FFFFFF',
      dark: '#0F0F0F',
      light: '#E0E0E0',
      main: '#242424',
    },
    success: {
      '50': '#E9FBF0',
      '100': '#C6F6D9',
      '200': '#9AEFBC',
      '300': '#6AE79C',
      '400': '#3EE07F',
      '500': '#21CC66',
      '600': '#1DB45A',
      '700': '#1AA251',
      '800': '#178D46',
      '900': '#0F5C2E',
      contrastText: '#FFFFFF',
      dark: '#0F5C2E',
      light: '#6AE79C',
      main: '#3DB429',
    },
    text: {
      disabled: '#E0E0E0',
      primary: '#424242',
      secondary: '#424242',
    },
    tonalOffset: 0.2,
    warning: {
      '50': '#FFF9EB',
      '100': '#FFF3C1',
      '200': '#FFECA1',
      '300': '#FFDC48',
      '400': '#F4C000',
      '500': '#DEA500',
      '600': '#D18E00',
      '700': '#AB6800',
      '800': '#8C5800',
      '900': '#5A3600',
      contrastText: '#FFFFFF',
      dark: '#AB6800',
      light: '#FFDC48',
      main: '#FFB946',
    },
  },
  resets: {
    anchor: {
      color: 'inherit',
      textDecoration: 'none',
    },
    common: {
      background: 'inherit',
      border: 'none',
      outline: 'none',
    },
    list: {
      listStyleType: 'none',
    },
  },
  shape: {
    borderRadius: 5,
  },
  sizes: {
    header: 70,
    icon: 15,
    maxWidth: 1111,
    sidebar: 256,
    sidebarCompact: 68,
    tableMaxWidth: 1111,
  },
  transitions: {
    duration: {
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
      short: 175,
      shorter: 150,
      shortest: 125,
      standard: 200,
    },
    easing: {
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  typography: {
    body1: {
      color: '#242424',
      fontFamily: typography.openSans,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.142857142857143,
    },
    body2: {
      color: '#242424',
      fontFamily: typography.openSans,
      fontSize: '0.6875rem',
      fontWeight: 400,
      lineHeight: 1.333333333333333,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5,
      textTransform: 'initial',
    },
    caption: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.125,
    },
    fontFamily: typography.openSans,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontFamily: typography.openSans,
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.354166666666667,
    },
    h2: {
      fontFamily: typography.openSans,
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.375,
    },
    h3: {
      fontFamily: typography.openSans,
      fontSize: '1.75rem',
      fontWeight: 400,
      lineHeight: 1.357142857142857,
    },
    h4: {
      fontFamily: typography.openSans,
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.375,
    },
    h5: {
      fontFamily: typography.openSans,
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 1.357142857142857,
    },
    h6: undefined,
    htmlFontSize: 16,
    overline: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
  },
  unstable_strictMode: true,
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    mobileStepper: 1000,
    modal: 1300,
    snackbar: 1400,
    speedDial: 1050,
    tooltip: 1500,
  },
}

export const muiTheme = createTheme(theme as ThemeOptions)
