import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import React, {PropsWithChildren} from 'react'

const FieldGroupContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

const FieldGroupLabel = styled('div')`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
`

const Red = styled('span')`
  color: ${(props) => props.theme.palette.error.main};
`

interface IProps {
  label: string
  sx?: object
  isOptional?: boolean
}

export default function FieldGroup({
  label,
  sx = {},
  isOptional = false,
  children,
}: PropsWithChildren<IProps>) {
  return (
    <FieldGroupContainer sx={sx}>
      <FieldGroupLabel>
        {label}
        {isOptional ? ' (Optional)' : <Red>*</Red>}
      </FieldGroupLabel>
      <div>{children}</div>
    </FieldGroupContainer>
  )
}
