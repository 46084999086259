import type {AutocompleteProps} from '@mui/material'
import MuiIcon from '@mui/material/Icon'
import Icon from '@ui/icons'
import React from 'react'
import StyledAutocomplete from './StyledAutocomplete'

export interface IStandardAutocompleteGenericProps<T, K extends boolean = true, L extends boolean = true>
  extends AutocompleteProps<T, K, L, true> {
  name?: string
  popupIconId?: string
  onTextFieldChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  textValueOverride?: string
}

const DEFAULT_POPUP_ICON_ID = 'arrowhead'

export default function StandardAutocompleteGeneric<T, K extends boolean = true, L extends boolean = true>({
  name,
  popupIconId = DEFAULT_POPUP_ICON_ID,
  fullWidth = true,
  onTextFieldChange,
  error,
  helperText,
  textValueOverride,
  sx,
  ...props
}: IStandardAutocompleteGenericProps<T, K, L>) {
  const iconFlipLogic: object =
    popupIconId === DEFAULT_POPUP_ICON_ID
      ? {}
      : {'& .MuiAutocomplete-popupIndicator': {transform: 'none'}}
  return (
    <StyledAutocomplete
      sx={{...iconFlipLogic, ...sx}}
      isOptionEqualToValue={(option, value) => option === value}
      clearIcon={
        <MuiIcon>
          <Icon icon="close" />
        </MuiIcon>
      }
      popupIcon={
        <MuiIcon>
          <Icon icon={popupIconId} />
        </MuiIcon>
      }
      fullWidth={fullWidth}
      forcePopupIcon
      {...props}
    />
  )
}
