import {Alert, Snackbar} from '@mui/material'
import Box from '@mui/material/Box'
import CandidateInventory from '@ui/candidate-list/CandidateInventory'
import JobOrderCard from '@ui/card/JobOrderCard'
import MspCompanyDetailsSummaryCard from '@ui/card/MspCompanyDetailsSummaryCard'
import MspPositionBreakdownSummaryCard from '@ui/card/MspPositionBreakdownSummaryCard'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {Applicant} from '~/src/models/applicant'
import type {Candidate} from '~/src/models/jobOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useLazyGetApplicantListByIdsQuery} from '~/src/store/apis/applicants-api'
import {
  useAddCandidatesToJobOrderMutation,
  useGetJobOrderQuery,
} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetPrefillQuery} from '~/src/store/apis/prefill-api'
import {useAppSelector} from '~/src/store/store-hooks'
import {colors} from '../../../../config/theme'

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;

    margin: auto;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

const TitleContainer = styled(Box)`
  display: flex
  flex-direction: row;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
`

const JobOrderNumberContainer = styled('div')`
  font-size: 24px
  font-weight: 300;
  display: flex;
  margin: auto
  max-width: 1111px;
  width: 1111px;
  flex-irection: row;
`

const JobOrderCardContainer = styled('div')`
  display: flex;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
  flex-direction: row;
  justify-content: space-between;
`

export const CompanyDetailsContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const PositionBreakdownContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

const CandidateDetailsContainer = styled('div')`
  display: flex;
  max-width: 1111px;
  width: 1111px;
  margin: auto;
  flex-direction: row;
  margin-top: 42px;
`

interface UrlParams {
  jobOrderId: string
  prefillId: string
}

export default function JobOrderFromPrefill() {
  const {jobOrderId, prefillId}: UrlParams = useParams()
  const [addedCandidates, setAddedCandidates] = useState<Applicant[]>([])
  const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>([])
  const [openError, setOpenError] = useState(false)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [isAddConfirmationDialogOpen, setIsAddConfirmationDialogOpen] = useState(false)

  const [getApplicantsFromIds, {error}] = useLazyGetApplicantListByIdsQuery()
  const [addCandidatesToJobOrder, {error: addingError}] = useAddCandidatesToJobOrderMutation()
  const dispatch = useDispatch()

  const {
    data: jobOrderData,
    refetch: jobOrderRefetch,
    isLoading,
  } = useGetJobOrderQuery({id: jobOrderId})

  const {data: prefillData, refetch: prefillRefetch} = useGetPrefillQuery({
    id: prefillId,
    agencyId: loggedInAgencyId,
  })

  const {data: positionData, refetch: positionRefetch} = useGetPositionByIdQuery({
    id: jobOrderData ? jobOrderData.positionId : '',
  })

  const isFilled =
    jobOrderData?.agencyDistributions
      .map((item) => item.agencyStatus)
      .find((item) => item !== 'Filled') === undefined

  const getDate = (date: string | undefined) => {
    if (date) {
      return new Date(date).toLocaleTimeString('En-US', {
        hour12: true,
        hour: 'numeric',
      })
    }
  }

  let totalCandidatesNeeded: number = 0
  let totalCandidatesLeft: number = 0
  let totalCandidatesFilled: number = 0

  if (jobOrderData) {
    totalCandidatesNeeded = jobOrderData.agencyDistributions
      .map((distribution) => distribution.agencyCandidateDistributions.requested)
      .reduce((prev: number, curr: number) => prev + curr, 0)

    totalCandidatesLeft = jobOrderData.agencyDistributions
      .map((distribution) => distribution.agencyCandidateDistributions.fulfilled)
      .reduce((prev: number, curr: number) => totalCandidatesNeeded - curr, 0)

    totalCandidatesFilled = jobOrderData.agencyDistributions
      .map((distribution) => distribution.agencyCandidateDistributions.fulfilled)
      .reduce((prev: number, curr: number) => prev + curr, 0)
  }

  useEffect(() => {
    void jobOrderRefetch()
    void positionRefetch()
  }, [jobOrderData])

  const handleAddPrefillToJobOrder = async () => {
    setIsAddConfirmationDialogOpen(false)
    try {
      void (await addCandidatesToJobOrder({
        jobOrderId,
        candidateIds: selectedCandidateIds,
        agencyId: loggedInAgencyId,
      }).unwrap())
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${
            SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
          }/${encodeURIComponent(jobOrderId)}`,
        ),
      )
    } catch (err: unknown) {
      setOpenError(true)
    }
  }

  const getAddedCandidates = async () => {
    if (prefillData?.candidateList.length) {
      const addedIds: string[] = []
      prefillData.candidateList.forEach((candidate: Candidate) => {
        if (candidate.candidateStatus !== 'Skipped' && candidate.candidateStatus !== 'Declined') {
          addedIds.push(candidate.candidateId)
        }
      })

      const added = await getApplicantsFromIds({
        applicantIds: addedIds,
        agencyId: loggedInAgencyId,
      }).unwrap()

      setAddedCandidates(added)
    }
  }

  useEffect(() => {
    void getAddedCandidates()
  }, [prefillData])

  const handleCloseConfirmation = () => {
    setIsAddConfirmationDialogOpen(false)
  }

  const handleOpenConfirmDialog = (ids?: string[]) => {
    setSelectedCandidateIds(ids ?? [])
    setIsAddConfirmationDialogOpen(true)
  }

  return (
    <LoadingBoundary isLoading={isLoading}>
      <Snackbar
        open={openError}
        autoHideDuration={10000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={() => setOpenError(false)} severity="error" sx={{width: '100%'}}>
          {addingError?.data.message}
        </Alert>
      </Snackbar>
      <BasicConfirmationDialog
        isOpen={isAddConfirmationDialogOpen}
        header="Candidates will be transfered to the Job Order"
        bigBlurb="Move Candidates To Job Order"
        smallBlurb=""
        handleCancel={handleCloseConfirmation}
        handleSubmit={handleAddPrefillToJobOrder}
      />
      <AgencyOrderDetailsStyles.Container>
        <TitleContainer>
          <JobOrderNumberContainer>
            <h2 style={{marginBottom: 20}}>Select Candidates For Job Order</h2>
          </JobOrderNumberContainer>
        </TitleContainer>
        <ErrorBoundary>
          <LoadingBoundary>
            <>
              <Box>
                <JobOrderCardContainer>
                  <div>
                    {jobOrderData && (
                      <JobOrderCard
                        onListIconClick={() => {
                          /**no op*/
                        }}
                        jobOrder={jobOrderData}
                        currentlyfilled={totalCandidatesFilled}
                        totalrequested={totalCandidatesNeeded}
                      />
                    )}
                  </div>
                  <CompanyDetailsContainer>
                    {jobOrderData && positionData && (
                      <MspCompanyDetailsSummaryCard
                        location={positionData.facilityAddress.display}
                        endTime={getDate(positionData.endTime)}
                        position={jobOrderData.positionId}
                        positionName={positionData.name}
                        startTime={getDate(positionData.startTime)}
                        manager={positionData.manager}
                      />
                    )}
                  </CompanyDetailsContainer>
                  <PositionBreakdownContainer>
                    {jobOrderData && positionData && (
                      <MspPositionBreakdownSummaryCard
                        JobOrderData={jobOrderData}
                        totalCandidatesNeeded={totalCandidatesNeeded}
                        totalCandidatesLeft={totalCandidatesLeft}
                        positionData={positionData}
                      />
                    )}
                  </PositionBreakdownContainer>
                </JobOrderCardContainer>
                <CandidateDetailsContainer>
                  {prefillData && prefillData.candidateList.length > 0 ? (
                    <CandidateInventory
                      candidates={addedCandidates}
                      agencyId={loggedInAgencyId}
                      isFilledOrReady={isFilled}
                      onSubmit={handleOpenConfirmDialog}
                      isMoving={true}
                    />
                  ) : null}
                </CandidateDetailsContainer>
              </Box>
            </>
          </LoadingBoundary>
        </ErrorBoundary>
      </AgencyOrderDetailsStyles.Container>
    </LoadingBoundary>
  )
}
