import {Alert, Snackbar} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {push} from 'connected-react-router'
import React, {useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import type {IServiceOrderTemplate, TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useAppDispatch} from '~/src/store/store-hooks'
import PrependedTemplateHeader from './PrependedTemplateHeader'

const CreateButton = styled.button`
  border: 1px solid #03a9fc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #005599;
  width: 96px;
  height: 29px;
`

interface TemplateTableProps {
  data: TServiceOrderTemplatePayload
  currentPageNumber: number
  onPaginationChange(value: number): void
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Template Name',
    align: 'left',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: true,
    label: 'Created By',
    align: 'left',
  },
  {
    id: 'editedBy',
    numeric: true,
    disablePadding: true,
    label: 'Edited By',
    align: 'left',
  },
  {
    id: 'client',
    numeric: true,
    disablePadding: true,
    label: 'Client',
    align: 'left',
  },
  {
    id: 'createOrder',
    numeric: true,
    disablePadding: true,
    label: 'Create Order',
    align: 'center',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{height: 65}}>
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function PositionRow(props: string | number | boolean | object | any): JSX.Element {
  const dispatch = useAppDispatch()
  const {data: companyData} = useGetCompanyNameByIdQuery({
    id: props.row.companyId,
  })

  const getEditedByValue = (date: string) => {
    return date === '0001-01-01T00:00:00Z' || date === undefined || date === null
      ? '---'
      : new Date(date).toLocaleDateString()
  }

  return (
    <>
      {props.row && (
        <>
          {props.filterCompanyName === 'Select Company' ? (
            <>
              <TableRow
                key={props.index}
                sx={{textDecoration: 'none', height: 65}}
                datatest-id="table-row"
                hover
                tabIndex={-1}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  const target = event.target as HTMLButtonElement
                  if (target.id === 'createOrder') {
                    event.preventDefault()
                  } else {
                    dispatch(
                      push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.TEMPLATE_DETAILS}`, {
                        currentPageNumber: 1,
                        compName: companyData,
                        templateData: props.row,
                      }),
                    )
                  }
                }}>
                <StyledTableCell />
                <StyledTableCell
                  component="th"
                  textDecoration="none"
                  scope="row"
                  padding="normal"
                  sx={{maxWidth: '200px'}}>
                  {props.row.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {props.row.createdBy}
                  <br />
                  <span style={{fontWeight: 300}}>{getEditedByValue(props.row.createdDate)}</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {props.row.modifiedBy ? props.row.modifiedBy : '---'}
                  <br />
                  <span style={{fontWeight: 300}}>{getEditedByValue(props.row.modifiedDate)}</span>
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  textDecoration="none"
                  scope="row"
                  padding="normal"
                  sx={{maxWidth: '200px'}}>
                  {companyData}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CreateButton
                    value={props.row.id}
                    id="createOrder"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      dispatch(
                        push(`${BASE_ROUTES.AGENCY_ADMIN}/manage-orders/add-order`, {
                          ...props.row,
                          isTemplate: true,
                          isMsp: true,
                        }),
                      )
                    }}>
                    Create Order
                  </CreateButton>
                </StyledTableCell>
              </TableRow>
            </>
          ) : (
            <>
              {props.filterCompanyName === companyData ? (
                <TableRow
                  key={props.index}
                  sx={{textDecoration: 'none', height: 65}}
                  datatest-id="table-row"
                  hover
                  tabIndex={-1}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    const target = event.target as HTMLButtonElement
                    if (target.id === 'createOrder') {
                      event.preventDefault()
                    } else {
                      dispatch(
                        push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.TEMPLATE_DETAILS}`, {
                          currentPageNumber: 1,
                          compName: props.filterCompanyName,
                          templateData: props.row,
                        }),
                      )
                    }
                  }}>
                  <StyledTableCell />
                  <StyledTableCell
                    component="th"
                    textDecoration="none"
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    {props.row.name}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {props.row.createdBy}
                    <br />
                    <span style={{fontWeight: 300}}>{getEditedByValue(props.row.createdDate)}</span>
                  </StyledTableCell>

                  <StyledTableCell lign="left">
                    {props.row.modifiedBy}
                    <br />
                    <span style={{fontWeight: 300}}>
                      {getEditedByValue(props.row.modifiedDate)}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    textDecoration="none"
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    {companyData}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <NavLink
                      to={{
                        pathname: 'manage-orders/add-order',
                        state: {...props.row, isTemplate: true, isMsp: true},
                      }}>
                      <CreateButton
                        id="createOrder"
                        data-testid="Create Order"
                        value={props.row.id}>
                        Create Order
                      </CreateButton>
                    </NavLink>
                  </StyledTableCell>
                </TableRow>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default function ManageTemplatesTable({
  data,
  currentPageNumber,
  onPaginationChange,
}: TemplateTableProps) {
  const [selectedCompany, setSelectedCompany] = React.useState('Select Company')
  const [positionsFiltered, setPositionsFiltered] = React.useState<
    IServiceOrderTemplate[] | undefined
  >()
  const location = useLocation()
  const dispatch = useAppDispatch()

  interface IState {
    state: {
      data: IServiceOrderTemplate
      isEdit: Boolean
    }
  }

  const locationState = location?.state as IState
  const [open, setOpen] = useState(
    locationState?.state?.openSnackbar ? locationState?.state?.openSnackbar : false,
  )

  const handleCompanyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyId = event.target.value
    setSelectedCompany(companyId)
    const positionsFilteredByCompany = data?.serviceOrderTemplatesModel.filter(
      (position) => position.companyId === companyId,
    )

    if (positionsFilteredByCompany?.length) {
      setPositionsFiltered(positionsFilteredByCompany)
    } else {
      setPositionsFiltered(undefined)
    }
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`))
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          border: '1px solid #CDCED9',
          borderRadius: '4px',
        }}>
        <PrependedTemplateHeader
          data={data}
          onCompanySelection={handleCompanyFilter}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          {locationState?.state.isEdit ? (
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              Success! The Template {locationState?.state.name} has been Edited!
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              Success! The Template {locationState?.state.name} has been Created!
            </Alert>
          )}
        </Snackbar>
        <TableContainer>
          <Table
            sx={{minWidth: 750, height: 65}}
            aria-labelledby="tableTitle"
            size="small"
            padding="normal">
            <EnhancedTableHead />
            <TableBody>
              {data?.serviceOrderTemplatesModel?.map((row, index) => {
                return <PositionRow key={index} row={row} filterCompanyName={selectedCompany} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledPagination
          page={currentPageNumber}
          totalRecords={data?.totalServiceOrderTemplates}
          recordsPerPage={10}
          onChange={onPaginationChange}
        />
      </Box>
    </>
  )
}
