import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import {styled, useTheme} from '@mui/material/styles'
import React from 'react'

const StyledButton = styled(Button)({
  width: '120px',
  fontWeight: 'normal',
})

const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

const Body = styled(Box)({
  maxWidth: 1600,
  m: '5px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
})

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const BigBlurb = styled('div')({
  display: 'flex',
  justifyContent: 'center',

  fontSize: '28px',
  fontWeight: 600,
  marginBottom: '50px',
})

const SmallBlurb = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  textAlign: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
  marginTop: '30px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const PleaseReviewP1Text = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
  marginRight: '100px',
  marginLeft: '100px',
})

const PleaseReviewP2Text = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
})

const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '50px 5px 75px 5px',
  justifyContent: 'center',
})

interface IProps {
  isOpen: boolean
  handleCancel(): void
  handleSubmit(): void
  header?: string
  bigBlurb?: string
  bigBlurbStyle?: object
  smallBlurb?: string
  smallBlurbStyle?: object
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  switchOffBottom?: boolean
}

export default function BasicConfirmationDialog({
  isOpen,
  handleCancel,
  handleSubmit,
  header = '',
  bigBlurb = 'Save Changes',
  bigBlurbStyle,
  smallBlurb = 'Are you sure you want to save these changes?',
  smallBlurbStyle,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  switchOffBottom = false,
}: IProps): React.ReactElement {
  const theme = useTheme()
  return (
    <Dialog
      fullWidth
      open={isOpen}
      PaperProps={{
        sx: {minWidth: theme.breakpoints.values.md, width: 1600, height: 500},
      }}>
      <HeaderContainer>
        <HeaderText>{header}</HeaderText>
        <IconButton
          aria-label="close"
          size="large"
          onClick={handleCancel}
          style={{position: 'relative', right: '-10px'}}>
          <CloseIcon style={{color: theme.palette.grey[900]}} />
        </IconButton>
      </HeaderContainer>
      <Body>
        <TextBox>
          <BigBlurb style={{...bigBlurbStyle}}>{bigBlurb}</BigBlurb>
          {smallBlurb && <SmallBlurb style={{...smallBlurbStyle}}>{smallBlurb}</SmallBlurb>}
          {!switchOffBottom && (
            <>
              <PleaseReviewP1Text>
                Please <span style={{fontWeight: 400, color: 'black'}}>&nbsp;review&nbsp;</span> the
                contents and make sure everything
              </PleaseReviewP1Text>
              <PleaseReviewP2Text>{`is correct, once done click “${confirmButtonLabel}”.`}</PleaseReviewP2Text>
            </>
          )}
        </TextBox>
        <ButtonBox>
          <StyledButton
            style={{marginRight: '50px'}}
            onClick={handleCancel}
            variant="outlined"
            color="secondary"
            type="button">
            {cancelButtonLabel}
          </StyledButton>
          <StyledButton onClick={handleSubmit} variant="contained" color="primary" type="button">
            {confirmButtonLabel}
          </StyledButton>
        </ButtonBox>
      </Body>
    </Dialog>
  )
}
