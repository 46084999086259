import styled from 'styled-components'
import Button from '@mui/material/Button'

export const StyledButton = styled(Button)({
  width: '120px',
  fontWeight: 'normal',
}) as typeof Button

export const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

export const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

export const Body = styled('div')({
  m: '5px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '30px 60px',
})

export const TextFieldLabel = styled('div')`
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`

export const IDLabel = styled('div')`
  color: #949494;
  font-size: 12px;
  font-weight: 400;
`

export const ButtonBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '30px 5px 0px 5px',
  justifyContent: 'center',
})

export const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  overflow: scroll;
  margin-top: 30px;
  margin-bottom: 30px;
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

export const ListItem = styled('div')`
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 10px;
`

export const ListItemText = styled('div')`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
`

export const SaveToContainer = styled('div')`
  width: 528px;
  height: 95px;
  border-radius: 4px;
  background: #f6f6f6;
`

export const SaveToContainerMargin = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-bottom: 20px;
`

export const SaveToContainerText = styled('div')`
  display: flex;
  color: #333;
  font-size: 14px;
  font-weight: 400;
`

export const SaveToContainerSpaceBetween = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
`

export const DeleteFileContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
`
