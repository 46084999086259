import {makeStyles} from '@mui/styles'
import {headerArray} from '@utils/candidateHeaderDisplay'
import {errorFunction} from '@utils/errorFunction'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {Applicant} from '~/src/models/applicant'
import {useUpdateApplicantMutation} from '~/src/store/apis/applicants-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {selectApplicantsState} from '~/src/store/slices/applicants-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import DocumentsMain from '../../common/Documents/DocumentsMain'
import DataCleanupForm from '../../msp/manage-data-cleanup/DataCleanupForm'
import JobOrderAgreements from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderAgreements'
import JobOrderInterview from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderInterview'
import JobOrderSummaryActivity from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderSummaryActivity'
import JobOrderSummaryAssignmentHistory from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderSummaryAssignmentHistory'
import JobOrderSummaryEvents from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderSummaryEvents'
import JobOrderSummaryProfile from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderSummaryProfile'
import JobOrderSummarySkills from '../../msp/manage-job-orders/job-order-summary/msp-job-order-information/JobOrderSummarySkills'
import ReusableSnackbar from '../../Snackbar/ReusableSnackbar'
import {
  CandidateContainerMargin,
  DashboardMainStyles,
  DataCleanupContainer,
  FlexContainer,
  HeaderContainer,
  HeaderText,
  InnerButtonPillHeaderContainer,
  InnerDataHeaderContainer,
  InnerHeaderContainer,
} from './styles/CandidateInformationStyles'

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const useStyles = makeStyles({
  state: {
    background: '#EBEBEB',
    border: '1px solid #F1F1F1',
    borderRadius: '6px',
    padding: 2,
  },
})
export const createAssignmentItem = {
  jobTitle: '',
  customerName: '',
  assignmentID: '',
  originalStartDate: null,
  endDate: null,
  description: '',
}
interface IParams {
  id: string
}
interface IProps {
  candidates: Partial<Applicant>[] | undefined
  candidateSelected: string
  candidateLoadingStatuses?: {isLoading: boolean; isFetching: boolean; isSuccess: boolean}
  refetchCandidates?: () => void
  isDataCleanup: boolean
  onConfirmContact: () => void
  onConfirmAvailability: () => void
  onConfirmSkills: () => void
}

const CandidateInformation = ({
  candidates,
  candidateSelected,
  isDataCleanup,
  onConfirmContact,
  onConfirmAvailability,
  onConfirmSkills,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
}: IProps) => {
  const classes = useStyles()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const {currentUser, loggedInAgencyId} = useAppSelector((state) => state.root)
  const {id}: IParams = useParams()
  const [candidateHeaderSelected, setCandidateHeaderSelected] = useState(
    isDataCleanup ? 'dataCleanUp' : 'profile',
  )
  const [selectedCand, setSelectedCand] = useState(candidateSelected)
  const oldSelectedCand = useRef(selectedCand)
  const {applicant: singleApplicant} = useAppSelector(selectApplicantsState)
  const [updateApplicant] = useUpdateApplicantMutation()

  const handleClick = (value: string) => {
    setCandidateHeaderSelected(value)
  }

  const [enablelizeBooleanState, setEnablelizeBooleanState] = useState(false)

  const setLoadingContext = useContext(LoadingContext)

  useEffect(() => {
    if (JSON.stringify(oldSelectedCand.current) !== JSON.stringify(selectedCand)) {
      return setEnablelizeBooleanState(true)
    }
  }, [candidateSelected, selectedCand, singleApplicant?.id])

  const getTabDisplay = (
    applicantFromFormik: Applicant,
    handleSubmit: (values: Applicant) => Promise<void>,
    refetchCandidatesCallback: () => void,
    candidateLoadingInfo?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean},
  ) => {
    if (singleApplicant) {
      switch (candidateHeaderSelected) {
        case 'activity':
          return (
            <JobOrderSummaryActivity
              handleSubmit={handleSubmit}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'interview':
          return (
            <JobOrderInterview
              applicantFromFormik={applicantFromFormik}
              selectedApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'assignmentHistory':
          return (
            <JobOrderSummaryAssignmentHistory
              handleSubmit={handleSubmit}
              singleApplicantRefetch={() => {}}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'events':
          return (
            <JobOrderSummaryEvents
              handleSubmit={handleSubmit}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'skills':
          return (
            <JobOrderSummarySkills
              handleSubmit={handleSubmit}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'agreements':
          return (
            <JobOrderAgreements
              handleSubmit={handleSubmit}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )

        case 'dataCleanUp':
          return (
            <DataCleanupForm
              oldApplicant={singleApplicant}
              onConfirmContact={onConfirmContact}
              onConfirmAvailability={onConfirmAvailability}
              onConfirmSkills={onConfirmSkills}
            />
          )

        case 'documents':
          return <DocumentsMain singleApplicant={singleApplicant} />

        default:
          return (
            <JobOrderSummaryProfile
              handleSubmit={handleSubmit}
              oldApplicant={singleApplicant}
              candidateLoadingStatuses={candidateLoadingInfo}
              refetchCandidates={refetchCandidatesCallback}
            />
          )
      }
    }
  }

  const handleSubmit = async (values: Applicant) => {
    const newWorkHistoryValues = values.workHistories.map((i) => {
      return {
        ...i,
        city: i?.city?.length > 0 ? i.city : i.address.city,
        state: i?.state?.length > 0 ? i.state : i.address.state,
        reasonForLeaving: i?.reasonForLeaving?.length > 0 ? i.reasonForLeaving : undefined,
        endDate: i?.endDate ? i.endDate : undefined,
        startingPay: i?.startingPay > 0 ? i.startingPay : undefined,
        endingPay: i?.endingPay > 0 ? i.endingPay : undefined,
        startingPayFrequency:
          i?.startingPayFrequency?.length > 0 ? i.startingPayFrequency : undefined,
        endingPayFrequency: i?.endingPayFrequency?.length > 0 ? i.endingPayFrequency : undefined,
      }
    })

    if (values.assignments[0]?.jobTitle?.length === 0) {
      const convertedAssignments = values.assignments.slice(1)
      const parameters = {
        ...values,
        dateofBirth: values?.dateofBirth === null ? '' : values.dateofBirth,
        workHistories: newWorkHistoryValues,
        education: values.education.map((i) => {
          return {
            degreeType: i.degreeType,
            nameOfSchool: i.nameOfSchool,
            graduationDate: i.graduationDate,
            city: i?.city?.length > 0 ? i.city : i.address.city,
            state: i?.state?.length > 0 ? i.state : i.address.state,
          }
        }),
        certifications: values.certifications,
        assignments: convertedAssignments.map((i) => {
          return {
            ...i,
            description: i?.description?.length > 0 ? i.description : undefined,
          }
        }),
      }

      await updateApplicant({applicant: parameters})
        .unwrap()
        .then((res) => {
          setLoadingContext.setNewIsLoading(true)
        })
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    } else {
      const newAssignmentValues = values?.assignments.map((i) => {
        return {
          ...i,
          description: i?.description?.length > 0 ? i.description : undefined,
        }
      })

      const newEducationValues = values.education.map((i) => {
        return {
          ...i,
          city: i?.city?.length > 0 ? i.city : i.address.city,
          state: i?.state?.length > 0 ? i.state : i.address.state,
        }
      })

      const newParams = {
        ...values,
        dateofBirth: values?.dateofBirth === null ? '' : values.dateofBirth,
        assignments: newAssignmentValues,
        workHistories: newWorkHistoryValues,

        education: newEducationValues,
        certifications: values.certifications,
      }

      await updateApplicant({applicant: newParams})
        .unwrap()
        .then((res) => {
          setLoadingContext.setNewIsLoading(true)
        })
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    }
  }

  useEffect(() => {
    setSelectedCand(candidateSelected)
  }, [singleApplicant, candidateSelected, selectedCand])

  useEffect(() => {
    setCandidateHeaderSelected(isDataCleanup ? 'dataCleanUp' : 'profile')
  }, [singleApplicant?.id])

  const initialFormValues: Applicant = singleApplicant && {
    ...singleApplicant,
  }

  const headerWidthSwitch = (value: string) => {
    switch (value) {
      case 'Skills':
        return '50px'
      case 'Interview':
        return '68px'
      case 'Activity':
        return '69px'
      case 'Events':
        return '69px'
      case 'Assignment History':
        return '120px'
      case 'Agreements':
        return '81px'
      case 'Documents':
        return '81px'
      default:
        return '70px'
    }
  }

  return (
    <>
      <DashboardMainStyles.Container>
        <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
        {singleApplicant && (
          <CandidateContainerMargin>
            <FlexContainer>
              {isDataCleanup ? (
                <DataCleanupContainer>
                  <HeaderText
                    style={{width: '100%'}}
                    $candidateHeaderSelected={candidateHeaderSelected === 'dataCleanUp'}
                    onClick={() => handleClick('dataCleanUp')}>
                    <InnerDataHeaderContainer>
                      <div
                        aria-label="Data Clean-up Requirements"
                        className={candidateHeaderSelected === 'dataCleanUp' ? classes.state : ''}>
                        Clean-up
                        <Red>*</Red>
                      </div>
                    </InnerDataHeaderContainer>
                  </HeaderText>
                </DataCleanupContainer>
              ) : null}
              <HeaderContainer>
                <InnerHeaderContainer>
                  {headerArray.map((displayInfo, displayInfoIndex) => {
                    return (
                      <InnerButtonPillHeaderContainer
                        key={displayInfoIndex}
                        onClick={() => handleClick(displayInfo.stateValue)}
                        style={{
                          width: headerWidthSwitch(displayInfo.displayValue),
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                        className={
                          candidateHeaderSelected === displayInfo.stateValue ? classes.state : ''
                        }>
                        <HeaderText
                          aria-label={`${displayInfo.displayValue}-header-option`}
                          data-testid={`${displayInfo.displayValue}-header-option`}
                          $candidateHeaderSelected={
                            candidateHeaderSelected === displayInfo.stateValue
                          }>
                          {displayInfo.displayValue}
                        </HeaderText>
                      </InnerButtonPillHeaderContainer>
                    )
                  })}
                </InnerHeaderContainer>
              </HeaderContainer>
            </FlexContainer>

            <div>
              {getTabDisplay(
                initialFormValues,
                handleSubmit,
                refetchCandidates,
                candidateLoadingStatuses,
              )}
            </div>
          </CandidateContainerMargin>
        )}
      </DashboardMainStyles.Container>
    </>
  )
}
export default React.memo(CandidateInformation)
