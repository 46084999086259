import {Tooltip} from '@mui/material'
import {newTimezoneDateFunction} from '@utils/TimezoneFunction'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  FlexSpaceBetweenContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileDetailsText,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string): void
  values: Applicant
}

export default function MspCandidateProfileContactDisplay({handleClick, values}: IProps) {
  const contactPhoneNumber = values?.contactCellPhone?.length > 1 ? 'Cell Phone' : 'Home Phone'

  const formattedDob =
    values?.dateofBirth?.length > 0 ? newTimezoneDateFunction(values.dateofBirth) : undefined

  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Contact Information</InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer style={{display: 'flex', flexDirection: 'column'}}>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText style={{width: '209px'}}>
                First Name
                <InformationCardProfileDetailsText
                  aria-label={`firstName-text-${values.firstName}`}
                  data-testid={`firstName-text-${values.firstName}`}
                  style={{
                    maxWidth: 209,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {values.firstName}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText style={{width: '300px'}}>
                Last Name
                <InformationCardProfileDetailsText
                  aria-label={`lastName-text-${values.lastName}`}
                  data-testid={`lastName-text-${values.lastName}`}
                  style={{
                    maxWidth: '151px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {values.lastName}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>

              <InformationCardProfileHeaderText style={{width: '151px'}}>
                Date of Birth
                <InformationCardProfileDetailsText
                  aria-label={`dob-text-${formattedDob}`}
                  data-testid={`dob-text-${formattedDob}`}>
                  {formattedDob}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>

            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText style={{width: '209px'}}>
                Email
                <InformationCardProfileDetailsText
                  aria-label={`email-text-${values.contactEmail}`}
                  data-testid={`email-text-${values.contactEmail}`}
                  style={{
                    maxWidth: 209,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {values.contactEmail}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText style={{width: '300px'}}>
                Location
                <Tooltip title={values.address.display}>
                  <InformationCardProfileDetailsText
                    aria-label={`address-text-${values.address.display}`}
                    data-testid={`address-text-${values.address.display}`}
                    style={{
                      maxWidth: 300,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}>
                    {values.address.display}
                  </InformationCardProfileDetailsText>
                </Tooltip>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText
                aria-label={`cellPhone-text-${contactPhoneNumber}`}
                data-testid={`cellPhone-text-${contactPhoneNumber}`}
                style={{width: '151px'}}>
                {contactPhoneNumber}
                <InformationCardProfileDetailsText>
                  {values?.contactCellPhone && values?.contactCellPhone !== ''
                    ? values.contactCellPhone
                    : values.contactHomePhone}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
