import {Form as FormikForm} from 'formik'
import {IconButton as MuiIconButton} from '@mui/material'

import {styled, emphasize} from '@mui/material/styles'
import Mui from '@ui/mui'

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const CloseButton = styled(Mui.IconButton)({
  display: 'none',
  height: 28,
})

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const RightColumn = styled('div')({
  gridColumn: '2/3',
  overflow: 'hidden',
  textAlign: 'left',
  width: 250,
})

const Form = styled(FormikForm)({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '7fr 3fr',
})

const Body = styled('div')(({theme}) => ({
  flexGrow: 1,
  padding: theme.spacing(5),
}))

const Header = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[100],
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
}))

const Card = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.boxShadows[3],
  marginBottom: theme.spacing(5),
  width: theme.sizes.maxWidth,
}))

const IconButton = styled(MuiIconButton)(({theme}) => ({
  borderRadius: 4,
  color: '#C4C4C4',
  height: 38,
  width: 38,

  '& svg': {
    color: emphasize(theme.palette.secondary.main, 0.2),
  },

  // '&:hover': {
  //   backgroundColor: emphasize(theme.palette.secondary.main, 0.2),
  // },
}))

export default {
  Body,
  Button,
  Card,
  CloseButton,
  IconButton,
  Form,
  Header,
  LeftColumn: Mui.Grid,
  Red,
  RightColumn,
}
