import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function PersonCircleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='32'
			height='33'
			viewBox='0 0 32 33'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle cx='16' cy='16.498' r='16' fill='currentColor' />
			<path
				d='M21.3332 22.498V21.1647C21.3332 20.4575 21.0522 19.7792 20.5521 19.2791C20.052 18.779 19.3737 18.498 18.6665 18.498H13.3332C12.6259 18.498 11.9476 18.779 11.4476 19.2791C10.9475 19.7792 10.6665 20.4575 10.6665 21.1647V22.498'
				stroke='#FAFAFA'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.0002 15.8314C17.4729 15.8314 18.6668 14.6375 18.6668 13.1647C18.6668 11.692 17.4729 10.498 16.0002 10.498C14.5274 10.498 13.3335 11.692 13.3335 13.1647C13.3335 14.6375 14.5274 15.8314 16.0002 15.8314Z'
				stroke='#FAFAFA'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</Svg>
	);
}
