import styled, {css} from 'styled-components'
import {colors, font, resets} from '~/src/config/theme'
import {isNullOrUndefined, sizes} from '~/src/utils'
import Card from './CardStyles'

const FormStyles = {
  Container: styled(Card.Container)`
    width: ${sizes.maxWidth};
  `,
  Header: styled(Card.Header)``,
  Body: styled.form`
    display: grid;
    grid-template-columns: 1fr auto;
    max-width: 600px;
    padding: 45px;
  `,
  InputArea: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 40px;
    position: relative;
    row-gap: 24px;

    &::before {
      background-color: #dadada;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  `,
  Field: styled.div`
    display: grid;
    grid-template-columns: 140px 1fr;
  `,
  Symbol: styled.span`
    color: ${colors.red};
  `,
  Label: styled.label`
    color: #52575c;
    font-size: ${font.sizes.smaller};
    letter-spacing: 0.10000000149011612px;
    line-height: 40px;
  `,
  InputContainer: styled.div`
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 166.844px);
    position: relative;
    row-gap: 24px;
  `,
  Textarea: styled.textarea(
    ({area}: {area?: number[]}) => css`
      ${resets.common}
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      font-size: ${font.sizes.smaller};
      padding: 8px 16px;
      resize: none;

      ${!isNullOrUndefined(area) &&
      css`
        grid-column: ${area[0]} / ${area[1]};
      `}
    `,
  ),
  Image: styled.img`
    cursor: pointer;
  `,
  IconGroup: styled.div`
    column-gap: 27px;
    display: flex;

    svg {
      color: #808080;
      cursor: pointer;
      height: 16px;
      width: 16px;
    }
  `,
  ImageUploadArea: styled.div`
    margin: 0 40px;
    padding: 25px 0;
  `,
  ButtonArea: styled.div`
    column-gap: 15px;
    display: flex;
    grid-column: 1/2;
  `,
}

export const FormInput = {
  Container: styled.div(
    ({area}: {area?: number[]; isPlaceholder?: boolean}) => css`
      position: relative;

      ${!isNullOrUndefined(area) &&
      css`
        grid-column: ${area[0]} / ${area[1]};
      `}
    `,
  ),
  ErrorMessage: styled.div`
    color: ${colors.red};
    content: 'Please fill out this field';
    font-size: ${font.sizes.micro};
    position: absolute;
    right: 0;
    top: -20px;
  `,
  Input: styled.input<{error: boolean; isPlaceholder: boolean}>`
    ${resets.common}
    border: 1px solid ${colors.gray250};
    border-radius: 4px;
    font-size: ${font.sizes.smaller};
    height: 40px;
    padding: 0 17px;
    width: 100%;

    ${(props) =>
      props.isPlaceholder &&
      css`
        color: ${colors.gray250};
      `}

    &:disabled {
      color: ${colors.gray200};
      border-color: ${colors.gray200};
    }

    &:disabled::placeholder {
      color: ${colors.gray200};
    }

    ${(props) =>
      props.error &&
      css`
        border-color: ${colors.red};
        color: ${colors.red};

        ::placeholder {
          color: ${colors.red};
        }
      `}
  `,
}

export default FormStyles
