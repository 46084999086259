import {FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  FlexSpaceBetweenContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileDetailsText,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleCancel(): void
  handleClick(value: string): void
  oldApplicant: Applicant
  setFieldValue: any
  values: Applicant
  isDataCleanup?: boolean
}

export default function MspCandidateProfileContactEdit({
  handleCancel,
  handleClick,
  oldApplicant,
  setFieldValue,
  values,
  isDataCleanup,
}: IProps) {
  const isCellPhoneExist = oldApplicant?.contactCellPhone?.length > 0
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Contact Information</InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                {isDataCleanup ? null : (
                  <>
                    <InformationCardHeaderTextEdit
                      aria-label="cancel-contact-info-button"
                      data-testid="cancel-contact-info-button"
                      style={{marginRight: 10}}
                      onClick={handleCancel}>
                      Cancel
                    </InformationCardHeaderTextEdit>
                    <InformationCardHeaderTextEdit
                      aria-label="save-contact-info-button"
                      data-testid="save-contact-info-button"
                      onClick={() => handleClick('saveChanges')}>
                      Save
                    </InformationCardHeaderTextEdit>
                  </>
                )}
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>

          <InformationCardDetailsContainer style={{display: 'flex', flexDirection: 'column'}}>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>First name</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    name="firstName"
                    aria-label="firstName-text-field"
                    data-testid="firstName-text-field"
                    type="text"
                    sx={{
                      marginBottom: '15px',
                      width: '249px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Last Name</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    name="lastName"
                    aria-label="lastName-text-field"
                    data-testid="lastName-text-field"
                    sx={{
                      maxWidth: '300px',
                      minWidth: '300px',
                      height: '28px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Date of Birth</span>
                <InformationCardProfileDetailsText style={{width: '140px'}}>
                  <FormikDatePicker
                    maxDate={new Date()}
                    aria-label="lastName-text-field"
                    data-testid="lastName-text-field"
                    name="dateofBirth"
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>

            {/*  */}
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>E-mail</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    aria-label="contactEmail"
                    name="contactEmail"
                    data-testid="contactEmail"
                    type="text"
                    label=""
                    sx={{
                      marginBottom: '15px',
                      width: '249px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>

              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Location</span>
                <InformationCardProfileDetailsText style={{width: '300px'}}>
                  <FormikGooglePlacesAutocomplete
                    name="address"
                    setFieldValue={setFieldValue}
                    // inputProps={{maxLength: 50}}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Phone Number</span>
                <InformationCardProfileDetailsText>
                  {isCellPhoneExist ? (
                    <FormikTextField
                      aria-label="contactCellPhone"
                      name="contactCellPhone"
                      data-testid="contactCellNumber"
                      type="tel"
                      sx={{
                        maxWidth: '140px',
                        minWidth: '140px',
                        height: '28px',
                        maxHeight: '28px',
                        '& .MuiInputBase-input': {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                  ) : (
                    <FormikTextField
                      aria-label="contactHomePhone"
                      name="contactHomePhone"
                      data-testid="contactHomeNumber"
                      type="tel"
                      sx={{
                        maxWidth: '140px',
                        minWidth: '140px',
                        height: '28px',
                        maxHeight: '28px',
                        '& .MuiInputBase-input': {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                  )}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
