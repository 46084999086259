import {createAssignmentItem} from '@ui/card/CandidateInformation'
import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {Applicant, Assignment} from '~/src/models/applicant'
import MspCandidateAssignHistMain from '../candidate-details/candidate-assignment-history/MspCandidateAssignHistMain'
import {DashboardMainStyles} from '../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>

  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
  singleApplicantRefetch(): void
}

export default function JobOrderSummaryAssignmentHistory({
  handleSubmit,
  singleApplicantRefetch,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
}: IProps) {
  const assignmentValues = oldApplicant
    ? oldApplicant.assignments.map((i: Assignment) => {
        return {
          ...i,
          employeeBranch: i.employeeBranch,
          customerName: i.customerName,
          description: i?.description ? i.description : undefined,
          jobTitle: i.jobTitle,
          assignmentID: i.assignmentID,
          endDate: i.endDate,
          originalStartDate: i.originalStartDate,
        }
      })
    : []

  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,
    // assignment info
    newAssignments: createAssignmentItem,
    assignments: assignmentValues,
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          // new
          newAssignments: Yup.object({
            jobTitle: Yup.string().required('Required'),
            customerName: Yup.string().required('Required'),
            assignmentID: Yup.string().required('Required'),
            originalStartDate: Yup.date()
              .required('Required')
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            endDate: Yup.string()
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined)),
            description: Yup.string().nullable(),
          }),

          // old

          assignments: Yup.array().of(
            Yup.object({
              customerName: Yup.string().required('Required'),
              description: Yup.string().nullable(),
              jobTitle: Yup.string().required('Required'),
              assignmentID: Yup.string().required('Required'),
              originalStartDate: Yup.date()
                .required('Required')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
              endDate: Yup.string()
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            }),
          ),
        })}>
        {({setFieldValue, values, resetForm, errors}) => {
          
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <DashboardMainStyles>
                  <JobOrderCandidateNameHeader
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    isAssignmentHistory={true}
                    oldApplicant={oldApplicant}
                    singleApplicant={values}
                  />

                  <MspCandidateAssignHistMain
                    errors={errors}
                    handleSubmit={handleSubmit}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    oldApplicant={oldApplicant}
                    values={values}
                    singleApplicantRefetch={singleApplicantRefetch}
                  />
                </DashboardMainStyles>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}
