import HeaderContainer from '@ui/header-container/HeaderContainer'
import React from 'react'
import type {IInitialPositionValues} from '~/src/models/position'
import Title from '../../layout/Title'
import PositionForm from '../../common/positions/PositionForm'

export default function AddPosition(): React.ReactElement {
  const initialValues = {
    onsiteFacilityName: '',
    onsiteFacilityAddress: '',
    position: '',
    departmentName: '',
    workersCompCode: '',
    startTime: null,
    endTime: null,
    manager: '',
    jobDescription: undefined,
  } as IInitialPositionValues

  return (
    <>
      <Title pageTitle="Add New Position" leaveLast={true} />
      <HeaderContainer header={<div style={{fontWeight: 'bold'}}>Add Position</div>}>
        <PositionForm initialValues={initialValues} />
      </HeaderContainer>
    </>
  )
}
