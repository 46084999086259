import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import React from 'react'
import {ReactComponent as PlusIcon} from '~/src/assets/images/Group 25.svg'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 274px;
    width: 260px;
    border-radius: 16px;
    border: 1px solid ${theme.palette.grey[300]};
    padding: 15px;
    cursor: pointer;
  `,
) as typeof Box

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  sx?: object
}

export default function AddPrefillCard({onClick, sx}: IProps) {
  return (
    <Container sx={{...sx}} onClick={onClick}>
      <PlusIcon />
    </Container>
  )
}
