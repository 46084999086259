import React from 'react'
import Box from '@mui/material/Box'
import {TextField} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import CheckboxChecked from '@ui/icons/CheckboxChecked'

interface PrependedPositionHeader {
  numSelected: number
}

export default function PrependedPositionHeader(props: PrependedPositionHeader) {
  const {numSelected} = props

  return (
    <Box
      sx={{
        display: 'flex',
        height: 65,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 16px',
        borderBottom: '1px solid #CDCED9',
      }}>
      {numSelected > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '20px',
              paddingLeft: '16px',
            }}>
            <CheckboxChecked />
            <div style={{fontSize: '15px', fontWeight: 500}}>{numSelected} Selected</div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px 10px 16px',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
            }}>
            <div style={{fontSize: '15px', fontWeight: 500}}>Delete Selected</div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <FormControl>
              <div style={{fontSize: '15px', fontWeight: 500}}>
                <InputLabel sx={{m: -0.7, fontSize: '15px', fontWeight: 500}}>
                  Move Selected
                </InputLabel>
              </div>
              <TextField
                select
                sx={{
                  minWidth: '150px',
                  borderLeft: 'none',
                }}
                inputProps={{style: {fontSize: '15px', fontWeight: 500}}}
                value="">
                <MenuItem value={10}>Group A</MenuItem>
                <MenuItem value={20}>Group B</MenuItem>
                <MenuItem value={30}>Group C</MenuItem>
                <MenuItem>Add Group</MenuItem>
              </TextField>
            </FormControl>
          </Box>
        </Box>
      ) : (
        <div
          style={{
            fontSize: '15px',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px 10px 16px',
          }}>
          All Positions
        </div>
      )}
    </Box>
  )
}
