import CloseIcon from '@mui/icons-material/Close'
import {Button} from '@mui/material'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import type {FormikErrors} from 'formik'
import React from 'react'
import type {Applicant, Assignment} from '~/src/models/applicant'
import {
  AddAssignmentCardContainer,
  AddAssignmentContainer,
  AddAssignmentText,
  CloseIconContainer,
  CloseIconVerticalAlignContainer,
  DashboardMainStyles,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceEvenlyContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
  MarginBottom15Px,
  Red,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  resetForm(): void
  setFieldValue(
    field: string | undefined,
    value: any | undefined,
    shouldValidate?: boolean | undefined,
  ): void
  singleApplicantRefetch(): void
  values: Applicant
}

export default function MspCandidateEmptyFormAssignHist({
  errors,
  handleSubmit,
  resetForm,
  setFieldValue,
  singleApplicantRefetch,
  values,
}: IProps) {
  const assignmentError = values?.newAssignments?.assignmentID?.length
    ? values.newAssignments.assignmentID.length > 0
    : false

  const customerNameError = values?.newAssignments?.customerName?.length
    ? values.newAssignments.customerName.length > 0
    : false

  const jobTitleError = values?.newAssignments?.jobTitle?.length
    ? values.newAssignments.jobTitle.length > 0
    : false

  const startDateError = values.newAssignments?.originalStartDate
    ? values.newAssignments?.originalStartDate
    : false

  const handleClick = async () => {
    const newValueAssignment = values.newAssignments
      ? values.newAssignments.endDate === ''
        ? {
            assignmentID: values.newAssignments?.assignmentID,
            customerName: values.newAssignments?.customerName,
            description:
              values?.newAssignments?.description?.length > 0
                ? values.newAssignments.description
                : undefined,
            jobTitle: values.newAssignments?.jobTitle,
            originalStartDate: values.newAssignments?.originalStartDate,
          }
        : {
            assignmentID: values.newAssignments?.assignmentID,
            customerName: values.newAssignments?.customerName,
            description:
              values?.newAssignments?.description?.length > 0
                ? values.newAssignments.description
                : undefined,
            endDate: values.newAssignments?.endDate,
            jobTitle: values.newAssignments?.jobTitle,
            originalStartDate: values.newAssignments?.originalStartDate,
          }
      : {}

    if (assignmentError && customerNameError && jobTitleError && startDateError) {
      values.assignments.unshift(newValueAssignment)
      await handleSubmit(values)
      setFieldValue('newAssignemnts.description', undefined)
      resetForm()
      singleApplicantRefetch()
    }
  }

  const maxStartDate = values.newAssignments?.endDate
    ? new Date(values.newAssignments.endDate)
    : undefined

  const minEndDate = values.newAssignments?.originalStartDate
    ? new Date(values.newAssignments?.originalStartDate)
    : undefined

  const addAssignmentButtonSwitch = () => {
    if (assignmentError && customerNameError && jobTitleError && startDateError) {
      return (
        <AddAssignmentCardContainer>
          <Button
            variant="contained"
            color="primary"
            sx={{width: 154, fontWeight: 'normal'}}
            onClick={handleClick}
            aria-label="add new activity button"
            data-testid="activityAddNewNote">
            Add Assignment
          </Button>
        </AddAssignmentCardContainer>
      )
    } else {
      return (
        <AddAssignmentCardContainer>
          <AddAssignmentContainer onClick={handleClick}>
            <FlexSpaceEvenlyContainer>
              <AddAssignmentText>Add Assignment</AddAssignmentText>
            </FlexSpaceEvenlyContainer>
          </AddAssignmentContainer>
        </AddAssignmentCardContainer>
      )
    }
  }

  return (
    <>
      <DashboardMainStyles>
        <InformationCardHeaderPadding>
          <InformationCardHeaderTextTitle>Enter new Assignment</InformationCardHeaderTextTitle>
        </InformationCardHeaderPadding>
        <InformationCardContainer>
          <FlexContainer>
            <InformationCardDetailsContainer>
              <MarginBottom15Px>
                <InformationCardProfileExperienceJobText>
                  <FlexContainer>
                    <FlexColumnContainer>
                      <div style={{display: 'flex'}}>
                        Job Title <Red>*</Red>
                      </div>
                      <FormikTextField
                        name="newAssignments.jobTitle"
                        type="text"
                        label=""
                        data-testid="assignmentPosition"
                        value={values.newAssignments?.jobTitle}
                        sx={{
                          maxWidth: '210px',
                          minWidth: '210px',
                          height: '28px',
                          maxHeight: '28px',
                          marginRight: '19px',
                        }}
                      />
                    </FlexColumnContainer>
                    <FlexColumnContainer>
                      <div style={{display: 'flex'}}>
                        Customer Name<Red>*</Red>
                      </div>
                      <FormikTextField
                        name="newAssignments.customerName"
                        data-testid="assignmentsCompany"
                        type="text"
                        label=""
                        value={values.newAssignments?.customerName}
                        sx={{
                          maxWidth: '210px',
                          minWidth: '210px',
                          height: '28px',
                          maxHeight: '28px',
                          marginRight: '19px',
                        }}
                      />
                    </FlexColumnContainer>
                    <FlexColumnContainer>
                      <div style={{display: 'flex'}}>
                        Assignment ID<Red>*</Red>
                      </div>
                      <FormikTextField
                        name="newAssignments.assignmentID"
                        data-testid="assignmentsEmptyAssignmentId"
                        type="text"
                        label=""
                        value={values.newAssignments?.assignmentID}
                        sx={{
                          maxWidth: '195px',
                          minWidth: '195px',
                          height: '28px',
                          maxHeight: '28px',
                        }}
                      />
                    </FlexColumnContainer>
                  </FlexContainer>
                </InformationCardProfileExperienceJobText>
                <InformationCardProfileExperienceJobText>
                  <FlexContainer>
                    <FlexColumnContainer>
                      <div style={{display: 'flex'}}>
                        Start Date<Red>*</Red>
                      </div>
                      <div style={{width: '210px'}}>
                        <FormikDatePicker
                          name="newAssignments.originalStartDate"
                          data-testid="assignmentsEmptyStartDate"
                          initialValue={values.newAssignments?.originalStartDate}
                          sx={{
                            width: '100%',
                          }}
                          maxDate={
                            values.newAssignments?.endDate === '' ||
                            values.newAssignments?.endDate === undefined
                              ? undefined
                              : maxStartDate?.setDate(maxStartDate.getDate())
                          }
                          onClose={(va: Date) =>
                            setFieldValue(values.newAssignments?.originalStartDate, va)
                          }
                        />
                      </div>
                    </FlexColumnContainer>
                    <FlexColumnContainer style={{marginLeft: 20}}>
                      End Date
                      <div style={{width: '210px'}}>
                        <FormikDatePicker
                          name="newAssignments.endDate"
                          data-testid="assignmentsEmptyEndDate"
                          initialValue={undefined}
                          sx={{
                            width: '100%',
                          }}
                          minDate={minEndDate?.setDate(minEndDate.getDate())}
                          onClose={(va: Date) => setFieldValue(values.newAssignments?.endDate, va)}
                        />
                      </div>
                    </FlexColumnContainer>
                  </FlexContainer>
                </InformationCardProfileExperienceJobText>
                <InformationCardProfileHeaderText>
                  <div style={{display: 'flex'}}>Description</div>
                  <FormikTextField
                    name="newAssignments.description"
                    data-testid="assignmentEmptyFormDescription"
                    id="outlined-multiline-flexible"
                    multiline
                    rows={2}
                    type="text"
                    label=""
                    inputProps={{
                      style: {
                        paddingTop: 5,
                        paddingBottom: 20,
                      },
                    }}
                    sx={{
                      marginBottom: '15px',
                      maxWidth: '650px',
                      minWidth: '650px',
                    }}
                  />
                </InformationCardProfileHeaderText>
              </MarginBottom15Px>
            </InformationCardDetailsContainer>
            <CloseIconContainer style={{height: '318px'}} onClick={() => resetForm()}>
              <CloseIconVerticalAlignContainer>
                <CloseIcon />
              </CloseIconVerticalAlignContainer>
            </CloseIconContainer>
          </FlexContainer>
        </InformationCardContainer>
        {addAssignmentButtonSwitch()}
      </DashboardMainStyles>
    </>
  )
}
