import {push} from 'connected-react-router'
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import Dashboard from '~/src/components/company/dashboard'
import AddPosition from '~/src/components/company/positions/AddPosition'
import EditPosition from '~/src/components/company/positions/EditPosition'
import ManagePositions from '~/src/components/company/positions/ManagePositions'
import UsersAndRoles from '~/src/components/company/users-and-roles'
import Layout from '~/src/components/layout/Layout'
import {SIDEBAR_ROUTES} from '~/src/routes'
import {useSignoutMutation} from '~/src/store/apis/auth-api'
import {getCurrentSession} from '~/src/store/cognito'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppDispatch} from '~/src/store/store-hooks'
import S from '../components/common/styles/AdminStyles'
import CompanyCandidateViewMain from '../components/company/manage-orders/company-candidate-view/CompanyCandidateViewMain'
import CompanyCompletedOrdersMain from '../components/company/manage-orders/completed-orders/CompanyCompletedOrdersMain'
import ServiceOrderSummary from '../components/company/manage-orders/manage-order-tables/company-summary/ServiceOrderSummary'
import ManageOrders from '../components/company/manage-orders/manage-order-tables/ManageOrders'
import OrderDetails from '../components/company/manage-orders/manage-order-tables/OrderDetails'
import AddServiceOrder from '../components/common/service-orders/AddServiceOrder'
import EditServiceOrder from '../components/company/manage-orders/order-form/EditServiceOrder'
import ManageTemplates from '../components/company/manage-templates/ManageTemplates'
import ManageCompany from '../components/company/manage-company/ManageCompany'

export default function CompanyAdminPage(): JSX.Element {
  const {path, url} = useRouteMatch()
  const {currentUser} = useCurrentUser()
  const dispatch = useAppDispatch()
  const [signout] = useSignoutMutation()

  useEffect(() => {
    const fetchCurrentSession = async () => {
      const session = await getCurrentSession()
      if (!session?.isValid()) {
        // Logout and reroute
        await signout({email: currentUser.emailAddress}).unwrap()
        dispatch(push('/signin'))
      }
    }
    const timeoutVar = setInterval(() => {
      void fetchCurrentSession()
    }, 30000)
    return () => {
      clearInterval(timeoutVar)
    }
  }, [currentUser.emailAddress, dispatch, signout])

  return (
    <Layout variant="company">
      <S.Container>
        <S.Main>
          <Switch>
            <Route path={path + SIDEBAR_ROUTES.DASHBOARD} component={Dashboard} />
            <Route path={path + SIDEBAR_ROUTES.ADD_POSITION} component={AddPosition} />
            <Route
              path={`${path + SIDEBAR_ROUTES.EDIT_POSITION}/:orderId`}
              component={EditPosition}
            />
            <Route path={path + SIDEBAR_ROUTES.POSITIONS} component={ManagePositions} />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}
              component={CompanyCompletedOrdersMain}
            />
            <Route
              exact
              path={`${path + SIDEBAR_ROUTES.SERVICE_ORDER_DETAILS}/:type/summary`}
              component={ServiceOrderSummary}
            />
            <Route path={path + SIDEBAR_ROUTES.ADD_ORDER} component={AddServiceOrder} />
            <Route
              path={`${path + SIDEBAR_ROUTES.EDIT_SERVICEORDER}/:orderId/:positionId/:orderIndex`}
              component={EditServiceOrder}
            />
            <Route
              exact
              path={`${path + SIDEBAR_ROUTES.COMPANY_CANDIDATE_LIST}/:orderId`}
              component={CompanyCandidateViewMain}
            />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.SERVICE_ORDER_DETAILS}
              component={OrderDetails}
            />
            <Route path={path + SIDEBAR_ROUTES.SERVICE_ORDERS} component={ManageOrders} />

            <Route path={path + SIDEBAR_ROUTES.USERS_AND_ROLES} component={UsersAndRoles} />
            <Route path={path + SIDEBAR_ROUTES.MANAGE_COMPANY} component={ManageCompany} />
            <Route path={path + SIDEBAR_ROUTES.MANAGE_TEMPLATES} component={ManageTemplates} />
            <Redirect exact from={path} to={url + SIDEBAR_ROUTES.DASHBOARD} />
          </Switch>
        </S.Main>
      </S.Container>
    </Layout>
  )
}
