import {createApi} from '@reduxjs/toolkit/query/react'
import type {ISuccessMessage} from '~/src/models'
import type {IPosition, TPagedPositionPayload, TPositionPayload} from '../../models/position'
import {baseQuery} from '../base-query'

export const positionApi = createApi({
  reducerPath: 'positionApi',
  tagTypes: ['Position'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPosition: builder.query<TPositionPayload, TPagedPositionPayload>({
      providesTags: ['Position'],
      query: ({pageNumber, pageSize, agencyId}) => ({
        url: `/api/Position`,
        method: 'GET',
        params: {pageNumber, pageSize, agencyId},
      }),
    }),

    getPositionById: builder.query<IPosition, {id: string}>({
      providesTags: ['Position'],
      query: ({id}) => ({
        url: `/api/Position/${id}`,
        method: 'GET',
      }),
    }),

    getPositionsByIds: builder.query<IPosition[], string[]>({
      providesTags: ['Position'],
      query: (ids) => ({
        url: `/api/position/getPositionsByIds`,
        method: 'POST',
        body: ids,
      }),
    }),

    getPositionByCompanyId: builder.query<TPositionPayload, TPagedPositionPayload>({
      providesTags: ['Position'],
      query: ({pageNumber, pageSize, companyId, agencyId}) => ({
        url: `/api/Position/GetPositionsByCompanyId`,
        method: 'GET',
        params: {pageNumber, pageSize, companyId, agencyId},
      }),
    }),

    createPosition: builder.mutation<ISuccessMessage, {position: IPosition; agencyId?: string}>({
      invalidatesTags: ['Position'],
      query: ({position, agencyId}) => ({
        url: `/api/Position`,
        method: 'POST',
        body: position,
        params: {agencyId},
      }),
    }),

    updatePosition: builder.mutation<ISuccessMessage, {position: IPosition; agencyId?: string}>({
      invalidatesTags: ['Position'],
      query: ({position, agencyId}) => ({
        url: `/api/Position`,
        method: 'PUT',
        body: position,
        params: {agencyId},
      }),
    }),
  }),
})

export const {
  useGetPositionQuery,
  useGetPositionByIdQuery,
  useLazyGetPositionsByIdsQuery,
  useGetPositionByCompanyIdQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
} = positionApi
