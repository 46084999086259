import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import type {TRootState} from '.'

/**
 *  After getting a token from Cognito it's put in the Redux store.
 */
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers: Headers, {getState}: {getState: () => unknown}): Headers => {
    const token = (getState() as TRootState).root.token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})
