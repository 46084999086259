import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function DealsIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M2.50008 4.03333H17.5001C17.5718 4.03333 17.6334 4.09493 17.6334 4.16666V15.8333C17.6334 15.9051 17.5718 15.9667 17.5001 15.9667H2.50008C2.42835 15.9667 2.36675 15.9051 2.36675 15.8333V4.16666C2.36675 4.09493 2.42835 4.03333 2.50008 4.03333Z'
				stroke='currentColor'
				strokeWidth='1.4'
			/>
			<rect x='6.5' y='3.33333' width='1.16667' height='13.3333' fill='currentColor' />
			<rect x='12.3333' y='3.33333' width='1.16667' height='13.3333' fill='currentColor' />
		</Svg>
	);
}
