import React, {useEffect, useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateProfileExperienceDisplay from '../CandidateProfileDisplayandEdit/MspCandidateProfileExperienceDisplay'
import MspCandidateProfileExperienceEdit from '../CandidateProfileDisplayandEdit/MspCandidateProfileExperienceEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

const MspCandidateProfileExperience = ({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
}: IProps) => {
  const [editProfileExperienceState, setEditProfileExperienceState] = useState('saveChanges')

  const handleClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileExperienceState('edit')
    } else {
      await handleSubmit(values)
      setEditProfileExperienceState('saveChanges')
    }
  }

  const handleCancel = () => {
    resetForm()
    setEditProfileExperienceState('saveChanges')
  }

  useEffect(() => {
    setEditProfileExperienceState('saveChanges')
  }, [oldApplicant.id])

  const projectExperience = () => {
    switch (editProfileExperienceState) {
      case 'edit':
        return (
          <MspCandidateProfileExperienceEdit
            handleCancel={handleCancel}
            handleClick={handleClick}
            setFieldValue={setFieldValue}
            values={values.workHistories}
          />
        )

      default:
        return (
          <MspCandidateProfileExperienceDisplay
            handleClick={handleClick}
            values={values.workHistories}
          />
        )
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectExperience()}</DashboardMainStyles>
    </>
  )
}

export default MspCandidateProfileExperience
