/* eslint-disable complexity */
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import React, {useEffect} from 'react'
import type {IUserAndEmail} from '~/src/models'
import type {Candidate} from '~/src/models/jobOrder'
import {useGetAgencyNamesByIdsQuery} from '~/src/store/apis/agency-api'
import {useAppSelector} from '~/src/store/store-hooks'

const CandidateName = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
`

const Detail = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
`

const StyledListItem = styled(ListItem)<{$selected: boolean; $isBigBox: boolean}>`
  max-width: 260px;
  display: flex;
  flex-directio: row;
  padding: 9px 6px 4px 8px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  border: ${(props) => (props.$selected ? '2px solid black' : 'none')};
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  height: ${(props) => (props.$isBigBox ? 'auto' : '109px')};
`

const Divider = styled('div')`
  border-bottom: 1px solid #ebebeb;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
`

interface InventoryListCardProps {
  candidate: Candidate
  removeCandidate?: (candidateId: string) => void
  isDataCleanup: boolean
  isPrefill: boolean
  isMoving: boolean
  isMsp: boolean
  isDisabled: boolean
  selected: boolean
  jobOrderStatus?: string
  usersAndEmails?: IUserAndEmail[]
  onSelect: (id: string) => void
}

const InventoryListCard = ({
  candidate,
  removeCandidate,
  isDataCleanup,
  selected,
  onSelect,
  isPrefill,
  isMoving,
  isMsp,
  isDisabled,
  usersAndEmails,
  jobOrderStatus,
}: InventoryListCardProps) => {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const agencyId = candidate.agencyId ?? ''
  const {data: agencyNameData, refetch: agencyNameRefetch} = useGetAgencyNamesByIdsQuery([agencyId])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const ITEM_HEIGHT = 48
  const handleRemoveCandidate = async () => {
    if (removeCandidate) {
      handleCloseMenu()
      void removeCandidate(candidate.candidateId)
    }
  }

  const handleSelectClick = () => {
    onSelect(candidate.id)
  }

  const modifiedDate = new Date(candidate.statusModifiedDate)
  const modifiedBy = candidate.modifiedBy ?? candidate.statusModifiedBy
  const modifiedByFirstLastNames = usersAndEmails?.find((item) => item.emailAddress === modifiedBy)
  const endedByFirstLastNames = usersAndEmails?.find(
    (item) => item.emailAddress === candidate.endDateUpdatedBy,
  )
  const phone = candidate.contactCellPhone ? candidate.contactCellPhone : candidate.contactHomePhone
  const last4ssn =
    candidate.ssn && candidate.ssn.length > 0
      ? candidate.ssn.slice(candidate.ssn.length - 4, candidate.ssn.length)
      : ''
  const isSubmitted = jobOrderStatus === 'Filled'
  const details = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Detail>
        <span style={{fontWeight: 'bold'}}>Added Date: </span>
        {modifiedDate.toLocaleString()}
      </Detail>
      <Detail>
        <span style={{fontWeight: 'bold'}}>{isDataCleanup ? 'Cleaned By: ' : 'Added By: '}</span>
        <Tooltip title={modifiedBy}>
          <span>{`${modifiedByFirstLastNames?.firstName} ${modifiedByFirstLastNames?.lastName}`}</span>
        </Tooltip>
      </Detail>
      {isPrefill ? null : (
        <Detail>
          <span style={{fontWeight: 'bold'}}>Agency: </span>
          {`${agencyNameData ? agencyNameData[0]?.name : ''}`}
        </Detail>
      )}
      {isSubmitted && !candidate.endDate && (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '220px'}}>
          <Divider />
          <Detail>
            <span style={{fontWeight: 'bold'}}>Ended Date: </span>
          </Detail>
          <Detail>
            <span style={{fontWeight: 'bold'}}>Ended By: </span>
          </Detail>
          <Detail>
            <span style={{fontWeight: 'bold'}}>Agency: </span>
          </Detail>
          <CheckCircleOutlineIcon
            color="primary"
            sx={{position: 'absolute', left: '225px', top: '133px', height: '19px', width: '19px'}}
          />
        </Box>
      )}
      {candidate.endDate && (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '220px'}}>
          <Divider />
          <Detail>
            <span style={{fontWeight: 'bold'}}>Ended Date: </span>
            {`${new Date(candidate.endDate).toLocaleString()}`}
          </Detail>
          <Detail>
            <span style={{fontWeight: 'bold'}}>Ended By: </span>
            {`${endedByFirstLastNames?.firstName} ${endedByFirstLastNames?.lastName}`}
          </Detail>
          <Detail>
            <span style={{fontWeight: 'bold'}}>Agency: </span>
            {`${agencyNameData ? agencyNameData[0]?.name : ''}`}
          </Detail>
          <HighlightOffIcon
            color="error"
            sx={{position: 'absolute', left: '225px', top: '133px', height: '19px', width: '19px'}}
          />
        </Box>
      )}
    </Box>
  )
  useEffect(() => {
    void agencyNameRefetch()
  }, [agencyNameRefetch])

  return (
    <StyledListItem
      key={candidate.candidateId}
      $selected={selected}
      $isBigBox={isSubmitted}
      disablePadding
      alignItems="flex-start">
      <ListItemButton
        onClick={handleSelectClick}
        alignItems="flex-start"
        sx={{
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
        <Box sx={{display: 'flex', flexDirection: 'row', marginBottom: '6px'}}>
          <ListItemAvatar sx={{margin: '0'}}>
            <Avatar
              sx={{backgroundColor: '#03a9fc', margin: '0'}}
              src={candidate?.profilePictureName}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Tooltip
                  title={`${candidate.firstName} ${candidate.middleName ?? ''} ${
                    candidate.lastName
                  } ${last4ssn}`}>
                  <CandidateName>{`${candidate.firstName} ${candidate.middleName ?? ''} ${
                    candidate.lastName
                  } ${last4ssn}`}</CandidateName>
                </Tooltip>
                <Detail>{phone}</Detail>
              </>
            }
          />
        </Box>
        {details}
      </ListItemButton>
      {selected && <CheckCircleIcon color="primary" />}
      {isDataCleanup || selected || isMoving ? null : (
        <div style={{alignSelf: 'flex-start'}}>
          <IconButton
            size="small"
            sx={{padding: 0, margin: 0}}
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            edge="end"
            onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}>
            <MenuItem
              disabled={loggedInAgencyId !== agencyId || candidate.endDate !== undefined}
              onClick={handleRemoveCandidate}>
              {jobOrderStatus === 'Filled' ? 'End Assignment' : 'Remove'}
            </MenuItem>
          </Menu>
        </div>
      )}
    </StyledListItem>
  )
}

export default InventoryListCard
