import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function PersonIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.99935 0.666504C6.70659 0.666504 7.38487 0.947455 7.88497 1.44755C8.38506 1.94765 8.66602 2.62593 8.66602 3.33317C8.66602 4.04041 8.38506 4.71869 7.88497 5.21879C7.38487 5.71889 6.70659 5.99984 5.99935 5.99984C5.2921 5.99984 4.61383 5.71889 4.11373 5.21879C3.61363 4.71869 3.33268 4.04041 3.33268 3.33317C3.33268 2.62593 3.61363 1.94765 4.11373 1.44755C4.61383 0.947455 5.2921 0.666504 5.99935 0.666504V0.666504ZM5.99935 7.33317C8.94602 7.33317 11.3327 8.5265 11.3327 9.99984V11.3332H0.666016V9.99984C0.666016 8.5265 3.05268 7.33317 5.99935 7.33317Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
