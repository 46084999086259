import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ModulesIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='42'
			height='52'
			viewBox='0 0 42 52'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect x='1' y='37' width='40' height='14' rx='1' stroke='currentColor' strokeWidth='2' />
			<rect x='1' y='19' width='40' height='14' rx='1' stroke='currentColor' strokeWidth='2' />
			<rect x='1' y='1' width='40' height='14' rx='1' stroke='currentColor' strokeWidth='2' />
		</Svg>
	);
}
