/* eslint-disable complexity */
import {Typography} from '@mui/material'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import {colors} from '../../../../config/theme'

export const CompanyDetailsContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const PositionBreakdownContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const CandidateDetailsContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  max-width: 1111px;
  width: 1111px;
  margin: auto;
  flex-direction: row;
  margin-top: 25px;
  justify-content: space-between;
`

export const AllCandidatesContainer = styled(Box)`
  max-width: 260px;
  min-width: 260px;
  padding: 0;
  margin-right: 18px;
  background-color: ${colors.white};
  border-radius: 8px;
  flex-grow: 0;
  flex-basis: 100px;
`

export const AllCandidatesHeaderContainer = styled(Box)`
  background-color: ${colors.white};
  padding: 8px 0px 10px 14px;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const AllCandidatesHeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.primary};
`

export const AllCandidatesButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const CandidateInformationContainer = styled('div')`
  max-width: 828px;
  width: 828px;
  display: flex;
  justify-content: space-between
  align-items: left;
  padding: 10px 20px 25px 25px;
  font-weight: 600;
  background-color: ${colors.white};
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  border-radius: 16px;
`

export const DropDownContainer = styled('div')`
  width: 10.5em;
  margin: 0 auto;
`

export const DropDownHeader = styled('div')`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`

export const DropDownListContainer = styled('div')`
  position: absolute;
  max-width: 261px;
  min-width: 261px;
  border-radius: 10px;
  margin-left: -15px;
  z-index: 3;
`

export const DropDownList = styled('ul')`
  padding: 5px;
  background: #ffffff;
  box-sizing: border-box;
  color: black;
  border-radius: 8px;
  &:first-child {
    padding-top: 0.8em;
  }
`

export const ListItem = styled('li')`
  display: flex;
  list-style: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0.8em;
  cursor: pointer;
  &:hover {
    background: #ebebeb;
    border-radius: 8px;
  }
`
