// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {format} from 'date-fns'
import React, {useEffect, useState} from 'react'
import type {Applicant, EventsArray} from '~/src/models/applicant'
import {
  FlexContainer,
  FlexSpaceBetweenContainer,
  FlexWithoutPaddingContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateEventsEdit from './MspCandidateEventsEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  index: number
  newEvents: EventsArray
  oldApplicant: Applicant
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateEventSingleEdit({
  handleSubmit,
  oldApplicant,
  setFieldValue,
  values,
  index,
  newEvents,
}: IProps) {
  const [editProfileEventsState, setEditProfileEventsState] = useState('saveChanges')
  const [targetEvents, setTargetEvents] = useState([-1])
  const handleClick = async (value: string, index2: number, isCancel: boolean) => {
    if (value === 'saveChanges' && isCancel) {
      setFieldValue(`events.${index2}`, oldApplicant.events[index2])
      setTargetEvents(targetEvents.filter((i) => i !== index2))
      setEditProfileEventsState('saveChanges')
    } else if (value === 'edit') {
      setTargetEvents([...targetEvents, index2])
      setEditProfileEventsState('edit')
    } else if (value === 'saveChanges' && !isCancel) {
      await handleSubmit(values)
      setEditProfileEventsState('saveChanges')
    }
  }

  const newEventsmentsArray = values.events.slice(1)

  useEffect(() => {
    setEditProfileEventsState('saveChanges')
  }, [oldApplicant.id])

  return (
    <>
      {editProfileEventsState === 'edit' && targetEvents.includes(index) ? (
        <MspCandidateEventsEdit
          handleClick={handleClick}
          index={index}
          oldApplicant={oldApplicant}
          setFieldValue={setFieldValue}
          targetEvents={targetEvents}
          values={newEvents}
        />
      ) : (
        <>
          <InformationCardExperianceBorder index={index === newEventsmentsArray.length}>
            <FlexSpaceBetweenContainer>
              <InformationCardHeaderTextTitle>{newEvents.eventName}</InformationCardHeaderTextTitle>

              <InformationCardHeaderTextEdit onClick={() => handleClick('edit', index, false)}>
                Edit
              </InformationCardHeaderTextEdit>
            </FlexSpaceBetweenContainer>
            <FlexWithoutPaddingContainer>
              <InformationCardProfileExperienceJobText style={{marginRight: '15px'}}>
                {newEvents.position} | {newEvents.company}
              </InformationCardProfileExperienceJobText>
              <InformationCardProfileExperienceJobText>
                {format(new Date(newEvents.startDate), 'MMMM dd yyyy')} -{' '}
                {format(new Date(newEvents.endDate), 'MMMM dd yyyy')}
              </InformationCardProfileExperienceJobText>
            </FlexWithoutPaddingContainer>

            <FlexContainer>
              <InformationCardProfileHeaderText>
                {newEvents.eventDescription}
              </InformationCardProfileHeaderText>
            </FlexContainer>
          </InformationCardExperianceBorder>
        </>
      )}
    </>
  )
}
