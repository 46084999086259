import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {useField} from 'formik'
import React from 'react'
import StyledTextField from '../mui-text-field/StyledTextField'

export default function FormikTimePicker({name, ...other}: {name: string}) {
  const [field, meta, helpers] = useField(name)
  
  const configTextField: {error: boolean; helperText: string} = {error: false, helperText: ''}
  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        {...other}
        value={field.value}
        onChange={(newValue) => {
          helpers.setValue(newValue)
        }}
        DialogProps={{
          PaperProps: {
            sx: {'& *:focus': {outline: 'none'}},
          },
        }}
        PopperProps={{
          sx: {'& *:focus': {outline: 'none'}},
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            {...configTextField}
            onBlur={() => {
              helpers.setTouched(true)
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
