import React from 'react'
import Box from '@mui/material/Box'
import type {TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'

interface HeaderProps {
  data: TServiceOrderTemplatePayload
}

export default function PrependedTemplateTotalHeader({data}: HeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 16px',
        borderBottom: '1px solid #CDCED9',
      }}>
      <div
        style={{
          fontSize: '15px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          padding: '10px 20px 10px 16px',
        }}>
        Total Templates ({data?.totalServiceOrderTemplates})
      </div>
    </Box>
  )
}
