/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {CompanyMainStyles} from './styles/company-main.styles'
import AddButton from '@ui/buttons/AddButton'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import {push} from 'connected-react-router'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetPositionQuery} from '~/src/store/apis/position-api'
import PositionUserTable from './PositionUserTable'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import type {IPosition} from '~/src/models/position'
import {useCurrentUser} from '~/src/store/slice-hooks'
import Title from '../../layout/Title'

export default function PositionsDetails(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const dispatch = useAppDispatch()
  const [targetPosition, setTargetPosition] = useState<IPosition>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching} = useGetPositionQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const {currentUser} = useCurrentUser()

  const {
    data: addressUser,
    refetch: fetchUser,
    error: getCompanyError,
    isLoading: loadingUser,
    isFetching: fetchingUser,
  } = useGetOneCompanyQuery(currentUser.companyId)

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    refetch()
  }, [currPageIdx, refetch])

  return (
    <CompanyMainStyles.Container>
      <Title
        pageTitle="Positions & Departments Details"
        button={
          <AddButton
            data-testid="add-position-button"
            onClick={() => {
              dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.ADD_POSITION}`))
            }}>
            Add New Position
          </AddButton>
        }
      />
      <ErrorBoundary>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <PositionUserTable
            data={data}
            addressUser={addressUser}
            setTargetPosition={setTargetPosition}
            currentPageNumber={currPageIdx}
            onPaginationChange={handlePaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles.Container>
  )
}
