/* eslint-disable jsx-a11y/anchor-is-valid */
import AddButton from '@ui/buttons/AddButton'
import ErrorBoundary from '@ui/error'
import Icon from '@ui/icons'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import type {ICompanyBase} from '~/src/models'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompaniesQuery} from '~/src/store/apis/company-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import CompanyTable from './CompanyTable'
import S from './styles/company-main.styles'
import UpdateStatusModal from './UpdateCompanyStatusModal'

export type TCompanyStatus = 'Active' | 'Rejected' | 'Disabled' | 'Pending' | 'Creating' | 'Failed'

export default function CompanyList(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [selectedStatus, setSelectedStatus] = useState<TCompanyStatus>()
  const [targetCompany, setTargetCompany] = useState<ICompanyBase>()
  const dispatch = useAppDispatch()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, isLoading, isFetching, error, refetch} = useGetCompaniesQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  const handleModalUnload = () => {
    setSelectedStatus(undefined)
    setTargetCompany(undefined)
  }

  const statusModalVisible = !!(targetCompany && selectedStatus)

  useEffect(() => {
    refetch()
  }, [currPageIdx, refetch])

  return (
    <S.Container>
      <Title
        pageTitle="Manage Companies"
        button={
          <AddButton
            onClick={() => {
              dispatch(push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.ONBOARDING_COMPANY}`))
            }}>
            Onboard New Company
          </AddButton>
        }
      />
      <ErrorBoundary error={error} data-testid="role-access-boundary">
        <S.TopRow>
          {data && (
            <>
              <S.LeftContainer />
              <S.RightContainer>
                <S.FilterContainer>
                  <S.Filter>
                    <Icon icon="filter" />
                    Filter
                  </S.Filter>
                  <S.BlueText>All Companies ({data.totalCompanies})</S.BlueText>
                </S.FilterContainer>
              </S.RightContainer>
            </>
          )}
        </S.TopRow>

        <LoadingBoundary isLoading={isLoading || isFetching}>
          {data && (
            <CompanyTable
              data={data.companies}
              addPagination={data.totalCompanies}
              setSelectedStatus={(s) => setSelectedStatus(s)}
              setTargetCompany={(c) => setTargetCompany(c)}
              currentPageNumber={currPageIdx}
              onPaginationChange={handlePaginationChange}
              isAgency={false}
            />
          )}
        </LoadingBoundary>

        {statusModalVisible && (
          <UpdateStatusModal
            selectedStatus={selectedStatus}
            targetCompany={targetCompany}
            onModalClose={handleModalUnload}
            isAgency={false}
          />
        )}
      </ErrorBoundary>
    </S.Container>
  )
}
