const lowerCase = /[a-z]/
const upperCase = /[A-Z]/
const number = /[0-9]/
const specialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
const min8Characters = /.{8,}/

export default {
  lowerCase,
  upperCase,
  number,
  specialCharacter,
  min8Characters,
}