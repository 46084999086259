// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React, {useEffect, useState} from 'react'
import type {Applicant, Assignment} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateAssignHistSingleEdit from './MspCandidateAssignHistSingleEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm: () => void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
  newAssignmentsArray: any
}

export default function MspCandidateAssignHistDisplay({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  newAssignmentsArray,
  values,
}: IProps) {
  const [editProfileAssignHistState, setEditProfileAssignHistState] = useState('saveChanges')

  useEffect(() => {
    setEditProfileAssignHistState('saveChanges')
  }, [oldApplicant.id])

  return (
    <>
      <DashboardMainStyles>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderContainer>
              <InformationCardHeaderPadding>
                <InformationCardHeaderTextTitle>Assignment History</InformationCardHeaderTextTitle>
              </InformationCardHeaderPadding>
            </InformationCardHeaderContainer>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            {newAssignmentsArray.map((newAssign: Assignment, index: number) => {
              return (
                <div key={index}>
                  <MspCandidateAssignHistSingleEdit
                    handleSubmit={handleSubmit}
                    index={index}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                    newAssign={newAssign}
                  />
                </div>
              )
            })}
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStyles>
    </>
  )
}
