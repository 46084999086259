import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function GoogleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='social / Google'>
				<g id='Google'>
					<rect id='Rectangle' width='48' height='48' rx='24' fill='#EA4335' />
					<path
						id='Vector'
						d='M8.01003 24.0208C7.69024 15.6011 15.0651 7.82036 23.4932 7.71941C27.7888 7.35304 31.9679 9.02115 35.2187 11.7418C33.8852 13.208 32.5284 14.6573 31.0791 16.0141C28.2173 14.2754 24.7717 12.9504 21.427 14.1271C16.0322 15.6633 12.7652 22.0329 14.7616 27.3104C16.4148 32.8223 23.1191 35.8472 28.3733 33.5318C31.094 32.5576 32.8877 30.0473 33.6749 27.3499C30.5568 27.2878 27.438 27.3266 24.3199 27.2405C24.3121 25.3853 24.3043 23.5372 24.3121 21.682C29.512 21.6743 34.7196 21.6587 39.9273 21.7053C40.2471 26.2508 39.5764 31.1154 36.6214 34.7636C32.575 39.9713 25.0992 41.4996 19.0176 39.4567C12.5633 37.3367 7.86955 30.8344 8.01003 24.0208Z'
						fill='white'
					/>
				</g>
			</g>
		</Svg>
	);
}
