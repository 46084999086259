export type TCognitoRole =
  | 'platformadmin'
  | 'companyadmin'
  | 'agencyadmin'
  | 'agencyuser'
  | 'ollicandidate'
  | 'general_admin'

export type TDisplayedRole =
  | 'Platform Administrator'
  | 'Company Administrator'
  | 'Agency Administrator'
  | 'Agency User'
  | 'Candidate'
  | 'General Administrator'

export type TOperation = 'Create' | 'Read' | 'Update' | 'Delete' | 'UpdateStatus'

export function displayToCognito(arg: unknown): TCognitoRole {
  switch (arg) {
    case 'Platform Administrator':
      return 'platformadmin'
    case 'Company Administrator':
      return 'companyadmin'
    case 'Agency Administrator':
      return 'agencyadmin'
    case 'Agency User':
      return 'agencyadmin'
    case 'Candidate':
      return 'ollicandidate'
    case 'General Administrator':
      return 'general_admin'
    default:
      throw Error(`Could not transform [${arg}] into a Cognito role.`)
  }
}

export function cognitoToDisplay(arg: unknown): TDisplayedRole {
  switch (arg) {
    case 'platformadmin':
      return 'Platform Administrator'
    case 'companyadmin':
      return 'Company Administrator'
    case 'agencyadmin':
      return 'Agency Administrator'
    case 'agencyuser':
      return 'Agency Administrator'
    case 'ollicandidate':
      return 'Candidate'
    case 'general_admin':
      return 'General Administrator'
    default:
      throw Error(`Could not transform [${arg}] into a readable role.`)
  }
}

export interface IAccessItem {
  entityType: string
  operations: TOperation[]
  scope: string
}

export interface IRole {
  id: string
  name: TCognitoRole
  accessList: IAccessItem[]
}
