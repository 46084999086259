import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {IconButton, Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/material/styles'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {formatShift} from '@utils'
import React, {Suspense, useState} from 'react'
import {NavLink} from 'react-router-dom'
import type {IAgencyBase, TAgenciesPayload} from '~/src/models'
import type {AgencyDistribution} from '~/src/models/jobOrder'
import type {IOrderDistribution} from '~/src/models/order'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useUpdatePositionOrderDistributionMutation} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import {StyledTableCell} from '../../../ui/table-cell/StyledTableCell'

//bit of a complicanted function i found on a medium article about lazy loading
//the any types are good but anything else is giving errors as well so unsure what to put
export const lazyRetry = function (componentImport: any) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    )
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}
const AgencyCalendarOrderDialog = React.lazy(() =>
  // @ts-expect-error cant figure out red lining error and no documentation on it really
  lazyRetry(() => import('../Dialog/AgencyCalendarOrderDialog')),
)
type OrderDetailsTableProps = {
  allAgencies: TAgenciesPayload | undefined
  companyName: string | undefined
  currentAgency: IAgencyBase | undefined
  needed: number | void
  serviceOrder: IServiceOrder
  setOpen: (value: boolean) => void
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
  sortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'empty',
    numeric: false,
    disablePadding: true,
    label: '     ',
    align: 'center',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
    sortable: true,
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'Quantity',
    numeric: false,
    disablePadding: true,
    label: 'Quantity',
    align: 'left',
  },
  {
    id: 'pledged',
    numeric: true,
    disablePadding: true,
    label: 'Pledged',
    align: 'left',
  },
  {
    id: 'agency',
    numeric: false,
    disablePadding: true,
    label: 'Agency',
    align: 'left',
  },
  {
    id: 'distribution',
    numeric: true,
    disablePadding: true,
    label: 'Distribution',
    align: 'left',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: true,
    label: 'Status',
    align: 'left',
  },
]

function PositionRow(props: Partial<IServiceOrder>): JSX.Element {
  const theme = useTheme()
  const [calendarModalVisible, setCalendarModalVisible] = useState(false)

  const {data: positionData} = useGetPositionByIdQuery({
    id: props.order.positionId,
  })
  const agencyDistributions: {name: string; id: string; status: string}[] = []

  const totalRequestFromOrder = props.order.orderDistribution.agencyDistributions
    .flat()
    .map(
      (agencyQuantityDistribution: {agencyQuantityDistributions: AgencyDistribution}) =>
        agencyQuantityDistribution.agencyQuantityDistributions,
    )
    .flat()
    .map((distribution: {requested: number}) => {
      if (distribution?.requested) {
        return distribution.requested
      } else {
        return 0
      }
    })
    .reduce((prev: number, curr: number) => prev + curr, 0)

  const totalPledgedFromOrder = props.order?.orderDistribution.agencyDistributions
    ?.flat()
    .map(
      (agencyQuantityDistribution: {agencyQuantityDistributions: IOrderDistribution}) =>
        agencyQuantityDistribution.agencyQuantityDistributions,
    )
    .flat()
    .map((distribution: {pledged: number}) => {
      if (distribution?.pledged) {
        return distribution.pledged
      } else {
        return 0
      }
    })
    ?.reduce((prev: number, curr: number) => prev + curr, 0)

  const numAgencies = props.order?.orderDistribution?.agencyDistributions?.length
  let numAgenciesLabel = '(0) Agencies'
  if (numAgencies === 1) {
    numAgenciesLabel = '(1) Agency'
  } else if (numAgencies !== 0 && numAgencies !== null && numAgencies !== undefined) {
    numAgenciesLabel = `(${numAgencies}) Agencies`
  }

  if (props.allAgencies) {
    props.order?.orderDistribution?.agencyDistributions?.forEach(
      (distribution: {
        agencyId: string
        agencyStatus: string
        agencyQuantityDistributions: IOrderDistribution[]
      }) => {
        const item: {name: string; id: string; status: string} = {
          name: '',
          id: '',
          status: '',
        }
        const agencyFound = props.allAgencies?.agencies?.find(
          (agency: {id: string}) => agency?.id === distribution?.agencyId,
        )

        item.name = agencyFound?.name
        item.id = agencyFound?.id
        item.status = distribution.agencyStatus
        let totalRequested = 0
        let totalPledged = 0
        distribution.agencyQuantityDistributions.forEach((quantityItem) => {
          if (quantityItem.requested != null) {
            totalRequested += quantityItem.requested
          }
          if (quantityItem.pledged != null) {
            totalPledged += quantityItem.pledged
          }
        })
        item.quantity = totalRequested ? totalRequested : 0
        item.pledged = totalPledged ? totalPledged : 0
        if (agencyFound?.id == props?.currentAgency.id) {
          item.isYou = true
          agencyDistributions.unshift(item)
        } else {
          item.isYou = false
          agencyDistributions.push(item)
        }
      },
    )
  }

  const positionStatus =
    props.order.positionStatus === 'InProgress'
      ? 'In Progress'
      : props.order.positionStatus === 'AwaitingPledge'
      ? 'Awaiting Pledge'
      : props.order.positionStatus === 'NewOrder'
      ? 'New Order'
      : props.order.positionStatus === 'Pledged'
      ? 'Pledged'
      : props.order.positionStatus === 'Committed'
      ? 'Committed'
      : props.order.positionStatus === 'CommitmentAccepted'
      ? 'Commitment Accepted'
      : props.order.positionStatus === 'CommitmentRejected'
      ? 'Commitment Rejected'
      : null

  return (
    <>
      {positionData && (
        <>
          <Suspense fallback={<tr />}>
            {calendarModalVisible && (
              <AgencyCalendarOrderDialog
                allAgencies={props.allAgencies}
                currentAgency={props.currentAgency}
                order={props.order}
                positionData={positionData}
                serviceOrderAgencyId={props.serviceOrderAgencyId}
                modalOpen={calendarModalVisible}
                onModalClose={() => {
                  setCalendarModalVisible(false)
                }}
                onSave={props.handleDialog}
                serviceOrderNumber={props.serviceOrderNumber}
                companyName={props.companyName}
                totalNeeded={props.needed}
              />
            )}
          </Suspense>
          <>
            <TableRow key={props.index}>
              <StyledTableCell />

              <StyledTableCell component="td" scope="row" padding="normal" sx={{maxWidth: '200px'}}>
                {positionData.name}
              </StyledTableCell>
              <StyledTableCell>{formatShift(positionData)}</StyledTableCell>
              {/* sum of rows */}
              <StyledTableCell>{totalRequestFromOrder}</StyledTableCell>
              <StyledTableCell>{totalPledgedFromOrder ? totalPledgedFromOrder : 0}</StyledTableCell>

              <StyledTableCell>{numAgenciesLabel}</StyledTableCell>

              <StyledTableCell align="left">
                <IconButton
                  sx={{color: '#858585'}}
                  disabled={!props.isEditable}
                  onClick={() => {
                    setCalendarModalVisible(true)
                  }}>
                  <CalendarTodayIcon />
                </IconButton>
              </StyledTableCell>
              <StyledTableCell>{positionStatus}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </>
          <>
            {agencyDistributions.map((row, index) => {
              return (
                <TableRow key={index} style={{background: '#FAFAFA'}}>
                  <StyledTableCell style={{background: '#F5F6F8'}} />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell>{row.quantity}</StyledTableCell>
                  <StyledTableCell>{row.pledged}</StyledTableCell>
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    {row.isYou ? (
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                        <div>{row.name}</div>{' '}
                        <div style={{paddingLeft: '8px', color: theme.palette.grey[600]}}>
                          (you)
                        </div>
                      </Box>
                    ) : (
                      <div>{row.name}</div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell>{row.status}</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              )
            })}
          </>
        </>
      )}
    </>
  )
}

export default function AgencyOrderDetailsTable({
  serviceOrder,
  allAgencies,
  currentAgency,
  companyName,
  setOpen,
  needed,
}: OrderDetailsTableProps) {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updatePositionOrderDistribution] = useUpdatePositionOrderDistributionMutation()
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const theme = useTheme()

  const handlePaginationChange = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber)
  }

  const handleDialog = async (newAgencyDistributions: any, positionId: string) => {
    const serviceOrderId = serviceOrder.id
    const orderIndex = serviceOrder.orders.findIndex((item) => item.positionId == positionId)
    const orderDistributionCopy = JSON.parse(
      JSON.stringify(serviceOrder.orders[orderIndex]?.orderDistribution),
    ) as IOrderDistribution

    orderDistributionCopy.agencyDistributions = newAgencyDistributions

    let totalRequestedOnSplit = 0
    orderDistributionCopy.agencyDistributions?.forEach((t) => {
      t.agencyQuantityDistributions?.forEach((l: {requested: number}) => {
        totalRequestedOnSplit += l.requested
      })
    })

    const newDistribition = orderDistributionCopy.agencyDistributions?.map((t) => {
      const newMap = t.agencyQuantityDistributions?.map((l) => {
        const newIndividualDate = new Date(l.date)
        const convertedDateForEachDate = new Date(newIndividualDate.setHours(5, 0, 0)).toISOString()
        return {date: convertedDateForEachDate, pledged: l.pledged, requested: l.requested}
      })
      return {agencyId: t.agencyId, agencyQuantityDistributions: newMap}
    })

    orderDistributionCopy.agencyDistributions = newDistribition
    await updatePositionOrderDistribution({
      serviceOrderId,
      positionId,
      agencyId: loggedInAgencyId,
      body: orderDistributionCopy,
    })
      .unwrap()
      .then(() => {
        if (
          newAgencyDistributions[0].agencyQuantityDistributions[0].requested === needed ||
          totalRequestedOnSplit === needed
        ) {
          return setOpen(true)
        } else {
          setOpen(false)
        }
      })
  }
  const isEditable =
    serviceOrder.mspStatus === 'New Order' ||
    serviceOrder.mspStatus === 'Preparing Pledges' ||
    serviceOrder.mspStatus === 'Ready To Distribute'

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey[200]}`,
        }}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size="medium" padding="normal">
            <TableHead style={{borderBottom: '1px solid #CDCED9'}}>
              <TableRow>
                {headCells.map((headCell: HeadCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding="normal"
                    style={{borderBottom: 'none'}}>
                    {headCell.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="left" padding="normal" style={{borderBottom: 'none'}}>
                  <IconButton
                    component={NavLink}
                    to={{
                      pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}/${serviceOrder?.id}/mspagency/summary`,
                    }}>
                    <FileDownloadIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceOrder.orders.map((order, index) => {
                return (
                  <PositionRow
                    allAgencies={allAgencies}
                    companyName={companyName}
                    currentAgency={currentAgency}
                    handleDialog={handleDialog}
                    isEditable={isEditable}
                    key={index}
                    needed={needed}
                    order={order}
                    serviceOrderAgencyId={serviceOrder.agencyId}
                    serviceOrderNumber={serviceOrder.orderNumber}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledPagination
          page={currentPageNumber}
          totalRecords={serviceOrder.orders.length}
          recordsPerPage={10}
          onChange={handlePaginationChange}
        />
      </Box>
    </>
  )
}
