import ServiceOrderCardMsp from '@ui/card/ServiceOrderCardMsp'
import useWindowDimensions from '@ui/custom-hook-height/CustomHeightHook'
import LoadingBoundary from '@ui/loader'
import React, {ReactElement, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const ContentContainer = styled('div')({
  maxWidth: '100%',
  width: '100%',
  paddingBottom: 75,
})

const JobOrderContainer = styled('div')({
  display: 'flex',
  maxWidth: '100%',
  width: '100%',
  marginTop: '30px',
})

const JobOrdersLabel = styled('div')({
  fontWeight: 600,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '24px',
})

const FlexSpaceBetweenContainer = styled('div')`
  display: flex;
  max-width: 1109px;
  width: 1109px;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (min-width: 2000px) {
    width: 1385px;
    max-width: 1385px;
  }
`

interface IProps {
  isMsp?: boolean
}

export default function ServiceOrderDashboard({isMsp}: IProps): ReactElement {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const widthDimensions = useWindowDimensions()

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: 1,
    pageSize: widthDimensions.width > 1999 ? 5 : 4,
    filterBy: 'PendingOrders',
    agencyId: loggedInAgencyId,
  })

  const seeAllDirect = () => {
    if (isMsp) {
      return (
        <div>
          <NavLink
            style={{
              fontSize: '15px',
              verticalAlign: '-webkit-baseline-middle',
              color: 'grey',
            }}
            data-testid="to-service-orders"
            to={{
              pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`,
            }}>
            See All
          </NavLink>
        </div>
      )
    } else {
      return (
        <div>
          <NavLink
            style={{
              fontSize: '15px',
              verticalAlign: '-webkit-baseline-middle',
              color: 'grey',
            }}
            data-testid="to-service-orders"
            to={{
              pathname: `${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`,
            }}>
            See All
          </NavLink>
        </div>
      )
    }
  }

  useEffect(() => {
    void refetch()
  }, [refetch])

  return (
    <>
      <LoadingBoundary isLoading={isFetching || isLoading}>
        <ContentContainer>
          <FlexSpaceBetweenContainer>
            <JobOrdersLabel>Pending Service Orders</JobOrdersLabel>
            {seeAllDirect()}
          </FlexSpaceBetweenContainer>
          <JobOrderContainer>
            {data?.serviceOrdersModel.map((serviceOrder: IServiceOrder) => {
              return (
                <ServiceOrderCardMsp
                  serviceOrder={serviceOrder}
                  currentUserAgencyId={loggedInAgencyId}
                  currentServiceOrderPageNumber={1}
                  isdashboard={true}
                  agencyStatus={
                    !isMsp
                      ? serviceOrder.orders[0]?.orderDistribution?.agencyDistributions[0]
                          ?.agencyStatus
                      : undefined
                  }
                  key={serviceOrder.id}
                />
              )
            })}
          </JobOrderContainer>
        </ContentContainer>
      </LoadingBoundary>
    </>
  )
}
