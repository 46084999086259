import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined'
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/material/styles'
import EditVariantIcon from '@ui/icons/EditVariant'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {formatShift} from '@utils'
import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useCurrentUser} from '~/src/store/slice-hooks'

const OrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  TableInfo: styled.p`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}
type TemplateDetailsTableProps = {
  values: any
  compName: string
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
  sortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'empty',
    numeric: false,
    disablePadding: true,
    label: '     ',
    align: 'center',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
    sortable: true,
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'facility',
    numeric: false,
    disablePadding: true,
    label: 'Facility',
    align: 'left',
  },
  {
    id: 'Department',
    numeric: true,
    disablePadding: true,
    label: 'Department',
    align: 'left',
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: true,
    label: 'Manager',
    align: 'left',
  },
  {
    id: 'notes',
    numeric: true,
    disablePadding: true,
    label: 'Notes',
    align: 'left',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'left',
  },
]

function PositionRow(props: string | number | boolean | any): JSX.Element {
  const {data: positionData} = useGetPositionByIdQuery({
    id: props?.positionRow?.positionId,
  })

  return (
    <>
      {positionData && (
        <>
          <TableRow key={props?.index}>
            <StyledTableCell style={{border: 'none'}}>
              <Checkbox data-testid="order-details-checkbox" />
            </StyledTableCell>
            <StyledTableCell component="td" scope="row" padding="normal" sx={{maxWidth: '200px'}}>
              {positionData.name}
            </StyledTableCell>
            <StyledTableCell>{formatShift(positionData)}</StyledTableCell>
            <StyledTableCell>{positionData.facilityName}</StyledTableCell>
            <StyledTableCell>{positionData.departmentName}</StyledTableCell>
            <StyledTableCell>{positionData.manager}</StyledTableCell>
            <StyledTableCell>
              {' '}
              <IconButton>
                <NoteOutlinedIcon />
              </IconButton>
            </StyledTableCell>

            <StyledTableCell align="center">
              <IconButton sx={{color: '#858585'}}>
                <NavLink
                  data-testid="template-details-edit"
                  to={{
                    pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.EDIT_TEMPLATE}/${props.values.id}/${positionData.id}`,
                  }}>
                  <EditVariantIcon />
                </NavLink>
              </IconButton>
            </StyledTableCell>
          </TableRow>
        </>
      )}
    </>
  )
}

export default function TemplateDetailsTable({values, compName}: TemplateDetailsTableProps) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const theme = useTheme()

  const handlePaginationChange = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber)
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey[200]}`,
        }}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size="medium" padding="normal">
            <TableHead style={{borderBottom: '1px solid #CDCED9'}}>
              <TableRow>
                {headCells.map((headCell: HeadCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding="normal"
                    style={{borderBottom: 'none'}}>
                    {headCell.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.orders.map((positionRow, index) => {
                return (
                  <PositionRow
                    values={values}
                    compName={compName}
                    positionRow={positionRow}
                    key={index}
                  />
                )
              })}
            </TableBody>
          </Table>
          {/* <OrderDetailsStyles.AddContainer>
            <>
              <NavLink
                style={{textDecoration: 'none', color: '#000000'}}
                to={{
                  pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.EDIT_TEMPLATE}`,
                }}>
                <IconButton>
                  <Add />
                </IconButton>
                <span>Add Another Position</span>
              </NavLink>
            </>
          </OrderDetailsStyles.AddContainer> */}
        </TableContainer>
        <StyledPagination
          page={currentPageNumber}
          recordsPerPage={10}
          onChange={handlePaginationChange}
        />
      </Box>
    </>
  )
}
