import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import {FormikAutocomplete, FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FieldGroup from '@ui/field-group/FieldGroup'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import SwitchToggle from '@ui/toggle-switch/SwitchToggle'
import {push} from 'connected-react-router'
import {setHours, setMinutes, setSeconds} from 'date-fns'
import {Form, Formik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import type {IBaseAddress} from '~/src/models/baseAddress'
import type {IPrefillInputParams, IUpdatePrefillInputParams} from '~/src/models/prefill'
import {SIDEBAR_ROUTES} from '~/src/routes/constants'
import {useGetAllCompanyNamesQuery} from '~/src/store/apis/company-api'
import {useCreatePrefillMutation, useUpdatePrefillMutation} from '~/src/store/apis/prefill-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import {serializeBucketData, useBucket} from '../bucket/BucketList'
import BucketSettings from '../bucket/BucketSettings'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 900px;
`

const PaperContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1097px;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px rgba(16, 30, 115, 0.06);
`

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
  height: 75px;
  font-size: 20px;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
`

const HeaderText = styled('div')`
  font-size: 20px;
  font-weight: 700;
`

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0px 0px 8px 8px;
  background: #fdfdfd;
  padding: 30px;
`

const VerticalFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 40px;
  margin-bottom: 25px;
`

const HorizontalFormContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  height: 40px;
  width: 120px;
  font-weight: normal;
` as typeof Button

const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IFormSubmitValues {
  companyName?: string
  dueDate?: Date | string
  worksiteLocation?: IBaseAddress
  requestedQuantity?: number
  title?: string
}

export default function AddOrEditPrefill(): React.ReactElement {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [, pathBase, pathAddOrEdit] = location.pathname.split('/')
  const isAdd = pathAddOrEdit === 'prefill-add'
  const [createPrefill] = useCreatePrefillMutation()
  const [updatePrefill] = useUpdatePrefillMutation()
  const {prefillToEdit: prefillData} = useAppSelector((state) => state.prefill)
  const {data: companyNames} = useGetAllCompanyNamesQuery({pageNumber: 1, pageSize: 99999})

  const {
    redCheck,
    setRedCheck,
    greenCheck,
    setGreenCheck,
    yellowCheck,
    setYellowCheck,
    noFlagCheck,
    setNoFlagCheck,
    selectedDistance,
    setSelectedDistance,
    previouslyWorkedAtText,
    setPreviouslyWorkedAtText,
    previouslyWorkedAtFilters,
    setPreviouslyWorkedAtFilters,
    previouslyWorkedAtValue,
    setPreviouslyWorkedAtValue,
    skillsText,
    setSkillsText,
    skillsFilters,
    setSkillsFilters,
    skillsValue,
    setSkillsValue,
    setFiltersToPass,
    init,
  } = useBucket()

  const [isActive, setIsActive] = useState<boolean>(isAdd ? false : prefillData?.active ?? false)

  const initialFormValues = {
    title: isAdd ? '' : prefillData?.title,
    companyName: isAdd ? undefined : prefillData?.companyName,
    worksiteLocation: {
      line1: isAdd ? '' : prefillData?.worksiteLocation?.line1,
      city: isAdd ? '' : prefillData?.worksiteLocation?.city,
      state: isAdd ? '' : prefillData?.worksiteLocation?.state,
      zipCode: isAdd ? '' : prefillData?.worksiteLocation?.zipCode,
      display: isAdd ? '' : prefillData?.worksiteLocation?.display,
    },
    requestedQuantity: isAdd ? undefined : prefillData?.requestedQuantity,
    dueDate: isAdd ? 'mm/dd/yyyy' : prefillData?.dueDate,
  } as IFormSubmitValues

  const handleSubmit = useCallback(
    async (formikValues: IFormSubmitValues) => {
      const allFiltersToPass = setFiltersToPass()
      if (isAdd) {
        // Set time to 5:00 pm
        let intermediateCalculation = setHours(formikValues.dueDate as Date, 17)
        intermediateCalculation = setMinutes(intermediateCalculation, 0)
        intermediateCalculation = setSeconds(intermediateCalculation, 0)
        const params = JSON.parse(JSON.stringify(formikValues)) as IPrefillInputParams
        params.dueDate = intermediateCalculation.toISOString()
        params.requestedQuantity = Number(params.requestedQuantity)
        params.agencyId = loggedInAgencyId
        params.companyName = formikValues.companyName

        if (formikValues.companyName === '') {
          params.companyName = undefined
        }

        params.milesFromWorksite = allFiltersToPass.selectedDistance
          ? allFiltersToPass.selectedDistance
          : undefined
        params.clientsPreviouslyWorkedAt = allFiltersToPass.previouslyWorkedAtFilters
          ? allFiltersToPass.previouslyWorkedAtFilters
          : undefined
        params.skills = allFiltersToPass.skills ? allFiltersToPass.skills : undefined
        params.filterByFlags = allFiltersToPass.filterByFlags
          ? allFiltersToPass.filterByFlags
          : undefined

        void createPrefill(params)
      } else {
        const params = {} as IUpdatePrefillInputParams
        params.id = prefillData?.id as string
        params.active = isActive
        params.title = formikValues.title as string
        params.agencyId = loggedInAgencyId

        params.milesFromWorksite = allFiltersToPass.selectedDistance
          ? allFiltersToPass.selectedDistance
          : undefined
        params.clientsPreviouslyWorkedAt = allFiltersToPass.previouslyWorkedAtFilters
          ? allFiltersToPass.previouslyWorkedAtFilters
          : undefined
        params.skills = allFiltersToPass.skills ? allFiltersToPass.skills : undefined
        params.filterByFlags = allFiltersToPass.filterByFlags
          ? allFiltersToPass.filterByFlags
          : undefined
        void updatePrefill(params)
      }
      dispatch(push(`/${pathBase}${SIDEBAR_ROUTES.DASHBOARD}`))
    },
    [
      createPrefill,
      dispatch,
      isActive,
      isAdd,
      loggedInAgencyId,
      pathBase,
      prefillData?.id,
      setFiltersToPass,
      updatePrefill,
    ],
  )

  const title = isAdd ? 'New Pre-fill' : 'Edit Pre-fill'
  useEffect(() => {
    if (isAdd) {
      init(
        serializeBucketData({
          selectedDistance: undefined,
          previouslyWorkedAtFilters: [],
          skillsFilters: [],
          redCheck: true,
          greenCheck: true,
          yellowCheck: true,
          noFlagCheck: true,
        }),
      )
    } else {
      init({
        selectedDistance: prefillData?.milesFromWorksite,
        previouslyWorkedAtFilters: prefillData?.clientsPreviouslyWorkedAt,
        skills: prefillData?.skills,
        filterByFlags: prefillData?.filterByFlags,
      })
    }
  }, [
    init,
    isAdd,
    prefillData?.clientsPreviouslyWorkedAt,
    prefillData?.filterByFlags,
    prefillData?.milesFromWorksite,
    prefillData?.skills,
  ])

  return (
    <Container>
      <Title pageTitle={title} />
      <PaperContainer>
        <Header>
          <HeaderText>Pre-fill Details</HeaderText>
          {!isAdd && (
            <SwitchToggle
              checked={isActive}
              onLabel="Active"
              offLabel="Inactive"
              onChange={() => {
                setIsActive(!isActive)
              }}
            />
          )}
        </Header>
        <Body>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
              title: Yup.string().required('Required'),
              companyName: Yup.string(),
              worksiteLocation: Yup.object({
                line1: Yup.string(),
                city: Yup.string(),
                state: Yup.string(),
                zipCode: Yup.string(),
                display: Yup.string().required('Required'),
              }).nullable(),
              requestedQuantity: Yup.number()
                .integer()
                .moreThan(0)
                .typeError('Invalid format')
                .nullable()
                .required('Required'),
              dueDate: Yup.date().typeError('Required').required('Required').nullable(),
            })}>
            {({isSubmitting, setFieldValue, values, handleChange, errors, getFieldMeta}) => {
              return (
                <Form
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}>
                  <VerticalFormContainer>
                    <HorizontalFormContainer sx={{marginBottom: '25px'}}>
                      <FieldGroup label="Pre-fill Title" sx={{marginRight: '12px'}}>
                        <FormikTextField
                          name="title"
                          placeholder="Pre-fill Title Here"
                          inputProps={{maxLength: 50}}
                        />
                      </FieldGroup>
                      <FieldGroup label="Company Name" sx={{marginLeft: '12px'}} isOptional>
                        <FormikAutocomplete
                          name="companyName"
                          options={companyNames ?? []}
                          placeholder="Company Name Here"
                          freeSolo={true}
                          defaultValue={isAdd ? undefined : prefillData?.companyName}
                          disabled={!isAdd}
                        />
                      </FieldGroup>
                    </HorizontalFormContainer>
                    <HorizontalFormContainer>
                      <FieldGroup label="Location" sx={{marginRight: '12px'}}>
                        <FormikGooglePlacesAutocomplete
                          address={values.worksiteLocation?.display}
                          error={errors.worksiteLocation?.display === ''}
                          name="worksiteLocation"
                          setFieldValue={setFieldValue}
                          inputProps={{maxLength: 50}}
                          disabled={!isAdd}
                        />
                      </FieldGroup>
                      <HorizontalFormContainer sx={{width: '100%', marginLeft: '12px'}}>
                        <FieldGroup label="Quantity" sx={{marginRight: '12px'}}>
                          <FormikTextField
                            name="requestedQuantity"
                            placeholder="Quantity"
                            inputProps={{maxLength: 50}}
                            disabled={!isAdd}
                          />
                        </FieldGroup>
                        <FieldGroup label="Due Date" sx={{marginLeft: '12px'}}>
                          <FormikDatePicker
                            name="dueDate"
                            minDate={new Date()}
                            onClose={() => {}}
                            error={errors.dueDate === '' && getFieldMeta('dueDate').touched}
                            disabled={!isAdd}
                          />
                        </FieldGroup>
                      </HorizontalFormContainer>
                    </HorizontalFormContainer>
                  </VerticalFormContainer>
                  <BucketSettings
                    selectedDistance={selectedDistance}
                    setSelectedDistance={setSelectedDistance}
                    previouslyWorkedAtFilters={previouslyWorkedAtFilters}
                    setPreviouslyWorkedAtFilters={setPreviouslyWorkedAtFilters}
                    previouslyWorkedAtText={previouslyWorkedAtText}
                    setPreviouslyWorkedAtText={setPreviouslyWorkedAtText}
                    previouslyWorkedAtValue={previouslyWorkedAtValue}
                    setPreviouslyWorkedAtValue={setPreviouslyWorkedAtValue}
                    skillsFilters={skillsFilters}
                    setSkillsFilters={setSkillsFilters}
                    skillsText={skillsText}
                    setSkillsText={setSkillsText}
                    skillsValue={skillsValue}
                    setSkillsValue={setSkillsValue}
                    redCheck={redCheck}
                    setRedCheck={setRedCheck}
                    greenCheck={greenCheck}
                    setGreenCheck={setGreenCheck}
                    yellowCheck={yellowCheck}
                    setYellowCheck={setYellowCheck}
                    noFlagCheck={noFlagCheck}
                    setNoFlagCheck={setNoFlagCheck}
                    containerSx={{marginLeft: '27px'}}
                  />
                  <ButtonBox sx={{marginTop: '25px'}}>
                    <StyledButton
                      variant="outlined"
                      color="secondary"
                      type="reset"
                      sx={{mr: '20px'}}
                      onClick={() => {
                        dispatch(push(`/${pathBase}${SIDEBAR_ROUTES.DASHBOARD}`))
                      }}>
                      Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="primary" type="submit">
                      {isAdd ? 'Finish' : 'Save'}
                    </StyledButton>
                  </ButtonBox>
                </Form>
              )
            }}
          </Formik>
        </Body>
      </PaperContainer>
    </Container>
  )
}
