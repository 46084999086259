import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio, {RadioProps} from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import type {Theme} from '@mui/material/styles'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import CheckboxUnchecked from '@ui/icons/CheckboxUnchecked'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import React from 'react'
import type {IAgencyBase} from '~/src/models'

function CustomRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckboxChecked />}
      icon={<CheckboxUnchecked />}
      {...props}
    />
  )
}

interface IProps {
  onCancel: () => void
  onMove: (agencyId: string) => void
  modalOpen: boolean
  allAgencies?: IAgencyBase[]
}

export default function SelectAgenciesDialog({
  modalOpen,
  onCancel,
  onMove,
  allAgencies,
}: IProps): React.ReactElement {
  const [agencyId, setAgencyId] = React.useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencyId(event.target.value)
  }

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={() => {
        onCancel()
        setAgencyId('')
      }}
      PaperProps={{
        sx: {
          minWidth: (theme: Theme) => theme.breakpoints.values.md,
          width: 1600,
          height: 'max-content',
        },
      }}>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          borderBottom: '1px solid black',
          fontSize: '16px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{marginLeft: 40, padding: 20}}>Select Agency To Move To </div>
      </div>
      <Box
        sx={{
          maxWidth: 1600,
          marginTop: 5,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
        }}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
          }}>
          <Box sx={{marginBottom: '5%', fontWeight: 500}}>
            Select the Agency you would like to move the candidate to
          </Box>
          <Box sx={{border: '1px solid #F1F1F1', width: '100%'}}>
            <FormControl sx={{width: '100%'}}>
              <RadioGroup value={agencyId} onChange={handleChange} sx={{my: 1}}>
                <TableContainer sx={{height: '430px'}}>
                  <Table
                    sx={{maxWidth: 'auto', height: 'max-content'}}
                    aria-labelledby="tableTitle"
                    size="medium"
                    padding="normal">
                    {allAgencies?.map((agency) => {
                      return (
                        <TableRow
                          hover
                          sx={{height: 65}}
                          role="checkbox"
                          tabIndex={-1}
                          key={agency.id}>
                          <StyledTableCell padding="checkbox" align="left" sx={{width: '100%'}}>
                            <FormControlLabel
                              key={agency.id}
                              value={agency.id}
                              control={<CustomRadio sx={{marginRight: '30px'}} />}
                              label={agency.name}
                              sx={{marginLeft: '13px'}}
                            />
                          </StyledTableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              paddingTop: '50px',
            }}>
            <Button
              variant="outlined"
              color="secondary"
              type="reset"
              sx={{width: 120, fontWeight: 'normal'}}
              onClick={() => {
                onCancel()
                setAgencyId('')
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ml: 2, width: 120, fontWeight: 'normal'}}
              onClick={() => {
                onMove(agencyId)
                setAgencyId('')
              }}>
              Move
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
