import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

const Svg = styled.svg``

export default function CodeIcon (props: HTMLAttributes<unknown>): JSX.Element {
  return (
    <Svg {...props} focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='CodeIcon'>
      <path
        fill='currentColor'
        d='M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z' />
    </Svg>
  )
}
