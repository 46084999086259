import {useTheme} from '@mui/material/styles'
import type {PaletteColor, Color} from '@mui/material'

type TThemeColorName = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

type TPaletteColor = PaletteColor &
  Pick<Color, 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900>

type TShade = keyof TPaletteColor

type TSerializedColor = `${TThemeColorName}.${TShade}`

function useColor(color: TSerializedColor = 'primary.main') {
  const [themeColor, shade] = color.split('.') as [TThemeColorName, TShade]
  const theme = useTheme()

  return (theme.palette[themeColor] as TPaletteColor)[shade]
}

type TColor = ReturnType<typeof useColor>

export type {TSerializedColor, TColor, TPaletteColor, TShade}
export default useColor
