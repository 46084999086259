import {useField} from 'formik'
import React from 'react'
import SwitchToggle, {TMode} from './SwitchToggle'

interface IProps {
  name: string
  onLabel?: string
  offLabel?: string
  onChange?: (event: object, checked: boolean) => void
  mode?: TMode
  inputProps?: object
}

export default function FormikSwitchToggle({
  name,
  onLabel = 'On',
  offLabel = 'Off',
  mode = 'center',
  onChange = () => {},
  inputProps,
}: IProps) {
  const [field, meta, helpers] = useField(name)
  return (
    <SwitchToggle
      checked={field.value}
      mode={mode}
      onLabel={onLabel}
      offLabel={offLabel}
      inputProps={inputProps}
      onChange={(event, checked) => {
        onChange(event, checked)
        helpers.setValue(checked)
      }}
    />
  )
}
