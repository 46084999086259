import LoadingBoundary from '@ui/loader'
import React, {useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {useGetDocumentByEntityIdQuery} from '~/src/store/apis/document-api'
import {useAppSelector} from '~/src/store/store-hooks'
import {DocumentApplicantContext} from '../../../store/context/DocumentApplicantContext'
import FolderListView from './FolderListView'
import SignedDocumentsMain from './SignedDocumentsMain'
import {
  DocumentContainer,
  DocumentContainerMain,
  HeaderContainer,
  HeaderContainerMargin,
  HeaderText,
} from './styles/documentStyles'

interface IProps {
  singleApplicant: Applicant
}

export default function DocumentsMain({singleApplicant}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [signedDocumentView, setSignedDocumentView] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState()
  const [buttonSubmitting, setButtonSubmitting] = useState(false)

  const {data: documents, isLoading} = useGetDocumentByEntityIdQuery({
    entityId: singleApplicant.id,
    agencyId: singleApplicant.agencyId,
  })

  // always pass in applicants agency id
  return (
    <DocumentContainerMain>
      <DocumentApplicantContext.Provider
        value={{singleApplicant, documents, loggedInAgencyId: singleApplicant.agencyId}}>
        <DocumentContainer>
          <HeaderContainer>
            <HeaderContainerMargin>
              <HeaderText>Documents</HeaderText>
            </HeaderContainerMargin>
          </HeaderContainer>
          {isLoading ? (
            <LoadingBoundary isLoading={isLoading} />
          ) : signedDocumentView ? (
            <SignedDocumentsMain
              buttonSubmitting={buttonSubmitting}
              documentId={documents?.id}
              selectedFolder={selectedFolder}
              setButtonSubmitting={setButtonSubmitting}
              setIsSubmitting={setIsSubmitting}
              setSignedDocumentView={setSignedDocumentView}
              setSelectedFolder={setSelectedFolder}
            />
          ) : (
            <FolderListView
              buttonSubmitting={buttonSubmitting}
              documents={documents}
              setButtonSubmitting={setButtonSubmitting}
              setIsSubmitting={setIsSubmitting}
              setSelectedFolder={setSelectedFolder}
              setSignedDocumentView={setSignedDocumentView}
            />
          )}
        </DocumentContainer>
      </DocumentApplicantContext.Provider>
    </DocumentContainerMain>
  )
}
