import styled from 'styled-components'
import {colors, font, resets} from '~/src/config/theme'

export const CompanyMainStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  TopRow: styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
  `,
  LeftContainer: styled.div`
    column-gap: 30px;
    display: flex;
  `,
  RightContainer: styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: ${font.sizes.smaller};
    font-weight: 400;
    row-gap: 28px;
  `,
  FilterContainer: styled.div`
    display: flex;
  `,
  Filter: styled.label`
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    display: flex;
  `,
  BlueText: styled.a`
    color: ${colors.primary};
    font-weight: 700;
    margin-left: 4px;
  `,
  LinkList: styled.ul`
    display: flex;
  `,
  Link: styled.li`
    color: #808080;
    list-style-type: none;

    + li::before {
      content: '\u007C';
      padding: 0 8px;
    }
  `,
  Prepend: styled.div`
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding-left: 68px;
    padding-right: 30px;
    width: 100%;
  `,
  Text: styled.div`
    font-size: ${font.sizes.small};
  `,
  IconButton: styled.button`
    ${resets.common}
    align-items: center;
    column-gap: 5px;
    display: flex;
    font-size: inherit;

    svg {
      background-color: ${colors.primary};
      border-radius: 50%;
      color: ${colors.white};
      height: 13px;
      padding: 2px;
      width: 13px;
    }
  `,
}
