import {Grid, Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import Mui from '@ui/mui'
import {formatShift} from '@utils'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import type {IOrder} from '~/src/models/order'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetJobOrdersByServiceOrderIdsQuery} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useAppSelector} from '~/src/store/store-hooks'
import CompanySOTimeline from '../Timeline/CompanySOTimeline'
import ClickableDateGroup from './ClickableDateGroup'

const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
  `,
}

const CardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;

  min-width: 260px;
  max-width: 260px;
  background: #e0e0e0;
  border-radius: 16px;
  margin-bottom: 40px;
  margin-right: 24px;
`

const ServiceOrderText = styled('div')({
  font: 'normal 700 18px Open Sans',
  color: '#ffffff',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const CustomizedDivider = styled(Mui.Divider)({
  background: 'white',
  marginTop: 3,
  marginBottom: 3,
})

const CustomizedHeaderTypography = styled(Mui.Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: 'white',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  paddingTop: 5,
})

const HeaderContainer = styled(Mui.Box)({
  overflow: 'hidden',
  minHeight: '170px',
  maxHeight: '250px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '13px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const HeaderSubContainer = styled(Mui.Box)({
  overflowX: 'hidden',
  display: 'flex',
  padding: '15px',
  width: '260px',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'left',
})
const TimelineContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 7.5rem;
  min-height: 7.5rem;
  margin-top: 27px;
  margin-left: 15px;
  margin-right: 15px;
  
  &::-webkit-scrollbar: {
    display: none;
  },
`

const FooterContainer = styled('div')`
  display: flex;
  overflow: hidden;
  text-align: center;
  min-height: 88px;
  max-height: 88px;
  width: 100%;
  background: #ebebeb;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 13px 13px;
`

const StatusHeaderTextInTimeline = styled('div')`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`

const StatusDescriptionTextInTimeline = styled('div')`
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
`

const FooterButtonColored = styled(Mui.Button)({
  margin: 'auto',
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  width: '75%',
  maxHeight: '40px',
  overflow: 'none',
  backgroundColor: '#03A9FC',

  '&:hover': {
    transform: 'scale(1.1)',
    transition: '0.3s ease-out',
    cursor: 'pointer',
    backgroundColor: '#03A9FC',
  },
  color: 'white',
  borderRadius: '10px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const FooterButtonUnColored = styled(Mui.Button)({
  margin: 'auto',
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  width: '75%',
  maxHeight: '40px',
  overflow: 'none',
  backgroundColor: '#F0F0F0',

  '&:hover': {
    transform: 'scale(1.1)',
    transition: '0.3s ease-out',
    cursor: 'pointer',
  },
  color: '#333333',
  border: '1px solid #333333',
  borderRadius: '10px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

interface IProps {
  agencyId?: string
  callback?: (date: Date) => void
  companyId?: string | undefined
  currentServiceOrderPageNumber: string
  filledRatioCount: number
  isCompany?: boolean
  position: IOrder[]
  serviceOrderId: string
  serviceOrderNumber?: string | undefined
  status?: string | null
  style?: object
}

export default function ServiceOrderCard({
  agencyId,
  callback = () => {},
  companyId,
  currentServiceOrderPageNumber,
  filledRatioCount,
  isCompany,
  position,
  serviceOrderNumber,
  serviceOrderId,
  status,
  style,
}: IProps) {
  const dispatch = useDispatch()
  const positionVariableForQuery = position[0]?.positionId ? position[0].positionId : ''
  const {data: positionData} = useGetPositionByIdQuery({
    id: positionVariableForQuery,
  })
  const {data: companyData} = useGetCompanyNameByIdQuery({id: companyId})
  const [jobOrderIndex, setJobOrderIndex] = useState(0)
  const shift = formatShift(positionData)
  const positionDates = position[0]?.orderDistribution.orderQuantityDistributions.map(
    (item) => new Date(item.date),
  ) as Date[]
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const handleDateGroupCallback = (index: number) => {
    setJobOrderIndex(index)
    callback(positionDates[index] as Date)
  }

  const redirectToServiceOrderDetails = () => {
    if (isCompany) {
      dispatch(push(`/company-admin/manage-orders/${serviceOrderId}`))
    } else {
      dispatch(
        push(`/agency-admin/manage-orders/${serviceOrderNumber}`, {
          state: {
            currentPageNumber: currentServiceOrderPageNumber,
            compName: companyData,
            currentUserAgencyId: agencyId,
          },
        }),
      )
    }
  }

  const redirectToCandidateDetails = () => {
    dispatch(
      push(
        `${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.COMPANY_CANDIDATE_LIST}/${encodeURIComponent(
          serviceOrderId,
        )}`,
      ),
    )
  }

  const projectButtonSwitch = () => {
    switch (status) {
      case 'Submitting Order':
        return (
          <FooterButtonColored
            aria-label="Submitting Order Button To Company Service Order Details"
            onClick={redirectToServiceOrderDetails}>
            <Box>Submit Order</Box>
          </FooterButtonColored>
        )

      case 'Order Filled':
        return (
          <FooterButtonColored
            aria-label="Order Filled Button To Company Candidate Details"
            onClick={redirectToCandidateDetails}>
            <Box>{status}</Box>
          </FooterButtonColored>
        )

      default:
        return (
          <FooterButtonUnColored
            aria-label="View Order Button To Company Service Order Details"
            onClick={redirectToServiceOrderDetails}>
            <Box>View Order</Box>
          </FooterButtonUnColored>
        )
    }
  }

  const {
    data: jobOrders,
    refetch: jobOrderRefetch,
    error,
    isLoading,
    isFetching,
  } = useGetJobOrdersByServiceOrderIdsQuery({
    serviceOrderIds: [serviceOrderId],
    agencyId: loggedInAgencyId,
  })

  const singleJobOrder = jobOrders ? jobOrders[0]?.jobOrders : []
  const totalQuantity = position[0]?.quantity
  const fillRate = (filledRatioCount / totalQuantity) * 100

  const getJobOrderStatus = () => {
    switch (singleJobOrder?.[jobOrderIndex]?.status) {
      case 'FillingOrder':
        return 'Filling Order'
      case 'ReadyToFill':
        return 'Ready To Fill'
      case 'Filled':
        return 'Order Filled'

      default:
        return 'Filling Order'
    }
  }

  const getJobOrderDescriptionStatus = () => {
    switch (singleJobOrder?.[jobOrderIndex]?.status) {
      case 'FillingOrder':
        return 'Your order is being filled by MSP.'
      case 'ReadyToFill':
        return 'Your order is being filled by MSP.'
      case 'Filled':
        return 'Your order has been filled.'

      default:
        return 'Your order is being filled by MSP.'
    }
  }

  const getCompanyDescriptionStatus = () => {
    switch (status) {
      case 'Submitting Order':
        return 'Awaiting order submission.'
      case 'Processing Order':
        return 'Your order is being processed by MSP.'
      case 'Confirming Order':
        return 'The MSP is working on your order.'
      case 'Reviewing Order':
        return 'Your order is being reviewed by MSP.'
      case 'Filling Order':
        return 'Your order is being filled by MSP.'

      case 'Order Filled ':
        return 'Your order has been filled.'

      default:
        return 'Awaiting order submission.'
    }
  }

  useEffect(() => {
    void jobOrderRefetch()
  }, [jobOrderRefetch])

  //made a variable to make sure there is a job order made and doesnt error out the page and displays the correct timelines
  const singleJobOrderUndefinedBoolean =
    singleJobOrder === undefined ||
    singleJobOrder[jobOrderIndex] == undefined ||
    singleJobOrder.length === 0

  return (
    <>
      <DashboardMainStyles.Container style={style}>
        <LoadingBoundary isLoading={isLoading}>
          <ErrorBoundary error={error}>
            <CardContainer>
              <HeaderContainer
                style={{
                  backgroundColor: '#03A9FC',
                }}>
                <HeaderSubContainer>
                  <Tooltip title={`${positionData?.name}`}>
                    <ServiceOrderText>{`${positionData?.name}`}</ServiceOrderText>
                  </Tooltip>
                  <Tooltip title={shift}>
                    <ServiceOrderText>{shift}</ServiceOrderText>
                  </Tooltip>
                  <ServiceOrderText>{`Service Order #${serviceOrderNumber}`}</ServiceOrderText>
                  <Tooltip title={positionData?.manager}>
                    <CustomizedHeaderTypography>{`Manager: ${positionData?.manager}`}</CustomizedHeaderTypography>
                  </Tooltip>
                  <CustomizedDivider />
                  <div style={{marginBottom: 5}}>
                    <Grid item container>
                      <Grid item xs>
                        <CustomizedHeaderTypography>Overall Fill</CustomizedHeaderTypography>
                      </Grid>
                      <Grid item xs>
                        <CustomizedHeaderTypography>Fill Rate</CustomizedHeaderTypography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs>
                        <ServiceOrderText>
                          {filledRatioCount}/{totalQuantity}
                        </ServiceOrderText>
                      </Grid>
                      <Grid item xs>
                        <ServiceOrderText>{fillRate}%</ServiceOrderText>
                      </Grid>
                    </Grid>
                  </div>
                  <ClickableDateGroup
                    count={4}
                    dateArray={positionDates}
                    callback={handleDateGroupCallback}
                    isdashboard={false}
                    sx={{position: 'relative', left: '-32px', marginBottom: '1px'}}
                  />
                </HeaderSubContainer>
              </HeaderContainer>
              {singleJobOrderUndefinedBoolean && status === 'Filling Order' ? (
                <TimelineContainer>
                  <StatusHeaderTextInTimeline>Reviewing Order</StatusHeaderTextInTimeline>
                  <div style={{marginBottom: 5}}>
                    <CompanySOTimeline status="Reviewing Order" />
                  </div>
                  <StatusDescriptionTextInTimeline>
                    {getCompanyDescriptionStatus()}
                  </StatusDescriptionTextInTimeline>
                </TimelineContainer>
              ) : singleJobOrderUndefinedBoolean && status !== 'Filling Order' ? (
                <TimelineContainer>
                  <StatusHeaderTextInTimeline>{status}</StatusHeaderTextInTimeline>
                  <div style={{marginBottom: 5}}>
                    <CompanySOTimeline status={status} />
                  </div>
                  <StatusDescriptionTextInTimeline>
                    {getCompanyDescriptionStatus()}
                  </StatusDescriptionTextInTimeline>
                </TimelineContainer>
              ) : singleJobOrder?.[jobOrderIndex] ? (
                <TimelineContainer>
                  <StatusHeaderTextInTimeline>{getJobOrderStatus()}</StatusHeaderTextInTimeline>
                  <div style={{marginBottom: 5}}>
                    <CompanySOTimeline status={getJobOrderStatus()} />
                  </div>
                  <StatusDescriptionTextInTimeline>
                    {getJobOrderDescriptionStatus()}
                  </StatusDescriptionTextInTimeline>
                </TimelineContainer>
              ) : (
                <div />
              )}

              <FooterContainer>{projectButtonSwitch()}</FooterContainer>
            </CardContainer>
          </ErrorBoundary>
        </LoadingBoundary>
      </DashboardMainStyles.Container>
    </>
  )
}
