/* eslint-disable complexity */
import Box from '@mui/material/Box'
import {FormikAutocomplete} from '@ui/autocomplete'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {IUserBase} from '~/src/models'
import type {
  ApplicantInterview,
  ApplicantInterviewFormikSubmit,
  ApplicantInterviewGeneralFormik,
} from '~/src/models/applicant'
import {
  AvailabilityQuestions,
  BehavioralQuestionsSubset,
  BigBold,
  GreySubTitle,
  InformationCardContainer,
  InitialQuestions,
  NewInterview,
  QAndADropdown,
  QAndATextArea,
  QuestionsBox,
  StyledButton,
  TInterviewTypes,
} from './CommonInterviewComponents'

interface IProps {
  calculateSubmit: (template: TInterviewTypes, values: ApplicantInterviewFormikSubmit) => void
  selectedInterview?: ApplicantInterview
  currentUser?: IUserBase | null
}

const GeneralForm = ({calculateSubmit, selectedInterview, currentUser}: IProps) => {
  return (
    <Formik
      onSubmit={(values) => {
        void calculateSubmit('general_labor', values as unknown as ApplicantInterviewFormikSubmit)
      }}
      initialValues={
        {
          company: selectedInterview?.company ?? [],
          position: selectedInterview?.position ?? [],
          employmentType: selectedInterview?.employmentType ?? [],

          discovery:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'discovery')
              ?.value ?? null,
          employed:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'employed')
              ?.value ?? null,
          dislike:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'dislike')
              ?.value ?? null,
          like:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'like')
              ?.value ?? null,
          extraDuties:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'extraDuties')
              ?.value ?? '',

          coldEnvironment:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'coldEnvironment',
            )?.value ?? null,
          foodProduction:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'foodProduction',
            )?.value ?? null,
          foodProductionDuration:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'foodProductionDuration',
            )?.value ?? '',
          safetyTraining:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'safetyTraining',
            )?.value ?? null,
          safteyTrainingType:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'safteyTrainingType',
            )?.value ?? '',
          heavyLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'heavyLift')
              ?.value ?? null,
          lightLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'lightLift')
              ?.value ?? null,
          howManyLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'howManyLift')
              ?.value ?? '',
          standing:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'standing')
              ?.value ?? null,
          whyNotStand:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'whyNotStand')
              ?.value ?? '',
          languages:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'languages')
              ?.value ?? null,
          transportation:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'transportation',
            )?.value ?? null,
          forklift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'forklift')
              ?.value ?? null,
          steelToedShoes:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'steelToedShoes',
            )?.value ?? null,

          wage:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'wage')
              ?.value ?? '',
          flexible:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'flexible')
              ?.value ?? null,
          drugTest:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'drugTest')
              ?.value ?? null,

          outsideInjury:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'outsideInjury',
            )?.value ?? null,
          reportInjuryWho:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWho',
            )?.value ?? null,
          reportInjuryWhen:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWhen',
            )?.value ?? null,
          reportLiquid:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportLiquid',
            )?.value ?? null,
          reportPolicyBreaking:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportPolicyBreaking',
            )?.value ?? null,
          difficultCoworker:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'difficultCoworker',
            )?.value ?? '',

          movedDepartment:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'movedDepartment',
            )?.value ?? '',
          notes: selectedInterview?.notes ?? '',
        } as unknown as ApplicantInterviewGeneralFormik
      }
      validationSchema={Yup.object({
        company: Yup.array().min(1, 'Required').required('Required'),
        position: Yup.array().min(1, 'Required').required('Required'),
        employmentType: Yup.array().min(1, 'Required').required('Required'),

        discovery: Yup.string().nullable(),
        employed: Yup.string().nullable(),
        dislike: Yup.string().nullable(),
        like: Yup.string().nullable(),
        extraDuties: Yup.string().nullable(),

        coldEnvironment: Yup.string().nullable(),
        foodProduction: Yup.string().nullable(),
        foodProductionDuration: Yup.string().nullable(),
        safetyTraining: Yup.string().nullable(),
        safteyTrainingType: Yup.string().nullable(),
        heavyLift: Yup.string().nullable(),
        lightLift: Yup.string().nullable(),
        howManyLift: Yup.string().nullable(),
        standing: Yup.string().nullable(),
        whyNotStand: Yup.string().nullable(),
        languages: Yup.string().nullable(),
        transportation: Yup.string().nullable(),
        forklift: Yup.string().nullable(),
        steelToedShoes: Yup.string().nullable(),

        wage: Yup.string().nullable(),
        flexible: Yup.string().nullable(),
        drugTest: Yup.string().nullable(),

        outsideInjury: Yup.string().nullable(),
        reportInjuryWho: Yup.string().nullable(),
        reportInjuryWhen: Yup.string().nullable(),
        reportLiquid: Yup.string().nullable(),
        reportPolicyBreaking: Yup.string().nullable(),
        difficultCoworker: Yup.string().nullable(),

        movedDepartment: Yup.string().nullable(),
        notes: Yup.string().nullable().required('Required'),
      })}>
      {({
        values,
        errors: subErrors,
        setValues,
        setFieldValue,
        isSubmitting,
        handleSubmit: handleSubmitSub,
      }) => {
        return (
          <Form>
            <InformationCardContainer sx={{justifyContent: 'flex-start'}}>
              <NewInterview
                name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                type="general_labor"
                setValue={setFieldValue}
                selectedInterview={selectedInterview}
                valuesSubset={{
                  company: values.company,
                  position: values.position,
                  employmentType: values.employmentType,
                }}
              />
              <BigBold style={{marginTop: '20px'}}>Interview Questions</BigBold>
              <QuestionsBox>
                <InitialQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Skills
                </GreySubTitle>
                <QAndADropdown
                  question="Are you able to work in a cold environment?"
                  component={
                    <FormikAutocomplete
                      name="coldEnvironment"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                />
                <QAndADropdown
                  question="Have you worked in food production before?"
                  component={
                    <FormikAutocomplete
                      name="foodProduction"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.foodProduction === 'Yes' && (
                  <QAndATextArea
                    question="If yes, how long? (must have 6 months+)"
                    component={
                      <FormikTextField
                        name="foodProductionDuration"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Have you ever received safety training before?"
                  component={
                    <FormikAutocomplete
                      name="safetyTraining"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.safetyTraining === 'Yes' && (
                  <QAndATextArea
                    question="If so, what type? (material handling, manufacturing, food production, general labor, other)"
                    component={<FormikTextField name="safteyTrainingType" multiline rows={3} />}
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Are you able to lift more than 50lbs? (HL)"
                  component={
                    <FormikAutocomplete
                      name="heavyLift"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Are you able to lift more than 25-30lbs? (LL)"
                  component={
                    <FormikAutocomplete
                      name="lightLift"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.lightLift === 'No' && (
                  <QAndATextArea
                    question="If no, how many without restriction?"
                    component={<FormikTextField name="howManyLift" />}
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Are you able to stand for long periods of time?"
                  component={
                    <FormikAutocomplete
                      name="standing"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.standing === 'No' && (
                  <QAndATextArea
                    question="If no, why?"
                    component={
                      <FormikTextField
                        name="whyNotStand"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="What languages do you speak?"
                  component={
                    <FormikAutocomplete
                      name="languages"
                      options={['English only', 'Spanish Only', 'Bilingual']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have reliable transportation?"
                  component={
                    <FormikAutocomplete
                      name="transportation"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have forklift certification? May we have a copy?"
                  component={
                    <FormikAutocomplete
                      name="forklift"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have steel toed shoes?"
                  component={
                    <FormikAutocomplete
                      name="steelToedShoes"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Availability
                </GreySubTitle>
                <AvailabilityQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Behavioral Questions: (We’d like to see our EE’s thought process)
                </GreySubTitle>
                <BehavioralQuestionsSubset />
                <QAndATextArea
                  question="What would you do if your manager/supervisor moved you into another 
                department, position, or shift? (For example: you’re a packer and are asked to 
                  operate a forklift, what would you do?)"
                  component={
                    <FormikTextField
                      name="movedDepartment"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="Additional Notes"
                  isRequired
                  component={
                    <FormikTextField
                      name="notes"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <StyledButton
                    onClick={() => {
                      handleSubmitSub()
                    }}
                    variant="contained"
                    color="primary"
                    sx={{marginTop: '20px', width: '168px'}}>
                    Finish & Save
                  </StyledButton>
                </Box>
              </QuestionsBox>
            </InformationCardContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default React.memo(GeneralForm)
