import {createSlice} from '@reduxjs/toolkit'
import type {TOrderPayload} from 'src/models/serviceOrder'
import type {TRootState} from '..'
import {serviceOrderApi} from '../apis/service-order-api'

const adjustedPageNumber = JSON.parse(localStorage.getItem('currentServiceOrderPageNumber') ?? '1')
const adjustedCompletedPageNumber = JSON.parse(
  localStorage.getItem('currentServiceOrderCurrentOrderPageNumber') ?? '1',
)

const initialState: {
  serviceOrders: TOrderPayload | null
  currentServiceOrderPageNumber: string
  currentServiceOrderCompletedPageNumber: string
} = {
  serviceOrders: null,
  currentServiceOrderPageNumber: adjustedPageNumber,
  currentServiceOrderCompletedPageNumber: adjustedCompletedPageNumber,
}

const serviceOrderSlice = createSlice({
  name: 'serviceOrderSlice',
  initialState,
  reducers: {
    initializeInput: (state, {payload}) => {
      state.serviceOrders = payload
    },
    updatePageNumber: (state, {payload}) => {
      state.currentServiceOrderPageNumber = payload
    },
    updateCompletedPageNumber: (state, {payload}) => {
      state.currentServiceOrderCompletedPageNumber = payload
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      serviceOrderApi.endpoints.getServiceOrder.matchFulfilled,
      (state, {payload}) => {
        state.serviceOrders = payload
      },
    )
  },
})

export const {initializeInput, updatePageNumber, updateCompletedPageNumber} =
  serviceOrderSlice.actions

export const selectServiceOrderState = (state: TRootState): TRootState['serviceOrder'] =>
  state.serviceOrder

export default serviceOrderSlice.reducer
