import React from 'react'
import S from '@ui/error/styles/error.styles'
import {isFetchBaseQueryError, isSerializedError, isNullOrUndefined} from '~/src/utils'
import type { IErrorProps, } from '@ui/error/types'
 
export function ErrorBoundary({testId, error, children}: IErrorProps) {
  if (isFetchBaseQueryError(error)) {
    return (
      <S.Container {...(testId && {'data-testid': testId})}>
        {error.status} <S.Message>{JSON.stringify(error.data)}</S.Message>
      </S.Container>
    )
  }

  if (isSerializedError(error)) {
    return (
      <S.Container {...(testId && {'data-testid': testId})}>
        {error.name} <S.Message>{error.message}</S.Message>
      </S.Container>
    )
  }

  if (!isNullOrUndefined(error)) {
    return (
      <S.Container {...(testId && {'data-testid': testId})}>
        Error <S.Message>Uncaught error</S.Message>
      </S.Container>
    )
  }

  return <>{children}</>
}
