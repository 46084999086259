import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function AddThickIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect
				x='6.12402'
				y='0.5'
				width='2.875'
				height='15'
				rx='1.4375'
				fill='currentColor'
				stroke='currentColor'
			/>
			<rect
				x='14.625'
				y='6.62509'
				width='2.75'
				height='14.125'
				rx='1.375'
				transform='rotate(90 14.625 6.62509)'
				fill='currentColor'
				stroke='currentColor'
			/>
		</Svg>
	);
}
