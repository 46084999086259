import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ArrowDownCircleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle cx='12' cy='12' r='11.5' stroke='currentColor' />
			<path
				d='M11.9804 15.0041C11.8494 15.0049 11.7195 14.9796 11.5983 14.9299C11.477 14.8801 11.3667 14.8068 11.2737 14.7141L7.2927 10.7141C7.1999 10.6209 7.12629 10.5102 7.07607 10.3883C7.02585 10.2665 7 10.136 7 10.0041C7 9.87223 7.02585 9.74166 7.07607 9.61984C7.12629 9.49802 7.1999 9.38733 7.2927 9.29409C7.38549 9.20085 7.49566 9.12689 7.6169 9.07643C7.73815 9.02597 7.8681 9 7.99933 9C8.13057 9 8.26051 9.02597 8.38176 9.07643C8.503 9.12689 8.61317 9.20085 8.70597 9.29409L11.9804 12.6041L15.2647 9.42409C15.3563 9.3218 15.4679 9.23959 15.5925 9.18261C15.7171 9.12562 15.8521 9.09508 15.989 9.0929C16.1259 9.09072 16.2618 9.11695 16.3881 9.16994C16.5145 9.22293 16.6286 9.30155 16.7233 9.40087C16.818 9.5002 16.8914 9.61809 16.9387 9.74719C16.986 9.87628 17.0063 10.0138 16.9983 10.1511C16.9903 10.2885 16.9542 10.4227 16.8922 10.5453C16.8303 10.668 16.7438 10.7765 16.6382 10.8641L12.6571 14.7241C12.4751 14.9004 12.2332 15.0005 11.9804 15.0041Z'
				fill='currentColor'
				fillOpacity='0.48'
			/>
		</Svg>
	);
}
