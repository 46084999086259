import React from 'react'
import {useLocation} from 'react-router-dom'
import {crumbCase} from '~/src/utils'
import {ErrorDisplay} from '@ui/error'
import styled from 'styled-components'
import {font} from '~/src/config/theme'

const PageTitleStyles = {
  Container: styled.h4`
    font-size: ${font.sizes.h4};
    font-weight: 400;
    line-height: 33px;
  `,
}

export default function PageTitle({title}: {title?: string}) {
  const {pathname} = useLocation()
  const [currentPath] = pathname.split('/').slice(-1)

  return currentPath ? (
    <PageTitleStyles.Container data-testid="page-title">
      {title ?? crumbCase(currentPath)}
    </PageTitleStyles.Container>
  ) : (
    <ErrorDisplay message="Could not route to path" />
  )
}
