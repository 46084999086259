import Dialog from '@mui/material/Dialog'
import {errorFunction} from '@utils/errorFunction'
import {Form as FormikForm, Formik} from 'formik'
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import ReusableSnackbar from '~/src/components/Snackbar/ReusableSnackbar'
import type {IGetDocumentByEntityId} from '~/src/models/document'
import {usePutDeleteFileMutation} from '~/src/store/apis/document-api'
import {DocumentApplicantContext} from '~/src/store/context/DocumentApplicantContext'
import {ReactComponent as XButton} from '../../../../assets/images/closeIcon.svg'
import {
  Body,
  ButtonBox,
  DeleteFileContainer,
  HeaderContainer,
  HeaderText,
  StyledButton,
} from './styles/AddDocumentDialogStyles'

interface IProps {
  documentId: string | undefined
  isOpen: boolean
  fileId: string
  folderInfo: Partial<IGetDocumentByEntityId>
  handleCancel(): void
  setIsSubmitting: (value: boolean) => void
  setSelectedFolder: React.Dispatch<React.SetStateAction<undefined>>
}

export default function DeleteFileDialog({
  documentId,
  isOpen,
  fileId,
  folderInfo,
  handleCancel,
  setIsSubmitting,
  setSelectedFolder,
}: IProps): React.ReactElement {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const [putDeleteFile] = usePutDeleteFileMutation()

  const initialFormValues = {
    documentId: documentId,
    folderName: folderInfo?.name,
    fileId: fileId,
  }

  const documentContext = useContext(DocumentApplicantContext)

  const handleSubmit = async (values) => {
    await putDeleteFile({
      agencyId: documentContext?.loggedInAgencyId,
      documentId: values.documentId,
      folderName: values.folderName,
      fileId: values.fileId,
    })
      .unwrap()
      .then((res) => {
        const matchResponseFile = res.folders.find(
          (folderInfo: {name: string}) => folderInfo.name === values.folderName,
        )
        setSelectedFolder(matchResponseFile)
        setIsSubmitting(true)
        handleCancel()
      })
      .catch((err) => {
        errorFunction(err, setIsSnackbarOpen)
      })
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {minWidth: 546, width: 546, height: 468, overflow: ' hidden'},
      }}>
      <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />

      <HeaderContainer>
        <HeaderText>Delete File</HeaderText>
        <XButton
          onClick={() => {
            handleCancel()
            setIsSnackbarOpen(false)
          }}
          style={{cursor: 'pointer'}}
        />
      </HeaderContainer>
      <Body style={{justifyContent: 'center'}}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={Yup.object({
            documentId: Yup.string(),
            folderName: Yup.string().required('Required'),
          })}>
          {({values}) => {
            return (
              <FormikForm>
                <DeleteFileContainer>
                  Are you sure you want to delete this file?
                </DeleteFileContainer>
                <ButtonBox>
                  <StyledButton
                    data-testid="user-myInfo-cancel-button"
                    aria-label="user-myInfo-cancel-button"
                    style={{marginRight: '25px'}}
                    onClick={() => {
                      handleCancel()
                      setIsSnackbarOpen(false)
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button">
                    Cancel
                  </StyledButton>
                  <StyledButton
                    data-testid="user-myInfo-saveFiles-button"
                    aria-label="user-myInfo-saveFiles-button"
                    sx={{color: 'white'}}
                    variant="contained"
                    color="primary"
                    type="submit">
                    Delete
                  </StyledButton>
                </ButtonBox>
              </FormikForm>
            )
          }}
        </Formik>
      </Body>
    </Dialog>
  )
}
