import OpenSansBold from '~/src/assets/fonts/OpenSans-Bold.ttf'
import OpenSansBoldItalic from '~/src/assets/fonts/OpenSans-BoldItalic.ttf'
import OpenSansExtraBold from '~/src/assets/fonts/OpenSans-ExtraBold.ttf'
import OpenSansExtraBoldItalic from '~/src/assets/fonts/OpenSans-ExtraBoldItalic.ttf'
import OpenSansItalic from '~/src/assets/fonts/OpenSans-Italic.ttf'
import OpenSansLight from '~/src/assets/fonts/OpenSans-Light.ttf'
import OpenSansLightItalic from '~/src/assets/fonts/OpenSans-LightItalic.ttf'
import OpenSansRegular from '~/src/assets/fonts/OpenSans-Regular.ttf'
import OpenSansSemiBold from '~/src/assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansSemiBoldItalic from '~/src/assets/fonts/OpenSans-SemiBoldItalic.ttf'

const FontFaces = `
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 800;
		src: url(${OpenSansExtraBold}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 800;
		src: url(${OpenSansExtraBoldItalic}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		src: url(${OpenSansBold}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 700;
		src: url(${OpenSansBoldItalic}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		src: url(${OpenSansSemiBold}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 600;
		src: url(${OpenSansSemiBoldItalic}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		src: url(${OpenSansRegular}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 400;
		src: url(${OpenSansItalic}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 300;
		src: url(${OpenSansLight}) format('truetype');
	}
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 300;
		src: url(${OpenSansLightItalic}) format('truetype');
	}

`

// prettier-ignore
export const typography = {
  openSans: [
    '"Open Sans"'
  ].join(', '),
  
 
}

export default FontFaces
