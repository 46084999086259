import AddMemberDialog from '@ui/dialog/AddMemberDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect, useState} from 'react'
import {TableInfo as S} from '~/src/components/common/styles/TabsStyles'
import UserTable from '~/src/components/common/users-and-roles/UserTable'
import type {IUserBase} from '~/src/models'
import {useGetUsersSortedByNameQuery} from '~/src/store/apis/user-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import AddButton from '../../ui/buttons/AddButton'
import EditMemberDialog from './EditMemberDialog'

export function CompanyAdminUsersMain(): React.ReactElement {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [targetUser, setTargetUser] = useState<IUserBase>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, refetch, error, isLoading, isFetching} = useGetUsersSortedByNameQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  return (
    <S.Container>
      <Title
        pageTitle="Users & Roles"
        button={<AddButton onClick={() => setAddModalVisible(true)}>Add New Member</AddButton>}
      />
      <ErrorBoundary error={error}>
        <S.Body>
          <LoadingBoundary isLoading={isLoading || isFetching}>
            {data && (
              <UserTable
                data={data}
                onOpenEditModal={(user) => {
                  setTargetUser(user)
                  setEditModalVisible(true)
                }}
                currentPageNumber={currPageIdx}
                onPaginationChange={handlePaginationChange}
              />
            )}
          </LoadingBoundary>
        </S.Body>

        <AddMemberDialog
          modalOpen={addModalVisible}
          onModalClose={() => {
            setAddModalVisible(false)
          }}
        />

        <EditMemberDialog
          modalOpen={editModalVisible && Boolean(targetUser)}
          targetUser={targetUser}
          onModalClose={() => {
            setEditModalVisible(false)
            setTargetUser(undefined)
          }}
        />
      </ErrorBoundary>
    </S.Container>
  )
}
