import {push} from 'connected-react-router'
import React from 'react'
import {useDispatch} from 'react-redux'
import {ReactComponent as FormatListBulletedIcon} from '~/src/assets/images/FormatListIcon.svg'
import {ReactComponent as SearchIcon} from '~/src/assets/images/SearchIcon.svg'
import type {IOrder} from '~/src/models/order'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {colors} from './../../../config/theme'
import CalendarDayGraphic from './CalendarDayGraphic'
import {
  ClientText,
  DashboardMainStyles,
  DetailsSubMidSectionContainer,
  DetailsSubMidSectionGreyContainer,
  FooterContainer,
  HeaderContainer,
  HeaderMarginFlexContainer,
  HeaderMarginFlexSpaceBetweenContainer,
  JobOrderCardContainer,
  JobOrderText,
  MidSectionContainer,
  PositionNameAndJOContainer,
  PositionText,
  ShiftText,
  SubFooterContainer,
  SubFooterContainer2,
} from './JobOrderCardStyles.styles'

interface IProps {
  client?: string | undefined
  distributionDate: Date | undefined | unknown
  isInConfigurator?: boolean
  matchedRow: boolean
  position?: IOrder[] | string | undefined
  serviceOrderNumber: string
  shift?: string
  style: object
}

export default function JobOrderDistributeOptionalCard({
  client,
  distributionDate,
  isInConfigurator,
  matchedRow,
  position,
  serviceOrderNumber,
  shift,
  style,
}: IProps) {
  const dispatch = useDispatch()

  const redirectToServiceOrderDetails = () => {
    if (!isInConfigurator) {
      dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`))
    }
  }

  return (
    <>
      <DashboardMainStyles.Container>
        <JobOrderCardContainer
          style={{
            background: matchedRow ? colors.primary : '#FFFFFF',
            cursor: isInConfigurator ? 'default' : '',
            ...style,
          }}
          onClick={redirectToServiceOrderDetails}>
          <HeaderContainer>
            <HeaderMarginFlexSpaceBetweenContainer>
              <HeaderMarginFlexContainer>
                <PositionNameAndJOContainer>
                  <JobOrderText
                    style={{
                      color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                    }}>{`Order #${serviceOrderNumber}`}</JobOrderText>
                </PositionNameAndJOContainer>
                <PositionNameAndJOContainer>
                  <PositionText
                    style={{
                      color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                    }}>{`${position}`}</PositionText>
                </PositionNameAndJOContainer>
                <ShiftText
                  style={{
                    color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                  }}>
                  {shift}
                </ShiftText>
                <ClientText
                  style={{
                    color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                  }}>
                  {client}
                </ClientText>
              </HeaderMarginFlexContainer>
              <HeaderMarginFlexContainer>
                <CalendarDayGraphic
                  isSelected={matchedRow}
                  colorUnselected="#8A8A8A"
                  date={new Date(distributionDate)}
                />
              </HeaderMarginFlexContainer>
            </HeaderMarginFlexSpaceBetweenContainer>
          </HeaderContainer>
          <MidSectionContainer>
            <>
              {matchedRow ? (
                <DetailsSubMidSectionContainer>Needs Distribution</DetailsSubMidSectionContainer>
              ) : (
                <DetailsSubMidSectionGreyContainer>
                  Needs Distribution
                </DetailsSubMidSectionGreyContainer>
              )}
            </>
          </MidSectionContainer>

          <FooterContainer style={{paddingBottom: '0px'}}>
            <SubFooterContainer>
              {/* <StarOutlineIcon
                style={{
                  color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                }}
              />
              <BookmarkBorderIcon
                style={{
                  color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                }}
              /> */}
            </SubFooterContainer>

            <SubFooterContainer2>
              {/* <ShareIcon
                style={{
                  color: matchedRow ? '#FFFFFF' : '400 #8A8A8A',
                }}
              /> */}
              <SearchIcon
                style={{
                  marginRight: '7px',
                }}
                fill="#858585"
                stroke="#858585"
              />
              <FormatListBulletedIcon stroke="#858585" />
            </SubFooterContainer2>
          </FooterContainer>
          {/* </HeaderContainer> */}
        </JobOrderCardContainer>
      </DashboardMainStyles.Container>
    </>
  )
}
