import {styled} from '@mui/material/styles'
import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {Applicant} from '~/src/models/applicant'
import CandidateSkillsForm from '../candidate-details/candidate-skills/CandidateSkillsForm'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

const Container = styled('div')`
  width: 764px;
`
interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

export default function JobOrderSummarySkills({
  handleSubmit,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates,
}: IProps) {
  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,
    // skills
    skills: oldApplicant.skills,
  }
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          skills: Yup.array().of(
            Yup.object({
              category: Yup.string().required('Required'),
              categorySkills: Yup.array()
                .of(Yup.string().required('Required'))
                .required('Required'),
            }),
          ),
        })}>
        {({setFieldValue, values, resetForm, errors}) => {
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <Container>
                  <JobOrderCandidateNameHeader
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    isAssignmentHistory={true}
                    oldApplicant={oldApplicant}
                    singleApplicant={values}
                  />

                  <CandidateSkillsForm
                    errors={errors}
                    handleSubmit={handleSubmit}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                    initialValues={initialFormValues.skills}
                  />
                </Container>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}
