import type {IPosition} from '../models/position'

export function formatShift(position: IPosition | undefined) {
  if (position === undefined) {
    return ''
  }
  return `${new Date(position.startTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
  })} - ${new Date(position.endTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
  })}`
}

export function formatShiftShowMinutes(position: IPosition | undefined) {
  if (position === undefined) {
    return ''
  }
  return `${new Date(position.startTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })} - ${new Date(position.endTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })}`
}

export function formatShiftAlternate(startTime: string | undefined, endTime: string | undefined) {
  if (startTime === undefined || endTime === undefined) {
    return ''
  }
  return `${new Date(startTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
  })} - ${new Date(endTime).toLocaleTimeString('En-US', {
    hour12: true,
    hour: 'numeric',
  })}`
}

export function formatShiftForDetailsTable(position: IPosition | undefined) {
  if (position === undefined) {
    return ''
  }

  let shiftStart = new Date(position.startTime)
    .toLocaleTimeString([], {
      hour: '2-digit',
    })
    .replace('M', '')
    .toLowerCase()
    .trim()

  let shiftEnd = new Date(position.endTime)
    .toLocaleTimeString([], {
      hour: '2-digit',
    })
    .replace('M', '')
    .toLowerCase()
    .trim()

  if (shiftStart.startsWith('0')) {
    shiftStart = shiftStart.replace('0', '')
  }

  if (shiftEnd.startsWith('0')) {
    shiftEnd = shiftEnd.replace('0', '')
  }

  return `${shiftStart}-${shiftEnd}`.split(' ').join('')
}
