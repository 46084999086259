import AddButton from '@ui/buttons/AddButton'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {useEffect} from 'react'
import styled, {css} from 'styled-components'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetServiceOrderTemplatesQuery} from '~/src/store/apis/service-order-api'
import {selectServiceOrderState, updatePageNumber} from '~/src/store/slices/service-order-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import ManageTemplatesTable from './ManageTemplatesTable'

const Container = styled('div')(
  ({theme}) => css`
    position: relative;
    flex: 1;
  `,
)

export default function ManageTemplates(): JSX.Element {
  const {currentServiceOrderPageNumber} = useAppSelector(selectServiceOrderState)
  const dispatch = useAppDispatch()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderTemplatesQuery({
    pageNumber: currentServiceOrderPageNumber,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const handlePaginationChange = (pageNumber: number) => {
    dispatch(updatePageNumber(pageNumber))
  }

  useEffect(() => {
    refetch()
  }, [currentServiceOrderPageNumber, refetch])

  useEffect(() => {
    handlePaginationChange(1)
  }, [])
  const addButton = (
    <AddButton
      data-testid="templates-add"
      onClick={() => {
        dispatch(
          push(
            `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.ADD_TEMPLATE}/${currentServiceOrderPageNumber}`,
          ),
        )
      }}>
      Create New Template
    </AddButton>
  )
  return (
    <Container>
      <Title pageTitle="Manage Templates" button={addButton} />
      <ErrorBoundary>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <ManageTemplatesTable
            data={data}
            currentPageNumber={currentServiceOrderPageNumber}
            onPaginationChange={handlePaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </Container>
  )
}
