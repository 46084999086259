import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import {css, styled, useTheme} from '@mui/material/styles'
import React from 'react'
import EulaContent from '~/src/pages/sign-in/EulaContent'
import {useAppSelector} from '~/src/store/store-hooks'

const HeaderText = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    padding-right: 20px;
    white-space: nowrap;
  `,
)

const NameAndDate = styled('div')(
  ({theme}) => css`
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    color: ${theme.palette.primary.main};
  `,
)

interface IProps {
  onModalClose(): void
  isModalOpen: boolean
}

export default function EulaDialog({onModalClose, isModalOpen}: IProps): JSX.Element {
  const theme = useTheme()
  const {currentUser} = useAppSelector((state) => state.root)
  const agreedToTermsDate = new Date(currentUser?.agreedToTermsDate as unknown as Date)
  const localTime = agreedToTermsDate.toLocaleDateString()

  function handleModalClose() {
    onModalClose()
  }

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={handleModalClose}
      PaperProps={{sx: {maxWidth: 1100}}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '40px',
          height: '600px',
        }}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingBottom: '25px',
            marginBottom: '40px',
            width: '100%',
            height: 'auto',
            borderBottom: '1px solid grey',
          }}>
          <HeaderText>Terms & Conditions</HeaderText>
          <NameAndDate>
            {currentUser?.firstName} {currentUser?.lastName} has agreed to these terms on{' '}
            {localTime}
          </NameAndDate>
        </Box>

        <div
          style={{
            width: '100%',
            border: '1px solid grey',
            overflowY: 'scroll',
            height: '400px',
            borderRadius: '4px',
            padding: '25px',
          }}>
          <EulaContent />
        </div>

        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingTop: '50px',
          }}>
          <Button
            variant="outlined"
            color="secondary"
            type="reset"
            sx={{width: 120, fontWeight: 'normal'}}
            onClick={handleModalClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
