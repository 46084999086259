import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import {StandardAutocomplete} from '@ui/autocomplete'
import React, {useState} from 'react'
import type {
  Applicant,
  ApplicantInterview,
  ApplicantInterviewFormikSubmit,
  CreateApplicantInterviewRequestBody,
  UpdateApplicantInterviewRequestBody,
} from '~/src/models/applicant'
import {
  useCreateApplicantInterviewMutation,
  useUpdateApplicantInterviewMutation,
} from '~/src/store/apis/applicants-api'
import {useGetAllCompanyNamesAndIdsQuery} from '~/src/store/apis/company-api'
import {useGetUsersByEmailsQuery} from '~/src/store/apis/user-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import ClericalForm from '../candidate-details/candidate-interview/ClericalForm'
import {
  BoldText,
  InformationCardContainer,
  StyledButton,
  TInterviewTypes,
  TViewMode,
} from '../candidate-details/candidate-interview/CommonInterviewComponents'
import GeneralForm from '../candidate-details/candidate-interview/GeneralForm'
import HistoryItem from '../candidate-details/candidate-interview/HistoryItem'
import RestaurantForm from '../candidate-details/candidate-interview/RestaurantForm'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

export const InformationCardHeader = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ebeff2;
  height: 56px;
  min-height: 56px;
  width: 100%;
`

interface IProps {
  selectedApplicant: Applicant
  applicantFromFormik: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

const JobOrderInterview = ({
  selectedApplicant,
  applicantFromFormik,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
}: IProps) => {
  const [templateDropdown, setTemplateDropdown] = useState<string>('')
  const [viewMode, setViewMode] = useState<TViewMode>('start')
  const [selectedInterview, setSelectedInterview] = useState<ApplicantInterview | undefined>(
    undefined,
  )

  const labelMap = new Map<string, TInterviewTypes>([
    ['General Labor', 'general_labor'],
    ['Clerical', 'clerical'],
    ['Restaurant', 'restaurant'],
  ])
  const [createApplicantInterview] = useCreateApplicantInterviewMutation()
  const [updateApplicantInterview] = useUpdateApplicantInterviewMutation()
  const {currentUser} = useCurrentUser()

  const {data: companyNamesAndIds} = useGetAllCompanyNamesAndIdsQuery({
    pageNumber: 1,
    pageSize: 99999,
  })

  const {data: usersAndEmails} = useGetUsersByEmailsQuery(
    selectedApplicant.interviews?.map((interview) => interview.modifiedBy ?? interview.createdBy) ??
      [],
  )

  const calculateSubmit = async (
    template: TInterviewTypes,
    values: ApplicantInterviewFormikSubmit,
  ) => {
    const dataToSubmit = {
      applicantId: selectedApplicant.id,
      interviewTemplate: template,
      company: values.company.map((data) => data.id),
      position: values.position,
      employmentType: values.employmentType.map((data) => data.id),
      notes: values.notes,
      interviewAnswers: Object.entries(values)
        .filter((item) => {
          return item[1] !== '' && item[1]
        })
        .filter((item) => !['company', 'position', 'employmentType', 'notes'].includes(item[0]))
        .map((item) => {
          return {
            key: item[0],
            value: item[1],
          }
        }),
    }
    if (selectedInterview) {
      const dataToUpdate = dataToSubmit as unknown as UpdateApplicantInterviewRequestBody
      dataToUpdate._id = selectedInterview._id
      await updateApplicantInterview({agencyId: selectedApplicant.agencyId, body: dataToUpdate})
    } else {
      await createApplicantInterview({
        agencyId: selectedApplicant.agencyId,
        body: dataToSubmit as CreateApplicantInterviewRequestBody,
      })
    }
    setSelectedInterview(undefined)
    setViewMode('start')
  }

  const renderMode = (mode: TViewMode) => {
    switch (mode) {
      case 'start':
        return (
          <>
            <InformationCardContainer
              sx={{justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
              <BoldText>Select Template & Start New Interview</BoldText>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}>
                <StandardAutocomplete
                  options={Array.from(labelMap.keys())}
                  value={templateDropdown}
                  placeholder="Select"
                  disableClearable
                  sx={{width: '200px', marginRight: '20px'}}
                  onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
                    setTemplateDropdown(value)
                  }}
                />
                <StyledButton
                  onClick={() => {
                    setViewMode(labelMap.get(templateDropdown) ?? 'start')
                  }}
                  variant={templateDropdown.length > 0 ? 'contained' : 'outlined'}
                  color="primary"
                  type="submit"
                  disabled={templateDropdown.length === 0}>
                  Start New Interview
                </StyledButton>
              </Box>
            </InformationCardContainer>
            <InformationCardContainer sx={{justifyContent: 'flex-start'}}>
              <InformationCardHeader sx={{paddingLeft: '15px'}}>
                Interview History
              </InformationCardHeader>
              <Box
                sx={{display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 20px'}}>
                {selectedApplicant.interviews?.map((interview, index) => {
                  return (
                    <HistoryItem
                      key={index}
                      isLast={index + 1 === selectedApplicant.interviews?.length}
                      interview={interview}
                      companyNamesAndIds={companyNamesAndIds}
                      usersAndEmails={usersAndEmails}
                      onClick={(clickedInterview) => {
                        setViewMode(interview.interviewTemplate as TViewMode)
                        setSelectedInterview(clickedInterview)
                      }}
                    />
                  )
                })}
              </Box>
            </InformationCardContainer>
          </>
        )
      case 'general_labor':
        return (
          <GeneralForm
            calculateSubmit={calculateSubmit}
            currentUser={currentUser}
            selectedInterview={selectedInterview}
          />
        )
      case 'clerical':
        return (
          <ClericalForm
            calculateSubmit={calculateSubmit}
            currentUser={currentUser}
            selectedInterview={selectedInterview}
          />
        )
      case 'restaurant':
        return (
          <RestaurantForm
            calculateSubmit={calculateSubmit}
            currentUser={currentUser}
            selectedInterview={selectedInterview}
          />
        )
      default:
        console.error(
          `${mode} is an invalid type; valid types are [start | general_labor | clerical | restaurant]`,
        )
        return <></>
    }
  }

  return (
    <>
      <JobOrderCandidateNameHeader
        candidateLoadingStatuses={candidateLoadingStatuses}
        refetchCandidates={refetchCandidates}
        oldApplicant={selectedApplicant}
        singleApplicant={applicantFromFormik}
      />
      {renderMode(viewMode)}
    </>
  )
}

export default React.memo(JobOrderInterview)
