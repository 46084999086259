import React, {useContext, useState} from 'react'
import type {IGetDocumentByEntityId} from '~/src/models/document'
import {DocumentApplicantContext} from '~/src/store/context/DocumentApplicantContext'
import {ReactComponent as FolderIconSmall} from '../../../assets/images/FolderIconSmall.svg'
import {ReactComponent as UploadIcon} from '../../../assets/images/UploadFileSvg.svg'
import AddDocumentDialog from './Dialogs/AddDocumentDialog'
import NewFolderDialog from './Dialogs/NewFolderDialog'

import DocumentFolders from './DocumentFolders'
import {
  BodyFolderContainer,
  ButtonContainer,
  ButtonContainerMargin,
  ButtonContainerSpacebetween,
  StyledButton,
} from './styles/documentStyles'

interface IProps {
  buttonSubmitting: boolean
  documents: IGetDocumentByEntityId | undefined
  setButtonSubmitting: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  setSignedDocumentView: (value: boolean) => void
  setSelectedFolder: React.Dispatch<React.SetStateAction<undefined>>
}

export default function FolderListView({
  buttonSubmitting,
  documents,
  setButtonSubmitting,
  setIsSubmitting,
  setSignedDocumentView,
  setSelectedFolder,
}: IProps) {
  const [addDocumentDialog, setAddDocumentDialog] = useState(false)
  const [createFolderDialog, setCreateFolderDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedEditFolder, setSelectedEditFolder] = useState()

  const documentContext = useContext(DocumentApplicantContext)

  const handleOpenCreateFolderDialog = () => {
    setCreateFolderDialog(true)
  }

  const handleCancelCreateFolderDialog = () => {
    setIsEdit(false)
    setSelectedEditFolder(undefined)
    setCreateFolderDialog(false)
  }

  const handleOpenAddDocumentDialog = () => {
    setAddDocumentDialog(true)
  }

  const handleCancelAddDocumentDialog = () => {
    setAddDocumentDialog(false)
  }

  const handleSelectedFolder = (fileArray: []) => {
    setSelectedFolder(fileArray)
    setSignedDocumentView(true)
  }
  return (
    <>
      <AddDocumentDialog
        buttonSubmitting={buttonSubmitting}
        documentId={documents?.id}
        folderList={documents?.folders}
        handleCancel={handleCancelAddDocumentDialog}
        isInFolder={false}
        isOpen={addDocumentDialog}
        setButtonSubmitting={setButtonSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
      <NewFolderDialog
        buttonSubmitting={buttonSubmitting}
        documentId={documents?.id}
        isEdit={isEdit}
        isOpen={createFolderDialog}
        handleCancel={handleCancelCreateFolderDialog}
        setButtonSubmitting={setButtonSubmitting}
        setIsSubmitting={setIsSubmitting}
        selectedEditFolder={selectedEditFolder}
      />

      <ButtonContainer>
        <ButtonContainerMargin>
          <ButtonContainerSpacebetween style={{justifyContent: 'normal'}}>
            <StyledButton
              aria-label="uploadFiles-button"
              data-testid="uploadFiles-button"
              onClick={handleOpenAddDocumentDialog}
              style={{marginRight: 30}}
              sx={{
                border: '1px solid #B3B3B3',
                fontSize: '12px',
                color: '#333',
              }}>
              <UploadIcon style={{marginRight: 5}} />
              <span style={{fontWeight: 500}}>Upload Files</span>
            </StyledButton>
            <StyledButton
              aria-label="newFolder-button"
              data-testid="newFolder-button"
              onClick={handleOpenCreateFolderDialog}
              sx={{
                border: '1px solid #B3B3B3',
                fontSize: '12px',
                color: '#333',
              }}>
              <FolderIconSmall style={{marginRight: 5}} height={18} width={22} />{' '}
              <span style={{fontWeight: 500}}>New Folder</span>
            </StyledButton>
          </ButtonContainerSpacebetween>
        </ButtonContainerMargin>
      </ButtonContainer>
      <BodyFolderContainer>
        {documentContext.documents?.folders?.map((folderInfo, folderInfoIndex) => {
          return (
            <DocumentFolders
              documentId={documents?.id}
              key={folderInfoIndex}
              folderInfo={folderInfo}
              folderInfoIndex={folderInfoIndex}
              handleSelectedFolder={handleSelectedFolder}
              setIsSubmitting={setIsSubmitting}
              setSelectedEditFolder={setSelectedEditFolder}
              setCreateFolderDialog={setCreateFolderDialog}
              setIsEdit={setIsEdit}
            />
          )
        })}
      </BodyFolderContainer>
    </>
  )
}
