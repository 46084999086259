import {Grid, MenuItem, Select, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import React, {useState} from 'react'
import type {TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import FormControl from '@mui/material/FormControl'

interface HeaderProps {
  data: TServiceOrderTemplatePayload
  onCompanySelection?: (event: any) => void
  selectedCompany?: string
  setSelectedCompany: any
}

function MenuRow(props: any): JSX.Element {
  const {data: companyData} = useGetCompanyNameByIdQuery({
    id: props.i,
  })

  return (
    <MenuItem
      value={props.i}
      onClick={(e) => {
        return props.handleChange(companyData)
      }}
      key={props.index}>
      {companyData}
    </MenuItem>
  )
}

export default function PrependedTemplateHeader({
  data,
  selectedCompany,
  onCompanySelection,
  setSelectedCompany,
}: HeaderProps) {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [open, setOpen] = React.useState(false)

  const uniqueCompanyNames = [...new Set(data?.serviceOrderTemplatesModel?.map((i) => i.companyId))]
  const handleChange = (e: any) => {
    setSelectedCompany(e)
    setOpen(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 65,
        justifyContent: 'space-between',

        padding: '10px 20px 10px 16px',
        borderBottom: '1px solid #CDCED9',
      }}>
      {data && (
        <Grid container>
          <Grid
            item
            xs
            style={{
              fontSize: '15px',
              fontWeight: 500,
              display: 'flex',

              padding: '10px 20px 10px 16px',
            }}>
            Total Templates ({data.totalServiceOrderTemplates})
          </Grid>
          <Grid item xs />
          <Grid
            item
            xs
            sx={{textAlign: 'right', fontSize: '16px', fontWeight: 500, padding: '10px'}}>
            Company:
          </Grid>
          <Grid item xs>
            <FormControl
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                minWidth: 250,
              }}>
              <Select
                onClose={() => {
                  setOpen(false)
                }}
                onOpen={() => {
                  setOpen(true)
                }}
                open={open}
                value={selectedCompany ?? 'none'}
                onChange={onCompanySelection}>
                <MenuItem value="Select Company">Select Company</MenuItem>
                {uniqueCompanyNames.map((i, index) => {
                  return (
                    <MenuRow
                      value={selectedCompany ? selectedCompany : ''}
                      handleChange={handleChange}
                      key={index}
                      i={i}>
                      {selectedCompany}
                    </MenuRow>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
