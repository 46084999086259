// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React from 'react'
import type {Activity, Applicant} from '~/src/models/applicant'
import {
  ActivityBoxContainer,
  DashboardMainStyles,
  FlexSpaceBetweenContainer,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateActivitySingleEdit from './MspCandidateActivitySingleEdit'
import {useGetUsersByEmailsQuery} from '~/src/store/apis/user-api'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  setFieldValue(field: string, value: string, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateActivityDisplay({
  handleSubmit,
  oldApplicant,
  setFieldValue,
  values,
}: IProps) {
  const allModifiedByEmails = values.activities.map((activity) => activity.modifiedBy)
  const allModifiedByEmailsDeduped = allModifiedByEmails.filter(
    (item, index) => allModifiedByEmails.indexOf(item) === index,
  )
  const {data: usersByEmails} = useGetUsersByEmailsQuery(allModifiedByEmailsDeduped)
  return (
    <>
      <DashboardMainStyles>
        <ActivityBoxContainer>
          {values.activities.map((activ: Activity, index: number) => {
            return (
              <FlexSpaceBetweenContainer key={index}>
                <MspCandidateActivitySingleEdit
                  activity={activ}
                  handleSubmit={handleSubmit}
                  index={index}
                  oldApplicant={oldApplicant}
                  setFieldValue={setFieldValue}
                  values={values}
                  usersAndEmails={usersByEmails}
                />
              </FlexSpaceBetweenContainer>
            )
          })}
        </ActivityBoxContainer>
      </DashboardMainStyles>
    </>
  )
}
