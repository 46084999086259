import {Alert, Button, Snackbar} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import AddButton from '@ui/buttons/AddButton'
import ServiceOrderCard from '@ui/card/ServiceOrderCard'
import useWindowDimensions from '@ui/custom-hook-height/CustomHeightHook'
import CreateOrderDropdown from '@ui/dropdown/CreateOrderDropdown'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {format} from 'date-fns'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Title from '~/src/components/layout/Title'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {selectServiceOrderState, updatePageNumber} from '~/src/store/slices/service-order-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'

export const CompanyMainStyles = styled('div')(
  ({theme}) => css`
    position: relative;
    flex: 1;
  `,
)

const ContainerBox = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    p: 1;
    borderradius: 1;

    ${theme.breakpoints.up('xl')} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      max-width: 100%;
      p: 1;
      borderradius: 1;
      flex-wrap: wrap;
    }
  `,
)

const PaginationBox = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    margin-top: -50px;
    p: 1;
    borderradius: 1;
  `,
)

interface IState {
  openSnackbar: boolean | undefined
  orderNumber: number | undefined
  type: string | undefined
}

export default function ManageOrders(): JSX.Element {
  const location = useLocation()
  const locationState = location.state as IState | undefined
  const {currentServiceOrderPageNumber} = useAppSelector(selectServiceOrderState)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const dispatch = useAppDispatch()
  const {height, width} = useWindowDimensions()
  const [serviceOrderIndex, setServiceOrderIndex] = useState<number | undefined>(undefined)
  const [dateIndex, setDateIndex] = useState<number>(0)

  localStorage.setItem('currentServiceOrderPageNumber', currentServiceOrderPageNumber)
  const pageNumberParsed = JSON.parse(currentServiceOrderPageNumber)
  const [open, setOpen] = useState(locationState?.openSnackbar ? locationState.openSnackbar : false)
  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: pageNumberParsed,
    pageSize: height > 1500 ? 8 : 4,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })
  const handlePaginationChange = (pageNumber: number) => {
    dispatch(updatePageNumber(pageNumber))
  }

  useEffect(() => {
    void refetch()
  }, [currentServiceOrderPageNumber, refetch])

  const totalOrders = data?.totalServiceOrders
  const positionDates = data?.serviceOrdersModel[0]?.orders[0]
    ? data.serviceOrdersModel[0].orders[0].orderDistribution.orderQuantityDistributions.map(
        (item) => new Date(item.date),
      )
    : []
  const handleCallback = (date: Date) => {
    const month = format(date, 'MMM')
    const day = format(date, 'dd')

    if (!data || totalOrders === 0) {
      setServiceOrderIndex(undefined)
    } else if (totalOrders === 1) {
      setServiceOrderIndex(0)
    } else {
      const foundIndex = data.serviceOrdersModel.findIndex((element) => {
        const elementDate = new Date(element.createdDate)
        const elementMonth = format(elementDate, 'MMM')
        const elementDay = format(elementDate, 'dd')
        return month === elementMonth && day === elementDay
      })
      setServiceOrderIndex(foundIndex)
    }

    const foundDateIndex = positionDates.findIndex((element) => {
      const elementDate = new Date(element)
      const elementMonth = format(elementDate, 'MMM')
      const elementDay = format(elementDate, 'dd')
      return month === elementMonth && day === elementDay
    })
    setDateIndex(foundDateIndex)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <CompanyMainStyles>
      <Title
        pageTitle="Manage Orders"
        button={
          <CreateOrderDropdown
            label="Create New Order"
            CustomButton={AddButton as typeof Button}
            top="50px"
          />
        }
      />
      {locationState?.openSnackbar && (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! Service Order {locationState.orderNumber} has been Created!
          </Alert>
        </Snackbar>
      )}
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <ContainerBox>
            {data?.serviceOrdersModel.map((row, index) => {
              return (
                <ServiceOrderCard
                  agencyId={row.agencyId}
                  callback={handleCallback}
                  companyId={row.companyId}
                  currentServiceOrderPageNumber={currentServiceOrderPageNumber}
                  filledRatioCount={0}
                  isCompany={true}
                  key={index}
                  position={row.orders}
                  serviceOrderId={row.id}
                  serviceOrderNumber={row.orderNumber}
                  status={row.companyStatus}
                />
              )
            })}
          </ContainerBox>
          <PaginationBox>
            <StyledPagination
              page={Number(currentServiceOrderPageNumber)}
              totalRecords={totalOrders}
              recordsPerPage={4}
              onChange={handlePaginationChange}
            />
          </PaginationBox>
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles>
  )
}
