import {push} from 'connected-react-router'
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import Layout from '~/src/components/layout/Layout'
import Dashboard from '~/src/components/platform/dashboard'
import ManageCompanies from '~/src/components/platform/manage-companies'
import GeneralUsers from '~/src/components/platform/users-and-roles/GeneralUsers'
import GeneralAdmins from '~/src/components/platform/users-and-roles/GeneralAdmins'
import AddOrEditGa from '../components/platform/users-and-roles/AddOrEditGa'
import {SIDEBAR_ROUTES} from '~/src/routes'
import {useSignoutMutation} from '~/src/store/apis/auth-api'
import {getCurrentSession} from '~/src/store/cognito'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppDispatch} from '~/src/store/store-hooks'
import S from '../components/common/styles/AdminStyles'
import AgenciesMain from '../components/platform/manage-companies/AgenciesMain'
import AccountDetails from '../components/platform/onboarding/AccountDetails'
import OnboardingAgency from '../components/platform/onboarding/OnboardingAgency'
import OnboardingCompany from '../components/platform/onboarding/OnboardingCompany'
import {ImportWizardMain} from '../components/common/import-wizard/ImportWizardMain'

export default function PlatformAdminPage() {
  const {path, url} = useRouteMatch()
  const {currentUser} = useCurrentUser()
  const dispatch = useAppDispatch()
  const [signout] = useSignoutMutation()

  useEffect(() => {
    const fetchCurrentSession = async () => {
      const session = await getCurrentSession()
      if (!session?.isValid()) {
        // Logout and reroute
        await signout({email: currentUser?.emailAddress as string}).unwrap()
        dispatch(push('/signin'))
      }
    }
    const timeoutVar = setInterval(() => {
      void fetchCurrentSession()
    }, 30000)
    return () => {
      clearInterval(timeoutVar)
    }
  }, [currentUser?.emailAddress, dispatch, signout])

  return (
    <Layout variant="platform">
      <S.Container>
        <Switch>
          <Route path={path + SIDEBAR_ROUTES.DASHBOARD} component={Dashboard} />
          <Route
            exact
            path={path + SIDEBAR_ROUTES.MANAGE_COMPANIES_DETAILS}
            component={AccountDetails}
          />
          <Route path={path + SIDEBAR_ROUTES.MANAGE_COMPANIES} component={ManageCompanies} />
          <Route path={path + SIDEBAR_ROUTES.MANAGE_AGENCIES} component={AgenciesMain} />
          <Route path={path + SIDEBAR_ROUTES.ONBOARDING_AGENCY} component={OnboardingAgency} />
          <Route path={path + SIDEBAR_ROUTES.ONBOARDING_COMPANY} component={OnboardingCompany} />
          <Route exact path={path + SIDEBAR_ROUTES.USERS_AND_ROLES + SIDEBAR_ROUTES.GENERAL_USERS} component={GeneralUsers} />
          <Route exact path={path + SIDEBAR_ROUTES.USERS_AND_ROLES + SIDEBAR_ROUTES.GENERAL_ADMINS} component={GeneralAdmins} />
          <Route exact path={path + SIDEBAR_ROUTES.USERS_AND_ROLES + SIDEBAR_ROUTES.GENERAL_ADMINS + SIDEBAR_ROUTES.ADD_ADMIN} component={AddOrEditGa} />
          <Route exact path={path + SIDEBAR_ROUTES.USERS_AND_ROLES + SIDEBAR_ROUTES.GENERAL_ADMINS + SIDEBAR_ROUTES.EDIT_ADMIN} component={AddOrEditGa} />
          <Route path={path + SIDEBAR_ROUTES.IMPORT} component={ImportWizardMain} />
          <Redirect exact from={path} to={url + SIDEBAR_ROUTES.DASHBOARD} />
        </Switch>
      </S.Container>
    </Layout>
  )
}
