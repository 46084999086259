import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import type {Theme} from '@mui/material/styles'
import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import React from 'react'
import {useAppDispatch} from '~/src/store/store-hooks'

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const Header = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  fontSize: '18px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  minHeight: '50px',
  paddingLeft: '60px',
})

const OrderPlaced = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '28px',
  fontWeight: 600,
  marginTop: 50,
})

const OrderPlacedSubText = styled('div')({
  display: 'flex',
  color: '#8A8A8A',
  justifyContent: 'center',
  alignContent: 'center',
  fontSize: '14px',
  fontWeight: 400,
  marginTop: 30,
})

interface IProps {
  isOpen: boolean
  isIconVisible?: boolean
  header?: string
  bigBlurb?: string
  smallBlurb?: string
  confirmButtonLabel?: string
  handleSubmit(): void
}

export default function BasicSuccessDialog({
  isOpen,
  isIconVisible = true,
  header = '',
  bigBlurb = '',
  smallBlurb = '',
  confirmButtonLabel = 'Done',
  handleSubmit,
}: IProps): React.ReactElement {
  const dispatch = useAppDispatch()

  return (
    <Dialog
      fullWidth
      open={isOpen}
      PaperProps={{
        sx: {minWidth: (theme: Theme) => theme.breakpoints.values.md, width: 1600, height: 500},
      }}>
      <Header>{header}</Header>
      <Box
        sx={{
          maxWidth: 1600,
          m: 5,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
        }}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
          }}>
          {isIconVisible && (
            <IconContainer>
              <CheckCircleOutline sx={{width: 100, height: 100, color: '#3DB429'}} />
            </IconContainer>
          )}
          <OrderPlaced>{bigBlurb}</OrderPlaced>
          <OrderPlacedSubText>{smallBlurb}</OrderPlacedSubText>
          <Mui.Grid sx={{m: 5}} item xs mt={6} display="flex" justifyContent="center">
            <Button
              onClick={() => {
                handleSubmit()
              }}
              variant="outlined"
              color="secondary"
              type="button"
              sx={{marginTop: isIconVisible ? '0px' : '120px'}}>
              {confirmButtonLabel}
            </Button>
          </Mui.Grid>
        </Box>
      </Box>
    </Dialog>
  )
}
