import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import * as React from 'react'
import type {TCompanyStatus} from '~/src/components/platform/manage-companies/CompanyMain'
import {EllipsisMenuItem} from './EllipsisMenuItem'

interface IProps {
  status: string
  onSelect(value: TCompanyStatus): void
  specificType: string
}

export function EllipsisMenu({status, onSelect, specificType}: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuSelect = (value: TCompanyStatus) => {
    setAnchorEl(null)
    onSelect(value)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon style={{color: '#858585'}} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: '20ch',
            border: '1px solid #E8E8E8',
            background: '#FAFAFA',
            borderRadius: '6px',
            boxShadow: '0px 0px',
          },
        }}>
        <EllipsisMenuItem
          data-testid="activeEllipsisMenuItem"
          disabled={status === 'Active'}
          value="active"
          onClick={() => handleMenuSelect('Active')}>
          Set status as Active
        </EllipsisMenuItem>
        <EllipsisMenuItem
          data-testid="rejectedEllipsisMenuItem"
          disabled={status === 'Rejected'}
          value="rejected"
          onClick={() => handleMenuSelect('Rejected')}>
          Set status as Rejected
        </EllipsisMenuItem>
        <EllipsisMenuItem
          data-testid="disabledEllipsisMenuItem"
          disabled={status === 'Disabled'}
          value="disable"
          onClick={() => handleMenuSelect('Disabled')}>
          Disable {specificType}
        </EllipsisMenuItem>
        {/* //TODO uncomment when we accept a remove  */}
        {/* <EllipsisMenuItem value="remove" onClick={() => handleMenuSelect(-1)}>
          Remove from List
        </EllipsisMenuItem> */}
      </Menu>
    </div>
  )
}
