import React from 'react'
import {
  DashboardMainStylesMaxWidth,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import {styled} from '@mui/material/styles'
import type {Applicant} from '~/src/models/applicant'

export const FlexSpaceBetweenContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const WhereText = styled('div')`
  font-size: 14px;
  font-weight: 600;
  color: #424242;
  margin-bottom: 10px;
`

export const WhereAnswer = styled('span')`
  font-size: 14px;
  font-weight: 400;
  color: #8a8a8a;
  margin-bottom: 10px;
`
interface IProps {
  handleClick(value: string): void
  applicant: Applicant
}

export default function MspCandidateProfileSummaryDisplay({handleClick, applicant}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Summary</InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText>
                {applicant.summary[0]}
              </InformationCardProfileHeaderText>
              <WhereText style={{marginBottom: '20px'}}>
                Where did you hear about us?
                {applicant.whereDidYouHearAboutUs && (
                  <WhereAnswer>- {applicant.whereDidYouHearAboutUs}</WhereAnswer>
                )}
              </WhereText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
