import React, {useEffect, useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateProfileSummaryDisplay from '../CandidateProfileDisplayandEdit/MspCandidateProfileSummaryDisplay'
import MspCandidateProfileSummaryEdit from '../CandidateProfileDisplayandEdit/MspCandidateProfileSummaryEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(): void
  values: Applicant
}

export default function MspCandidateProfileSummary({
  handleSubmit,
  oldApplicant,
  resetForm,
  values,
}: IProps) {
  const [editProfileSummaryState, setEditProfileSummaryState] = useState('saveChanges')

  const handleClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileSummaryState('edit')
    } else {
      try {
        await handleSubmit(values)
        setEditProfileSummaryState('saveChanges')
      } catch (err: unknown) {
        return console.log('err', err)
      }
    }
  }

  const handleCancel = () => {
    resetForm({values: oldApplicant})
    setEditProfileSummaryState('saveChanges')
  }

  useEffect(() => {
    setEditProfileSummaryState('saveChanges')
  }, [oldApplicant.id])
  const projectSummary = () => {
    switch (editProfileSummaryState) {
      case 'edit':
        return (
          <MspCandidateProfileSummaryEdit
            handleCancel={handleCancel}
            handleClick={handleClick}
            applicant={values}
          />
        )
      case 'saveChanges':
        return (
          <MspCandidateProfileSummaryDisplay handleClick={handleClick} applicant={values} />
        )

      default:
        return (
          <MspCandidateProfileSummaryDisplay handleClick={handleClick} applicant={values} />
        )
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectSummary()}</DashboardMainStyles>
    </>
  )
}
