import React from 'react'
import {push} from 'connected-react-router'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'

import {BASE_ROUTES} from '../routes/constants'
import {errorText} from '../components/auth/constants/helper-text'
import {FormSubtitle} from '../components/auth/Typography'
import {regex} from '../utils'
import TextField from '@ui/text-field/TextField'
import {useAppDispatch} from '../store/store-hooks'
import {useRegisterMutation} from '../store/apis/auth-api'
import Checkbox from '@ui/checkbox/FormikCheckbox'
import ErrorBox from '../components/ui/error/ErrorBox'
import S from '../components/auth/styles/Form.styles'
import SubmitButton from '../components/auth/SubmitButton'
import CheckedBanner from '~/src/components/auth/CheckedImage'
import {styled, css} from '@mui/material/styles'
import {NavLink} from 'react-router-dom'
import SignInContainer from '@ui/sign-in-container/SignInContainer'

export default function RegistrationPage() {
  const dispatch = useAppDispatch()
  const [register, {isError, isSuccess}] = useRegisterMutation()

  if (isSuccess) {
    return (
      <S.Outer>
        <S.Background />
        <S.MaxWidth>
          <S.InnerPaper elevation={6}>
            <S.Greeting
              customFooter={
                <S.RegisterLink className="nav-link" to={BASE_ROUTES.SIGNIN}>
                  Already have an account? Go to Sign in
                </S.RegisterLink>
              }
            />
            <S.InnerBox>
              <S.Container onSubmit={() => dispatch(push(BASE_ROUTES.SIGNIN))}>
                <S.Title>Password Update</S.Title>
                <FormSubtitle>Your password has been updated!</FormSubtitle>
                <CheckedBanner />
                <SubmitButton>Back to Sign</SubmitButton>
              </S.Container>
            </S.InnerBox>
          </S.InnerPaper>
        </S.MaxWidth>
      </S.Outer>
    )
  }

  return (
    <S.Outer>
      {process.env.FR ? <S.BackgroundPa /> : <S.Background />}
      <S.MaxWidth>
        <S.InnerPaper elevation={6}>
          <S.Greeting
            customFooter={
              <S.RegisterLink className="nav-link" to={BASE_ROUTES.SIGNIN}>
                Already have an account? Go to Sign in
              </S.RegisterLink>
            }
          />
          <S.InnerBox>
            <S.FormTitle>Sign-Up</S.FormTitle>
            <ErrorBox>{isError && errorText.serverError}</ErrorBox>
            <Formik
              onSubmit={async function (values) {
                await register({emailAddress: values.email, password: values.password}).unwrap()
              }}
              initialValues={{email: '', password: '', confirm: '', acceptTerms: false}}
              validationSchema={Yup.object({
                email: Yup.string().email(errorText.emailInvalid).required(errorText.emailRequired),

                password: Yup.string()
                  .matches(regex.lowerCase, errorText.mustContainLowercase)
                  .matches(regex.upperCase, errorText.mustContainUppercase)
                  .matches(regex.number, errorText.mustContainNumber)
                  .matches(regex.specialCharacter, errorText.mustContainSpecialCharacter)
                  .min(8, errorText.passwordTooShort)
                  .required(errorText.passwordRequired),

                confirm: Yup.string()
                  .required(errorText.confirmPasswordRequired)
                  .oneOf([Yup.ref('password')], errorText.passwordsDontMatch),

                acceptTerms: Yup.boolean().required().oneOf([true], errorText.mustAcceptTerms),
              })}>
              {({isSubmitting}) => (
                <Form>
                  <S.FieldContainer>
                    <TextField name="email" label="Email" type="email" />
                    <TextField name="password" label="Password" type="password" />
                    <TextField name="confirm" label="Re-enter password" type="password" />
                  </S.FieldContainer>
                  <Checkbox
                    name="acceptTerms"
                    label="Accept Terms and Conditions"
                    disabled={isSubmitting}
                  />

                  <SubmitButton disabled={isSubmitting}>Sign Up</SubmitButton>
                </Form>
              )}
            </Formik>
          </S.InnerBox>
        </S.InnerPaper>
      </S.MaxWidth>
    </S.Outer>
  )
}
