import {createApi} from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import type {ISuccessMessage} from '~/src/models'
import type {Applicant} from '../../models/applicant'
import {baseQuery} from '../base-query'

export const documentsApi = createApi({
  reducerPath: 'DocumentApi',
  tagTypes: ['Documents'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getDocumentByEntityId: builder.query<Applicant, {entityId: string; agencyId?: string}>({
      providesTags: ['Documents'],
      query: ({entityId, agencyId}) => ({
        url: `/document/getDocumentByEntityId/${entityId}`,
        params: {agencyId},
        method: 'GET',
      }),
    }),

    putCreateFolder: builder.mutation<
      ISuccessMessage,
      {body: {documentId: string; name: string}; agencyId}
    >({
      invalidatesTags: ['Documents'],
      query: ({body, agencyId}) => ({
        url: `/document/createFolder`,
        body: body,
        method: 'PUT',
        params: {agencyId},
      }),
    }),

    putRenameFolder: builder.mutation<
      ISuccessMessage,
      {
        body: {
          documentId: string
          currentFolderName: string
          newFolderName: string
        }
        agencyId?: string
      }
    >({
      invalidatesTags: ['Documents'],
      query: ({body, agencyId}) => ({
        url: `/document/renameFolder`,
        body: body,
        method: 'PUT',
        params: {agencyId},
      }),
    }),

    putUploadFile: builder.mutation<
      ISuccessMessage,
      {agencyId: string; documentId: string; folderName: string; body: File}
    >({
      invalidatesTags: ['Documents'],
      query: ({agencyId, body, documentId, folderName}) => {
        const formData = new FormData()
        formData.append('file', body)
        return {
          url: `/document/uploadFile/${documentId}/${folderName}`,
          method: 'PUT',
          body: formData,
          params: {agencyId, documentId, folderName},
        }
      },
    }),

    putDeleteFile: builder.mutation<
      ISuccessMessage,
      {
        agencyId: string
        documentId: string
        folderName: string
        fileId: string
      }
    >({
      invalidatesTags: ['Documents'],
      query: ({agencyId, documentId, folderName, fileId}) => ({
        url: `/document/deleteFile?documentId=${documentId}&folderName=${folderName}&fileId=${fileId}`,
        method: 'PUT',
        params: {agencyId},
      }),
    }),
  }),
})

export const {
  useGetDocumentByEntityIdQuery,
  usePutCreateFolderMutation,
  usePutRenameFolderMutation,
  usePutUploadFileMutation,
  usePutDeleteFileMutation,
} = documentsApi

// RTK doesnt like to download files so used axios
export const downloadFileApi = async (
  documentId: string,
  folderName: string,
  fileId: string,
  token: string,
  agencyId?: string,
) => {
  const apiConfigCustom = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const headers = apiConfigCustom.headers

  const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/document/downloadFile`, {
    headers,
    responseType: 'blob',
    params: {
      agencyId: agencyId,
      documentId: documentId,
      folderName: folderName,
      fileId: fileId,
    },
  })
  return new Blob([res.data])
}
