import {Button} from '@mui/material'
import Box from '@mui/material/Box'
import ErrorBoundary from '@ui/error'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import type {IOrder} from '~/src/models/order'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {selectServiceOrderState} from '~/src/store/slices/service-order-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import TemplateDetailsTable from './TemplateDetailsTable'
import {styled} from '@mui/material/styles'

const Container = styled('div')({
  position: 'relative',
  flex: 1,
})
const TableInfo = styled('div')({
  marginRight: '24px',
  padding: '8px 0',
})
const AddContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '23px',
})

interface IParams {
  orderId: string
}
interface IFormUpdateValues {
  id: string
  status: string
  mspStatus?: string
  orderOwner: string
  orderNumber: string
  agencyId: string
  versionKey: number
  orders: IOrder[]
}

interface IState {
  templateData: any
  compName: string
  currentPageNumber: number
}

export default function TemplateDetails() {
  const history = useHistory()
  const {serviceOrders, currentServiceOrderPageNumber} = useAppSelector(selectServiceOrderState)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const location = useLocation()
  const locationState = location.state as IState

  // This keeps the subscription to the endpoint alive
  useGetServiceOrderQuery({
    pageNumber: currentServiceOrderPageNumber,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const initialFormValues = {
    id: 'test',
  } as unknown as IFormUpdateValues

  const handleSubmit = async () => {}

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={Yup.object({
        id: Yup.string().required(),

        versionKey: Yup.string().required(),
        orders: Yup.array().of(
          Yup.object({
            orderType: Yup.string().required('Required'),
            positionId: Yup.string().required('Required'),
            payRate: Yup.string().required('Required'),
            distributionStartDate: Yup.string().required('Required').nullable(),
            distributionEndDate: Yup.string().required('Required').nullable(),
            quantity: Yup.string()
              .required('Required')
              .max(50, 'Quantity must be 50 characters or less.'),
            note: Yup.string(),
          }),
        ),
      })}>
      {({isSubmitting, values}) => {
        return (
          <FormikForm>
            <Container>
              <Title pageTitle="Template Details" />
              <ErrorBoundary>
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px 20px 10px 16px',
                      fontWeight: '600',
                      backgroundColor: '#FFFFFF',
                      fontStyle: 'normal',
                      fontSize: '15px',
                      lineHeight: '20px',
                      marginTop: '63px',
                    }}>
                    <div style={{display: 'flex', justifyItems: 'center'}}>
                      <TableInfo>Template Name: {locationState.templateData.name}</TableInfo>
                    </div>
                    <div>
                      <Button
                        onClick={history.goBack}
                        variant="outlined"
                        color="secondary"
                        style={{marginRight: '18px'}}>
                        Back
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={isSubmitting}>
                        Save
                      </Button>
                    </div>
                  </Box>
                  <TemplateDetailsTable
                    compName={locationState.compName}
                    values={locationState.templateData}
                  />
                </>
              </ErrorBoundary>
            </Container>
          </FormikForm>
        )
      }}
    </Formik>
  )
}
