import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {Applicant, Event} from '~/src/models/applicant'
import MspCandidateEventsMain from '../candidate-details/candidate-events/MspCandidateEventsMains'
import {DashboardMainStyles} from '../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

export default function JobOrderSummaryEvents({
  handleSubmit,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates,
}: IProps) {
  const createEventItem = () => ({
    assignmentID: undefined,
    company: undefined,
    endDate: '',
    eventDescription: undefined,
    eventName: undefined,
    position: undefined,
    startDate: '',
  })

  const eventValues = oldApplicant?.events
    ? oldApplicant.events.map((i: Event) => {
        return {
          assignmentID: i.assignmentID,
          company: i.company,
          endDate: i.endDate,
          eventDescription: i.eventDescription,
          eventName: i.eventName,
          position: i.position,
          startDate: i.startDate,
        }
      })
    : []

  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,
    // basic info
    events: eventValues,
    newEvents: createEventItem(),
  }
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          events: Yup.array().of(
            Yup.object({
              assignmentId: Yup.string().required('Required'),
              company: Yup.string().nullable().required('Required'),
              endDate: Yup.date()
                .required('Required')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
              eventDescription: Yup.string(),
              eventName: Yup.string().required('Required'),
              position: Yup.string().required('Required'),
              startDate: Yup.date()
                .required('Required')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            }),
          ),
          newEvents: Yup.object({
            assignmentID: Yup.string().required('Required'),
            company: Yup.string().required('Required'),
            endDate: Yup.date()
              .required('Required')
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            eventDescription: Yup.string(),
            eventName: Yup.string().required('Required'),
            position: Yup.string().required('Required'),
            startDate: Yup.date()
              .required('Required')
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
          }),
        })}>
        {({isSubmitting, setFieldValue, values, resetForm, errors}) => {
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <DashboardMainStyles>
                  <JobOrderCandidateNameHeader
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    isAssignmentHistory={true}
                    oldApplicant={oldApplicant}
                    singleApplicant={values}
                  />

                  <MspCandidateEventsMain
                    errors={errors}
                    handleSubmit={handleSubmit}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    oldApplicant={oldApplicant}
                    values={values}
                  />
                </DashboardMainStyles>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}
