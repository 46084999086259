import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import {muiTheme} from './mui-theme'

// Hoist this. Doesn't need to be re-created on each render.
const inputCssBaseline = <CssBaseline />

export function UiThemeProvider({children}: {children: React.ReactNode}) {
  return (
    <ThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        {inputCssBaseline}
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  )
}
