import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function MenuIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path d='M3 6H21' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
			<path d='M3 12H21' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
			<path d='M3 18H21' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
		</Svg>
	);
}
