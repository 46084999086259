import CustomIcon, {IAttributes} from '@ui/icons'
import {MenuItem as MuiMenuItem, MenuItemProps} from '@mui/material'
import {styled} from '@mui/material/styles'

const Icon = styled(CustomIcon)<IAttributes>`
  &&& {
    color: ${({theme}) => theme.palette.primary.main};
  }
  height: 12px;
  width: 12px;
`

const MenuItem = styled(MuiMenuItem)<MenuItemProps>`
  display: flex;
  justify-content: space-between;
`

export default {
  Icon,
  MenuItem,
}
