import {styled} from '@mui/material/styles'
import React from 'react'

const Container = styled('div')`
  display: flex;
  padding-left: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  height: 55px;
`

const TopRow = styled('div')`
  display: flex;
  align-items: center;
`

const PageTitle = styled('div')`
  font-weight: bold;
  font-size: 24px;
  color: #424242;
`

const TitleButton = styled('div')``

interface TitleProps {
  pageTitle: string
  button?: React.ReactElement
  leaveLast?: boolean
  sx?: object
}

export default function Title({
  pageTitle,
  button,
  leaveLast = false,
  sx,
}: TitleProps): React.ReactElement {
  return (
    <Container style={sx}>
      <TopRow>
        <PageTitle>{pageTitle}</PageTitle>
        {button && <TitleButton>{button}</TitleButton>}
      </TopRow>
    </Container>
  )
}
