import Box from '@mui/material/Box'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import type {TActivePlatform} from '../../platform/onboarding/AddCompanyForm'
import EditCompany from './EditCompany'

export default function ManageCompany() {
  const [activePlatform] = React.useState<TActivePlatform>('Company')
  const location = useLocation()
  const {currentUser} = useAppSelector((state) => state.root)
  const companyID = currentUser?.companyId
  const {data, isLoading, error, refetch} = useGetOneCompanyQuery(companyID ?? '')
  const {data: agency} = useGetOneAgencyQuery(data?.agencyId ?? '')

  useEffect(() => {
    void refetch()
  }, [refetch])

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Title pageTitle="Manage Company" />
      </Box>
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading}>
          <EditCompany agency={agency} activePlatform={activePlatform} initialEditValues={data} />
        </LoadingBoundary>
      </ErrorBoundary>
    </>
  )
}
