import {MenuItem, TextField} from '@mui/material'
import AddButton from '@ui/buttons/AddButton'
import {push} from 'connected-react-router'
import React from 'react'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useAppDispatch} from '~/src/store/store-hooks'

interface AddPositionSelectProps {
  companyIds: string | number | boolean | object | undefined
}

function MenuRow(props: any): JSX.Element {
  const {data: companyName} = useGetCompanyNameByIdQuery({
    id: props.i,
  })

  return (
    <MenuItem
      value={props.i}
      onClick={(e) => {
        return props.handleChange(companyName, props.i)
      }}
      key={props.index}>
      {companyName}
    </MenuItem>
  )
}

export default function AddPositionSelect(props: AddPositionSelectProps) {
  const {companyIds} = props
  const dispatch: any = useAppDispatch()
  const [selectedCompany, setSelectedCompany] = React.useState('Select Company')
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: string | number | boolean | object | undefined,
  ) => {
    setSelectedCompany(event)
    dispatch(
      push(`add-position/${encodeURIComponent(event)}`, {
        state: {companyId: i},
      }),
    )
  }

  return (
    <AddButton onClick={() => {}}>
      <TextField data-testid="Select Company" select value="none" variant="standard">
        <MenuItem value="none" sx={{display: 'none'}}>
          Select Company
        </MenuItem>
        {companyIds?.map((i, index) => {
          return (
            <MenuRow key={index} value={i} handleChange={handleChange} i={i}>
              {selectedCompany}
            </MenuRow>
          )
        })}
      </TextField>
    </AddButton>
  )
}
