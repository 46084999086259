import {css} from 'styled-components'

export const colors = {
  clear: 'transparent',
  darkGray: '#334d6e',
  darkWhite: '#fafafa',
  darkerWhite: '#f5f5f5',
  disabled: '#c4c4c4',
  green: '#3db429',
  lightGray: '#d0d0d0',
  orange: '#ffb946',
  primary: '#03a9fc',
  primaryDark: '#005599',
  red: '#ff6760',
  secondary: '#404040',
  yellow: '#ffb946',
  white: '#ffffff',
  gray50: '#fafafa',
  gray100: '#f0f0f0',
  gray150: '#ebebeb',
  gray200: '#e0e0e0',
  gray250: '#c7c7c7',
  gray300: '#b3b3b3',
  gray350: '#949494',
  gray400: '#8a8a8a',
  gray450: '#616161',
  gray500: '#424242',
  gray550: '#3d3d3d',
  gray600: '#292929',
  gray650: '#1f1f1f',
  gray700: '#0f0f0f',
  gray750: '#292929',
  gray800: '#1f1f1f',
  gray900: '#0f0f0f',
  gray1000: '#000000',
  textDisabled: '#e0e0e0',
  textPrimary: '#242424',
  textSecondary: '#424242',
} as const

export const gradients = {
  primary: 'linear-gradient(156.23deg, #03a9fc 4.93%, #2b74b5 96.05%)',
} as const

export const shadows = {
  small: `box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.06)`,
  normal: `box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06)`,
  big: `box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06)`,
  bigger: `box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)`,
} as const

export const font = {
  sizes: {
    h1: '48px',
    h2: '32px',
    h3: '28px',
    h4: '24px',
    h5: '18px',
    regular: '16px',
    small: '15px',
    smaller: '14px',
    tiny: '12px',
    micro: '11px',
  },
  family: {
    openSans: "'Open Sans', 'Segoe UI', Tahoma, sans-serif",
    verdana: "'Verdana', 'Open Sans', 'Segoe UI', Tahoma, sans-serif",
  },
} as const

export const resets = {
  common: css`
    background: inherit;
    border: 0;
    outline: 0;
  `,
  ul: css`
    list-style-type: inherit;
    margin: 0;
    padding: 0;
  `,
  a: css`
    color: inherit;
    text-decoration: inherit;
  `,
} as const

export const transitions = {
  slow: '400ms ease',
  regular: '250ms ease',
  fast: '100ms ease',
} as const

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
