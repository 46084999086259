import TextField from '@mui/material/TextField/TextField'
import {useField} from 'formik'
import React from 'react'
import type {IStandardAutocompleteGenericProps} from './StandardAutocompleteGeneric'
import StandardAutocompleteGeneric from './StandardAutocompleteGeneric'

export type IFormikAutocompleteMultipleProps<T> = IStandardAutocompleteGenericProps<T>

export default function FormikAutocompleteMultiple<T>({
  name,
  freeSolo,
  defaultValue,
  inputValue,
  renderInput,
  helperText,
  ...rest
}: IFormikAutocompleteMultipleProps<T>) {
  const [field, meta, helpers] = useField(name as string)
  const errorMsg = meta.touched && meta.error ? meta.error : ''

  const onChangeHandler = function (e: React.SyntheticEvent, value: unknown): void {
    void helpers.setValue(value ?? [])
  }

  return (
    <StandardAutocompleteGeneric
      name={field.name}
      onChange={onChangeHandler}
      freeSolo={freeSolo}
      helperText={errorMsg}
      defaultValue={defaultValue}
      value={field.value}
      inputValue={inputValue}
      renderInput={(params) => {
        return <TextField {...params} error={errorMsg.length > 0} helperText={errorMsg} />
      }}
      {...rest}
    />
  )
}
