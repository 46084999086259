import {formHelperTextClasses} from '@mui/material/FormHelperText'
import {styled} from '@mui/material/styles'
import {typographyClasses} from '@mui/material/Typography'
import type {FormControlProps} from '@mui/material/FormControl'

import Mui from '@ui/mui'

const Checkbox = styled(Mui.Checkbox)({
  padding: 3,

  '&:hover, &:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },

  '& svg': {
    width: 16,
    height: 16,
  },
})

const FormControlLabel = styled(Mui.FormControlLabel)(
  ({theme}) => ({
    margin: 'initial',
    
    [`& .${typographyClasses.root}`]: {
      marginLeft: theme.spacing(1),
    },
  }),
)

const FormControl = styled(Mui.FormControl)<FormControlProps & {error?: unknown}>(
  ({error, theme}) => ({
    position: 'relative',

    ...(error && {
      [`& .${typographyClasses.root}, 
        & .${formHelperTextClasses.root}`]: {
        color: theme.palette.error.main,
      },
    }),
  }),
)

export default {
  Checkbox,
  FormControl,
  FormControlLabel,
}
