import Autocomplete from '@mui/material/Autocomplete'
import {styled} from '@mui/material/styles'

const StyledAutocomplete = styled(Autocomplete)({
  padding: 0,

  '&&& .MuiOutlinedInput-root': {
    padding: 0,
    paddingRight: 48,

    '& .MuiAutocomplete-input': {
      padding: '10px 5px 10px 14px',
    },
  },

  '&&& .MuiAutocomplete-endAdornment': {
    right: 9,
    top: '50%',
    transform: 'translateY(-50%)',
  },

  '& .MuiAutocomplete-clearIndicator': {
    height: 28,
    width: 28,
  },
}) as typeof Autocomplete

export default StyledAutocomplete
