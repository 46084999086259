import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {IconButton, Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/material/styles'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {formatShift} from '@utils'
import React, {SetStateAction, Suspense, lazy, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {lazyRetry} from '~/src/components/msp/manage-orders/agency-order-tables/AgencyOrderDetailsTable'
import type {IAgencyBase, TAgenciesPayload} from '~/src/models'
import type {AgencyDistributions, IOrderDistribution} from '~/src/models/order'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES} from '~/src/routes'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useUpdatePositionOrderDistributionMutation} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import {StyledTableCell} from '../../../ui/table-cell/StyledTableCell'

const NMACalendarOrderDialog = lazy(() =>
  lazyRetry(() => import('../nonmsp-agency-calendar/NMACalendarDialog')),
)

type OrderDetailsTableProps = {
  serviceOrder: IServiceOrder
  allAgencies: TAgenciesPayload | undefined
  currentAgency: IAgencyBase | undefined
  setOpen: React.Dispatch<SetStateAction<boolean>>
  needed: number | void
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
  sortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'empty',
    numeric: false,
    disablePadding: true,
    label: '     ',
    align: 'center',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
    sortable: true,
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'Quantity',
    numeric: false,
    disablePadding: true,
    label: 'Quantity',
    align: 'left',
  },
  {
    id: 'pledged',
    numeric: true,
    disablePadding: true,
    label: 'Pledged',
    align: 'left',
  },
  {
    id: 'distributionStart',
    numeric: true,
    disablePadding: true,
    label: 'Distribution Start',
    align: 'left',
  },
  {
    id: 'distributionEnd',
    numeric: true,
    disablePadding: true,
    label: 'Distribution End',
    align: 'left',
  },

  {
    id: 'distribution',
    numeric: true,
    disablePadding: true,
    label: 'Distribution',
    align: 'left',
  },
]

function PositionRow(props: Partial<IServiceOrder>): JSX.Element {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [calendarModalVisible, setCalendarModalVisible] = useState(false)
  const [selectedAgencyId, setSelectedAgencyId] = useState<string>()
  const [targetCalendar, setTargetCalendar] = useState<string>('')

  const handleDialog = (agencyId: string, agencyTest: string | number | boolean | null) => {
    return (
      setSelectedAgencyId(agencyId),
      props.setAgencyNewData({...props.agencyNewData, [targetCalendar]: agencyTest})
    )
  }

  const findAgency: AgencyDistributions | undefined = props.serviceOrder
    ? props.serviceOrder?.orders[0]?.orderDistribution.agencyDistributions?.find((item) => {
        return item.agencyId === loggedInAgencyId
      })
    : undefined

  const filteredAgencyName = selectedAgencyId
    ? props?.agencyNewData[targetCalendar]?.filter((r: {id: string}) =>
        selectedAgencyId?.includes(r.id),
      )
    : []

  const {data: positionData} = useGetPositionByIdQuery({
    id: props.order.positionId,
  })

  let totalRequestedInPosition = 0

  findAgency?.agencyQuantityDistributions?.forEach((t) => {
    if (t?.requested) {
      return (totalRequestedInPosition += t.requested)
    }
  })

  let totalPledgedInPosition = 0

  findAgency?.agencyQuantityDistributions?.forEach((t) => {
    if (t?.pledged) {
      return (totalPledgedInPosition += t.pledged)
    } else {
      return 0
    }
  })
  if (positionData) {
    return (
      <>
        <Suspense fallback={<tr />}>
          {calendarModalVisible && (
            <NMACalendarOrderDialog
              allAgencies={props.allAgencies}
              serviceOrder={props.serviceOrder}
              currentAgency={props.currentAgency}
              order={props.order}
              positionData={positionData}
              filteredAgencyName={filteredAgencyName ? filteredAgencyName : []}
              modalOpen={calendarModalVisible}
              needed={props.needed}
              onSelectUpdate={handleDialog}
              onModalClose={() => {
                setCalendarModalVisible(false)
              }}
              onSave={props.handleDialog}
              serviceOrderAgencyId={props.serviceOrderAgencyId}
            />
          )}
        </Suspense>
        <>
          <TableRow key={props.index}>
            <StyledTableCell />

            <StyledTableCell component="td" scope="row" padding="normal" sx={{maxWidth: '200px'}}>
              {positionData.name}
            </StyledTableCell>
            <StyledTableCell>{formatShift(positionData)}</StyledTableCell>
            <StyledTableCell>{totalRequestedInPosition}</StyledTableCell>
            <StyledTableCell>
              {isNaN(totalPledgedInPosition) ? 0 : totalPledgedInPosition}
            </StyledTableCell>
            <StyledTableCell>
              {new Date(props.order.distributionStartDate).toLocaleDateString()}
            </StyledTableCell>
            <StyledTableCell>
              {new Date(props.order.distributionEndDate).toLocaleDateString()}
            </StyledTableCell>

            <StyledTableCell align="left">
              <IconButton
                sx={{color: '#858585'}}
                disabled={!props.isEditable}
                onClick={() => {
                  setTargetCalendar(props.order.positionId)
                  setCalendarModalVisible(true)
                }}>
                <CalendarTodayIcon />
              </IconButton>
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </>
      </>
    )
  } else {
    return <></>
  }
}

export default function NonMspAgencyOrderDetailsTable({
  serviceOrder,
  allAgencies,
  currentAgency,
  setOpen,
  needed,
}: OrderDetailsTableProps) {
  const [targetCalendar, setTargetCalendar] = useState<string>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updatePositionOrderDistribution] = useUpdatePositionOrderDistributionMutation()

  const [agencyNewData, setAgencyNewData] = useState(
    serviceOrder.orders.reduce((orders, order) => {
      orders[order.positionId] = []
      return orders
    }, {}),
  )

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const theme = useTheme()
  const statusArray = serviceOrder.orders
    .map((order) => order.orderDistribution.agencyDistributions?.map((l) => l.agencyStatus))
    .flat()
  const isEditable =
    statusArray.includes('New Request') ||
    statusArray.includes('Confirming') ||
    statusArray.includes('Ready To Confirm')

  const handlePaginationChange = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber)
  }

  const handleDialog = async (
    newAgencyDistributions: Partial<IServiceOrder>,
    positionId: string,
  ) => {
    const serviceOrderId = serviceOrder.id
    const orderIndex = serviceOrder.orders.findIndex((item) => item.positionId == positionId)
    const orderDistributionCopy = JSON.parse(
      JSON.stringify(serviceOrder.orders[orderIndex]?.orderDistribution),
    )

    if (!orderDistributionCopy.agencyDistributions) {
      orderDistributionCopy.agencyDistributions = []
    }
    newAgencyDistributions.forEach((newAgencyDistribution: IOrderDistribution) => {
      const agencyDistributionIndex = orderDistributionCopy.agencyDistributions.findIndex(
        (item: {agencyId: string}) => item.agencyId === newAgencyDistribution.agencyId,
      )

      if (agencyDistributionIndex > -1) {
        orderDistributionCopy.agencyDistributions[agencyDistributionIndex] = newAgencyDistribution
      } else {
        orderDistributionCopy.agencyDistributions.push(newAgencyDistribution)
      }
    })
    const newDistribition = orderDistributionCopy.agencyDistributions?.map((t) => {
      const newMap = t?.agencyQuantityDistributions?.map((l) => {
        const newIndividualDate = new Date(l.date)
        const convertedDateForEachDate = new Date(newIndividualDate.setHours(5, 0, 0)).toISOString()
        return {date: convertedDateForEachDate, pledged: l.pledged, requested: l.requested}
      })
      return {agencyId: t.agencyId, agencyQuantityDistributions: newMap}
    })

    orderDistributionCopy.agencyDistributions = newDistribition
    await updatePositionOrderDistribution({
      serviceOrderId,
      positionId,
      agencyId: loggedInAgencyId,
      body: orderDistributionCopy,
    })
      .unwrap()
      .then(() => {
        if (newAgencyDistributions[0].agencyQuantityDistributions[0].pledged === needed) {
          return setOpen(true)
        } else {
          return setOpen(false)
        }
      })
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey[200]}`,
        }}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size="medium" padding="normal">
            <TableHead style={{borderBottom: '1px solid #CDCED9'}}>
              <TableRow>
                {headCells.map((headCell: HeadCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding="normal"
                    style={{borderBottom: 'none'}}>
                    {headCell.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="left" padding="normal" style={{borderBottom: 'none'}}>
                  <IconButton
                    component={NavLink}
                    to={{
                      pathname: `${BASE_ROUTES.AGENCY}/manage-orders/${serviceOrder?.id}/agency/summary`,
                    }}>
                    <FileDownloadIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceOrder.orders.map((order, index) => {
                return (
                  <PositionRow
                    agencyNewData={agencyNewData}
                    allAgencies={allAgencies}
                    currentAgency={currentAgency}
                    handleDialog={handleDialog}
                    isEditable={isEditable}
                    key={index}
                    order={order}
                    needed={needed}
                    setAgencyNewData={setAgencyNewData}
                    setTargetCalendar={setTargetCalendar}
                    serviceOrder={serviceOrder}
                    serviceOrderAgencyId={serviceOrder.agencyId}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledPagination
          page={currentPageNumber}
          totalRecords={serviceOrder.orders.length}
          recordsPerPage={10}
          onChange={handlePaginationChange}
        />
      </Box>
    </>
  )
}
