import styled from 'styled-components'

export const SkillContainerBox = styled('div')<{isSelected: boolean; displayBox: boolean}>`
  display: ${(props) => (props.displayBox ? 'flex' : 'none')};
  height: 45px;
  border-radius: 6px;
  background: ${(props) => (props.isSelected ? '#f0f0f0' : '#C2CFE0')};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
`

export const SkillInnerBoxMargin = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
`

export const SkillInnerTextSpan = styled('span')`
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #707070;
  font-size: 12px;
  font-weight: 400;
`

export const HeaderText = styled('div')`
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 18px;
`

export const DropdownContainer = styled('div')<{isEnabled: boolean}>`
  display: ${(props) => (props.isEnabled ? 'flex' : 'none')};
  min-height: ${(props) => (props.isEnabled ? '70px' : '100px')};
  flex-wrap: wrap;
`

export const CategoryHeader = styled('div')`
  color: #333;
  font-size: 14px;
  font-weight: 400;
`

export const SkillMainContainer = styled('div')`
  min-height: 100px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`
