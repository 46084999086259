import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface IProps {
  customMessage?: string
  isOpen: boolean
  setIsSnackbarOpen: (value: boolean) => void
}

export default function ReusableSnackbar({customMessage, isOpen, setIsSnackbarOpen}: IProps) {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  return (
    <Snackbar
      aria-label="snackbar"
      data-testId="snackbar"
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert
        aria-label="snackbar-alert"
        data-testId="snackbar-alert"
        onClose={handleClose}
        severity="error"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: 60,
          alignItems: 'center',
        }}>
        {customMessage?.length > 0
          ? customMessage
          : 'Something went wrong, please contact your Administrator'}
      </Alert>
    </Snackbar>
  )
}
