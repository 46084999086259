import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import {css, styled} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import CalendarDayGraphic from './CalendarDayGraphic'

const ContainerBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1;
    margin: 1;
  `,
)

const LeftButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 32px;
    width: 32px;
    position: relative;
    left: 5px;
  `,
)

const RightButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 32px;
    width: 32px;
    position: relative;
    right: 5px;
  `,
)

interface IProps {
  count: number
  dateArray: Date[]
  isdashboard: boolean
  callback?: (index: number) => void
  sx?: object
}

export default function ClickableDateGroup({
  dateArray,
  count,
  callback = () => {},
  isdashboard,
  sx,
}: IProps) {
  const [leftButtonVisible, setLeftButtonVisible] = useState(false)
  const [rightButtonVisible, setRightButtonVisible] = useState(false)
  const [index, setIndex] = useState(0)
  const length = dateArray.length
  const initialSelectArray = new Array(length).fill(false)
  initialSelectArray[0] = true
  const [selectedDateIndex, setSelectedDateIndex] = useState(0)

  const indexedDateArray = dateArray.map((item, itemIndex) => {
    return {
      date: item,
      index: itemIndex,
    }
  })

  const slicedList = indexedDateArray.slice(index, index + count)

  const inc = () => {
    setIndex(index + 1)
  }

  const dec = () => {
    setIndex(index - 1)
  }

  useEffect(() => {
    setRightButtonVisible(length - index > count)
    setLeftButtonVisible(index > 0)
  }, [count, index, length])

  useEffect(() => {
    callback(selectedDateIndex)
  }, [callback, selectedDateIndex])

  const handleClick = (clickedIndex: number) => {
    setSelectedDateIndex(clickedIndex)
  }

  return (
    <ContainerBox
      sx={{
        ...sx,
      }}>
      <LeftButtonBox>
        {leftButtonVisible && (
          <IconButton color="secondary" onClick={dec}>
            <ArrowLeftIcon />
          </IconButton>
        )}
      </LeftButtonBox>
      <Stack direction="row" spacing={1.6}>
        {slicedList.map((item, i) => (
          <CalendarDayGraphic
            key={i}
            isSelected={selectedDateIndex == item.index}
            colorSelected={isdashboard ? '#424242' : undefined}
            colorUnselected={isdashboard ? '#424242' : undefined}
            onClick={() => {
              handleClick(item.index)
            }}
            date={item.date}
          />
        ))}
      </Stack>
      <RightButtonBox>
        {rightButtonVisible && (
          <IconButton color="secondary" onClick={inc} disableFocusRipple>
            <ArrowRightIcon />
          </IconButton>
        )}
      </RightButtonBox>
    </ContainerBox>
  )
}
