import type {IAddress, TCognitoRole} from '.'

export type TUserStatus = 'Pending' | 'Active' | 'Deactivated'

export const userStatusesReadonly: ReadonlyArray<TUserStatus> = ['Pending', 'Active', 'Deactivated']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PlatformReadonly: ReadonlyArray<any> = [
  'Platform Administrator',
  'Company Administrator',
  'Agency Administrator',
]

export interface IUserBase {
  age: number
  companyId: string
  agencyId: string
  department?: string
  emailAddress: string
  firstName: string
  id: string
  lastName: string
  phoneNumber: string
  roles: TCognitoRole[]
  status: TUserStatus
  isPlatformAdmin?: boolean
  address?: IAddress
  modifiedDate?: string
  createdDate?: string
  agreedToTermsDate?: string
}

export interface ICredentials {
  companyId: string
  email_verified: boolean
  emailAddress: string
  isPlatform?: boolean
  message: string
  oldPassword: string
  password: string
  roles: TCognitoRole
  token: string
}

export interface IAdminUser extends Omit<IUserBase, 'companyId'> {
  companyId?: string
}

export interface IAddGeneralAdminRequest {
  firstName: string
  lastName: string
  emailAddress: string
  roles: string[]
  allAccess?: boolean
  password?: string // For manually setting the temporary password
}

export interface IUpdateGeneralAdminRequest extends IAddGeneralAdminRequest {
  id: string
  status?: string
}

export interface IUserAndEmail {
    firstName: string
    lastName: string
    emailAddress: string
}

export type TPartialUser = Pick<ICredentials, 'emailAddress' | 'roles' | 'companyId' | 'isPlatform'>

export type SignoutResponse = Pick<ICredentials, 'message'>
export type FirstSigninResponse = Pick<ICredentials, 'oldPassword'> & TUserAttributes
export type ResetRequest = Pick<ICredentials, 'oldPassword' | 'password'> & TUserAttributes
export type SigninRequest = Pick<ICredentials, 'emailAddress' | 'password'>
export type RequestSignup = Pick<ICredentials, 'emailAddress' | 'password'>
export type SigninResponse = Pick<
  ICredentials,
  'emailAddress' | 'token' | 'roles' | 'companyId' | 'isPlatform'
>

export type TUserAttributes = {email: string; email_verified: string}
export type TUsersPayload = {users: IUserBase[]; totalUsers: number}
export type TOnboardPayload = Omit<IUserBase, 'age' | 'id' | 'phoneNumber'> & {isPlatform: boolean}
export type TAddMemberPayload = Omit<IAdminUser, 'id' | 'status' | 'age' | 'isPlatformAdmin'>
export type TEditMemberPayload = Omit<IAdminUser, 'id'>
