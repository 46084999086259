import React from 'react'
import {makeStyles} from '@mui/styles'
import BigCheckIcon from '~/src/assets/images/big-check.svg'

const useStyles = makeStyles({
  checkMark: {
    height: '100px',
    margin: '56px 0 48px 0',
    textAlign: 'center',
    width: '100%',
    '& img': {
      height: '100%',
    },
  },
})

export default function CheckedBanner() {
  const classes = useStyles()

  return (
    <div className={classes.checkMark}>
      <img src={BigCheckIcon} alt="checkmark" />
    </div>
  )
}
