export const summaryDropdown = [
  'Client Referral',
  'Company Website',
  'Craigslist',
  'Indeed',
  'Facebook',
  'Flyer',
  'Google',
  'Instagram',
  'Internal Referral',
  'Job Fair',
  'Linkedin',
  'Walk In',
]
