import Button from '@mui/material/Button'
import {Icon} from '@ui/components'
import styled from 'styled-components'
import {colors} from '../../../../../config/theme'

export const DashboardMainStyles = styled('div')`
  position: relative;
  flex-basis: 25%;
`

export const DashboardMainStylesMaxWidth = styled('div')`
  position: relative;
  flex-basis: 25%;
  max-width: 780px;
`

export const CandidateContainerMargin = styled('div')`
  margin-left: 19px;
  margin-top: 14px;
`

export const CompanyDetailsText = styled('div')`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
`

export const JobOrderDetailsText = styled('div')`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 25px;
`

export const HeaderText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
`

export const AvailabilityDateText = styled('div')`
  display: flex;
  font-size: 12px;
  font-weight: 400;
`

export const DescriptionText = styled('div')`
  font-size: 14px;
  font-weight: 400;
`

export const FlexWithoutPaddingContainer = styled('div')`
  display: flex;
`

export const FlexContainer = styled('div')`
  display: flex;
  margin-bottom: 16px;
`

export const FlexSpaceBetweenContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const DayBubbleContainer = styled('div')`
  display: flex;
  margin-left: 33px;
  justify-content: space-between;
  max-width: 355px;
`

export const DayEditInformationContainer = styled('div')`
  display: flex;
  margin-left: 33px;
  margin-right: 10px;
  justify-content: space-between;
`

export const ShiftSelectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

export const ShiftSelectBlueText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  color: rgba(66, 66, 66, 1)
  margin-bottom: 5px;
  margin-top: 3px;
  padding-left: 5px;
`

export const AvailabilityOverviewText = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: rgba(3, 169, 252, 1);
  margin-bottom: 2px;
`

export const DayBoxContainer = styled('div')`
  display: flex;
  height: 156px;
  width: 566px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  justify-content: center;
`

export const ShiftButtonSelectionContainer = styled('div')<{$shiftFocus?: boolean}>`
  display: flex;
  align-items: center;
  height: 32px;
  width: 155px;
  border: ${(props) => (props.$shiftFocus ? '1px solid #03a9fc' : '1px solid #b3b3b3')};
  border-radius: 4px;
  margin-bottom: 7px;
  color: ${(props) => (props.$shiftFocus ? '#FFFFFF' : 'black')};
  background-color: ${(props) => (props.$shiftFocus ? '#03a9fc' : '#FFFFFF')};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`

export const ShiftBubbleContainer = styled('div')`
  display: flex;
  margin-left: 33px;
  justify-content: space-between;
  max-width: 355px;
`

export const HeaderContainer = styled('div')`
  display: flex;
  width: 418px;
  max-width: 418px;
  max-height: 35px;
  height: 35px;
  justify-content: space-between;
  border: 1px solid #707070;
  border-radius: 9px;
  margin-bottom: 16px;
  margin-right: 11px;
`

export const InnerHeaderContainer = styled('div')`
  display: flex;
  width: 418px;
  max-width: 418px;
  max-height: 35px;
  height: 35px;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 8px;
  margin-right: 9px;
  margin-left: 9px;
`

export const DataCleanupContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  max-width: 1111px;
  width: 1111px;
  justify-content: flex-start;
`

export const CloseIconContainer = styled('div')`
  width: 45px;
  height: 206px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c2cfe0;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
`

export const EventsCloseIconContainer = styled('div')`
  width: 45px;
  height: 384px;
  text-align: center;
  background: #c2cfe0;
  border-radius: 0px 6px 6px 0px;
`

export const CloseIconVerticalAlignContainer = styled('div')`
  margin-top: 0px;
`

export const InnerDataHeaderContainer = styled('div')`
  width: 205px;
  max-width: 205px;
  max-height: 35px;
  height: 35px;
  margin-top: 7px;
  margin-bottom: 8px;
  margin-right: 9px;
  margin-left: 9px;
`

export const InformationCardContainer = styled('div')`
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
  min-height: 100px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`

export const InformationAvailabilityCardDisplayContainer = styled('div')<{isAvailable: boolean}>`
  width: 780px;
  max-width: 780px;
  min-height: ${(props) => (props.isAvailable ? '230px' : '125px')};
  margin-top: 10px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`

export const InformationAvailabilityCardEditContainer = styled('div')<{$isAvailable?: boolean}>`
  width: 780px;
  max-width: 780px;
  min-height: ${(props) => (props.$isAvailable ? '340px' : '155px')};
  margin-top: 10px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
  margin-bottom: 10px;
`

export const AvailabilityContainer = styled('div')<{
  $isAvailable?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => (props.$isAvailable ? 'white' : 'black')};
  margin-bottom: 15px;
  height: 28px;
  width: 103px;
  border-radius: 15px;
  background: ${(props) => (props.$isAvailable ? '#03a9fc' : '#FFF')};
  cursor: pointer;
  margin-top: 1px;
`

export const UnAvailabilityContainer = styled('div')<{$isAvailable?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => (props.$isAvailable === false ? 'white' : 'black')};
  margin-bottom: 15px;
  height: 28px;
  width: 103px;
  border-radius: 15px;
  background: ${(props) => (props.$isAvailable === false ? '#424242' : '#FFFFFF')};
  cursor: pointer;
  margin-top: 1px;
`

export const AvailabilityContainerSimple = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-bottom: 15px;
  height: 28px;
  width: 103px;
  border-radius: 15px;
  background: #03a9fc;
  cursor: pointer;
`

export const UnavailabilityContainerSimple = styled('div')`
  margin-top: 30px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-bottom: 30px;
  height: 28px;
  width: 103px;
  border-radius: 15px;
  background: #424242;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

export const ActivityBoxContainer = styled('div')`
  width: 800px;
  max-width: 800px;
  margin-top: 10px;
`

export const InformationCardHeaderContainer = styled('div')`
  border-bottom: 1px solid #ebeff2;
`

export const InformationCardHeaderPadding = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
`

export const ActivityCardHeaderPadding = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-right: 50px;
`

export const InformationCardHeaderTextTitle = styled('div')`
  margin-top: 18px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
`

export const InformationCardHeaderTextEdit = styled('div')`
  margin-top: 18px;
  color: #383838;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`

export const AssignmentHistoryActiveContainer = styled('div')`
  display: flex;
  margin-top: 18px;
  background: ${colors.primary};
  border-radius: 9px;
  width: 56px;
  height: 23px;
  justify-content: center;
  margin-right: 10px;
`

export const AssignmentHistoryActiveText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  text-color: white;
  color: white;
  padding: 1;
`

export const FilterIconStyle = styled('div')`
  margin-top: 18px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #707070;
  cursor: pointer;
`

export const ActivityTextEdit = styled('div')`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`

export const InformationCardProfileHeaderText = styled('div')`
  margin-top: 17px;
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  margin-bottom: 15px;
  text-overflow: ellipsis;
`

export const InformationCardProfileAvailabilityHeaderText = styled('div')`
  margin-top: 25px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
`

export const InformationActivityDateText = styled('span')`
  font-weight: 300;
  font-size: 12px;
  color: #8a8a8a;
`

export const InformationActivityButtonText = styled('div')`
  font-weight: 300;
  font-size: 12px;
  color: #8a8a8a;
`

export const InformationActivityIconAndManagerText = styled('span')`
  font-weight: 400;
  font-size: 13px;
  color: #333333;
`

export const InformationCardSkillEditText = styled('div')`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const SkillText = styled('div')`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const InformationCardProfileExperienceJobText = styled('div')`
  margin-top: 17px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #707070;
`

export const InformationCardProfileDetailsText = styled('div')`
  margin-top: 5px;
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 400;
`

export const InformationCardProfileAvailabilityStartTimeText = styled('div')`
  margin-top: 17px;
  font-size: 14px;
  font-weight: 600;
`

export const InformationCardDetailsContainer = styled('div')`
  margin-left: 33px;
  margin-right: 33px;
`

export const InformationCardExperianceBorder = styled('div')<{index: boolean}>`
  border-bottom: ${(props) => (props.index ? '1px solid white;' : ' 1px solid #c4c4c4;')};
`

export const MarginBottom15Px = styled('div')`
  margin-bottom: 15px;
`

export const InformationMarginBottom = styled('div')`
  margin-bottom: 15px;
`

export const InformationCardDetailsTextTitle = styled('div')`
  font-size: 14px;
  font-weight: 600;
`

export const SkillContainerBox = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 84px;
  height: 46px;
  text-align: center;
  background: #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const AddNewSkillContainerBox = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 152px;
  height: 46px;
  background: #c2cfe0;
  text-align: center;
  border-radius: 6px;
`

export const FlexColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const AvailabiltyMarginBottom = styled('div')`
  margin-bottom: 3px;
`

export const FlexSpaceEvenlyContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
`

export const AddExperienceContainer = styled('div')`
  width: 194px;
  height: 34px;
  background: #c2cfe0;
  boxshadow: 0px 4px 4px rgba(0; 0; 0; 0.08);
  border-radius: 6px;
  margin-bottom: 15px;
`

export const AddAssignmentContainer = styled(Button)`
  width: 154px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 15px;
`

export const AddExperienceText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-evenly;
  margin-top: 7px;
`

export const AddAssignmentText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #b3b3b3;
  justify-content: space-evenly;
`

export const AddSkillText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-evenly;
`

export const AddSkillXIcon = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #c4c4c4;
  justify-content: space-evenly;
`

export const AddExperienceCardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-left: 33px;
  margin-right: 33px;
  cursor: pointer;
`

export const AddAssignmentCardContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  cursor: pointer;
`

export const Red = styled('span')(({theme}) => ({
  color: '#FF6760',
}))

const ICON_SIZE = 12
const MARGIN = 21

export const Adornment = styled(Icon)({
  width: ICON_SIZE,
  margin: `0 ${MARGIN}px 0 ${MARGIN / 2}px`,
  display: 'flex',
  alignItems: 'center',
})

export const AbsoluteAdornment = styled(Adornment)({
  margin: `0 ${MARGIN}px`,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
})

export const AddandCancelNoteButton = styled(Button)`
  height: 40px;
  width: 120px;
  max-height: 40px;
  max-width: 120px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  color: #b3b3b3;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
`

export const CircleCenterInTextField = styled('div')`
  display: flex;
  margin-top: 1px;
  width: 20px;
  height: 25px;
  justify-content: center;
  margin-right: 3;
`

export const CircleCenter = styled('div')`
  display: flex;
  margin-top: 16px;
  width: 20px;
  height: 25px;
  justify-content: center;
  margin-right: 1;
  margin-left: -22px;
`

export const Circle = styled('div')`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${colors.primary};
  position: absolute;
  margin-top: 8px;
`

export const PulseCircle = styled('div')({
  display: 'flex',
  background: `${colors.primary} 30%`,
  borderRadius: '9px',
  width: '15px',
  height: '15px',
  justifyContent: 'center',
  margin: 'auto',
  '@keyframes pulsate': {
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'scale(1.2)',
    },
  },
  animation: 'pulsate 2s infinite ease',
})

export const ShiftText = styled('div')`
  width: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #8a8a8a;
`

export const ToggleContainer = styled('div')`
  display: flex;
  height: 32px;
  border-radius: 15px;
  border: 1px solid #b3b3b3;
  background-color: #ffffff;
  margin-top: 17px;
`

export const SaveButtonContainer = styled('div')`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  height: 40px;
  width: 134px;
  background: #03a9fc;
  border-radius: 9px;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
`

export const SkillListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
`

export const SkillListHeaderAndBodyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`
