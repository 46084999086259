import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import {css, styled} from '@mui/material/styles'
import React from 'react'
const FieldContainer = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(4)};
  `,
)

const ContentRow = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  `,
)

const IconAndText = styled('div')(
  ({theme}) => css`
    display: 'flex';
    flexwrap: 'wrap';
    align-items: center;
    justify-content: center;
  `,
)

interface IProps {
  validate: any
}

const PasswordRequirements = ({validate}: IProps) => (
  <FieldContainer>
    <div>
      <ContentRow>
        <div>
          {validate.hasLow ? (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CheckIcon fontSize="5px" sx={{marginRight: 1, color: '#3DB429'}} /> One lowercase
              character
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CircleIcon fontSize="5px" sx={{marginRight: 1, color: '#8A8A8A'}} /> One lowercase
              character
            </div>
          )}
        </div>
      </ContentRow>
      <ContentRow>
        <span>
          {validate.hasCap ? (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              {' '}
              <CheckIcon fontSize="5px" sx={{marginRight: 1, color: '#3DB429'}} /> One uppercase
              character
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              {' '}
              <CircleIcon fontSize="5px" sx={{marginRight: 1, color: '#8A8A8A'}} /> One uppercase
              character
            </div>
          )}
        </span>
      </ContentRow>
      <ContentRow>
        <span>
          {validate.hasSpecialChar ? (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CheckIcon fontSize="5px" sx={{marginRight: 1, color: '#3DB429'}} />
              One special character
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CircleIcon fontSize="5px" sx={{marginRight: 1, color: '#8A8A8A'}} />
              One special character
            </div>
          )}
        </span>
      </ContentRow>
      <ContentRow>
        <span>
          {validate.hasNumber ? (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CheckIcon fontSize="5px" sx={{marginRight: 1, color: '#3DB429'}} /> One Number
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CircleIcon fontSize="5px" sx={{marginRight: 1, color: '#8A8A8A'}} /> One Number
            </div>
          )}
        </span>
      </ContentRow>
      <ContentRow>
        <span>
          {validate.hasLong ? (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CheckIcon fontSize="5px" sx={{marginRight: 1, color: '#3DB429'}} />
              Eight characters minimum
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <CircleIcon fontSize="5px" sx={{marginRight: 1, color: '#8A8A8A'}} />
              Eight characters minimum
            </div>
          )}
        </span>
      </ContentRow>
    </div>
  </FieldContainer>
)

export default PasswordRequirements
