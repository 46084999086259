import React from 'react'
import {MenuItem as MuiMenuItem, MenuItemProps} from '@mui/material'
import S from './MenuItem.styles'

function SelectedMenuItem({children, ...props}: MenuItemProps) {
  return (
    <S.MenuItem {...props}>
      <span>{children}</span>
      <S.Icon icon="check" />
    </S.MenuItem>
  )
}

export default function MenuItem(props: MenuItemProps) {
  return props.selected ? <SelectedMenuItem {...props} /> : <MuiMenuItem {...props} />
}
