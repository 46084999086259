import {createContext} from 'react'

export interface LoadingContextInterface {
  setNewIsLoading: (value: boolean) => void
  newIsLoading: boolean
}

//going to start using this to refresh pages in near future
export const LoadingContext = createContext<LoadingContextInterface>({
  setNewIsLoading: (value: boolean) => null,
  newIsLoading: false,
})
