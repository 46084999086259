import {Form as FormikForm} from 'formik'

import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'

export const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

export const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

export const RightColumn = styled('div')({
  gridColumn: '2/3',
  overflow: 'hidden',
  textAlign: 'left',
  width: 250,
})

export const Form = styled(FormikForm)({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '7fr 3fr',
})

export default {
  Button,
  Form,
  LeftColumn: Mui.Grid,
  Red,
  RightColumn,
}
