import {createApi} from '@reduxjs/toolkit/query/react'
import type {
  AllDataCleanups,
  ICreateDataCleanupInputParams,
  IDataCleanup,
  IUpdateDataCleanupInputParams,
} from '~/src/models/dataCleanup'
import {baseQuery} from '../base-query'

export const dataCleanupApi = createApi({
  reducerPath: 'dataCleanupApi',
  tagTypes: ['DataCleanup'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    //void makes it so we can do () when calling query
    getAllDataCleanups: builder.query<AllDataCleanups, string>({
      providesTags: ['DataCleanup'],
      query: (agencyId) => ({
        url: `/dataCleanup/getAllDataCleanups`,
        method: 'GET',
        params: {agencyId},
      }),
    }),

    getApplicantsByDataCleanupId: builder.query<
      {applicantIds: string[]; count: number},
      {id: string; startIndex: number; endIndex: number; agencyId?: string; __?: string}
    >({
      providesTags: ['DataCleanup'],
      query: ({id, startIndex, endIndex, agencyId}) => ({
        url: `/dataCleanup/getApplicantIdsByDataCleanup/${id}`,
        method: 'GET',
        params: {startIndex, endIndex, agencyId},
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse: (data: {applicantIds: string[]; count: number}) => {
        return data
      },
    }),

    createDataCleanup: builder.mutation<IDataCleanup, ICreateDataCleanupInputParams>({
      invalidatesTags: ['DataCleanup'],
      query: ({agencyId, ...rest}) => ({
        url: `/dataCleanup/createDataCleanup`,
        method: 'POST',
        body: rest,
        params: {agencyId},
      }),
    }),

    updateDataCleanup: builder.mutation<IDataCleanup, IUpdateDataCleanupInputParams>({
      invalidatesTags: ['DataCleanup'],
      query: ({agencyId, ...rest}) => ({
        url: `/dataCleanup/updateDataCleanup`,
        method: 'PUT',
        body: rest,
        params: {agencyId},
      }),
    }),

    skipDataCleanupCandidate: builder.mutation<
      IDataCleanup,
      {applicantId: string; id: string; notes: string; agencyId?: string}
    >({
      invalidatesTags: ['DataCleanup'],
      query: ({id, applicantId, notes, agencyId}) => ({
        url: `/dataCleanup/skipCandidate`,
        method: 'POST',
        body: {applicantId, id, notes},
        params: {agencyId},
      }),
    }),

    cleanDataCleanupCandidate: builder.mutation<
      IDataCleanup,
      {applicantId: string; id: string; agencyId?: string}
    >({
      invalidatesTags: ['DataCleanup'],
      query: ({id, applicantId, agencyId}) => ({
        url: `/dataCleanup/cleanCandidate`,
        method: 'POST',
        body: {applicantId, id},
        params: {agencyId},
      }),
    }),

    getCleanedApplicantsByDataCleanupId: builder.query<
      {cleanedApplicantIds: string[]; count: number},
      {id: string; startIndex: number; endIndex: number; agencyId?: string}
    >({
      providesTags: ['DataCleanup'],
      query: ({id, startIndex, endIndex, agencyId}) => ({
        url: `/dataCleanup/getCleanedApplicantIdsByDataCleanup/${id}`,
        method: 'GET',
        params: {startIndex, endIndex, agencyId},
      }),
    }),
  }),
})

export const {
  useGetAllDataCleanupsQuery,
  useCreateDataCleanupMutation,
  useUpdateDataCleanupMutation,
  useSkipDataCleanupCandidateMutation,
  useCleanDataCleanupCandidateMutation,
  useLazyGetAllDataCleanupsQuery,
  useLazyGetApplicantsByDataCleanupIdQuery,
  useLazyGetCleanedApplicantsByDataCleanupIdQuery,
} = dataCleanupApi
