import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import {css, styled, useTheme} from '@mui/material/styles'
import {FormikSelect, MenuItem} from '@ui/components'
import FormikIconDatePicker from '@ui/date-time-pickers/FormikIconDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {setHours, setMinutes, setSeconds} from 'date-fns'
import {Formik, Form as FormikForm} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {ICreateDataCleanupInputParams} from '~/src/models/dataCleanup'
import {useCreateDataCleanupMutation} from '~/src/store/apis/data-cleanup-api'
import {useAppSelector} from '~/src/store/store-hooks'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    height: 274px;
    width: 260px;
    border-radius: 16px;
    padding: 5px 15px;
    margin-top: 30px;
  `,
) as typeof Box

const FirstRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    padding-bottom: 5px;
  `,
) as typeof Box

const Title = styled('div')(
  ({theme}) => css`
    font: normal 700 16px 'Open Sans';
    color: ${theme.palette.grey[900]};
  `,
)

const DueDateContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    font: normal 400 10.35px 'Open Sans';
    color: ${theme.palette.grey[700]};
  `,
) as typeof Box

const SecondRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
  `,
) as typeof Box

const SaveButton = styled(Button)`
  &.MuiButton-root {
    font: normal 400 15px 'Open Sans';
    color: #333333;
  }
` as typeof Button

const ThirdRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  `,
) as typeof Box

interface IProps {
  callback?: (
    isSuccess: boolean,
    res: {id: string; totalApplicants: number; applicantIds: number},
    isNormalAdd?: boolean,
  ) => void
  sx?: object
}

interface IFormSubmitValues {
  title: string
  cleanupRange: string | undefined
}

export default function CreateDataCleanupCard({
  callback = (
    isSuccess: boolean,
    res: {id: string; totalApplicants: number; applicantIds: number},
    isNormalAdd?: boolean,
  ) => {},
  sx,
}: IProps) {
  const theme = useTheme()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [createDataCleanup] = useCreateDataCleanupMutation()

  const initialFormValues = {
    title: '',
    cleanupRange: 'ThirtyDayRange',
  } as IFormSubmitValues

  const handleSubmit = async (formikValues: IFormSubmitValues) => {
    // Setting to 5:00 pm
    const inputParams = JSON.parse(JSON.stringify(formikValues)) as ICreateDataCleanupInputParams

    inputParams.agencyId = loggedInAgencyId
    try {
      const res = await createDataCleanup(inputParams).unwrap()
      callback(
        true,
        {
          id: res.id,
          // totalApplicants: inputParams.quantity,
          applicantIds: res.applicantIds ? res.applicantIds.length : 0,
        },
        false,
      )

      return
    } catch (e: unknown) {
      callback(false, {id: '', totalApplicants: 0, applicantIds: 0}, false)
    }
  }

  return (
    <Container sx={{...sx}}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        validateOnBlur={false}
        validationSchema={Yup.object({
          title: Yup.string().required('Required'),
          cleanupRange: Yup.string().required('Required'),
        })}>
        {({isSubmitting, setFieldValue, values, handleChange, errors}) => {
          return (
            <FormikForm>
              <FirstRow>
                <Title>New Data Clean-Up</Title>
                <IconButton
                  aria-label="close"
                  size="large"
                  onClick={() => {
                    callback(false, {}, true)
                  }}
                  style={{position: 'relative', right: '-10px'}}>
                  <CloseIcon style={{color: theme.palette.grey[900]}} />
                </IconButton>
              </FirstRow>
              <FormikTextField
                aria-label="Title Text Field"
                name="title"
                placeholder="Enter Title"
                inputProps={{maxLength: 50}}
                sx={{marginTop: '10px'}}
              />
              <div style={{width: '100%', marginTop: '15px'}}>
                <FormikSelect
                  InputProps={{
                    readOnly: false,
                  }}
                  sx={{width: '100%'}}
                  name="cleanupRange">
                  <MenuItem value="ThirtyDayRange">30 Days Check-up</MenuItem>
                  <MenuItem value="SixtyDayRange">60 Days Check-up</MenuItem>
                  <MenuItem value="NinetyDayRange">90 Days Check-up</MenuItem>
                </FormikSelect>
              </div>
              <SecondRow />
              <DueDateContainer>Due Date</DueDateContainer>
              <ThirdRow>
                <SaveButton aria-label="Save Button" variant="outlined" type="submit">
                  Save
                </SaveButton>
              </ThirdRow>
            </FormikForm>
          )
        }}
      </Formik>
    </Container>
  )
}
