import React from 'react'

export default function EulaContent(): JSX.Element {
  return (
    <div>
      <b>
        <div style={{textAlign: 'center'}}>END USER LICENSE AGREEMENT</div>
        <div style={{textAlign: 'center'}}>STAFFOLOGY</div>
        <br /> IMPORTANT - READ THIS AGREEMENT BEFORE USING YOUR PRODUCT. USING YOUR PRODUCT
        INDICATES YOUR ACCEPTANCE OF THIS AGREEMENT.
      </b>
      <br />
      <br /> This End User License Agreement (“EULA”) is a legal agreement between you and Kraferd,
      a California corporation (“Staffology”), as the licensor of the software (except for Excluded
      Software as defined below) accessible at the website located at www.jobsource-msp.com (the
      “Product”) and related materials which shall be collectively referred to as the “Software.”
      This EULA covers the Software, including but not limited to the software of Staffology’s
      affiliates and third party licensors (“Third Party Licensors”), and accompanying printed or
      online documentation. The Software includes all of the software in your Product, including
      updates or modified software provided to you by Staffology, whether stored on media or
      downloaded via any method, but not Excluded Software as defined below.
      <br />
      <br /> STAFFOLOGY PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS
      EULA AND ON THE CONDITION THAT YOU ACCEPT AND COMPLY WITH THEM.
      <br />
      <br /> IF YOU REPRESENT A BUSINESS ENTITY (“COMPANY”) USING THE SOFTWARE, YOU WARRANT THAT YOU
      HAVE THE RIGHT, POWER AND AUTHORITY TO BIND THE COMPANY, AND THE TERMS HEREIN SHALL APPLY TO
      BOTH YOU AND THE COMPANY JOINTLY AND SEVERALLY. BY CLICKING THE “ACCEPT” BUTTON BELOW, AND/OR
      BY DOWNLOADING, INSTALLING OR OTHERWISE USING THE SOFTWARE, YOU (A) ACCEPT THIS EULA AND AGREE
      THAT YOU ARE LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT: (I) YOU ARE 18
      YEARS OF AGE OR OLDER; (II) YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS EULA
      AND BIND YOURSELF (AND COMPANY, IF APPLICABLE) TO ITS TERMS; AND (III) IF YOU DO NOT AGREE TO
      THE TERMS OF THIS EULA, STAFFOLOGY WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO YOU AND YOU
      MUST NOT DOWNLOAD, INSTALL OR USE THE SOFTWARE OR DOCUMENTATION.
      <br />
      <br /> Staffology may add to, change, or remove any part, term, or condition of this EULA,
      including but not limited to as it applies to the Software at any time without prior notice or
      liability to you. Any such additions, changes, or removals provided via updates shall apply as
      soon as such updates are installed. By continuing to use the Product or Software after
      installing such updates, you are indicating your acceptance thereto. <br />
      <br />
      <b>SOFTWARE LICENSE</b>
      <br />
      <br /> The Software is owned by Staffology and the Third Party Licensors, and its structure,
      organization and code are valuable trade secrets of Staffology and the Third Party Licensors.
      Except as expressly set forth in this EULA, this EULA does not grant you any intellectual
      property rights in your Product (including but not limited to the Software), and you cannot
      use the Software except as specified herein. The Software is licensed, not sold. Staffology
      grants you a limited license to use the Software only on one (1) computer or mobile device, as
      applicable, and you may create one (1) back up copy of the Software. If you received a trial
      version of the Software, subject to your compliance with the terms and conditions of this
      EULA, Staffology grants you a limited license for a limited trial period to use one (1) copy
      of the Software to evaluate the Software, and only for your personal, noncommercial use on a
      single computer or mobile device, as applicable. The Software may create data files
      automatically for use with the Software, and you agree that any such data files are deemed to
      be a part of the Software. This does not include, however, any user-generated content, which
      will remain the property of the owner. The Software is licensed as a single product, and you
      may not separate its component parts for use on more than one (1) computer or mobile device
      unless expressly authorized by Staffology. You agree not to copy, modify, publish, adapt,
      redistribute, reverse engineer, decompile, disassemble, attempt to derive or discover source
      code, or otherwise reduce to a human-perceivable form, or create derivative works of, the
      Software in whole or in part or to use the Software in whole or in part for any purpose other
      than as expressly permitted under this EULA. In addition, you may not share, distribute, loan,
      rent, lease, sublicense, assign, transfer, or sell the Software. Staffology and its Third
      Party Licensors retain all rights, title and interest (including but not limited to
      intellectual property rights) that this EULA does not expressly grant to you. You shall not:
      (a) violate, tamper with, bypass, modify, defeat, or circumvent any of the functions or
      protections of the Software, or any mechanisms operatively linked to the Software; or (b)
      remove, alter, cover, or deface any trademarks or proprietary legends, notices or language in
      or on the Software. You shall not use any of Staffology’s trade names, trademarks, service
      marks or other designation(s) for any purpose without express written permission by
      Staffology. <br />
      <br />
      <b>EXCLUDED SOFTWARE</b>
      <br />
      <br /> Notwithstanding the foregoing limited license grant, you acknowledge that the Product
      includes software subject to other terms and conditions governing the use of such software
      other than this EULA (“Excluded Software”). Certain Excluded Software may be covered by open
      source software licenses (“Open Source Components”), which means any software licenses
      approved as open source licenses by the Open Source Initiative or any substantially similar
      licenses, including but not limited to any license that, as a condition of distribution of the
      software licensed under such license, requires that the distributor make the software
      available in source code format. Such terms and conditions may be changed by the applicable
      third party at any time without liability to you. To the extent required by the licenses
      covering Open Source Components, the terms of such licenses will apply in lieu of the terms of
      this EULA. To the extent the terms of the licenses applicable to Open Source Components
      prohibit any of the restrictions in this EULA with respect to such Open Source Components,
      such restrictions will not apply to such Open Source Component. To the extent the terms of the
      licenses applicable to Open Source Components require Staffology to make an offer to provide
      source code in connection with the Software, such offer is hereby made. <br />
      <br />
      <b>INTERNET CONNECTIVITY AND THIRD PARTY SERVICES</b>
      <br />
      <br /> You understand, acknowledge and agree that access to Product features, including but
      not limited to required registration of the Product, requires an Internet connection for which
      you are solely responsible. You are solely responsible for payment of any third party fees
      associated with your Internet connection, including but not limited to Internet service
      provider or airtime charges. Operation of the Product and Software may be limited or
      restricted depending on the capabilities, bandwidth or technical limitations of your Internet
      connection and service. You understand, acknowledge and agree that Internet connectivity in
      relation to the Product is provided by third parties over which Staffology has no control, and
      is governed by the respective terms of such third parties. The provision, quality,
      availability and security of such Internet connectivity, software and services are the sole
      responsibility of such third party. You understand, acknowledge and agree that this software
      must be activated, by contacting our network through the internet, before the software will
      operate, and that it may only be re-activated on different computers a limited amount of times
      before the license will automatically deactivate. <br />
      <br />
      <b>EXCLUSION OF WARRANTY</b>
      <br />
      <br /> The Software and any other software provided on or through the Product is not
      fault-tolerant and is not designed, manufactured or intended for any use other than specified
      by Staffology.
      <br />
      <br /> THE SOFTWARE, PRODUCT AND ANY ACCOMPANYING DOCUMENTATION ARE FURNISHED TO YOU “AS IS”
      AND “AS AVAILABLE” AND WITHOUT WARRANTIES, DUTIES OR CONDITIONS, STATUTORY OR OTHERWISE, OF
      ANY KIND. STAFFOLOGY, ITS AFFILIATES, AND THIRD PARTY LICENSORS EXPRESSLY DISCLAIM ALL
      WARRANTIES, DUTIES AND CONDITIONS, EXPRESS (EXCEPT TO THE EXTENT EXPRESSLY PROVIDED OTHERWISE
      BY THIS EULA) OR IMPLIED, STATUTORY OR OTHERWISE, OF ANY KIND, INCLUDING BUT NOT LIMITED TO
      THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE, MERCHANTABILITY, AND FITNESS FOR A
      PARTICULAR PURPOSE. STAFFOLOGY, ITS AFFILIATES, AND THIRD PARTY LICENSORS DO NOT WARRANT THAT
      THE SOFTWARE OR ACCOMPANYING DOCUMENTATION, WILL MEET YOUR REQUIREMENTS OR PROVIDE SPECIFIC
      RESULTS, OR THAT THEY WILL BE UPDATED, OR THAT THE OPERATION OF ALL OR ANY OF THEM WILL BE
      UNINTERRUPTED OR ERROR FREE, OR THAT ANY DEFECTS WILL BE CORRECTED. FURTHERMORE, STAFFOLOGY,
      ITS AFFILIATES, AND THIRD PARTY LICENSORS DO NOT WARRANT OR MAKE ANY REPRESENTATIONS OR
      CONDITIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SOFTWARE OR ACCOMPANYING
      DOCUMENTATION IN TERMS OF THEIR ACCURACY, RELIABILITY, COMPLETENESS OR OTHERWISE. STAFFOLOGY
      and ITS AFFILIATES AND THIRD PARTY LICENSORS SHALL HAVE NO RESPONSIBILITY FOR THE security of,
      TIMELINESS, DELETION, MIS-DELIVERY, OR FAILURE TO STORE ANY OF YOUR COMMUNICATIONS OR
      PERSONALIZED SETTINGS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY STAFFOLOGY, ITS
      AFFILIATES, A STAFFOLOGY AUTHORIZED REPRESENTATIVE OR ANY THIRD PARTY SHALL CREATE A WARRANTY,
      DUTY OR CONDITION, OR IN ANY WAY CHANGE THIS EXCLUSION OF WARRANTY, DUTY AND CONDITION. SHOULD
      THE SOFTWARE OR THE MEDIA ON WHICH IT IS FURNISHED OR THE ACCOMPANYING DOCUMENTATION PROVE
      DEFECTIVE, YOU (AND NOT STAFFOLOGY, ITS AFFILIATES, A STAFFOLOGY AUTHORIZED REPRESENTATIVE OR
      ANY THIRD PARTY) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.
      <br />
      <br /> Without limiting the generality of the foregoing, you further understand, acknowledge
      and agree that the Software is not designed or intended for use on any other Product other
      than the Product. You expressly acknowledge and agree that any other hardware, software,
      content or data could be damaged by installing or using the Software on any other Product
      other than the Product, and Staffology, its affiliates and Third Party Licensors are not
      responsible for any such damage. <br />
      <br />
      <b>LIMITATION OF LIABILITY</b>
      <br />
      <br /> IN NO EVENT WILL STAFFOLOGY (INCLUDING BUT NOT LIMITED TO ANY NEGLIGENCE ISSUES RELATED
      TO THIRD PARTY LICENSORS), ITS AFFILIATES OR THIRD PARTY LICENSORS BE LIABLE TO YOU FOR ANY
      SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
      INCLUDING BUT NOT LIMITED TO COMPENSATION, REIMBURSEMENT OR DAMAGES IN CONNECTION WITH,
      ARISING OUT OF OR RELATING TO THIS EULA ON ACCOUNT OF THE USE OR LOSS OF USE OF THE PRODUCT,
      SOFTWARE OR ACCOMPANYING DOCUMENTATION, DOWNTIME OR YOUR TIME, LOSS OF PRESENT OR PROSPECTIVE
      PROFITS, LOSS OF DATA, INFORMATION OF ANY KIND, BUSINESS PROFITS, OR OTHER COMMERCIAL LOSS, OR
      FOR ANY OTHER REASON WHATSOEVER, WHETHER BASED ON THEORIES OF CONTRACT OR TORT (INCLUDING BUT
      NOT LIMITED TO NEGLIGENCE OR STRICT LIABILITY), EVEN IF STAFFOLOGY, ITS AFFILIATES OR THIRD
      PARTY LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE PRODUCT, SOFTWARE
      AND ACCOMPANYING DOCUMENTATION ARE FURNISHED TO YOU FOR USE AT YOUR OWN RISK. STAFFOLOGY, ITS
      AFFILIATES, AND THIRD PARTY LICENSORS WILL NOT BE LIABLE FOR DAMAGES FOR BREACH OF ANY EXPRESS
      (EXCEPT TO THE EXTENT EXPRESSLY PROVIDED OTHERWISE BY THIS EULA OR ACCOMPANYING DOCUMENTATION)
      OR IMPLIED WARRANTY, DUTY OR CONDITION, UNDER BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY
      OR ANY OTHER LEGAL THEORY RELATED TO THE PRODUCT, SOFTWARE, ACCOMPANYING DOCUMENTATION, OR
      THIS EULA. STAFFOLOGY, ITS AFFILIATES, and THIRD PARTY LICENSORS CANNOT ENSURE THAT THE
      PRODUCT, THE SOFTWARE OR OTHER DATA YOU ACCESS OR DOWNLOAD FROM OR THROUGH the PRODUCT WILL BE
      FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES, AND STAFFOLOGY, ITS AFFILIATES, and
      THIRD PARTY LICENSORS DISCLAIM ANY LIABILITY RELATED THERETO. STAFFOLOGY, ITS AFFILIATES and
      THIRD PARTY LICENSORS FURTHER DISCLAIM ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND
      CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF the PRODUCT or
      SOFTWARE. IF, NOTWITHSTANDING THE TERMS OF THIS EULA, STAFFOLOGY, ITS AFFILIATES and THIRD
      PARTY LICENSORS ARE FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH ARISES UNDER OR IN
      CONNECTION WITH THIS EULA, INCLUDING BUT NOT LIMITED TO YOUR USE OF the PRODUCT, THE SOFTWARE,
      or ACCOMPANYING DOCUMENTATION, THEIR COLLECTIVE TOTAL AGGREGATE LIABILITY TO YOU SHALL IN NO
      EVENT EXCEED THE MANUFACTURER’S SUGGESTED RETAIL PRICE OF YOUR PRODUCT. <br />
      <br />
      Some jurisdictions may not allow exclusions or limitations of incidental or consequential
      damages, exclusions or limitations of implied warranties, duties or conditions, or allow
      limitations on how long an implied warranty, duty or condition lasts, so the above limitations
      or exclusions may not apply to you. <br />
      <br />
      <b>USE OF SOFTWARE WITH COPYRIGHTED MATERIALS</b>
      <br />
      <br />
      The Software can be used by you to store, process and use content created by you and third
      parties. Such content may be protected by copyright, other intellectual property laws, and/or
      agreements. You agree to use the Software only in compliance with all such laws and agreements
      that apply to such content. You agree that Staffology may take appropriate measures to protect
      copyright of content stored, processed or used by the Software. <br />
      <br />
      <b>EXPORT RESTRICTIONS</b>
      <br />
      <br /> The Software may contain encryption technology. You acknowledge that any export of
      Software containing encryption technology from the United States or subsequent re-export of
      such software or content by a person located outside of the United States requires a license
      or other authorization from the U.S. Department of Commerce’s Bureau of Industry and Security.
      You further acknowledge that the Software containing encryption technology and acquired from
      Staffology or its Third Party Licensors is not intended for use by a foreign government end
      user. By accepting this EULA, you agree to abide by all relevant U.S. export laws and
      regulations in the purchase and use of the Product being acquired, including but not limited
      to those regulations relating to the export control of cryptographic items and not to
      transfer, or authorize the transfer, of the Product or Software to a prohibited country or
      otherwise in violation of any such restrictions or regulations. <br />
      <br />
      <b>ARBITRATION</b>
      <br />
      <br /> A. In the interest of resolving disputes between you and Staffology in the most
      expedient and cost effective manner, you and Staffology agree that any and all disputes
      arising in connection with this EULA shall be resolved by binding arbitration. Arbitration is
      more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a
      judge or jury, may allow for more limited discovery than in court, and can be subject to very
      limited review by courts. Arbitrators can award the same damages and relief that a court can
      award. This EULA to arbitrate disputes includes, but is not limited to all claims arising out
      of or relating to any aspect of this EULA, whether based in contract, tort, statute, fraud,
      misrepresentation or any other legal theory, and regardless of whether the claims arise during
      or after the termination of these Terms. You understand and agree that, by entering into this
      EULA, you and Staffology are each waiving the right to a trial by jury or to participate in a
      class action. <br />
      <br />
      B. Notwithstanding subsection (A) above, you and Staffology both agree that nothing herein
      shall be deemed to waive, preclude, or otherwise limit either of Staffology's right to (i)
      bring an individual action in small claims court, (ii) pursue enforcement actions through
      applicable federal, state, or local agencies where such actions are available, (iii) seek
      injunctive relief in a court of law, (iv) to file suit in a court of law to address
      intellectual property infringement claims, or (v) to seek remedies permitted by GDPR. <br />
      <br />
      C. Any arbitration between you and Staffology will be governed by the Commercial Dispute
      Resolution Procedures and the Supplementary Procedures for Consumer Related Disputes
      (collectively, "AAA Rules") of the American Arbitration Association ("AAA"), as modified by
      this EULA, and will be administered by the AAA. The AAA Rules and filing forms are available
      online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting Staffology.{' '}
      <br />
      <br />
      D. A party who intends to seek arbitration must first send a written notice of the dispute to
      the other, by certified mail or Federal Express (signature required), or in the event that
      Staffology does not have a physical address on file for You, by electronic mail ("Notice").
      Staffology's address for Notice is: Staffology 1600 N. Broadway, Suite 900, Santa Ana, CA
      92706. The Notice must (a) describe the nature and basis of the claim or dispute; and (b) set
      forth the specific relief sought ("Demand"). You and Staffology agree to use good faith
      efforts to resolve the claim directly, but if an agreement is not reached within 30 days after
      the Notice is received, you or Staffology may commence an arbitration proceeding. During the
      arbitration, the amount of any settlement offer made by you or Staffology shall not be
      disclosed to the arbitrator until after the arbitrator makes a final decision and award, if
      any. In the event our dispute is finally resolved through arbitration in Your favor,
      Staffology shall pay you the lessor of (i) the amount awarded by the arbitrator, if any, (ii)
      the last written settlement amount offered by Staffology in settlement of the dispute prior to
      the arbitrator’s award, or (iii) $1,000.00. <br />
      <br />
      E. In the event that you commence arbitration in accordance with this EULA, Staffology will
      reimburse you for your payment of the filing fee, unless Your claim is for greater than
      $10,000, in which case the payment of any fees shall be decided by the AAA Rules. Any
      arbitration hearings will take place at a location to be agreed upon in California, provided
      that if the claim is for $10,000 or less, you may choose whether the arbitration will be
      conducted solely on the basis of documents submitted to the arbitrator, through a
      non-appearance based telephonic hearing, or by an in-person hearing as established by the AAA
      Rules in California. If the arbitrator finds that either the substance of Your claim or the
      relief sought in the Demand is frivolous or brought for an improper purpose (as measured by
      the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
      fees will be governed by the AAA Rules. In such case, you agree to reimburse Staffology for
      all monies previously disbursed by it that are otherwise Your obligation to pay under the AAA
      Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall
      issue a reasoned written decision sufficient to explain the essential findings and conclusions
      on which the decision and award, if any, are based. The arbitrator may make rulings and
      resolve disputes as to the payment and reimbursement of fees or expenses at any time during
      the proceeding and upon request from either party made within 14 days of the arbitrator’s
      ruling on the merits. <br />
      <br />
      F. YOU AND STAFFOLOGY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
      INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
      REPRESENTATIVE PROCEEDING. Further, unless both you and Staffology agree otherwise, the
      arbitrator may not consolidate more than one person’s claims, and may not otherwise preside
      over any form of a representative or class proceeding. <br />
      <br />
      G. In the event that Staffology makes any future change to this arbitration provision (other
      than a change to the Staffology's address for Notice), you may reject any such change by
      sending us written notice within 30 days of the change to Staffology's address for Notice, in
      which case your account with Staffology shall be immediately terminated and this arbitration
      provision, as in effect immediately prior to the amendments you reject shall survive. <br />
      <br />
      <b>JURY TRIAL & CLASS ACTION WAIVER</b>
      <br />
      <br />
      TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, THE PARTIES HERETO WAIVE TRIAL BY JURY WITH
      RESPECT TO ANY MATTERS OR DISPUTES ARISING UNDER OR RELATING TO THIS EULA. Any claim or cause
      of action arising under this EULA, or you may have with respect to the Product, Software, or
      accompanying documentation must be commenced within one (1) year after the claim or cause of
      action arises.
      <br />
      <br /> Any court trial or arbitration (whether before a judge or jury or pursuant to judicial
      reference) of any Claim will take place on an individual basis without resort to any form of
      class or representative action (the "Class Action Waiver"). THE CLASS ACTION WAIVER PRECLUDES
      ANY PARTY FROM PARTICIPATING IN OR BEING REPRESENTED IN ANY CLASS OR REPRESENTATIVE ACTION
      REGARDING A CLAIM. Regardless of anything else in this EULA, the validity and effect of the
      Class Action Waiver may be determined only by a court or referee and not by an arbitrator. The
      Parties to this EULA acknowledge that the Class Action Waiver is material and essential to the
      litigation of any disputes between the Parties and is nonseverable from the EULA. THE PARTIES
      ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES WILL A CLASS ACTION BE ARBITRATED.
      <br />
      <br />
      <b>EQUITABLE REMEDIES</b>
      <br />
      <br /> You agree that any violation of or non-compliance with any term or condition this EULA
      by you will constitute an unlawful and unfair business practice, and will cause irreparable
      harm to Staffology, its affiliates or Third Party Licensors for which monetary damages would
      be inadequate, and you consent to Staffology obtaining any injunctive or equitable relief that
      Staffology deems necessary or appropriate in such circumstances. These remedies are in
      addition to any other remedies that may be available to Staffology under contract, at law or
      in equity. <br />
      <br />
      <b>ENTIRE AGREEMENT, NOTICE, WAIVER, SEVERABILITY</b>
      <br />
      <br /> This EULA, as amended and modified from to time, together constitute the entire
      agreement between you and Staffology with respect to the subject matter hereof. The United
      Nations Convention on Contracts for the International Sale of Goods shall not apply to this
      EULA, including but not limited to the Product or the Software. Furthermore, this EULA
      (including but limited to the Product or the Software) will not be governed or interpreted in
      any way by referring to any law based on the Uniform Computer Information Transactions Act
      (UCITA) or any other act derived from or related to UCITA. Any notice by Staffology hereunder
      may be made by letter, e-mail, or posting on or through the Product, or as otherwise specified
      herein. The failure of Staffology to exercise or enforce any right or provision of this EULA
      shall not constitute a waiver of such right or provision. If any part of this EULA is held
      invalid, illegal, or unenforceable, that provision shall be enforced to the maximum extent
      permissible so as to maintain the intent of this EULA, and the other parts will remain in full
      force and effect. THIRD PARTY BENEFICIARIES This EULA shall not be interpreted or construed to
      confer any rights or remedies on any third parties, except that each Indemnified Party, and
      Third Party Licensor shall and is an express intended third party beneficiary of, and shall be
      entitled to directly enforce and rely upon, each provision of this EULA that confers a right
      or remedy in favor of such party. <br />
      <br />
      <b>TERM AND SURVIVAL</b>
      <br />
      <br /> This EULA is effective until terminated. Staffology may terminate this EULA immediately
      if you fail to comply with its terms by giving you notice. In addition, upon termination you
      will have no recourse against Staffology, its affiliates or Third Party Licensors for your
      inability to use the Software or the accompanying documentation. Any of your obligations under
      this EULA which by their nature are intended to survive the termination of this EULA or your
      use of the Product or Software shall continue to apply to you after the termination of this
      EULA or you cease to use the Product or Software. <br />
      <div style={{textAlign: 'center'}}>© 2022 KRAFERD All Rights Reserved.</div>
    </div>
  )
}
