import React from 'react'
import {FieldConfig, useField} from 'formik'
import FormHelperText from '@mui/material/FormHelperText'

import S from './TextField.styles'
import TextFieldBase from './TextFieldBase'
import type {ITextField} from './typings'

export default function StandardFormikField({label, ...props}: ITextField) {
  const [field, meta] = useField(props as FieldConfig)
  const errorMsg = meta.touched && meta.error ? meta.error : ''

  return (
    <TextFieldBase
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      slots={{
        TopLeft: label && (
          <S.InputLabel htmlFor={props.id ?? props.name} focused={false}>
            {label}
          </S.InputLabel>
        ),
        Bottom: <FormHelperText>{errorMsg}</FormHelperText>,
      }}
    />
  )
}
