import {MenuItem} from '@mui/material'
import React, {MouseEvent} from 'react'
import {NavLink, Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

interface NavMenuItemProps {
  text: string
  link: string
  active: boolean
  id: string
  handleSetActive: (e: MouseEvent<HTMLElement>) => void
}

interface StyleProps {
  $active: boolean
  id: string
}

const StyledNavLink = styled(NavLink)(
  ({theme}) => css`
    padding-left: 16px;
    padding-right: 16px;
    height: 28px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${(props: StyleProps) => (props.$active ? theme.palette.primary.main : '#334D6E')};
    text-decoration: none;
    &:hover {
      color: ${theme.palette.primary.main};
      background-color: rgba(0, 0, 0, 0.04);
    }
  `,
)

const NavMenuItem = ({link, text, active, handleSetActive, id}: NavMenuItemProps) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    handleSetActive(event)
  }

  return (
    <StyledNavLink style={{}} onClick={handleClick} id={id} to={link} $active={active}>
      {text}
    </StyledNavLink>
  )
}

export default NavMenuItem
