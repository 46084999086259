import {Avatar, Table, TableContainer, Tooltip} from '@mui/material'
import Divider from '@mui/material/Divider'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import type {IJobOrder} from '~/src/models/jobOrder'
import type {IPosition} from '~/src/models/position'
import {useGetAgencyNameByIdQuery, useGetAgencyNamesByIdsQuery} from '~/src/store/apis/agency-api'

export const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
    flex-basis: 25%;
  `,
}

export const PositionBreakdownCardContainer = styled('div')({
  marginLeft: '21px',
  marginTop: '13px',
})

export const PositionBreakdownText = styled('div')({
  fontSize: '18px',
  fontWeight: '700',
  textAlign: 'left',
  marginBottom: '15px',
})

export const TotalCandidatesHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 10,
  marginBottom: 10,
})

export const BodyContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 10,
})

export const TotalNeededText = styled('td')({
  fontSize: '15px',
  fontWeight: 600,
  paddingLeft: 10,
})

export const FlexContainer = styled('div')({
  display: 'flex',
})

export const AvatarContianer = styled('td')({
  textAlign: 'center',
  justifyContent: 'center',
  width: '200px',
  maxWidth: '200px',
  display: 'flex',
})

export const AgencyNameContainer = styled('td')({
  maxWidth: '100px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const TotalLeftText = styled('div')({
  marginRight: 3,
})

export const TotalLeftNumberText = styled('div')({
  color: '#FF6760',
})

export const CustomBorder = styled('div')({
  position: 'absolute',
  border: '1px solid #F1F1F1',
  height: '223px',
  left: '284px',
  marginTop: -10,
  marginBottom: -10,
})

export const CustomHorizontalBorder = styled(Divider)({
  position: 'absolute',
  width: '100%',
  marginTop: 32,
  marginLeft: '-21px',
  zIndex: 2,
})

export const CustomTRHeader = styled('tr')({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 2,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

interface IProps {
  JobOrderData: IJobOrder
  totalCandidatesLeft: number
  totalCandidatesNeeded: number
  positionData: IPosition
}

interface Provider {
  agencyId: string
  agencyCandidateDistributions: {
    fulfilled: number
    requested: number
  }
}
// gets rid of red lines
interface Provider2 {
  agencyId: string
  fulfilled: number
  requested: number
}

function PositionRow(props: Provider2): JSX.Element {
  const {
    data,
    isLoading: agencyIsLoading,
    error,
    refetch: agencyRefetch,
  } = useGetAgencyNamesByIdsQuery([props.agencyId])

  const leftNumber = props.requested - props.fulfilled < 0 ? 0 : props.requested - props.fulfilled

  useEffect(() => {
    void agencyRefetch()
  }, [agencyRefetch])

  return (
    <tr style={{lineHeight: '14px', height: '40px'}}>
      <td>
        <Avatar
          sx={{maxWidth: '18px', maxHeight: '18px'}}
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
        />
      </td>
      <Tooltip title={data ? data[0]?.name : ''}>
        <AgencyNameContainer>{data ? data[0]?.name : ''}</AgencyNameContainer>
      </Tooltip>
      <TotalNeededText>{props.requested}</TotalNeededText>
      <td />
      <td>Left:</td>
      <td style={{color: '#FF6760'}}>{leftNumber}</td>
    </tr>
  )
}

export default function MspPositionBreakdownSummaryCard({
  JobOrderData,
  totalCandidatesNeeded,
  totalCandidatesLeft,
  positionData,
}: IProps) {
  return (
    <>
      <DashboardMainStyles.Container>
        <PositionBreakdownCardContainer>
          <PositionBreakdownText>Position Breakdown</PositionBreakdownText>
          <Divider sx={{marginLeft: '-21px'}} />
          <CustomHorizontalBorder />
          {/* stupid fix but it works for the overflow. all other overflows allows a scroll
          even if there is no overflow and i cannot figure out what is causing it. the conditional will
          only allow a scroll if is 5 or more agencies which is when the overflow occurs and is needed */}
          <TableContainer
            sx={{
              maxHeight: '230px',
              overflowY: JobOrderData.agencyDistributions.length > 4 ? 'auto' : 'hidden',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            <Table>
              <colgroup>
                <col style={{width: '7%'}} />
                <col style={{width: '40%'}} />
                <col style={{width: '20%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '10%'}} />
              </colgroup>
              <tbody>
                <CustomTRHeader>
                  <td colSpan={2} style={{height: '32px'}}>
                    Total Candidates Needed:{' '}
                  </td>
                  <TotalNeededText>{totalCandidatesNeeded}</TotalNeededText>
                  <td>
                    <CustomBorder />
                  </td>
                  <td>Left:</td>
                  <td style={{color: '#FF6760'}}>{totalCandidatesLeft}</td>
                </CustomTRHeader>
                {JobOrderData.agencyDistributions.map((valueRow: Provider, index: number) => {
                  return (
                    <PositionRow
                      key={index}
                      agencyId={valueRow.agencyId}
                      requested={valueRow.agencyCandidateDistributions.requested}
                      fulfilled={valueRow.agencyCandidateDistributions.fulfilled}
                    />
                  )
                })}
              </tbody>
            </Table>
          </TableContainer>
        </PositionBreakdownCardContainer>
      </DashboardMainStyles.Container>
    </>
  )
}
