import {createApi} from '@reduxjs/toolkit/query/react'
import type {ISuccessMessage} from '~/src/models'
import type {
  IJobOrder,
  JobOrderCreate,
  TJobOrderAddCandidateRequest,
  TJobOrderBatchedResponse,
  TJobOrderPayload,
  TPagedJobOrderInputParams,
} from '~/src/models/jobOrder'
import {baseQuery} from '../base-query'

export const jobOrderApi = createApi({
  reducerPath: 'JobOrderApi',
  tagTypes: ['JobOrder'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getJobOrder: builder.query<IJobOrder, {id: string; agencyId?: string}>({
      providesTags: ['JobOrder'],
      query: ({id, agencyId}) => ({
        url: `/api/joborder`,
        method: 'GET',
        params: {id, agencyId},
      }),
    }),

    // Nothing is using this endpoint in figma but good for future use
    updateFullJobOrder: builder.query<TJobOrderPayload, TPagedJobOrderInputParams>({
      providesTags: ['JobOrder'],
      query: (body) => ({
        url: `/api/joborder`,
        method: 'PUT',
        params: body,
      }),
    }),

    getJobOrders: builder.query<TJobOrderPayload, TPagedJobOrderInputParams>({
      providesTags: ['JobOrder'],
      query: ({pageNumber, pageSize, filterBy, agencyId}) => ({
        url: `/api/joborder/getJobOrders`,
        method: 'GET',
        params: {pageNumber, pageSize, filterBy, agencyId},
      }),
    }),

    getJobOrdersByServiceOrderIds: builder.query<
      TJobOrderBatchedResponse[],
      {serviceOrderIds: string[]; agencyId?: string}
    >({
      providesTags: ['JobOrder'],
      query: ({serviceOrderIds, agencyId}) => ({
        url: `/api/joborder/getJobOrdersByServiceOrderIds`,
        method: 'GET',
        params: {serviceOrderIds, agencyId},
      }),
    }),

    getJobOrdersByJobOrderOrderIds: builder.query<IJobOrder[], {ids: string[]; agencyId?: string}>({
      providesTags: ['JobOrder'],
      query: ({ids, agencyId}) => ({
        url: `/api/joborder/getJobOrdersByJobOrderIds`,
        method: 'POST',
        params: {agencyId},
        body: ids,
      }),
    }),

    createJobOrder: builder.mutation<ISuccessMessage, JobOrderCreate>({
      invalidatesTags: ['JobOrder'],
      query: ({agencyId, ...rest}) => ({
        url: `/api/joborder/createJobOrder`,
        method: 'POST',
        body: rest,
        params: {agencyId},
      }),
    }),

    deleteJobOrder: builder.mutation<ISuccessMessage, {id: string; agencyId?: string}>({
      invalidatesTags: ['JobOrder'],
      query: ({id, agencyId}) => ({
        url: `/api/joborder/${id}`,
        method: 'DELETE',
        params: {agencyId},
      }),
    }),

    // This endpoint will be used to update job order candidate list not the general job order update endpoint
    addCandidateToJobOrder: builder.mutation<ISuccessMessage, TJobOrderAddCandidateRequest>({
      invalidatesTags: ['JobOrder'],
      query: ({candidateId, candidateStatus, jobOrderId, notes, agencyId}) => ({
        url: `/api/joborder/addCandidateToJobOrder`,
        method: 'PUT',
        params: {jobOrderId, agencyId},
        body: {candidateId, candidateStatus, notes},
      }),
    }),

    addCandidatesToJobOrder: builder.mutation<
      ISuccessMessage,
      {jobOrderId: string; candidateIds: string[]; agencyId?: string}
    >({
      invalidatesTags: ['JobOrder'],
      query: ({candidateIds, jobOrderId, agencyId}) => ({
        url: `/api/joborder/addCandidatesToJobOrder`,
        method: 'PUT',
        body: {candidateIds, jobOrderId},
        params: {agencyId},
      }),
    }),

    fillJobOrder: builder.mutation<ISuccessMessage, {jobOrderId: string; agencyId?: string}>({
      invalidatesTags: ['JobOrder'],
      query: (params) => ({
        url: `/api/joborder/fillJobOrder`,
        method: 'PUT',
        params: params,
      }),
    }),

    removeCandidateFromJobOrder: builder.mutation<
      ISuccessMessage,
      {jobOrderId: string; candidateId: string; agencyId?: string}
    >({
      invalidatesTags: ['JobOrder'],
      query: (params) => ({
        url: `/api/joborder/removeCandidateFromJobOrder`,
        method: 'PUT',
        params: params,
      }),
    }),

    declineCandidateFromJobOrder: builder.mutation<
      ISuccessMessage,
      {jobOrderId: string; candidateId: string; notes: string; agencyId?: string}
    >({
      invalidatesTags: ['JobOrder'],
      query: ({candidateId, jobOrderId, notes, agencyId}) => ({
        url: `/api/joborder/declineCandidateForJobOrder`,
        method: 'PUT',
        params: {jobOrderId, agencyId},
        body: {candidateId, notes},
      }),
    }),

    skipCandidateFromJobOrder: builder.mutation<
      ISuccessMessage,
      {jobOrderId: string; candidateId: string; notes: string; agencyId?: string}
    >({
      invalidatesTags: ['JobOrder'],
      query: ({candidateId, jobOrderId, notes, agencyId}) => ({
        url: `/api/joborder/skipCandidateForJobOrder`,
        method: 'PUT',
        params: {jobOrderId, agencyId},
        body: {candidateId, notes, candidateStatus: 'Skipped'},
      }),
    }),

    updateCandidateEndDate: builder.mutation<
      ISuccessMessage,
      {agencyId?: string; id: string; candidateId: string; reason: string; endDate: Date}
    >({
      invalidatesTags: ['JobOrder'],
      query: ({agencyId, ...rest}) => ({
        url: `/api/joborder/updateCandidateEndDate`,
        method: 'PUT',
        params: {agencyId},
        body: {...rest},
      }),
    }),
  }),
})

export const {
  useGetJobOrderQuery,
  useUpdateFullJobOrderQuery,
  useGetJobOrdersQuery,
  useGetJobOrdersByServiceOrderIdsQuery,
  useLazyGetJobOrdersByJobOrderOrderIdsQuery,
  useCreateJobOrderMutation,
  useAddCandidateToJobOrderMutation,
  useAddCandidatesToJobOrderMutation,
  useFillJobOrderMutation,
  useRemoveCandidateFromJobOrderMutation,
  useDeclineCandidateFromJobOrderMutation,
  useSkipCandidateFromJobOrderMutation,
  useUpdateCandidateEndDateMutation,
} = jobOrderApi
