import {Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditVariantIcon from '@ui/icons/EditVariant'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {formatShift} from '@utils'
import React from 'react'
import {NavLink} from 'react-router-dom'
import type {IPosition, TPositionPayload} from '~/src/models/position'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import PrependedPositionHeader from './PrependedPositionHeader'

const DataContainer = styled('div')({
  display: 'block',
  width: '10ch',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

interface PositionProps {
  positions: IPosition[]
  companyFilterData?: TPositionPayload
  companies: {companyId: string; companyName: string}[]
  totalPositions: number
  currentPageNumber: number
  selectedCompany: string
  setTargetPosition(s: IPosition): void
  onPaginationChange(value: number): void
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCompanyFilter: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface Data {
  id: string
  name: string
  startTime: string
  endTime: string
  department: string
  location: string
  industry: string
  date: string
  edit: string
  shift: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: true,
    label: 'Department',
    align: 'left',
  },
  {
    id: 'location',
    numeric: true,
    disablePadding: true,
    label: 'Location',
    align: 'left',
  },

  {
    id: 'createdBy',
    numeric: true,
    disablePadding: true,
    label: 'Created By',
    align: 'left',
  },
  {
    id: 'editedBy',
    numeric: true,
    disablePadding: true,
    label: 'Edited By',
    align: 'left',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: true,
    label: 'Company',
    align: 'left',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'center',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{height: 65}}>
        <StyledTableCell />
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function ClientPositionsTable({
  positions,
  companyFilterData,
  companies,
  selectedCompany,
  totalPositions,
  currentPageNumber,
  onPaginationChange,
  onCompanyFilter,
}: PositionProps) {
  const [selected, setSelected] = React.useState<readonly string[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.positionModel.map((n) => n.id)
      setSelected(newSelecteds as string[])
      return
    }
    setSelected([])
  }

  const isSelected = (id: string) => selected.includes(id)

  return (
    <Box
      sx={{
        width: '100%',
        mb: 10,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <PrependedPositionHeader
        numSelected={selected.length}
        companies={companies}
        onCompanySelection={onCompanyFilter}
        selectedCompany={selectedCompany}
      />
      <TableContainer>
        <Table
          sx={{minWidth: 750, height: 65}}
          aria-labelledby="tableTitle"
          size="small"
          padding="normal">
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={positions.length}
          />
          <TableBody>
            {selectedCompany === 'none' ? (
              positions.map((position, index) => {
                const isItemSelected = isSelected(position.id as string)
                const labelId = `enhanced-table-checkbox-${index}`
                const department = position.departmentName
                const facility = position.facilityAddress.display
                const shift = formatShift(position)

                return (
                  <TableRow
                    sx={{height: 65}}
                    role="checkbox"
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={position.id}
                    selected={isItemSelected}>
                    <StyledTableCell />

                    <StyledTableCell component="th" id={labelId} scope="row" padding="normal">
                      <Tooltip title={position.name}>
                        <DataContainer>{position.name}</DataContainer>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <Tooltip title={shift}>
                        <DataContainer style={{width: '14ch', maxWidth: '16ch'}}>
                          <>{shift}</>
                        </DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip title={department}>
                        <DataContainer>{department}</DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip title={facility}>
                        <DataContainer>{facility}</DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip
                        title={
                          <>
                            {position.createdBy} <br />
                            <span style={{fontWeight: 300}}>
                              {new Date(position.createdDate).toLocaleDateString('En-US') ?? '---'}
                            </span>
                          </>
                        }>
                        <DataContainer>
                          {position.createdBy ?? '---'} <br />
                          <span style={{fontWeight: 300}}>
                            {new Date(position.createdDate).toLocaleDateString('En-US') ?? '---'}
                          </span>
                        </DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip
                        title={
                          <>
                            {position.modifiedBy} <br />
                            <span style={{fontWeight: 300}}>
                              {/*  eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                              {position.modifiedDate === null
                                ? '---'
                                : new Date(position.modifiedDate).toLocaleDateString('En-US')}
                            </span>
                          </>
                        }>
                        <DataContainer>
                          {position.modifiedBy ?? '---'} <br />
                          <span style={{fontWeight: 300}}>
                            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                            {position.modifiedDate === null
                              ? '---'
                              : new Date(position.modifiedDate).toLocaleDateString('En-US')}
                          </span>
                        </DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Tooltip title={position.companyName}>
                        <DataContainer>{position.companyName}</DataContainer>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton>
                        <NavLink
                          data-testid="positions-edit"
                          to={{
                            pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.CLIENT_POSITIONS}/${position.id}`,
                          }}>
                          <EditVariantIcon />
                        </NavLink>
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                )
              })
            ) : (
              <>
                {companyFilterData?.positionModel?.map((position, index) => {
                  const isItemSelected = isSelected(position.id as string)
                  const labelId = `enhanced-table-checkbox-${index}`
                  const department = position.departmentName
                  const facility = position.facilityAddress.display
                  const shift = formatShift(position)

                  return (
                    <TableRow
                      sx={{height: 65}}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={position.id}
                      selected={isItemSelected}>
                      <StyledTableCell />

                      <StyledTableCell component="th" id={labelId} scope="row" padding="normal">
                        <Tooltip title={position.name}>
                          <DataContainer>{position.name}</DataContainer>
                        </Tooltip>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Tooltip title={shift}>
                          <DataContainer>
                            <>{shift}</>
                          </DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip title={department}>
                          <DataContainer>{department}</DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip title={facility}>
                          <DataContainer>{facility}</DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip
                          title={
                            <>
                              {position.createdBy} <br />
                              <span style={{fontWeight: 300}}>
                                {new Date(position.createdDate).toLocaleDateString('En-US') ??
                                  '---'}
                              </span>
                            </>
                          }>
                          <DataContainer>
                            {position.createdBy ?? '---'} <br />
                            <span style={{fontWeight: 300}}>
                              {new Date(position.createdDate).toLocaleDateString('En-US') ?? '---'}
                            </span>
                          </DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip
                          title={
                            <>
                              {position.modifiedBy} <br />
                              <span style={{fontWeight: 300}}>
                                {/*  eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                                {position.modifiedDate === null
                                  ? '---'
                                  : new Date(position.modifiedDate).toLocaleDateString('En-US')}
                              </span>
                            </>
                          }>
                          <DataContainer>
                            {position.modifiedBy ?? '---'} <br />
                            <span style={{fontWeight: 300}}>
                              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                              {position.modifiedDate === null
                                ? '---'
                                : new Date(position.modifiedDate).toLocaleDateString('En-US')}
                            </span>
                          </DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip title={position.companyName}>
                          <DataContainer>{position.companyName}</DataContainer>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton>
                          <NavLink
                            data-testid="positions-edit"
                            to={{
                              pathname: `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.CLIENT_POSITIONS}/${position.id}`,
                            }}>
                            <EditVariantIcon />
                          </NavLink>
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={currentPageNumber}
        totalRecords={
          selectedCompany === 'none' ? totalPositions : companyFilterData?.totalPositions
        }
        recordsPerPage={10}
        onChange={onPaginationChange}
      />
    </Box>
  )
}
