import React from 'react'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import Checkbox from '@mui/material/Checkbox'
import {styled} from '@mui/material/styles'
import CheckboxUnchecked from '@ui/icons/CheckboxUnchecked'

const CustomizedCheckbox = styled(Checkbox)`` as typeof Checkbox

export function StyledCheckbox({...props}) {
  return (
    <CustomizedCheckbox icon={<CheckboxUnchecked />} checkedIcon={<CheckboxChecked />} {...props} />
  )
}
