import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function DoubleBuildingIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='59'
			height='50'
			viewBox='0 0 59 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.6663 11.6667H14.9997C15.4599 11.6667 15.833 11.2935 15.833 10.8333V7.49999C15.833 7.03978 15.4599 6.66666 14.9997 6.66666H11.6663C11.2061 6.66666 10.833 7.03978 10.833 7.49999V10.8333C10.833 11.2935 11.2061 11.6667 11.6663 11.6667ZM12.4997 8.33332H14.1663V9.99999H12.4997V8.33332Z'
				fill='currentColor'
			/>
			<path
				d='M35.8333 25H39.1667C39.6269 25 40 24.6269 40 24.1667V20.8333C40 20.3731 39.6269 20 39.1667 20H35.8333C35.3731 20 35 20.3731 35 20.8333V24.1667C35 24.6269 35.3731 25 35.8333 25ZM36.6667 21.6667H38.3333V23.3333H36.6667V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M42.8333 25H46.1667C46.6269 25 47 24.6269 47 24.1667V20.8333C47 20.3731 46.6269 20 46.1667 20H42.8333C42.3731 20 42 20.3731 42 20.8333V24.1667C42 24.6269 42.3731 25 42.8333 25ZM43.6667 21.6667H45.3333V23.3333H43.6667V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M35.8333 32H39.1667C39.6269 32 40 31.6269 40 31.1667V27.8333C40 27.3731 39.6269 27 39.1667 27H35.8333C35.3731 27 35 27.3731 35 27.8333V31.1667C35 31.6269 35.3731 32 35.8333 32ZM36.6667 28.6667H38.3333V30.3333H36.6667V28.6667Z'
				fill='currentColor'
			/>
			<path
				d='M42.8333 32H46.1667C46.6269 32 47 31.6269 47 31.1667V27.8333C47 27.3731 46.6269 27 46.1667 27H42.8333C42.3731 27 42 27.3731 42 27.8333V31.1667C42 31.6269 42.3731 32 42.8333 32ZM43.6667 28.6667H45.3333V30.3333H43.6667V28.6667Z'
				fill='currentColor'
			/>
			<path
				d='M35.8333 38H39.1667C39.6269 38 40 37.6269 40 37.1667V33.8333C40 33.3731 39.6269 33 39.1667 33H35.8333C35.3731 33 35 33.3731 35 33.8333V37.1667C35 37.6269 35.3731 38 35.8333 38ZM36.6667 34.6667H38.3333V36.3333H36.6667V34.6667Z'
				fill='currentColor'
			/>
			<path
				d='M42.8333 38H46.1667C46.6269 38 47 37.6269 47 37.1667V33.8333C47 33.3731 46.6269 33 46.1667 33H42.8333C42.3731 33 42 33.3731 42 33.8333V37.1667C42 37.6269 42.3731 38 42.8333 38ZM43.6667 34.6667H45.3333V36.3333H43.6667V34.6667Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 11.6667H21.6667C22.1269 11.6667 22.5 11.2935 22.5 10.8333V7.49999C22.5 7.03978 22.1269 6.66666 21.6667 6.66666H18.3333C17.8731 6.66666 17.5 7.03978 17.5 7.49999V10.8333C17.5 11.2935 17.8731 11.6667 18.3333 11.6667ZM19.1667 8.33332H20.8333V9.99999H19.1667V8.33332Z'
				fill='currentColor'
			/>
			<path
				d='M25.0003 11.6667H28.3337C28.7939 11.6667 29.167 11.2935 29.167 10.8333V7.49999C29.167 7.03978 28.7939 6.66666 28.3337 6.66666H25.0003C24.5401 6.66666 24.167 7.03978 24.167 7.49999V10.8333C24.167 11.2935 24.5401 11.6667 25.0003 11.6667ZM25.8337 8.33332H27.5003V9.99999H25.8337V8.33332Z'
				fill='currentColor'
			/>
			<path
				d='M11.6663 18.3333H14.9997C15.4599 18.3333 15.833 17.9602 15.833 17.5V14.1667C15.833 13.7065 15.4599 13.3333 14.9997 13.3333H11.6663C11.2061 13.3333 10.833 13.7065 10.833 14.1667V17.5C10.833 17.9602 11.2061 18.3333 11.6663 18.3333ZM12.4997 15H14.1663V16.6667H12.4997V15Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 18.3333H21.6667C22.1269 18.3333 22.5 17.9602 22.5 17.5V14.1667C22.5 13.7065 22.1269 13.3333 21.6667 13.3333H18.3333C17.8731 13.3333 17.5 13.7065 17.5 14.1667V17.5C17.5 17.9602 17.8731 18.3333 18.3333 18.3333ZM19.1667 15H20.8333V16.6667H19.1667V15Z'
				fill='currentColor'
			/>
			<path
				d='M25.0003 18.3333H28.3337C28.7939 18.3333 29.167 17.9602 29.167 17.5V14.1667C29.167 13.7065 28.7939 13.3333 28.3337 13.3333H25.0003C24.5401 13.3333 24.167 13.7065 24.167 14.1667V17.5C24.167 17.9602 24.5401 18.3333 25.0003 18.3333ZM25.8337 15H27.5003V16.6667H25.8337V15Z'
				fill='currentColor'
			/>
			<path
				d='M11.6663 25H14.9997C15.4599 25 15.833 24.6269 15.833 24.1667V20.8333C15.833 20.3731 15.4599 20 14.9997 20H11.6663C11.2061 20 10.833 20.3731 10.833 20.8333V24.1667C10.833 24.6269 11.2061 25 11.6663 25ZM12.4997 21.6667H14.1663V23.3333H12.4997V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 25H21.6667C22.1269 25 22.5 24.6269 22.5 24.1667V20.8333C22.5 20.3731 22.1269 20 21.6667 20H18.3333C17.8731 20 17.5 20.3731 17.5 20.8333V24.1667C17.5 24.6269 17.8731 25 18.3333 25ZM19.1667 21.6667H20.8333V23.3333H19.1667V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M25.0003 25H28.3337C28.7939 25 29.167 24.6269 29.167 24.1667V20.8333C29.167 20.3731 28.7939 20 28.3337 20H25.0003C24.5401 20 24.167 20.3731 24.167 20.8333V24.1667C24.167 24.6269 24.5401 25 25.0003 25ZM25.8337 21.6667H27.5003V23.3333H25.8337V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M11.6663 31.6667H14.9997C15.4599 31.6667 15.833 31.2935 15.833 30.8333V27.5C15.833 27.0398 15.4599 26.6667 14.9997 26.6667H11.6663C11.2061 26.6667 10.833 27.0398 10.833 27.5V30.8333C10.833 31.2935 11.2061 31.6667 11.6663 31.6667ZM12.4997 28.3333H14.1663V30H12.4997V28.3333Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 31.6667H21.6667C22.1269 31.6667 22.5 31.2935 22.5 30.8333V27.5C22.5 27.0398 22.1269 26.6667 21.6667 26.6667H18.3333C17.8731 26.6667 17.5 27.0398 17.5 27.5V30.8333C17.5 31.2935 17.8731 31.6667 18.3333 31.6667ZM19.1667 28.3333H20.8333V30H19.1667V28.3333Z'
				fill='currentColor'
			/>
			<path
				d='M25.0003 31.6667H28.3337C28.7939 31.6667 29.167 31.2935 29.167 30.8333V27.5C29.167 27.0398 28.7939 26.6667 28.3337 26.6667H25.0003C24.5401 26.6667 24.167 27.0398 24.167 27.5V30.8333C24.167 31.2935 24.5401 31.6667 25.0003 31.6667ZM25.8337 28.3333H27.5003V30H25.8337V28.3333Z'
				fill='currentColor'
			/>
			<path
				d='M11.6663 38.3333H14.9997C15.4599 38.3333 15.833 37.9602 15.833 37.5V34.1667C15.833 33.7065 15.4599 33.3333 14.9997 33.3333H11.6663C11.2061 33.3333 10.833 33.7065 10.833 34.1667V37.5C10.833 37.9602 11.2061 38.3333 11.6663 38.3333ZM12.4997 35H14.1663V36.6667H12.4997V35Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 38.3333H21.6667C22.1269 38.3333 22.5 37.9602 22.5 37.5V34.1667C22.5 33.7065 22.1269 33.3333 21.6667 33.3333H18.3333C17.8731 33.3333 17.5 33.7065 17.5 34.1667V37.5C17.5 37.9602 17.8731 38.3333 18.3333 38.3333ZM19.1667 35H20.8333V36.6667H19.1667V35Z'
				fill='currentColor'
			/>
			<path
				d='M25.0003 38.3333H28.3337C28.7939 38.3333 29.167 37.9602 29.167 37.5V34.1667C29.167 33.7065 28.7939 33.3333 28.3337 33.3333H25.0003C24.5401 33.3333 24.167 33.7065 24.167 34.1667V37.5C24.167 37.9602 24.5401 38.3333 25.0003 38.3333ZM25.8337 35H27.5003V36.6667H25.8337V35Z'
				fill='currentColor'
			/>
			<path
				d='M32.5 48.3333V5H33.3333C33.7935 5 34.1667 4.62687 34.1667 4.16667V0.833333C34.1667 0.373125 33.7935 0 33.3333 0H6.66667C6.20646 0 5.83333 0.373125 5.83333 0.833333V4.16667C5.83333 4.62687 6.20646 5 6.66667 5H7.5V48.3333H0V50H40V48.3333H32.5ZM7.5 1.66667H32.5V3.33333H7.5V1.66667ZM19.1667 48.3333H15.8333V41.6667H19.1667V48.3333ZM24.1667 48.3333H20.8333V41.6667H24.1667V48.3333ZM30.8333 48.3333H25.8333V40.8333C25.8333 40.3731 25.4602 40 25 40H15C14.5398 40 14.1667 40.3731 14.1667 40.8333V48.3333H9.16667V5H30.8333V48.3333Z'
				fill='currentColor'
			/>
			<rect
				x='31.75'
				y='12.75'
				width='19.5'
				height='29.5'
				stroke='currentColor'
				strokeWidth='1.5'
			/>
			<rect x='31.75' y='41.75' width='19.5' height='7.5' stroke='currentColor' strokeWidth='1.5' />
			<mask id='path-25-inside-1' fill='white'>
				<rect width='23' height='5' rx='1' transform='matrix(1 0 0 -1 31 13)' />
			</mask>
			<rect
				width='23'
				height='5'
				rx='1'
				transform='matrix(1 0 0 -1 31 13)'
				stroke='currentColor'
				strokeWidth='3'
				mask='url(#path-25-inside-1)'
			/>
			<line y1='49' x2='59' y2='49' stroke='currentColor' strokeWidth='2' />
		</Svg>
	);
}
