import {Select, MenuItem, SelectChangeEvent} from '@mui/material'
import React from 'react'
import {SkipCandidateReason} from '~/src/models/jobOrder'

interface SkipReasonsProps {
  notes: string
  varient: 'skip' | 'skipCleanup'
  onSelectNotes: (event: SelectChangeEvent<string>) => void
}

const SkipReasons = ({notes, onSelectNotes, varient}: SkipReasonsProps) => {
  const {NO_ANSWER, REQUESTED_CALLBACK, PROFILE_OUTDATED} = SkipCandidateReason

  return (
    <Select value={notes} onChange={onSelectNotes} displayEmpty sx={{textAlign: 'left'}}>
      <MenuItem value="">
        <em>Select a Reason</em>
      </MenuItem>
      <MenuItem value={NO_ANSWER}>{NO_ANSWER}</MenuItem>
      <MenuItem value={REQUESTED_CALLBACK}>{REQUESTED_CALLBACK}</MenuItem>
      {varient === 'skip' && <MenuItem value={PROFILE_OUTDATED}>{PROFILE_OUTDATED}</MenuItem>}
    </Select>
  )
}

export default SkipReasons
