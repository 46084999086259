import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import Mui from '@ui/mui'
import {push} from 'connected-react-router'
import {Form as FormikForm, Formik} from 'formik'
import {nanoid} from 'nanoid/non-secure'
import React, {useState} from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import type {ITemplateOrder} from '~/src/models/order'
import type {IServiceOrderTemplate, TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {
  useCreateServiceOrderTemplateMutation,
  useUpdateServiceOrderTemplateMutation,
} from '~/src/store/apis/service-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import AddTemplateOrder from './AddTemplateOrder'
import PrependedTemplateAddHeader from './PrependedTemplateAddHeader'

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

export const createTemplateItem = () => ({
  id: nanoid(),
  orderType: '',
  positionId: '',
  payRate: '',
  note: '',
})

interface IProps {
  initialValues: TServiceOrderTemplatePayload
  index: number
  data: TServiceOrderTemplatePayload
  isEdit?: boolean
  companyId: string | number | boolean | object | null
}

interface IFormSubmitValues {
  name: ''
  orders: ITemplateOrder[]
  companyId: ''
}

export default function TemplateForm({
  onOpenOrderModal,
  initialValues,
  index,
  data,
  isEdit,
  companyId,
}: IProps): React.ReactElement {
  const dispatch = useAppDispatch()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [filterCompanyName, setFilterCompanyName] = useState<String>(initialValues.companyName)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [addTemplate, {error: addTemplateError}] = useCreateServiceOrderTemplateMutation()
  const [updateTemplate, {error: updateTemplateError}] = useUpdateServiceOrderTemplateMutation()
  const initialFormValues = {
    ...initialValues,
  }

  const [selectedCompany, setSelectedCompany] = React.useState(
    initialValues?.id ? initialValues.companyName : 'Select Company',
  )
  const [positionsFiltered, setPositionsFiltered] = React.useState<
    IServiceOrderTemplate[] | undefined
  >()

  const handleCompanyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyIds = event.target.value
    setSelectedCompany(companyIds)
    const positionsFilteredByCompany = data?.serviceOrderTemplatesModel.filter(
      (position) => position.companyId === companyIds,
    )

    if (positionsFilteredByCompany?.length) {
      setPositionsFiltered(positionsFilteredByCompany)
    } else {
      setPositionsFiltered(undefined)
    }
  }
  const handleSubmit = async (values: IFormSubmitValues) => {
    if (isEdit) {
      void updateTemplate({template: values, agencyId: loggedInAgencyId}).unwrap()
      dispatch(
        push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`, {
          state: {openSnackbar: true, name: values.name, type: 'agency', isEdit: true},
        }),
      )
    } else {
      void addTemplate({template: values, agencyId: loggedInAgencyId}).unwrap()
      dispatch(
        push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`, {
          state: {openSnackbar: true, name: values.name, type: 'agency', isEdit: false},
        }),
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          border: '1px solid #CDCED9',
          borderRadius: '4px',
        }}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            companyId: Yup.string().required('Required'),
            orders: Yup.array().of(
              Yup.object({
                orderType: Yup.string().required('Required'),
                positionId: Yup.string().required('Required'),
                payRate: Yup.string().required('Required'),
                note: Yup.string(),
              }),
            ),
          })}>
          {({isSubmitting, setFieldValue, values, handleChange, errors}) => {
            return (
              <FormikForm>
                <PrependedTemplateAddHeader
                  data={data}
                  companyId={companyId}
                  values={values}
                  isEdit={isEdit}
                  initialValues={initialValues}
                  setFieldValue={setFieldValue}
                  onCompanySelection={handleCompanyFilter}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                />
                {values.orders && values.orders.length > 0 && (
                  <AddTemplateOrder
                    index={index}
                    initialValues={initialFormValues}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    onOpenOrderModal={onOpenOrderModal}
                    currentPageNumber={currPageIdx}
                    modalOpen={false}
                  />
                )}

                <Grid sx={{m: 5}} item xs mt={6} display="flex">
                  <Grid item xs>
                    <>
                      <Button
                        data-testid="service-order-cancel"
                        variant="outlined"
                        color="secondary"
                        type="reset"
                        onClick={() => {
                          dispatch(
                            push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`),
                          )
                        }}
                        disabled={isSubmitting}
                        sx={{mr: 'auto'}}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{ml: 2}}>
                        Save
                      </Button>
                    </>
                  </Grid>
                  <Grid item xs />
                </Grid>
              </FormikForm>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}
