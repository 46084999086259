import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import type {IPosition} from '~/src/models/position'
import {useFindCompaniesAssignedToMspQuery} from '~/src/store/apis/company-api'
import {useGetPositionQuery, useGetPositionByCompanyIdQuery} from '~/src/store/apis/position-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import AddPositionSelect from './AddPositionSelect'
import ClientPositionsTable from './ClientPositionsTable'

const Container = styled.div`
  position: relative;
  flex: 1;
`

export default function ManageClientPositions(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [targetPosition, setTargetPosition] = useState<IPosition>()
  const [companies, setCompanies] = React.useState()
  const [selectedCompany, setSelectedCompany] = React.useState('none')
  const [positionsFiltered, setPositionsFiltered] = React.useState<IPosition[] | undefined>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data: companyIds} = useFindCompaniesAssignedToMspQuery(loggedInAgencyId)

  const {data, refetch, error, isLoading, isFetching} = useGetPositionQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const {data: companyFilterData, refetch: companyFilterRefetch} = useGetPositionByCompanyIdQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    companyId: selectedCompany === 'none' ? '' : selectedCompany,
    agencyId: loggedInAgencyId,
  })

  const path = [...location.pathname.split('/')]

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  const getCompanies = () => {
    const result = data?.positionModel.map((position) => {
      return {companyName: position.companyName, id: position.companyId}
    })
    return result?.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id),
    )
  }

  const handleCompanyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyId = event.target.value

    if (companyId === 'none') {
      refetch()
      setSelectedCompany('none')
      setCurrPageIdx(1)
    } else {
      setSelectedCompany(companyId)
      setCurrPageIdx(1)
    }
  }

  useEffect(() => {
    refetch()
  }, [currPageIdx, refetch])

  React.useEffect(() => {
    setCompanies(getCompanies())
  }, [data])

  const positions = positionsFiltered ? positionsFiltered : data?.positionModel

  return (
    <Container>
      <Title pageTitle="Client Positions" button={<AddPositionSelect companyIds={companyIds} />} />
      <ErrorBoundary>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          {positions && companies && (
            <ClientPositionsTable
              companyFilterData={companyFilterData}
              positions={positions}
              companies={companies}
              selectedCompany={selectedCompany}
              totalPositions={data.totalPositions}
              currentPageNumber={currPageIdx}
              setTargetPosition={setTargetPosition}
              onPaginationChange={handlePaginationChange}
              onCompanyFilter={handleCompanyFilter}
            />
          )}
        </LoadingBoundary>
      </ErrorBoundary>
    </Container>
  )
}
