import {nanoid} from 'nanoid/non-secure'
import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {
  useFindCompaniesAssignedToMspQuery,
  useGetCompanyNameByIdQuery,
} from '~/src/store/apis/company-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetServiceOrderTemplateByIdQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import TemplateForm from './TemplateForm'

export const createTemplateItem = () => ({
  id: nanoid(),
  orderType: '',
  positionId: '',
  payRate: '',
  note: '',
})
interface UrlParams {
  templateId: string
  positionId: string
}

export default function EditTemplate(): React.ReactElement {
  const templateId: UrlParams = useParams()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: companyId} = useFindCompaniesAssignedToMspQuery(loggedInAgencyId)

  const {data: templateData, isSuccess} = useGetServiceOrderTemplateByIdQuery({
    id: templateId.templateId,
    agencyId: loggedInAgencyId,
  })
  const {data: positionData} = useGetPositionByIdQuery({id: templateId.positionId})
  const {data: companyName} = useGetCompanyNameByIdQuery({id: templateData?.companyId}, {skip: !isSuccess})

  const templateOrders =
    templateData && positionData
      ? templateData.orders.map((order) => {
          return {
            orderType: order.orderType,
            note: order.note,
            payRate: order.payRate,

            positionId: order.positionId,
            positionStartTime: positionData.startTime,
            positionEndTime: positionData.endTime,
            positionJobDescription: positionData.jobDescription,
            positionManager: positionData.manager,
            positionName: positionData.name,
            department: positionData.departmentName,
            facility: positionData.facilityName,
            facilityAddress: positionData.facilityAddress,
            positionCompCode: positionData.compensationCode,
          }
        })
      : []
  const initialValues: any =
    templateData && positionData && companyName
      ? {
          name: templateData.name,
          companyName: companyName,
          companyId: templateData.companyId,
          orders: templateOrders,
          id: templateData.id,
          versionKey: templateData.versionKey,
        }
      : {}

  return (
    <>
      <Title pageTitle="Edit Template" />
      {templateData && positionData ? (
        <TemplateForm
          initialValues={initialValues}
          companyId={companyId}
          data={initialValues}
          isEdit={true}
          index={0}
        />
      ) : (
        <div />
      )}
    </>
  )
}
