import styled from 'styled-components';
import {colors} from '~/src/config/theme';
import {sizes} from '~/src/utils';

const Card = {
	Container: styled.div`
		background: ${colors.white};
		border-radius: 8px;
		box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
		display: flex;
		flex-direction: column;
		width: ${sizes.maxWidth};
	`,
	FullWidth: styled.div`
		width: ${sizes.maxWidth};
	`,
	Header: styled.div`
		align-items: center;
		background-color: rgba(232, 232, 232, 0.2);
		border-bottom: 1px solid #dbdde0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		display: flex;
		font-weight: bold;
		height: 56px;
		letter-spacing: 0.10000000149011612px;
		line-height: 20px;
		padding: 0 24px;
	`,
	Body: styled.div`
		flex: 1;
		padding: 45px;
	`,
};

export default Card;
