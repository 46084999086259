import React, {useState} from 'react'
import Box from '@mui/material/Box'
import {useGetCompaniesQuery} from '~/src/store/apis/company-api'

interface IProps {
  isAgency: boolean
}

export default function PrependedCompanyHeader({isAgency}: IProps) {
  const [currPageIdx, setCurrPageIdx] = useState(1)

  const {data, isLoading, isFetching, error, refetch} = useGetCompaniesQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
  })
  return (
    <Box
      sx={{
        display: 'flex',
        height: 65,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 16px',
        borderBottom: '1px solid #CDCED9',
      }}>
      {isAgency ? (
        <div
          style={{
            fontSize: '15px',
            fontWeight: 500,
            padding: '10px 20px 10px 16px',
          }}>
          All Agencies
        </div>
      ) : (
        <div
          style={{
            fontSize: '15px',
            fontWeight: 500,
            padding: '10px 20px 10px 16px',
          }}>
          All Companies
        </div>
      )}
    </Box>
  )
}
