import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import {InputAdornment, MenuItem} from '@mui/material'
import {FormikGooglePlacesAutocomplete} from '@ui/components'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {FieldArray} from 'formik'
import React from 'react'
import {useDispatch} from 'react-redux'
import type {Education} from '~/src/models/applicant'
import {DegreeType} from '~/src/models/CandidateForm'
import {
  AddExperienceCardContainer,
  AddExperienceContainer,
  AddExperienceText,
  CloseIconContainer,
  DashboardMainStylesMaxWidth,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceBetweenContainer,
  FlexSpaceEvenlyContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  Red,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import {createNewEducationItem} from '../../../msp-job-order-information/JobOrderSummaryProfile'
import MuiIcon from '@mui/material/Icon'
import Icon from '@ui/icons'
interface IProps {
  handleCancel(): void
  handleClick(value: string): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Education[]
}

export default function MspCandidateProfileEducationEdit({
  handleCancel,
  handleClick,
  setFieldValue,
  values,
}: IProps) {
  const dispatch = useDispatch()

  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Education</InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                <InformationCardHeaderTextEdit style={{marginRight: 10}} onClick={handleCancel}>
                  Cancel
                </InformationCardHeaderTextEdit>
                <InformationCardHeaderTextEdit onClick={() => handleClick('saveChanges')}>
                  Save
                </InformationCardHeaderTextEdit>
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>

          <FieldArray name="education">
            {({push, remove}) => (
              <React.Fragment>
                {values.map((newEducation: Education, index: number) => {
                  return (
                    <>
                      <FlexContainer
                        style={{
                          width: '757px',
                          height: '206px',
                          borderRadius: '6px',
                          background: '#FFF',
                          boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
                        }}>
                        <InformationCardDetailsContainer>
                          <InformationCardProfileExperienceJobText>
                            <FlexContainer>
                              <FlexColumnContainer>
                                <span>
                                  Name of School <Red>*</Red>
                                </span>
                                <FormikTextField
                                  aria-label="education-nameOfSchool"
                                  name={`education.${index}.nameOfSchool`}
                                  data-testid="education-nameOfSchool"
                                  type="text"
                                  label=""
                                  value={newEducation?.nameOfSchool}
                                  sx={{
                                    maxWidth: '210px',
                                    minWidth: '210px',
                                    height: '28px',
                                    maxHeight: '28px',
                                    marginRight: '19px',
                                  }}
                                />
                              </FlexColumnContainer>
                              <FlexColumnContainer>
                                <span>
                                  Degree Type <Red>*</Red>
                                </span>
                                <FormikTextField
                                  aria-label="education-degreeType"
                                  name={`education.${index}.degreeType`}
                                  data-testid="educationDegreeType"
                                  value={newEducation.degreeType}
                                  select
                                  sx={{
                                    maxWidth: '210px',
                                    minWidth: '210px',
                                    height: '28px',
                                    maxHeight: '28px',
                                  }}>
                                  {DegreeType.map((degreeInfo, degreeInfoIndex) => {
                                    return (
                                      <MenuItem
                                        key={degreeInfoIndex}
                                        aria-label={`candidate-graduationType-${degreeInfo}-Value`}
                                        data-testid={`candidate-graduationType-${degreeInfo}-Value`}
                                        value={degreeInfo}>
                                        {`${degreeInfo}`}
                                      </MenuItem>
                                    )
                                  })}
                                </FormikTextField>
                              </FlexColumnContainer>
                              <FlexColumnContainer style={{width: 210}} />
                            </FlexContainer>
                          </InformationCardProfileExperienceJobText>
                          <InformationCardProfileExperienceJobText style={{marginTop: '30px'}}>
                            <FlexContainer>
                              <FlexColumnContainer style={{marginRight: 20}}>
                                <span>
                                  Graduation Date <Red>*</Red>
                                </span>
                                <div style={{width: '210px'}}>
                                  <FormikDatePicker
                                    aria-label="education-graduationDate"
                                    name={`education.${index}.graduationDate`}
                                    data-testid={`education-${index}-graduationDate`}
                                    initialValue={newEducation?.graduationDate}
                                    sx={{
                                      width: '100%',
                                    }}
                                    onClose={(va: Date) =>
                                      setFieldValue(newEducation.graduationDate, va)
                                    }
                                  />
                                </div>
                              </FlexColumnContainer>
                              <FlexColumnContainer>
                                <span>
                                  City, State <Red>*</Red>
                                </span>
                                <div style={{width: 210}}>
                                  <FormikGooglePlacesAutocomplete
                                    isCityStateBoolean
                                    name={`education.${index}.address`}
                                    setFieldValue={setFieldValue}
                                    inputProps={{maxLength: 210}}
                                  />
                                </div>
                              </FlexColumnContainer>
                            </FlexContainer>
                          </InformationCardProfileExperienceJobText>
                        </InformationCardDetailsContainer>
                        <CloseIconContainer
                          aria-label="education-remove-education-button"
                          data-testid="education-remove-education-button"
                          style={{height: '206px'}}
                          onClick={() => remove(index)}>
                          <CloseIcon />
                        </CloseIconContainer>
                      </FlexContainer>
                    </>
                  )
                })}
                <AddExperienceCardContainer>
                  <AddExperienceContainer
                    aria-label="education-add-new-education-button"
                    data-testid="education-add-new-education-button"
                    onClick={() => push(createNewEducationItem())}>
                    <FlexSpaceEvenlyContainer>
                      <AddExperienceText>
                        <AddCircleIcon sx={{maxHeight: '20px', maxWidth: '20px'}} />
                      </AddExperienceText>
                      <AddExperienceText>Add New Education</AddExperienceText>
                    </FlexSpaceEvenlyContainer>
                  </AddExperienceContainer>
                </AddExperienceCardContainer>
              </React.Fragment>
            )}
          </FieldArray>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
