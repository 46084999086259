import {createSlice} from '@reduxjs/toolkit'
import type {TRootState} from '..'
import {prefillApi} from './../apis/prefill-api'
import type { IPrefill } from '~/src/models/prefill'

interface IPrefillState {
  prefills: IPrefill[] | null
  currentPrefillPageNumber: number
  prefillId: string
  prefillToEdit: IPrefill | undefined
}

const initialState: IPrefillState = {
  prefills: null,
  currentPrefillPageNumber: 1,
  prefillId: '',
  prefillToEdit: undefined,
}

const prefillSlice = createSlice({
  name: 'prefillSlice',
  initialState,
  reducers: {
    initializeInput: (state, {payload}) => {
      state.prefills = payload
    },
    updatePageNumber: (state, {payload}) => {
      state.currentPrefillPageNumber = payload
    },
    updatePrefillId: (state, {payload}) => {
      state.prefillId = payload
    },
    setPrefillToEdit: (state, {payload}) => {
      state.prefillToEdit = payload
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(prefillApi.endpoints.getPrefills.matchFulfilled, (state, {payload}) => {
      state.prefills = payload
    })
  },
})

export const {initializeInput, updatePageNumber, updatePrefillId, setPrefillToEdit} = prefillSlice.actions

export const selectPrefillState = (state: TRootState): TRootState['prefill'] => state.prefill

export default prefillSlice.reducer
