import Dialog from '@mui/material/Dialog'
import type {Theme} from '@mui/material/styles'
import {ErrorBox, FormikSelect, FormikTextField, Icon, MenuItem} from '@ui/components'
import {Form, Formik} from 'formik'
import React, {useCallback, useMemo} from 'react'
import * as Yup from 'yup'
import {cognitoToDisplay, displayToCognito, IUserBase, userStatusesReadonly} from '~/src/models'
import {useLazyGetUserByEmailQuery, useUpdateMemberMutation} from '~/src/store/apis/user-api'
import {
  AbsoluteAdornment,
  Adornment,
  Blue,
  Body,
  Button,
  Footer,
  Header,
  IconButton,
  Title,
  useStyles,
} from '~/src/components/ui/dialog/styles/MemberDialog.styles'

interface IProps {
  onModalClose(): void
  targetUser?: IUserBase
  modalOpen: boolean
}

export default function EditMemberDialog({
  modalOpen,
  onModalClose,
  targetUser,
}: IProps): React.ReactElement {
  const classes = useStyles()
  const [editMember] = useUpdateMemberMutation()
  const [error, setError] = React.useState<string>()

  const [findEmail] = useLazyGetUserByEmailQuery()

  const validateEmail = useCallback(
    async function (
      value: string | undefined,
      resolve: (value: boolean) => void,
      targetUser?: IUserBase,
    ) {
      if (targetUser?.emailAddress === value) {
        resolve(true)
      } else {
        try {
          const alreadyExists = await findEmail(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  function handleModalClose() {
    onModalClose()
    setError(undefined)
  }

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={handleModalClose}
      PaperProps={{sx: {minWidth: (theme: Theme) => theme.breakpoints.values.sm, maxWidth: 546}}}>
      <Formik
        initialValues={{
          firstName: targetUser?.firstName ?? '',
          lastName: targetUser?.lastName ?? '',
          emailAddress: targetUser?.emailAddress ?? '',
          status: targetUser?.status ?? ('' as IUserBase['status']),
          role: targetUser?.roles ? cognitoToDisplay(targetUser.roles[0]) : '',
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('Required')
            .max(50, 'First Name must be less than 50 characters'),
          lastName: Yup.string()
            .required('Required')
            .max(50, 'Last Name must be less than 50 characters'),
          emailAddress: Yup.string()
            .email('Email address is invalid.')
            .test(
              'already-exists',
              'This email is already in use',
              (value) =>
                new Promise((resolve) => debouncedValidateEmail(value, resolve, targetUser)),
            )
            .required('Required'),
          status: Yup.string().required('Required'),
          role: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          if (targetUser) {
            const member = {
              id: targetUser.id,
              ...values,
              roles: [displayToCognito(values.role)],
            }
            try {
              await editMember(member).unwrap()
              handleModalClose()
            } catch (e: unknown) {
              setError(JSON.stringify(e))
            }
          } else {
            setError('No target user specified.')
          }
        }}>
        {({isSubmitting}) => (
          <Form>
            <Header>
              <Title>
                <Blue>Edit</Blue> User
              </Title>
              <IconButton onClick={handleModalClose}>
                <Icon icon="close" />
              </IconButton>
            </Header>

            <Body>
              <div>
                <b>Edit</b> user information:
              </div>
              <FormikTextField
                name="firstName"
                placeholder="First Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="lastName"
                placeholder="Last Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="emailAddress"
                placeholder="danyl.nava@example.com"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="email" />}
              />

              <FormikSelect
                name="role"
                placeholder="Role"
                inputProps={{className: classes.selectTextField}}
                startadornment={<AbsoluteAdornment icon="pen" />}>
                {['Company Administrator'].map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </FormikSelect>

              <FormikSelect
                name="status"
                placeholder="Status"
                inputProps={{className: classes.selectTextField}}
                startadornment={<AbsoluteAdornment icon="loader" />}>
                {userStatusesReadonly.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </FormikSelect>

              <ErrorBox>{error}</ErrorBox>
            </Body>

            <Footer>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={handleModalClose}>
                Cancel
              </Button>

              <Button variant="outlined" color="secondary" type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
