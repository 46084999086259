import {Avatar} from '@mui/material'
import LoadingBoundary from '@ui/loader'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'

export const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
    flex-basis: 25%;
  `,
}

export const CompanyDetailsCardContainer = styled('div')({
  marginLeft: '21px',
  marginTop: '13px',
})

export const CompanyDetailsText = styled('div')({
  fontSize: '18px',
  fontWeight: '700',
  textAlign: 'left',
  marginBottom: '16px',
})

export const JobOrderDetailsText = styled('div')({
  fontSize: '18px',
  fontWeight: '700',
  textAlign: 'left',
  marginBottom: '16px',
  marginTop: '25px',
})

export const HeaderText = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: 2,
  color: '#8A8A8A',
})

export const DescriptionText = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
})

export const FlexContainer = styled('div')({
  display: 'flex',
  marginBottom: '16px',
})

export const FlexColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

interface IProps {
  endTime: string | undefined
  location: string | undefined
  manager: string | undefined
  position: string | undefined
  positionName: string | undefined
  startTime: string | undefined
}

export default function MspCompanyDetailsSummaryCard({
  endTime,
  location,
  manager,
  position,
  positionName,
  startTime,
}: IProps) {
  const {
    data: PositionData,
    isLoading,
    refetch: PositionRefetch,
  } = useGetPositionByIdQuery({
    id: position,
  })

  const {data: CompanyData, refetch: CompanyRefetch} = useGetCompanyNameByIdQuery({
    id: PositionData?.companyId,
  })

  useEffect(() => {
    PositionRefetch()
    CompanyRefetch()
    if (CompanyData) {
      localStorage.setItem('companyName', CompanyData)
    }
  }, [PositionRefetch, CompanyRefetch])
  return (
    <>
      <LoadingBoundary isLoading={isLoading}>
        <DashboardMainStyles.Container>
          <CompanyDetailsCardContainer>
            <CompanyDetailsText>Company Details</CompanyDetailsText>
            <FlexContainer>
              <Avatar sx={{maxWidth: '33px', maxHeight: '33px', marginRight: 2}}>
                {CompanyData?.[0]}
              </Avatar>
              <FlexColumnContainer>
                <HeaderText>Company</HeaderText>
                <DescriptionText>{CompanyData}</DescriptionText>
              </FlexColumnContainer>
            </FlexContainer>

            <FlexContainer>
              <FlexColumnContainer>
                <HeaderText>Work Location</HeaderText>
                <DescriptionText>{location}</DescriptionText>
              </FlexColumnContainer>
            </FlexContainer>
            <FlexColumnContainer>
              <JobOrderDetailsText>Job Order Details</JobOrderDetailsText>
              <FlexColumnContainer>
                <HeaderText>Positions, Shift & Manager</HeaderText>
                <DescriptionText>
                  {positionName}, Shift 1, {startTime} - {endTime} - {manager}
                </DescriptionText>
              </FlexColumnContainer>
            </FlexColumnContainer>
          </CompanyDetailsCardContainer>
        </DashboardMainStyles.Container>
      </LoadingBoundary>
    </>
  )
}
