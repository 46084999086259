import {Button, Divider} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {formatShift} from '@utils'
import {format} from 'date-fns'
import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import type {AgencyDistributions} from '~/src/models/order'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetSingleServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const Container = styled('div')({
  position: 'relative',
  flex: 1,
})

const Labels = styled('div')({
  fontWeight: 600,
  fontSize: '18px',
})

const Information = styled('div')({
  fontWeight: 500,
  fontSize: '18px',
  marginLeft: 10,
})
const RowContainer = styled('div')({
  display: 'flex',
  marginBottom: 10,
})
const Header = styled('div')({
  display: 'flex',
  fontWeight: 700,
  fontSize: '20px',
  justifyContent: 'space-between',
})

const HeaderText = styled('div')({
  marginTop: 10,
})

const StyledBox = styled(Box)({
  marginTop: 5,
  marginLeft: 5,
  marginBottom: 10,
  fontWeight: 700,
  fontSize: '20px',
})

interface IParams {
  type: string
  orderId: string
}
function PositionRow(props: string | number | boolean | object | any): JSX.Element {
  const {data: positionData} = useGetPositionByIdQuery({
    pageNumber: 1,
    pageSize: 1000,
    id: props?.row.positionId,
  })

  const projectOrderTypeSwitch = () => {
    switch (props.row.orderType) {
      case 'Temp':
        return 'Temp'
      case 'TempToHire':
        return 'Temp To Hire'
      case 'DirectPlacement':
        return 'Direct Placement'
      default:
        return 'Project'
    }
  }
  return (
    <>
      {positionData && (
        <Box sx={{marginBottom: 5, marginLeft: 5, marginRight: 5}} key={props.index}>
          <RowContainer>
            <Labels>Position: </Labels>
            <Information>{positionData.name}</Information>
          </RowContainer>
          <RowContainer>
            <Labels>Order Type: </Labels>
            <Information>{projectOrderTypeSwitch()}</Information>
          </RowContainer>
          <RowContainer>
            <Labels>Facility: </Labels>
            <Information>{positionData.facilityName}</Information>
          </RowContainer>
          <RowContainer>
            <Labels>Shift: </Labels>
            <Information>{formatShift(positionData)}</Information>
          </RowContainer>
          <RowContainer>
            <Labels>Department: </Labels>
            <Information>{positionData.departmentName}</Information>
          </RowContainer>
          <RowContainer>
            <Labels>Pay Rate: </Labels>
            <Information>{props.row.payRate}</Information>{' '}
          </RowContainer>
          <RowContainer>
            <Labels>Reporting Manager: </Labels>
            <Information>{positionData.manager}</Information>{' '}
          </RowContainer>
          <RowContainer>
            <Labels>Workers Compensation Code: </Labels>
            <Information>{positionData.compensationCode}</Information>{' '}
          </RowContainer>
          <RowContainer>
            <Labels>Notes: </Labels>
            <Information>{props.row.note}</Information>{' '}
          </RowContainer>
          <RowContainer>
            {props.type === 'agency' ? (
              <div />
            ) : (
              <>
                <Labels>Total Quantity: </Labels>
                <Information>{props.row.quantity}</Information>{' '}
              </>
            )}
          </RowContainer>
        </Box>
      )}
    </>
  )
}

export default function ServiceOrderSummary(): JSX.Element {
  const history = useHistory()
  const {type, orderId}: IParams = useParams()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {
    data: serviceOrderData,
    isLoading: serviceOrderIsLoading,
    error: serviceOrderError,
    isSuccess: serviceOrderSuccess,
  } = useGetSingleServiceOrderQuery({
    id: orderId,
  })
  const positionId = serviceOrderData?.orders[0] ? serviceOrderData.orders[0].positionId : ''
  const {data: onePosition} = useGetPositionByIdQuery(
    {
      id: positionId,
    },
    {skip: !serviceOrderSuccess},
  )

  const needed = serviceOrderData?.orders
    .map((order) => Number(order.quantity))
    .reduce((prev, curr) => prev + curr, 0)

  const totalRequestFromOrder = serviceOrderData?.orders[0]
    ? serviceOrderData.orders[0].orderDistribution.orderQuantityDistributions
        .map((distro) => distro.quantity)
        .reduce((prev, curr) => prev + curr, 0)
    : 0

  const totalPledgedFromOrder = serviceOrderData?.orders[0]?.orderDistribution.agencyDistributions
    ? serviceOrderData.orders[0].orderDistribution.agencyDistributions
        .flat()
        .map((agencyQuantityDistribution) => agencyQuantityDistribution.agencyQuantityDistributions)
        .flat()
        .map((distribution) => {
          if (distribution?.pledged) {
            return distribution.pledged
          } else {
            return 0
          }
        })
        .reduce((prev, curr) => prev + curr, 0)
    : 0

  // for non msp agency
  const findAgency: AgencyDistributions | undefined = serviceOrderData?.orders[0]?.orderDistribution
    .agencyDistributions
    ? serviceOrderData.orders[0].orderDistribution.agencyDistributions.find((item) => {
        return item.agencyId === loggedInAgencyId
      })
    : undefined

  let agencyNeeded = 0

  findAgency?.agencyQuantityDistributions?.forEach((l) => {
    agencyNeeded += l.requested
  })

  let totalAgencyPledged = 0
  findAgency?.agencyQuantityDistributions?.forEach((t) => {
    if (t?.pledged) {
      return (totalAgencyPledged += t.pledged)
    } else {
      return 0
    }
  })

  return (
    <Container>
      <ErrorBoundary error={serviceOrderError}>
        <LoadingBoundary isLoading={serviceOrderIsLoading}>
          <>
            <Header>
              {type === 'company' ? (
                <HeaderText>For Company</HeaderText>
              ) : type === 'mspagency' ? (
                <HeaderText>For MSP</HeaderText>
              ) : (
                <HeaderText>For Agency</HeaderText>
              )}
              <Button
                onClick={history.goBack}
                variant="contained"
                color="primary"
                sx={{marginBottom: 2}}>
                Back
              </Button>
            </Header>
          </>
          <Box sx={{border: '1px solid black'}}>
            <Box
              sx={{
                marginTop: 5,
                marginLeft: 5,
                marginBottom: 5,
                fontWeight: 700,
                fontSize: '20px',
              }}>
              Service Order Details
            </Box>
            <Box sx={{marginLeft: 5}}>
              <RowContainer>
                <Labels>Service Order:</Labels>{' '}
                <Information>#{serviceOrderData?.orderNumber}</Information>
              </RowContainer>
              <RowContainer>
                <Labels>Company Name:</Labels> <Information>{onePosition?.companyName}</Information>
              </RowContainer>
              <RowContainer>
                {' '}
                <Labels>Total Needed:</Labels>
                <Information>{type === 'agency' ? agencyNeeded : needed}</Information>
              </RowContainer>
              <RowContainer>
                {' '}
                <Labels>Total Requested:</Labels>
                <Information>{type === 'agency' ? '-' : totalRequestFromOrder}</Information>
              </RowContainer>
              <RowContainer>
                {' '}
                <Labels>Total Pledged:</Labels>
                {serviceOrderData?.orders[0] ? (
                  <Information>
                    {type === 'company' &&
                    totalPledgedFromOrder > Number(serviceOrderData.orders[0].quantity)
                      ? serviceOrderData.orders[0].quantity
                      : type === 'agency'
                      ? totalAgencyPledged
                      : totalPledgedFromOrder}
                  </Information>
                ) : (
                  <div />
                )}
              </RowContainer>
              <RowContainer>
                {' '}
                <Labels>Service Order Status: </Labels>
                <Information>{serviceOrderData?.mspStatus}</Information>
              </RowContainer>
            </Box>
            <Divider />
            <Box
              sx={{
                marginTop: 5,
                marginLeft: 5,
                marginBottom: 5,
                fontWeight: 700,
                fontSize: '20px',
              }}>
              Position Details
            </Box>
            {serviceOrderData?.orders.map((row, index) => {
              return <PositionRow key={index} row={row} type={type} />
            })}

            <Divider />
            {type === 'mspagency' && serviceOrderData?.orders[0] ? (
              <Box sx={{marginLeft: 5, marginTop: 5}}>
                <RowContainer>
                  <Labels>Distribution Start Date:</Labels>{' '}
                  <Information>
                    {format(
                      new Date(serviceOrderData.orders[0].distributionStartDate),
                      'MM-dd-yyyy',
                    )}
                  </Information>
                </RowContainer>
                <RowContainer>
                  <Labels>Distribution End Date:</Labels>{' '}
                  <Information>
                    {format(new Date(serviceOrderData.orders[0].distributionEndDate), 'MM-dd-yyyy')}
                  </Information>
                </RowContainer>
              </Box>
            ) : type === 'agency' && serviceOrderData?.orders[0] ? (
              <Box sx={{marginLeft: 5, marginTop: 5}}>
                <RowContainer>
                  <Labels>Distribution Start Date:</Labels>{' '}
                  <Information>
                    {format(
                      new Date(serviceOrderData.orders[0].distributionStartDate),
                      'MM-dd-yyyy',
                    )}
                  </Information>
                </RowContainer>
                <RowContainer>
                  <Labels>Distribution End Date:</Labels>{' '}
                  <Information>
                    {format(new Date(serviceOrderData.orders[0].distributionEndDate), 'MM-dd-yyyy')}
                  </Information>
                </RowContainer>
              </Box>
            ) : (
              <div />
            )}
          </Box>
        </LoadingBoundary>
      </ErrorBoundary>
    </Container>
  )
}
