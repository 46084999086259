import Box from '@mui/material/Box'
import JobOrderCard from '@ui/card/JobOrderCard'
import JobOrderDistributeOptionalCard from '@ui/card/JobOrderDistributeOptionalCard'
import ServiceOrderCardMsp from '@ui/card/ServiceOrderCardMsp'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {push} from 'connected-react-router'
import {format} from 'date-fns'
import {nanoid} from 'nanoid/non-secure'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import Title from '~/src/components/layout/Title'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetJobOrdersByServiceOrderIdsQuery} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const CompanyMainStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
}

interface ICardComboProps {
  serviceOrder: IServiceOrder
  agencyId: string
  currentServiceOrderPageNumber: number
  shift?: string
  sx?: object
}

function CardCombo({
  serviceOrder,
  agencyId,
  currentServiceOrderPageNumber,
  shift,
  sx,
}: ICardComboProps) {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const dispatch = useDispatch()

  const {
    data: jobOrderBatch,
    refetch,
    error,
    isLoading,
    isFetching,
  } = useGetJobOrdersByServiceOrderIdsQuery({
    serviceOrderIds: [serviceOrder.id],
    agencyId: loggedInAgencyId,
  })

  const jobOrders = jobOrderBatch ? jobOrderBatch[0]?.jobOrders : undefined
  const [jobOrderIndex, setJobOrderIndex] = useState<number | undefined>(undefined)
  const [dateIndex, setDateIndex] = useState<number>(0)

  const {
    data: positionData,
    isLoading: isPositionLoading,
    refetch: positionRefetch,
  } = useGetPositionByIdQuery({
    id: serviceOrder.orders[0]?.positionId as string,
  })

  const {data: companyName} = useGetCompanyNameByIdQuery({
    id: positionData?.companyId,
  })

  const positionDates = serviceOrder.orders[0]?.orderDistribution.orderQuantityDistributions.map(
    (item) => new Date(item.date),
  ) as Date[]

  const handleCallback = (date: Date) => {
    const month = format(date, 'MMM')
    const day = format(date, 'dd')

    if (!jobOrders || jobOrders.length === 0) {
      setJobOrderIndex(undefined)
    } else if (jobOrders.length === 1) {
      setJobOrderIndex(0)
    } else {
      const foundIndex = jobOrders.findIndex((element) => {
        const elementDate = new Date(element.date)
        const elementMonth = format(elementDate, 'MMM')
        const elementDay = format(elementDate, 'dd')
        return month === elementMonth && day === elementDay
      })
      setJobOrderIndex(foundIndex)
    }

    const foundDateIndex = positionDates.findIndex((element) => {
      const elementDate = new Date(element)
      const elementMonth = format(elementDate, 'MMM')
      const elementDay = format(elementDate, 'dd')
      return month === elementMonth && day === elementDay
    })
    setDateIndex(foundDateIndex)
  }

  const selectedJobOrder = jobOrders?.at(jobOrderIndex ?? 0)

  const totalCandidatesNeeded = selectedJobOrder?.agencyDistributions
    .map((distribution, indexOne: number) => distribution.agencyCandidateDistributions.requested)
    .reduce((prev: number, curr: number) => prev + curr, 0)

  const totalCandidatesFilled = selectedJobOrder?.agencyDistributions
    .map((distribution, indexOne: number) => distribution.agencyCandidateDistributions.fulfilled)
    .reduce((prev: number, curr: number) => prev + curr, 0)

  useEffect(() => {
    void refetch()
  }, [refetch])

  const hasJobOrderVariable =
    jobOrderIndex === -1 || jobOrderIndex === undefined ? undefined : selectedJobOrder?.id

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        borderRadius: 1,
        marginBottom: '23px',
        ...sx,
      }}>
      <ErrorBoundary error={error}>
        <ServiceOrderCardMsp
          callback={handleCallback}
          currentServiceOrderPageNumber={currentServiceOrderPageNumber}
          currentUserAgencyId={agencyId}
          jobOrderId={hasJobOrderVariable}
          serviceOrder={serviceOrder}
          sx={{borderRadius: '16px 16px 0px 0px', marginBottom: 0}}
        />
        {jobOrderIndex === undefined ? (
          <div style={{maxWidth: '260px'}}>
            <JobOrderDistributeOptionalCard
              key={nanoid()}
              position={positionData?.name}
              distributionDate={positionDates[dateIndex] as Date}
              client={companyName}
              shift={shift as string}
              matchedRow={false}
              style={{borderRadius: '0px 0px 16px 16px'}}
            />
          </div>
        ) : (
          <JobOrderCard
            jobOrder={selectedJobOrder}
            serviceOrder={serviceOrder}
            position={positionData}
            distributionDateOverride={positionDates[dateIndex] as Date}
            totalrequested={totalCandidatesNeeded as number}
            currentlyfilled={totalCandidatesFilled as number}
            style={{borderRadius: '0px 0px 16px 16px'}}
            onListIconClick={(e) => {
              if (hasJobOrderVariable) {
                dispatch(
                  push(
                    `${BASE_ROUTES.AGENCY_ADMIN}${
                      SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
                    }/${encodeURIComponent(hasJobOrderVariable)}?showDetails=false`,
                  ),
                )
              }
            }}
          />
        )}
      </ErrorBoundary>
    </Box>
  )
}

export default function CompletedOrdersMain(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: currPageIdx,
    pageSize: 12,
    filterBy: 'CompletedOrders',
    agencyId: loggedInAgencyId,
  })

  const handleServiceOrderPaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPageIdx])

  const totalServiceOrders = data?.totalServiceOrders

  return (
    <CompanyMainStyles.Container>
      <Title pageTitle="Completed Orders" />

      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: 1200,
              borderRadius: 1,
            }}>
            {data?.serviceOrdersModel.map((row, index) => {
              return (
                <CardCombo
                  key={index}
                  serviceOrder={row}
                  agencyId={loggedInAgencyId}
                  currentServiceOrderPageNumber={currPageIdx}
                />
              )
            })}
          </Box>
          <StyledPagination
            page={currPageIdx}
            totalRecords={totalServiceOrders}
            recordsPerPage={12}
            onChange={handleServiceOrderPaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles.Container>
  )
}
