import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function SearchIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.40548 9.46275L10.723 11.7773C11.0155 12.0743 11.488 12.0743 11.7805 11.7773C12.0745 11.481 12.0745 11.0017 11.7805 10.7047L9.47048 8.39475C10.123 7.51875 10.5107 6.43275 10.5107 5.25525C10.5107 2.35275 8.15798 0 5.25623 0C2.35373 0 0.000976562 2.35275 0.000976562 5.25525C0.000976562 8.15775 2.35373 10.5105 5.25623 10.5105C6.43748 10.5105 7.52798 10.1205 8.40548 9.46275ZM5.25548 9.009C3.18323 9.009 1.50248 7.329 1.50248 5.25525C1.50248 3.18225 3.18248 1.5015 5.25623 1.5015C7.32923 1.5015 9.00923 3.1815 9.00923 5.25525C9.00923 7.32825 7.32923 9.009 5.25698 9.009H5.25548Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
