import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import type {Theme} from '@mui/material/styles'
import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import React from 'react'
import {updateUserSignIn} from '~/src/store/slices/root-slice'
import {useAppDispatch} from '~/src/store/store-hooks'

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const Header = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  fontSize: '18px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
})

const ConfirmOrder = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '28px',
  fontWeight: 600,
  marginTop: 50,
  marginBottom: 50,
})

const ReadyToSubmitText = styled('div')({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
  marginTop: 30,
  paddingBottom: '10px',
})

const PleaseReviewP1Text = styled('div')({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
  paddingBottom: '10px',
})

const PleaseReviewP2Text = styled('div')({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  color: '#8A8A8A',
  fontSize: '15px',
  fontWeight: 400,
  paddingBottom: '10px',
})

const ButtonContainer = styled('div')({
  marginTop: '50px',
  marginBottom: '50px',
})

const BoxContainer = styled(Box)({
  maxWidth: 1600,
  m: 5,
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
})

interface IProps {
  onModalClose(): void
  modalOpen: boolean
  handleSubmit(): string | number | boolean | null
  acceptConfirmationApprovedDialog: boolean
  visited: any
  setVisited: any
}

export default function IsMspAssignedDialog({modalOpen}: IProps): React.ReactElement {
  const dispatch = useAppDispatch()

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      PaperProps={{
        sx: {
          minWidth: (theme: Theme) => theme.breakpoints.values.md,
          width: 1600,
          height: 500,
          overflow: 'hidden',
        },
      }}>
      <>
        <Header>
          <div style={{marginLeft: 40, padding: 20}}>Notice</div>
        </Header>
        <BoxContainer
          sx={{
            maxWidth: 1600,
            m: 5,
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
          }}>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
            }}>
            <ConfirmOrder>MSP is Not Assigned</ConfirmOrder>
            <ReadyToSubmitText> Your company does not yet have a MSP assigned.</ReadyToSubmitText>
            <PleaseReviewP1Text>
              Service Orders cannot be submitted until a MSP has been assigned.
            </PleaseReviewP1Text>
            <PleaseReviewP2Text>
              Please contact your JobSource Representative to request a MSP Assignment.
            </PleaseReviewP2Text>

            <ButtonContainer>
              <Grid sx={{m: 5}} item xs display="flex" justifyContent="center">
                <Button
                  onClick={() => {
                    return dispatch(updateUserSignIn({data: false}))
                  }}
                  variant="outlined"
                  color="secondary"
                  type="submit">
                  Confirm
                </Button>
              </Grid>
            </ButtonContainer>
          </Box>
        </BoxContainer>
      </>
    </Dialog>
  )
}
