// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {format} from 'date-fns'
import React, {useEffect, useState} from 'react'
import type {Applicant, Assignment} from '~/src/models/applicant'
import {
  AssignmentHistoryActiveContainer,
  AssignmentHistoryActiveText,
  Circle,
  CircleCenter,
  FlexContainer,
  FlexSpaceBetweenContainer,
  FlexWithoutPaddingContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
  PulseCircle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateAssignHistEdit from './MspCandidateAssignHistEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  index: number
  newAssign: Assignment
  oldApplicant: Applicant
  resetForm: () => void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateAssignHistSingleEdit({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
  index,
  newAssign,
}: IProps) {
  const [editProfileAssignHistState, setEditProfileAssignHistState] = useState('saveChanges')
  const [targetAssign, setTargetAssign] = useState([-1])

  const handleClick = async (value: string, index2: number, isCancel: boolean) => {
    if (value === 'saveChanges' && isCancel) {
      resetForm()
      setTargetAssign(targetAssign.filter((i) => i !== index2 + 1))
      setEditProfileAssignHistState('saveChanges')
    } else if (value === 'edit') {
      setTargetAssign([...targetAssign, index2])
      setEditProfileAssignHistState('edit')
    } else if (value === 'saveChanges' && !isCancel) {
      await handleSubmit(values)
      setEditProfileAssignHistState('saveChanges')
    }
  }

  const newAssignmentsArray = values.assignments

  useEffect(() => {
    setEditProfileAssignHistState('saveChanges')
  }, [oldApplicant.id])

  const formattedEndDate =
    newAssign?.endDate?.length > 0
      ? `- ${format(new Date(newAssign?.endDate), 'MMMM dd yyyy')}`
      : ''

  const formattedOriginalDate =
    newAssign?.originalStartDate?.length > 0 ? new Date(newAssign?.originalStartDate) : new Date()

  return (
    <>
      {editProfileAssignHistState === 'edit' && targetAssign.includes(index) ? (
        <MspCandidateAssignHistEdit
          handleSubmit={handleSubmit}
          handleClick={handleClick}
          index={index}
          oldApplicant={oldApplicant}
          setFieldValue={setFieldValue}
          targetAssign={targetAssign}
          values={newAssign}
        />
      ) : (
        <InformationCardExperianceBorder index={index === newAssignmentsArray.length - 1}>
          <FlexSpaceBetweenContainer style={{height: '30px'}}>
            {newAssign?.endDate ? (
              <FlexSpaceBetweenContainer style={{width: '100%'}}>
                <InformationCardHeaderTextTitle>
                  {newAssign.jobTitle}
                </InformationCardHeaderTextTitle>
                <InformationCardHeaderTextEdit onClick={() => handleClick('edit', index, false)}>
                  Edit
                </InformationCardHeaderTextEdit>
              </FlexSpaceBetweenContainer>
            ) : (
              <>
                <FlexWithoutPaddingContainer>
                  <CircleCenter>
                    <PulseCircle />
                    <Circle />
                  </CircleCenter>
                  <InformationCardHeaderTextTitle>
                    {newAssign.jobTitle}
                  </InformationCardHeaderTextTitle>
                </FlexWithoutPaddingContainer>
                <FlexContainer>
                  <AssignmentHistoryActiveContainer>
                    <AssignmentHistoryActiveText>Active</AssignmentHistoryActiveText>
                  </AssignmentHistoryActiveContainer>
                  <InformationCardHeaderTextEdit onClick={() => handleClick('edit', index, false)}>
                    Edit
                  </InformationCardHeaderTextEdit>
                </FlexContainer>
              </>
            )}
          </FlexSpaceBetweenContainer>
          <div style={{display: 'flex'}}>
            <InformationCardProfileExperienceJobText style={{marginRight: '25px'}}>
              {newAssign.customerName}
            </InformationCardProfileExperienceJobText>
            <InformationCardProfileExperienceJobText>
              {format(new Date(formattedOriginalDate), 'MMMM dd yyyy')} {formattedEndDate}
            </InformationCardProfileExperienceJobText>
          </div>
          {/* for future uses, description is not recorded in the backend currently */}
          <InformationCardProfileHeaderText>
            {newAssign?.description}
          </InformationCardProfileHeaderText>
        </InformationCardExperianceBorder>
      )}
    </>
  )
}
