import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function AmazonIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='social / Amazon'>
				<g id='Amazon'>
					<rect id='Rectangle' width='48' height='48' rx='24' fill='#FF9201' />
					<g id='Group'>
						<path
							id='path3673'
							d='M32.5509 27.4557C31.8838 26.543 31.1682 25.8005 31.1682 24.1022V18.4647C31.1682 16.0753 31.3415 13.8819 29.5609 12.2381C28.1594 10.9051 25.8313 10.4348 24.0507 10.4348C20.5684 10.4348 16.6837 11.7194 15.869 15.9763C15.7828 16.4285 16.1148 16.6652 16.4159 16.7317L19.9618 17.1113C20.2938 17.0946 20.5336 16.7718 20.5979 16.4466C20.9019 14.9797 22.1439 14.275 23.5394 14.275C24.292 14.275 25.1467 14.5473 25.5922 15.2149C26.105 15.9582 26.0362 16.9797 26.0362 17.8379V18.3104C23.9154 18.5448 21.141 18.7006 19.1547 19.5618C16.8637 20.5432 15.2549 22.5401 15.2549 25.4754C15.2549 29.237 17.6488 31.1175 20.7311 31.1175C23.3322 31.1175 24.7557 30.5103 26.7624 28.487C27.428 29.4412 27.6459 29.9009 28.8598 30.8997C29.1321 31.0434 29.4831 31.0297 29.7243 30.8136L29.7252 30.8128L29.7334 30.8218C30.4641 30.1792 31.7908 29.0389 32.5388 28.419C32.8376 28.1762 32.7846 27.7838 32.5509 27.4557ZM25.3517 25.83C24.7709 26.8508 23.8458 27.4753 22.8179 27.4753C21.4163 27.4753 20.5933 26.4167 20.5933 24.8508C20.5933 21.7681 23.3904 21.2086 26.0355 21.2086V21.9897H26.0362C26.0362 23.3998 26.0703 24.574 25.3517 25.83Z'
							fill='white'
						/>
						<g id='Group_2'>
							<path
								id='path3719'
								d='M36.9242 33.6073C33.4216 36.1906 28.3422 37.5652 23.9677 37.5652C17.8391 37.5652 12.3187 35.3007 8.14148 31.5307C7.81382 31.2343 8.10628 30.8294 8.49993 31.0596C13.0074 33.6816 18.5806 35.2599 24.3366 35.2599C28.22 35.2599 32.4881 34.455 36.4153 32.7888C37.0077 32.5384 37.5042 33.18 36.9242 33.6073Z'
								fill='white'
							/>
							<path
								id='path3721'
								d='M38.3819 31.9432C37.9342 31.3705 35.4195 31.6718 34.2918 31.808C33.9488 31.8478 33.896 31.5502 34.2042 31.3318C36.2095 29.9239 39.4954 30.3313 39.8776 30.802C40.2627 31.2791 39.777 34.5717 37.8974 36.1425C37.6082 36.3849 37.3336 36.2573 37.4613 35.9374C37.8841 34.8805 38.8312 32.5169 38.3819 31.9432Z'
								fill='white'
							/>
						</g>
					</g>
				</g>
			</g>
		</Svg>
	);
}
