/* eslint-disable complexity */
import Box from '@mui/material/Box'
import {FormikAutocomplete} from '@ui/autocomplete'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {IUserBase} from '~/src/models'
import type {
  ApplicantInterview,
  ApplicantInterviewClericalFormik,
  ApplicantInterviewFormikSubmit,
} from '~/src/models/applicant'
import {
  AvailabilityQuestions,
  BehavioralQuestionsSubset,
  BigBold,
  GreySubTitle,
  InformationCardContainer,
  InitialQuestions,
  NewInterview,
  QAndADropdown,
  QAndATextArea,
  QuestionsBox,
  StyledButton,
  TInterviewTypes,
} from './CommonInterviewComponents'

interface IProps {
  calculateSubmit: (template: TInterviewTypes, values: ApplicantInterviewFormikSubmit) => void
  selectedInterview?: ApplicantInterview
  currentUser?: IUserBase | null
}

const ClericalForm = ({calculateSubmit, selectedInterview, currentUser}: IProps) => {
  return (
    <Formik
      onSubmit={(values) => {
        void calculateSubmit('clerical', values as unknown as ApplicantInterviewFormikSubmit)
      }}
      initialValues={
        {
          company: selectedInterview?.company ?? [],
          position: selectedInterview?.position ?? [],
          employmentType: selectedInterview?.employmentType ?? [],

          discovery:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'discovery')
              ?.value ?? null,
          employed:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'employed')
              ?.value ?? null,
          dislike:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'dislike')
              ?.value ?? null,
          like:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'like')
              ?.value ?? null,
          extraDuties:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'extraDuties')
              ?.value ?? '',

          safteyForClerical:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'safteyForClerical',
            )?.value ?? null,
          whatClericalSaftey:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'whatClericalSaftey',
            )?.value ?? '',
          wpm:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'wpm')
              ?.value ?? '',
          msOffice:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'msOffice')
              ?.value ?? '',
          longDeskWork:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'longDeskWork',
            )?.value ?? null,
          whyNoLongDeskWork:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'whyNoLongDeskWork',
            )?.value ?? '',
          languages:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'languages')
              ?.value ?? null,
          transportaion:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'transportaion',
            )?.value ?? null,
          techSavvy:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'techSavvy')
              ?.value ?? null,
          techSavvyHow:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'techSavvyHow',
            )?.value ?? '',
          certs:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'certs')
              ?.value ?? null,
          whichCerts:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'whichCerts')
              ?.value ?? '',
          whichOfficeJobs:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'whichOfficeJobs',
            )?.value ?? '',
          fastPaced:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'fastPaced')
              ?.value ?? null,
          angryCustomer:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'angryCustomer',
            )?.value ?? '',
          teamOrIndependent:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'teamOrIndependent',
            )?.value ?? null,

          wage:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'wage')
              ?.value ?? '',
          flexible:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'flexible')
              ?.value ?? null,
          drugTest:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'drugTest')
              ?.value ?? null,

          outsideInjury:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'outsideInjury',
            )?.value ?? null,
          reportInjuryWho:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWho',
            )?.value ?? null,
          reportInjuryWhen:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWhen',
            )?.value ?? null,
          reportLiquid:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportLiquid',
            )?.value ?? null,
          reportPolicyBreaking:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportPolicyBreaking',
            )?.value ?? null,
          difficultCoworker:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'difficultCoworker',
            )?.value ?? '',

          notes: selectedInterview?.notes ?? '',
        } as unknown as ApplicantInterviewClericalFormik
      }
      validationSchema={Yup.object({
        company: Yup.array().min(1, 'Required').required('Required'),
        position: Yup.array().min(1, 'Required').required('Required'),
        employmentType: Yup.array().min(1, 'Required').required('Required'),

        discovery: Yup.string().nullable(),
        employed: Yup.string().nullable(),
        dislike: Yup.string().nullable(),
        like: Yup.string().nullable(),
        extraDuties: Yup.string().nullable(),

        safteyForClerical: Yup.string().nullable(),
        whatClericalSaftey: Yup.string().nullable(),
        wpm: Yup.string().nullable(),
        msOffice: Yup.string().nullable(),
        longDeskWork: Yup.string().nullable(),
        whyNoLongDeskWork: Yup.string().nullable(),
        languages: Yup.string().nullable(),
        transportaion: Yup.string().nullable(),
        techSavvy: Yup.string().nullable(),
        techSavvyHow: Yup.string().nullable(),
        certs: Yup.string().nullable(),
        whichCerts: Yup.string().nullable(),
        whichOfficeJobs: Yup.string().nullable(),
        fastPaced: Yup.string().nullable(),
        angryCustomer: Yup.string().nullable(),
        teamOrIndependent: Yup.string().nullable(),

        wage: Yup.string().nullable(),
        flexible: Yup.string().nullable(),
        drugTest: Yup.string().nullable(),

        outsideInjury: Yup.string().nullable(),
        reportInjuryWho: Yup.string().nullable(),
        reportInjuryWhen: Yup.string().nullable(),
        reportLiquid: Yup.string().nullable(),
        reportPolicyBreaking: Yup.string().nullable(),
        difficultCoworker: Yup.string().nullable(),

        notes: Yup.string().nullable().required('Required'),
      })}>
      {({
        values,
        errors: subErrors,
        setValues,
        setFieldValue,
        isSubmitting,
        handleSubmit: handleSubmitSub,
      }) => {
        return (
          <Form>
            <InformationCardContainer sx={{justifyContent: 'flex-start'}}>
              <NewInterview
                name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                type="clerical"
                setValue={setFieldValue}
                selectedInterview={selectedInterview}
                valuesSubset={{company: values.company, position: values.position, employmentType: values.employmentType}}
              />
              <BigBold style={{marginTop: '20px'}}>Interview Questions</BigBold>
              <QuestionsBox>
                <InitialQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Skills
                </GreySubTitle>
                <QAndADropdown
                  question="Have you ever received any education or safety training related to clerical?"
                  component={
                    <FormikAutocomplete
                      name="safteyForClerical"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.safteyForClerical === 'Yes' && (
                  <QAndATextArea
                    question="If so, what type? (neck and back strain, carpal tunnel, crushed fingers, paper cuts)"
                    component={
                      <FormikTextField
                        name="whatClericalSaftey"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndATextArea
                  question="Do you know how many WPM you can type?"
                  component={<FormikTextField name="wpm" />}
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="Are you familiar with Microsoft Office? 
                How comfortable are you working with them? (Word, Excel, Power Point)"
                  component={
                    <FormikTextField
                      name="msOffice"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Are you able to work in a desk for long periods of time?"
                  component={
                    <FormikAutocomplete
                      name="longDeskWork"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.longDeskWork === 'No' && (
                  <QAndATextArea
                    question="If no, why?"
                    component={
                      <FormikTextField
                        name="whyNoLongDeskWork"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="What languages can you speak?"
                  component={
                    <FormikAutocomplete
                      name="languages"
                      options={['English Only', 'Spanish Only', 'Bilingual']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have reliable transportation?"
                  component={
                    <FormikAutocomplete
                      name="transportaion"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you consider yourself TECH SAVVY?"
                  component={
                    <FormikAutocomplete
                      name="techSavvy"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.techSavvy === 'Yes' && (
                  <QAndATextArea
                    question="If yes, why?"
                    component={
                      <FormikTextField
                        name="techSavvyHow"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Do you have any certifications?"
                  component={
                    <FormikAutocomplete
                      name="certs"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.certs === 'Yes' && (
                  <QAndATextArea
                    question="If yes, which ones?"
                    component={
                      <FormikTextField
                        name="whichCerts"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndATextArea
                  question="What office job can you perform the best?"
                  component={
                    <FormikTextField
                      name="whichOfficeJobs"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Are you comfortable working in a fast-paced environment?"
                  component={
                    <FormikAutocomplete
                      name="fastPaced"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="How do you deal with an angry customer?"
                  component={
                    <FormikTextField
                      name="angryCustomer"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you prefer to work in a team or independently?"
                  component={
                    <FormikAutocomplete
                      name="teamOrIndependent"
                      options={['Team', 'Independent']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Availability
                </GreySubTitle>
                <AvailabilityQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Behavioral Questions: (We’d like to see our EE’s thought process)
                </GreySubTitle>
                <BehavioralQuestionsSubset />
                <QAndATextArea
                  question="Additional Notes"
                  isRequired
                  component={
                    <FormikTextField
                      name="notes"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <StyledButton
                    onClick={() => {
                      handleSubmitSub()
                    }}
                    variant="contained"
                    color="primary"
                    sx={{marginTop: '20px', width: '168px'}}>
                    Finish & Save
                  </StyledButton>
                </Box>
              </QuestionsBox>
            </InformationCardContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default React.memo(ClericalForm)
