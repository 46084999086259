import type {FormikErrors} from 'formik'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateEventsDisplay from './MspCandidateEventsDisplay'
import MspCandidateEventsEmptyForm from './MspCandidateEventsEmptyForm'

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  resetForm(obj: Applicant): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  oldApplicant: Applicant
  values: Applicant
}

export default function MspCandidateEventsMain({
  errors,
  handleSubmit,
  resetForm,
  setFieldValue,
  oldApplicant,
  values,
}: IProps) {
  return (
    <>
      <DashboardMainStyles>
        <MspCandidateEventsEmptyForm
          errors={errors}
          handleSubmit={handleSubmit}
          resetForm={resetForm}
          setFieldValue={setFieldValue}
          values={values}
        />
        <MspCandidateEventsDisplay
          handleSubmit={handleSubmit}
          oldApplicant={oldApplicant}
          setFieldValue={setFieldValue}
          values={values}
        />
      </DashboardMainStyles>
    </>
  )
}
