import {styled} from '@mui/material/styles'
import ErrorBoundary from '@ui/error'
import React from 'react'
import ViewWrapper from '~/src/components/common/ViewWrapper'
import CardLink from '~/src/components/platform/onboarding/PlatformWidget'
import Title from '../../layout/Title'
import AddCompanyForm from './AddCompanyForm'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 36,
})

const TopRow = styled('div')({
  columnGap: 30,
  display: 'flex',
})
export type TActivePlatform = 'Company' | 'Agency'

export default function OnboardingCompany() {
  const [activePlatform, setactivePlatform] = React.useState<TActivePlatform>('Company')

  return (
    <ViewWrapper customHeader={<Title pageTitle={`Onboarding ${activePlatform}`} />}>
      <Container>
        <TopRow>
          <CardLink
            icon="building"
            topText="Onboard Company"
            bottomText="104"
            data-testid="company-nav-button"
            isActive={true}
            onClick={() => setactivePlatform('Company')}
          />
        </TopRow>

        <ErrorBoundary>
          <AddCompanyForm activePlatform={activePlatform} />
        </ErrorBoundary>
      </Container>
    </ViewWrapper>
  )
}
