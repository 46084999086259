import {Grid} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import PasswordFormikTextField from '@ui/mui-text-field/PasswordFormikTextField'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {regex} from '~/src/utils'
import ErrorBox from '../ui/error/ErrorBox'
import {errorText} from './constants/helper-text'
import PasswordRequirements from './PasswordRequirements'
import SubmitButton from './SubmitButton'

const FieldContainer = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(4)};
    margin-top: ${theme.spacing(5)};
  `,
)

const ProgressBar = styled('div')(
  ({theme}) => css`
    .password {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      height: 5px;
      background: #ddd;
      border-radius: 5px;
      width: 200px;
    }
    .password:before,
    .password:after {
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #ddd;
      border-style: solid;
      border-width: 0 1px 0;
      position: absolute;
      width: calc(20%);
      z-index: 10;
    }
    .password:before {
      left: calc(20%);
    }
    .password:after {
      right: calc(20%);
    }

    .strength-1::-webkit-progress-value {
      background-color: #f25f5c;
      width: 100%;
    }
    .strength-2::-webkit-progress-value {
      background-color: #f25f5c;
      width: 100%;
    }

    .strength-3::-webkit-progress-value {
      background-color: orange;
      width: 100%;
    }
    .strength-4::-webkit-progress-value {
      background-color: orange;
      width: 100%;
    }
    .strength-5::-webkit-progress-value {
      background-color: green;
      width: 100%;
    }

    .strength-0 {
      color: white;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }
    .strength-1,
    .strength-0s {
      color: #f25f5c;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }

    .strength-2 {
      color: #f25f5c;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }

    .strength-3 {
      color: orange;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }

    .strength-4 {
      color: orange;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }

    .strength-5 {
      color: green;
      display: flex;
      font-size: 11px;
      font-weight: 500;
    }

    .feedback {
      display: inherit !important;
      margin-top: -5px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  `,
)

interface IProps {
  isError?: boolean
  oldPassword?: string
  onSubmit: (args: {password: string; confirm: string}) => Promise<unknown>
}

export default function PasswordResetForm({onSubmit, isError, oldPassword}: IProps) {
  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    hasLong: false,
    hasSpecialChar: false,
  })

  const strength = Object.values(validate).reduce((a, item) => a + item, 0)
  const feedback = {
    1: 'Weak',
    2: 'Weak',
    3: 'Medium',
    4: 'Medium',
    5: 'Strong',
  }[strength]
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{password: '', confirm: ''}}
      validate={(values) => {
        return (
          regex.lowerCase.test(values.password)
            ? setValidate((o) => ({...o, hasLow: true}))
            : setValidate((o) => ({...o, hasLow: false})),
          regex.upperCase.test(values.password)
            ? setValidate((o) => ({...o, hasCap: true}))
            : setValidate((o) => ({...o, hasCap: false})),
          regex.specialCharacter.test(values.password)
            ? setValidate((o) => ({...o, hasSpecialChar: true}))
            : setValidate((o) => ({...o, hasSpecialChar: false})),
          regex.number.test(values.password)
            ? setValidate((o) => ({...o, hasNumber: true}))
            : setValidate((o) => ({...o, hasNumber: false})),
          regex.min8Characters.test(values.password)
            ? setValidate((o) => ({...o, hasLong: true}))
            : setValidate((o) => ({...o, hasLong: false}))
        )
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .test(
            'Should not match old password',
            errorText.tooSimilarToOldPassword,
            (val) => val !== oldPassword,
          )
          .required(errorText.passwordRequired),

        confirm: Yup.string()
          .oneOf([Yup.ref('password')], errorText.passwordsDontMatch)
          .required(errorText.confirmPasswordRequired),
      })}>
      {({isSubmitting, errors, values}) => {
        return (
          <Form>
            <FieldContainer>
              <PasswordFormikTextField
                InputLabelProps={{
                  shrink: true,
                }}
                name="password"
                label="Password"
                type="password"
              />

              <ProgressBar sx={{marginTop: '-10px'}}>
                <Grid container>
                  <Grid item xs sx={{textAlign: 'right'}}>
                    <progress
                      className={`password strength-${strength}`}
                      value={strength}
                      max="5"
                    />
                  </Grid>
                  <Grid item xs />
                  <Grid item xs />

                  <Grid item xs>
                    <div className={`feedback strength-${strength}`}>{feedback}</div>
                  </Grid>
                </Grid>
              </ProgressBar>

              <PasswordRequirements validate={validate} />
              <PasswordFormikTextField
                InputLabelProps={{
                  shrink: true,
                }}
                name="confirm"
                label="Re-enter password"
                type="password"
              />
            </FieldContainer>

            <ErrorBox>{isError && errorText.serverError}</ErrorBox>

            <SubmitButton
              disabled={
                !(
                  validate.hasCap &&
                  validate.hasLong &&
                  validate.hasLow &&
                  validate.hasNumber &&
                  validate.hasSpecialChar
                ) ||
                values.password === '' ||
                values.password !== values.confirm
              }>
              Set Password
            </SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
