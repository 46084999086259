import styled from 'styled-components'

import {resets} from '~/src/config/theme'

const CardButtonStyles = {
  Container: styled.button`
    ${resets.common};
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 88px;
    text-decoration: none;
    width: 255px;
    white-space: nowrap;
    background-color: transparent;
  `,
}

export default CardButtonStyles
