import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import {css, styled} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import type {IPrefill} from '~/src/models/prefill'
import PrefillCard from './PrefillCard'

const ContainerBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1;
    margin: 1;
  `,
)

const LeftButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;
    min-height: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(217, 217, 217, 0.4);
    margin-right: -35px;
    left: 30px;
  `,
)

const RightButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;
    right: 5px;
  `,
)

const RightButtonBox2 = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;

    min-height: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(217, 217, 217, 0.4);
    margin-left: -90px;
    right: 85px;
  `,
)
interface IProps {
  count: number
  isMsp: boolean
  allPrefills: IPrefill[]
  prefillId: string | null
  sx?: object
  onToggleInventory: (value: boolean) => void
  showList?: boolean
}

 const ClickablePrefill = function ClickablePrefill({
  allPrefills,
  count,
  isMsp,
  prefillId,
  sx,
  onToggleInventory,
  showList = false,
}: IProps) {
  const [display, setDisplay] = useState(false)
  const showButton = (e) => {
    e.preventDefault()
    setDisplay(true)
  }

  const hideButton = (e) => {
    e.preventDefault()
    setDisplay(false)
  }
  const [leftButtonVisible, setLeftButtonVisible] = useState(false)
  const [rightButtonVisible, setRightButtonVisible] = useState(false)
  const [index, setIndex] = useState(0)
  const [activeLink, setActiveLink] = useState<'search' | 'list'>(showList ? 'list' : 'search')
  const length = allPrefills.length
  const initialSelectArray = new Array(length).fill(false)
  initialSelectArray[0] = true

  const selectedData: IPrefill[] = allPrefills.filter((i: IPrefill) => {
    return i.id === prefillId
  })

  const filteredPrefillArray = allPrefills.filter((i: IPrefill) => i.id !== prefillId)
  const slicedList = filteredPrefillArray.slice(index, index + count)
  const inc = () => {
    setIndex(index + 1)
  }

  const dec = () => {
    setIndex(index - 1)
  }

  useEffect(() => {
    setRightButtonVisible(length - index > count)
    setLeftButtonVisible(index > 0)
  }, [count, index, length])

  return (
    <ContainerBox
      onMouseEnter={(e) => showButton(e)}
      onMouseLeave={(e) => hideButton(e)}
      sx={{
        ...sx,
        justifyContent: 'space-between',
      }}>
      {selectedData[0] && (
        <PrefillCard
          prefillData={selectedData[0]}
          onSummaryToggle={onToggleInventory}
          isInPrefill={true}
          isSelected={true}
          isMsp={isMsp}
          key={-1}
          sx={{minWidth: '260px'}}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
      )}

      {leftButtonVisible && display ? (
        <LeftButtonBox>
          <IconButton
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            color="secondary"
            onClick={dec}>
            <ArrowBackIosNewIcon
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </IconButton>
        </LeftButtonBox>
      ) : (
        <div style={{marginLeft: 21}} />
      )}

      <Stack direction="row">
        {slicedList.map((item: IPrefill, index3: number) => (
          <PrefillCard
            prefillData={item}
            onSummaryToggle={onToggleInventory}
            isInPrefill={true}
            isSelected={false}
            isMsp={isMsp}
            key={index3}
            sx={{marginRight: '25px', minWidth: '260px'}}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
        )
        )}
      </Stack>
      <RightButtonBox>
        {rightButtonVisible && display ? (
          <RightButtonBox2>
            <IconButton
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              color="secondary"
              onClick={inc}
              disableFocusRipple>
              <ArrowForwardIosIcon
                sx={{
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              />
            </IconButton>
          </RightButtonBox2>
        ) : (
          <div />
        )}
      </RightButtonBox>
    </ContainerBox>
  )
}

export default React.memo(ClickablePrefill)
