import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import {MenuItem} from '@mui/material'
import Checkbox from '@ui/checkbox/FormikCheckbox'
import {FormikGooglePlacesAutocomplete} from '@ui/components'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {FieldArray} from 'formik'
import React from 'react'
import type {WorkHistory} from '~/src/models/applicant'
import {PayFrequency, ReasonForLeaving} from '~/src/models/CandidateForm'
import {
  AddExperienceCardContainer,
  AddExperienceContainer,
  AddExperienceText,
  CloseIconContainer,
  CloseIconVerticalAlignContainer,
  DashboardMainStylesMaxWidth,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceBetweenContainer,
  FlexSpaceEvenlyContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
  MarginBottom15Px,
  Red,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import {createNewExperienceItem} from '../../../msp-job-order-information/JobOrderSummaryProfile'

interface IProps {
  handleCancel(): void
  handleClick(value: string): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: WorkHistory[]
}

const MspCandidateProfileExperienceEdit = ({
  handleCancel,
  handleClick,
  setFieldValue,
  values,
}: IProps) => {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Work Experience</InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                <InformationCardHeaderTextEdit style={{marginRight: 10}} onClick={handleCancel}>
                  Cancel
                </InformationCardHeaderTextEdit>
                <InformationCardHeaderTextEdit onClick={() => handleClick('saveChanges')}>
                  Save
                </InformationCardHeaderTextEdit>
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <FieldArray name="workHistories">
            {({push, remove}) => (
              <React.Fragment>
                {values.map((newWorkHistory: WorkHistory, index: number) => {
                  const maxStartDate = newWorkHistory.endDate
                    ? new Date(newWorkHistory.endDate)
                    : undefined
                  const minEndDate = new Date(newWorkHistory.startDate)

                  const handleContact = (value) => {
                    setFieldValue(`workHistories.${index}.contactEmployer`, value)
                  }

                  return (
                    <>
                      <FlexContainer
                        style={{
                          width: '770px',
                          height: '390px',
                          borderRadius: '6px',
                          background: '#FFF',
                          boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
                        }}>
                        <InformationCardDetailsContainer>
                          <MarginBottom15Px>
                            <InformationCardProfileExperienceJobText>
                              <FlexContainer>
                                <FlexColumnContainer>
                                  <span>
                                    Position <Red>*</Red>
                                  </span>
                                  <FormikTextField
                                    aria-label="work History-job position"
                                    name={`workHistories.${index}.position`}
                                    type="text"
                                    label=""
                                    data-testid="workHistoryPosition"
                                    value={newWorkHistory?.position}
                                    sx={{
                                      maxWidth: '210px',
                                      minWidth: '210px',
                                      height: '28px',
                                      maxHeight: '28px',
                                      marginRight: '19px',
                                    }}
                                  />
                                </FlexColumnContainer>
                                <FlexColumnContainer style={{marginRight: '20px'}}>
                                  <span>
                                    Company <Red>*</Red>
                                  </span>
                                  <FormikTextField
                                    aria-label="work History- company name"
                                    name={`workHistories.${index}.companyName`}
                                    data-testid="workHistoryCompanyName"
                                    type="text"
                                    label=""
                                    value={newWorkHistory.companyName}
                                    sx={{
                                      maxWidth: '210px',
                                      minWidth: '210px',
                                      height: '28px',
                                      maxHeight: '28px',
                                    }}
                                  />
                                </FlexColumnContainer>

                                <FlexColumnContainer>
                                  <span>
                                    City, State <Red>*</Red>
                                  </span>
                                  <div style={{width: 210}}>
                                    <FormikGooglePlacesAutocomplete
                                      isCityStateBoolean
                                      name={`workHistories.${index}.address`}
                                      setFieldValue={setFieldValue}
                                      inputProps={{maxLength: 210}}
                                    />
                                  </div>
                                </FlexColumnContainer>
                              </FlexContainer>
                            </InformationCardProfileExperienceJobText>
                            <InformationCardProfileExperienceJobText>
                              <FlexContainer>
                                <FlexColumnContainer>
                                  <span>Starting Pay</span>
                                  <div style={{width: '95px', marginRight: 20}}>
                                    <FormikTextField
                                      aria-label="work-History-startingPay"
                                      name={`workHistories.${index}.startingPay`}
                                      data-testid="workHistoryStartingPay"
                                      type="number"
                                      value={newWorkHistory?.startingPay}
                                      sx={{
                                        height: '28px',
                                        maxHeight: '28px',
                                        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                          {
                                            WebkitAppearance: 'none',
                                            margin: 0,
                                          },
                                        'input[type=number]': {
                                          MozAppearance: 'textfield',
                                        },
                                      }}
                                    />
                                  </div>
                                </FlexColumnContainer>
                                <FlexColumnContainer>
                                  <span>Pay Period</span>
                                  <div style={{width: '95px', marginRight: 20}}>
                                    <FormikTextField
                                      select
                                      aria-label="work History-startingPayFrequency"
                                      name={`workHistories.${index}.startingPayFrequency`}
                                      data-testid="workHistoryStartingPayFrequency"
                                      type="text"
                                      value={newWorkHistory?.startingPayFrequency}
                                      sx={{
                                        height: '28px',
                                        maxHeight: '28px',
                                      }}>
                                      {PayFrequency.map((payInfo, payInfoIndex) => {
                                        return (
                                          <MenuItem
                                            key={payInfoIndex}
                                            aria-label={`candidate-graduationType-${payInfo}-Value`}
                                            data-testid={`candidate-graduationType-${payInfo}-Value`}
                                            value={payInfo}>
                                            {`${payInfo}`}
                                          </MenuItem>
                                        )
                                      })}
                                    </FormikTextField>
                                  </div>
                                </FlexColumnContainer>
                                <FlexColumnContainer>
                                  <FlexColumnContainer>
                                    <span>Ending Pay</span>
                                    <div style={{width: '95px', marginRight: 20}}>
                                      <FormikTextField
                                        aria-label="workHistory-endingPay"
                                        name={`workHistories.${index}.endingPay`}
                                        data-testid="workHistoryEndingPay"
                                        type="number"
                                        value={newWorkHistory?.endingPay}
                                        sx={{
                                          height: '28px',
                                          maxHeight: '28px',
                                          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                            {
                                              WebkitAppearance: 'none',
                                              margin: 0,
                                            },
                                          'input[type=number]': {
                                            MozAppearance: 'textfield',
                                          },
                                        }}
                                      />
                                    </div>
                                  </FlexColumnContainer>
                                </FlexColumnContainer>
                                <FlexColumnContainer>
                                  <FlexColumnContainer>
                                    <span>Pay Period</span>
                                    <div style={{width: '95px'}}>
                                      <FormikTextField
                                        select
                                        aria-label="work History-endingPayFrequency"
                                        name={`workHistories.${index}.endingPayFrequency`}
                                        data-testid="workHistoryCompany"
                                        type="text"
                                        value={newWorkHistory?.endingPayFrequency}
                                        sx={{
                                          height: '28px',
                                          maxHeight: '28px',
                                        }}>
                                        {PayFrequency.map((payInfo, payInfoIndex) => {
                                          return (
                                            <MenuItem
                                              key={payInfoIndex}
                                              aria-label={`candidate-graduationType-${payInfo}-Value`}
                                              data-testid={`candidate-graduationType-${payInfo}-Value`}
                                              value={payInfo}>
                                              {`${payInfo}`}
                                            </MenuItem>
                                          )
                                        })}
                                      </FormikTextField>
                                    </div>
                                  </FlexColumnContainer>
                                </FlexColumnContainer>
                              </FlexContainer>
                            </InformationCardProfileExperienceJobText>
                            <InformationCardProfileExperienceJobText>
                              <FlexContainer>
                                <FlexColumnContainer>
                                  <span>
                                    Start Date <Red>*</Red>
                                  </span>
                                  <div style={{width: '95px'}}>
                                    <FormikDatePicker
                                      aria-label="work History-start date"
                                      name={`workHistories.${index}.startDate`}
                                      data-testid="workHistoryStartDate"
                                      initialValue={newWorkHistory.startDate}
                                      minDate={
                                        newWorkHistory?.endDate
                                          ? maxStartDate.setDate(maxStartDate.getDate())
                                          : undefined
                                      }
                                      sx={{
                                        width: '100%',
                                      }}
                                      onClose={(va: Date) =>
                                        setFieldValue(newWorkHistory.startDate, va)
                                      }
                                    />
                                  </div>
                                </FlexColumnContainer>
                                <FlexColumnContainer style={{marginLeft: 20}}>
                                  End Date
                                  <div style={{width: '95px'}}>
                                    <FormikDatePicker
                                      aria-label="work History-end date"
                                      name={`workHistories.${index}.endDate`}
                                      data-testid="workHistoryEndDate"
                                      initialValue={
                                        newWorkHistory?.endDate ? newWorkHistory.endDate : undefined
                                      }
                                      minDate={minEndDate.setDate(minEndDate.getDate())}
                                      sx={{
                                        width: '100%',
                                      }}
                                      onClose={(va: Date) =>
                                        setFieldValue(newWorkHistory.endDate, va)
                                      }
                                    />
                                  </div>
                                </FlexColumnContainer>
                                <FlexColumnContainer style={{marginLeft: 20}}>
                                  <FlexColumnContainer>
                                    <span>
                                      Supervisor <Red>*</Red>
                                    </span>
                                    <FormikTextField
                                      aria-label="workHistory-supervisorName"
                                      name={`workHistories.${index}.supervisorName`}
                                      data-testid="workHistorySupervisorName"
                                      type="text"
                                      value={newWorkHistory?.supervisorName}
                                      sx={{
                                        maxWidth: '210px',
                                        minWidth: '210px',
                                        height: '28px',
                                        maxHeight: '28px',
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: '15px',
                                        textWrap: 'nowrap',
                                      }}>
                                      <Checkbox
                                        checked={newWorkHistory.contactEmployer}
                                        name="contactEmployer"
                                        onClick={(e) => handleContact(e.target.checked)}
                                      />
                                      <span
                                        style={{
                                          marginLeft: '5px',
                                          color: '#707070',
                                          fontSize: '11px',
                                          fontWeight: 400,
                                        }}>
                                        May we contact this employer?
                                      </span>
                                    </div>
                                  </FlexColumnContainer>
                                </FlexColumnContainer>
                                <FlexColumnContainer style={{marginLeft: 20}}>
                                  Reason For Leaving
                                  <FormikTextField
                                    select
                                    aria-label="work History-reasonForLeaving"
                                    name={`workHistories.${index}.reasonForLeaving`}
                                    data-testid="workHistoryReasonForLeaving"
                                    type="text"
                                    label=""
                                    value={newWorkHistory?.reasonForLeaving}
                                    sx={{
                                      maxWidth: '210px',
                                      minWidth: '210px',
                                      height: '28px',
                                      maxHeight: '28px',
                                    }}>
                                    {ReasonForLeaving.map(
                                      (reasonForLeavingInfo, reasonForLeavingInfoIndex) => {
                                        return (
                                          <MenuItem
                                            key={reasonForLeavingInfoIndex}
                                            aria-label={`candidate-reasonForLeavingType-${reasonForLeavingInfo}-Value`}
                                            data-testid={`candidate-reasonForLeavingType-${reasonForLeavingInfo}-Value`}
                                            value={reasonForLeavingInfo}>
                                            {`${reasonForLeavingInfo}`}
                                          </MenuItem>
                                        )
                                      },
                                    )}
                                  </FormikTextField>
                                </FlexColumnContainer>
                              </FlexContainer>
                            </InformationCardProfileExperienceJobText>
                            <InformationCardProfileHeaderText>
                              <span>
                                Job Duties<Red>*</Red>
                              </span>
                              <FormikTextField
                                aria-label="work History- job duties"
                                name={`workHistories.${index}.jobDuties`}
                                data-testid="workHistoryJobDuties"
                                id="outlined-multiline-flexible"
                                multiline
                                rows={2}
                                type="text"
                                label=""
                                value={newWorkHistory.jobDuties}
                                inputProps={{
                                  style: {
                                    paddingTop: 5,
                                    paddingBottom: 20,
                                  },
                                }}
                                sx={{
                                  marginBottom: '15px',
                                }}
                              />
                            </InformationCardProfileHeaderText>
                          </MarginBottom15Px>
                        </InformationCardDetailsContainer>
                        <CloseIconContainer style={{height: '100%'}} onClick={() => remove(index)}>
                          <CloseIconVerticalAlignContainer>
                            <CloseIcon />
                          </CloseIconVerticalAlignContainer>
                        </CloseIconContainer>
                      </FlexContainer>
                    </>
                  )
                })}
                <AddExperienceCardContainer>
                  <AddExperienceContainer onClick={() => push(createNewExperienceItem())}>
                    <FlexSpaceEvenlyContainer>
                      <AddExperienceText>
                        <AddCircleIcon sx={{maxHeight: '20px', maxWidth: '20px'}} />
                      </AddExperienceText>
                      <AddExperienceText>Add New Experience</AddExperienceText>
                    </FlexSpaceEvenlyContainer>
                  </AddExperienceContainer>
                </AddExperienceCardContainer>
              </React.Fragment>
            )}
          </FieldArray>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}

export default React.memo(MspCandidateProfileExperienceEdit)
