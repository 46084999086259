import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  FlexSpaceBetweenContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileDetailsText,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleCancel(): void
  handleSave(value: string): void
  oldApplicant: Applicant
  setFieldValue: any
  applicant: Applicant
  isDataCleanup?: boolean
}

export default function MspCandidateProfileContactEdit({
  handleCancel,
  handleSave,
  oldApplicant,
  setFieldValue,
  applicant,
  isDataCleanup,
}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Emergency Contact</InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                {isDataCleanup ? null : (
                  <>
                    <InformationCardHeaderTextEdit style={{marginRight: 10}} onClick={handleCancel}>
                      Cancel
                    </InformationCardHeaderTextEdit>
                    <InformationCardHeaderTextEdit onClick={() => handleSave('saveChanges')}>
                      Save
                    </InformationCardHeaderTextEdit>
                  </>
                )}
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Full Name</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    aria-label="emergencyContactName"
                    name="emergencyContactName"
                    data-testid="emergencyContactName"
                    type="text"
                    label=""
                    value={applicant?.emergencyContactName}
                    sx={{
                      marginBottom: '15px',
                      width: '249px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Relationship</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    aria-label="emergencyContactRelationship"
                    name="emergencyContactRelationship"
                    data-testid="emergencyContactRelationship"
                    type="text"
                    label=""
                    value={applicant?.emergencyContactRelationship}
                    sx={{
                      marginBottom: '15px',
                      minWidth: '140px',
                      maxWidth: '140px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText>
                <span style={{fontWeight: 'bold'}}>Phone Number</span>
                <InformationCardProfileDetailsText>
                  <FormikTextField
                    aria-label="emergencyPhone"
                    name="emergencyPhone"
                    data-testid="emergencyPhone"
                    type="text"
                    label=""
                    value={applicant?.emergencyPhone}
                    sx={{
                      marginBottom: '15px',
                      width: '300px',
                    }}
                  />
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
