import {Box, Button, css} from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import {styled} from '@mui/material/styles'
import CheckboxAndLabel from '@ui/checkbox/CheckboxAndLabel'
import {FormikSelect, LoadingBoundary, MenuItem} from '@ui/components'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import FieldGroup from '@ui/field-group/FieldGroup'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import SwitchToggle from '@ui/toggle-switch/SwitchToggle'
import {push} from 'connected-react-router'
import {Form, Formik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import type {IUserBase} from '~/src/models'
import type {EntityAccess, EntityAccessList, MemberAccess} from '~/src/models/memberAccess'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes/constants'
import {useGetAgenciesQuery} from '~/src/store/apis/agency-api'
import {
  useCreateMemberAccessMutation,
  useLazyGetMemberAccessByMemberIdQuery,
  useUpdateMemberAccessMutation,
} from '~/src/store/apis/member-access-api'
import {useAddGeneralAdminMutation, useUpdateGeneralAdminMutation} from '~/src/store/apis/user-api'
import {useAppDispatch} from '~/src/store/store-hooks'
import Title from '../../layout/Title'

const OutterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 900px;
  padding: 0px 20px 20px 20px;
`

const LowerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 1110px;
  height: 615px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
`

const Body = styled(Box)`
  display: flex;
  justify-contend: space-evenly;
  height: 100%;
  background: #fff;
  margin: 50px;
`

const LeftSide = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  border-right: 1px solid #e0e0e0;
  padding-right: 50px;
`

const RightSide = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  padding-left: 50px;
`

const Footer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  border-top: 1px solid #e0e0e0;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  margin-left: 50px;
  margin-right: 50px;
`

const StyledButton = styled(Button)`
  width: 120;
  font-weight: normal;
`

const BorderBottom = styled('div')`
  border-bottom: 1px solid #e0e0e0;
  margin-top: 24px;
  margin-bottom: 24px;
`

const SelectAgencyText = styled('div')`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
`

const SelectAgencyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 412px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
`

const Overlay = styled('div')<{$visible: boolean}>`
  position: absolute;
  display: ${(props) => (props.$visible ? 'block' : 'none')};
  width: 429px;
  height: 411px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 2;
`

const SelectAgencyHeader = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 42px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  background-color: rgba(217, 217, 217, 0.1);
`

const SelectAgencyInnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #fff;
`

const DuplicatEmailErrorMessage = styled('div')`
  font-weight: 400;
  font-size: 0.6875rem;
  color: #ff6760;
  text-align: right;
`

const Red = styled('span')(
  ({theme}) => css`
    color: ${theme.palette.error.main};
  `,
)

interface IFormikValues {
  firstName: string
  lastName: string
  email: string
  role: string
}

type TCheckboxData = {
  isChecked: boolean
  agencyId: string
}

export default function AddOrEditGa(): React.ReactElement {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [pathLast] = location.pathname.split('/').slice(-1)
  const user = location.state as IUserBase | undefined
  const isAdd = pathLast === 'add-admin'
  const [addGeneralAdmin] = useAddGeneralAdminMutation()
  const [updateGeneralAdmin, {isError: isErrorUpdateGa}] = useUpdateGeneralAdminMutation()
  const [createMemberAccess] = useCreateMemberAccessMutation()
  const [updateMemberAccess] = useUpdateMemberAccessMutation()
  const [getMemberAccessByMemberId] = useLazyGetMemberAccessByMemberIdQuery()

  const initialFormValues: IFormikValues = {
    firstName: isAdd ? '' : (user?.firstName as string),
    lastName: isAdd ? '' : (user?.lastName as string),
    email: isAdd ? '' : (user?.emailAddress as string),
    role: 'general_admin',
  }

  const {
    data: allAgencies,
    isLoading,
    isSuccess,
  } = useGetAgenciesQuery({
    pageNumber: 1,
    pageSize: 999999999,
  })

  const [checkboxData, setCheckboxData] = useState<TCheckboxData[] | undefined>(undefined)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [toggleAllAccess, setToggleAllAccess] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showDuplicateEmailError, setShowDuplicateEmailError] = useState(false)
  const [memberAccessData, setMemberAccessData] = useState<MemberAccess | undefined>(undefined)

  const buildData: TCheckboxData[] | undefined = useMemo(() => {
    if (isAdd) {
      return allAgencies?.agencies.map((agency) => {
        return {isChecked: false, agencyId: agency.id} as TCheckboxData
      })
    } else if (
      // The user has All Access
      memberAccessData?.entityAccessList.length === 1 &&
      memberAccessData.entityAccessList[0]?.allAccess
    ) {
      setToggleAllAccess(true)
      return allAgencies?.agencies.map((agency) => {
        return {isChecked: true, agencyId: agency.id} as TCheckboxData
      })
    } else {
      const checkedIds = memberAccessData?.entityAccessList.map((item) => item.entityId)
      return allAgencies?.agencies.map((agency) => {
        return {isChecked: checkedIds?.includes(agency.id), agencyId: agency.id} as TCheckboxData
      })
    }
  }, [allAgencies?.agencies, isAdd, memberAccessData])

  useEffect(() => {
    const insideQuery = async () => {
      if (!isAdd) {
        setMemberAccessData(await getMemberAccessByMemberId(user?.id as string).unwrap())
      }
    }
    void insideQuery()
  }, [getMemberAccessByMemberId, isAdd, user?.id])

  useEffect(() => {
    setCheckboxData(buildData)
  }, [buildData])

  const makeEntityAccessList = (id: string): EntityAccessList => {
    const retVal = {
      memberId: id,
      entityAccessList: [],
    } as EntityAccessList

    if (toggleAllAccess) {
      retVal.entityAccessList = [
        {
          role: 'general_admin',
          entityType: 'Agency',
          allAccess: true,
        },
      ]
    } else if (checkboxData) {
      retVal.entityAccessList = checkboxData
        .filter((datum) => datum.isChecked)
        .map((item) => {
          return {
            role: 'general_admin',
            entityType: 'Agency',
            entityId: item.agencyId,
          } as EntityAccess
        })
    }
    return retVal
  }

  const handleSubmit = async (values: IFormikValues) => {
    const isAnyChecked = checkboxData
      ?.map((datum) => datum.isChecked)
      .reduce((prev: boolean, curr: boolean) => prev || curr, false)
    if (isAnyChecked || toggleAllAccess) {
      if (isAdd) {
        const newAdmin = await addGeneralAdmin({
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.email,
          roles: [values.role],
        })
          .unwrap()
          .catch((error) => {
            if (error.data.message.search(/already exists/i) !== -1) {
              setShowDuplicateEmailError(true)
            }
          })
        if (!newAdmin) return
        void createMemberAccess(makeEntityAccessList(newAdmin.id))
      } else {
        const updatedAdmin = await updateGeneralAdmin({
          id: user?.id as string,
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.email,
          roles: [values.role],
          allAccess: toggleAllAccess,
          status: user?.status,
        })
          .unwrap()
          .catch((error) => {
            if (error.data.message.search(/duplicate/i) !== -1) {
              setShowDuplicateEmailError(true)
            }
          })
        if (!updatedAdmin) return
        void updateMemberAccess(makeEntityAccessList(user?.id as string))
      }
      dispatch(
        push(
          `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_ADMINS}`,
        ),
      )
    } else {
      setIsOpen(true)
    }
  }

  const setAllCheckboxesTrue = () => {
    setCheckboxData(checkboxData?.map((datum) => ({...datum, isChecked: true})))
  }

  const setAllCheckboxesFalse = () => {
    setCheckboxData(checkboxData?.map((datum) => ({...datum, isChecked: false})))
  }

  const title = isAdd ? 'Add New User' : 'Edit User'

  return (
    <OutterContainer>
      <BasicSuccessDialog
        isOpen={isOpen}
        header="Please Select an Agency"
        bigBlurb="Please Select an Agency"
        smallBlurb="General administrator users requires the selection of at least one or more agency(s)."
        confirmButtonLabel="OK"
        isIconVisible={false}
        handleSubmit={() => {
          setIsOpen(false)
        }}
      />
      <Title pageTitle={title} sx={{height: '23px', marginBottom: '15px'}} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          email: Yup.string().email('Email address is invalid.').required('Required'),
          role: Yup.string().required('Required'),
        })}>
        {({isSubmitting, setFieldValue, values, handleChange, errors, getFieldMeta}) => {
          return (
            <Form>
              <LowerContainer>
                <Body>
                  <LeftSide>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '24px',
                      }}>
                      <FieldGroup label="First Name" sx={{marginRight: '12px'}}>
                        <FormikTextField name="firstName" inputProps={{maxLength: 50}} />
                      </FieldGroup>
                      <FieldGroup label="Last Name" sx={{marginLeft: '12px'}}>
                        <FormikTextField name="lastName" inputProps={{maxLength: 50}} />
                      </FieldGroup>
                    </Box>
                    <FieldGroup label="Email" sx={{marginBottom: '24px'}}>
                      <FormikTextField
                        name="email"
                        inputProps={{maxLength: 50}}
                        onChange={(e) => {
                          setShowDuplicateEmailError(false)
                        }}
                      />
                      {showDuplicateEmailError && (
                        <DuplicatEmailErrorMessage>
                          This email is already in use
                        </DuplicatEmailErrorMessage>
                      )}
                    </FieldGroup>
                    <FieldGroup label="Role">
                      <FormikSelect defaultValue="general_admin" sx={{width: '100%'}} name="role">
                        <MenuItem value="general_admin">General Administrator</MenuItem>
                      </FormikSelect>
                    </FieldGroup>
                    <BorderBottom />
                    <FieldGroup label="Allow user access to all current and future added Agencies">
                      <SwitchToggle
                        checked={toggleAllAccess}
                        onChange={(event, checked) => {
                          setToggleAllAccess(checked)
                          if (checked) {
                            setAllCheckboxesTrue()
                          } else {
                            setAllCheckboxesFalse()
                            setSelectAll(false)
                          }
                        }}
                      />
                    </FieldGroup>
                  </LeftSide>
                  <RightSide>
                    <SelectAgencyText>
                      Select Agency Access<Red>*</Red>
                    </SelectAgencyText>
                    <SelectAgencyContainer>
                      <Overlay $visible={toggleAllAccess} />
                      <SelectAgencyHeader>
                        <CheckboxAndLabel
                          label="Select All"
                          checked={selectAll}
                          onChange={() => {
                            if (!toggleAllAccess) {
                              if (selectAll) {
                                setSelectAll(!selectAll) // Changing from true to false
                                setAllCheckboxesFalse()
                              } else {
                                setSelectAll(!selectAll) // Changing from false to true
                                setAllCheckboxesTrue()
                              }
                            }
                          }}
                          containerProps={{marginLeft: '4px', marginRight: '40px'}}
                          labelProps={{
                            fontSize: '12px',
                            fontWeight: 400,
                          }}
                        />
                      </SelectAgencyHeader>
                      <SelectAgencyInnerContainer>
                        <LoadingBoundary isLoading={isLoading}>
                          <TableContainer sx={{height: '360px'}}>
                            <Table aria-labelledby="tableTitle" size="medium" padding="normal">
                              {allAgencies?.agencies.map((agency, index) => {
                                return (
                                  <TableRow
                                    hover
                                    sx={{height: 45}}
                                    onChange={() => {
                                      if (!toggleAllAccess) {
                                        setCheckboxData(
                                          checkboxData?.map((datum, datumIndex) => {
                                            if (datumIndex === index) {
                                              if (datum.isChecked) {
                                                setSelectAll(false)
                                              }
                                              return {...datum, isChecked: !datum.isChecked}
                                            } else {
                                              return datum
                                            }
                                          }),
                                        )
                                      }
                                    }}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={agency.id}
                                    selected={
                                      checkboxData ? checkboxData[index]?.isChecked : false
                                    }>
                                    <CheckboxAndLabel
                                      label={agency.name}
                                      checked={
                                        checkboxData?.[index]
                                          ? checkboxData[index]?.isChecked
                                          : false
                                      }
                                      containerProps={{marginLeft: '4px'}}
                                      labelProps={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                      }}
                                    />
                                  </TableRow>
                                )
                              })}
                            </Table>
                          </TableContainer>
                        </LoadingBoundary>
                      </SelectAgencyInnerContainer>
                    </SelectAgencyContainer>
                  </RightSide>
                </Body>
                <Footer>
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    sx={{mr: '20px'}}
                    onClick={() => {
                      dispatch(
                        push(
                          `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_ADMINS}`,
                        ),
                      )
                    }}>
                    Cancel
                  </StyledButton>
                  <StyledButton variant="contained" color="primary" type="submit">
                    {isAdd ? 'Add User' : 'Update User'}
                  </StyledButton>
                </Footer>
              </LowerContainer>
            </Form>
          )
        }}
      </Formik>
    </OutterContainer>
  )
}
