import styled, {css} from 'styled-components'
import {font} from '~/src/config/theme'

const Admin = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 60px);
    margin-top: 90px;
  `,
  Main: styled.div(
    ({isCompact}: {isCompact: boolean}) => css`
      display: flex;
      min-width: 1111px;
      justify-content: center;
      flex-direction: column;
      transition: all 400ms ease;
    `,
  ),
  Title: styled.h4`
    font-size: ${font.sizes.h4};
    font-weight: 400;
    line-height: 33px;
  `,
}

export default Admin
