import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EllipsisIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 14 4' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M1.99992 0.333328C1.08325 0.333328 0.333252 1.08333 0.333252 1.99999C0.333252 2.91666 1.08325 3.66666 1.99992 3.66666C2.91658 3.66666 3.66658 2.91666 3.66658 1.99999C3.66658 1.08333 2.91658 0.333328 1.99992 0.333328ZM11.9999 0.333328C11.0833 0.333328 10.3333 1.08333 10.3333 1.99999C10.3333 2.91666 11.0833 3.66666 11.9999 3.66666C12.9166 3.66666 13.6666 2.91666 13.6666 1.99999C13.6666 1.08333 12.9166 0.333328 11.9999 0.333328ZM6.99992 0.333328C6.08325 0.333328 5.33325 1.08333 5.33325 1.99999C5.33325 2.91666 6.08325 3.66666 6.99992 3.66666C7.91658 3.66666 8.66658 2.91666 8.66658 1.99999C8.66658 1.08333 7.91658 0.333328 6.99992 0.333328Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
