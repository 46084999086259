import {useMemo} from 'react'
import {assert} from '../utils/assert'
import {isNullOrUndefined} from '../utils/type-validators'
import {useAppSelector} from './store-hooks'

export function usePartialUser() {
  const {partialUser} = useAppSelector((state) => state.root)
  return useMemo(() => ({partialUser}), [partialUser])
}

export function useCurrentUser() {
  const {currentUser} = useAppSelector((state) => state.root)
  return useMemo(() => ({currentUser}), [currentUser])
}

// Only use this for children of the PrivateRoute component.
// We don't have the assert the user in the both the route
// and display component.
export function useConcreteUser() {
  const {currentUser} = useAppSelector((state) => state.root)
  assert(!isNullOrUndefined(currentUser), `This should never be nullish`)
  return useMemo(() => ({currentUser}), [currentUser])
}
