import {createSlice} from '@reduxjs/toolkit'
import type {TRootState} from '..'
import type {Applicant, ApplicantsResponse} from './../../models/applicant'
import {applicantsApi} from './../apis/applicants-api'

export interface IApplicantsState {
  applicant: Applicant | undefined
  allApplicants: ApplicantsResponse | undefined
}
const initialState: IApplicantsState = {
  applicant: undefined,
  allApplicants: undefined,
}

const applicantSlice = createSlice({
  name: 'applicantsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(applicantsApi.endpoints.getApplicant.matchFulfilled, (state, {payload}) => {
      state.applicant = payload
    }).addMatcher(applicantsApi.endpoints.getApplicants.matchFulfilled, (state, {payload}) => {
      state.allApplicants = payload
    })
  },
})

export const selectApplicantsState = (state: TRootState): TRootState['applicants'] =>
  state.applicants
export default applicantSlice.reducer
