import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import type {TRootState} from '../'
import type {IInput} from '../../components/common/typings'
import type {IDepartment} from '../../models'
import {isEmpty} from '../../utils'
import {errorMessages} from '../../utils/error-messages'
import {companyApi} from '../apis/company-api'

export interface IDepartmentState {
  formIsValid: boolean
  inProgress: boolean
  departments: IDepartment[]

  inputs: {
    name: IInput
    manager: IInput
    parentName: IInput
    description: IInput
    subDeptName?: IInput
  }
}

const initialState: IDepartmentState = {
  formIsValid: false,
  inProgress: false,
  departments: [],
  inputs: {
    name: {value: '', isError: false, errorMsg: null, touched: false},
    parentName: {value: '', isError: false, touched: false},
    manager: {value: '', isError: false, touched: false},
    description: {value: '', isError: false, touched: false},
    subDeptName: {value: '', isError: false, touched: false},
  },
}

const departmentSlice = createSlice({
  name: 'departmentSlice',
  initialState,
  reducers: {
    initializeInputs: (state, {payload}) => {
      state.inputs.name = {
        ...initialState.inputs.name,
        ...(payload.name && {value: payload.name}),
      }
      state.inputs.manager = {
        ...initialState.inputs.manager,
        ...(payload.manager && {value: payload.manager}),
      }
      state.inputs.description = {
        ...initialState.inputs.description,
        ...(payload.description && {value: payload.description}),
      }
      state.inputs.parentName = {
        ...initialState.inputs.parentName,
        ...(payload.parentName && {value: payload.parentName}),
      }
    },
    updateDeptInput: (
      state,
      {payload}: PayloadAction<{name: keyof IDepartmentState['inputs']; value: string}>,
    ) => {
      state.inputs[payload.name] = {
        touched: true,
        value: payload.value,
        isError: isEmpty(payload.value),
      }
    },
    updateNameInput: (state, {payload}) => {
      const blankInput = {
        isError: isEmpty(payload.value),
        errorMsg: errorMessages.required,
      }
      const alreadyExists = {
        isError:
          payload.value !== payload.targetName &&
          state.departments.map((d) => d.name).includes(payload.value),
        errorMsg: errorMessages.alreadyTaken,
      }

      state.inputs.name = {
        touched: true,
        value: payload.value,
        isError: blankInput.isError || alreadyExists.isError,
        errorMsg: blankInput.isError
          ? blankInput.errorMsg
          : alreadyExists.isError
          ? alreadyExists.errorMsg
          : null,
      }
    },
    updateSubDeptNameInput: (state, {payload}) => {
      const alreadyExists = {
        isError: state.departments.map((d) => d.name).includes(payload.value),
        errorMsg: errorMessages.alreadyTaken,
      }

      state.inputs.subDeptName = {
        touched: true,
        value: payload.value,
        isError: alreadyExists.isError,
        errorMsg: alreadyExists.isError ? alreadyExists.errorMsg : null,
      }
    },
    validateForm: (state) => {
      const {name, subDeptName, manager, description} = state.inputs
      state.formIsValid = [
        !name.isError,
        !manager.isError,
        !description.isError,
        !subDeptName?.isError,
      ].every(Boolean)

    },
    onUnload: (state) => {
      state.inputs = initialState.inputs
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(companyApi.endpoints.getDepartments.matchPending, (state) => {
      state.inProgress = true
    })
    builder.addMatcher(companyApi.endpoints.getDepartments.matchRejected, (state) => {
      state.inProgress = false
    })
    builder.addMatcher(companyApi.endpoints.getDepartments.matchFulfilled, (state, {payload}) => {
      state.departments = payload
      state.inProgress = false
    })
  },
})

export const {
  onUnload,
  initializeInputs,
  updateNameInput,
  updateDeptInput,
  updateSubDeptNameInput,
  validateForm,
} = departmentSlice.actions

export const selectDeptState = (state: TRootState): TRootState['department'] => state.department
export default departmentSlice.reducer
