import {styled} from '@mui/material/styles'
import TextField, {TextFieldProps} from '@mui/material/TextField'
import React from 'react'

const CustomizedTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    margin-bottom: 5px;
  }
  & .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
` as typeof TextField

export default function StyledTextField({children, ...props}: TextFieldProps) {
  return (
    <CustomizedTextField InputLabelProps={{style: {top: '-6px'}}} {...props}>
      {children}
    </CustomizedTextField>
  )
}
