import {Box, Button, SvgIcon} from '@mui/material'
import {css, styled, useTheme} from '@mui/material/styles'
import type {SerializedError} from '@reduxjs/toolkit'
import SignInContainer from '@ui/sign-in-container/SignInContainer'
import {push} from 'connected-react-router'
import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import {ReactComponent as CompanyIcon} from '~/src/assets/images/Group 968.svg'
import {ReactComponent as AgencyIcon} from '~/src/assets/images/Group 969.svg'
import {BASE_ROUTES} from '~/src/routes'
import {errorText} from '../../components/auth/constants/helper-text'
import {useAppDispatch} from '../../store/store-hooks'

const Title = styled('div')(
  () => css`
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
  `,
)

const SubTitle = styled('div')(
  ({theme}) => css`
    color: ${theme.palette.grey[600]};
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  `,
)

interface IPaperButtonContainer {
  enabled: boolean
  enableColor: string
}

const PaperButtonContainer = styled(Box)(
  ({theme}) => css`
    background-color: ${(props: IPaperButtonContainer) =>
      props.enabled ? props.enableColor : theme.palette.grey[50]};
    width: 131px;
    height: 113px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `,
)

interface ILabel {
  $enabled: boolean
}

const Label = styled('div')(
  ({theme}) => css`
    font-size: 15px;
    font-weight: 700;
    color: ${(props: ILabel) => (props.$enabled ? theme.palette.grey[50] : '#383838')};
  `,
)

interface IProps {
  svg: React.ElementType<unknown>
  enableColor: string
  iconStyles?: React.CSSProperties
  enabled: boolean
  label: string
  inheritViewBox?: boolean
  viewBox?: string
  callback: () => void
}

export function PaperIconButton({
  svg,
  enableColor,
  iconStyles,
  enabled,
  label,
  callback,
  ...rest
}: IProps) {
  return (
    <PaperButtonContainer enabled={enabled} enableColor={enableColor} onClick={() => callback()}>
      <SvgIcon
        component={svg}
        sx={{fill: enabled ? 'white' : enableColor, marginTop: '20px', ...iconStyles}}
        {...rest}
      />
      <Label $enabled={enabled} style={{marginBottom: '15px'}}>
        {label}
      </Label>
    </PaperButtonContainer>
  )
}

const routeMap = {
  platformadmin: BASE_ROUTES.PLATFORM_ADMIN,
  companyadmin: BASE_ROUTES.COMPANY_ADMIN,
  agencyadmin: BASE_ROUTES.AGENCY_ADMIN,
  agency: BASE_ROUTES.AGENCY,
} as const

const defaultError: SerializedError = {
  message: errorText.serverError,
}

export default function CreateAccountPortal() {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [registerCompany, setRegisterCompany] = useState(false)
  const [registerAgency, setRegisterAgency] = useState(false)
  const [continueButtonSelected, setContinueButtonSelected] = useState(false)

  const handleCompanyClick = () => {
    if (!registerCompany) {
      if (registerAgency) {
        setRegisterAgency(!registerAgency)
      }
      setRegisterCompany(!registerCompany)
    }
    setContinueButtonSelected(true)
  }

  const handleAgencyClick = () => {
    if (!registerAgency) {
      if (registerCompany) {
        setRegisterCompany(!registerCompany)
      }
      setRegisterAgency(!registerAgency)
    }
    setContinueButtonSelected(true)
  }

  return (
    <SignInContainer
      customHeader='Welcome Back'
      customFooter={
        <NavLink to={BASE_ROUTES.SIGNIN}>
          <span>
            Already have an account? <u>Sign in</u>
          </span>
        </NavLink>
      }>
      <Title>Create New Account</Title>
      <SubTitle>I want to register as a:</SubTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '75px',
          paddingBottom: '30px',
          justifyContent: 'space-between',
        }}>
        <PaperIconButton
          svg={CompanyIcon}
          enableColor={theme.palette.primary.main}
          iconStyles={{fontSize: '45px'}}
          inheritViewBox
          enabled={registerCompany}
          label="Company"
          callback={handleCompanyClick}
        />
        <PaperIconButton
          svg={AgencyIcon}
          enableColor={theme.palette.primary.main}
          iconStyles={{fontSize: '45px'}}
          viewBox="0 0 55 55"
          enabled={registerAgency}
          label="Agency"
          callback={handleAgencyClick}
        />
      </Box>
      <Button
        sx={{width: '100%', marginTop: '75px'}}
        disabled={!continueButtonSelected}
        variant={continueButtonSelected ? 'contained' : 'outlined'}
        onClick={() => {
          if (registerCompany) {
            dispatch(push(BASE_ROUTES.CREATE_COMPANY))
          } else {
            dispatch(push(BASE_ROUTES.CREATE_AGENCY))
          }
        }}>
        Continue
      </Button>
    </SignInContainer>
  )
}
