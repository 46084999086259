import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function DeleteIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='13'
			height='16'
			viewBox='0 0 13 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.44152 0.992295H8.05371V1.45458H8.99707V0.92758C8.99719 0.416132 8.60177 0 8.11572 0H5.37951C4.89347 0 4.49805 0.416132 4.49805 0.92758V1.45458H5.44152V0.992295Z'
				fill='currentColor'
			/>
			<path
				d='M10.8289 5.09058H1.91715C1.67296 5.09058 1.48073 5.30548 1.50041 5.55664L2.24545 15.0607C2.28697 15.5913 2.71595 15.9999 3.23133 15.9999H9.51458C10.03 15.9999 10.4589 15.5913 10.5005 15.0606L11.2455 5.55664C11.2653 5.30548 11.0731 5.09058 10.8289 5.09058V5.09058ZM4.075 14.9862C4.06505 14.9868 4.05509 14.9872 4.04525 14.9872C3.79638 14.9872 3.58747 14.7873 3.57199 14.5276L3.10509 6.72522C3.08901 6.45524 3.28808 6.22288 3.54967 6.20629C3.81042 6.18995 4.03661 6.39483 4.05269 6.66494L4.51946 14.4673C4.53566 14.7373 4.33659 14.9695 4.075 14.9862V14.9862ZM6.85299 14.4975C6.85299 14.7678 6.64048 14.9871 6.37829 14.9871C6.11611 14.9871 5.9036 14.7678 5.9036 14.4975V6.69502C5.9036 6.42454 6.11611 6.2053 6.37829 6.2053C6.64036 6.2053 6.85299 6.42454 6.85299 6.69502V14.4975ZM9.64093 6.72386L9.19516 14.5262C9.1804 14.7864 8.97113 14.9871 8.72178 14.9871C8.71242 14.9871 8.70294 14.9868 8.69346 14.9863C8.43175 14.9704 8.23208 14.7386 8.24756 14.4686L8.69322 6.66617C8.70858 6.39619 8.93249 6.1902 9.19492 6.20617C9.45662 6.22202 9.65629 6.45387 9.64093 6.72386V6.72386Z'
				fill='currentColor'
			/>
			<path
				d='M12.726 3.75964L12.4012 2.66491C12.3156 2.37632 12.0753 2.18164 11.8046 2.18164H0.942451C0.671924 2.18164 0.431526 2.37632 0.346017 2.66491L0.0212353 3.75964C-0.0413959 3.97077 0.0401121 4.18612 0.192252 4.29351C0.254258 4.33723 0.32764 4.36351 0.408273 4.36351H12.339C12.4196 4.36351 12.4931 4.33723 12.555 4.29337C12.7071 4.18598 12.7886 3.97063 12.726 3.75964Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
