import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {formatShift} from '@utils'
import React from 'react'
import type {IAddress, IDepartment} from '~/src/models'
import type {IPosition} from '~/src/models/position'
import {StyledAllCheckbox} from '../../../ui/styled-checkbox/StyledAllCheckbox'
import {StyledCheckbox} from '../../../ui/styled-checkbox/StyledCheckbox'

interface IProps {
  onModalClose(): void
  setFieldValue: (field: string, value: unknown) => void
  modalOpen: boolean
  onSelectUpdate: (id: string) => void
  filteredData: IPosition[]
  companyDepartments: IDepartment[]
  companyAddresses: IAddress[]
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: true,
    label: 'Department',
    align: 'left',
  },
  {
    id: 'facility',
    numeric: false,
    disablePadding: true,
    label: 'Facility',
    align: 'left',
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: true,
    label: 'Manager',
    align: 'left',
  },
]

function EnhancedTableHead({numSelected}: {numSelected: number}) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <StyledAllCheckbox disabled indeterminate={numSelected > 0} />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function SelectPositionDialog({
  modalOpen,
  onModalClose,
  onSelectUpdate,
  filteredData,
}: IProps): React.ReactElement {
  function handleModalClose() {
    onModalClose()
  }

  const [selected, setSelected] = React.useState<string>()

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    onSelectUpdate(id)
    setSelected(id)
    onModalClose()
  }

  const isSelected = (id: string) => selected === id

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={handleModalClose}
      PaperProps={{
        sx: {
          minWidth: 1000,
        },
      }}>
      <div
        style={{
          marginLeft: 40,
          paddingTop: 40,
          fontSize: '18px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
        }}>
        Please Select A Position:
      </div>
      <Box
        sx={{
          m: 5,
          backgroundColor: '#FFFFFF',
          border: '1px solid #CDCED9',
          borderRadius: '4px',
        }}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            border: '1px solid #CDCED9',
            borderRadius: '4px',
          }}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" padding="normal">
              <EnhancedTableHead numSelected={selected ? 1 : 0} />
              <TableBody>
                {filteredData.map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}>
                      <StyledTableCell padding="checkbox" align="left">
                        <StyledCheckbox
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{maxWidth: '200px'}}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                        {formatShift(row)}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.departmentName}</StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{maxWidth: '200px'}}>
                        {row.facilityName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.manager}</StyledTableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Dialog>
  )
}
