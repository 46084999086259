import {
  combineReducers,
  configureStore,
  EnhancedStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import {agencyApi} from './apis/agency-api'
import {applicantsApi} from './apis/applicants-api'
import {authApi} from './apis/auth-api'
import {companyApi} from './apis/company-api'
import {dataCleanupApi} from './apis/data-cleanup-api'
import {documentsApi} from './apis/document-api'
import {jobOrderApi} from './apis/job-order-api'
import {memberAccessApi} from './apis/member-access-api'
import {positionApi} from './apis/position-api'
import {prefillApi} from './apis/prefill-api'
import {reqTemplatesApi} from './apis/req-template-api'
import {roleApi} from './apis/role-api'
import {serviceOrderApi} from './apis/service-order-api'
import {userApi} from './apis/user-api'
import agencyReducer from './slices/agency-slice'
import applicantsReducer from './slices/applicants-slice'
import calendarReducer from './slices/calendar-slice'
import departmentReducer from './slices/department-slice'
import jobOrderReducer from './slices/job-order-slice'
import prefillReducer from './slices/prefill-slice'
import reqTemplateReducer from './slices/req-template-slice'
import rootReducer from './slices/root-slice'
import serviceOrderReducer from './slices/service-order-slice'

export const history = createBrowserHistory()

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['loggedInAgencyId', 'role'],
}

const agencyPersistConfig = {
  key: 'agency',
  storage: storage,
  whitelist: ['agencyNamesAndIds'],
}

const prefillPersistConfig = {
  key: 'prefill',
  storage: storage,
  whitelist: ['prefillToEdit'],
}

// The combined reducer should be named "root" by convension but we already have a slice named root above;
// that one should probably be renamed to "user"
const combinedPersistConfig = {
  key: 'combined',
  storage,
  whitelist: ['root', 'agency'],
}

const combinedReducers = combineReducers({
  router: connectRouter(history),
  calendar: calendarReducer,
  department: departmentReducer,
  jobOrder: jobOrderReducer,
  prefill: persistReducer(prefillPersistConfig, prefillReducer),
  reqTemplate: reqTemplateReducer,
  root: persistReducer(rootPersistConfig, rootReducer),
  serviceOrder: serviceOrderReducer,
  applicants: applicantsReducer,
  agency: persistReducer(agencyPersistConfig, agencyReducer),

  [agencyApi.reducerPath]: agencyApi.reducer,
  [agencyApi.reducerPath]: agencyApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [jobOrderApi.reducerPath]: jobOrderApi.reducer,
  [positionApi.reducerPath]: positionApi.reducer,
  [applicantsApi.reducerPath]: applicantsApi.reducer,
  [reqTemplatesApi.reducerPath]: reqTemplatesApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [memberAccessApi.reducerPath]: memberAccessApi.reducer,
  [serviceOrderApi.reducerPath]: serviceOrderApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [dataCleanupApi.reducerPath]: dataCleanupApi.reducer,
  [prefillApi.reducerPath]: prefillApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
})

const persistedReducer = persistReducer(combinedPersistConfig, combinedReducers)

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // Investigate why this is happening
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isRejectedWithValue(action)) {
    console.warn('REJECTED ACTION: ', JSON.stringify(action.error.data))
  }

  return next(action)
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // This blocks redux-persist from issuing an console error saying we're trying to serialze something
      // that isn't serializable; this should be investigated further as it appears everything
      // we're persisting is serializable.
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      thunk,
      routerMiddleware(history),
      rtkQueryErrorLogger,
      agencyApi.middleware,
      authApi.middleware,
      companyApi.middleware,
      jobOrderApi.middleware,
      prefillApi.middleware,
      positionApi.middleware,
      applicantsApi.middleware,
      prefillApi.middleware,
      dataCleanupApi.middleware,
      reqTemplatesApi.middleware,
      roleApi.middleware,
      serviceOrderApi.middleware,
      userApi.middleware,
      memberAccessApi.middleware,
      documentsApi.middleware,
    )
  },
})

export const persistor = persistStore(store)
export type TRootState = ReturnType<typeof combinedReducers>
export type TAppStore = EnhancedStore
export type TAppDispatch = TAppStore['dispatch']
