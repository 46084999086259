import {nanoid} from 'nanoid/non-secure'
import React from 'react'
import {useParams} from 'react-router-dom'
import {useFindCompaniesAssignedToMspQuery} from '~/src/store/apis/company-api'
import {useGetServiceOrderTemplatesQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import TemplateForm from './TemplateForm'

export const createTemplateItem = () => ({
  id: nanoid(),
  orderType: '',
  positionId: '',
  payRate: '',
  note: undefined,
})
interface UrlParams {
  currPageIndex: number
}

export default function AddTemplate(): React.ReactElement {
  const currPage: UrlParams = useParams()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data: companyId} = useFindCompaniesAssignedToMspQuery(loggedInAgencyId)
  const {data} = useGetServiceOrderTemplatesQuery({
    pageNumber: currPage.currPageIndex,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })
  const initialValues: any = {
    name: '',
    orders: [createTemplateItem()],
    companyId: '',
  }
  return (
    <>
      <Title pageTitle="New Template" />
      <TemplateForm
        initialValues={initialValues}
        companyId={companyId}
        isEdit={false}
        data={data}
        index={0}
      />
    </>
  )
}
