import {FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import Icon from '@ui/icons'
import {FormikYesNo} from '@ui/radio/FormikYesNo'
import FormikTextField from '@ui/text-field/StandardFormikField'
import React from 'react'
import type {IAgencyBase, ICompanyBase} from '~/src/models'
import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import {Grid} from '@mui/material'

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const CloseButton = styled(Mui.IconButton)({
  display: 'none',
  height: 28,
})

const ButtonContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  right: -28,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 28,

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
})
const HoverButton = styled('div')({
  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
})

const Field = styled(Mui.Grid)({
  position: 'relative',
})

const TextField = styled(Mui.TextField)({
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '-1.2rem',
    right: '0',
  },
})

interface IProps {
  activePlatform: string
  index: number
  remove: (index: number) => void
  setFieldValue: (field: string, value: unknown) => void
  addresses: string | number | boolean | null
  initialEditValues: IAgencyBase | ICompanyBase | undefined
}

export default function CompanyAddressSignInItem(props: IProps) {
  const {index, remove, setFieldValue, addresses} = props
  const testId = `company-address-index-${index}`

  return (
    <>
      <HoverButton>
        <Grid container spacing={3} key={index} data-testid={testId}>
          <Field item xs={5}>
            <Grid item xs paddingBottom={2}>
              Location Name<Red>*</Red>:
            </Grid>
            <FormikTextField name={`addresses.${index}.name`} placeholder="Headquarters" />
          </Field>
          <Grid item xs={1} />

          <Field item xs>
            <Grid item xs paddingBottom={2}>
              Location Address<Red>*</Red>:
            </Grid>
            <FormikGooglePlacesAutocomplete
              name={`addresses.${index}.address`}
              initialEditValues={props.initialEditValues}
              setFieldValue={setFieldValue}
              value={addresses}
              address={false}
            />
            {index !== 0 && (
              <ButtonContainer>
                <CloseButton color="error" onClick={() => remove(index)}>
                  <Icon icon="close" />
                </CloseButton>
              </ButtonContainer>
            )}
          </Field>
        </Grid>
        <Grid container>
          <Field item xs>
            <Grid item xs paddingBottom={2} paddingTop={2}>
              Onsite Facility<Red>*</Red>:
            </Grid>
            <div>Is this location an On-Site Facility?</div>
            <FormikYesNo name={`addresses.${index}.isOnsite`} />
          </Field>
        </Grid>
      </HoverButton>
    </>
  )
}
