import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import React from 'react'
import {ReactComponent as PlusIcon} from '~/src/assets/images/Group 25.svg'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 274px;
    width: 260px;
    border-radius: 16px;
    border: 1px solid ${theme.palette.grey[300]};
    padding: 15px;
    margin-top: 30px;
    cursor: pointer;
  `,
) as typeof Box

interface IProps {
  callback?: (
    isSuccess: boolean,
    res: {id: string; totalApplicants: number; applicantIds: number},
    isNormalAdd?: boolean,
  ) => void
  sx?: object
}

export default function AddDataCleanupCard({
  callback = (
    isSuccess: boolean,
    res: {id: string; totalApplicants: number; applicantIds: number},
    isNormalAdd?: boolean,
  ) => {},
  sx,
}: IProps) {
  return (
    <Container
      sx={{...sx}}
      onClick={() => {
        callback(true, {id: '', totalApplicants: 0, applicantIds: 0}, true)
      }}>
      <PlusIcon />
    </Container>
  )
}
