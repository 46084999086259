import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import {styled} from '@mui/material/styles'

const CustomizedMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: #383838;
  }

  &.Mui-disabled {
    color: 'black';
  }
`

export function EllipsisMenuItem({...props}) {
  return <CustomizedMenuItem {...props} />
}
