import AddIcon from '@ui/icons/Add'
import Mui from '@ui/mui'
import {FieldArray, FormikValues} from 'formik'
import React, {useState} from 'react'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'
import AddTemplateItem from './AddTemplateItem'
import {createTemplateItem} from './TemplateForm'

interface IProps {
  initialValues?: IInitialServiceOrderValues
  values?: FormikValues
  handleChange: string | number | boolean | object | null
  onOpenOrderModal(): void
  modalOpen: boolean
  currentPageNumber: number
  setFieldValue: (field: string, value: unknown) => void
  errors: string | number | boolean | object | null
  index: number | undefined
}

export default function AddTemplateOrder({
  initialValues,
  values,
  index,
  setFieldValue,
  handleChange,
  onOpenOrderModal,

  errors,
}: IProps): React.ReactElement {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [orderModalVisible, setOrderModalVisible] = useState(false)

  return (
    <>
      <FieldArray name="orders">
        {({push, remove}) => (
          <React.Fragment>
            {values.orders.map((_orders: {id: string}, index: number) => {
              return (
                <>
                  <AddTemplateItem
                    index={index}
                    handleChange={handleChange}
                    values={values}
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                    onModalClose={() => {
                      setOrderModalVisible(false)
                    }}
                    remove={remove}
                    onOpenOrderModal={onOpenOrderModal}
                    currentPageNumber={currPageIdx}
                    modalOpen={false}
                    errors={errors}
                  />
                </>
              )
            })}
          </React.Fragment>
        )}
      </FieldArray>
    </>
  )
}
