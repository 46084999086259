import Box from '@mui/material/Box'
import ServiceOrderCardMsp from '@ui/card/ServiceOrderCardMsp'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../../layout/Title'

const MspAgencyJobOrderMainContainer = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  Spacing: styled.div`
    margin-bottom: 20px;
    margin-right: 20px;
  `,
}

export default function NonMspAgencyPendingOrders(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: currPageIdx,
    pageSize: 12,
    filterBy: 'PendingOrders',
    agencyId: loggedInAgencyId,
  })

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])
  const totalOrders = data?.totalServiceOrders

  return (
    <MspAgencyJobOrderMainContainer.Container>
      <Title pageTitle="Pending Service Orders" />
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: 1200,
              borderRadius: 1,
            }}>
            {data?.serviceOrdersModel.map((serviceOrder: IServiceOrder, index: number) => {
              const orderStatusForMsp = serviceOrder.orders.map((i) =>
                i.orderDistribution.agencyDistributions?.filter(
                  (t) => t.agencyId === loggedInAgencyId,
                ),
              )[0]

              const agencyStatus = orderStatusForMsp[0]?.agencyStatus
              return (
                <MspAgencyJobOrderMainContainer.Spacing key={index}>
                  <ServiceOrderCardMsp
                    serviceOrder={serviceOrder}
                    currentUserAgencyId={loggedInAgencyId}
                    currentServiceOrderPageNumber={1}
                    agencyStatus={agencyStatus}
                    isdashboard={true}
                    key={index}
                  />
                </MspAgencyJobOrderMainContainer.Spacing>
              )
            })}
          </Box>
          <StyledPagination
            page={currPageIdx}
            totalRecords={totalOrders}
            recordsPerPage={12}
            onChange={handlePaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </MspAgencyJobOrderMainContainer.Container>
  )
}
