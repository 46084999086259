import React from 'react'
import S from '@ui/button/styles/button.styles'
import type {IButtonProps} from '@ui/button/typings'

export function Button({display, children, variant, ...props}: IButtonProps) {
  return (
    <S.Container {...props} variant={variant}>
      {children ?? display}
    </S.Container>
  )
}

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
}
