export interface IJobOrder {
  date: Date
  id: string
  // used to specifiy specific id in endpoints but same as job order id
  jobOrderId?: string
  orderNumber: string
  serviceOrderId: string
  positionId: string
  mspId: string
  status: string
  agencyDistributions: AgencyDistribution[]
  versionKey: number
  createdDate: Date
  modifiedDate: Date
  createdBy: string
  modifiedBy: string
}
// question marks is fixing the red lines in MSPJobOrdersDetails because
// i had to create a custom object
export interface JobOrderCreate {
  serviceOrderId?: string | undefined
  positionId?: string | undefined
  date?: Date | undefined
  agencyDistributions: JobOrderCreateAgencyDistribution
  totalPledged?: number
  totalRequested?: number
  requested?: number
  agencyId?: string
}

export interface JobOrderCreateAgencyDistribution {
  //this stops the red line in job order details main agency distribution requested
  //if i define the any as unknown or never or anything else it causes a red line in values.agencyDistribution.map function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  agencyId: string | undefined
  agencyCandidateDistributions: {
    requested: number | undefined
  }
}
export interface AgencyDistribution {
  agencyId: string
  agencyStatus: string
  agencyCandidateDistributions: AgencyCandidateDistribution
}

export interface AgencyCandidateDistribution {
  fulfilled: number
  requested: number
  candidateList: Candidate[]
}

export type Candidate = {
  agencyId?: string
  candidateId: string
  candidateStatus: string
  contactCellPhone?: string
  contactEmail: string
  contactHomePhone?: string
  firstName: string
  lastName: string
  middleName?: string
  statusModifiedBy: string
  statusModifiedDate: string
  modifiedBy?: string
  modifiedDate?: Date
  endDate?: Date
  endDateUpdatedBy?: string
  ssn?: string
  profilePictureName?: string
}

export interface IInitialJobOrderValues {
  id: string
  orderNumber: string
  serviceOrderId: string
  positionId: string
  mspId: string
  status: string
  agencyDistributions: [
    {
      agencyId: string
      agencyCandidateDistributions: [
        {
          date: string
          requested: 0
          candidateList: [
            {
              candidateId: string
              candidateStatus: string
            },
          ]
        },
      ]
    },
  ]
  versionKey: number
  createdDate: Date
  modifiedDate: Date
  createdBy: string
  modifiedBy: string
}

export type TJobOrderAddCandidateRequest = {
  jobOrderId: string
  candidateId: string
  candidateStatus: string
  notes: string
  agencyId?: string
}

export type TJobOrderPayload = {
  jobOrders: IJobOrder[]
  totalJobOrders: number
}

export type TPagedJobOrderInputParams = {
  pageNumber: number
  pageSize: number
  id?: string
  serviceOrderId?: string
  filterBy?: 'CurrentOrders' | 'CompletedOrders'
  agencyId?: string
}

export type TJobOrderBatchedResponse = {
  totalJobOrders: string
  jobOrders: IJobOrder[]
}

export enum DeclineCandidateReason {
  SKILLS = 'Does not meet Job Criteria - Skills',
  LICENSE_OR_CERTIFICATION = 'Does not meet Job Criteria - License/Certification',
  AVAILABILITY = 'Does not meet Job Criteria - Availability',
  WORKING_ELSEWHERE = 'Candidate Declined Offer - Working Elsewhere',
  NOT_INTERESTED = 'Candidate Declined Offer - Not Interested',
}

export enum SkipCandidateReason {
  NO_ANSWER = 'Candidate did not answer call',
  REQUESTED_CALLBACK = 'Candidate requested a future call back',
  PROFILE_OUTDATED = 'Candidate profile not up-to-date',
}
