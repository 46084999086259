import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import {styled} from '@mui/material/styles'
import FormikAutocomplete, {IFormikAutocompleteProps} from '@ui/autocomplete/FormikAutocomplete'
import FormikAutocompleteMultiple from '@ui/autocomplete/FormikAutocompleteMultiple'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import CheckboxUnchecked from '@ui/icons/CheckboxUnchecked'
import FormikTextField, {IFormikTextFieldProps} from '@ui/mui-text-field/FormikTextField'
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import type {ApplicantInterview, TCompanyPositionEmpType} from '~/src/models/applicant'
import {useGetAllCompanyNamesAndIdsQuery} from '~/src/store/apis/company-api'

export const SmallBold = styled('div')`
  font-size: 14px;
  font-weight: 600;
`

export const VertBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const HorizBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const HorizBoxSpaceBetween = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const GreyTitle = styled('div')`
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  color: #707070;
`

export const Answer = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`

const Red = styled('span')(({theme}) => ({
  color: '#FF6760',
}))

export type TInterviewTypes = 'general_labor' | 'clerical' | 'restaurant'
export type TViewMode = 'start' | TInterviewTypes

export const employmentTypesLabelMap = new Map<string, string>([
  ['full-time', 'Full Time'],
  ['part-time', 'Part Time'],
  ['seasonal', 'Seasonal'],
  ['weekend-only', 'Weekend Only'],
])

export const TopData = ({title, data, sx}: {title: string; data: string; sx?: Object}) => {
  return (
    <VertBox sx={sx}>
      <GreyTitle style={{marginBottom: '3px'}}>{title}</GreyTitle>
      <Answer>{data}</Answer>
    </VertBox>
  )
}

export interface ITopTextFieldProps {
  title: string
  name: string
  sx?: Object
}

export type TNameAndId = {name: string; id: string}

export interface ITopTextMultiSelect<T> {
  title: string
  name: string
  options: T[]
  getOptionLabel?: (option: string | T) => string
  isOptionEqualToValue?: (option: T, value: T) => boolean
  onClose?: () => void
  sx?: Object
}

const TopTextMultiSelect = <T,>({
  title,
  name,
  options,
  getOptionLabel = (option) => option as string,
  isOptionEqualToValue,
  onClose = () => {},
  sx,
}: ITopTextMultiSelect<T>) => {
  return (
    <VertBox sx={sx}>
      <GreyTitle style={{marginBottom: '3px'}}>
        {title}
        <Red> *</Red>
      </GreyTitle>
      <FormikAutocompleteMultiple<T>
        limitTags={0}
        name={name}
        multiple
        disableCloseOnSelect
        disableClearable
        options={options}
        getOptionLabel={getOptionLabel}
        placeholder="Select"
        sx={{width: '210px'}}
        isOptionEqualToValue={isOptionEqualToValue}
        onClose={() => {
          onClose()
        }}
        renderOption={({style, ...rest}, option, {selected}) => {
          return (
            <li {...rest} style={{...style, padding: '0px'}}>
              <Checkbox
                icon={<CheckboxUnchecked />}
                checkedIcon={<CheckboxChecked />}
                style={{marginRight: 8}}
                checked={selected}
              />
              {getOptionLabel(option)}
            </li>
          )
        }}
        renderTags={(tagValue, getTagProps) => (
          <div style={{marginLeft: '15px', color: 'darkgray'}}>{`${tagValue.length} Selected`}</div>
        )}
        // renderInput is defined here to prevent a typescript error but isn't actually being used;
        // the real renderInput is defined in FormikAutocompletMultiple; refactor in the future
        renderInput={(params) => {
          return <TextField {...params} />
        }}
      />
    </VertBox>
  )
}

export const BigBold = styled('div')`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`

type IQAndADropdownProps = {
  question: string
  component: ReactElement<IFormikAutocompleteProps>
  sx?: Object
}

export const QAndADropdown = React.memo(function QAndADropdown({
  question,
  component,
  sx,
}: IQAndADropdownProps) {
  return (
    <VertBox sx={sx}>
      <SmallBold style={{marginBottom: '3px'}}>{question}</SmallBold>
      {component}
    </VertBox>
  )
})

type IQAndATextAreaProps = {
  question: string
  component: ReactElement<IFormikTextFieldProps>
  isRequired?: boolean
  sx?: Object
}

export const QAndATextArea = React.memo(function QAndATextArea({
  question,
  component,
  isRequired = false,
  sx,
}: IQAndATextAreaProps) {
  return (
    <VertBox sx={sx}>
      <SmallBold style={{marginBottom: '3px'}}>
        {question}
        {isRequired && <Red> *</Red>}
      </SmallBold>
      {component}
    </VertBox>
  )
})

export const InformationCardContainer = styled(Box)`
  width: 780px;
  max-width: 780px;
  margin-top: 30px;
  min-height: 100px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

export const BoldText = styled('div')`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
`

export const StyledButton = styled(Button)`
  height: 40px;
  width: 200;
  font-weight: normal;
` as typeof Button

export const NewInterviewBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 40px;
  border-bottom: 1px solid #e0e0e0;
`

export const QuestionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 40px;
`

export const GreySubTitle = styled('div')`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #707070;
`

export const NewInterview = ({
  name,
  type,
  setValue,
  selectedInterview,
  valuesSubset,
}: {
  name: string
  type: TInterviewTypes
  setValue: (field: string, value: unknown) => void
  selectedInterview?: ApplicantInterview
  valuesSubset: TCompanyPositionEmpType
}) => {
  const [sortedCompanyOptions, setSortedCompanyOptions] = useState<TNameAndId[]>([])
  const [sortedPositionOptions, setSortedPositionOptions] = useState<string[]>([])
  const [sortedEmpTypeOptions, setSortedEmpTypeOptions] = useState<TNameAndId[]>([])
  const {data: companyNamesAndIds} = useGetAllCompanyNamesAndIdsQuery({
    pageNumber: 1,
    pageSize: 99999,
  })

  const empTypeOptions = useMemo(() => {
    return [
      {id: 'full-time', name: 'Full Time'},
      {id: 'part-time', name: 'Part Time'},
      {id: 'seasonal', name: 'Seasonal'},
      {id: 'weekend-only', name: 'Weekend Only'},
    ]
  }, [])

  const restaurantPositions = useMemo(() => {
    return [
      'Server',
      'Line Cook',
      'Prep Cook',
      'Bartender',
      'Busboy',
      'Dishwasher',
      'Cashier',
      'Hostess',
      'Manager',
      'Assistant Manager',
      'Shift Lead',
    ]
  }, [])

  const generalPositions = useMemo(() => {
    return [
      'Sanitation',
      'Qualify Control Food Manufacturing',
      'Quality Control Garment',
      'Food production packer',
      'Food production assembly line',
      'Forklift operator',
      'Warehouse Labor',
      'Shipping Clerk',
      'Receiving Clerk',
      'Janitorial',
      'Machine Operator',
      'Maintenance Mechanic',
    ]
  }, [])

  const clericalPositions = useMemo(() => {
    return [
      'Accountant',
      'Human Resources',
      'Administrative assistant',
      'Data Entry',
      'Sales Representative',
      'Manger',
      'IT',
      'Safety Manger',
    ]
  }, [])

  const assignPositionOptions = useCallback(
    (interviewType: TInterviewTypes): string[] => {
      switch (interviewType) {
        case 'restaurant':
          return restaurantPositions
        case 'general_labor':
          return generalPositions
        case 'clerical':
          return clericalPositions
        default:
          console.error(
            'Invalid interview type; valid interview types ' +
              'are [restaurant | general_labor | clerical]',
          )
          return []
      }
    },
    [clericalPositions, generalPositions, restaurantPositions],
  )

  const sortCompanyOptions = useCallback(() => {
    setSortedCompanyOptions(
      sortedCompanyOptions.toSorted((a, b) => {
        if (
          valuesSubset.company.map((item) => item.id).includes(a.id) &&
          !valuesSubset.company.map((item) => item.id).includes(b.id)
        ) {
          return -1
        } else if (
          valuesSubset.company.map((item) => item.id).includes(b.id) &&
          !valuesSubset.company.map((item) => item.id).includes(a.id)
        ) {
          return 1
        }
        return 0
      }) ?? [],
    )
  }, [sortedCompanyOptions, valuesSubset.company])

  const sortPositionOptions = useCallback(() => {
    setSortedPositionOptions(
      sortedPositionOptions.toSorted((a, b) => {
        if (valuesSubset.position.includes(a) && !valuesSubset.position.includes(b)) {
          return -1
        } else if (valuesSubset.position.includes(b) && !valuesSubset.position.includes(a)) {
          return 1
        }
        return 0
      }) ?? [],
    )
  }, [sortedPositionOptions, valuesSubset.position])

  const sortEmpTypeOptions = useCallback(() => {
    setSortedEmpTypeOptions(
      sortedEmpTypeOptions.toSorted((a, b) => {
        if (
          valuesSubset.employmentType.map((item) => item.id).includes(a.id) &&
          !valuesSubset.employmentType.map((item) => item.id).includes(b.id)
        ) {
          return -1
        } else if (
          valuesSubset.employmentType.map((item) => item.id).includes(b.id) &&
          !valuesSubset.employmentType.map((item) => item.id).includes(a.id)
        ) {
          return 1
        }
        return 0
      }) ?? [],
    )
  }, [sortedEmpTypeOptions, valuesSubset.employmentType])

  useEffect(() => {
    const initFunc = async () => {
      const reconstructedEmpTypes = selectedInterview?.employmentType.map((empType) => {
        return {id: empType, name: employmentTypesLabelMap.get(empType) ?? ''}
      })
      setValue('employmentType', reconstructedEmpTypes)
      setSortedEmpTypeOptions(
        empTypeOptions.toSorted((a, b) => {
          if (
            selectedInterview?.employmentType.includes(a.id) &&
            !selectedInterview.employmentType.includes(b.id)
          ) {
            return -1
          } else if (
            selectedInterview?.employmentType.includes(b.id) &&
            !selectedInterview.employmentType.includes(a.id)
          ) {
            return 1
          }
          return 0
        }),
      )
      setSortedPositionOptions(
        assignPositionOptions(type).toSorted((a, b) => {
          if (selectedInterview?.position.includes(a) && !selectedInterview.position.includes(b)) {
            return -1
          } else if (
            selectedInterview?.position.includes(b) &&
            !selectedInterview.position.includes(a)
          ) {
            return 1
          }
          return 0
        }),
      )
      const reconstructedCompanyValues = selectedInterview?.company.map((companyId) => {
        return {
          id: companyId,
          name: companyNamesAndIds?.find((datum) => datum.id === companyId)?.name ?? '',
        }
      })
      setSortedCompanyOptions(
        companyNamesAndIds?.toSorted((a, b) => {
          if (
            selectedInterview?.company.includes(a.id) &&
            !selectedInterview.company.includes(b.id)
          ) {
            return -1
          } else if (
            selectedInterview?.company.includes(b.id) &&
            !selectedInterview.company.includes(a.id)
          ) {
            return 1
          }
          return 0
        }) ?? [],
      )
      setValue('company', reconstructedCompanyValues)
    }
    if (selectedInterview) {
      void initFunc()
    } else {
      setSortedCompanyOptions(companyNamesAndIds ?? [])
      setSortedPositionOptions(assignPositionOptions(type))
      setSortedEmpTypeOptions(empTypeOptions)
    }
  }, [assignPositionOptions, companyNamesAndIds, empTypeOptions, selectedInterview, setValue, type])

  return (
    <NewInterviewBox>
      <SmallBold style={{textAlign: 'center'}}>New Interview</SmallBold>
      <HorizBox sx={{marginTop: '10px'}}>
        <TopData title="Interviewer" data={name} />
        <TopData
          title="Date"
          data={
            selectedInterview === undefined
              ? new Date().toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
              : new Date(selectedInterview.createdDate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
          }
          sx={{marginLeft: '50px'}}
        />
      </HorizBox>
      <HorizBoxSpaceBetween sx={{marginTop: '20px'}}>
        <TopTextMultiSelect<TNameAndId>
          title="Company(s)"
          name="company"
          options={sortedCompanyOptions}
          isOptionEqualToValue={(option: TNameAndId, value: TNameAndId) => {
            return option.id === value.id
          }}
          getOptionLabel={(option) => {
            const castOption = option as unknown as TNameAndId
            return castOption.name
          }}
          onClose={sortCompanyOptions}
        />
        <TopTextMultiSelect<string>
          title="Position(s)"
          name="position"
          options={sortedPositionOptions}
          onClose={sortPositionOptions}
        />
        <TopTextMultiSelect<TNameAndId>
          title="Employment Type(s)"
          name="employmentType"
          options={sortedEmpTypeOptions}
          isOptionEqualToValue={(option: TNameAndId, value: TNameAndId) => {
            return option.id === value.id
          }}
          getOptionLabel={(option) => {
            const castOption = option as unknown as TNameAndId
            return castOption.name
          }}
          onClose={sortEmpTypeOptions}
        />
      </HorizBoxSpaceBetween>
    </NewInterviewBox>
  )
}

export const InitialQuestions = () => {
  return (
    <>
      <HorizBoxSpaceBetween>
        <QAndADropdown
          question="How did you hear about JobSource?"
          component={
            <FormikAutocomplete
              name="discovery"
              options={[
                'Client Referral',
                'Company Website',
                'Craigslist',
                'Indeed',
                'Facebook',
                'Flyer',
                'Google',
                'Instagram',
                'Internal Referral',
                'Job Fair',
                'Linkedin',
                'Walk In',
              ]}
              placeholder="Select"
              sx={{width: '330px'}}
            />
          }
        />
        <QAndADropdown
          question="Are you currently employed?"
          component={
            <FormikAutocomplete
              name="employed"
              disableClearable
              options={['Yes', 'No']}
              placeholder="Select"
              sx={{width: '330px'}}
            />
          }
        />
      </HorizBoxSpaceBetween>
      <QAndADropdown
        question="What did you dislike from your previous job?"
        component={
          <FormikAutocomplete
            name="dislike"
            options={[
              'Work Environment',
              'Unsatisfactory Pay Rate',
              'Job Duties',
              'Co Workers',
              'Managers/Supervisors',
              'Work Schedule conflict',
              'Distance',
              'Insufficient training',
              'No growth',
            ]}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndADropdown
        question="What did you like about your previous job?"
        component={
          <FormikAutocomplete
            name="like"
            options={[
              'Job Duties',
              'Managers/Supervisors',
              'Pay Rate',
              'Distance',
              'Co Workers',
              'Thorough Training',
              'Bonus/Incentive Program',
            ]}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndATextArea
        question="What are some job duties you've done in the past that we didn't discuss?"
        component={
          <FormikTextField name="extraDuties" multiline rows={3} inputProps={{maxLength: 1000}} />
        }
        sx={{marginTop: '20px'}}
      />
    </>
  )
}

export const AvailabilityQuestions = () => {
  return (
    <>
      <QAndATextArea
        question="What is the minimum wage accepted for employment?"
        component={<FormikTextField name="wage" />}
      />
      <QAndADropdown
        question="Are you open to flexible schedule? Working days, nights, weekends and overtime?"
        component={
          <FormikAutocomplete
            name="flexible"
            disableClearable
            options={['Yes', 'No']}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndADropdown
        question="If company requires it, are you willing to take a drug test and or background screening?"
        component={
          <FormikAutocomplete
            name="drugTest"
            disableClearable
            options={['Yes', 'No']}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
    </>
  )
}

export const BehavioralQuestionsSubset = () => {
  return (
    <>
      <QAndADropdown
        question="Is it ok to report an outside injury as a work-related injury?"
        component={
          <FormikAutocomplete
            name="outsideInjury"
            disableClearable
            options={['Yes', 'No']}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
      />
      <QAndADropdown
        question="If you are hurt at work who would you report it to?"
        component={
          <FormikAutocomplete
            name="reportInjuryWho"
            options={['Supervisor', 'Safety Department', 'Employee', 'Human Resources', 'No One']}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndADropdown
        question="How soon would you report a work related injury?"
        component={
          <FormikAutocomplete
            name="reportInjuryWhen"
            options={['Immediately', 'Next Day', 'After work', 'After I feel better']}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndADropdown
        question="If someone dropped a liquid or object on the floor without you noticing what would you do?"
        component={
          <FormikAutocomplete
            name="reportLiquid"
            options={[
              'Notify management',
              'Let the person that dropped it know',
              'Notify safety',
              'Do not say anything',
            ]}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndADropdown
        question="If you see a coworker breaking a safety policy what would you do?"
        component={
          <FormikAutocomplete
            name="reportPolicyBreaking"
            options={[
              'Do not say anything',
              'Let my co worker know',
              'Notify HR',
              'Notify Management',
              'Notify Safety Department',
            ]}
            placeholder="Select"
            sx={{width: '100%'}}
          />
        }
        sx={{marginTop: '20px'}}
      />
      <QAndATextArea
        question="How would you handle a difficult situation with a coworker or supervisor?"
        component={
          <FormikTextField
            name="difficultCoworker"
            multiline
            rows={3}
            inputProps={{maxLength: 1000}}
          />
        }
        sx={{marginTop: '20px'}}
      />
    </>
  )
}
