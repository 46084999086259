import {Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/material/styles'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import type {IAgencyBase} from '~/src/models'
import type {JobOrderCreate} from '~/src/models/jobOrder'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {useGetAgencyNamesByIdsQuery} from '~/src/store/apis/agency-api'
import {StyledTableCell} from '../../../ui/table-cell/StyledTableCell'

const TableContainerBox = styled(Box)(({theme}) => ({
  width: '100%',
  mb: 10,
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey[200]}`,
}))

type OrderDetailsTableProps = {
  allAgencies?: IAgencyBase[]
  currentAgency?: IAgencyBase
  companyName?: string
  numAgenciesLabel: string
  totalNeeded: number
  pledged: number
  position: string
  requested: number
  shift: string
  setFieldValue(field: string, value: string | boolean, shouldValidate?: boolean | undefined): void
  totalJobOrderRequested: number
  totalPledged: number
  totalRequested: number
  date: Date | string
  values: JobOrderCreate
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
  sortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'empty',
    numeric: false,
    disablePadding: true,
    label: '     ',
    align: 'center',
  },

  {
    id: 'needed',
    numeric: true,
    disablePadding: true,
    label: 'Needed',
    align: 'left',
  },
  {
    id: 'requested',
    numeric: true,
    disablePadding: true,
    label: 'Requested',
    align: 'left',
  },
  {
    id: 'pledged',
    numeric: true,
    disablePadding: true,
    label: 'Pledged',
    align: 'left',
  },
  {
    id: 'jobOrder',
    numeric: true,
    disablePadding: true,
    label: 'Job Order',
    align: 'left',
  },

  {
    id: 'empty',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'empty',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'agency',
    numeric: false,
    disablePadding: true,
    label: 'Agency',
    align: 'center',
  },
]

function PositionRow(props: string | number | boolean | object | any): JSX.Element {
  const theme = useTheme()

  const {
    data,
    isLoading: agencyIsLoading,
    error,
    refetch: agencyRefetch,
  } = useGetAgencyNamesByIdsQuery([props?.agencyId])

  useEffect(() => {
    void agencyRefetch()
  }, [agencyRefetch])

  return (
    <TableRow>
      <StyledTableCell />
      <StyledTableCell component="td" scope="row" padding="normal" sx={{maxWidth: '200px'}} />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell>{props?.totalRequested}</StyledTableCell>
      <StyledTableCell> {props?.totalPledged}</StyledTableCell>
      <StyledTableCell align="left">
        <FormikTextField
          type="number"
          value={
            props.values.agencyDistributions.props?.index.requested === 0
              ? ''
              : props.values.agencyDistributions[props?.index].requested
          }
          onChange={(e) => {
            if (e.target.value > props?.totalPledged) {
              return props?.setFieldValue(
                `agencyDistributions.${props?.index}.requested`,
                props?.totalPledged,
              )
            } else {
              return props?.setFieldValue(
                `agencyDistributions.${props?.index}.requested`,
                parseInt(e.target.value, 10),
              )
            }
          }}
          sx={{
            marginTop: 1,
            maxWidth: 50,
            width: 50,
            height: 15,
            maxHeight: 15,
            paddingBottom: 5,
            marginLeft: -2,
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          inputProps={{
            style: {
              max: props?.totalPledged,
              paddingTop: 5,
              paddingBottom: 5,
            },
          }}
          name={`agencyDistributions.${props?.index}.requested`}
        />
      </StyledTableCell>
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell align="center">{data ? data[0]?.name : ''} </StyledTableCell>
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
    </TableRow>
  )
}

export default function MspJobOrdersDetailsDistributionTable({
  date,
  numAgenciesLabel,
  pledged,
  position,
  requested,
  setFieldValue,
  shift,
  totalJobOrderRequested,
  totalNeeded,
  totalPledged,
  totalRequested,
  values,
}: OrderDetailsTableProps) {
  return (
    <>
      <TableContainerBox>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size="medium" padding="normal">
            <TableHead style={{borderBottom: '1px solid #CDCED9'}}>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="left" padding="normal" style={{borderBottom: 'none'}}>
                  {position}
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell align="left" padding="normal" style={{borderBottom: 'none'}}>
                  {shift}
                </StyledTableCell>

                {headCells.map((headCell: HeadCell, index: number) => (
                  <StyledTableCell
                    key={index}
                    align={headCell.align}
                    padding="none"
                    style={{borderBottom: 'none'}}>
                    {headCell.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {/* header */}
              <>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    {date}
                  </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell>{totalNeeded}</StyledTableCell>
                  <StyledTableCell>{totalRequested}</StyledTableCell>
                  <StyledTableCell align="left"> {totalPledged}</StyledTableCell>
                  <StyledTableCell align="left">
                    {isNaN(totalJobOrderRequested) ? 0 : totalJobOrderRequested}
                  </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell align="center">{numAgenciesLabel} </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                </TableRow>
              </>

              {/* row */}
              {values.agencyDistributions
                ? values.agencyDistributions.map(
                    (valueRow: Partial<IServiceOrder>, index: number) => {
                      return (
                        <PositionRow
                          key={index}
                          agencyId={valueRow.agencyId}
                          index={index}
                          date={date}
                          totalPledged={valueRow.totalPledged}
                          totalRequested={valueRow.totalRequested}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )
                    },
                  )
                : undefined}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainerBox>
    </>
  )
}
