import type {
  TPagedPrefillInputParams,
  TPrefillPayload,
  IPrefill,
  TPrefillAddCandidatePayload,
  TPrefillBatchedPayload,
  TPagedPrefillBatchedInputParams,
  IPrefillInputParams,
  IUpdatePrefillInputParams,
} from '../../models/prefill'
import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQuery} from '../base-query'
import type {ISuccessMessage} from '~/src/models'

export const prefillApi = createApi({
  reducerPath: 'PrefillApi',
  tagTypes: ['Prefill'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPrefill: builder.query<IPrefill, TPagedPrefillInputParams>({
      providesTags: ['Prefill'],
      query: ({id, agencyId}) => ({
        url: `/api/prefill`,
        method: 'GET',
        params: {id, agencyId},
      }),
    }),
    updatePrefill: builder.mutation<ISuccessMessage, IUpdatePrefillInputParams>({
      invalidatesTags: ['Prefill'],
      query: ({agencyId, ...rest}) => ({
        url: `/api/prefill/`,
        method: 'PUT',
        body: rest,
        params: {agencyId},
      }),
    }),
    // nothing is using this endpoint in figma but good for future use
    updateFullPrefill: builder.query<TPrefillPayload, TPagedPrefillInputParams>({
      providesTags: ['Prefill'],
      query: (body) => ({
        url: `/api/prefill`,
        method: 'PUT',
        params: body,
      }),
    }),
    //void makes it so we can do () when calling query
    getPrefills: builder.query<IPrefill[], string | undefined>({
      providesTags: ['Prefill'],
      query: (agencyId) => ({
        url: `/api/prefill/getPrefills`,
        method: 'GET',
        params: {agencyId},
      }),
    }),

    getPrefillsByServiceOrderIds: builder.query<
      TPrefillBatchedPayload,
      TPagedPrefillBatchedInputParams
    >({
      providesTags: ['Prefill'],
      query: ({serviceOrderIds}) => ({
        url: `/api/prefill/getPrefillsByServiceOrderIds`,
        method: 'GET',
        params: {serviceOrderIds},
      }),
    }),

    createPrefill: builder.mutation<ISuccessMessage, IPrefillInputParams>({
      invalidatesTags: ['Prefill'],
      query: ({agencyId, ...rest}) => ({
        url: `/api/prefill/createPrefill`,
        method: 'POST',
        body: rest,
        params: {agencyId},
      }),
    }),

    deletePrefill: builder.mutation<ISuccessMessage, {id: string, agencyId?: string}>({
      invalidatesTags: ['Prefill'],
      query: ({id, agencyId}) => ({
        url: `/api/prefill/${id}`,
        method: 'DELETE',
        params: {agencyId},
      }),
    }),

    addCandidateToPrefill: builder.mutation<ISuccessMessage, TPrefillAddCandidatePayload>({
      invalidatesTags: ['Prefill'],
      query: ({candidateId, candidateStatus, prefillId, notes, agencyId}) => ({
        url: `/api/prefill/addCandidateToPrefill`,
        method: 'PUT',
        body: {candidateId, candidateStatus, notes},
        params: {preFillId: prefillId, agencyId},
      }),
    }),

    fillPrefill: builder.mutation<ISuccessMessage, {prefillId: string}>({
      invalidatesTags: ['Prefill'],
      query: ({prefillId}) => ({
        url: `/api/prefill/fillPrefill`,
        method: 'PUT',
        params: {prefillId},
      }),
    }),

    removeCandidateFromPrefill: builder.mutation<
      ISuccessMessage,
      {prefillId: string; candidateId: string, agencyId?: string}
    >({
      invalidatesTags: ['Prefill'],
      query: ({candidateId, prefillId, agencyId}) => ({
        url: `/api/prefill/removeCandidatesFromPreFill`,
        method: 'PUT',
        params: {preFillId: prefillId, agencyId},
        body: [candidateId],
      }),
    }),

    declineCandidateFromPrefill: builder.mutation<
      ISuccessMessage,
      {prefillId: string; candidateId: string; notes: string, agencyId?: string}
    >({
      invalidatesTags: ['Prefill'],
      query: ({candidateId, prefillId, notes, agencyId}) => ({
        url: `/api/prefill/declineCandidateForPrefill`,
        method: 'PUT',
        params: {preFillId: prefillId, agencyId},
        body: {candidateId, notes, candidateStatus: 'Declined'},
      }),
    }),

    skipCandidateFromPrefill: builder.mutation<
      ISuccessMessage,
      {prefillId: string; candidateId: string; notes: string, agencyId?: string}
    >({
      invalidatesTags: ['Prefill'],
      query: ({candidateId, prefillId, notes, agencyId}) => ({
        url: `/api/prefill/skipCandidateForPrefill`,
        method: 'PUT',
        params: {preFillId: prefillId, agencyId},
        body: {candidateId, notes, employeeStatus: 'Skipped'},
      }),
    }),
  }),
})

export const {
  useGetPrefillQuery,
  useGetPrefillsQuery,
  useUpdateFullPrefillQuery,
  useUpdatePrefillMutation,
  useLazyGetPrefillsQuery,
  useGetPrefillsByServiceOrderIdsQuery,
  useLazyGetPrefillsByServiceOrderIdsQuery,
  useCreatePrefillMutation,
  useDeletePrefillMutation,
  useAddCandidateToPrefillMutation,
  useFillPrefillMutation,
  useRemoveCandidateFromPrefillMutation,
  useDeclineCandidateFromPrefillMutation,
  useSkipCandidateFromPrefillMutation,
} = prefillApi
