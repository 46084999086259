import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import {push} from 'connected-react-router'
import {Formik, Form as FormikForm} from 'formik'
import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import type {IOrder} from '~/src/models/order'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {
  useCreateServiceOrderFromTemplateMutation,
  useCreateServiceOrderMutation,
  useUpdateServiceOrderMutation,
} from '~/src/store/apis/service-order-api'
import {updatePageNumber} from '~/src/store/slices/service-order-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import AddPositionOrder from '../../company/manage-orders/order-form/AddPositionOrder'

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

interface IProps {
  initialValues: Partial<IInitialServiceOrderValues>
  isEdit?: boolean
  onOpenOrderModal(): void
  index: number
  isTemplate?: boolean
  isMsp?: boolean
  isMspTemplateId?: string
}

interface IFormSubmitValues {
  orders: IOrder[]
}

function OrderForm({
  onOpenOrderModal,
  initialValues,
  isEdit = false,
  index,
  isTemplate,
  isMsp,
  isMspTemplateId,
}: IProps): React.ReactElement {
  const dispatch = useAppDispatch()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [open, setOpen] = useState(false)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updateServiceOrder, {error: updateServiceOrderError}] = useUpdateServiceOrderMutation()
  const ref = useRef(null)
  const [addServiceOrder, {error: serviceOrderError}] = useCreateServiceOrderMutation()
  const [addServiceOrderFromTemplate, {error: serviceOrderFromTemplateError}] =
    useCreateServiceOrderFromTemplateMutation()

  const initialFormValues = {
    ...initialValues,
  }

  const handleSubmit = async (formikValues: IFormSubmitValues) => {
    const values = JSON.parse(JSON.stringify(formikValues)) as unknown as IFormSubmitValues
    if (values.orders[index]?.orderDistribution.agencyDistributions) {
      delete values.orders[index]?.orderDistribution.agencyDistributions
    }

    values.orders.forEach((order: IOrder) => {
      if (
        order.distributionEndDate === '' ||
        order.distributionEndDate < order.distributionStartDate ||
        order.distributionEndDate === order.distributionStartDate
      ) {
        const newDate = new Date(order.distributionStartDate)
        const convertedStartDate = new Date(newDate.setHours(5, 0, 0)).toISOString()
        const newEndDate = new Date(order.distributionStartDate)
        const convertedEndDate = new Date(newEndDate.setHours(5, 0, 0)).toISOString()
        order.distributionStartDate = convertedStartDate
        order.distributionEndDate = convertedEndDate
        order.orderDistribution.orderQuantityDistributions = [
          {
            quantity: parseInt(order.quantity, 10),
            date: convertedStartDate,
          },
        ]
      } else {
        const newDate = new Date(order.distributionStartDate)
        const convertedStartDate = new Date(newDate.setHours(5, 0, 0)).toISOString()
        const newEndDate = new Date(order.distributionEndDate)
        const convertedEndDate = new Date(newEndDate.setHours(5, 0, 0)).toISOString()

        order.distributionStartDate = convertedStartDate
        order.distributionEndDate = convertedEndDate
        const newDistributions = order.orderDistribution.orderQuantityDistributions.map((da) => {
          const newIndividualDate = new Date(da.date)
          const convertedDateForEachDate = new Date(
            newIndividualDate.setHours(5, 0, 0),
          ).toISOString()
          return {
            quantity: da.quantity,
            date: convertedDateForEachDate,
          }
        })
        order.orderDistribution.orderQuantityDistributions = newDistributions
      }
    })
    if (isEdit && !isMsp) {
      const payload = await updateServiceOrder(values as unknown as IOrder[]).unwrap()

      dispatch(
        push(`/company-admin/manage-orders/${payload.id}`, {
          openSnackbar: true,
          orderNumber: payload.orderNumber,
          type: 'company',
        }),
      )
    } else if (isMsp) {
      void addServiceOrderFromTemplate({
        template: {
          templateId: isMspTemplateId,
          serviceOrderFromTemplatePosition: [
            {
              positionId: values.orders[0]?.positionId,
              distributionStartDate: values.orders[0]?.distributionStartDate,
              distributionEndDate: values.orders[0]?.distributionEndDate,
              payRate: values.orders[0]?.payRate,
              note: values.orders[0]?.note === '' ? undefined : values.orders[0]?.note,
              orderDistribution: values.orders[0]?.orderDistribution,
            },
          ],
        },
        agencyId: loggedInAgencyId,
      })
        .unwrap()
        .then((payload) =>
          dispatch(
            push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}/pending`, {
              openSnackbar: true,
              orderNumber: payload.orderNumber,
              type: 'agency',
            }),
          ),
        )
    } else {
      const payload = await addServiceOrder({
        ...values,
      }).unwrap()
      dispatch(updatePageNumber(1))
      dispatch(
        push(`/company-admin/manage-orders/${payload.id}`, {
          openSnackbar: true,
          orderNumber: payload.orderNumber,
          type: 'company',
          isCreated: true,
        }),
      )
    }
  }

  const handleCancel = () => {
    if (isMsp && isTemplate) {
      dispatch(push(`/agency-admin/manage-templates`))
    } else if (isMsp) {
      dispatch(push(`/agency-admin/manage-orders/${localStorage.getItem('orderId')}`))
    } else if (isTemplate) {
      dispatch(push(`/company-admin/manage-templates`))
    } else if (initialValues.id) {
      dispatch(push(`/company-admin/manage-orders/${initialValues.id}`))
    } else {
      dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`))
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        mb: 10,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 5,
        }}>
        <div
          style={{
            fontSize: '18px',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}>
          {isEdit && (
            <>
              Service Order Number:
              <span style={{color: '#03A9FC'}}>#{initialValues.orderNumber}</span>
            </>
          )}
        </div>
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        innerRef={ref}
        validationSchema={Yup.object({
          orders: Yup.array().of(
            Yup.object().shape(
              {
                orderType: Yup.string().required('Required'),
                positionId: Yup.string().required('Required'),
                payRate: Yup.string().required('Required'),
                distributionStartDate: Yup.string().required('Required').nullable(),
                distributionEndDate: Yup.string()
                  .when('distributionEndDate', (val, schema) => {
                    if (val?.length > 0) {
                      return Yup.string().required('Required')
                    } else {
                      return Yup.string().notRequired()
                    }
                  })
                  .nullable(),

                quantity: Yup.number().required('Required'),
                note: Yup.string(),
                orderDistribution: Yup.object({
                  orderQuantityDistributions: Yup.array().of(
                    Yup.object({
                      date: Yup.string(),
                      quantity: Yup.number(),
                    }),
                  ),
                }),
              },
              // @ts-expect-error error is because of formik and cant figure out what is causing double red lines
              ['distributionEndDate', 'distributionEndDate'],
            ),
          ),
        })}>
        {({isSubmitting, setFieldValue, values, handleChange, errors}) => {
          return (
            <FormikForm>
              {values.orders.length > 0 && (
                <AddPositionOrder
                  index={index}
                  isEdit={isEdit}
                  isMsp={isMsp}
                  initialValues={initialFormValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onOpenOrderModal={onOpenOrderModal}
                  currentPageNumber={currPageIdx}
                  modalOpen={false}
                  isTemplate={isTemplate}
                  formikRef={ref}
                />
              )}

              <Mui.Grid sx={{m: 5}} item xs mt={6} display="flex">
                <Grid item xs>
                  {open ? (
                    <>
                      <Button
                        onClick={handleCancel}
                        variant="outlined"
                        color="secondary"
                        disabled
                        sx={{mr: 'auto'}}>
                        Cancel
                      </Button>
                      <Button variant="contained" color="primary" disabled sx={{ml: 2}}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        data-testid="service-order-cancel"
                        variant="outlined"
                        color="secondary"
                        type="reset"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                        sx={{mr: 'auto'}}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{ml: 2}}>
                        Save
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid item xs />
              </Mui.Grid>
            </FormikForm>
          )
        }}
      </Formik>
    </Box>
  )
}

export default React.memo(OrderForm)
