import React from 'react'
import Checkbox, {CheckboxProps} from '@mui/material/Checkbox'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import CheckboxUnchecked from '@ui/icons/CheckboxUnchecked'
import FormControlLabel from '@mui/material/FormControlLabel'

function CustomCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckboxChecked />}
      icon={<CheckboxUnchecked />}
      {...props}
    />
  )
}

interface ICheckboxAndLabelProps {
  label: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => void
  labelProps?: object
  checkboxProps?: object
  containerProps?: object
}

export default function CheckboxAndLabel({
  label,
  checked = false,
  onChange = () => {},
  labelProps,
  checkboxProps,
  containerProps,
}: ICheckboxAndLabelProps) {
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          checked={checked}
          onChange={onChange}
          sx={{
            ...checkboxProps,
          }}
        />
      }
      label={label}
      sx={{
        ...containerProps,
        '.MuiFormControlLabel-label': {...labelProps},
      }}
    />
  )
}
