import ErrorBoundary from '@ui/error'
import HeaderContainer from '@ui/header-container/HeaderContainer'
import LoadingBoundary from '@ui/loader'
import React from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import PositionForm from '../../common/positions/PositionForm'
import Title from '../../layout/Title'

const Container = styled.div`
  position: relative;
  flex: 1;
`

export default function AddNewPositionPage() {
  const location = useLocation()

  interface IState {
    state: any
  }

  const locationState = location.state as IState

  const initialValues = {
    companyId: locationState?.state?.companyId,
    onsiteFacilityName: '',
    onsiteFacilityAddress: '',
    position: '',
    departmentName: '',
    workersCompCode: '',
    startTime: '',
    endTime: '',
    manager: '',
    jobDescription: undefined,
  }

  return (
    <Container>
      <Title pageTitle="Add New Position" leaveLast={true} />
      <ErrorBoundary>
        <LoadingBoundary isLoading={false}>
          <HeaderContainer header={<div style={{fontWeight: 'bold'}}>Add Position</div>}>
            <PositionForm initialValues={initialValues} isEdit={false} isMspAdmin={true} />
          </HeaderContainer>
        </LoadingBoundary>
      </ErrorBoundary>
    </Container>
  )
}
