import {nanoid} from 'nanoid/non-secure'

// These unique ids are arbitrary. Material UI's autocomplete keeps track of
// Formik field arrays using React keys, so we need to use ids instead of indices
// in order to remove the proper index when deleting a field.
// https://robinpokorny.medium.com/index-as-a-key-is-an-anti-pattern-e0349aece318
const createAddressItem = () => ({
  id: nanoid(),
  name: '',
  isOnsite: false,
  address: {
    line1: '',
    city: '',
    state: '',
    zipCode: '',
    display: '',
    location: {
      type: 'Point',
      coordinates: [
        ''
      ]
    }
  },
})

export default createAddressItem
