import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import React from 'react'

const StyledToggle = styled(Switch)(({theme}) => ({
  padding: 8,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#03A9FC',
        opacity: '1',
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#FFFFFF',
  },
})) as typeof Switch

export type TMode = 'center' | 'left'

const Container = styled(Box)<{mode: TMode}>(
  ({theme, mode}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    left: ${mode === 'left' ? '-8px' : '0px'};
  `,
)

interface IProps {
  checked?: boolean
  onLabel?: string
  offLabel?: string
  onChange?: (event: object, checked: boolean) => void
  mode?: TMode
  inputProps?: object
  name?: string
}

export default function SwitchToggle({
  checked = false,
  onLabel = 'On',
  offLabel = 'Off',
  mode = 'center',
  onChange = () => {},
  inputProps,
  name,
}: IProps) {
  return (
    <Container mode={mode}>
      {mode === 'center' && <Typography>{offLabel}</Typography>}
      <StyledToggle
        name={name}
        checked={checked}
        onChange={(event, isChecked) => {
          onChange(event, isChecked)
        }}
        {...inputProps}
      />
      {mode === 'center' && <Typography>{onLabel}</Typography>}
      {mode === 'left' && <Typography>{checked ? onLabel : offLabel}</Typography>}
    </Container>
  )
}
