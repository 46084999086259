import React from 'react'
import S from './styles/error.styles'

export function ErrorDisplay(error: {title?: string; message: string}) {
  return (
    <S.Container>
      {error.title}
      <S.Message>{JSON.stringify(error.message)}</S.Message>
    </S.Container>
  )
}
