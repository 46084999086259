export const sizes = {
  header: '60px',
  sidebar: '256px',
  sidebarCompact: '68px',
  maxWidth: '1111px',
}

export const nSizes = {
  maxWidth: 1111,
}
