import CallIcon from '@mui/icons-material/Call'
import CallEndIcon from '@mui/icons-material/CallEnd'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {Box, CircularProgress, IconButton, Tooltip} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import FlagDropdown from '@ui/dialog/FlagDropdown'
import UpdateCandidatePictureDialog from '@ui/dialog/UploadCandidatePictureDialog'
import {flagSwitch} from '@ui/flags/FlagSwitch'
import React, {useState} from 'react'
import {ReactComponent as AvatarEditIcon} from '~/src/assets/images/AvatarEditIcon.svg'
import type {TFlag} from '../../common/bucket/BucketList'
import type {TProcessedCandidate} from './CandidateList'
import {
  ActionsBox,
  CallIconWrapper,
  Header,
  HeaderText,
  Name,
  StyledButton,
  StyledSkipButton,
} from './styles/CandidateCardStyles'

interface CandidateCardProps {
  addCandidate?: (candidateStatus: string, candidate: TProcessedCandidate) => void
  cleanupCandidate?: () => Promise<void>
  candidate: TProcessedCandidate
  isCallActive: boolean
  onActivateCall: () => void
  isDataCleanup: boolean
  loading: boolean
  openSkipOrDeclineDialog: (skipOrDecline: 'skip' | 'decline') => void
  isCleanupDisabled: boolean
  disableCall: boolean
  setIsCallActive(value: boolean): void
  unlockApplicant: (val: {id: string; clientId: string}) => void
  clientId: string | undefined
}

const CandidateCard = ({
  candidate,
  loading,
  addCandidate,
  cleanupCandidate,
  openSkipOrDeclineDialog,
  isCallActive,
  onActivateCall,
  isDataCleanup,
  isCleanupDisabled,
  disableCall = false,
  setIsCallActive,
  unlockApplicant,
  clientId,
}: CandidateCardProps) => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false)

  const handleDialogOpen = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  const handleDialogClose = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  const [isFlagDropdownOpen, setIsFlagDropdownOpen] = useState<null | HTMLElement>(null)

  const open = Boolean(isFlagDropdownOpen)

  const handleClose = () => {
    if (isDataCleanup) {
      window.location.reload()
    } else {
      setIsFlagDropdownOpen(null)
    }
  }
  const {contactCellPhone, firstName, lastName, userStaffingRep} = candidate.candidate
  const {city, state, zipCode} = candidate.candidate.address

  const subHeader = (
    <ul>
      <Tooltip title={`${city}, ${state} ${zipCode}`}>
        <li
          style={{
            listStyle: 'none',
            color: '#424242',
            fontSize: '12px',
            fontWeight: 400,
            maxWidth: '150px',
            textWrap: 'nowrap',
            whiteSpace: 'no-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: '5px',
            marginTop: '5px',
          }}>{`${city}, ${state} ${zipCode}`}</li>
      </Tooltip>
      <Tooltip title={candidate.candidate.industryChoice}>
        <li
          style={{
            color: '#424242',
            fontSize: '12px',
            fontWeight: 400,
            maxWidth: '150px',
            textWrap: 'nowrap',
            whiteSpace: 'no-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          Industry: {candidate.candidate.industryChoice}
        </li>
      </Tooltip>
    </ul>
  )

  const phone = contactCellPhone
  const headerDisplay = isCallActive ? phone : 'Call Candidate'
  const name = `${firstName} ${lastName}`

  const handleAddCandidate = () => {
    if (addCandidate && !isDataCleanup) {
      unlockApplicant({id: candidate.candidate.id, clientId: clientId as string})
      addCandidate('Accepted', candidate)
      setIsCallActive(false)
    }
  }

  const handleOpenSkipModal = () => {
    openSkipOrDeclineDialog('skip')
  }

  const handleOpenDeclineModal = () => {
    openSkipOrDeclineDialog('decline')
  }

  const handleOpenFlagDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setIsFlagDropdownOpen(event.currentTarget)
  }

  const disabledAddOrCleanupButton = candidate.candidate.flag === 'Do Not Use'

  return (
    <Box
      sx={{
        display: 'inline-block',
        marginBottom: '16px',
        width: '100%',
        height: '220px',
      }}>
      <UpdateCandidatePictureDialog
        agencyId={candidate.candidate.agencyId}
        candidateId={candidate.candidate.id}
        candidateProfilePic={
          candidate.candidate.profilePictureName?.length > 0
            ? `${candidate.candidate.profilePictureName}?cache=${new Date().getTime()}`
            : undefined
        }
        isOpen={openUploadDialog}
        handleClose={handleDialogClose}
      />
      <FlagDropdown
        agencyId={candidate.candidate.agencyId}
        anchorEl={isFlagDropdownOpen}
        isOpen={open}
        handleClose={handleClose}
        candidateId={candidate.candidate.id}
      />
      <Header>
        <HeaderText>{headerDisplay}</HeaderText>
        <CallIconWrapper
          aria-label="call"
          size="small"
          $isCallActive={isCallActive}
          disabled={(candidate.candidate.lockStatus && !isCallActive) || disableCall}
          onClick={onActivateCall}>
          {loading ? <CircularProgress /> : null}
          {isCallActive && !loading ? (
            <CallEndIcon fontSize="medium" />
          ) : (
            <CallIcon fontSize="medium" />
          )}
        </CallIconWrapper>
        <IconButton
          aria-label="open-flag-menu"
          data-testid="open-flag-menu"
          onClick={handleOpenFlagDropdown}
          size="small"
          sx={{color: 'white'}}>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
      </Header>
      <Box
        sx={{
          display: 'block',
          border: '1px solid #8A8A8A',
          borderRadius: '0 0 15px 15px',
          padding: '10px 12px',
          minHeight: '166px',
          maxHeight: '166px',
        }}>
        <div style={{display: 'flex', marginBottom: '28px', minHeight: '78px', maxHeight: '78px'}}>
          <div style={{marginRight: '15px'}}>
            <Badge
              sx={{cursor: 'pointer'}}
              onClick={handleDialogOpen}
              overlap="circular"
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              badgeContent={<AvatarEditIcon alt="edit-profile-icon" />}>
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                badgeContent={flagSwitch(candidate.candidate.flag as TFlag, true, false)}>
                <Avatar
                  sx={{
                    backgroundColor: '#03a9fc',
                    border: '2px solid #03A9FC',
                    paddingBottom: 0,
                    height: 66,
                    width: 66,
                  }}
                  aria-label="candidate icon"
                  src={`${candidate.candidate.profilePictureName}?cache=${new Date().getTime()}`}
                />
              </Badge>
            </Badge>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Name>{name}</Name>
            {subHeader}
          </div>
        </div>
        <Box sx={{paddingTop: 0, paddingBottom: 0}}>
          {isDataCleanup ? (
            <ActionsBox>
              <StyledSkipButton
                variant="contained"
                onClick={cleanupCandidate}
                disabled={!isCallActive || isCleanupDisabled}>
                Clean Up
              </StyledSkipButton>
              <StyledSkipButton
                variant="outlined"
                color="secondary"
                onClick={handleOpenSkipModal}
                disabled={!isCallActive}>
                Skip
              </StyledSkipButton>
            </ActionsBox>
          ) : (
            <ActionsBox>
              <StyledButton
                variant="contained"
                onClick={handleAddCandidate}
                disabled={!isCallActive || disabledAddOrCleanupButton}>
                Add
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="secondary"
                disabled={!isCallActive}
                onClick={handleOpenSkipModal}>
                Skip
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="secondary"
                disabled={!isCallActive}
                onClick={handleOpenDeclineModal}>
                Decline
              </StyledButton>
            </ActionsBox>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CandidateCard
