import {Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import {push} from 'connected-react-router'
import React, {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {ReactComponent as FormatListBulletedIcon} from '~/src/assets/images/FormatListIcon.svg'
import {ReactComponent as SearchIcon} from '~/src/assets/images/SearchIcon.svg'
import {BASE_ROUTES} from '~/src/routes/constants'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    height: 274px;
    width: 260px;
    border-radius: 16px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
  `,
) as typeof Box

const FirstRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const Ratio = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
) as typeof Box

const BigNumbers = styled('div')<{isBlue?: boolean; isSelected?: boolean}>(
  ({theme, isBlue = false, isSelected}) => css`
    font: normal 700 36px 'Open Sans';
    color: ${isSelected ? 'white' : isBlue ? theme.palette.primary.main : theme.palette.grey[700]};
  `,
)

const SecondRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const DemoText = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 700 18px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
    margin-top: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
)

const FilterText = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 600 14px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
  `,
)

const ThirdRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const SmallVertContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
) as typeof Box

const SmallLabel = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 400 12px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
  `,
)

const SmallValue = styled('div')<{isBlue?: boolean; isSelected?: boolean}>(
  ({theme, isBlue = false, isSelected}) => css`
    font: normal 700 20px 'Open Sans';
    color: ${isSelected ? 'white' : isBlue ? theme.palette.primary.main : theme.palette.grey[700]};
  `,
)

const SmallHorizContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  `,
) as typeof Box

const FourthRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 10px;
  `,
) as typeof Box

const IconLeftContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  `,
) as typeof Box

const IconRightContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
  `,
) as typeof Box

type TLink = 'search' | 'list'

interface IProps {
  callback?: () => void
  cardId: string | number
  denominator: number
  filter: number
  isInDataCleanup: boolean
  isSelected?: boolean
  isMsp: boolean
  numerator: number
  onToggleInventory: (value: boolean) => void
  sx?: object
  title: string
  activeLink?: TLink
  setActiveLink?: (link: TLink) => void
}

export default function DataCleanupCard({
  title,
  isInDataCleanup,
  isSelected,
  filter,
  callback = () => {},
  sx,
  cardId,
  onToggleInventory,
  numerator,
  denominator,
  isMsp,
  activeLink,
  setActiveLink,
}: IProps) {
  const dispatch = useDispatch()
  const {params} = useRouteMatch()

  const percentCleaned = Math.floor((numerator / denominator) * 100)

  // const startOfDistributionDate = startOfDay(new Date(date))
  // const year = getYear(startOfDistributionDate)
  // const month = getMonth(startOfDistributionDate)
  // const day = getDate(startOfDistributionDate)
  // const startTime = new Date(date as string)
  // let intermediateResult = setYear(startTime, year)
  // intermediateResult = setMonth(intermediateResult, month)
  // const exactStartTime = setDate(intermediateResult, day)
  // const currentTime = new Date()
  // let daysLeft = 0
  // let hoursLeft = 0

  // if (isFuture(exactStartTime)) {
  //   daysLeft = differenceInDays(exactStartTime, currentTime)
  //   const totalHoursRemaining = differenceInHours(exactStartTime, currentTime)
  //   hoursLeft = totalHoursRemaining % 24
  // }

  const redirect = (link: TLink | undefined) => {
    const baseAgencyRoute = isMsp ? BASE_ROUTES.AGENCY_ADMIN : BASE_ROUTES.AGENCY
    dispatch(
      push(
        `${baseAgencyRoute}/data-cleanup/${encodeURIComponent(cardId)}?showDetails=${
          link !== 'list'
        }`,
      ),
    )
  }

  const redirectIfNotSelected = (isSearch: boolean) => {
    if (cardId !== params.cardId) {
      redirect(isSearch ? 'search' : 'list')
    }
  }

  const searchIconColor = useMemo(() => {
    if (isSelected) {
      return activeLink === 'search' ? '#424242' : 'white'
    }
    return '#858585'
  }, [activeLink])

  const listIconColor = useMemo(() => {
    if (isSelected) {
      return activeLink === 'list' ? '#424242' : 'white'
    }
    return '#858585'
  }, [activeLink])

  return (
    <Container
      sx={{...sx, background: isSelected ? '#03A9FC' : '', cursor: 'pointer', height: '248px'}}>
      <Box
        style={{marginLeft: 20, marginRight: 20}}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          const target = event.target as HTMLButtonElement
          if (
            target.id === 'edit-data-cleanup' ||
            target.id === 'edit-data-cleanup-icon' ||
            target.id === 'edit-variant-icon'
          ) {
            event.preventDefault()
          } else {
            redirect(activeLink)
          }
        }}>
        <FirstRow style={{height: '75px'}}>
          <BigNumbers isSelected={isSelected} isBlue={true}>
            {denominator}
          </BigNumbers>
        </FirstRow>
        <SecondRow style={{height: '125px', paddingBottom: 0}}>
          <Tooltip title={filter}>
            <FilterText
              style={{
                marginTop: '19px',
                color: isSelected ? 'white' : '#8A8A8A',
                fontSize: '18px',
                fontWeight: 700,
              }}
              isSelected={isSelected}>{`${filter} Days Check-up`}</FilterText>
          </Tooltip>
        </SecondRow>
        {/* <FirstRow>
          <Ratio>
            <BigNumbers style={{color: isSelected ? 'black' : ''}}>{numerator}</BigNumbers>
            <BigNumbers style={{color: isSelected ? 'black' : ''}}>/</BigNumbers>
          </Ratio>
          <CalendarDayGraphic date={date} colorUnselected={isSelected ? 'white' : '#828282'} />
        </FirstRow>
        <SecondRow>
          <Tooltip title={title}>
            <DemoText isSelected={isSelected}>{title}</DemoText>
          </Tooltip>
          <FilterText isSelected={isSelected}>{`Filter: ${filter} Days Check-up`}</FilterText>
        </SecondRow>
        <ThirdRow>
          <SmallVertContainer>
            <SmallLabel isSelected={isSelected}>Time Left</SmallLabel>
            <SmallValue isSelected={isSelected} isBlue={true}>
              {`${daysLeft}D:${hoursLeft}H`}
            </SmallValue>
          </SmallVertContainer>
          <SmallVertContainer>
            <SmallLabel isSelected={isSelected}>Cleaned</SmallLabel>
            <SmallValue isSelected={isSelected}>{`${percentCleaned}%`}</SmallValue>
          </SmallVertContainer>
          <SmallHorizContainer>
            <Avatar
              sx={{maxHeight: '25px', maxWidth: '25px'}}
              alt="Remy Sharp"
              src="/static/images/avatar/2.jpg"
            />

            <AdditionalAvatarText>+4</AdditionalAvatarText>
          </SmallHorizContainer>
        </ThirdRow> */}
      </Box>
      <FourthRow style={{marginTop: 0, height: '48px', marginLeft: 20, marginRight: 20}}>
        {isInDataCleanup ? (
          <div />
        ) : (
          <IconLeftContainer>
            {/* hiding edit button */}
            {/* <IconButton
              id="edit-data-cleanup"
              onClick={() => {
                callback()
              }}>
              <EditVariantIcon id="edit-data-cleanup-icon" />
            </IconButton> */}
          </IconLeftContainer>
        )}
        <IconRightContainer style={{height: '48px'}}>
          <SearchIcon
            aria-label="data-cleanup-search-icon"
            data-testid="data-cleanup-search-icon"
            fill={searchIconColor}
            stroke={searchIconColor}
            onClick={() => {
              onToggleInventory(true)
              setActiveLink?.('search')
              redirectIfNotSelected(true)
            }}
            style={{
              cursor: 'pointer',
              marginRight: '7px',
            }}
          />
          <FormatListBulletedIcon
            aria-label="data-cleanup-list-icon"
            data-testid="data-cleanup-list-icon"
            stroke={listIconColor}
            onClick={() => {
              onToggleInventory(false)
              setActiveLink?.('list')
              redirectIfNotSelected(false)
            }}
            style={{
              cursor: 'pointer',
            }}
          />
        </IconRightContainer>
      </FourthRow>
    </Container>
  )
}
