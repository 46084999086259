import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {UserPool, getCognitoInfo} from '../cognito'
import type {
  ResetRequest,
  RequestSignup,
  FirstSigninResponse,
  SigninResponse,
  SigninRequest,
  SignoutResponse,
} from '../../models'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://card-ui.auth.us-east-1.amazoncognito.com',
  }),
  endpoints: (builder) => ({
    signin: builder.mutation<FirstSigninResponse | SigninResponse, SigninRequest>({
      queryFn: async ({emailAddress: email, password}) => {
        const {cognitoUser, authenticationDetails} = getCognitoInfo({email, password})

        return new Promise((resolve) => {
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async (session) => {
              const {payload} = session.getIdToken()
              const token = session.getIdToken().getJwtToken()
              const data = {
                token: token,
                emailAddress: payload.email,
                roles: payload['cognito:groups'],
                companyId: payload['custom:CompanyId'],
                isPlatform: /true/i.test(payload['custom:IsPlatform']),
              }

              resolve({data})
            },
            newPasswordRequired: ({email_verified, email: _email}) => {
              resolve({
                data: {email_verified, email: _email, oldPassword: password},
              })
            },
            onFailure: (err) => {
              resolve({error: {data: err.message, status: 400}})
            },
          })
        })
      },
    }),

    signout: builder.mutation<SignoutResponse, {email: string}>({
      queryFn: ({email}: {email: string}) => {
        const {cognitoUser} = getCognitoInfo({email})

        cognitoUser.signOut()
        window.localStorage.clear()
        return {data: {message: `You have been signed out`}}
      },
    }),

    register: builder.mutation<null, RequestSignup>({
      queryFn: async ({emailAddress: email, password}) => {
        const {attributeList} = getCognitoInfo({email})

        return new Promise((resolve) => {
          UserPool.signUp(email, password, attributeList, [], (error) => {
            if (error) resolve({error: {data: 'FETCH_ERROR', status: 500}})
            else resolve({data: null})
          })
        })
      },
    }),

    reset: builder.mutation<null, Partial<ResetRequest>>({
      queryFn: (auth) => {
        const {cognitoUser, authenticationDetails} = getCognitoInfo({
          email: auth.email as string,
          password: auth.oldPassword,
        })
        return new Promise((resolve) => {
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: () => ({}),
            onFailure: () => ({}),
            newPasswordRequired: () => {
              cognitoUser.completeNewPasswordChallenge(auth.password as string, null, {
                onSuccess: () => resolve({data: null}),
                onFailure: () => resolve({error: {data: 'FETCH_ERROR', status: 500}}),
              })
            },
          })
        })
      },
    }),
  }),
})

export const {useSigninMutation, useResetMutation, useRegisterMutation, useSignoutMutation} =
  authApi
