import type {IOrder} from '~/src/models/order'
import type {IServiceOrder} from '~/src/models/serviceOrder'

export const createValuesFromTemplate = (values: IServiceOrder): IOrder[] => {
  return values.orders.map((order: IOrder): IOrder => {
    return {
      orderType: order.orderType ?? '',
      note: order.note ?? '',
      positionId: order.positionId ?? '',
      payRate: order.payRate ?? '',
      quantity: order.quantity ?? '',
      distributionStartDate: order.distributionStartDate ?? '',
      distributionEndDate: order.distributionEndDate ?? '',
      orderDistribution: order.orderDistribution ?? {
        orderQuantityDistributions: [],
        agencyDistributions: [],
      },
    }
  })
}
