// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React, {useEffect, useState} from 'react'
import type {Applicant, EventsArray} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateEventSingleEdit from './MspCandidateEventSingleEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateEventsDisplay({
  handleSubmit,
  oldApplicant,
  setFieldValue,
  values,
}: IProps) {
  const [editProfileEventsState, setEditProfileEventsState] = useState('saveChanges')

  useEffect(() => {
    setEditProfileEventsState('saveChanges')
  }, [oldApplicant.id])

  return (
    <>
      <DashboardMainStyles>
        <InformationCardContainer style={{marginBottom: 0}}>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Events History</InformationCardHeaderTextTitle>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            {values.events.map((newEvent: EventsArray, index: number) => {
              return (
                <div key={index}>
                  <MspCandidateEventSingleEdit
                    handleSubmit={handleSubmit}
                    index={index}
                    oldApplicant={oldApplicant}
                    setFieldValue={setFieldValue}
                    values={values}
                    newEvents={newEvent}
                  />
                </div>
              )
            })}
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStyles>
    </>
  )
}
