import React from 'react'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import {styled} from '@mui/material/styles'

const CustomizedTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: '#25282B',
    fontSize: 14,
    fontWeight: 700,
    borderRadius: '4px',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#1C1C1C',
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export function StyledTableCell({...props}) {
  return <CustomizedTableCell {...props} />
}
