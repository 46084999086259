import React, {useEffect, useState} from 'react'
import {TableInfo as S} from '~/src/components/common/styles/TabsStyles'
import LoadingBoundary from '@ui/loader'
import Title from '../../layout/Title'
import AddButton from '@ui/buttons/AddButton'
import {css, styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    min-width: 900px;
  `,
)

const Body = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  `,
)

export function ImportWizardMain(): React.ReactElement {
  return (
    <Container>
      <Title
        pageTitle="Imports"
        button={<AddButton onClick={() => {}}>Import Candidates</AddButton>}
      />
      <Body>
        <LoadingBoundary isLoading={false} />
      </Body>
    </Container>
  )
}
