import {format} from 'date-fns'
import React from 'react'
import type {Certification} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string): void
  values: Certification[]
}

export default function MspCandidateProfileCertsDisplay({handleClick, values}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer style={{marginBottom: 0}}>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>
                Certifications / Licenses
              </InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            {values.map((certs: Certification, index: number) => {
              return (
                <InformationCardExperianceBorder
                  style={{marginBottom: 0}}
                  index={index === values.length - 1}
                  key={index}>
                  <InformationCardHeaderTextTitle>
                    {certs?.certificationName}
                  </InformationCardHeaderTextTitle>
                  <div style={{display: 'flex'}}>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '20px', marginTop: 5}}>
                      {certs.establishment}
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '20px', marginTop: 5}}>
                      {certs?.state}
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText style={{marginTop: 5}}>
                      Valid Until:
                      {certs?.expirationDate ? (
                        <span style={{marginLeft: 5}}>
                          {format(new Date(certs.expirationDate), 'MMMM yyyy')}
                        </span>
                      ) : (
                        ''
                      )}
                    </InformationCardProfileExperienceJobText>
                  </div>
                  <InformationCardProfileExperienceJobText />
                </InformationCardExperianceBorder>
              )
            })}
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
