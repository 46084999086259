import React from 'react'
import {FieldConfig, useField} from 'formik'
import {FormHelperText} from '@mui/material'
import Checked from './CheckedSvg'
import Indeterminate from './IndeterminateSvg'
import S from './Checkbox.styles'
import Unchecked from './UnCheckedSvg'
import useColor, {TSerializedColor} from '@utils/use-color'
import type {CheckboxProps} from '@mui/material/Checkbox'

interface ICheckbox extends Omit<CheckboxProps, 'color'> {
  label?: string
  color?: TSerializedColor
  textColor?: TSerializedColor
  labelProps?: object
  boxProps?: object
}

export default function FormikCheckbox(props: ICheckbox) {
  const {color = 'primary.main', label = '', textColor = 'secondary.dark', labelProps, boxProps, ...rest} = props
  const [field, meta] = useField({...rest, type: 'checkbox'} as FieldConfig)
  const txtColor = useColor(textColor)
  const errorMsg = meta.touched && meta.error ? meta.error : ''
  const isError = meta.touched && Boolean(meta.error)

  const labelledCheckbox = () => (
    <S.FormControlLabel
      label={label}
      sx={{color: txtColor, ...boxProps}}
      componentsProps={{
        typography: {
          sx: {
            color: txtColor,
            fontSize: props.size === 'medium' ? '0.875rem' : '0.6875rem',
            ...labelProps,
          },
        },
      }}
      control={
        <S.Checkbox
          {...field}
          {...rest}
          icon={<Unchecked error={isError} />}
          checkedIcon={<Checked color={color} />}
          indeterminateIcon={<Indeterminate color={color} />}
        />
      }
    />
  )

  const regularCheckbox = () => (
    <S.Checkbox
      {...field}
      {...rest}
      inputProps={{'aria-label': 'checkbox no label', ...rest.inputProps}}
      icon={<Unchecked error={isError} />}
      checkedIcon={<Checked color={color} />}
      indeterminateIcon={<Indeterminate color={color} />}
    />
  )

  return (
    <S.FormControl error={isError}>
      {label ? labelledCheckbox() : regularCheckbox()}
      <FormHelperText>{errorMsg}</FormHelperText>
    </S.FormControl>
  )
}
