import {Tooltip} from '@mui/material'
import React from 'react'
import {ReactComponent as GreenFlag} from '~/src/assets/images/GreenFlag.svg'
import {ReactComponent as LargerGreenFlag} from '~/src/assets/images/LargerGreenFlag.svg'
import {ReactComponent as LargerRedFlag} from '~/src/assets/images/LargerRedFlag.svg'
import {ReactComponent as LargerYellowFlag} from '~/src/assets/images/LargerYellowFlag.svg'
import {ReactComponent as RedFlag} from '~/src/assets/images/RedFlag.svg'
import {ReactComponent as YellowFlag} from '~/src/assets/images/YellowFlag.svg'
import {ReactComponent as GreyYellowFlag} from '~/src/assets/images/GreyYellowFlag.svg'
import {ReactComponent as GreyRedFlag} from '~/src/assets/images/GreyRedFlag.svg'
import {ReactComponent as GreyGreenFlag} from '~/src/assets/images/GreyGreenFlag.svg'
import type {TFlag} from '../../common/bucket/BucketList'

export const flagSwitch = (value: TFlag, isInHeader: boolean, isGreyFlag: boolean) => {
  switch (value) {
    case 'Ready For Assignment':
      return (
        <Tooltip title="Ready For Assignment">
          <div>
            {isInHeader ? (
              <LargerGreenFlag style={{marginRight: 10}} />
            ) : isGreyFlag ? (
              <GreyGreenFlag />
            ) : (
              <GreenFlag />
            )}
          </div>
        </Tooltip>
      )

    case 'Incomplete Application':
      return (
        <Tooltip title="Incomplete Application">
          <div>
            {isInHeader ? (
              <LargerYellowFlag style={{marginRight: 10}} />
            ) : isGreyFlag ? (
              <GreyYellowFlag />
            ) : (
              <YellowFlag />
            )}
          </div>
        </Tooltip>
      )

    case 'Do Not Use':
      return (
        <Tooltip title="Do Not Use">
          <div>
            {isInHeader ? (
              <LargerRedFlag style={{marginRight: 10}} />
            ) : isGreyFlag ? (
              <GreyRedFlag />
            ) : (
              <RedFlag />
            )}
          </div>
        </Tooltip>
      )
    default:
      return <div />
  }
}
