import styled from 'styled-components'
import Button from '@mui/material/Button'

export const StyledButton = styled(Button)`
  width: 120px;
` as typeof Button

export const BodyContainer = styled('div')`
  display: flex;
  height: 170px;
`

export const Body = styled('div')`
  margin: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  width: 100%;
  justify-content: end;
`

export const ButtonBox = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
`
