import {useState, FocusEvent} from 'react'
import {callAll} from '~/src/utils'
import type {IUseDropdownNums, IUseDropdownNumsReturn, IDropdownNums} from './typings'

const initialProps = {
  options: [],
  onBlur: () => ({}),
  onToggle: () => ({}),
  onChange: () => ({}),
  onClick: () => ({}),
}

export const useDropdown = (props?: IUseDropdownNums): IUseDropdownNumsReturn => {
  const [isOpen, setIsOpen] = useState(props?.initialOpen ?? false)
  const [idx, setIdx] = useState(-1)
  const toggle = () => setIsOpen(!isOpen)

  function handleBlur(e: FocusEvent<HTMLDivElement>) {
    e.preventDefault()

    if (e.currentTarget !== e.relatedTarget) {
      if (isOpen) toggle()
    }
  }

  const getDropdownProps = (_props: IDropdownNums = initialProps): IDropdownNums => ({
    ..._props,
    isOpen: isOpen,
    options: _props.options,
    initialSelected: _props.initialSelected,
    onBlur: callAll(handleBlur, _props.onBlur),
    onToggle: callAll(toggle, _props.onToggle),
    onChange: callAll(toggle, _props.onChange, setIdx),
  })

  return {getDropdownProps, optionIdx: idx}
}
