import type {StringMatcher} from 'cypress/types/net-stubbing'
import type {IAddress} from './address'

export enum EAgencyStatus {
  'Pending',
  'Active',
  'Rejected',
  'Disabled',
  'Needs Review',
  'Failed',
}

export interface IAgencyTypeMaps {
  nameToId: Record<string, string>
  idToName: Record<string, string>
}

// Model from backend server
export interface IAgencyBase {
  id: string
  address: IAddress
  adminFirstName: string
  adminLastName: string
  adminEmail: StringMatcher
  name: string
  phoneNumber: string
  website: string | null
  status: EAgencyStatus
  typeId: string
  createdDate: Date
  modifiedDate: Date
  versionKey: number
  isMsp: boolean
}

export interface TAgenciesPayload {
  agencies: IAgencyBase[]
  totalAgencies: number
}

export type TCreateAgencyPayload = Pick<
  IAgencyBase,
  | 'name'
  | 'phoneNumber'
  | 'adminFirstName'
  | 'adminLastName'
  | 'typeId'
  | 'adminEmail'
  | 'address'
  | 'website'
>

export type TAgencyNameAndId = Pick<IAgencyBase, 'id' | 'name'>

export type TUpdateAgencyStatusPayload = Pick<IAgencyBase, 'id' | 'status'>

export type TPagedAgenciesPayload = {pageNumber?: number, pageSize?: number, agencyId?: string}
