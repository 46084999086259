import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function BriefcaseIcon (props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props}
			width="48" 
			height="48" 
			viewBox="0 0 48 48" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg">
			<path d="M11.0001 8.5C11.0001 8.10218 11.1581 7.72064 11.4394 7.43934C11.7207 7.15804 12.1022 7 12.5001 7H24.0001V16C24.0001 17.0609 24.4215 18.0783 25.1716 18.8284C25.9218 19.5786 26.9392 20 28.0001 20H37.0001V39.5C37.0001 39.8978 36.842 40.2794 36.5607 40.5607C36.2794 40.842 35.8979 41 35.5001 41H28.0001V44H35.5001C36.091 44 36.6762 43.8836 37.2221 43.6575C37.7681 43.4313 38.2642 43.0998 38.682 42.682C39.0999 42.2641 39.4314 41.768 39.6575 41.2221C39.8837 40.6761 40.0001 40.091 40.0001 39.5V19.328C40.0001 18.4 39.6321 17.51 38.9741 16.854L27.1461 5.026C26.49 4.36952 25.6001 4.00046 24.6721 4H12.5001C11.3066 4 10.162 4.47411 9.31808 5.31802C8.47417 6.16193 8.00006 7.30653 8.00006 8.5V23.17C8.31206 23.06 8.65006 23 9.00006 23H11.0001V8.5ZM34.8801 17H28.0001C27.7348 17 27.4805 16.8946 27.2929 16.7071C27.1054 16.5196 27.0001 16.2652 27.0001 16V9.12L34.8801 17Z" fill="currentColor"/>
			<path d="M7.50006 26.5C7.50006 26.1022 7.6581 25.7206 7.9394 25.4393C8.22071 25.158 8.60224 25 9.00006 25H19.0001C19.3979 25 19.7794 25.158 20.0607 25.4393C20.342 25.7206 20.5001 26.1022 20.5001 26.5V30H21.5001C22.6935 30 23.8381 30.4741 24.682 31.318C25.526 32.1619 26.0001 33.3065 26.0001 34.5V41.5C26.0001 42.6935 25.526 43.8381 24.682 44.682C23.8381 45.5259 22.6935 46 21.5001 46H6.50006C5.30659 46 4.16199 45.5259 3.31808 44.682C2.47417 43.8381 2.00006 42.6935 2.00006 41.5V34.5C2.00006 33.3065 2.47417 32.1619 3.31808 31.318C4.16199 30.4741 5.30659 30 6.50006 30H7.50006V26.5ZM17.5001 28H10.5001V30H17.5001V28ZM6.50006 33C6.10224 33 5.72071 33.158 5.4394 33.4393C5.1581 33.7206 5.00006 34.1022 5.00006 34.5V41.5C5.00006 42.328 5.67206 43 6.50006 43H21.5001C21.8979 43 22.2794 42.842 22.5607 42.5607C22.842 42.2794 23.0001 41.8978 23.0001 41.5V34.5C23.0001 34.1022 22.842 33.7206 22.5607 33.4393C22.2794 33.158 21.8979 33 21.5001 33H6.50006Z" fill="currentColor"/>
		</Svg>
	);
}

