import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ArrowRightCircleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.380469 9.99998C0.380469 15.3192 4.68124 19.62 10.0005 19.62C15.3197 19.62 19.6205 15.3192 19.6205 9.99998C19.6205 4.68075 15.3197 0.37998 10.0005 0.37998C4.68124 0.37998 0.380469 4.68075 0.380469 9.99998ZM1.70047 9.99998C1.70047 5.40721 5.4077 1.69998 10.0005 1.69998C14.5932 1.69998 18.3005 5.40721 18.3005 9.99998C18.3005 14.5928 14.5932 18.3 10.0005 18.3C5.4077 18.3 1.70047 14.5928 1.70047 9.99998Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.2'
			/>
			<path
				d='M9.76017 15.5028L9.83088 15.5735L9.90159 15.5028L15.3336 10.0708L15.4043 10.0001L15.3336 9.9294L9.90159 4.4974L9.83088 4.42669L9.76017 4.4974L8.97616 5.2814L8.90545 5.35212L8.97616 5.42283L13.5535 10.0001L8.97616 14.5774L8.90545 14.6481L8.97616 14.7188L9.76017 15.5028Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.2'
			/>
			<path
				d='M4.96094 9.33994H4.86094V9.43994V10.5599V10.6599H4.96094H14.4809H14.5809V10.5599V9.43994V9.33994H14.4809H4.96094Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.2'
			/>
		</Svg>
	);
}
