import {styled} from '@mui/material/styles'
import {push} from 'connected-react-router'
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import Layout from '~/src/components/layout/Layout'
import UsersAndRoles from '~/src/components/msp/users-and-roles'
import {SIDEBAR_ROUTES} from '~/src/routes'
import {useSignoutMutation} from '~/src/store/apis/auth-api'
import {getCurrentSession} from '~/src/store/cognito'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppDispatch} from '~/src/store/store-hooks'
import {Candidates} from '../components/common/candidates/Candidates'
import {ImportWizardMain} from '../components/common/import-wizard/ImportWizardMain'
import JobOrdersDetailsSummaryMain from '../components/common/job-orders/JobOrderDetailsSummaryMain'
import ManageMspAgency from '../components/common/manage-profile/ManageMspAgency'
import AddOrEditPrefill from '../components/common/prefill/AddOrEditPrefill'
import PrefillMain from '../components/common/prefill/PrefillMain'
import AddServiceOrder from '../components/common/service-orders/AddServiceOrder'
import ServiceOrderSummary from '../components/company/manage-orders/manage-order-tables/company-summary/ServiceOrderSummary'
import AddTemplate from '../components/msp/add-templates/AddTemplate'
import EditTemplate from '../components/msp/add-templates/EditTemplate'
import MspAgencyDashboard from '../components/msp/dashboard/Dashboard'
import MspDataCleanupMain from '../components/msp/manage-data-cleanup/MspDataCleanupMain'
import MspJobOrdersDetailsMain from '../components/msp/manage-job-orders/job-order-details/MspJobOrdersDetailsMain'
import JobOrderFromPrefill from '../components/msp/manage-job-orders/job-order-summary/JobOrderFromPrefill'
import MspAgencyJobOrderMain from '../components/msp/manage-job-orders/job-orders-main/MspJobOrdersMain'
import AgencyManageOrdersMain from '../components/msp/manage-orders/agency-order-tables/AgencyManageOrdersMain'
import AgencyOrderDetails from '../components/msp/manage-orders/agency-order-tables/AgencyOrderDetails'
import AgencyPendingOrders from '../components/msp/manage-orders/agency-order-tables/AgencyPendingOrders'
import CompletedOrdersMain from '../components/msp/manage-orders/completed-orders/CompletedOrdersMain'
import AddNewPositionPage from '../components/msp/manage-positions/AddNewPositionPage'
import EditCompanyPositionPage from '../components/msp/manage-positions/EditCompanyPositionPage'
import ManageClientPositions from '../components/msp/manage-positions/ManageClientPositions'
import ManageTemplates from '../components/msp/manage-templates/ManageTemplates'
import TemplateDetails from '../components/msp/manage-templates/TemplateDetails'

const Container = styled('div')`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px);
  margin-top: 90px;
`

const Main = styled('div')`
  display: flex;
  min-width: 1111px;
  justify-content: center;
  flex-direction: column;
  transition: all 400ms ease;
`

export default function AgencyAdminPage() {
  const {path, url} = useRouteMatch()
  const {currentUser} = useCurrentUser()
  const dispatch = useAppDispatch()
  const [signout] = useSignoutMutation()

  useEffect(() => {
    const fetchCurrentSession = async () => {
      const session = await getCurrentSession()
      if (!session?.isValid()) {
        // Logout and reroute
        await signout({email: currentUser?.emailAddress as string}).unwrap()
        dispatch(push('/signin'))
      }
    }
    const timeoutVar = setInterval(() => {
      void fetchCurrentSession()
    }, 30000)
    return () => {
      clearInterval(timeoutVar)
    }
  }, [currentUser?.emailAddress, dispatch, signout])

  return (
    <Layout variant="msp">
      <Container>
        <Main>
          <Switch>
            <Route path={path + SIDEBAR_ROUTES.DASHBOARD} component={MspAgencyDashboard} />
            <Route exact path={`${path}/add-position/:compName`} component={AddNewPositionPage} />
            <Route
              exact
              path={`${path + SIDEBAR_ROUTES.CLIENT_POSITIONS}/:orderId`}
              component={EditCompanyPositionPage}
            />
            <Route
              path={path + SIDEBAR_ROUTES.CLIENT_POSITIONS}
              component={ManageClientPositions}
            />
            <Route path={path + SIDEBAR_ROUTES.ADD_ORDER} component={AddServiceOrder} />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}
              component={CompletedOrdersMain}
            />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}
              component={AgencyPendingOrders}
            />
            <Route
              exact
              path={`${path + SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}/:orderId/:type/summary`}
              component={ServiceOrderSummary}
            />
            <Route
              exact
              path={[
                path + SIDEBAR_ROUTES.SERVICE_ORDER_DETAILS,
                path + SIDEBAR_ROUTES.SERVICE_ORDER_PENDING_DETAILS,
              ]}
              component={AgencyOrderDetails}
            />
            <Route
              path={`${path + SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY}/:id`}
              component={JobOrdersDetailsSummaryMain}
            />
            <Route
              path={`${path + SIDEBAR_ROUTES.PREFILL_JOB_ORDER}/:jobOrderId/:prefillId`}
              component={JobOrderFromPrefill}
            />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.JOB_ORDER_DETAILS}
              component={MspJobOrdersDetailsMain}
            />
            <Route path={path + SIDEBAR_ROUTES.IMPORT} component={ImportWizardMain} />
            <Route path={path + SIDEBAR_ROUTES.CANDIDATES} component={Candidates} />
            <Route path={path + SIDEBAR_ROUTES.PREFILL_ADD} component={AddOrEditPrefill} />
            <Route path={path + SIDEBAR_ROUTES.PREFILL_EDIT} component={AddOrEditPrefill} />
            <Route path={path + SIDEBAR_ROUTES.PREFILL} component={PrefillMain} />
            <Route path={path + SIDEBAR_ROUTES.JOB_ORDERS} component={MspAgencyJobOrderMain} />
            <Route path={path + SIDEBAR_ROUTES.DATA_CLEANUP} component={MspDataCleanupMain} />
            <Route path={path + SIDEBAR_ROUTES.SERVICE_ORDERS} component={AgencyManageOrdersMain} />
            <Route
              path={`${path + SIDEBAR_ROUTES.EDIT_TEMPLATE}/:templateId/:positionId`}
              component={EditTemplate}
            />
            <Route
              path={`${path + SIDEBAR_ROUTES.ADD_TEMPLATE}/:currPageIndex`}
              component={AddTemplate}
            />
            <Route path={path + SIDEBAR_ROUTES.TEMPLATE_DETAILS} component={TemplateDetails} />
            <Route path={path + SIDEBAR_ROUTES.MANAGE_TEMPLATES} component={ManageTemplates} />
            <Route path={path + SIDEBAR_ROUTES.USERS_AND_ROLES} component={UsersAndRoles} />
            <Route path={path + SIDEBAR_ROUTES.MANAGE_MSP_AGENCY} component={ManageMspAgency} />
            <Redirect exact from={path} to={url + SIDEBAR_ROUTES.DASHBOARD} />
          </Switch>
        </Main>
      </Container>
    </Layout>
  )
}
