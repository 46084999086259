import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import {styled} from '@mui/material/styles'

const CustomizedFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: #333333;
    size: 14px;
    white-space: nowrap;
  }
`

export function StyledFormControlLabel({...props}) {
  return <CustomizedFormControlLabel {...props} />
}
