import {styled} from '@mui/material/styles'
import {FormHelperText as MuiFormHelperText, InputLabel as MuiInputLabel} from '@mui/material'
import CustomIcon from '@ui/icons'

const InputLabel = styled(MuiInputLabel)`
  color: ${({theme}) => theme.palette.text.primary};
  max-width: initial;
  padding-right: 3px;
  position: relative;
  text-align: left;
`

const FormHelperText = styled(MuiFormHelperText)`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Flex = styled('div')`
  display: flex;
  height: 1rem;
  line-height: 1rem;
  position: absolute;
  top: -1.2rem;
  width: 100%;
`

const Icon = styled(CustomIcon)`
  color: ${({theme}) => theme.palette.text.secondary};
  cursor: pointer;
  width: 1.125rem;
  height: 1.125rem;
`

export default {
  Flex,
  FormHelperText,
  Icon,
  InputLabel,
}
