import {Grid} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import {FormikAutocomplete} from '@ui/components'
import FormikTimePicker from '@ui/date-time-pickers/FormikTimePicker'
import Mui from '@ui/mui'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {push} from 'connected-react-router'
import {Formik, Form as FormikForm} from 'formik'
import {nanoid} from 'nanoid/non-secure'
import React, {useState} from 'react'
import * as Yup from 'yup'
import type {ICompanyBase, IDepartment} from '~/src/models'
import type {
  IInitialPositionValues,
  IPosition,
  IPositionFacilityAddress,
} from '~/src/models/position'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetOneCompanyQuery, useUpdateCompanyMutation} from '~/src/store/apis/company-api'
import {useCreatePositionMutation, useUpdatePositionMutation} from '~/src/store/apis/position-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'

const Form = styled(FormikForm)({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '7fr 3fr',
})

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const RightColumn = styled('div')({
  gridColumn: '2/3',
  overflow: 'hidden',
  textAlign: 'left',
  width: 250,
})

interface IProps {
  initialValues: IInitialPositionValues
  isEdit?: boolean
  isMspAdmin?: boolean
}

interface IFormSubmitValues {
  onsiteFacility: string
  position: string
  departmentName: string
  workersCompCode: string
  startTime: string
  endTime: string
  manager: string
  jobDescription: string | undefined
  companyId: string
}

export default function PositionForm({
  initialValues,
  isEdit,
  isMspAdmin,
}: IProps): React.ReactElement {
  const dispatch = useAppDispatch()
  const {currentUser} = useCurrentUser()
  const [facilityAddress, setFacilityAddress] = useState<string>(
    isEdit ? initialValues.onsiteFacilityAddress : '',
  )
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updateCompany, {error: updateCompanyError}] = useUpdateCompanyMutation()
  const [addPosition, {error: positionError}] = useCreatePositionMutation()
  const [updatePosition, {error: updatePositionError}] = useUpdatePositionMutation()

  const {data, error: getCompanyError} = useGetOneCompanyQuery(
    initialValues.companyId ?? (currentUser?.companyId as string),
  )

  const initialFormValues = {
    companyId: initialValues.companyId,
    onsiteFacility: initialValues.onsiteFacilityName,
    position: initialValues.position,
    departmentName: initialValues.departmentName,
    workersCompCode: initialValues.workersCompCode,
    startTime: initialValues.startTime,
    endTime: initialValues.endTime,
    manager: initialValues.manager,
    jobDescription: initialValues.jobDescription,
  } as IFormSubmitValues

  const handleFacilityChange = (addressName: string) => {
    if (addressName) {
      const filteredAddress = data?.addresses.filter(
        (address) => address.isOnsite && address.name == addressName,
      )
      if (filteredAddress?.[0]?.address.display) {
        setFacilityAddress(filteredAddress[0]?.address.display)
      } else {
        setFacilityAddress('')
      }
    } else {
      setFacilityAddress('')
    }
  }

  const handleSubmit = async (values: IFormSubmitValues) => {
    let departmentId
    const foundDepartment = data?.departments.find((item) => item.name === values.departmentName)
    if (foundDepartment) {
      departmentId = foundDepartment.id
    } else {
      departmentId = nanoid()
      const departmentsClone = JSON.parse(JSON.stringify(data?.departments)) as Array<IDepartment>
      departmentsClone.push({id: departmentId, name: values.departmentName})

      const body = {
        ...data,
        departments: departmentsClone,
      } as ICompanyBase
      await updateCompany(body).unwrap()
    }

    const parameters: IPosition = {
      name: values.position,
      companyId: initialValues.companyId ?? (currentUser?.companyId as string),
      departmentId: departmentId,
      onsiteFacilityId: data?.addresses.filter((item) => item.name === values.onsiteFacility)[0]
        ?.id,
      compensationCodeId: data?.compensationCodes.filter(
        (item) => item.code === values.workersCompCode,
      )[0]?.id,
      startTime:
        typeof values.startTime === 'string'
          ? values.startTime
          : (values.startTime as unknown as Date).toISOString(),
      endTime:
        typeof values.endTime === 'string'
          ? values.endTime
          : (values.endTime as unknown as Date).toISOString(),
      manager: values.manager,
      jobDescription: values?.jobDescription?.length > 0 ? values.jobDescription : null,
      id: '',
      createdBy: '',
      createdDate: '',
      companyName: '',
      departmentName: '',
      compensationCode: '',
      facilityId: '',
      facilityAddress: {} as IPositionFacilityAddress,
      facilityName: '',
      modifiedBy: '',
      modifiedDate: '',
    }

    if (isEdit) {
      parameters.createdBy = initialValues.createdBy as string
      parameters.createdDate = initialValues.createdDate as string
      parameters.modifiedBy = currentUser?.emailAddress as string
      parameters.id = initialValues.id as string
      parameters.versionKey = initialValues.versionKey
      parameters.modifiedDate = new Date().toISOString()
      await updatePosition({position: parameters, agencyId: loggedInAgencyId}).unwrap()
    } else {
      parameters.createdDate = new Date().toISOString()
      parameters.createdBy = currentUser?.emailAddress as string
      await addPosition({position: parameters, agencyId: loggedInAgencyId}).unwrap()
    }
    if (isMspAdmin) {
      dispatch(push('/agency-admin/client-positions'))
    } else {
      dispatch(push('/company-admin/manage-positions'))
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={Yup.object({
        position: Yup.string().required('Required'),
        departmentName: Yup.string().required('Required'),
        onsiteFacility: Yup.string().required('Required'),
        workersCompCode: Yup.string().required('Required'),
        startTime: Yup.date().typeError('Invalid format.').nullable().required('Required'),
        endTime: Yup.date().typeError('Invalid format.').nullable().required('Required'),
        manager: Yup.string().required('Required'),
      })}>
      {({isSubmitting, setFieldValue, values}) => {
        return (
          <Form>
            <Grid container columnSpacing={4} rowSpacing={4}>
              <Grid
                item
                xs={3}
                component="label"
                htmlFor="position"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Position<Red>*</Red>:
              </Grid>

              <Grid item xs={9}>
                <FormikTextField name="position" inputProps={{maxLength: 50}} />
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="departmentName"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Department Name<Red>*</Red>:
              </Grid>

              <Grid item xs={9}>
                <FormikAutocomplete
                  name="departmentName"
                  options={data ? data.departments.map((item) => item.name) : []}
                  placeholder="Warehouse"
                  freeSolo={true}
                  defaultValue={isEdit ? initialValues.departmentName : undefined}
                />
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="onsiteFacility"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Onsite Facility<Red>*</Red>:
              </Grid>

              <Grid item xs={9}>
                <FormikAutocomplete
                  name="onsiteFacility"
                  options={
                    data
                      ? data.addresses.filter((item) => item.isOnsite).map((item) => item.name)
                      : []
                  }
                  placeholder="Facility A"
                  onChange={handleFacilityChange}
                  defaultValue={isEdit ? initialValues.onsiteFacilityName : undefined}
                />
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="workerLocation"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Work Site Location:
              </Grid>

              <Grid item xs={9}>
                <div style={{fontWeight: 600, fontSize: '14px'}}>{facilityAddress}</div>
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="workersCompensationCode"
                justifyContent="flex-end"
                display="flex"
                alignItems="center"
                flexWrap="wrap">
                Worker's <span />
                Compensation Code<Red>*</Red>:
              </Grid>

              <Grid item xs={9}>
                <FormikAutocomplete
                  name="workersCompCode"
                  options={data ? data.compensationCodes.map((item) => `${item.code}`) : []}
                  placeholder="Comp Code A"
                  defaultValue={values.workersCompCode}
                />
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="workerStartTime"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Work Start Time<Red>*</Red>:
              </Grid>

              <Grid item xs={9} display="flex" alignItems="center" justifyContent="space-between">
                <div data-testid="startTime" style={{width: '175px'}}>
                  <FormikTimePicker name="startTime" />
                </div>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <div style={{marginRight: '25px'}}>
                    Work End Time<Red>*</Red>:
                  </div>
                  <div data-testid="endTime" style={{width: '175px'}}>
                    <FormikTimePicker name="endTime" />
                  </div>
                </Box>
              </Grid>

              <Grid item xs={3} marginTop={-2} />
              <Grid
                item
                xs={9}
                color="#8A8A8A"
                marginTop={-2}
                display="flex"
                alignItems="flex-start">
                <div>
                  Note: All time zones are reflected on the worksite locationís standard time.
                </div>
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="manager"
                justifyContent="flex-end"
                display="flex"
                alignItems="center">
                Reporting Manager<Red>*</Red>:
              </Grid>

              <Grid item xs={9}>
                <FormikTextField name="manager" inputProps={{maxLength: 50}} />
              </Grid>

              <Grid
                item
                xs={3}
                component="label"
                htmlFor="description"
                justifyContent="flex-end"
                display="flex"
                alignItems="flex-start">
                Job Description:
              </Grid>

              <Grid item xs={9}>
                <FormikTextField name="jobDescription" multiline rows={10} />
                {/* <MUIRichTextEditor
                    controls={['bold', 'italic', 'underline', 'bulletList', 'numberList']}
                    toolbarButtonSize="small"
                    label="Start typing..."
                  /> */}
              </Grid>

              <Grid item xs={3} sx={{pr: '20px'}} justifyContent="end" />

              <Grid item xs={9} mt={6}>
                {initialValues.companyId ? (
                  <Button
                    data-testid="position-cancel"
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting}
                    sx={{mr: 'auto'}}
                    onClick={() => {
                      dispatch(
                        push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.CLIENT_POSITIONS}`),
                      )
                    }}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    data-testid="position-cancel"
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting}
                    sx={{mr: 'auto'}}
                    onClick={() => {
                      dispatch(push(`${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.POSITIONS}`))
                    }}>
                    Cancel
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ml: 2}}>
                  Publish
                </Button>
              </Grid>
            </Grid>

            <RightColumn />
          </Form>
        )
      }}
    </Formik>
  )
}

PositionForm.defaultProps = {
  isEdit: false,
}
