import React from 'react'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import Checkbox from '@mui/material/Checkbox'
import {styled} from '@mui/material/styles'
import CheckboxUnchecked from '@ui/icons/CheckboxUnchecked'

const CustomizedCheckbox = styled(Checkbox)``

export function StyledAllCheckbox({...props}) {
  return (
    <CustomizedCheckbox
      id="selectAll"
      icon={<CheckboxUnchecked />}
      checkedIcon={<CheckboxChecked />}
      {...props}
    />
  )
}
