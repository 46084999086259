import {Box, Button} from '@mui/material'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import React, {useMemo, useState} from 'react'
import type {Candidate} from '~/src/models/jobOrder'
import {useGetUsersByEmailsQuery} from '~/src/store/apis/user-api'
import InventoryListCard from './InventoryListCard'
import {
  CardContainer,
  HeaderBox,
  HeaderText,
  SubmitButton,
  Wrapper,
} from './styles/CandidateInventoryStyles'

interface CandidateInventoryProps {
  candidates: Candidate[]
  removeCandidate?: (candidateId: string) => void
  isFilledOrReady?: boolean
  isDataCleanup?: boolean
  isPrefill?: boolean
  isDisabled?: boolean
  isMsp?: boolean
  isMoving?: boolean
  jobOrderStatus?: string
  onSubmit?: (ids?: string[]) => void
}

const CandidateInventory = ({
  candidates,
  removeCandidate,
  isDataCleanup = false,
  isFilledOrReady = false,
  isPrefill,
  isDisabled = false,
  isMsp = false,
  isMoving = false,
  jobOrderStatus,
  onSubmit = () => {},
}: CandidateInventoryProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isButtonVisible = (isPrefill || isMoving || isFilledOrReady) && !isDataCleanup
  const emailAddressesCreatedBy = candidates.map((candidate) => candidate.statusModifiedBy)
  const emailAddressesEndedBy = candidates
    .map((candidate) => candidate.endDateUpdatedBy)
    .filter((item) => item !== undefined) as string[]
  const emailAddressesCombined = [...emailAddressesCreatedBy, ...emailAddressesEndedBy]
  const emailAddressesDeduped = emailAddressesCombined.filter(
    (email, index) => emailAddressesCombined.indexOf(email) === index,
  )
  const {data: usersByEmails} = useGetUsersByEmailsQuery(emailAddressesDeduped)

  let buttonText = ''
  let headerText = ''
  let bigBlurbText = 'Submit Candidate List'
  let smallBlurbText = ''
  if (isPrefill) {
    buttonText = 'Move To Job Order'
    smallBlurbText = 'Are you ready to deliver this set of applicants?'
  } else if (isMoving) {
    buttonText = 'Move Candidates'
    smallBlurbText = 'Are you ready to deliver this set of applicants?'
  } else if (isMsp) {
    buttonText = 'Submit Order'
    headerText = 'Deliver Applicants to Company'
    bigBlurbText = 'Please Confirm Order Filled'
    smallBlurbText = 'Are you ready to deliver this set of applicants to the Company?'
  } else {
    buttonText = 'Order Filled'
    headerText = 'Deliver Applicants to MSP'
    bigBlurbText = 'Please Confirm Order Filled'
    smallBlurbText = 'Are you ready to deliver this set of applicants to the MSP?'
  }

  const handleSelectCandidate = (id: string) => {
    if (isMoving) {
      const selectedArray = [...selected]
      const idIndex = selectedArray.indexOf(id)
      if (idIndex >= 0) {
        selectedArray.splice(idIndex, 1)
        setSelected(selectedArray)
      } else {
        setSelected([...selectedArray, id])
      }
    }
  }

  const handleSelectAll = () => {
    const selectedArray: string[] = candidates.map((candidate) => {
      return candidate.id ?? ''
    })

    if (selectedArray.length) {
      setSelected(selectedArray)
    }
  }

  const handleDeselectAll = () => {
    setSelected([])
  }

  const handleSubmitClick = () => {
    if (isMoving || isPrefill) {
      onSubmit(selected)
    } else {
      setIsModalOpen(true)
    }
  }

  const disableButton = useMemo(() => {
    if (isDisabled) return true
    if (isMoving && selected.length === 0) return true
    if (isPrefill) return false
    return false
  }, [selected, isDisabled])

  return (
    <Wrapper>
      <BasicConfirmationDialog
        header={headerText}
        bigBlurb={bigBlurbText}
        smallBlurb={smallBlurbText}
        isOpen={isModalOpen}
        handleCancel={() => {
          setIsModalOpen(false)
        }}
        handleSubmit={() => {
          onSubmit()
          setIsModalOpen(false)
        }}
        cancelButtonLabel="Back"
      />
      <HeaderBox>
        <Box>
          <HeaderText>Candidate Inventory List</HeaderText>
        </Box>
        <Box>
          {isButtonVisible ? (
            <>
              {isMoving ? (
                <>
                  <Button sx={{color: '#fff'}} onClick={handleSelectAll}>
                    Select All
                  </Button>
                  |
                  <Button sx={{color: '#fff'}} onClick={handleDeselectAll}>
                    Cancel
                  </Button>
                </>
              ) : null}
              <SubmitButton onClick={handleSubmitClick} disabled={disableButton}>
                {buttonText}
              </SubmitButton>
            </>
          ) : null}
        </Box>
      </HeaderBox>
      <CardContainer>
        {candidates.length > 0
          ? candidates
              .toSorted((a, b) => {
                if (a.endDate && !b.endDate) {
                  return -1
                } else if (!a.endDate && b.endDate) {
                  return 1
                }
                return 0
              })
              .map((candidate) => {
                const isSelected = selected.includes(candidate.id)
                return (
                  <InventoryListCard
                    key={candidate.candidateId}
                    isMoving={isMoving}
                    candidate={candidate}
                    selected={isSelected}
                    removeCandidate={isDataCleanup ? undefined : removeCandidate}
                    isDataCleanup={isDataCleanup}
                    onSelect={handleSelectCandidate}
                    isPrefill={!!isPrefill}
                    jobOrderStatus={jobOrderStatus}
                    isMsp={isMsp}
                    isDisabled={isDisabled}
                    usersAndEmails={usersByEmails}
                  />
                )
              })
          : null}
      </CardContainer>
    </Wrapper>
  )
}

export default React.memo(CandidateInventory)
