import type {Button} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import AddButton from '@ui/buttons/AddButton'
import ServiceOrderCard from '@ui/card/ServiceOrderCard'
import useWindowDimensions from '@ui/custom-hook-height/CustomHeightHook'
import CreateOrderDropdown from '@ui/dropdown/CreateOrderDropdown'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import React, {useEffect} from 'react'
import Title from '~/src/components/layout/Title'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {
  selectServiceOrderState,
  updateCompletedPageNumber,
} from '~/src/store/slices/service-order-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'

export const CompanyMainStyles = styled('div')(
  ({theme}) => css`
    position: relative;
    flex: 1;
  `,
)

const ContainerBox = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    p: 1;
    borderradius: 1;

    ${theme.breakpoints.up('xl')} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      max-width: 100%;
      p: 1;
      borderradius: 1;
      flex-wrap: wrap;
    }
  `,
)

const PaginationBox = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    margin-top: -50px;
    p: 1;
    borderradius: 1;
  `,
)

export default function CompanyCompletedOrdersMain(): JSX.Element {
  const {currentServiceOrderCompletedPageNumber} = useAppSelector(selectServiceOrderState)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const dispatch = useAppDispatch()
  const {height, width} = useWindowDimensions()

  localStorage.setItem('currentServiceOrderPageNumber', currentServiceOrderCompletedPageNumber)
  const pageNumberParsed = JSON.parse(currentServiceOrderCompletedPageNumber)
  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: pageNumberParsed,
    pageSize: height > 1500 ? 8 : 4,
    filterBy: 'CompletedOrders',
    agencyId: loggedInAgencyId
  })

  const handlePaginationChange = (pageNumber: number) => {
    dispatch(updateCompletedPageNumber(pageNumber))
  }

  useEffect(() => {
    void refetch()
    dispatch(updateCompletedPageNumber(currentServiceOrderCompletedPageNumber))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServiceOrderCompletedPageNumber, refetch])

  const totalOrders = data?.totalServiceOrders

  return (
    <CompanyMainStyles>
      <Title
        aria-label="Compeleted Orders"
        pageTitle="Completed Orders"
        button={
          <CreateOrderDropdown
            aria-label="Create New Order"
            label="Create New Order"
            CustomButton={AddButton as typeof Button}
            top="50px"
          />
        }
      />

      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <ContainerBox>
            {data?.serviceOrdersModel.map((row, index) => {
              let filledRatioCount = 0
              row.orders.forEach((i) => {
                i.orderDistribution.orderQuantityDistributions.forEach((q, index2) => {
                  filledRatioCount += q.quantity
                })
              })
              return (
                <ServiceOrderCard
                  agencyId={row.agencyId}
                  companyId={row.companyId}
                  currentServiceOrderPageNumber={currentServiceOrderCompletedPageNumber}
                  filledRatioCount={filledRatioCount}
                  isCompany={true}
                  key={index}
                  position={row.orders}
                  serviceOrderId={row.id}
                  serviceOrderNumber={row.orderNumber}
                  status={row.companyStatus}
                />
              )
            })}
          </ContainerBox>
          <PaginationBox>
            <StyledPagination
              page={Number(currentServiceOrderCompletedPageNumber)}
              totalRecords={totalOrders}
              recordsPerPage={4}
              onChange={handlePaginationChange}
            />
          </PaginationBox>
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles>
  )
}
