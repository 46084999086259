import {format} from 'date-fns'
import React from 'react'
import type {WorkHistory} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string): void
  values: WorkHistory[]
}

export default function MspCandidateProfileExperienceDisplay({handleClick, values}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Work Experience</InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            {values.map((workHistoryData: WorkHistory, index: number) => {
              return (
                <InformationCardExperianceBorder index={index === values.length - 1} key={index}>
                  <InformationCardHeaderTextTitle>
                    {workHistoryData.position}
                  </InformationCardHeaderTextTitle>
                  <div style={{display: 'flex'}}>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '25px', marginTop: 5}}>
                      {workHistoryData.companyName},
                      <span style={{marginLeft: 1}}>
                        {workHistoryData?.city?.length > 0
                          ? `${workHistoryData?.city}, ${workHistoryData?.state}`
                          : `${workHistoryData?.address?.city}, ${workHistoryData?.address?.state}`}
                      </span>
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '25px', marginTop: 5}}>
                      Supervisor:
                      <span style={{marginLeft: 1}}>{workHistoryData.supervisorName}</span>
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '25px', marginTop: 5}}>
                      {format(new Date(workHistoryData.startDate), 'MMMM yyyy')}
                      {workHistoryData?.endDate
                        ? ` - ${format(new Date(workHistoryData.endDate), 'MMMM yyyy')}`
                        : ''}
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '25px', marginTop: 5}}>
                      {workHistoryData?.reasonForLeaving
                        ? `(${workHistoryData.reasonForLeaving})`
                        : ''}
                    </InformationCardProfileExperienceJobText>
                  </div>
                  <div style={{display: 'flex'}}>
                    {workHistoryData?.startingPay ? (
                      <InformationCardProfileExperienceJobText
                        style={{marginRight: '25px', marginTop: '5px'}}>
                        {`Starting Pay: ${workHistoryData?.startingPay}${
                          workHistoryData?.startingPayFrequency
                            ? `/${workHistoryData?.startingPayFrequency}`
                            : ''
                        }`}
                      </InformationCardProfileExperienceJobText>
                    ) : (
                      <div />
                    )}
                    {workHistoryData?.endingPay ? (
                      <InformationCardProfileExperienceJobText
                        style={{marginRight: '25px', marginTop: '5px'}}>
                        {`Ending Pay: ${workHistoryData?.endingPay} ${
                          workHistoryData?.endingPayFrequency
                            ? `/${workHistoryData.endingPayFrequency}`
                            : ''
                        }`}
                      </InformationCardProfileExperienceJobText>
                    ) : (
                      <div />
                    )}
                  </div>
                  <InformationCardProfileExperienceJobText
                    style={{marginTop: '5px', marginBottom: '20px'}}>
                    {workHistoryData.jobDuties}
                  </InformationCardProfileExperienceJobText>
                </InformationCardExperianceBorder>
              )
            })}
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
