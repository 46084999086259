export const getFilterDays = (filter: string) => {
  switch (filter) {
    case 'ThirtyDayRange':
      return 30
    case 'SixtyDayRange':
      return 60
    case 'NinetyDayRange':
      return 90
    default:
      return 30
  }
}
