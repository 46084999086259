import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Avatar, IconButton, Menu, MenuItem, Tooltip, Typography} from '@mui/material'
import {push} from 'connected-react-router'
import React, {useCallback, useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import type {IUserBase} from '~/src/models'
import {useLazyGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useSignoutMutation} from '~/src/store/apis/auth-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import EulaDialog from './EulaDialog'

interface UserMenuProps {
  currentUser: IUserBase
}

const StyledMenuItem = styled(MenuItem)(
  ({theme}) => css`
    width: 100%;
    &:hover .itemText {
      color: ${theme.palette.primary.main};
    }
  `,
)

const StyledText = styled(Typography)(
  ({theme}) => css`
    left: 9.62%;
    right: 17.79%;
    top: 20.27%;
    bottom: 71.17%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #383838;
  `,
)

const StyledLightText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8a8a8a;
`

const UserMenu = ({currentUser}: UserMenuProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [isEulaOpen, setIsEulaOpen] = useState(false)
  const dispatch = useAppDispatch()
  const [signout] = useSignoutMutation()
  const [getAgency, {error: agencyError}] = useLazyGetOneAgencyQuery()
  const [stateRole, newStateRole] = useState('')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [setAnchorElUser])

  const handleSignOut = async () => {
    await signout({email: currentUser.emailAddress}).unwrap()
    dispatch(push('/signin'))
  }

  const handleCloseEula = useCallback(() => {
    setIsEulaOpen(false)
  }, [setIsEulaOpen])

  const handleOpenEula = useCallback(() => {
    handleCloseUserMenu()
    setIsEulaOpen(true)
  }, [handleCloseUserMenu])

  const {emailAddress, firstName, lastName, roles} = currentUser

  useEffect(() => {
    async function fetchData() {
      if (roles[0] === 'agencyadmin' || roles[0] === 'general_admin') {
        const agency = await getAgency(loggedInAgencyId).unwrap()
        const agencyMSP = agency.isMsp

        return agencyMSP ? newStateRole('MSP User (me)') : newStateRole('Agency User (me)')
      } else {
        switch (roles[0]) {
          case 'companyadmin':
            return newStateRole('Company User (me)')
            break
          case 'platformadmin':
            return newStateRole('Platform User (me)')
            break
          default:
            return newStateRole('Platform Admin (me)')
            break
        }
      }
    }
    void fetchData()
  }, [getAgency, loggedInAgencyId, roles])

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Avatar sx={{backgroundColor: '#03a9fc'}}>{firstName[0]}</Avatar>
          <ArrowDropDownIcon sx={{color: '#8A8A8A'}} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        <MenuItem className="itemText">
          <StyledLightText>{stateRole}</StyledLightText>
        </MenuItem>
        <StyledMenuItem>
          <StyledText textAlign="center" className="itemText">
            My Account
          </StyledText>
        </StyledMenuItem>
        <StyledMenuItem>
          <StyledText textAlign="center" className="itemText">
            Settings
          </StyledText>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleOpenEula}>
          <StyledText textAlign="center" className="itemText">
            EULA
          </StyledText>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleSignOut}>
          <StyledText textAlign="center" className="itemText">
            Log Out
          </StyledText>
        </StyledMenuItem>
        <MenuItem sx={{marginTop: '37px'}}>
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/2.jpg"
            sx={{marginRight: '13px', backgroundColor: '#03a9fc'}}>
            {firstName[0]}
          </Avatar>
          <StyledLightText>
            {emailAddress}
            <br />
            {firstName} {lastName}
          </StyledLightText>
        </MenuItem>
      </Menu>
      <EulaDialog isModalOpen={isEulaOpen} onModalClose={handleCloseEula} />
    </>
  )
}

export default UserMenu
