import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect, useState} from 'react'
import {useGetServiceOrderTemplatesQuery} from '~/src/store/apis/service-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import {CompanyMainStyles} from '../positions/styles/company-main.styles'
import ManageTemplatesTable from './ManageTemplatesTable'

export default function ManageTemplates(): JSX.Element {
  const dispatch = useAppDispatch()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderTemplatesQuery({
    pageNumber: currPageIdx,
    pageSize: 10,
    agencyId: loggedInAgencyId,
  })

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])

  return (
    <CompanyMainStyles.Container>
      <Title pageTitle="Manage Templates" />
      <ErrorBoundary>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <ManageTemplatesTable
            data={data}
            currentPageNumber={currPageIdx}
            onPaginationChange={handlePaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles.Container>
  )
}
