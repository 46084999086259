export const DegreeType = [
  'High School',
  'Community College',
  'Professional School',
  'Technical or Trade School',
  'University',
  'Other',
]

export const ReasonForLeaving = [
  'Transportation Issues',
  'Relocated',
  'Personal Reasons',
  'Work Environment',
  'Unsatisfactory Payrate',
  'JobDuties',
  'Conflict With Management',
  'Conflict With Colleague',
  'Schedule Conflict',
  'Distance',
  'Insufficient Training',
  'No Growth',
]

export const PayFrequency = ['Hourly', 'Weekly', 'Monthly', 'Annual']

export const CertificationType = [
  'Stand up Reach',
  'Sit Down',
  'Stand Up',
  'Clamps',
  'Cherry Picker',
  'Electric Pallet Jack Ryder',
  'Class A',
  'Class B',
  'Class C',
  'Safe Serve Food Handlers',
  'RBS',
  'MS Office',
]
