import styled from 'styled-components'
import Mui from '@ui/mui'

export const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
  `,
}

export const JobOrderCardContainer = styled('div')({
  height: '287px',
  width: '260px',
  borderRadius: '16px',
  background: '#FFFFFF',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 20px 10px 20px',
  boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, 0.05)',
})

export const JobOrderCardCompanyContainer = styled('div')({
  height: '160px',
  width: '260px',
  borderRadius: '16px',
  background: '#03A9FC',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '22px',
  padding: '15px',
})

export const PositionText = styled('div')({
  color: '#707070',
  fontSize: '18px',
  fontWeight: 700,
  textAlign: 'left',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '160px',
  lineHeight: 'normal',
})

export const JobOrderText = styled('div')({
  lineHeight: 'normal',
  fontSize: '12px',
  fontWeight: 400,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '160px',
})

export const ShiftText = styled('div')({
  fontSize: '18px',
  fontWeight: 700,
  textAlign: 'left',
  color: '#707070',
  lineHeight: 'normal',
})

export const ShiftCompanyText = styled('div')({
  fontSize: '18px',
  fontWeight: 700,
  textAlign: 'left',
  marginBottom: '5px',
  color: '#FFFFFF',
})

export const ClientText = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#707070',
  marginBottom: '2px',
})

export const CustomizedDivider = styled(Mui.Divider)({
  background: 'white',
  marginTop: 3,
  marginBottom: 3,
})

export const CustomizedCompanyDivider = styled(Mui.Divider)({
  background: 'white',
  marginTop: 1,
  marginBottom: 5,
})

export const HeaderContainer = styled(Mui.Box)(({theme}) => ({
  overflow: 'hidden',
  minHeight: '90px',
  maxHeight: '90px',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

export const HeaderSubFlexContainer = styled('div')({
  display: 'flex',
})

export const HeaderMarginFlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const HeaderMarginFlexSpaceBetweenContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export const PositionNameAndJOContainer = styled('div')({
  display: 'flex',
  color: '#707070',
})

export const PositionNameAndJOCompanyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2px',
  color: '#FFFFFF',
})

export const MidSectionContainer = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  width: '100%',
  minHeight: '140px',
  justifyContent: 'space-between',
  borderRadius: '0 0 16px 16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const SubMidSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

export const DetailsSubMidSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  color: '#FFFFFF',
  margin: 'auto',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '25px',
  letterSpacing: '0em',
  textAlign: 'center',
})

export const DetailsSubMidSectionGreyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  color: '#707070',
  margin: 'auto',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '25px',
  letterSpacing: '0em',
  textAlign: 'center',
})

export const SubMidSectionContainer2 = styled('div')({
  textAlign: 'left',
  display: 'flex-end',
  flexDirection: 'row-reverse',
  marginTop: '10px',
})

export const InnerIconContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

export const InnerSubIconContainer = styled('div')({
  textAlign: 'right',
  alignItems: 'right',
})

export const FooterContainer = styled('div')({
  overflow: 'hidden',
  marginTop: '7px',
  display: 'flex',
  flexDirection: 'row',
  minHeight: '40px',
  maxHeight: '40px',
  borderTop: '1px solid #F0F0F0',
  paddingBottom: '15px',
  justifyContent: 'space-between',
})

export const FooterCompanyContainer = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  position: 'relative',
  marginTop: '10px',
  width: '260px',
  bottom: 0,
  minHeight: '40px',
  maxHeight: '40px',
  justifyContent: 'flex-start',
})

export const SubFooterContainer = styled('div')``

export const SubFooterContainer2 = styled('div')`
  display: flex;
  align-items: center;
`

export const CircularProgressWithLabelContainer = styled('div')({
  marginLeft: '20px',
  marginTop: '10px',
  position: 'relative',
  display: 'inline-flex',

  borderRadius: '0px',
})

export const CircularProgressWithLabelSubContainer = styled('div')({
  top: -20,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const FilledText = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  textAlign: 'center',
  justifyContent: 'center',
})

export const RatioText = styled('div')({
  display: 'flex',
  fontSize: '25px',
  fontWeight: 700,
  color: '#707070',
})

export const BodyLabelText = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: '7px',
})

export const TimeLeftText = styled('div')({
  fontSize: '16px',
  fontWeight: 700,
  marginBottom: '7px',
  color: '#707070',
})

export const PercentLeftText = styled('div')({
  fontSize: '14px',
  fontWeight: 700,
  marginBottom: '7px',
  color: '#707070',
})

export const AdditionalAvatarText = styled('div')({
  fontSize: '10px',
  verticalAlign: 'bottom',
  fontWeight: 600,
  marginLeft: '10px',
  marginTop: '7px',
})

export const FooterCompanyHeaderText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#FFFFFF',
})

export const FooterCompanyDescriptionText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  color: '#FFFFFF',
})
