import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ArrowIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='8'
			height='11'
			viewBox='0 0 8 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.28811 10.0473L7.08811 5.24726L2.28811 0.447266L0.92811 1.80727L4.35211 5.24726L0.912109 8.68726L2.28811 10.0473Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
