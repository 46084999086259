import {Grid, IconButton} from '@mui/material'
import {styled} from '@mui/material/styles'
import {FormikAutocomplete} from '@ui/components'
import Icon from '@ui/icons'
import FormikNumericFormat from '@ui/numeric-format/FormikNumericFormat'
import React from 'react'
import {useRouteMatch} from 'react-router-dom'
import FormikTextField from '../../ui/mui-text-field/FormikTextField'
import {stateNames} from './constants'

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const CloseButton = styled(IconButton)({
  display: 'none',
  height: 28,
})

const ButtonContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  right: -28,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 28,

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
})

const Container = styled(Grid)(({theme}) => ({
  position: 'relative',

  '& + &': {
    marginTop: theme.spacing(4),
  },

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
}))

const Label = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textAlign: 'right',
  paddingRight: '20px',
})

const Field = styled(Grid)({
  position: 'relative',
})

interface IProps {
  index: number
  remove: (index: number) => void
  compensationCodes: {state: string}[]
}

export default function CompensationCodeItem({index, remove, compensationCodes}: IProps) {
  const {path} = useRouteMatch()
  const isPlatformAdmin = path.startsWith('/platform-admin')

  return (
    <Container container xs={12} columnSpacing={2} rowSpacing={4}>
      <Label item xs={3}>
        Worker&apos;s
        <br />
        Compensation Code<Red>*</Red>:
      </Label>

      <Field item xs={3}>
        <FormikTextField
          name={`compensationCodes.${index}.code`}
          placeholder="CA-2095"
          inputProps={{
            maxLength: 50,
            readOnly: !isPlatformAdmin,
          }}
        />
      </Field>

      <Field item xs={6}>
        <FormikAutocomplete
          name={`compensationCodes.${index}.state`}
          options={stateNames}
          placeholder="California"
          defaultValue={compensationCodes[index]?.state.toString()}
          readOnly={!isPlatformAdmin}
        />
        {index !== 0 && isPlatformAdmin && (
          <ButtonContainer>
            <CloseButton color="error" onClick={() => remove(index)}>
              <Icon icon="close" />
            </CloseButton>
          </ButtonContainer>
        )}
      </Field>

      {/* <Label item xs={3}>
        Mark-up Rates<Red>*</Red>:
      </Label>

      <Field item xs={3}>
        <FormikNumericFormat name={`compensationCodes.${index}.markUpRate`} suffix="%" />
      </Field>

      <Label item xs={3}>
        Carry Over Rates:
      </Label>

      <Field item xs={3}>
        <FormikNumericFormat name={`compensationCodes.${index}.carryOverRate`} suffix="%" />
      </Field> */}
    </Container>
  )
}
