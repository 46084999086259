/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import type {ILoader} from './types'
import S from './styles/loader.styles'

export function LoadingBoundary({children, isLoading, size, positioned, page, ...props}: ILoader) {
  return isLoading === true ? (
    <S.Loader
      data-testid="isLoadingSpinner"
      {...props}
      positioned={positioned}
      size={size}
      className="main"
    />
  ) : (
    <>
      {React.Children.map(
        children,
        (child) => React.isValidElement(child) && React.cloneElement(child, {...props}),
      )}
    </>
  )
}

LoadingBoundary.defaultProps = {
  size: 'medium',
  'data-testid': 'loader',
  positioned: 'absolute',
}
