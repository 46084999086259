import ErrorBoundary from '@ui/error'
import HeaderContainer from '@ui/header-container/HeaderContainer'
import LoadingBoundary from '@ui/loader'
import React, {useEffect} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {IInitialPositionValues, IPosition} from '~/src/models/position'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import PositionForm from '../../common/positions/PositionForm'
import Title from '../../layout/Title'

const Container = styled.div`
  position: relative;
  flex: 1;
`
interface IParams {
  orderId: string
}

export default function EditCompanyPositionPage() {
  const {orderId}: IParams = useParams()

  const {
    data: position,
    refetch: positionRefetch,
    isLoading: positionDataLoading,
    error: positionError,
  } = useGetPositionByIdQuery({id: orderId})
  const initialValues: IInitialPositionValues = position
    ? {
        id: position.id,
        createdBy: position.createdBy,
        versionKey: position.versionKey,
        companyId: position.companyId,
        onsiteFacilityName: position.facilityName,
        onsiteFacilityAddress: position.facilityAddress.display,
        position: position.name,
        departmentName: position.departmentName,
        workersCompCode: position.compensationCode,
        startTime: position.startTime,
        endTime: position.endTime,
        manager: position.manager,
        jobDescription: position.jobDescription,
      }
    : {}

  useEffect(() => {
    void positionRefetch()
  }, [positionRefetch])

  return (
    <Container>
      <Title pageTitle="Edit Position" />
      <ErrorBoundary error={positionError}>
        <LoadingBoundary isLoading={positionDataLoading}>
          <HeaderContainer header={<div style={{fontWeight: 'bold'}}>Edit Position</div>}>
            <PositionForm initialValues={initialValues} isEdit={true} isMspAdmin={true} />
          </HeaderContainer>
        </LoadingBoundary>
      </ErrorBoundary>
    </Container>
  )
}
