import Box from '@mui/material/Box'
import JobOrderCard from '@ui/card/JobOrderCard'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import type {IJobOrder, TPagedJobOrderInputParams} from '~/src/models/jobOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetJobOrdersQuery} from '~/src/store/apis/job-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../../layout/Title'

const MspAgencyJobOrderMainContainer = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
}

export default function MspAgencyJobOrderMain(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [selectedJobOrderId, setSelectedJobOrderId] = useState('')
  const location = useLocation()
  const prefillId = new URLSearchParams(location.search).get('prefillId')
  const [prefillDialogOpen, setPrefillDialogOpen] = useState(false)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const dispatch = useDispatch()

  const inputParams: TPagedJobOrderInputParams = {
    pageNumber: currPageIdx,
    pageSize: 12,
    agencyId: loggedInAgencyId,
  }

  if (prefillId) {
    inputParams.filterBy = 'CurrentOrders'
  }

  const {data, refetch, error, isLoading, isFetching} = useGetJobOrdersQuery(inputParams)

  const path = [...location.pathname.split('/')]

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])
  const totalOrders = data?.totalJobOrders

  const handleClosePrefillDialog = () => {
    setPrefillDialogOpen(false)
  }

  const handleOpenPrefillDialog = (id: string) => {
    setSelectedJobOrderId(id)
    setPrefillDialogOpen(true)
  }

  const handleConfirmPrefill = () => {
    if (prefillId) {
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.PREFILL_JOB_ORDER}/${encodeURIComponent(
            selectedJobOrderId,
          )}/${encodeURIComponent(prefillId)}`,
        ),
      )
    } else {
      dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.DASHBOARD}}`))
    }
  }

  return (
    <>
      <BasicConfirmationDialog
        isOpen={prefillDialogOpen}
        header="Confirm Job Order Selection"
        bigBlurb="Select This Job Order?"
        smallBlurb=""
        handleCancel={handleClosePrefillDialog}
        handleSubmit={handleConfirmPrefill}
      />
      <MspAgencyJobOrderMainContainer.Container>
        <Title pageTitle={prefillId ? 'Please Select a Job Order' : 'Job Orders'} />
        <ErrorBoundary error={error}>
          <LoadingBoundary isLoading={isLoading || isFetching}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: 1200,
                borderRadius: 1,
              }}>
              {data?.jobOrders.map((jobOrder: IJobOrder, index: number) => {
                const totalCandidatesNeeded = jobOrder.agencyDistributions
                  .map(
                    (distribution, indexOne: number) =>
                      distribution.agencyCandidateDistributions.requested,
                  )
                  .reduce((prev: number, curr: number) => prev + curr, 0)

                const totalCandidatesFilled = jobOrder.agencyDistributions
                  .map(
                    (distribution, indexOne: number) =>
                      distribution.agencyCandidateDistributions.fulfilled,
                  )
                  .reduce((prev: number, curr: number) => prev + curr, 0)

                return (
                  <JobOrderCard
                    key={jobOrder.id}
                    jobOrder={jobOrder}
                    onCardClick={prefillId ? handleOpenPrefillDialog : undefined}
                    currentlyfilled={totalCandidatesFilled}
                    totalrequested={totalCandidatesNeeded}
                    style={{marginBottom: '20px'}}
                  />
                )
              })}
            </Box>
            <StyledPagination
              page={currPageIdx}
              totalRecords={totalOrders}
              recordsPerPage={12}
              onChange={handlePaginationChange}
            />
          </LoadingBoundary>
        </ErrorBoundary>
      </MspAgencyJobOrderMainContainer.Container>
    </>
  )
}
