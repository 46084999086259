import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function FacebookIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='social / Facebook'>
				<g id='Facebook'>
					<rect id='Rectangle' width='48' height='48' rx='24' fill='#4267B2' />
					<path
						id='Vector'
						d='M25.6025 39.9998V25.4031H30.502L31.2355 19.7145H25.6025V16.0825C25.6025 14.4356 26.0599 13.3131 28.4218 13.3131L31.4341 13.3117V8.22391C30.9129 8.15484 29.1249 8 27.0446 8C22.7015 8 19.7281 10.6509 19.7281 15.5194V19.7147H14.8159V25.4033H19.728V40L25.6025 39.9998Z'
						fill='white'
					/>
				</g>
			</g>
		</Svg>
	);
}
