/* eslint-disable complexity */
import Box from '@mui/material/Box'
import {FormikAutocomplete} from '@ui/autocomplete'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {IUserBase} from '~/src/models'
import type {
  ApplicantInterview,
  ApplicantInterviewFormikSubmit,
  ApplicantInterviewRestaurantFormik,
} from '~/src/models/applicant'
import {
  AvailabilityQuestions,
  BehavioralQuestionsSubset,
  BigBold,
  GreySubTitle,
  InformationCardContainer,
  InitialQuestions,
  NewInterview,
  QAndADropdown,
  QAndATextArea,
  QuestionsBox,
  StyledButton,
  TInterviewTypes,
} from './CommonInterviewComponents'

interface IProps {
  calculateSubmit: (template: TInterviewTypes, values: ApplicantInterviewFormikSubmit) => void
  selectedInterview?: ApplicantInterview
  currentUser?: IUserBase | null
}

const RestaurantForm = ({calculateSubmit, selectedInterview, currentUser}: IProps) => {
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        void calculateSubmit('restaurant', values as unknown as ApplicantInterviewFormikSubmit)
      }}
      initialValues={
        {
          company: selectedInterview?.company ?? [],
          position: selectedInterview?.position ?? [],
          employmentType: selectedInterview?.employmentType ?? [],

          discovery:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'discovery')
              ?.value ?? null,
          employed:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'employed')
              ?.value ?? null,
          dislike:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'dislike')
              ?.value ?? null,
          like:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'like')
              ?.value ?? null,
          extraDuties:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'extraDuties')
              ?.value ?? '',

          education:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'education')
              ?.value ?? null,
          educationType:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'educationType',
            )?.value ?? '',
          heavyLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'heavyLift')
              ?.value ?? null,
          lightLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'lightLift')
              ?.value ?? null,
          howManyLift:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'howManyLift')
              ?.value ?? '',
          standing:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'standing')
              ?.value ?? null,
          whyNotStand:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'whyNotStand')
              ?.value ?? '',
          languages:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'languages')
              ?.value ?? null,
          transportation:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'transportation',
            )?.value ?? null,
          foodHandlerCert:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'foodHandlerCert',
            )?.value ?? null,
          foodCertsOther:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'foodCertsOther',
            )?.value ?? null,
          whichFoodCerts:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'whichFoodCerts',
            )?.value ?? '',
          nonSlipShoes:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'nonSlipShoes',
            )?.value ?? null,
          whichRestaurantJobs:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'whichRestaurantJobs',
            )?.value ?? '',
          fastPaced:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'fastPaced')
              ?.value ?? null,
          angryCustomer:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'angryCustomer',
            )?.value ?? '',
          workStyle:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'workStyle')
              ?.value ?? null,

          wage:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'wage')
              ?.value ?? '',
          flexible:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'flexible')
              ?.value ?? null,
          drugTest:
            selectedInterview?.interviewAnswers.find((interview) => interview.key === 'drugTest')
              ?.value ?? null,

          outsideInjury:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'outsideInjury',
            )?.value ?? null,
          reportInjuryWho:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWho',
            )?.value ?? null,
          reportInjuryWhen:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportInjuryWhen',
            )?.value ?? null,
          reportLiquid:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportLiquid',
            )?.value ?? null,
          reportPolicyBreaking:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'reportPolicyBreaking',
            )?.value ?? null,
          difficultCoworker:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'difficultCoworker',
            )?.value ?? '',

          oneOrManyJobs:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'oneOrManyJobs',
            )?.value ?? '',
          missingShift:
            selectedInterview?.interviewAnswers.find(
              (interview) => interview.key === 'missingShift',
            )?.value ?? '',
          notes: selectedInterview?.notes ?? '',
        } as unknown as ApplicantInterviewRestaurantFormik
      }
      validationSchema={Yup.object({
        company: Yup.array().min(1, 'Required').required('Required'),
        position: Yup.array().min(1, 'Required').required('Required'),
        employmentType: Yup.array().min(1, 'Required').required('Required'),

        discovery: Yup.string().nullable(),
        employed: Yup.string().nullable(),
        dislike: Yup.string().nullable(),
        like: Yup.string().nullable(),
        extraDuties: Yup.string().nullable(),

        education: Yup.string().nullable(),
        educationType: Yup.string().nullable(),
        heavyLift: Yup.string().nullable(),
        lightLift: Yup.string().nullable(),
        howManyLift: Yup.string().nullable(),
        standing: Yup.string().nullable(),
        whyNotStand: Yup.string().nullable(),
        languages: Yup.string().nullable(),
        transportation: Yup.string().nullable(),
        foodHandlerCert: Yup.string().nullable(),
        foodCertsOther: Yup.string().nullable(),
        whichFoodCerts: Yup.string().nullable(),
        nonSlipShoes: Yup.string().nullable(),
        whichRestaurantJobs: Yup.string().nullable(),
        fastPaced: Yup.string().nullable(),
        angryCustomer: Yup.string().nullable(),
        workStyle: Yup.string().nullable(),

        wage: Yup.string().nullable(),
        flexible: Yup.string().nullable(),
        drugTest: Yup.string().nullable(),

        outsideInjury: Yup.string().nullable(),
        reportInjuryWho: Yup.string().nullable(),
        reportInjuryWhen: Yup.string().nullable(),
        reportLiquid: Yup.string().nullable(),
        reportPolicyBreaking: Yup.string().nullable(),
        difficultCoworker: Yup.string().nullable(),

        oneOrManyJobs: Yup.string().nullable(),
        missingShift: Yup.string().nullable(),
        notes: Yup.string().nullable().required('Required'),
      })}>
      {({
        values,
        errors: subErrors,
        setValues,
        setFieldValue,
        isSubmitting,
        handleSubmit: handleSubmitSub,
      }) => {
        return (
          <Form>
            <InformationCardContainer sx={{justifyContent: 'flex-start'}}>
              <NewInterview
                name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                type="restaurant"
                setValue={setFieldValue}
                selectedInterview={selectedInterview}
                valuesSubset={{
                  company: values.company,
                  position: values.position,
                  employmentType: values.employmentType,
                }}
              />
              <BigBold style={{marginTop: '20px'}}>Interview Questions</BigBold>
              <QuestionsBox>
                <InitialQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Skills
                </GreySubTitle>
                <QAndADropdown
                  question="Have you ever received any education or safety training related to the restaurant
                industry before?"
                  component={
                    <FormikAutocomplete
                      disableClearable
                      name="education"
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                />
                {values.education === 'Yes' && (
                  <QAndATextArea
                    question="If so, what type? (food production, chemicals handling, other)"
                    component={
                      <FormikTextField
                        name="educationType"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Are you able to lift more than 50lbs? (HL)"
                  component={
                    <FormikAutocomplete
                      name="heavyLift"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Are you able to lift more than 25-30lbs? (LL)"
                  component={
                    <FormikAutocomplete
                      name="lightLift"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.lightLift === 'No' && (
                  <QAndATextArea
                    question="If no, how many without restriction?"
                    component={<FormikTextField name="howManyLift" />}
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Are you able to stand for long periods of time?"
                  component={
                    <FormikAutocomplete
                      name="standing"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.standing === 'No' && (
                  <QAndATextArea
                    question="If no, why?"
                    component={
                      <FormikTextField
                        name="whyNotStand"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="What languages can you speak?"
                  component={
                    <FormikAutocomplete
                      name="languages"
                      options={['English Only', 'Spanish Only', 'Bilingual']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="What form of transportation do you have?"
                  component={
                    <FormikAutocomplete
                      name="transportation"
                      options={['Own vehicle', 'Bus ', 'Carpool', 'None']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have a food handlers’ certification? May we have a copy?"
                  component={
                    <FormikAutocomplete
                      name="foodHandlerCert"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you have any other food industry certifications?"
                  component={
                    <FormikAutocomplete
                      name="foodCertsOther"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                {values.foodCertsOther === 'Yes' && (
                  <QAndATextArea
                    question="If yes, which ones and for how long?"
                    component={
                      <FormikTextField
                        name="whichFoodCerts"
                        multiline
                        rows={3}
                        inputProps={{maxLength: 1000}}
                      />
                    }
                    sx={{marginTop: '20px'}}
                  />
                )}
                <QAndADropdown
                  question="Do you have nonslip shoes?"
                  component={
                    <FormikAutocomplete
                      name="nonSlipShoes"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="What restaurant job can you perform the best?"
                  component={
                    <FormikTextField
                      name="whichRestaurantJobs"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Are you comfortable working in a fast-paced environment?"
                  component={
                    <FormikAutocomplete
                      name="fastPaced"
                      disableClearable
                      options={['Yes', 'No']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="How do you deal with an angry customer?"
                  component={
                    <FormikTextField
                      name="angryCustomer"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndADropdown
                  question="Do you prefer to work in a team or independently?"
                  component={
                    <FormikAutocomplete
                      name="workStyle"
                      options={['Team', 'Independent']}
                      placeholder="Select"
                      sx={{width: '100%'}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Availability
                </GreySubTitle>
                <AvailabilityQuestions />
                <GreySubTitle style={{marginTop: '30px', marginBottom: '30px'}}>
                  Behavioral Questions: (We’d like to see our EE’s thought process)
                </GreySubTitle>
                <BehavioralQuestionsSubset />
                <QAndATextArea
                  question="Do you enjoy doing one job at a time or many jobs almost all at once?"
                  component={<FormikTextField name="oneOrManyJobs" />}
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="What happened when some else didn’t show up for their shift?"
                  component={
                    <FormikTextField
                      name="missingShift"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />
                <QAndATextArea
                  question="Additional Notes"
                  isRequired
                  component={
                    <FormikTextField
                      name="notes"
                      multiline
                      rows={3}
                      inputProps={{maxLength: 1000}}
                    />
                  }
                  sx={{marginTop: '20px'}}
                />

                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <StyledButton
                    onClick={() => {
                      handleSubmitSub()
                    }}
                    variant="contained"
                    color="primary"
                    sx={{marginTop: '20px', width: '168px'}}>
                    Finish & Save
                  </StyledButton>
                </Box>
              </QuestionsBox>
            </InformationCardContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default React.memo(RestaurantForm)
