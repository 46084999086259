import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EditVariantIcon (props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0)'>
				<path
			    id='edit-variant-icon'
					d='M11.3335 2.00053C11.5086 1.82543 11.7165 1.68654 11.9452 1.59178C12.174 1.49702 12.4192 1.44824 12.6668 1.44824C12.9145 1.44824 13.1596 1.49702 13.3884 1.59178C13.6172 1.68654 13.8251 1.82543 14.0002 2.00053C14.1753 2.17562 14.3142 2.38349 14.4089 2.61227C14.5037 2.84104 14.5524 3.08624 14.5524 3.33386C14.5524 3.58148 14.5037 3.82668 14.4089 4.05546C14.3142 4.28423 14.1753 4.4921 14.0002 4.66719L5.00016 13.6672L1.3335 14.6672L2.3335 11.0005L11.3335 2.00053Z'
					stroke='#858585'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round' />
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='16' height='16' fill='white' transform='translate(0 0.000976562)' />
				</clipPath>
			</defs>
		</Svg>
	);
}

