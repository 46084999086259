import styled from 'styled-components'

export const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
    flex-basis: 25%;
  `,
}

export const CandidateContainerMargin = styled('div')`
  margin-top: 14px;
`

export const HeaderText = styled('div')<{$candidateHeaderSelected: boolean}>`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

export const FlexContainer = styled('div')`
  display: flex;
  margin-bottom: 16px;
`

export const HeaderContainer = styled('div')`
  display: flex;
  max-height: 35px;
  height: 35px;
  justify-content: space-between;
  border: 1px solid #707070;
  border-radius: 9px;
  margin-bottom: 16px;
  margin-right: 11px;
`
export const InnerHeaderContainer = styled('div')`
  display: flex;
  max-height: 100%;
  height: 100%;
  align-items: center;
`

export const InnerButtonPillHeaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

export const DataCleanupContainer = styled('div')`
  display: flex;
  width: 78px;
  max-width: 78px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 9px;
  margin-right: 10px;
  cursor: pointer;
  align-items: center;
`

export const InnerDataHeaderContainer = styled('div')`
  max-height: 100%;
  text-align: center;
`

export const FlexColumnContianer = styled('div')`
  display: flex;
  flex-direction: column;
`
