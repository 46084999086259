import MoreVertIcon from '@mui/icons-material/MoreVert'
import {Tooltip} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, {useContext, useState} from 'react'
import {downloadFileApi} from '~/src/store/apis/document-api'
import {DocumentApplicantContext} from '~/src/store/context/DocumentApplicantContext'
import {useAppSelector} from '~/src/store/store-hooks'
import {ReactComponent as SignedFileIcon} from '../../../assets/images/FileWithScribbleIcon.svg'
import DeleteFileDialog from './Dialogs/DeleteFileDialog'
import {FolderContainer, FolderNameText, ThreeDotSetting} from './styles/documentStyles'

interface IProps {
  buttonSubmitting: boolean
  documentId: string | undefined
  selectedFolder: {id: string; name: string}
  selectedFile: {id: string; name: string; extension: string}
  folderInfoIndex: number
  setButtonSubmitting: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  setSelectedFolder: React.Dispatch<React.SetStateAction<undefined>>
}

export default function SignedFiles({
  buttonSubmitting,
  documentId,
  selectedFolder,
  selectedFile,
  folderInfoIndex,
  setButtonSubmitting,
  setIsSubmitting,
  setSelectedFolder,
}: IProps) {
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState(false)
  const [threeDotStyle, setThreeDotStyle] = useState({display: 'none'})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const stateToken = useAppSelector((state) => state.root.token)

  const documentContext = useContext(DocumentApplicantContext)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = async () => {
    setButtonSubmitting(true)
    try {
      await downloadFileApi(
        documentId,
        selectedFolder?.name,
        selectedFile.id,
        stateToken,
        documentContext?.loggedInAgencyId,
      )
        .then((response) => response)
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${selectedFile.name}`)

          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })

      setAnchorEl(null)
      setThreeDotStyle({display: 'none'})
      setButtonSubmitting(false)
    } catch (err: unknown) {
      setButtonSubmitting(false)
      console.error(err)
    }
  }

  const handleOpenDeleteFileDialog = () => {
    setOpenDeleteFileDialog(true)
  }

  const handleCancelDeleteFileDialog = () => {
    setOpenDeleteFileDialog(false)
    handleClose()
  }

  return (
    <FolderContainer
      key={folderInfoIndex}
      onMouseEnter={(e) => {
        setThreeDotStyle({display: 'flex'})
      }}
      onMouseLeave={(e) => {
        setThreeDotStyle({display: 'none'})
      }}>
      <DeleteFileDialog
        documentId={documentId}
        isOpen={openDeleteFileDialog}
        folderInfo={selectedFolder}
        fileId={selectedFile?.id}
        handleCancel={handleCancelDeleteFileDialog}
        setIsSubmitting={setIsSubmitting}
        setSelectedFolder={setSelectedFolder}
      />
      <ThreeDotSetting style={threeDotStyle}>
        <IconButton
          aria-label="openFileMenu-button"
          data-testid="openFileMenu-button"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          aria-label="fileMenu"
          data-testid="fileMenu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem
            disabled={buttonSubmitting}
            aria-label="fileMenu-download"
            data-testid="fileMenu-download"
            onClick={handleDownload}>
            download
          </MenuItem>
          <MenuItem
            aria-label="fileMenu-delete"
            data-testid="fileMenu-delete"
            onClick={handleOpenDeleteFileDialog}>
            delete
          </MenuItem>
        </Menu>
      </ThreeDotSetting>
      {/* keeps div from jumping */}
      <div style={{height: threeDotStyle.display === 'none' ? 40 : 0}} />
      <SignedFileIcon />
      <Tooltip placement="right-end" title={selectedFile.name}>
        <FolderNameText
          style={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          {selectedFile.name}
        </FolderNameText>
      </Tooltip>
    </FolderContainer>
  )
}
