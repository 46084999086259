import RingVolumeIcon from '@mui/icons-material/RingVolume'
import {SvgIcon} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import {Box} from '@mui/system'
import UpdateCandidatePictureDialog from '@ui/dialog/UploadCandidatePictureDialog'
import {flagSwitch} from '@ui/flags/FlagSwitch'
import React, {KeyboardEvent, useEffect, useRef, useState} from 'react'
import {ReactComponent as AlreadyAssignedIcon} from '~/src/assets/images/assigned.svg'
import {ReactComponent as AvatarEditIcon} from '~/src/assets/images/AvatarEditIcon.svg'
import type {Applicant} from '~/src/models/applicant'
import {CandidateNameText, ListItemContent, StyledListItem} from './styles/CandidateListStyles'

export type TProcessedCandidate = {
  candidate: Applicant
  conflictingJobOrderIds: string[]
}

interface CandidateListProps {
  candidates: TProcessedCandidate[]
  onCandidateClicked: (value: TProcessedCandidate, index: number) => void
  handleListNavigate: (e: KeyboardEvent) => void
  selectedCandidate: TProcessedCandidate
}

const CandidateList = ({
  candidates,
  onCandidateClicked,
  handleListNavigate,
  selectedCandidate,
}: CandidateListProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const [openUploadDialog, setOpenUploadDialog] = useState(false)

  const handleDialogOpen = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  const handleDialogClose = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])
  if (candidates.length > 0) {
    return (
      <>
        <UpdateCandidatePictureDialog
          agencyId={selectedCandidate?.candidate?.agencyId}
          candidateId={selectedCandidate?.candidate?.id}
          candidateProfilePic={
            selectedCandidate?.candidate?.profilePictureName?.length > 0
              ? `${selectedCandidate?.candidate?.profilePictureName}?cache=${new Date().getTime()}`
              : undefined
          }
          isOpen={openUploadDialog}
          handleClose={handleDialogClose}
        />
        <List dense sx={{width: '100%', maxWidth: 360}} onKeyDown={handleListNavigate}>
          {candidates.map((candidateDatum, index) => {
            const selected = candidateDatum.candidate.id == selectedCandidate.candidate.id
            return (
              <StyledListItem
                key={candidateDatum.candidate.id}
                disablePadding
                value={candidateDatum.candidate.id}
                $currentCandidate={selected}>
                <ListItemButton
                  ref={index === 0 ? ref : null}
                  sx={{padding: '8px 12px'}}
                  onClick={() => onCandidateClicked(candidateDatum, index)}>
                  <ListItemContent>
                    <ListItemAvatar>
                      {selected ? (
                        <Badge
                          sx={{cursor: 'pointer'}}
                          onClick={handleDialogOpen}
                          overlap="circular"
                          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                          badgeContent={<AvatarEditIcon alt="edit-profile-icon" />}>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                            badgeContent={flagSwitch(candidateDatum?.candidate?.flag, false, true)}>
                            <Avatar
                              sx={{
                                backgroundColor: '#03a9fc',
                                border: selected ? '2px solid #03A9FC' : '',
                              }}
                              aria-label="candidate icon"
                              src={`${
                                candidateDatum.candidate.profilePictureName
                              }?cache=${new Date().getTime()}`}
                            />
                          </Badge>
                        </Badge>
                      ) : (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                          badgeContent={flagSwitch(candidateDatum.candidate.flag, false, false)}>
                          <Avatar
                            sx={{
                              backgroundColor: '#03a9fc',
                              border: selected ? '2px solid #03A9FC' : '',
                            }}
                            aria-label="candidate icon"
                            src={`${
                              candidateDatum.candidate.profilePictureName
                            }?cache=${new Date().getTime()}`}
                          />
                        </Badge>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <CandidateNameText sx={{fontWeight: 700}} $currentCandidate={selected}>
                          {`${candidateDatum.candidate.firstName} ${candidateDatum.candidate.lastName}`}
                        </CandidateNameText>
                      }
                      secondary={
                        <CandidateNameText $currentCandidate={selected}>
                          {`${candidateDatum.candidate.address.city ?? ''} ${
                            candidateDatum.candidate.address.state ?? ''
                          }`}
                        </CandidateNameText>
                      }
                    />
                    {candidateDatum.candidate.lockStatus ? (
                      <RingVolumeIcon sx={{color: selected ? '#FFFF' : 'black'}} />
                    ) : candidateDatum.conflictingJobOrderIds.length > 0 ? (
                      <Tooltip title="Already Assigned" placement="top">
                        <div>
                          <SvgIcon
                            component={AlreadyAssignedIcon}
                            sx={{fill: selected ? '#FFFF' : 'black'}}
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                  </ListItemContent>
                </ListItemButton>
              </StyledListItem>
            )
          })}
        </List>
      </>
    )
  } else {
    return <Box>There are no candidates available at this time. Please try again later.</Box>
  }
}

export default CandidateList
