import React from 'react'
import {useRouteMatch} from 'react-router-dom'
import {FieldArray, FormikValues} from 'formik'

import {createCompensationCodeItem} from './AddCompanyForm'
import CompensationCodeItem from './CompensationCodeItem'
import Mui from '@ui/mui'

interface IProps {
  values?: FormikValues
}

export default function CompensationCodeList(props: IProps) {
  const {compensationCodes} = props.values ?? {}
  const {path} = useRouteMatch()
  const isPlatformAdmin = path.startsWith('/platform-admin')

  return (
    <FieldArray name="compensationCodes">
      {({push, remove}) => (
        <React.Fragment>
          {compensationCodes?.length > 0 &&
            compensationCodes?.map((compensationCode: {id: string}, index: number) => (
              <CompensationCodeItem
                key={compensationCode.id}
                compensationCodes={compensationCodes}
                index={index}
                remove={remove}
              />
            ))}

          <Mui.Grid item xs={3} />

          {isPlatformAdmin && (
            <Mui.Grid item xs={9}>
              <Mui.Button
                variant="text"
                size="small"
                sx={{color: 'primary.dark', p: 0, mt: 4}}
                onClick={() => push(createCompensationCodeItem())}>
                + Add Another Compensation Code
              </Mui.Button>
            </Mui.Grid>
          )}
        </React.Fragment>
      )}
    </FieldArray>
  )
}
