import Dialog from '@mui/material/Dialog'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {errorFunction} from '@utils/errorFunction'
import {Form as FormikForm, Formik} from 'formik'
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import {Red} from '~/src/components/msp/manage-job-orders/job-order-summary/job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import ReusableSnackbar from '~/src/components/Snackbar/ReusableSnackbar'
import {usePutCreateFolderMutation, usePutRenameFolderMutation} from '~/src/store/apis/document-api'
import {ReactComponent as XButton} from '../../../../assets/images/closeIcon.svg'
import {DocumentApplicantContext} from '../../../../store/context/DocumentApplicantContext'
import {
  Body,
  ButtonBox,
  HeaderContainer,
  HeaderText,
  StyledButton,
} from './styles/AddDocumentDialogStyles'
import {BodyContainer, TextFieldContainer, TextFieldLabelText} from './styles/NewFolderDialogStyles'

interface IProps {
  buttonSubmitting: boolean
  documentId: string | undefined
  isEdit: boolean
  isOpen: boolean
  handleCancel(): void
  setButtonSubmitting: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  selectedEditFolder?: string
}

export default function NewFolderDialog({
  buttonSubmitting,
  documentId,
  isEdit,
  isOpen,
  handleCancel,
  setIsSubmitting,
  setButtonSubmitting,
  selectedEditFolder,
}: IProps): React.ReactElement {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const [putRenameFolder] = usePutRenameFolderMutation()
  const [putCreateFolder] = usePutCreateFolderMutation()

  const documentContext = useContext(DocumentApplicantContext)

  const initialFormValues = isEdit
    ? {
        documentId: documentId,
        currentFolderName: selectedEditFolder?.name,
        newFolderName: selectedEditFolder?.name,
      }
    : {
        documentId: documentId,
        name: '',
        private: false,
      }

  const handleSubmit = async (values) => {
    const submitBody = isEdit
      ? {
          documentId: values.documentId,
          currentFolderName: values.currentFolderName,
          newFolderName: values?.newFolderName,
        }
      : {
          documentId: values.documentId,
          name: values.name,
        }

    if (isEdit) {
      await putRenameFolder({body: submitBody, agencyId: documentContext?.loggedInAgencyId})
        .unwrap()
        .then(() => handleCancel())
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    } else {
      await putCreateFolder({body: submitBody, agencyId: documentContext?.loggedInAgencyId})
        .unwrap()
        .then(() => handleCancel())
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    }
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {minWidth: 546, width: 546, height: 557, overflow: 'hidden'},
      }}>
      <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
      <HeaderContainer style={{minHeight: '72px', maxHeight: '72px'}}>
        <HeaderText style={{fontWeight: 600, fontSize: '16px'}}>
          {isEdit ? 'Edit Folder' : 'New Folder'}
        </HeaderText>
        <XButton
          aria-label="newFolderOrEdit-closeDialog-x-button"
          data-testid="newFolderOrEdit-closeDialog-x-button"
          onClick={() => {
            setIsSnackbarOpen(false)
            handleCancel()
          }}
          style={{cursor: 'pointer'}}
        />
      </HeaderContainer>
      <Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            private: Yup.boolean(),
          })}>
          {({values, resetForm}) => {
            return (
              <FormikForm>
                <BodyContainer>
                  <TextFieldContainer>
                    <TextFieldLabelText>
                      Folder Name <Red>*</Red>
                    </TextFieldLabelText>
                    <FormikTextField
                      name={isEdit ? 'newFolderName' : 'name'}
                      placeholder="Other Documents..."
                    />
                  </TextFieldContainer>
                </BodyContainer>

                <ButtonBox>
                  <StyledButton
                    disabled={buttonSubmitting}
                    data-testid="newFolderOrEdit-cancel-button"
                    aria-label="newFolderOrEdit-cancel-button"
                    style={{marginRight: '25px'}}
                    onClick={async () => {
                      setIsSnackbarOpen(false)
                      await resetForm(values)
                      handleCancel()
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button">
                    Cancel
                  </StyledButton>
                  <StyledButton
                    disabled={buttonSubmitting}
                    data-testid="newFolderOrEdit-saveFolder-button"
                    aria-label="newFolderOrEdit-saveFolder-button"
                    sx={{color: 'white'}}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(values)}>
                    {isEdit ? 'Save' : 'Create'}
                  </StyledButton>
                </ButtonBox>
              </FormikForm>
            )
          }}
        </Formik>
      </Body>
    </Dialog>
  )
}
