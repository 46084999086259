import {uppercase} from '.';

/*
 * crumbCase('modules') --> Modules
 * crumbCase('manage-companies') --> Manage Companies
 * crumbCase('users-and-roles') --> Users & Roles
 * crumbCase('msp') --> MSP
 */

export function toAmpersand(arg: string) {
	return arg.replace(/\band\b/gi, '&');
}

export function toMSPAllCaps(arg: string) {
	return /^msp$/i.test(arg) ? arg.toUpperCase() : arg;
}

export function kebobToUpperFirst(arg: string) {
	return arg.split('-').filter(Boolean).map(uppercase).join(' ');
}

export function crumbCase(arg: string): string {
	return kebobToUpperFirst(arg).split(' ').map(toMSPAllCaps).join(' ');
}
