import styled, {css} from 'styled-components'

import {resets, font, colors, truncate} from '~/src/config/theme'
import Icon from '@ui/icons'

const DropdownNumsStyles = {
  Container: styled.div`
    color: #52575c;
    display: inline-block;
    font-size: ${font.sizes.smaller};
    font-style: normal;
    letter-spacing: 0.10000000149011612px;
    min-width: 158px;
    position: relative;
  `,
  Button: styled.button.attrs((props) => props.type)(
    ({hasIcon, error}: {hasIcon: boolean; error?: boolean}) => css`
      ${resets.common}
      align-items: center;
      background-color: ${colors.white};
      border: 1px solid ${error ? colors.red : '#c4c4c4'};
      border-radius: 4px;
      color: inherit;
      display: flex;
      font-size: inherit;
      font-weight: inherit;
      height: 40px;
      justify-content: space-between;
      letter-spacing: inherit;
      line-height: 40px;
      padding: 0 ${hasIcon ? '24px' : '13px'};
      column-gap: 10px;
      max-width: 100%;
      width: 100%;
    `,
  ),
  Left: styled.div(
    ({error}: {error?: boolean}) => css`
      flex: 1;
      min-width: 0;
      overflow: hidden;
      display: flex;

      > div {
        ${truncate}
        color: ${error ? colors.red : 'inherit'};
        text-align: left;
      }
    `,
  ),
  LeftIcon: styled(Icon)`
    height: 12px;
    width: 12px;
    align-self: center;
    margin-right: 24px;
  `,
  Right: styled.div``,
  RightIcon: styled(Icon)(
    ({error}: {error?: boolean}) => css`
      color: ${error ? colors.red : '#c4c4c4'};
    `,
  ),
  OptionList: styled.ul`
    background-color: ${colors.white};
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 1;
  `,
  Option: styled.li`
    ${truncate}
    cursor: pointer;
    flex: 1;
    height: 40px;
    line-height: 40px;
    list-style-type: none;
    padding: 0 13px;
  `,
}

export default DropdownNumsStyles
