import {Box, Button as MuiButton, IconButton as MuiIconButton} from '@mui/material'
import styled from 'styled-components'
import {makeStyles} from '@mui/styles'
import {Icon} from '@ui/components'

export const Title = styled(Box)(({theme}) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: 'bolder',
}))

export const Blue = styled('span')(({theme}) => ({
  color: theme.palette.primary.main,
}))

export const IconButton = styled(MuiIconButton)(({theme}) => ({
  color: theme.palette.common.white,
  height: 38,
  width: 38,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    color: theme.palette.common.white,
  },
}))

export const Header = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '56px',
  paddingRight: '45px',
  backgroundColor: theme.palette.grey[50],
}))

export const Body = styled('div')(({theme}) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
}))

export const Button = styled(MuiButton)(({theme}) => ({
  width: 140,

  '& + &': {
    marginLeft: theme.spacing(2),
  },
}))

export const Footer = styled('div')(({theme}) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
  display: 'flex',
  justifyContent: 'center',
}))

export const ICON_SIZE = 12
export const MARGIN = 21

export const Adornment = styled(Icon)({
  width: ICON_SIZE,
  margin: `0 ${MARGIN}px 0 ${MARGIN / 2}px`,
  display: 'flex',
  alignItems: 'center',
})

export const AbsoluteAdornment = styled(Adornment)({
  margin: `0 ${MARGIN}px`,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
})

export const useStyles = makeStyles({
  textField: {
    '&&&': {
      paddingLeft: 0,
    },
  },

  selectTextField: {
    '&&&': {
      paddingLeft: MARGIN * 2 + ICON_SIZE,
    },
  },
})

export default {
  Adornment,
  AbsoluteAdornment,
  Blue,
  Body,
  Button,
  Footer,
  Header,
  IconButton,
  Title,
  useStyles,
}
