import React from 'react'
import {useField} from 'formik'
import StyledTextField from '../mui-text-field/FormikTextField'

interface IProps {
  name: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function FormikSelect({name, ...props}: any) {
  const [field, meta, helpers] = useField(name)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const configTextField: any = {
    ...field,
    ...props,
    fullWidth: true,
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return <StyledTextField select {...configTextField} />
}
