import {createApi} from '@reduxjs/toolkit/query/react'
import type {
  ICompanyBase,
  IDepartment,
  ISuccessMessage,
  TCompaniesPayload,
  TPagedCompaniesPayload,
  TUpdateStatusPayload,
} from '../../models'
import {baseQuery} from '../base-query'

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: baseQuery,
  tagTypes: ['Company'],
  endpoints: (builder) => ({
    getCompanies: builder.query<TCompaniesPayload, TPagedCompaniesPayload>({
      providesTags: ['Company'],
      query: ({pageNumber, pageSize}) => ({
        url: `/api/Company/GetCompanies`,
        method: 'GET',
        params: {pageNumber, pageSize},
      }),
    }),

    getCompanyNameById: builder.query<string, TPagedCompaniesPayload>({
      providesTags: ['Company'],
      query: ({id}) => ({
        url: `/api/Company/GetCompanyNameById/${id}`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),

    getCompanyNames: builder.query<string[], string>({
      providesTags: ['Company'],
      query: (agencyId) => ({
        url: `/api/Company/getCompanyNames/Names`,
        method: 'GET',
        params: {agencyId},
      }),
    }),

    getAllCompanyNames: builder.query<string[], {pageNumber: number; pageSize: number}>({
      providesTags: ['Company'],
      query: ({pageNumber, pageSize}) => ({
        url: `/api/Company/getAllCompanyNames`,
        method: 'GET',
        params: {pageNumber, pageSize},
      }),
    }),

    getAllCompanyNamesAndIds: builder.query<
      {id: string; name: string}[],
      {pageNumber: number; pageSize: number}
    >({
      providesTags: ['Company'],
      query: ({pageNumber, pageSize}) => ({
        url: `/api/company/getAllCompanyNamesAndIds`,
        method: 'GET',
        params: {pageNumber, pageSize},
      }),
    }),

    getOneCompany: builder.query<ICompanyBase, string>({
      providesTags: ['Company'],
      query: (companyId) => ({
        url: `/api/Company/GetCompany/${companyId}`,
        method: 'GET',
      }),
      transformResponse: (company: ICompanyBase) => {
        return company
      },
    }),

    findCompany: builder.query<boolean, string>({
      providesTags: ['Company'],
      query: (name) => ({
        url: `/api/Company/FindCompanyName/Find/${name}`,
        method: 'GET',
      }),
    }),

    findCompaniesAssignedToMsp: builder.query<boolean, string>({
      providesTags: ['Company'],
      query: (mspId) => ({
        url: `/api/Company/GetCompanyIdsWithAssignedMspId/${mspId}`,
        method: 'GET',
      }),
    }),

    getDepartments: builder.query<IDepartment[], string>({
      providesTags: ['Company'],
      query: (companyId) => ({
        url: `/api/Company/${companyId}`,
        method: 'GET',
      }),
      transformResponse: ({departments}) => {
        return departments
      },
    }),

    createCompany: builder.mutation<ISuccessMessage, Partial<ICompanyBase>>({
      invalidatesTags: ['Company'],
      query: (body) => ({
        url: `/api/Company/OnboardCompany`,
        method: 'POST',
        body: body,
      }),
    }),

    updateCompanyStatus: builder.mutation<ISuccessMessage, TUpdateStatusPayload>({
      invalidatesTags: ['Company'],
      query: ({id, status}) => ({
        url: `/api/Company/UpdateCompanyStatus/${id}/Status/${status}`,
        method: 'PUT',
      }),
    }),

    updateCompany: builder.mutation<ISuccessMessage, ICompanyBase>({
      invalidatesTags: ['Company'],
      query: (body) => ({
        url: `/api/Company/UpdateCompany`,
        method: 'PUT',
        body: body,
      }),
    }),

    deleteCompany: builder.mutation<ISuccessMessage, string>({
      invalidatesTags: ['Company'],
      query: (id) => ({
        url: `/Company/DeleteCompany`,
        method: 'DELETE',
        params: {id},
      }),
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useGetAllCompanyNamesQuery,
  useGetAllCompanyNamesAndIdsQuery,
  useGetCompanyNameByIdQuery,
  useLazyGetCompanyNamesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyStatusMutation,
  useDeleteCompanyMutation,
  useGetOneCompanyQuery,
  useUpdateCompanyMutation,
  useLazyGetOneCompanyQuery,
  useGetDepartmentsQuery,
  useLazyGetDepartmentsQuery,
  usePrefetch,
  useLazyFindCompanyQuery,
  useFindCompaniesAssignedToMspQuery,
} = companyApi
