import type Button from '@mui/material/Button'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import CreateOrderSplitButtons from '../buttons/CreateOrderSplitButtons'
import { useAppSelector } from '~/src/store/store-hooks'
interface IProps {
  CustomButton: typeof Button
  label?: string
  top?: string
}

export default function CreateOrderDropdown({CustomButton, label, top}: IProps) {
  const dispatch = useDispatch()
  const {currentUser} = useCurrentUser()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {
    data: serviceOrders,
    refetch: serviceOrdersRefetch,
    isSuccess: serviceOrdersSuccess,
    isLoading: serviceOrdersIsLoading,
  } = useGetServiceOrderQuery({pageNumber: 1, pageSize: 999999, agencyId: loggedInAgencyId})
  const fitlerServiceOrders =
    serviceOrders && currentUser
      ? serviceOrders.serviceOrdersModel.filter(
          (item) => item.createdBy === currentUser.emailAddress,
        )
      : []

  const checkForPendingStatus = fitlerServiceOrders.filter(
    (item) => item.companyStatus === 'Submitting Order',
  )
  const pendingStatusBoolean = checkForPendingStatus.length > 0
  const pendingStatusOrderId = checkForPendingStatus[0]?.id
  const [showDropdown, setShowDropdown] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const handleRouteToDetails = () => {
    dispatch(
      push(`/company-admin/manage-orders/${pendingStatusOrderId}`, {
        pendingOrder: true,
      }),
    )
  }

  const projectButtonSwitch = () => {
    switch (pendingStatusBoolean) {
      case true:
        return <CustomButton onClick={handleRouteToDetails}>{label}</CustomButton>
      default:
        return (
          <CustomButton
            onClick={() => {
              setShowDropdown(!showDropdown)
            }}>
            {label}
          </CustomButton>
        )
    }
  }

  useEffect(() => {
    void serviceOrdersRefetch()
  }, [serviceOrdersRefetch])

  return (
    <LoadingBoundary isLoading={serviceOrdersIsLoading}>
      <div
        tabIndex={-1}
        onBlur={() => {
          if (!isHover) {
            setShowDropdown(false)
          }
        }}>
        {projectButtonSwitch()}
        {showDropdown && (
          <CreateOrderSplitButtons
            onEnter={() => {
              setIsHover(true)
            }}
            onLeave={() => {
              setIsHover(false)
            }}
            sx={{marginTop: '10px', position: 'absolute', zIndex: 2, top: {top}}}
          />
        )}
      </div>
    </LoadingBoundary>
  )
}
