import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function FilterIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.0622 9V16.2233C11.1034 16.4983 11.0003 16.7917 10.7631 16.9842C10.361 17.3417 9.71145 17.3417 9.30933 16.9842L7.23685 15.1417C6.99971 14.9308 6.8966 14.6467 6.93784 14.3808V9H6.90691L0.96789 2.235C0.617323 1.84083 0.69981 1.26333 1.14317 0.951667C1.33908 0.823333 1.55561 0.75 1.78244 0.75H16.2176C16.4444 0.75 16.6609 0.823333 16.8568 0.951667C17.3002 1.26333 17.3827 1.84083 17.0321 2.235L11.0931 9H11.0622Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
