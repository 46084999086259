import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import {styled} from '@mui/material/styles'
import React from 'react'
import {ReactComponent as DoubleBackward} from '~/src/assets/images/DoubleBackwardArrowIcon.svg'
import {ReactComponent as DoubleForward} from '~/src/assets/images/DoubleForwardArrowIcon.svg'

const CustomizedPaginationItem = styled(PaginationItem)`
  &.MuiPaginationItem-root {
    border: 1px solid #ebeff2;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    size: 13px;
    weight: 400;
  }
  &.MuiPaginationItem-sizeMedium {
    margin-bottom: 2px;
  }

  &.Mui-selected {
    background-color: #03a9fc;
    color: #ffffff;
  }

  &.Mui-selected:hover {
    background-color: #03a9fc;
    color: #ffffff;
  }

  &.MuiPaginationItem-ellipsis {
    border: 0;
  }
`
interface IProps {
  page: number
  totalRecords: number | undefined
  recordsPerPage: number
  onChange(pageNumber: number): void
}

export function StyledPagination({page, totalRecords, recordsPerPage, onChange, ...rest}: IProps) {
  const pageChangeHandler = (event, pageNumber: number) => {
    const lastPageNumber = Math.ceil(totalRecords / recordsPerPage)

    const lastButtonClick = event === 'LastPageIcon'

    const FirstButtonClick = event === 'FirstPageIcon'

    if (lastButtonClick) {
      if (page + 5 > lastPageNumber) {
        onChange(lastPageNumber)
      } else {
        const newPage = page + 5

        onChange(newPage)
      }
    } else if (FirstButtonClick) {
      if (page - 5 < 1) {
        onChange(1)
      } else {
        const newPage = page - 5
        onChange(newPage)
      }
    } else {
      onChange(pageNumber)
    }
  }

  return (
    <Pagination
      showFirstButton
      showLastButton
      page={page}
      count={Math.ceil(totalRecords / recordsPerPage)}
      sx={{pl: 5, pt: 5, pb: 5}}
      onChange={(event, pageNumber) => {
        pageChangeHandler(event?.target?.ariaLabel, pageNumber)
      }}
      siblingCount={2}
      boundaryCount={1}
      {...rest}
      renderItem={(item) => (
        <CustomizedPaginationItem slots={{last: DoubleForward, first: DoubleBackward}} {...item} />
      )}
    />
  )
}
