import Box from '@mui/material/Box'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import AddAgencyForm from './AddAgencyForm'
import AddCompanyForm, {TActivePlatform} from './AddCompanyForm'

const MainContainer = styled('div')({
  position: 'relative',
  flex: 1,
})

export default function AccountDetails() {
  const [activePlatform] = React.useState<TActivePlatform>('Company')

  const location = useLocation()
  const {orderId}: IParams = useParams()
  const history = useHistory()
  const path = [...location.pathname.split('/')]

  interface IState {
    currentPageNumber: number
    specificType: string
    data: any
  }
  const locationState = location.state as IState

  const {data: agency, refetch} = useGetOneAgencyQuery(locationState?.data.agencyId)

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <>
      <MainContainer>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '300',
              marginRight: '15px',
              marginBottom: '4px',
            }}>
            {locationState?.specificType === 'Agency' ? (
              <h2>Manage Agency</h2>
            ) : (
              <h2>Manage Company</h2>
            )}
          </div>
        </Box>
        <ErrorBoundary>
          <LoadingBoundary>
            <>
              <Box
                sx={{
                  marginTop: '63px',
                  float: 'left',
                }}>
                {locationState?.specificType === 'Agency' ? (
                  <AddAgencyForm
                    initialEditValues={locationState?.data}
                    activePlatform={activePlatform}
                  />
                ) : (
                  <AddCompanyForm
                    activePlatform={activePlatform}
                    initialEditValues={locationState?.data}
                    agency={agency}
                  />
                )}
              </Box>
            </>
          </LoadingBoundary>
        </ErrorBoundary>
      </MainContainer>
    </>
  )
}
