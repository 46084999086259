import styled, {css} from 'styled-components'
import Icon from '@ui/icons'
import {colors, resets} from '~/src/config/theme'
import type {IButtonIcon} from '@ui/button-icon/typings';

const primary = css`
  background-color: ${colors.primary};
`

const secondary = css`
  background-color: ${colors.secondary};
`

const clear = css`
  background-color: transparent;
`

const light = css`
  color: ${colors.white};
`

const dark = css`
  color: #424242;
`

const square = css`
  border-radius: 0.25rem;
`

const round = css`
  border-radius: 50%;
`

const small = css`
  height: 1.75rem;
  width: 1.75rem;
`

const medium = css`
  height: 2.4375rem;
  width: 2.4375rem;
`

const ButtonIconStyles = {
  Container: styled.button(
    (props: Pick<IButtonIcon, 'variant' | 'textColor' | 'shape' | 'sizeVariant'>) => css`
      ${resets.common};
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      color: inherit;

      ${props.variant === 'primary' && primary};
      ${props.variant === 'secondary' && secondary};
      ${props.variant === 'clear' && clear};

      ${props.textColor === 'light' && light};
      ${props.textColor === 'dark' && dark};

      ${props.shape === 'square' && square};
      ${props.shape === 'round' && round};

      ${props.sizeVariant === 'small' && small};
      ${props.sizeVariant === 'medium' && medium};
    `,
  ),
  Icon: styled(Icon)`
    height: 43.5%;
  `,
}

export default ButtonIconStyles
