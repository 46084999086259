import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  FlexSpaceBetweenContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileDetailsText,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string): void
  applicant: Applicant
}

export default function MspCandidateEmergencyContactDisplay({handleClick, applicant}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Emergency Contact</InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText style={{width: '209px'}}>
                Full Name
                <InformationCardProfileDetailsText
                  style={{
                    maxWidth: 209,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {applicant?.emergencyContactName}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText style={{width: '151px'}}>
                Relationship
                <InformationCardProfileDetailsText>
                  {applicant?.emergencyContactRelationship}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
              <InformationCardProfileHeaderText style={{width: '300px'}}>
                Phone Number
                <InformationCardProfileDetailsText
                  style={{
                    maxWidth: 300,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {applicant?.emergencyPhone}
                </InformationCardProfileDetailsText>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
