import React from 'react'
import {Button} from '@mui/material'

export default function SubmitButton({
  children,
  disabled,
}: {
  children: React.ReactNode
  disabled?: boolean
}) {
  return (
    <Button fullWidth sx={{mt: 5}} disabled={disabled} variant="contained" type="submit">
      {children}
    </Button>
  )
}
