import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function DepartmentsIcon (props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg 
			{...props} 
			width="45" 
			height="45" 
			viewBox="0 0 45 45" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg">
			<path d="M16.6691 19.8946C16.8015 18.8373 17.2188 17.8369 17.8751 16.9997L17.9683 16.8869C19.0468 15.5816 20.6762 14.75 22.4998 14.75C24.3232 14.75 25.9523 15.5813 27.0309 16.8863C27.7398 17.7458 28.1897 18.7891 28.3281 19.8946C28.4665 21 28.2877 22.1221 27.8126 23.1298C27.3375 24.1375 26.5856 24.9894 25.6447 25.586C24.7038 26.1826 23.6127 26.4994 22.4986 26.4994C21.3845 26.4994 20.2933 26.1826 19.3524 25.586C18.4116 24.9894 17.6597 24.1375 17.1846 23.1298C16.7095 22.1221 16.5307 21 16.6691 19.8946Z" stroke="#03A9FC" strokeWidth="2"/>
			<path d="M32.875 20.6243C32.8749 19.4446 32.678 18.3087 32.3144 17.25H41.25C41.9793 17.25 42.6788 17.5397 43.1945 18.0555L43.9016 17.3483L43.1945 18.0555C43.7102 18.5712 44 19.2707 44 20V21.25C44 24.5281 41.6098 28.0529 37.1499 29.5342C36.5608 28.7099 35.8035 28.0157 34.9248 27.4993C33.8108 26.8447 32.5421 26.4997 31.25 26.5C31.2499 26.5 31.2498 26.5 31.2497 26.5H31.0543C31.4264 25.9587 31.7471 25.3817 32.0112 24.7765C32.5826 23.4669 32.8767 22.0532 32.875 20.6243ZM32.875 20.6243C32.875 20.6246 32.875 20.6248 32.875 20.625H31.875L32.875 20.6243Z" stroke="#03A9FC" strokeWidth="2"/>
			<path d="M31.25 31L31.2526 31C31.6137 30.999 31.9714 31.0694 32.3051 31.2072L32.6866 30.2828L32.3051 31.2072C32.6389 31.3449 32.9422 31.5472 33.1975 31.8025C33.4528 32.0578 33.6551 32.3611 33.7928 32.6948C33.9305 33.0286 34.0009 33.3863 34 33.7473V33.75V35C34 39.2578 29.9229 44 22.5 44C15.0771 44 11 39.2578 11 35L11 33.75L11 33.7473C10.999 33.3863 11.0694 33.0286 11.2072 32.6948C11.3449 32.3611 11.5472 32.0578 11.8025 31.8025C12.0578 31.5472 12.3611 31.3449 12.6948 31.2072C13.0286 31.0694 13.3863 30.999 13.7473 31V31H13.75L31.25 31Z" stroke="#03A9FC" strokeWidth="2"/>
			<path d="M12.6864 17.25H3.74999C3.02065 17.25 2.32118 17.5397 1.80545 18.0555C1.28973 18.5712 1 19.2706 1 20V21.25C1 24.5281 3.39019 28.0529 7.85002 29.5342C8.43917 28.7099 9.19645 28.0157 10.0752 27.4993C11.1892 26.8447 12.4579 26.4997 13.75 26.5C13.7501 26.5 13.7501 26.5 13.7502 26.5H13.9457C13.5736 25.9587 13.2529 25.3817 12.9888 24.7765C12.4172 23.4667 12.1231 22.0527 12.125 20.6237M12.6864 17.25L12.125 20.6237M12.6864 17.25C12.322 18.3087 12.1251 19.4444 12.125 20.6237M12.6864 17.25L12.125 20.625C12.125 20.6246 12.125 20.6241 12.125 20.6237" stroke="#03A9FC" strokeWidth="2"/>
			<path d="M16.6542 2.72074C17.756 3.82252 18.375 5.31684 18.375 6.87499C18.375 8.43313 17.756 9.92746 16.6542 11.0292C15.5525 12.131 14.0581 12.75 12.5 12.75C10.9418 12.75 9.44752 12.131 8.34574 11.0292C7.24397 9.92746 6.625 8.43313 6.625 6.87499C6.625 5.31684 7.24397 3.82252 8.34574 2.72074C9.44752 1.61897 10.9418 1 12.5 1C14.0581 1 15.5525 1.61897 16.6542 2.72074Z" stroke="#03A9FC" strokeWidth="2"/>
			<path d="M36.6543 2.72074C37.7561 3.82252 38.375 5.31684 38.375 6.87499C38.375 8.43313 37.7561 9.92746 36.6543 11.0292C35.5525 12.131 34.0582 12.75 32.5 12.75C30.9419 12.75 29.4476 12.131 28.3458 11.0292C27.244 9.92746 26.6251 8.43313 26.6251 6.87499C26.6251 5.31684 27.244 3.82252 28.3458 2.72074C29.4476 1.61897 30.9419 1 32.5 1C34.0582 1 35.5525 1.61897 36.6543 2.72074Z" stroke="#03A9FC" strokeWidth="2"/>
		</Svg>
	);
}

