import {Alert, Snackbar} from '@mui/material'
import React, {ReactElement, useState} from 'react'
import {useLocation} from 'react-router-dom'
import PrefillDashboard from '../../common/prefill/PrefillDashboard'
import Title from '../../layout/Title'
import { DefaultContainer } from '../../msp/dashboard/Dashboard'
import DataCleanupDashboard from '../../msp/dashboard/DataCleanupDashboard'
import JobOrderDashboard from '../../msp/dashboard/JobOrderDashboard'
import ServiceOrderDashboard from '../../msp/dashboard/ServiceOrderDashboard'
export interface IState {
  state: {
    openSnackbar: boolean
    name: string
  }
}

export default function AgencyDashboard(): ReactElement {
  const location = useLocation()

  const locationState = location.state as IState
  const [open, setOpen] = useState(
    locationState?.state.openSnackbar ? locationState.state.openSnackbar : false,
  )

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <DefaultContainer>
      <Title pageTitle="Dashboard" />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
          Success! The Agency {locationState?.state.name} has been Edited!
        </Alert>
      </Snackbar>

      <DataCleanupDashboard />
      <PrefillDashboard />
      <JobOrderDashboard />
      <ServiceOrderDashboard />
    </DefaultContainer>
  )
}
