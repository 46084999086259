import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';

import CompanyMain from './CompanyMain';
import CompanyDetails from './CompanyDetails';

export function ManageCompaniesRoutes(): JSX.Element {
	const {path} = useRouteMatch();

	return (
		<Switch>
			<Route path={path} component={CompanyMain} exact />
			<Route path={`${path}/:companyName`} component={CompanyDetails} />
		</Switch>
	);
}
