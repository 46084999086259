import {MenuItem} from '@mui/material'
import {styled} from '@mui/material/styles'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {summaryDropdown} from '@utils/candidateArrays'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  FlexSpaceBetweenContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

export const Title = styled('div')`
  font-size: 14px;
  font-weight: 600;
  color: #424242;
  margin-bottom: 10px;
`

interface IProps {
  handleCancel(): void
  handleClick(value: string): void
  applicant: Applicant
}

export default function MspCandidateProfileSummaryEdit({
  handleCancel,
  handleClick,
  applicant,
}: IProps) {
  const sorted = summaryDropdown.sort((a, b) => {
    const aIndex = applicant?.whereDidYouHearAboutUs?.indexOf(a)
    const bIndex = applicant?.whereDidYouHearAboutUs?.indexOf(b)
    return bIndex - aIndex
  })

  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Summary</InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                <InformationCardHeaderTextEdit style={{marginRight: 10}} onClick={handleCancel}>
                  Cancel
                </InformationCardHeaderTextEdit>
                <InformationCardHeaderTextEdit onClick={() => handleClick('saveChanges')}>
                  Save
                </InformationCardHeaderTextEdit>
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            <FlexSpaceBetweenContainer>
              <InformationCardProfileHeaderText>
                <Title>Summary</Title>
                <FormikTextField
                  aria-label="summary"
                  name="summary[0]"
                  data-testid="summary[0]"
                  id="outlined-multiline-flexible"
                  multiline
                  rows={2}
                  type="text"
                  label=""
                  value={applicant.summary[0]}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 20,
                    },
                  }}
                  sx={{
                    marginBottom: '15px',
                    maxWidth: '700px',
                    minWidth: '700px',
                  }}
                />
                <Title>Where did you hear about us?</Title>
                <FormikTextField
                  select
                  aria-label="whereDidYouHearAboutUs"
                  data-testid="whereDidYouHearAboutUs"
                  name="whereDidYouHearAboutUs"
                  id="outlined-multiline-flexible"
                  type="text"
                  value={applicant.whereDidYouHearAboutUs}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 20,
                    },
                    MenuProps: {
                      id: 'id-menu',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                    },
                  }}
                  sx={{
                    marginBottom: '15px',
                    maxWidth: '500px',
                    minWidth: '500px',
                  }}>
                  {sorted.map((i, index) => {
                    return (
                      <MenuItem
                        aria-label={`whereDidYouHearAboutUs-value-${i}`}
                        data-testid={`whereDidYouHearAboutUs-value-${i}`}
                        key={index}
                        value={i}>
                        {i}
                      </MenuItem>
                    )
                  })}
                </FormikTextField>
              </InformationCardProfileHeaderText>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
