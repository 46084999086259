import {createSlice} from '@reduxjs/toolkit'
import type {TRootState} from '..'
import {jobOrderApi} from './../apis/job-order-api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  jobOrders: null,
  currentJobOrderPageNumber: 1,
  jobOrderId: '',
}

const jobOrderSlice = createSlice({
  name: 'jobOrderSlice',
  initialState,
  reducers: {
    initializeInput: (state, {payload}) => {
      state.jobOrders = payload
    },
    updatePageNumber: (state, {payload}) => {
      state.currentJobOrderPageNumber = payload
    },
    updateJobOrderId: (state, {payload}) => {
      state.jobOrderId = payload
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(jobOrderApi.endpoints.getJobOrders.matchFulfilled, (state, {payload}) => {
      state.jobOrders = payload
    })
  },
})

export const {initializeInput, updatePageNumber, updateJobOrderId} = jobOrderSlice.actions

export const selectJobOrderState = (state: TRootState): TRootState['jobOrder'] => state.jobOrder

export default jobOrderSlice.reducer
