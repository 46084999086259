import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import {css, styled} from '@mui/material/styles'
import {push} from 'connected-react-router'
import React, {UIEvent, useState} from 'react'
import {BASE_ROUTES} from '~/src/routes/constants'
import {useAppDispatch} from '~/src/store/store-hooks'
import EulaContent from './EulaContent'

const HeaderText = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 30px;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 1px solid grey;
  `,
)

interface IProps {
  onModalClose(): void
  isModalOpen: boolean
  handleClick: (response: boolean) => void
}

export default function EulaDialog({onModalClose, isModalOpen, handleClick}: IProps): JSX.Element {
  const dispatch = useAppDispatch()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const handleScroll = (
    e: {
      target: {scrollHeight: number; scrollTop: number; clientHeight: number}
    } & UIEvent<HTMLDivElement>,
  ) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 25
    if (bottom) {
      setIsButtonDisabled(false)
    }
  }

  const handleSubmit = async () => {
    handleClick(true)
    onModalClose()
  }

  function handleModalCancel() {
    handleClick(false)
    dispatch(push(BASE_ROUTES.SIGNIN))
  }

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={handleModalCancel}
      PaperProps={{sx: {maxWidth: 1100}}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '40px',
          height: '600px',
        }}>
        <HeaderText>Terms & Conditions</HeaderText>

        <div
          onScroll={handleScroll}
          style={{
            width: '100%',
            border: '1px solid grey',
            overflowY: 'scroll',
            height: '400px',
            borderRadius: '4px',
            padding: '25px',
          }}>
          <EulaContent />
        </div>

        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingTop: '50px',
          }}>
          <Button
            variant="outlined"
            color="secondary"
            type="reset"
            sx={{width: 120, fontWeight: 'normal'}}
            onClick={handleModalCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ml: 2, width: 120, fontWeight: 'normal'}}
            disabled={isButtonDisabled}
            onClick={handleSubmit}>
            I Agree
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
