import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import LoadingBoundary from '@ui/loader'
import React, {useEffect, useState} from 'react'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import Title from '../../layout/Title'
import CandidatesContainer from './CandidatesContainer'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    min-height: 100%;
  `,
)

const Body = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
)

export function Candidates(): React.ReactElement {
  const [newIsLoading, setNewIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setNewIsLoading(false)
      }, 1000)
    }

    void fetchData()
  }, [newIsLoading])

  return (
    <Container>
      <LoadingContext.Provider
        value={{setNewIsLoading: setNewIsLoading, newIsLoading: newIsLoading}}>
        {newIsLoading ? (
          <LoadingBoundary isLoading={newIsLoading} />
        ) : (
          <>
            <Title pageTitle="Candidates Directory" />
            <Body>
              <CandidatesContainer isCandidatesOnly isCandidateDirectory />
            </Body>
          </>
        )}
      </LoadingContext.Provider>
    </Container>
  )
}
