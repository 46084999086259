import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EyesCloseIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='22'
			height='22'
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.83366 3.91417L3.00699 2.75L18.3337 18.0767L17.1695 19.25L14.3462 16.4267C13.292 16.775 12.1737 16.9583 11.0003 16.9583C6.41699 16.9583 2.50283 14.1075 0.916992 10.0833C1.54949 8.47 2.55783 7.04917 3.84116 5.92167L1.83366 3.91417ZM11.0003 7.33333C11.7297 7.33333 12.4291 7.62306 12.9449 8.13879C13.4606 8.65451 13.7503 9.35399 13.7503 10.0833C13.7503 10.4042 13.6953 10.7158 13.5945 11L10.0837 7.48917C10.3678 7.38833 10.6795 7.33333 11.0003 7.33333ZM11.0003 3.20833C15.5837 3.20833 19.4978 6.05917 21.0837 10.0833C20.332 11.99 19.0578 13.64 17.417 14.8408L16.1153 13.53C17.362 12.6683 18.3887 11.495 19.0853 10.0833C17.5728 7.00333 14.447 5.04167 11.0003 5.04167C10.0012 5.04167 9.02033 5.20667 8.10366 5.5L6.69199 4.0975C8.01199 3.52917 9.46949 3.20833 11.0003 3.20833ZM2.91533 10.0833C4.42783 13.1633 7.55366 15.125 11.0003 15.125C11.6328 15.125 12.2562 15.0608 12.8337 14.9325L10.7437 12.8333C9.43283 12.6958 8.38783 11.6508 8.25033 10.34L5.13366 7.21417C4.22616 7.99333 3.46533 8.965 2.91533 10.0833Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
