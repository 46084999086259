// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import Button from '@mui/material/Button'
import {FormikTextField} from '@ui/components'
import React, {useContext} from 'react'
import type {Activity, Applicant} from '~/src/models/applicant'
import {useAddApplicantsActivityMutation} from '~/src/store/apis/applicants-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {
  AddandCancelNoteButton,
  DashboardMainStyles,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceBetweenContainer,
  InformationActivityButtonText,
  InformationCardProfileHeaderText,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  resetForm(obj: Applicant): void
  newActivity: Activity
  values: Applicant
  oldApplicant: Applicant
}

const AddNoteContainer = ({resetForm, newActivity, values, oldApplicant}: IProps) => {
  const [addActivity] = useAddApplicantsActivityMutation()

  const settingRefresh = useContext(LoadingContext)

  const pushActivity = async (value: Activity) => {
    if (value.notes !== '') {
      await addActivity({
        activity: value,
        agencyId: oldApplicant.agencyId,
      })
      settingRefresh.setNewIsLoading(true)
    }
  }
  const addNoteButtonSwitch = () => {
    if (newActivity.notes.length == 0) {
      return (
        <AddandCancelNoteButton
          onClick={() => pushActivity(newActivity)}
          aria-label="add new activity button"
          data-testid="activityAddNewNote">
          <InformationActivityButtonText>Add note</InformationActivityButtonText>
        </AddandCancelNoteButton>
      )
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          sx={{width: 120, fontWeight: 'normal', marginRight: '20px'}}
          onClick={() => pushActivity(newActivity)}
          aria-label="add new activity button"
          data-testid="activityAddNewNote">
          Add note
        </Button>
      )
    }
  }

  return (
    <>
      <DashboardMainStyles>
        <FlexSpaceBetweenContainer>
          <>
            <FlexColumnContainer>
              <InformationCardProfileHeaderText>
                <FormikTextField
                  name="newActivity.notes"
                  data-testid="activityNewNote"
                  id="outlined-multiline-flexible"
                  multiline
                  rows={2}
                  type="text"
                  label=""
                  placeholder="Click here to add a new note"
                  value={newActivity?.notes}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 20,
                    },
                  }}
                  sx={{
                    marginBottom: '5px',
                    maxWidth: '748px',
                    minWidth: '748px',
                  }}
                />
              </InformationCardProfileHeaderText>
              <FlexContainer style={{justifyContent: 'center'}}>
                {addNoteButtonSwitch()}

                <AddandCancelNoteButton
                  aria-label="clear form"
                  data-testid="activityCancelNewNote"
                  onClick={() => resetForm(values)}>
                  <InformationActivityButtonText>Cancel</InformationActivityButtonText>
                </AddandCancelNoteButton>
              </FlexContainer>
            </FlexColumnContainer>
          </>
        </FlexSpaceBetweenContainer>
      </DashboardMainStyles>
    </>
  )
}

export default React.memo(AddNoteContainer)
