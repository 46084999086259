import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import {styled, useTheme} from '@mui/material/styles'
import FormikAutocomplete from '@ui/autocomplete/FormikAutocomplete'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import {Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'

const StyledButton = styled(Button)({
  width: 120,
  fontWeight: 'normal',
})

const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

const BodyBox = styled(Box)({
  maxWidth: 1600,
  m: 5,
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
})

const InnerBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '50px 100px 50px 100px',
})

const LargeBold = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '28px',
  fontWeight: 600,
})

const SmallGrey = styled('div')({
  color: '#8A8A8A',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '50px 5px 0px 5px',
  justifyContent: 'center',
})

export interface IFormSubmitValues {
  reason?: string
  endDate?: Date | string
}

interface IProps {
  isOpen: boolean
  distributionStartDate: string | Date
  handleSubmit(values: IFormSubmitValues): void
  handleCancel(): void
}

export default function EndAssignmentDialog({
  isOpen,
  distributionStartDate,
  handleSubmit,
  handleCancel,
}: IProps): React.ReactElement {
  const theme = useTheme()

  const reasons = [
    'ATTENDANCE',
    'ATTITUDE',
    'CHANGE OF DEPARTMENT',
    'COMPLETED',
    'CUSTOMER ENDED-NOT WORK RELATED',
    'DELETED MISTAKENLY ADDED',
    'DRUG FREE POLICY VIOLATION',
    'TERMINATED DRESS CODE POLICY VIOLATION',
    'INSUFFICIENT SKILLS',
    'LACK OF WORK',
    'PUNCTUALITY PROBLEM',
    'POLICY VIOLATION',
    'PRODUCTIVITY',
    'DISLIKED JOB ENVIRONMENT',
    'FOUND WORK ELSEWHERE',
    'NO SHOW NO CALL',
    'VOLUNTAIRLY QUIT',
    'TRANSPORTATION ISSUES',
    'WALKED OFF THE JOB',
    'SAFETY VIOLATION',
  ]

  const initialFormValues = {
    reason: undefined,
    endDate: '',
  } as IFormSubmitValues

  return (
    <Dialog
      fullWidth
      open={isOpen}
      PaperProps={{
        sx: {minWidth: '546px', minHeight: '497px'},
      }}>
      <HeaderContainer>
        <HeaderText>End Candidate Assignment</HeaderText>
        <IconButton
          aria-label="close"
          size="large"
          onClick={handleCancel}
          style={{position: 'relative', right: '-10px'}}>
          <CloseIcon style={{color: theme.palette.grey[900]}} />
        </IconButton>
      </HeaderContainer>
      <BodyBox>
        <InnerBox>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
              reason: Yup.string().required('Required'),
              endDate: Yup.date().typeError('Required').required('Required').nullable(),
            })}>
            {({isSubmitting, setFieldValue, values, handleChange, errors, getFieldMeta}) => {
              return (
                <Form
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}>
                  <LargeBold>End Assignment?</LargeBold>
                  <SmallGrey style={{marginTop: '30px', marginBottom: '5px'}}>
                    Please select an end date and a reason for ending the Candidate's assignment
                  </SmallGrey>
                  <FormikDatePicker
                    name="endDate"
                    onClose={() => {}}
                    minDate={new Date(distributionStartDate)}
                    error={errors.endDate === '' && getFieldMeta('endDate').touched}
                    placeholder="Select Candidate End Date"
                    showIcon
                    sx={{marginTop: '40px'}}
                  />
                  <FormikAutocomplete
                    name="reason"
                    options={reasons}
                    placeholder="Select Reason"
                    sx={{marginTop: '30px'}}
                  />
                  <ButtonBox>
                    <StyledButton
                      style={{marginRight: '30px'}}
                      onClick={handleCancel}
                      variant="outlined"
                      color="secondary"
                      type="reset">
                      Cancel
                    </StyledButton>
                    <StyledButton variant="outlined" color="error" type="submit">
                      End
                    </StyledButton>
                  </ButtonBox>
                </Form>
              )
            }}
          </Formik>
        </InnerBox>
      </BodyBox>
    </Dialog>
  )
}
