export const errorFunction = (
  error: unknown,
  setIsSnackbarOpen: (value: boolean) => void,
  setCustomMessage?: (value: string) => void,
) => {
  if (error?.data?.message?.includes('does not contain an Agency Distribution')) {
    setCustomMessage?.(
      'An agency that is not part of the Job Order agency distribution request cannot add candidates.',
    )
  }

  setIsSnackbarOpen(true)
  console.error(error)
}
