import {Alert, Box, Snackbar} from '@mui/material'
import LoadingBoundary from '@ui/loader'
import React, {KeyboardEvent, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import type {IJobOrder} from '~/src/models/jobOrder'
import {
  useLazyLockApplicantQuery,
  useLazyUnLockApplicantQuery,
} from '~/src/store/apis/applicants-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {useCurrentUser} from '~/src/store/slice-hooks'
import type {Applicant} from '../../../models/applicant'
import SkipOrDeclineCandidateDialog from '../../msp/manage-job-orders/job-order-summary/dialogs/SkipOrDeclineCandidateDialog'
import CandidateCard from './CandidateCard'
import CandidateList, {TProcessedCandidate} from './CandidateList'

interface CandidateListContainerProps {
  candidates: Applicant[]
  isDataCleanup: boolean
  onAddCandidate?: (candidateStatus: string, candidate: TProcessedCandidate) => void
  onCandidateDeclined?: (candidateId: string, notes: string) => void
  onCleanCandidate?: () => Promise<void>
  onCandidateSkipped: (notes: string, candidateId: string, id?: string) => void
  setCandidateSelected(candidateId: string): void
  isCleanupDisabled: boolean
  isCapped: boolean
  isCandidateDirectory?: boolean
  setDataCleanupCandidateSelected: (candidateId: string) => void
  resetConfirmation?: () => void
  jobOrder?: IJobOrder
}

const CandidateListContainer = ({
  candidates,
  onAddCandidate,
  isDataCleanup,
  onCandidateDeclined,
  onCandidateSkipped,
  setCandidateSelected,
  onCleanCandidate,
  isCleanupDisabled,
  isCapped,
  isCandidateDirectory,
  setDataCleanupCandidateSelected,
  resetConfirmation = () => {},
  jobOrder,
}: CandidateListContainerProps) => {
  const location = useLocation()
  const [currentCandidate, setCurrentCandidate] = useState<TProcessedCandidate | undefined>(
    undefined,
  )
  const [isSkipOrDeclineDialogOpen, setIsSkipOrDeclineDialogOpen] = useState(false)
  const [dialogVarient, setDialogVarient] = useState<'skip' | 'decline' | 'skipCleanup'>('decline')
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0)
  const [isCallActive, setIsCallActive] = useState(false)
  const [showCapAlert, setShowCapAlert] = useState(false)
  const [unlockApplicant, {isLoading: unlockLoading}] = useLazyUnLockApplicantQuery()
  const [lockApplicant, {isLoading: lockLoading}] = useLazyLockApplicantQuery()
  const {currentUser} = useCurrentUser()

  const settingNewIsLoading = useContext(LoadingContext)

  const processedCandidates = useMemo(() => {
    return candidates.map((candidate) => {
      return {
        candidate: candidate,
        conflictingJobOrderIds:
          jobOrder && candidate.appliedJobOrders && candidate.appliedJobOrders.length > 0
            ? // At least one of the applied to job orders doesn't have an end date or
              // one of the end dates is > job order start date i.e. after
              (candidate.appliedJobOrders
                .filter(
                  (datum) =>
                    datum.endDate === undefined ||
                    new Date(jobOrder.date).getTime() <=
                      new Date(datum.endDate as unknown as string).getTime(),
                )
                .map((datum) => datum.jobOrderId) as unknown as string[])
            : [],
      }
    }) as TProcessedCandidate[]
  }, [candidates, jobOrder])

  useEffect(() => {
    setCurrentCandidate(processedCandidates[0])
  }, [processedCandidates])

  const handleSelectCandidate = (value: TProcessedCandidate, index: number) => {
    if (!isCallActive) {
      setCurrentCandidate(value)
      setCurrentCandidateIndex(index)
      setCandidateSelected(value.candidate.id)
      resetConfirmation()
      if (isDataCleanup) setDataCleanupCandidateSelected(value.candidate.id)
    }
  }

  const handleCloseDeclineModal = () => {
    setIsSkipOrDeclineDialogOpen(false)
  }

  const handleOpenDeclineModal = (skipOrDecline: 'skip' | 'decline' | 'skipCleanup') => {
    setIsSkipOrDeclineDialogOpen(true)
    setDialogVarient(skipOrDecline)
  }

  const handleDeclineOrSkipCandidate = async (
    notes: string,
    skipOrDecline: 'skip' | 'decline' | 'skipCleanup',
  ) => {
    if (
      currentCandidate?.candidate.id &&
      skipOrDecline === 'decline' &&
      onCandidateDeclined &&
      !isDataCleanup
    ) {
      onCandidateDeclined(currentCandidate.candidate.id, notes)
      setIsSkipOrDeclineDialogOpen(false)
      setIsCallActive(false)
      void (await unlockApplicant({
        id: currentCandidate.candidate.id,
        clientId: currentUser?.id ?? '',
      }))
    }

    if (currentCandidate && (skipOrDecline === 'skip' || skipOrDecline === 'skipCleanup')) {
      onCandidateSkipped(notes, currentCandidate.candidate.id)
      setIsSkipOrDeclineDialogOpen(false)
      setIsCallActive(false)
      void (await unlockApplicant({
        id: currentCandidate.candidate.id,
        clientId: currentUser?.id ?? '',
      }))
    }
  }

  const handleCallButtonClick = async () => {
    if (isCapped) {
      setShowCapAlert(true)
    } else if (isCallActive && currentCandidate && currentUser) {
      void (await unlockApplicant({id: currentCandidate.candidate.id, clientId: currentUser.id}))
      setIsCallActive(false)
    } else if (currentCandidate && !isCallActive && currentUser) {
      void (await lockApplicant({id: currentCandidate.candidate.id, clientId: currentUser.id}))
      setIsCallActive(true)
    }
  }

  const handleListNavigate = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      // arrow up/down button should select next/previous list element
      if (e.key === 'ArrowUp' && currentCandidateIndex > 0 && !isCallActive) {
        const newIndex = currentCandidateIndex - 1
        setCurrentCandidateIndex(newIndex)
        setCurrentCandidate(processedCandidates[newIndex])
        setCandidateSelected(processedCandidates[newIndex]?.candidate.id)
      } else if (
        e.key === 'ArrowDown' &&
        currentCandidateIndex < processedCandidates.length - 1 &&
        !isCallActive
      ) {
        const newIndex = currentCandidateIndex + 1
        setCurrentCandidateIndex(newIndex)
        setCurrentCandidate(processedCandidates[newIndex])
        setCandidateSelected(processedCandidates[newIndex]?.candidate.id)
      } else if (e.key === 'Enter' && !location.pathname.includes('candidates')) {
        const newIndex = currentCandidateIndex
        setCurrentCandidateIndex(newIndex)
        setCurrentCandidate(processedCandidates[newIndex])
        setCandidateSelected(processedCandidates[newIndex]?.candidate.id)
        setIsCallActive(!isCallActive)
      } else {
        // for when user hits enter it still allows user to move up and down list
        const newIndex = currentCandidateIndex
        setCurrentCandidateIndex(newIndex)
        setCurrentCandidate(processedCandidates[newIndex])
        setCandidateSelected(processedCandidates[newIndex]?.candidate.id)
      }
    },
    [processedCandidates, currentCandidateIndex, isCallActive],
  )

  //I dont know what this does
  // useEffect(() => {
  //   return () => {
  //     void unlockApplicant({
  //       id: currentCandidate?.candidate.id as string,
  //       clientId: currentUser?.id as string,
  //     })
  //   }
  // }, [])

  useEffect(() => {
    async function fetchData() {
      await unlockApplicant({
        id: currentCandidate?.candidate.id ?? '',
        clientId: currentUser?.id ?? '',
      })

      setCurrentCandidateIndex(0)
      setCandidateSelected(candidates[0]?.id ?? '')
      if (isDataCleanup) setDataCleanupCandidateSelected(candidates[0]?.id ?? '')
      setCurrentCandidate(processedCandidates[0])
      setIsCallActive(false)
    }

    void fetchData()
    // Removed unlockApplicant, currentCandidate, and currentUser from dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidates,
    isDataCleanup,
    processedCandidates,
    setCandidateSelected,
    setDataCleanupCandidateSelected,
  ])

  const onCleanCandidateHangUp = async () => {
    if (onCleanCandidate && isCallActive && currentCandidate && currentUser) {
      setIsCallActive(false)
      void (await unlockApplicant({id: currentCandidate.candidate.id, clientId: currentUser.id}))
      return onCleanCandidate()
    }
  }

  window.addEventListener('beforeunload', (event) => {
    void unlockApplicant({
      id: currentCandidate?.candidate.id as string,
      clientId: currentUser?.id as string,
    })
  })

  window.addEventListener('unload', (event) => {
    void unlockApplicant({
      id: currentCandidate?.candidate.id as string,
      clientId: currentUser?.id as string,
    })
  })

  if (candidates.length) {
    return (
      <>
        {settingNewIsLoading.newIsLoading ? (
          <LoadingBoundary isLoading={settingNewIsLoading.newIsLoading} />
        ) : (
          <>
            <Snackbar
              open={showCapAlert}
              autoHideDuration={3000}
              onClose={() => setShowCapAlert(false)}>
              <Alert onClose={() => setShowCapAlert(false)} severity="warning" sx={{width: '100%'}}>
                Job Order is full, please remove an applicant before adding another
              </Alert>
            </Snackbar>
            {currentCandidate && (
              <>
                <SkipOrDeclineCandidateDialog
                  varient={isDataCleanup ? 'skipCleanup' : dialogVarient}
                  open={isSkipOrDeclineDialogOpen}
                  onCloseDeclineModal={handleCloseDeclineModal}
                  onSkipOrDeclineCandidate={handleDeclineOrSkipCandidate}
                />
                {isCandidateDirectory ? (
                  <div />
                ) : (
                  <CandidateCard
                    onActivateCall={handleCallButtonClick}
                    isCallActive={isCallActive}
                    setIsCallActive={setIsCallActive}
                    addCandidate={isDataCleanup ? undefined : onAddCandidate}
                    candidate={currentCandidate}
                    isDataCleanup={isDataCleanup}
                    openSkipOrDeclineDialog={handleOpenDeclineModal}
                    loading={lockLoading || unlockLoading}
                    cleanupCandidate={onCleanCandidateHangUp}
                    isCleanupDisabled={isCleanupDisabled}
                    disableCall={isCapped}
                    unlockApplicant={unlockApplicant}
                    clientId={currentUser?.id}
                  />
                )}
                <CandidateList
                  selectedCandidate={currentCandidate}
                  handleListNavigate={handleListNavigate}
                  candidates={processedCandidates}
                  onCandidateClicked={handleSelectCandidate}
                />
              </>
            )}
          </>
        )}
      </>
    )
  }

  return <Box>No candidates available at this time. Please try again later</Box>
}

export default CandidateListContainer
