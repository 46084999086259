import React, {useRef} from 'react';

import {isEmpty, isNullOrUndefined, isString} from '~/src/utils';
import type {IDropdownNums} from '@ui/dropdown-nums/typings';
import S from '@ui/dropdown-nums/styles/dropdown-nums.styles';

/*
 * Primary UI Component
 */
export function DropdownNums({
	icon,
	error,
	isOpen,
	placeholder,
	options,
	optionIdx = -1,
	initialSelected = -1,
	buttonType = 'button',
	onChange = () => ({}),
	onToggle = () => ({}),
	...props
}: IDropdownNums): JSX.Element {
	const listRef = useRef<HTMLUListElement>(null);
	const isError = error === true || isString(error);

	return (
		<S.Container {...props} tabIndex={0}>
			<S.Button
				type={buttonType}
				error={isError}
				onClick={onToggle}
				hasIcon={!isNullOrUndefined(icon)}>
				<S.Left error={isError}>
					{icon && <S.LeftIcon icon={icon} />}
					<div>{options[initialSelected] ?? options[optionIdx] ?? placeholder}</div>
				</S.Left>

				<S.Right>
					<S.RightIcon error={isError} icon='arrowhead' />
				</S.Right>
			</S.Button>

			{isOpen && !isEmpty(options) && (
				<S.OptionList ref={listRef}>
					{options.map((opt, i: number) => (
						<S.Option key={i} data-testid={`option-${i}`} onClick={() => onChange(i)}>
							{opt}
						</S.Option>
					))}
				</S.OptionList>
			)}
		</S.Container>
	);
}
