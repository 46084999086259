import Box from '@mui/material/Box'
import ClickableDataCleanup from '@ui/card/ClickableDataCleanup'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {
  useCleanDataCleanupCandidateMutation,
  useGetAllDataCleanupsQuery,
  useSkipDataCleanupCandidateMutation,
} from '~/src/store/apis/data-cleanup-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {useAppSelector} from '~/src/store/store-hooks'
import {colors} from '../../../config/theme'
import CandidatesContainer from '../../common/candidates/CandidatesContainer'

interface UrlParams {
  cardId: string
}

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
    margin: auto;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

const TitleContainer = styled(Box)`
  display: flex
  flex-direction: row;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
`

const DataCleanupNumberContainer = styled('div')`
  font-size: 24px
  font-weight: 300;
  display: flex;
  margin: auto
  max-width: 1111px;
  width: 1111px;
  flex-irection: row;
`

const CardContainer = styled('div')`
  display: flex;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
  flex-direction: row;
  justify-content: space-between;
`

export const CompanyDetailsContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const PositionBreakdownContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export default function MspDataCleanupMain() {
  const params: UrlParams = useParams()
  const cleanupId = params.cardId
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: agencyData} = useGetOneAgencyQuery(loggedInAgencyId)
  const [candidateSelected, setDataCleanupCandidateSelected] = useState('')
  const showDetails = new URLSearchParams(location.search).get('showDetails')
  const [showCandidateDetails, setShowCandidateDetails] = useState(showDetails !== 'false')
  const [isCleanConfirmationDialogOpen, setIsCleanConfirmationDialogOpen] = useState(false)
  const [contactConfirmed, setContactConfirmed] = useState(false)
  const [availabilityConfirmed, setAvailabilityConfimred] = useState(false)
  const [skillsConfirmed, setSkillsConfimred] = useState(false)
  const [newIsLoading, setNewIsLoading] = useState(false)

  const {data: dataCleanupData, isLoading: dataCleanupIsLoading} =
    useGetAllDataCleanupsQuery(loggedInAgencyId)
  const [skipCandidate] = useSkipDataCleanupCandidateMutation()
  const [cleanCandidate] = useCleanDataCleanupCandidateMutation()

  const resetConfirmationStates = useCallback(() => {
    setContactConfirmed(false)
    setAvailabilityConfimred(false)
    setSkillsConfimred(false)
  }, [])

  const handleSummaryToggle = (value: boolean) => {
    setShowCandidateDetails(value)
  }

  const handleCleanCandidate = async () => {
    await cleanCandidate({
      id: cleanupId,
      applicantId: candidateSelected,
      agencyId: loggedInAgencyId,
    }).unwrap()
    setIsCleanConfirmationDialogOpen(true)
    resetConfirmationStates()
  }

  const handleSkipCandidate = async (notes: string) => {
    await skipCandidate({
      id: cleanupId,
      applicantId: candidateSelected,
      notes,
      agencyId: loggedInAgencyId,
    }).unwrap()
    resetConfirmationStates()
  }

  const handleCloseCleanCandidateConfirmationDialog = () => {
    setNewIsLoading(true)
    setIsCleanConfirmationDialogOpen(false)
  }

  const handleConfirmContact = () => {
    setContactConfirmed(true)
  }

  const handleConfirmAvailability = () => {
    setAvailabilityConfimred(true)
  }

  const handleConfirmSkills = () => {
    setSkillsConfimred(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setNewIsLoading(false)
      }, 1000)
    }

    void fetchData()
  }, [newIsLoading])

  return (
    <>
      <LoadingContext.Provider
        value={{setNewIsLoading: setNewIsLoading, newIsLoading: newIsLoading}}>
        {newIsLoading ? (
          <LoadingBoundary isLoading={newIsLoading} />
        ) : (
          <>
            <BasicSuccessDialog
              isOpen={isCleanConfirmationDialogOpen}
              header="Candidate Successfully Cleaned"
              bigBlurb="Candidate Successfully Cleaned"
              smallBlurb="Candidate was successfully Cleaned!"
              confirmButtonLabel="OK"
              handleSubmit={handleCloseCleanCandidateConfirmationDialog}
            />

            <AgencyOrderDetailsStyles.Container>
              <TitleContainer>
                <DataCleanupNumberContainer>
                  <h2 style={{marginBottom: 20}}>Data Clean-up</h2>
                </DataCleanupNumberContainer>
              </TitleContainer>
              <ErrorBoundary>
                <LoadingBoundary>
                  <>
                    <Box>
                      {dataCleanupData && agencyData && (
                        <CardContainer>
                          <ClickableDataCleanup
                            cleanupId={cleanupId}
                            count={3}
                            dateArray={dataCleanupData}
                            onToggleInventory={handleSummaryToggle}
                            isMsp={agencyData.isMsp}
                            showList={showDetails === 'false'}
                          />
                        </CardContainer>
                      )}
                      <CandidatesContainer
                        showCandidateDetails={showCandidateDetails}
                        handleSkipCandidate={handleSkipCandidate}
                        isCleanupDisabled={
                          !contactConfirmed || !availabilityConfirmed || !skillsConfirmed
                        }
                        onCleanCandidate={handleCleanCandidate}
                        handleConfirmContact={handleConfirmContact}
                        handleConfirmAvailability={handleConfirmAvailability}
                        handleConfirmSkills={handleConfirmSkills}
                        resetConfirmation={resetConfirmationStates}
                        setDataCleanupCandidateSelected={setDataCleanupCandidateSelected}
                        isCandidatesOnly
                        isDataCleanup
                      />
                    </Box>
                  </>
                </LoadingBoundary>
              </ErrorBoundary>
            </AgencyOrderDetailsStyles.Container>
          </>
        )}
      </LoadingContext.Provider>
    </>
  )
}
