import styled, {keyframes, css} from 'styled-components'
import {colors} from '~/src/config/theme'

const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`

export default {
  Loader: styled.div<{size: 'small' | 'medium' | 'large'; positioned?: 'relative' | 'absolute'}>`
    ${(props) =>
      props.positioned === 'absolute' &&
      css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `};

    ${(props) =>
      props.positioned === 'relative' &&
      css`
        position: relative;
      `};

    ${(props) =>
      props.size === 'small' &&
      css`
        width: 20px;
        height: 20px;
      `};
    ${(props) =>
      props.size === 'medium' &&
      css`
        width: 50px;
        height: 50px;
      `};
    ${(props) =>
      props.size === 'large' &&
      css`
        width: 80px;
        height: 80px;
      `};

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      left: 0;
      top: 0;
      border-color: transparent;
      border-style: solid;
      border-top-color: ${colors.primary};
      border-bottom-color: ${colors.primary};
      border-width: ${(props) =>
        props.size === 'small' ? '2px' : props.size === 'large' ? '6px' : '4px'};
      animation: ${rotate} 1s ease infinite;
    }
  `,
}
