import LoadingBoundary from '@ui/loader'
import {Form as FormikForm, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type {Applicant, Certification, Education, WorkHistory} from '~/src/models/applicant'
import MspCandidateEmergencyContact from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateEmergencyContact'
import MspCandidateProfileAvailability from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileAvailability'
import MspCandidateProfileCerts from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileCerts'
import MspCandidateProfileContact from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileContact'
import MspCandidateProfileEducation from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileEducation'
import MspCandidateProfileExperience from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileExperience'
import MspCandidateProfileSummary from '../candidate-details/candidate-profile/CandidateProfileMain/MspCandidateProfileSummary'
import {DashboardMainStyles} from '../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

export const createNewExperienceItem = () => ({
  companyName: '',
  position: '',
  address: {city: '', state: '', display: ''},
  contactEmployer: false,
  supervisorName: '',
  startDate: '',
  jobDuties: '',
  reasonForLeaving: undefined,
  endDate: '',
  startingPay: null,
  endingPay: null,
  startingPayFrequency: undefined,
  endingPayFrequency: undefined,
})

export const createNewEducationItem = () => ({
  degreeType: undefined,
  nameOfSchool: '',
  graduationDate: '',
  address: {
    city: '',
    state: '',
    display: '',
  },
})
export const createNewCertificationItem = () => ({
  certificationName: '',
  establishment: '',
  expirationDate: null,
  address: {display: '', state: ''},
})

interface IProps {
  handleSubmit: () => void
  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

export default function JobOrderSummaryProfile({
  handleSubmit,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
}: IProps) {
  const workHistoryValues = oldApplicant
    ? oldApplicant.workHistories.map((i: WorkHistory) => {
        return {
          companyName: i.companyName,
          position: i?.position,
          address: {city: i?.city, state: i?.state, display: `${i?.city}, ${i?.state}`},
          contactEmployer: i.contactEmployer,
          supervisorName: i.supervisorName,
          startDate: i.startDate,
          jobDuties: i.jobDuties,
          reasonForLeaving: i?.reasonForLeaving,
          endDate: i?.endDate ? i.endDate : '',
          startingPay: i?.startingPay,
          endingPay: i?.endingPay,
          startingPayFrequency: i?.startingPayFrequency,
          endingPayFrequency: i?.endingPayFrequency,
        }
      })
    : []

  // using time zone function here because time with T00:00:00.000+00:00 imported from ollieboard are showing one date previous so this hopefully corrects it
  const educationValues = oldApplicant
    ? oldApplicant.education.map((i: Education) => {
        return {
          degreeType: i.degreeType,
          nameOfSchool: i.nameOfSchool,
          graduationDate: i.graduationDate,
          address: {city: i?.city, state: i?.state, display: `${i?.city}, ${i?.state}`},
        }
      })
    : []

  const certificationValues = oldApplicant
    ? oldApplicant.certifications.map((i: Certification) => {
        return {
          certificationName: i?.certificationName,
          establishment: i?.establishment,
          expirationDate: i.expirationDate,
          state: i?.state,
        }
      })
    : []

  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,
    // basic info
    address: oldApplicant.address,
    id: oldApplicant.id,
    lastName: oldApplicant.lastName,
    firstName: oldApplicant.firstName,
    middleName: oldApplicant.middleName,
    contactEmail: oldApplicant.contactEmail,
    contactCellPhone: oldApplicant.contactCellPhone,
    contactHomePhone: oldApplicant.contactHomePhone,

    //emergency contatct
    emergencyContactName: oldApplicant.emergencyContactName,
    emergencyContactRelationship: oldApplicant.emergencyContactRelationship,
    emergencyPhone: oldApplicant.emergencyPhone,

    //availability
    availability: oldApplicant.availability,

    //summary
    summary: oldApplicant.summary,

    //work history
    workHistories: workHistoryValues,

    //education
    education: educationValues,

    //certifications
    certifications: certificationValues,
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          address: Yup.object({
            line1: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
            display: Yup.string().required('Required'),
          }),
          id: Yup.string(),
          branch: Yup.string(),
          employeeID: Yup.string(),
          lastName: Yup.string().required('Required'),
          firstName: Yup.string().required('Required'),
          middleName: Yup.string(),
          ssn: Yup.string(),
          employeeStatus: Yup.string(),

          dateofBirth: Yup.date()
            .required('Required')
            .nullable()
            .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
          gender: Yup.string(),
          contactCellPhone: Yup.string().when('$contactCellPhone', ([contactCellPhone], schema) => {
            if (contactCellPhone?.length > 0) {
              return Yup.string().required('Required').phone('US', true, 'Phone number is invalid.')
            } else {
              return Yup.string().notRequired()
            }
          }),
          contactHomePhone: Yup.string().when('$contactCellPhone', ([contactCellPhone], schema) => {
            if (contactCellPhone?.length > 0) {
              return Yup.string().notRequired()
            } else {
              return Yup.string().required('Required').phone('US', true, 'Phone number is invalid.')
            }
          }),
          userStaffingRep: Yup.string(),
          emergencyPhone: Yup.string().phone('US', true, 'Phone number is invalid.'),
          emergencyContactName: Yup.string(),
          emergencyContactRelationship: Yup.string(),
          agencyId: Yup.string(),
          // i dont think this value exists
          email: Yup.string(),
          availability: Yup.object({
            startDate: Yup.date()
              .required('Required')
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            endDate: Yup.date()
              .required('Required')
              .nullable()
              .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
            overAllAvailability: Yup.boolean(),
            shifts: Yup.array().of(
              Yup.object({
                shift: Yup.string(),
                shiftAvailability: Yup.array().of(Yup.string()),
              }),
            ),
          }),

          summary: Yup.array().of(Yup.string().required('Required')),
          workHistories: Yup.array().of(
            Yup.object({
              companyName: Yup.string().required('Required'),
              position: Yup.string().required('Required'),
              address: Yup.object({
                city: Yup.string(),
                state: Yup.string(),
                display: Yup.string().required('Required'),
              }),
              contactEmployer: Yup.boolean().required('Required'),
              supervisorName: Yup.string().required('Required'),
              jobDuties: Yup.string().required('Required'),
              reasonForLeaving: Yup.string(),
              startDate: Yup.date()
                .required('Required')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
              endDate: Yup.date()
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
              startingPay: Yup.number().nullable(),
              endingPay: Yup.number().nullable(),
              startingPayFrequency: Yup.string().nullable(),
              endingPayFrequency: Yup.string().nullable(),
            }),
          ),

          education: Yup.array().of(
            Yup.object({
              degreeType: Yup.string().required('Required'),
              nameOfSchool: Yup.string().required('Required'),
              graduationDate: Yup.date()
                .required('Required')
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
              address: Yup.object({
                city: Yup.string(),
                state: Yup.string(),
                display: Yup.string().required('Required'),
              }),
            }),
          ),
          certifications: Yup.array().of(
            Yup.object({
              certificationName: Yup.string().required('Required'),
              establishment: Yup.string().required('Required'),
              expirationDate: Yup.date()
                .nullable()
                .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),

              state: Yup.string(),
            }),
          ),
        })}>
        {({setFieldValue, values, resetForm, errors}) => {
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <DashboardMainStyles>
                  <JobOrderCandidateNameHeader
                    singleApplicant={values}
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    oldApplicant={oldApplicant}
                  />

                  {/* contact information */}
                  <MspCandidateProfileContact
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  />

                  {/* emergency information */}
                  <MspCandidateEmergencyContact
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  />

                  {/* availability info */}
                  <MspCandidateProfileAvailability
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    values={values}
                  />

                  {/* summary information */}
                  <MspCandidateProfileSummary
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    values={values}
                  />

                  {/* work history infomation */}
                  <MspCandidateProfileExperience
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    setFieldValue={setFieldValue}
                    resetForm={resetForm}
                    values={values}
                  />

                  {/* education infomation */}
                  <MspCandidateProfileEducation
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    setFieldValue={setFieldValue}
                    resetForm={resetForm}
                    values={values}
                  />

                  {/* certification infomation */}
                  <MspCandidateProfileCerts
                    handleSubmit={handleSubmit}
                    oldApplicant={oldApplicant}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </DashboardMainStyles>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}
