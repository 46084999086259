import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function DocumentIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M14.8873 5.44246L10.5575 1.11266C10.4534 1.00853 10.3122 0.95 10.1649 0.95H2.85837C1.80605 0.95 0.95 1.80605 0.95 2.85837V16.389C0.95 17.4413 1.80605 18.2974 2.85834 18.2974H13.1416C14.1939 18.2974 15.05 17.4413 15.05 16.389V5.83509C15.05 5.68782 14.9915 5.54659 14.8873 5.44246ZM10.7202 4.48205V2.84588L13.1541 5.27979H11.518C11.0781 5.27979 10.7202 4.92186 10.7202 4.48205ZM13.1416 17.1868H2.85834C2.41852 17.1868 2.06059 16.8288 2.06059 16.389V2.85837C2.06059 2.41852 2.41852 2.06059 2.85834 2.06059H9.60959V4.48205C9.60959 5.53433 10.4656 6.39038 11.5179 6.39038H13.9394V16.389C13.9394 16.8288 13.5815 17.1868 13.1416 17.1868Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
			<path
				d='M12.3299 15.0218H3.67033C3.36366 15.0218 3.11504 15.2704 3.11504 15.5771C3.11504 15.8838 3.36366 16.1324 3.67033 16.1324H12.3299C12.6366 16.1324 12.8852 15.8838 12.8852 15.5771C12.8852 15.2704 12.6366 15.0218 12.3299 15.0218Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
			<path
				d='M12.3299 12.857H3.67033C3.36366 12.857 3.11504 13.1056 3.11504 13.4123C3.11504 13.719 3.36366 13.9676 3.67033 13.9676H12.3299C12.6366 13.9676 12.8852 13.719 12.8852 13.4123C12.8852 13.1056 12.6366 12.857 12.3299 12.857Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
			<path
				d='M12.3299 10.692H3.67033C3.36366 10.692 3.11504 10.9406 3.11504 11.2473C3.11504 11.554 3.36366 11.8026 3.67033 11.8026H12.3299C12.6366 11.8026 12.8852 11.554 12.8852 11.2473C12.8852 10.9406 12.6366 10.692 12.3299 10.692Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
			<path
				d='M3.67033 9.63777H10.165C10.4717 9.63777 10.7203 9.38915 10.7203 9.08248C10.7203 8.77581 10.4717 8.52719 10.165 8.52719H3.67033C3.36366 8.52719 3.11504 8.77581 3.11504 9.08248C3.11504 9.38915 3.36366 9.63777 3.67033 9.63777Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
			<path
				d='M3.67033 7.47283H6.91765C7.22432 7.47283 7.47295 7.2242 7.47295 6.91753V3.67021C7.47295 3.36354 7.22432 3.11492 6.91765 3.11492H3.67033C3.36366 3.11492 3.11504 3.36354 3.11504 3.67021V6.91753C3.11504 7.2242 3.36366 7.47283 3.67033 7.47283ZM4.22563 4.22551H6.36236V6.36224H4.22563V4.22551Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='0.1'
			/>
		</Svg>
	);
}
