import {Alert, Snackbar} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {push} from 'connected-react-router'
import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {EllipsisMenu} from '~/src/components/platform/manage-companies/EllipsisMenu'
import type {AllDataPayload, ICompanyBase} from '~/src/models/company'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import type {TCompanyStatus} from './CompanyMain'
import PrependedCompanyHeader from './PrependedCompanyHeader'

interface IProps {
  data: AllDataPayload
  addPagination: number
  currentPageNumber: number
  onPaginationChange(value: number): void
  setSelectedStatus(s: TCompanyStatus): void
  setTargetCompany(s: ICompanyBase): void
  isAgency: boolean
}

interface Data {
  name: string
  type: string
  email: string
  admin: string
  date: string
  status: string
  ellipsis: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
    align: 'left',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email Address',
    align: 'left',
  },
  {
    id: 'admin',
    numeric: true,
    disablePadding: false,
    label: 'Administrator',
    align: 'left',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date Joined',
    align: 'left',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    align: 'center',
  },
  {
    id: 'ellipsis',
    numeric: true,
    disablePadding: false,
    label: '',
    align: 'center',
  },
]

export default function CompanyTable({
  data,
  addPagination,
  setSelectedStatus,
  setTargetCompany,
  currentPageNumber,
  onPaginationChange,
  isAgency,
}: IProps) {
  const dispatch = useAppDispatch()
  const location = useLocation()

  interface IState {
    state: {
      openSnackbar: boolean
      name: string
      type: string
    }
  }
  const locationState = location.state as IState
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [open, setOpen] = useState(
    locationState?.state.openSnackbar ? locationState?.state.openSnackbar : false,
  )
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)

    dispatch(
      push(
        isAgency
          ? `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_AGENCIES}`
          : `${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_COMPANIES}`,
      ),
    )
  }

  const {companyTypeMaps} = useAppSelector((state) => state.root)

  return (
    <Box
      sx={{
        width: '100%',
        mb: 10,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <PrependedCompanyHeader isAgency={isAgency} />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        {locationState?.state.type === 'agency' ? (
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! The Agency {locationState?.state.name} has been Edited!
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! The Company {locationState?.state.name} has been Edited!
          </Alert>
        )}
      </Snackbar>
      <TableContainer>
        <Table
          sx={{minWidth: 750, height: 65}}
          aria-labelledby="tableTitle"
          size="small"
          padding="normal">
          <TableHead>
            <TableRow sx={{height: 65}}>
              {isAgency ? (
                <>
                  <StyledTableCell aria-label="blank Column" />
                  <StyledTableCell
                    aria-label="Agency Name"
                    data-testid="Agency Name"
                    align="left"
                    padding="normal">
                    Agency Name
                  </StyledTableCell>

                  {headCells.map((headCell) => (
                    <StyledTableCell
                      key={headCell.id}
                      aria-label={headCell.label}
                      align={headCell.align}
                      padding="normal">
                      {headCell.label}
                    </StyledTableCell>
                  ))}
                </>
              ) : (
                <>
                  <StyledTableCell aria-label="blank Column" />
                  <StyledTableCell
                    aria-label="Company Name"
                    data-testid="Company Name"
                    align="left"
                    padding="normal">
                    Company Name
                  </StyledTableCell>

                  {headCells.map((headCell) => (
                    <StyledTableCell
                      key={headCell.id}
                      aria-label={headCell.label}
                      align={headCell.align}
                      padding="normal">
                      {headCell.label}
                    </StyledTableCell>
                  ))}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`
              const specificType = isAgency ? 'Agency' : 'Company'

              return (
                <TableRow
                  sx={{height: 65, textDecoration: 'none'}}
                  hover
                  tabIndex={-1}
                  key={index}
                  onClick={() => {
                    dispatch(
                      push(`/platform-admin/manage-companies/${encodeURIComponent(row?.name)}`, {
                        currentPageNumber: currentPageNumber,
                        specificType: specificType,
                        data: row,
                      }),
                    )
                  }}>
                  <StyledTableCell />
                  <StyledTableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    {row?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{specificType}</StyledTableCell>
                  <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                    {row?.adminEmail}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{maxWidth: '180px'}}
                    align="left">{`${row?.adminFirstName} ${row?.adminLastName}`}</StyledTableCell>
                  <StyledTableCell align="left">
                    {new Date(row?.createdDate).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.status}</StyledTableCell>
                  <StyledTableCell
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault()
                    }}
                    align="center">
                    <EllipsisMenu
                      specificType={specificType}
                      status={row?.status}
                      onSelect={(value: TCompanyStatus) => {
                        setSelectedStatus(value)
                        setTargetCompany(row)
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={currentPageNumber}
        totalRecords={addPagination}
        recordsPerPage={10}
        onChange={onPaginationChange}
      />
    </Box>
  )
}
