import {styled, css} from '@mui/material/styles'
import {NavLink as Link} from 'react-router-dom'
import {Box, Paper, PaperProps} from '@mui/material'
import DowntownBg from '~/src/assets/images/downtown-bg.jpeg'
import DowntownPa from '~/src/assets/images/Paris.jpg'
import StyledGreeting from '../Greeting'

const ForgotPWLink = styled(Link)(
  ({theme}) => css`
    color: ${theme.palette.text.primary};
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    text-decoration: none;
  `,
)

const ForgotPWLinkContainer = styled('div')(
  () => css`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
)

const RegisterLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.dark};
  text-decoration: none;
  margin-bottom: 38px;
`

const FieldContainer = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(4)};
    margin-top: ${theme.spacing(5)};
  `,
)

const FormTitle = styled('h2')(
  ({theme}) => css`
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.h2.fontWeight};
    margin-top: 50px;
  `,
)

const FormSubtitle = styled('h3')(
  ({theme}) => css`
    font-size: ${theme.typography.h3.fontSize};
  `,
)

const Title = styled('div')(
  ({theme}) => css`
    text-align: center;
    font-size: ${theme.typography.h2.fontSize};
    margin-top: 50px;

    ${theme.breakpoints.up('lg')} {
      text-align: left;
    }
  `,
)

const Greeting = styled(StyledGreeting)(
  ({theme}) => css`
    display: none;

    ${theme.breakpoints.up('lg')} {
      display: flex;
      justify-content: flex-start;
      padding-top: 70px;
      border-radius: ${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px;
      box-shadow: ${theme.shadows[2]};

      ${Title}:last-child {
        margin-top: 1.25rem;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
      }

      ${RegisterLink} {
        color: inherit;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 38px;
        text-decoration: none;
      }
    }
  `,
)

const InnerBox = styled(Box)(
  ({theme}) => css`
    background-color: ${theme.palette.background.paper};
    flex: 1;
    padding: 27px;
    position: relative;

    ${theme.breakpoints.up('lg')} {
      border-radius: ${theme.shape.borderRadius}px;
      grid-column: 2/3;
      grid-row: 1/2;
      min-height: 586px;
      padding: 32px 130px;
    }
  `,
)

const InnerPaper = styled(Paper)<PaperProps>(
  ({theme}) => css`
    width: 100%;

    ${theme.breakpoints.up('lg')} {
      display: grid;
      grid-template-columns: 350px auto;
      grid-template-rows: auto 1fr;
    }
  `,
)

const Container = styled(Box)`
  color: ${(props) => props.theme.palette.text.primary};
`

const MaxWidth = styled('div')(
  ({theme}) => css`
    left: 50%;
    max-width: 890px;
    padding: 30px 9.866%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);

    ${theme.breakpoints.up('lg')} {
      padding: 0;
    }
  `,
)

const Background = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownBg}) center 40% no-repeat;
  background-size: 135%;
`

const BackgroundPa = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownPa}) center 40% no-repeat;
  background-size: 135%;
`

const Outer = styled('div')`
  width: 100%;
  height: 100vh;
  flex: 1;
  overflow: hidden;
`

const VersionNumber = styled('div')`
  display: flex;
  justify-content: right;
  color: #a9a9a9;
`

export default {
  Background,
  BackgroundPa,
  Container,
  FieldContainer,
  ForgotPWLink,
  ForgotPWLinkContainer,
  FormTitle,
  FormSubtitle,
  Greeting,
  InnerPaper,
  InnerBox,
  MaxWidth,
  RegisterLink,
  Outer,
  Title,
  VersionNumber,
}
