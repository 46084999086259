import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ChatIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<g clipPath='url(#clip0)'>
				<path
					d='M5.00008 14.3H4.71013L4.50511 14.5051L2.36675 16.6434V3.33336C2.36675 2.80329 2.80335 2.36669 3.33342 2.36669H16.6668C17.1968 2.36669 17.6334 2.80329 17.6334 3.33336V13.3334C17.6334 13.8634 17.1968 14.3 16.6668 14.3H5.00008Z'
					stroke='currentColor'
					strokeWidth='1.4'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</Svg>
	);
}
