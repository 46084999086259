import {Button} from '@mui/material'
import Box from '@mui/material/Box'
import JobOrderCard from '@ui/card/JobOrderCard'
import JobOrderDistributeOptionalCard from '@ui/card/JobOrderDistributeOptionalCard'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {formatShift} from '@utils'
import {push} from 'connected-react-router'
import {Form as FormikForm, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import type {
  IJobOrder,
  JobOrderCreate,
  JobOrderCreateAgencyDistribution,
} from '~/src/models/jobOrder'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {
  useCreateJobOrderMutation,
  useGetJobOrdersByServiceOrderIdsQuery,
} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {
  useGetSingleServiceOrderQuery,
  useUpdateMspStatusToConfiguringJobOrderMutation,
} from '~/src/store/apis/service-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import MspJobOrdersDetailsDistributionTable from './MspJobOrdersDetailsDistributionTable'

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;

    margin: auto;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  maxWidth: '1111px',
  width: '1111px',
})

const JobOrderNumberContainer = styled('div')({
  fontSize: '24px',
  fontWeight: '300',
  display: 'flex',
  margin: 'auto',
  maxWidth: '1111px',
  width: '1111px',
  flexDirection: 'row',
})

const JobOrderCardContainer = styled('div')({
  overflow: 'scroll',
  display: 'flex',
  margin: 'auto',
  maxWidth: '1111px',
  width: '1111px',
  flexDirection: 'row',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const TableContainer = styled('div')({
  maxWidth: '1111px',
  width: '1111px',
  margin: 'auto',
})

const TableHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px 10px 16px',
  fontWeight: '600',
  backgroundColor: '#FFFFFF',
  fontStyle: 'normal',
  fontSize: '15px',
  lineHeight: '20px',
  marginTop: '63px',
})
interface IParams {
  orderId: string
}

export default function MspJobOrdersDetailsMain() {
  const dispatch = useAppDispatch()
  const [distributeJODialog, setDistributeJODialog] = useState(false)
  const [targetJobOrder, setTargetJobOrder] = useState(0)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {orderId}: IParams = useParams()
  const [createJobOrder] = useCreateJobOrderMutation()
  const [updateMspStatusToConfiguringJobOrder] = useUpdateMspStatusToConfiguringJobOrderMutation()
  const {
    data: matchServiceOrder,
    refetch: matchServiceOrderRefetch,
    isLoading,
    isSuccess: serviceOrderSuccess,
    error: serviceOrderError,
  } = useGetSingleServiceOrderQuery({id: orderId})
  const {data: positionData, refetch: positionRefetch} = useGetPositionByIdQuery(
    {
      id: matchServiceOrder?.orders[0]?.positionId as string,
    },
    {skip: !serviceOrderSuccess},
  )

  const {data: companyName, refetch: companyRefetch} = useGetCompanyNameByIdQuery(
    {
      id: matchServiceOrder?.companyId,
    },
    {skip: !serviceOrderSuccess},
  )
  const shift = formatShift(positionData)

  const {
    data: jobOrderBatch,
    refetch: jobOrderRefetch,
    isSuccess: jobOrderSuccess,
  } = useGetJobOrdersByServiceOrderIdsQuery(
    {serviceOrderIds: [matchServiceOrder?.id as string], agencyId: loggedInAgencyId},
    {
      skip: !serviceOrderSuccess,
    },
  )

  const jobOrderData = jobOrderBatch ? jobOrderBatch[0]?.jobOrders : undefined
  const serviceOrderFromLocationState: Partial<IServiceOrder> | undefined = matchServiceOrder
    ? matchServiceOrder.orders
    : []

  // changing state for values to change in the header job order and unsubmitted days
  const displayJobOrderCards = serviceOrderFromLocationState
    .map(
      (
        order: {orderDistribution: {agencyDistributions: Partial<IServiceOrder>}},
        indexOne: number,
      ) => order.orderDistribution.agencyDistributions,
    )
    .flat()
    .map(
      (
        agencyQuantityDistribution: {agencyQuantityDistributions: Partial<IServiceOrder>},
        indexTwo: number,
      ) => agencyQuantityDistribution.agencyQuantityDistributions,
    )
    .flat()

  const [jobOrderManipulateArray, setJobOrderManipulateArray] = useState(displayJobOrderCards)
  const filteredArray = displayJobOrderCards.filter(
    (item: {date: Date}) => !jobOrderData?.some((item2) => item.date === item2.date),
  )[0]
  const [targetedJobOrder, setTargetedJobOrder] = useState(filteredArray)
  // number of agencies total function
  const numAgencies = serviceOrderFromLocationState
    .map(
      (
        order: {orderDistribution: {agencyDistributions: Partial<IServiceOrder>}},
        indexOne: number,
      ) => order.orderDistribution.agencyDistributions,
    )
    ?.flat()
    ?.map(
      (
        agencyQuantityDistribution: {agencyQuantityDistributions: Partial<IServiceOrder>},
        indexTwo: number,
      ) => agencyQuantityDistribution,
    )?.length
  let numAgenciesLabel = '(0) Agencies'
  if (numAgencies === 1) {
    numAgenciesLabel = '(1) Agency'
  } else if (numAgencies !== 0 || numAgencies !== null || numAgencies !== undefined) {
    numAgenciesLabel = `(${numAgencies}) Agencies`
  }

  // variables for table
  const filterSameDayRequestedAndPledged = jobOrderManipulateArray?.filter(
    (i, indexOne: number) => i?.date === targetedJobOrder?.date,
  )

  const totalRequested = filterSameDayRequestedAndPledged
    ?.map((distribution: {requested: number}, indexOne: number) => distribution.requested)
    ?.reduce((prev: number, curr: number) => prev + curr, 0)

  const totalPledged = filterSameDayRequestedAndPledged
    ?.map((distribution: {pledged: number}, indexOne: number) => distribution.pledged)
    ?.reduce((prev: number, curr: number) => prev + curr, 0)

  const pledged = jobOrderManipulateArray?.[targetJobOrder]?.pledged

  const requested = jobOrderManipulateArray?.[targetJobOrder]?.requested
  // date nothing fancy
  const dateForTable = new Date(jobOrderManipulateArray?.[targetJobOrder]?.date).toLocaleDateString(
    'En-US',
  )

  //this condenses the data
  const multipleAgencyValues = serviceOrderFromLocationState
    .map(
      (
        order: {orderDistribution: {agencyDistributions: Partial<IServiceOrder>}},
        indexOne: number,
      ) => order.orderDistribution?.agencyDistributions,
    )[0]
    ?.map((newData: Partial<IServiceOrder>, indexTwo: number) => {
      return {
        agencyId: newData?.agencyId,
        agencyQuantityDistributions: newData?.agencyQuantityDistributions,
      }
    })
    .map((i: Partial<IServiceOrder>, indexTwo: number) => {
      const filteredData = i?.agencyQuantityDistributions?.filter(
        (f: Partial<IServiceOrder>, indexThree: number) => f.date === targetedJobOrder?.date,
      )

      return {
        agencyId: i.agencyId,
        totalPledged: filteredData?.[0]?.pledged,
        totalRequested: filteredData?.[0]?.requested,
        requested: 0,
        date: filteredData?.[0]?.date,
      }
    })
  const removeUnwantedData = multipleAgencyValues
    ? multipleAgencyValues.filter((i) => i.totalPledged)
    : []

  const uniqueDates: Array<unknown> = [
    ...new Set(jobOrderManipulateArray?.map((item: Partial<IJobOrder>) => item.date)),
  ]

  const initialFormValues = {
    agencyDistributions: removeUnwantedData,
  }

  const handleSubmit = async (values: JobOrderCreate) => {
    if (uniqueDates.length === 1) {
      const date = jobOrderManipulateArray[0].date
      const res = await createJobOrder({
        serviceOrderId: matchServiceOrder?.id,
        positionId: serviceOrderFromLocationState[0].positionId,
        date: date,
        agencyId: loggedInAgencyId,
        agencyDistributions: values.agencyDistributions.map(
          (info: JobOrderCreateAgencyDistribution) => {
            return {
              agencyId: info.agencyId,
              agencyCandidateDistributions: {
                requested: info.requested,
              },
            }
          },
        ),
      })
        .unwrap()
        .catch((error) => console.log(error))
      // optional if theres a single day SO it will redirect to summary page as well as the first day in ta multi day SO
      // the jobOrderData && gets rid of the red lines
      const firstJobOrderId = jobOrderData && jobOrderData.length > 0 ? jobOrderData[0]?.id : res.id
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${
            SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
          }/${encodeURIComponent(firstJobOrderId)}?showDetails=true`,
        ),
      )
      setDistributeJODialog(false)
    } else {
      const res = await createJobOrder({
        serviceOrderId: matchServiceOrder?.id,
        positionId: serviceOrderFromLocationState[0]?.positionId,
        date: jobOrderManipulateArray?.[targetJobOrder]?.date,
        agencyId: loggedInAgencyId,
        agencyDistributions: values.agencyDistributions.map(
          (info: JobOrderCreateAgencyDistribution) => {
            return {
              agencyId: info.agencyId,
              agencyCandidateDistributions: {
                requested: info.requested,
              },
            }
          },
        ),
      })
        .unwrap()
        .catch((error) => console.log(error))

      setDistributeJODialog(false)
    }
  }

  const display = () => {
    setDistributeJODialog(true)
  }

  //useEffect for each time we submit, didnt add some dependcies because it then causes an infinate loop
  // the if (serviceOrderSuccess) awaits the query, if it isnt there the page breaks because it says we cannot refetch a query that isnt finished
  useEffect(() => {
    if (serviceOrderSuccess) {
      void jobOrderRefetch()
    }
    if (jobOrderData) {
      setJobOrderManipulateArray(
        displayJobOrderCards?.filter(
          (x: {date: Date}) => !jobOrderData.some((y: {date: Date}) => y.date === x.date),
        ),
      )
      setTargetedJobOrder(filteredArray)
    } else {
      setJobOrderManipulateArray(displayJobOrderCards)
      setTargetedJobOrder(displayJobOrderCards?.at(jobOrderManipulateArray[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOrderData, jobOrderRefetch, totalRequested])

  useEffect(() => {
    if (matchServiceOrder?.mspStatus === 'Pledges Received') {
      void updateMspStatusToConfiguringJobOrder({
        serviceOrderId: matchServiceOrder.id,
        agencyId: loggedInAgencyId,
      })
    }
  }, [loggedInAgencyId, matchServiceOrder, updateMspStatusToConfiguringJobOrder])

  return (
    <LoadingBoundary isLoading={isLoading}>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          serviceOrderId: Yup.string(),
          positionId: Yup.string(),
          date: Yup.date(),
          agencyDistributions: Yup.array().of(
            Yup.object({
              agencyId: Yup.string(),
              requested: Yup.number().required('Required').typeError('Required'),
            }),
          ),
        })}>
        {({isSubmitting, setFieldValue, handleChange, values}) => {
          const totalJobOrderRequested = values.agencyDistributions
            .map(
              (agencyQuantityDistribution: {requested: number}, indexOne: number) =>
                agencyQuantityDistribution.requested,
            )
            ?.reduce((prev: number, curr: number) => prev + curr, 0)

          return (
            <FormikForm>
              <BasicConfirmationDialog
                isOpen={distributeJODialog}
                header="Order will be Sent to Recruiter(s)"
                bigBlurb="Are you ready to distribute this Job Order?"
                smallBlurb=""
                cancelButtonLabel="Back"
                handleCancel={() => {
                  setDistributeJODialog(false)
                }}
                handleSubmit={() => {
                  void handleSubmit(values)
                  setFieldValue('requested', 0)
                }}
              />
              <AgencyOrderDetailsStyles.Container>
                <TitleContainer>
                  <JobOrderNumberContainer>
                    <h2 style={{marginBottom: 20}}>
                      Service Order #{matchServiceOrder?.orderNumber}
                    </h2>
                  </JobOrderNumberContainer>
                </TitleContainer>
                <ErrorBoundary error={serviceOrderError}>
                  <>
                    <Box>
                      <JobOrderCardContainer>
                        <>
                          {jobOrderData?.map((jobOrderRow: IJobOrder, jobOrderIndex: number) => {
                            const totalCandidatesNeeded = jobOrderRow.agencyDistributions
                              .map(
                                (distribution, indexOne: number) =>
                                  distribution.agencyCandidateDistributions.requested,
                              )
                              .reduce((prev: number, curr: number) => prev + curr, 0)

                            const totalCandidatesFilled = jobOrderRow.agencyDistributions
                              .map(
                                (distribution, indexOne: number) =>
                                  distribution.agencyCandidateDistributions.fulfilled,
                              )
                              .reduce((prev: number, curr: number) => prev + curr, 0)

                            return (
                              <div
                                key={jobOrderIndex}
                                style={{maxWidth: '260px', marginRight: '20px'}}>
                                <JobOrderCard
                                  currentlyfilled={totalCandidatesFilled}
                                  isSuccess={serviceOrderSuccess}
                                  jobOrder={jobOrderRow}
                                  position={positionData}
                                  serviceOrder={matchServiceOrder}
                                  totalrequested={totalCandidatesNeeded}
                                />
                              </div>
                            )
                          })}
                        </>
                        {uniqueDates.map((value: unknown | Date | undefined, index: number) => {
                          const matchedRow = targetedJobOrder?.date === value

                          return (
                            <div key={index} style={{maxWidth: '260px', marginRight: '20px'}}>
                              <JobOrderDistributeOptionalCard
                                client={companyName}
                                distributionDate={value}
                                isInConfigurator
                                matchedRow={matchedRow}
                                position={positionData?.name}
                                serviceOrderNumber={matchServiceOrder?.orderNumber}
                                shift={shift}
                                style={{}}
                              />
                            </div>
                          )
                        })}
                      </JobOrderCardContainer>
                    </Box>

                    <TableContainer>
                      <TableHeaderContainer>
                        <div style={{display: 'flex', justifyItems: 'center'}}>
                          <AgencyOrderDetailsStyles.TableInfo>
                            <div style={{margin: 10}}>Job Order Distribution:</div>
                          </AgencyOrderDetailsStyles.TableInfo>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={
                              totalJobOrderRequested > totalPledged ||
                              totalJobOrderRequested === null ||
                              totalJobOrderRequested === 0 ||
                              isNaN(totalJobOrderRequested)
                            }
                            onClick={display}>
                            Distribute Job Order
                          </Button>
                        </div>
                      </TableHeaderContainer>
                      {positionData && matchServiceOrder ? (
                        <MspJobOrdersDetailsDistributionTable
                          date={dateForTable}
                          numAgenciesLabel={numAgenciesLabel}
                          position={positionData.name}
                          pledged={pledged}
                          requested={requested}
                          setFieldValue={setFieldValue}
                          shift={shift}
                          totalJobOrderRequested={totalJobOrderRequested}
                          totalNeeded={totalRequested}
                          totalRequested={totalRequested}
                          totalPledged={totalPledged}
                          values={values}
                        />
                      ) : (
                        <div />
                      )}
                    </TableContainer>
                  </>
                </ErrorBoundary>
              </AgencyOrderDetailsStyles.Container>
            </FormikForm>
          )
        }}
      </Formik>
    </LoadingBoundary>
  )
}
