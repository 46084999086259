import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ArrowheadIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='15'
			height='7'
			viewBox='0 0 15 7'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.1946 0.304536C14.0738 0.207999 13.9303 0.131411 13.7724 0.0791544C13.6145 0.0268982 13.4452 0 13.2742 0C13.1032 0 12.9339 0.0268982 12.7759 0.0791544C12.618 0.131411 12.4745 0.207999 12.3537 0.304536L7.2881 4.34496L2.22247 0.304536C1.97835 0.109827 1.64727 0.000440938 1.30204 0.000440938C0.956808 0.000440938 0.625716 0.109827 0.381603 0.304536C0.137489 0.499244 0.000350952 0.763325 0.000350952 1.03868C0.000350952 1.31404 0.137489 1.57812 0.381603 1.77283L6.3742 6.55261C6.49498 6.64915 6.63845 6.72573 6.79639 6.77799C6.95433 6.83025 7.12364 6.85714 7.29463 6.85714C7.46562 6.85714 7.63493 6.83025 7.79287 6.77799C7.95081 6.72573 8.09428 6.64915 8.21506 6.55261L14.2077 1.77283C14.7038 1.37712 14.7038 0.71066 14.1946 0.304536Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
