import React from 'react'
import {useTheme} from '@mui/material/styles'

export default function Unchecked({error}: {error?: boolean}) {
  const theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        stroke={error ? theme.palette.error.main : theme.palette.grey[700]}
      />
    </svg>
  )
}
