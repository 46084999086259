import {useField} from 'formik'
import React from 'react'
import type {IStandardAutocompleteProps} from './StandardAutocomplete'
import StandardAutocomplete from './StandardAutocomplete'

export type IFormikAutocompleteProps = IStandardAutocompleteProps & {
  onChange?: (value: unknown) => void
}

export default function FormikAutocomplete({
  name,
  onChange,
  freeSolo,
  defaultValue,
  inputValue,
  ...rest
}: IFormikAutocompleteProps) {
  const [field, meta, helpers] = useField(name as string)
  const errorMsg = meta.touched && meta.error ? meta.error : ''

  const onInputChangeHandler = function (e: React.SyntheticEvent, value: unknown): void {
    helpers.setValue(value ?? '')
    if (onChange) {
      onChange(value)
    }
  }

  const onTextFieldChangeHandler = function (e: React.ChangeEvent<HTMLInputElement>): void {
    if (freeSolo) {
      helpers.setValue(e.target.value)
      if (onChange) {
        onChange(e.target.value)
      }
    }
  }

  return (
    <StandardAutocomplete
      name={field.name}
      onChange={onChange}
      onInputChange={onInputChangeHandler}
      onTextFieldChange={onTextFieldChangeHandler}
      freeSolo={freeSolo}
      helperText={errorMsg}
      defaultValue={defaultValue}
      value={field.value}
      inputValue={inputValue}
      {...rest}
    />
  )
}
