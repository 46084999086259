import MspAvailabilityDayBubble from '@ui/day-bubble/MspAvailabilityDayBubble'
import {newTimezoneDateFunction} from '@utils/TimezoneFunction'
import React from 'react'
import type {Availability} from '~/src/models/applicant'
import {
  AvailabilityContainerSimple,
  AvailabiltyMarginBottom,
  DashboardMainStylesMaxWidth,
  DayBubbleContainer,
  FlexColumnContainer,
  FlexSpaceBetweenContainer,
  FlexWithoutPaddingContainer,
  InformationAvailabilityCardDisplayContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileAvailabilityHeaderText,
  UnavailabilityContainerSimple,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import AvailabilityShift from './AvailabilityShift'

interface IProps {
  handleClick(value: string): void
  values: Availability | undefined
}

export default function MspCandidateAvailabilityDisplay({handleClick, values}: IProps) {
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationAvailabilityCardDisplayContainer isAvailable={values?.overAllAvailability}>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle aria-label="Availability Information">
                Availability Information
              </InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit
                aria-label="Edit Button"
                onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          {values?.overAllAvailability ? (
            <>
              <InformationCardDetailsContainer>
                <FlexSpaceBetweenContainer
                  style={{alignItems: 'center', height: '75px', marginTop: '10px'}}>
                  <FlexWithoutPaddingContainer>
                    <InformationCardProfileAvailabilityHeaderText
                      style={{marginTop: '0px', marginBottom: '0px'}}
                      aria-label="Start Date">
                      Start Date:
                      <span style={{marginRight: 15, marginLeft: 5, fontWeight: 400}}>
                        {newTimezoneDateFunction(values.startDate)}
                      </span>
                    </InformationCardProfileAvailabilityHeaderText>
                    <InformationCardProfileAvailabilityHeaderText
                      style={{marginTop: '0px', marginBottom: '0px'}}
                      aria-label="End Date">
                      End Date:
                      <span style={{marginLeft: 5, fontWeight: 400}}>
                        {values?.endDate ? newTimezoneDateFunction(values.endDate) : ''}
                      </span>
                    </InformationCardProfileAvailabilityHeaderText>
                  </FlexWithoutPaddingContainer>

                  <AvailabilityContainerSimple
                    style={{marginTop: '0px', marginBottom: '0px'}}
                    aria-label="Available">
                    Available
                  </AvailabilityContainerSimple>
                </FlexSpaceBetweenContainer>
              </InformationCardDetailsContainer>
              <DayBubbleContainer>
                <FlexColumnContainer style={{marginBottom: '15px'}}>
                  <FlexSpaceBetweenContainer>
                    <AvailabiltyMarginBottom
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      {days.map((day: string, index: number) => {
                        return (
                          <div key={day}>
                            <MspAvailabilityDayBubble
                              availableContainer={true}
                              day={day}
                              index={index}
                              handleBubbleClick={() => {
                                /*no op*/
                              }}
                              values={values}
                            />
                          </div>
                        )
                      })}
                    </AvailabiltyMarginBottom>
                  </FlexSpaceBetweenContainer>

                  <>
                    <AvailabiltyMarginBottom>
                      <AvailabilityShift aria-label="Shift 1" shift={values.shifts[0]} />
                    </AvailabiltyMarginBottom>
                    <AvailabiltyMarginBottom>
                      <AvailabilityShift aria-label="Shift 2" shift={values.shifts[1]} />
                    </AvailabiltyMarginBottom>
                    <AvailabiltyMarginBottom>
                      <AvailabilityShift aria-label="Shift 3 " shift={values.shifts[2]} />
                    </AvailabiltyMarginBottom>
                  </>
                </FlexColumnContainer>
              </DayBubbleContainer>
            </>
          ) : (
            <InformationCardDetailsContainer>
              <FlexSpaceBetweenContainer style={{alignItems: 'center'}}>
                <span style={{fontSize: '14px', fontWeight: 400}}>Candidate is Unavailable.</span>
                <InformationCardDetailsContainer style={{display: 'flex', justifyContent: 'end'}}>
                  <div style={{height: '75px'}} />
                  <UnavailabilityContainerSimple aria-label="Unavailable">
                    <span style={{marginTop: 4}}>Unavailable</span>
                  </UnavailabilityContainerSimple>
                </InformationCardDetailsContainer>
              </FlexSpaceBetweenContainer>
            </InformationCardDetailsContainer>
          )}
        </InformationAvailabilityCardDisplayContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
