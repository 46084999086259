import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function PhoneIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='11'
			height='11'
			viewBox='0 0 11 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.1175 7.22034C9.44403 7.22034 8.78276 7.11501 8.15613 6.90793C7.84907 6.80319 7.4716 6.89927 7.28419 7.09175L6.04733 8.02544C4.61293 7.25976 3.72937 6.37648 2.97414 4.95285L3.88036 3.74822C4.1158 3.51309 4.20025 3.16962 4.09908 2.84735C3.8911 2.21742 3.78546 1.55646 3.78546 0.882705C3.78549 0.395977 3.38951 0 2.90281 0H0.882676C0.395977 0 0 0.395977 0 0.882676C0 6.46153 4.53865 11.0002 10.1175 11.0002C10.6042 11.0002 11.0002 10.6042 11.0002 10.1175V8.10299C11.0002 7.61632 10.6042 7.22034 10.1175 7.22034Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
