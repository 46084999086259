import React from 'react'
import {styled} from '@mui/material/styles'

const Body = styled('div')(({theme}) => ({
  flexGrow: 1,
  padding: theme.spacing(5),
}))

const Header = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[100],
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
}))

const Card = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(5),
  width: theme.sizes.maxWidth,
}))

interface IProps {
  header: React.ReactNode
  children: React.ReactNode
}

export default function HeaderContainer({header, children, ...rest}: IProps) {
  return (
    <Card {...rest}>
      <Header>{header}</Header>
      <Body>{children}</Body>
    </Card>
  )
}
