import Box from '@mui/material/Box'
import type {TProcessedCandidate} from '@ui/candidate-list/CandidateList'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import ClickablePrefill from '@ui/Prefill/ClickablePrefill'
import {push} from 'connected-react-router'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {Applicant} from '~/src/models/applicant'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useLazyGetApplicantListByIdsQuery} from '~/src/store/apis/applicants-api'
import {
  useAddCandidateToPrefillMutation,
  useDeclineCandidateFromPrefillMutation,
  useGetPrefillQuery,
  useGetPrefillsQuery,
  useRemoveCandidateFromPrefillMutation,
  useSkipCandidateFromPrefillMutation,
} from '~/src/store/apis/prefill-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppSelector} from '~/src/store/store-hooks'
import {colors} from '../../../config/theme'
import BucketList, {useBucket} from '../bucket/BucketList'
import CandidatesContainer from '../candidates/CandidatesContainer'

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
    margin: auto;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

const TitleContainer = styled(Box)`
  display: flex
  flex-direction: row;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
`

const JobOrderNumberContainer = styled('div')`
  font-size: 24px
  font-weight: 300;
  display: flex;
  margin: auto
  max-width: 1111px;
  width: 1111px;
  flex-irection: row;
`

const JobOrderCardContainer = styled('div')`
  display: flex;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
  flex-direction: row;
  justify-content: space-between;
`

export const CompanyDetailsContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const PositionBreakdownContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

interface UrlParams {
  prefillCardId: string
}

export default function PrefillMain() {
  const params: UrlParams = useParams()
  const prefillId = params.prefillCardId
  const showDetails = new URLSearchParams(location.search).get('showDetails')
  const {currentUser} = useCurrentUser()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: agencyData} = useGetOneAgencyQuery(loggedInAgencyId)
  const [isAddConfirmationDialogOpen, setIsAddConfirmationDialogOpen] = useState(false)
  const [showCandidateDetails, setShowCandidateDetails] = useState(showDetails !== 'false')
  const [addedCandidates, setAddedCandidates] = useState<Applicant[] | []>([])
  const [declineCandidate] = useDeclineCandidateFromPrefillMutation()
  const [skipCandidate] = useSkipCandidateFromPrefillMutation()
  const [addCandidate] = useAddCandidateToPrefillMutation()
  const [removeCandidate] = useRemoveCandidateFromPrefillMutation()
  const [getApplicantsFromIds, {error}] = useLazyGetApplicantListByIdsQuery()
  const dispatch = useDispatch()
  const [newIsLoading, setNewIsLoading] = useState(false)

  const {
    data: prefillData,
    refetch: prefillRefetch,
    isLoading,
    isSuccess,
  } = useGetPrefillQuery({id: prefillId, agencyId: loggedInAgencyId})

  const {data: allPrefills} = useGetPrefillsQuery(loggedInAgencyId)

  const {
    redCheck,
    setRedCheck,
    greenCheck,
    setGreenCheck,
    yellowCheck,
    setYellowCheck,
    noFlagCheck,
    setNoFlagCheck,
    selectedDistance,
    setSelectedDistance,
    previouslyWorkedAtText,
    setPreviouslyWorkedAtText,
    previouslyWorkedAtFilters,
    setPreviouslyWorkedAtFilters,
    previouslyWorkedAtValue,
    setPreviouslyWorkedAtValue,
    skillsText,
    setSkillsText,
    skillsFilters,
    setSkillsFilters,
    skillsValue,
    setSkillsValue,
    setFiltersToPass,
    allFiltersToPass,
    setAllFiltersToPass,
    init,
  } = useBucket()

  const resetFilters = useCallback(() => {
    if (prefillData) {
      init({
        selectedDistance: prefillData.milesFromWorksite,
        previouslyWorkedAtFilters: prefillData.clientsPreviouslyWorkedAt,
        skills: prefillData.skills,
        filterByFlags: prefillData.filterByFlags,
      })
    }
  }, [init, prefillData])

  const handleSummaryToggle = (value: boolean) => {
    setShowCandidateDetails(value)
  }

  const handleAddCandidate = async (candidateStatus: string, candidate: TProcessedCandidate) => {
    await addCandidate({
      candidateId: candidate.candidate.id,
      prefillId,
      candidateStatus,
      notes: `Placed on Pre-Fill: ${prefillData?.title} by ${currentUser?.firstName} ${currentUser?.lastName} - ${prefillData?.companyName}`,
      agencyId: loggedInAgencyId,
    }).unwrap()
    setIsAddConfirmationDialogOpen(true)
    void prefillRefetch()
  }

  const handleDeclineCandidate = async (candidateId: string, notes: string) => {
    await declineCandidate({candidateId, prefillId, notes, agencyId: loggedInAgencyId}).unwrap()
    void (await prefillRefetch())
  }

  const handleSkipCandidate = async (notes: string, candidateId: string) => {
    await skipCandidate({candidateId, prefillId, notes, agencyId: loggedInAgencyId}).unwrap()
    void (await prefillRefetch())
  }

  const handleRemoveCandidate = async (candidateId: string) => {
    if (prefillData) {
      await removeCandidate({
        candidateId,
        prefillId: prefillId,
        agencyId: loggedInAgencyId,
      }).unwrap()
      void prefillRefetch()
    }
  }

  const excludedIds = prefillData?.candidateList.map((candidate) => candidate.candidateId)

  const handleCloseAddCandidateConfirmationDialog = () => {
    setIsAddConfirmationDialogOpen(false)
  }

  useEffect(() => {
    const getAddedCandidates = async () => {
      if (prefillData?.candidateList.length) {
        const addedIds: string[] = []
        prefillData.candidateList.forEach((candidate) => {
          if (candidate.candidateStatus !== 'Skipped' && candidate.candidateStatus !== 'Declined') {
            addedIds.push(candidate.candidateId)
          }
        })

        const added = await getApplicantsFromIds({
          applicantIds: addedIds,
          agencyId: loggedInAgencyId,
        }).unwrap()

        setAddedCandidates(added)
      }
    }
    void getAddedCandidates()
  }, [getApplicantsFromIds, loggedInAgencyId, prefillData])

  useEffect(() => {
    if (prefillData) {
      init({
        selectedDistance: prefillData.milesFromWorksite,
        previouslyWorkedAtFilters: prefillData.clientsPreviouslyWorkedAt,
        skills: prefillData.skills,
        filterByFlags: prefillData.filterByFlags,
      })
    }
  }, [init, prefillData])

  const handleAddToJobOrder = () => {
    dispatch(push(`/agency-admin/manage-job-orders?prefillId=${prefillId}`))
  }

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setNewIsLoading(false)
      }, 1000)
    }

    void fetchData()
  }, [newIsLoading])

  return (
    <>
      <LoadingContext.Provider
        value={{setNewIsLoading: setNewIsLoading, newIsLoading: newIsLoading}}>
        {newIsLoading ? (
          <LoadingBoundary isLoading={newIsLoading} />
        ) : (
          <>
            <BasicSuccessDialog
              isOpen={isAddConfirmationDialogOpen}
              header="Candidate Successfully Added"
              bigBlurb="Candidate Successfully Added"
              smallBlurb="Candidate was successfully added to the Prefill!"
              confirmButtonLabel="OK"
              handleSubmit={handleCloseAddCandidateConfirmationDialog}
            />
            <AgencyOrderDetailsStyles.Container>
              <TitleContainer>
                <JobOrderNumberContainer>
                  <h2 style={{marginBottom: 20}}>Prefill</h2>
                </JobOrderNumberContainer>
              </TitleContainer>
              <ErrorBoundary>
                <Box>
                  <JobOrderCardContainer>
                    {agencyData ? (
                      <ClickablePrefill
                        count={3}
                        isMsp={agencyData.isMsp}
                        allPrefills={allPrefills ?? []}
                        prefillId={prefillId}
                        onToggleInventory={handleSummaryToggle}
                        showList={showDetails === 'false'}
                      />
                    ) : (
                      <div />
                    )}
                  </JobOrderCardContainer>
                  {showCandidateDetails && (
                    <BucketList
                      selectedDistance={selectedDistance}
                      setSelectedDistance={setSelectedDistance}
                      previouslyWorkedAtFilters={previouslyWorkedAtFilters}
                      setPreviouslyWorkedAtFilters={setPreviouslyWorkedAtFilters}
                      containerSx={{marginTop: '25px'}}
                      resetFilters={resetFilters}
                      setFiltersToPass={setFiltersToPass}
                      previouslyWorkedAtText={previouslyWorkedAtText}
                      setPreviouslyWorkedAtText={setPreviouslyWorkedAtText}
                      previouslyWorkedAtValue={previouslyWorkedAtValue}
                      setPreviouslyWorkedAtValue={setPreviouslyWorkedAtValue}
                      skillsText={skillsText}
                      setSkillsText={setSkillsText}
                      skillsFilters={skillsFilters}
                      setSkillsFilters={setSkillsFilters}
                      skillsValue={skillsValue}
                      setSkillsValue={setSkillsValue}
                      redCheck={redCheck}
                      setRedCheck={setRedCheck}
                      greenCheck={greenCheck}
                      setGreenCheck={setGreenCheck}
                      yellowCheck={yellowCheck}
                      setYellowCheck={setYellowCheck}
                      noFlagCheck={noFlagCheck}
                      setNoFlagCheck={setNoFlagCheck}
                    />
                  )}
                  <CandidatesContainer
                    prefillData={prefillData}
                    showCandidateDetails={showCandidateDetails}
                    excludedCandidateIds={excludedIds}
                    handleAddCandidate={handleAddCandidate}
                    handleDeclineCandidate={handleDeclineCandidate}
                    handleSkipCandidate={handleSkipCandidate}
                    handleRemoveCandidate={handleRemoveCandidate}
                    handleFillJobOrder={handleAddToJobOrder}
                    addedCandidates={addedCandidates}
                    isCapped={
                      prefillData
                        ? prefillData.filledQuantity >= prefillData.requestedQuantity
                        : false
                    }
                    bucketFilters={allFiltersToPass}
                    isCleanupDisabled
                    isCandidatesOnly
                    isPrefill
                  />
                </Box>
              </ErrorBoundary>
            </AgencyOrderDetailsStyles.Container>
          </>
        )}
      </LoadingContext.Provider>
    </>
  )
}
