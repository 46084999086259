import {Button, Divider, Grid} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import Mui from '@ui/mui'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {push} from 'connected-react-router'
import {Form as FormikForm, Formik} from 'formik'
import React, {useCallback, useMemo} from 'react'
import * as Yup from 'yup'
import DowntownBg from '~/src/assets/images/downtown-bg.jpeg'
import DowntownPa from '~/src/assets/images/Paris.jpg'
import {useLazyGetUserByEmailQuery} from '~/src/store/apis/user-api'
import {createCompensationCodeItem} from '../../components/platform/onboarding/AddCompanyForm'
import AddressAutoComplete from '../../components/platform/onboarding/AddressAutoComplete'
import createAddressItem from '../../components/platform/onboarding/helpers/createAddress'
import type {IAddress, ICompanyBase, ICompensationCode} from '../../models'
import type {IBaseAddress} from '../../models/baseAddress'
import {BASE_ROUTES} from '../../routes'
import {useCreateCompanyMutation} from '../../store/apis/company-api'
import {useAppDispatch} from '../../store/store-hooks'
import CompanyAddressSignInList from '../custom-signin-address-fields/CompanyAddressSignInList'

export type TActivePlatform = 'Company' | 'Agency'

const Outer = styled('div')`
  width: 100%;
  height: 100vh;
  flex: 1;
  overflow: hidden;
`
const Background = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownBg}) center 40% no-repeat;
  background-size: 135%;
`

const BackgroundPa = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownPa}) center 40% no-repeat;
  background-size: 135%;
`

const MaxWidth = styled('div')(
  ({theme}) => css`
    overflow-y: auto;
    left: 50%;
    max-width: 90%;
    max-height: 90%;
    padding: 30px 9.866%;
    position: relative;
    top: 50%;

    transform: translate(-50%, -50%);

    ${theme.breakpoints.up('lg')} {
      padding: 0;
    }
  `,
)

const Header = styled('div')(
  ({theme}) => css`
    width: 100%;
    border-bottom: 1px solid #cdced9;
  `,
)

const HeaderText = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    margin: 25px;
    margin-left: 50px;
    line-height: 32px;
    font-weight: 700;
  `,
)

const SubHeader = styled('div')(
  ({theme}) => css`
    padding: 0px 50px 50px 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  `,
)

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

export interface IFormValues {
  addresses: IAddress[]
  noEmployees: number
  billingAddress: IBaseAddress
  adminEmail: string
  adminFirstName: string
  adminLastName: string
  name: string
  compensationCodes?: ICompensationCode[]
  phoneNumber: string
  website: string
  agencyId?: string
}

interface IProps {
  activePlatform: TActivePlatform
}

export default function CreateCompany({activePlatform}: IProps) {
  const [addCompany, {error}] = useCreateCompanyMutation()
  const dispatch = useAppDispatch()

  const initialValues: IFormValues = {
    name: '',
    noEmployees: 1,
    phoneNumber: '',
    website: '',
    addresses: [createAddressItem()],
    billingAddress: {
      line1: '',
      city: '',
      state: '',
      zipCode: '',
      display: '',
    },
    adminFirstName: '',
    adminLastName: '',
    adminEmail: '',
    agencyId: '',
  }

  const handleSubmit = async (values: IFormValues) => {
    const castValues = values as unknown as Partial<ICompanyBase>
    await addCompany({
      ...castValues,
      agencyId: castValues?.agencyId?.length > 0 ? castValues.agencyId : undefined,
      departments: [],
      noEmployees: Number(values.noEmployees),
    }).unwrap()
    dispatch(push(BASE_ROUTES.REGISTRATION_SUCCESS))
  }

  const [findEmail] = useLazyGetUserByEmailQuery()

  const validateEmail = useCallback(
    async function (value: string | undefined, resolve: (value: boolean) => void) {
      try {
        const alreadyExists = await findEmail(value ?? '').unwrap()
        resolve(!alreadyExists)
      } catch (e: unknown) {
        resolve(true)
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  return (
    <Outer>
      {process.env.FR ? <BackgroundPa /> : <Background />}
      <MaxWidth>
        <>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #CDCED9',
              borderRadius: '8px',
            }}>
            <Header>
              <HeaderText>Registration</HeaderText>
            </Header>

            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
                phoneNumber: Yup.string()
                  .required('Required')
                  .phone('US', true, 'Phone number is invalid.'),
                adminEmail: Yup.string()
                  .email('Email address is invalid.')
                  .test(
                    'already-exists',
                    'This email is already in use',
                    (value) => new Promise((resolve) => debouncedValidateEmail(value, resolve)),
                  )
                  .required('Required'),
                adminFirstName: Yup.string().required('Required'),
                adminLastName: Yup.string().required('Required'),
                website: Yup.string(),
                addresses: Yup.array().of(
                  Yup.object({
                    id: Yup.string(),
                    name: Yup.string().required('Required'),
                    isOnsite: Yup.boolean().nullable().required('Required'),
                    address: Yup.object({
                      line1: Yup.string(),
                      city: Yup.string(),
                      state: Yup.string(),
                      zipCode: Yup.string(),
                      display: Yup.string().required('Required'),
                      location: Yup.object({
                        type: Yup.string(),
                        coordinates: Yup.array().of(Yup.number())
                      }).required('Required')
                    }),
                  }),
                ),
                billingAddress: Yup.object({
                  line1: Yup.string(),
                  city: Yup.string(),
                  state: Yup.string(),
                  zipCode: Yup.string(),
                  display: Yup.string().required('Required'),
                }),
                agencyId: Yup.string().nullable(),
              })}>
              {({isSubmitting, setFieldValue, values, handleChange, errors}) => {
                return (
                  <FormikForm>
                    <Box style={{margin: 50}}>
                      <SubHeader>Company Information</SubHeader>

                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Grid item xs paddingBottom={2}>
                            Company Name<Red>*</Red>:
                          </Grid>
                          <FormikTextField
                            name="name"
                            placeholder="Default Company"
                            inputProps={undefined}
                          />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs>
                          <Grid item xs paddingBottom={2}>
                            Company Phone<Red>*</Red>:
                          </Grid>
                          <FormikTextField
                            name="phoneNumber"
                            placeholder="1 123 456 7890"
                            inputProps={undefined}
                          />
                        </Grid>
                        <Grid item xs>
                          <Grid item xs paddingBottom={2}>
                            Company Website:
                          </Grid>
                          <FormikTextField
                            name="website"
                            placeholder="company.com"
                            inputProps={undefined}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingTop={3} paddingBottom={3}>
                        <Divider />
                      </Grid>

                      {/* Section2 */}

                      <CompanyAddressSignInList
                        values={values}
                        setFieldValue={setFieldValue}
                        activePlatform={activePlatform}
                      />
                      <Grid item xs={12} paddingTop={3} paddingBottom={3}>
                        <Divider />
                      </Grid>

                      {/* Section3 */}

                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Grid item xs paddingBottom={2}>
                            Billing Address<Red>*</Red>:
                          </Grid>
                          <AddressAutoComplete
                            address={values.billingAddress}
                            setFieldValue={setFieldValue}
                            activePlatform={activePlatform}
                            name="billingAddress"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingTop={3} paddingBottom={3}>
                        <Divider />
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs>
                          <Grid item xs paddingBottom={2}>
                            Admin First Name<Red>*</Red>:
                          </Grid>
                          <FormikTextField
                            name="adminFirstName"
                            placeholder="Jill "
                            inputProps={undefined}
                          />
                        </Grid>
                        <Grid item xs>
                          <Grid item xs paddingBottom={2}>
                            Admin Last Name<Red>*</Red>:
                          </Grid>
                          <FormikTextField
                            name="adminLastName"
                            placeholder="Doe"
                            inputProps={undefined}
                          />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={6}>
                          <Grid item xs paddingBottom={2}>
                            Admin Email<Red>*</Red>:
                          </Grid>
                          <FormikTextField
                            name="adminEmail"
                            placeholder="adminEmail@gmail.com"
                            inputProps={undefined}
                          />
                        </Grid>
                      </Grid>
                      <div style={{color: 'grey', fontWeight: '700', marginTop: 25}}>
                        * The MSP will be provided by Jobsource after registration *
                      </div>

                      <Mui.Grid item xs mt={6} display="flex">
                        <Grid item xs>
                          <>
                            <Button
                              onClick={() => {
                                dispatch(push(BASE_ROUTES.SIGNIN))
                              }}
                              variant="outlined"
                              color="secondary"
                              disabled={isSubmitting}>
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              sx={{ml: 2}}>
                              Finish
                            </Button>
                          </>
                        </Grid>
                      </Mui.Grid>
                    </Box>
                  </FormikForm>
                )
              }}
            </Formik>
          </Box>
        </>
      </MaxWidth>
    </Outer>
  )
}
