import React, {useState} from 'react'
import {ReactComponent as UploadIcon} from '../../../assets/images/UploadFileSvg.svg'
import AddDocumentDialog from './Dialogs/AddDocumentDialog'
import SignedFiles from './SignedFiles'
import {
  BodyFolderContainer,
  DocumentContainer,
  DocumentContainerMain,
  HeaderContainer,
  HeaderContainerMargin,
  HeaderText,
  StyledButton,
  UploadButtonHeaderContainer,
} from './styles/documentStyles'

interface IProps {
  buttonSubmitting: boolean
  documentId: string | undefined
  selectedFolder: undefined
  setButtonSubmitting: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  setSelectedFolder: React.Dispatch<React.SetStateAction<undefined>>
  setSignedDocumentView: (value: boolean) => void
}

export default function SignedDocumentsMain({
  buttonSubmitting,
  documentId,
  selectedFolder,
  setIsSubmitting,
  setSignedDocumentView,
  setButtonSubmitting,
  setSelectedFolder,
}: IProps) {
  const [addDocumentDialog, setAddDocumentDialog] = useState(false)

  const handleOpenAddDocumentDialog = () => {
    setAddDocumentDialog(true)
  }

  const handleCancelAddDocumentDialog = () => {
    setAddDocumentDialog(false)
  }

  return (
    <DocumentContainerMain>
      <AddDocumentDialog
        buttonSubmitting={buttonSubmitting}
        documentId={documentId}
        folderList={selectedFolder}
        handleCancel={handleCancelAddDocumentDialog}
        isInFolder={true}
        isOpen={addDocumentDialog}
        setButtonSubmitting={setButtonSubmitting}
        setIsSubmitting={setIsSubmitting}
        setSelectedFolder={setSelectedFolder}
      />
      <DocumentContainer>
        <UploadButtonHeaderContainer style={{display: 'flex', alignItems: 'center'}}>
          <HeaderContainerMargin style={{margin: '0px', marginLeft: '24px'}}>
            <StyledButton
              aria-label="uploadFiles-button"
              data-testid="uploadFiles-button"
              onClick={handleOpenAddDocumentDialog}
              style={{marginRight: 30}}
              sx={{
                border: '1px solid #B3B3B3',
                fontSize: '12px',
                color: '#333',
              }}>
              <UploadIcon style={{marginRight: 5}} />
              <span style={{fontWeight: 500}}>Upload Files</span>
            </StyledButton>
          </HeaderContainerMargin>
        </UploadButtonHeaderContainer>
        <HeaderContainer>
          <HeaderContainerMargin style={{justifyContent: 'space-between'}}>
            <HeaderText>Signed Documents ({selectedFolder?.files?.length})</HeaderText>
            <HeaderText
              onClick={() => setSignedDocumentView(false)}
              style={{marginRight: 20, cursor: 'pointer'}}>
              Back
            </HeaderText>
          </HeaderContainerMargin>
        </HeaderContainer>

        <BodyFolderContainer>
          {selectedFolder?.files?.map((fileInfo, fileInfoIndex) => {
            return (
              <SignedFiles
                buttonSubmitting={buttonSubmitting}
                documentId={documentId}
                key={fileInfoIndex}
                selectedFolder={selectedFolder}
                selectedFile={fileInfo}
                folderInfoIndex={fileInfoIndex}
                setButtonSubmitting={setButtonSubmitting}
                setIsSubmitting={setIsSubmitting}
                setSelectedFolder={setSelectedFolder}
              />
            )
          })}
        </BodyFolderContainer>
      </DocumentContainer>
    </DocumentContainerMain>
  )
}
