import React from 'react'

import type {ISelect} from './typings'
import {isEmptyString} from '~/src/utils'
import Icon from '@ui/icons'
import Mui from '@ui/mui'
import Placeholder from './Placeholder'
import S from './Select.styles'
import useStyles from './Menu.styles'

export default function SelectBase({
  children,
  inputProps,
  outerProps,
  placeholder = '-- Select --',
  slots = {},
  ...rest
}: ISelect) {
  const {Select, TopLeft, TopRight, Bottom} = slots
  const classes = useStyles()

  function defaultRenderValue(selected: unknown) {
    return isEmptyString(selected) ? (
      <Placeholder text={placeholder} />
    ) : (
      (selected as React.ReactNode)
    )
  }

  function defaultIconComponent() {
    return <Icon icon="arrowhead" outerProps={{className: 'select-icon'}} />
  }

  return (
    <Mui.FormControl
      {...outerProps}
      fullWidth={rest.fullWidth}
      error={rest.error}
      variant="standard">
      <S.Flex>
        {TopLeft ?? null}
        {TopRight ?? null}
      </S.Flex>

      {Select ?? (
        <Mui.Select
          {...rest}
          inputProps={{'aria-label': 'No label', ...inputProps}}
          input={<S.OutlinedInput />}
          id={rest.id ?? rest.name}
          renderValue={rest.renderValue ?? defaultRenderValue}
          IconComponent={rest.IconComponent ?? defaultIconComponent}
          MenuProps={{classes: {paper: classes.paper}}}>
          {children}
        </Mui.Select>
      )}

      {Bottom ?? null}
    </Mui.FormControl>
  )
}

SelectBase.defaultProps = {
  fullWidth: true,
  onChange: () => {
    throw Error('onChange is not implemented')
  },
}
