import React from 'react'
import {FieldConfig, useField} from 'formik'

import Mui from '@ui/mui'
import S from './TextField.styles'
import TextFieldBase from './TextFieldBase'
import type {ITextField} from './typings'

export default function PasswordFormikField({label, ...props}: ITextField) {
  const [field, meta] = useField(props as FieldConfig)
  const [showPassword, setShowPassword] = React.useState(false)
  const inputId = props.id ?? props.name
  const errorMsg = meta.touched && meta.error ? meta.error : ''

  return (
    <TextFieldBase
      {...field}
      {...props}
      type={showPassword ? 'text' : 'password'}
      error={meta.touched && Boolean(meta.error)}
      sx={{
        '&.MuiInputBase-adornedEnd': {pr: 0},
      }}
      endAdornment={
        <Mui.IconButton
          aria-label={`${inputId}-visibility-button`}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setShowPassword(!showPassword)
          }}>
          <S.Icon icon={showPassword ? 'eyes' : 'eyes-close'} />
        </Mui.IconButton>
      }
      slots={{
        TopLeft: (
          <S.InputLabel id={`${inputId}-label`} htmlFor={inputId} focused={false}>
            {label}
          </S.InputLabel>
        ),
        TopRight: (
          <S.FormHelperText>{errorMsg}</S.FormHelperText>
        ),
      }}
    />
  )
}
