import { styled } from '@mui/material/styles'
import LoadingBoundary from '@ui/loader'
import { Form as FormikForm, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import type { Applicant } from '~/src/models/applicant'
import { useAppSelector } from '~/src/store/store-hooks'
import CandidateAgreementsDisplay from '../candidate-details/candidate-agreements/CandidateAgreementsDisplay'
import JobOrderCandidateNameHeader from './JobOrderCandidateNameHeader'

const Container = styled('div')`
  width: 764px;
`
interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
}

export default function JobOrderAgreements({
  handleSubmit,
  oldApplicant,
  candidateLoadingStatuses,
  refetchCandidates,
}: IProps) {
  const {currentUser} = useAppSelector((state) => state.root)

  const createAgreementItem = () =>
    oldApplicant &&
    currentUser && {
      wOTCConfirmationNumber: oldApplicant.agreementsStatus?.wOTCConfirmationNumber ?? 'NA',
      wOTCScreening: oldApplicant.agreementsStatus?.wOTCScreening ?? false,
      iIPP: oldApplicant.agreementsStatus?.iIPP ?? false,
      employeeHandbook: oldApplicant.agreementsStatus?.employeeHandbook ?? false,
      sexualHarassment: oldApplicant.agreementsStatus?.sexualHarassment ?? false,
      antiHarassmentPolicy: oldApplicant.agreementsStatus?.antiHarassmentPolicy ?? false,
      covidSafety: oldApplicant.agreementsStatus?.covidSafety ?? false,
      safeWorkersPromise: oldApplicant.agreementsStatus?.safeWorkersPromise ?? false,
      arbitrationPolicy: oldApplicant.agreementsStatus?.arbitrationPolicy ?? false,
    }

  const initialFormValues: Applicant = oldApplicant && {
    //anything else we may need that i missed
    ...oldApplicant,

    agreementsStatus: createAgreementItem(),
  }
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          agreements: Yup.string(),
        })}>
        {({isSubmitting, setFieldValue, values, resetForm, errors}) => {
          return (
            <LoadingBoundary isLoading={false}>
              <FormikForm>
                <Container>
                  <JobOrderCandidateNameHeader
                    candidateLoadingStatuses={candidateLoadingStatuses}
                    refetchCandidates={refetchCandidates}
                    isAssignmentHistory={true}
                    oldApplicant={oldApplicant}
                    singleApplicant={values}
                  />

                  <CandidateAgreementsDisplay
                    errors={errors}
                    handleSubmit={handleSubmit}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    oldApplicant={oldApplicant}
                    refetchCandidates={refetchCandidates}
                    values={values}
                  />
                </Container>
              </FormikForm>
            </LoadingBoundary>
          )
        }}
      </Formik>
    </>
  )
}
