import React, { useEffect, useState } from 'react'
import type { Availability } from '~/src/models/applicant'
import {
  DashboardMainStyles,
  DayBubbleContainer,
  DayBubbleText,
  FlexSpaceBetweenContainer
} from './MspAvailabilityDayBubbleStyles.styles'

interface IProps {
  availableContainer: boolean
  day: string

  handleBubbleClick(day: string): void
  index: number
  values: Availability
}
export default function MspAvailabilityDayBubble({
  availableContainer,
  day,
  index,
  values,
  handleBubbleClick,
}: IProps) {
  const [isAvailable, setIsAvailable] = useState(true)
  const shift1 = values.shifts[0]?.shiftAvailability[index]
  const shift2 = values.shifts[1]?.shiftAvailability[index]
  const shift3 = values.shifts[2]?.shiftAvailability[index]

  useEffect(() => {
    if (availableContainer) {
      if (!shift1 && !shift2 && !shift3) {
        return setIsAvailable(false)
      } else {
        return setIsAvailable(true)
      }
    }
  }, [availableContainer, shift1, shift2, shift3])

  return (
    <>
      <DashboardMainStyles>
        <FlexSpaceBetweenContainer>
          <DayBubbleContainer onClick={() => handleBubbleClick(day)} isAvailable={isAvailable}>
            <DayBubbleText>{day}</DayBubbleText>
          </DayBubbleContainer>
        </FlexSpaceBetweenContainer>
      </DashboardMainStyles>
    </>
  )
}
