import {selectClasses} from '@mui/material';
import {css, styled} from '@mui/material/styles'
import Mui from '@ui/mui'

import {ICON_WIDTH, MARGIN} from './constants'

const InputLabel = styled(Mui.InputLabel)`
  color: ${({theme}) => theme.palette.text.primary};
  max-width: initial;
  padding-right: 1.25rem;
  position: relative;
  text-align: left;
`

const FormHelperText = styled(Mui.FormHelperText)`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Flex = styled('div')`
  display: flex;
  height: 1rem;
  line-height: 1rem;
  position: absolute;
  top: -1.2rem;
  width: 100%;
`

const Placeholder = styled(Mui.Typography)`
  line-height: initial;
  opacity: 0.42;
  transition: ${({theme}) => theme.transitions.create('opacity')};
`

const OutlinedInput = styled(Mui.OutlinedInput)(
  ({theme}) => css`
    padding-left: 0;
    position: relative;
    transition: ${theme.transitions.create(['all'])};

    & .select-icon {
      color: ${theme.palette.text.secondary};
      position: absolute;
      right: ${MARGIN}px;
      width: ${ICON_WIDTH}px;
    }

    & .${selectClasses.select} {
      padding: 10px;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;

      &[aria-expanded='true'] ~ .select-icon {
        transform: rotate(180deg);
      }
    }
  `,
)

export default {
  Flex,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Placeholder,
}
