import {Box, Button, Typography} from '@mui/material'
import styled from 'styled-components'

interface CallButtonProps {
  $isCallActive: boolean
}

export const Header = styled(Box)`
  display: flex;
  background: #424242;
  justify-content: flex-end;
  border-radius: 15px 15px 0 0;
  padding: 9px;
  align-items: center;
`
export const HeaderText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-right: 20px;
  margin-left: 5px;
`

export const CallIconWrapper = styled(Button)`
  background-color: ${(props: CallButtonProps) => (props.$isCallActive ? 'red' : '#3db429')};
  color: white;
  height: 35px;
  width: 45px;
  border-radius: 9px;

  &:hover {
    background-color: ${(props: CallButtonProps) => (props.$isCallActive ? '#8B0000' : 'green')};
  }
`

export const ActionsBox = styled(Box)`
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  border-radius: 9px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 33.33%;
  margin: 0 5px;
`

export const StyledSkipButton = styled(Button)`
  border-radius: 9px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 50%;
  margin: 0 5px;
`

export const Name = styled('div')`
  width: 150px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 700;
  font-size: 18px;
`
