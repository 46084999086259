import styled from 'styled-components'
import Icon from '@ui/icons'
import {colors} from '~/src/config/theme'

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const JobSourceLogo = styled(Icon).attrs(() => ({icon: 'brand-logo'}))`
  color: ${colors.primary};
  height: 24px !important;
  width: 24px !important;
  justify-self: center;
  margin-right: 14px;
`

export const LogoText = styled(Icon).attrs(() => ({icon: 'brand-text'}))`
  height: 13px;
  margin-left: 3px;
  width: 84px;
`
