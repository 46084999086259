import {Typography} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import {Box} from '@mui/system'
import styled, {css} from 'styled-components'

interface StyleProps {
  $currentCandidate: boolean
}

export const StyledListItem = styled(ListItem)(
  ({theme}) => css`
    margin-bottom: 11px;
    background-color: ${(props: StyleProps) => (props.$currentCandidate ? '#424242' : '#ffffff')};
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    border-radius: 8px;
  `,
)

export const CandidateNameText = styled(Typography)(
  ({theme}) => css`
    color: ${(props: StyleProps) => (props.$currentCandidate ? '#FFF' : '#424242')};
  `,
)

export const ListItemContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
`
