import React from 'react'
import {FieldConfig, useField} from 'formik'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {IconButton, InputAdornment} from '@mui/material'
import StyledTextField from './StyledTextField'

interface IPasswordFormikTextField {
name: string
label: string
type: string
InputLabelProps?: object
}

export default function PasswordFormikTextField({
  ...props
}: IPasswordFormikTextField ) {
  const [field, meta] = useField(props as FieldConfig)

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const configTextField = {
    ...field,
    ...props,
    fullWidth: true,
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <StyledTextField
      {...configTextField}
      type={values.showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {values.showPassword && <Visibility />}
              {!values.showPassword && <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
