import {Box, BoxProps} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {FormikErrors} from 'formik'
import React, {PropsWithChildren} from 'react'
import {ReactComponent as Checked} from '~/src/assets/images/checked.svg'
import {ReactComponent as Close} from '~/src/assets/images/close.svg'
import type {Applicant} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

const FlexSpaceBetweenContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SemiBold = styled('div')`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`

const NotBoldBlack = styled('div')`
  font-size: 14px;
  font-weight: 400;
`

const NotBoldGrey = styled('span')`
  font-size: 14px;
  font-weight: 400;
  color: #8a8a8a;
`

const Disclaimer = styled('div')`
  font-size: 13px;
  font-weight: 400;
  color: #424242;
`

const AgreementStatus = ({isChecked, children, sx, ...rest}: PropsWithChildren<BoxProps & {isChecked: boolean}>) => {
  return (
    <Box {...rest} sx={{display: 'flex', flexDirection: 'row', ...sx}}>
      {isChecked ? <Checked /> : <Close />}{' '}
      <NotBoldBlack style={{marginLeft: '10px'}}>{children}</NotBoldBlack>
    </Box>
  )
}

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  resetForm(obj: Applicant): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  oldApplicant: Applicant
  values: Applicant
  refetchCandidates?: () => void
}

export default function CandidateAgreementsDisplay({
  errors,
  handleSubmit,
  resetForm,
  setFieldValue,
  values,
  refetchCandidates = () => {},
}: IProps) {
  const agreements = values.agreementsStatus

  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Agreements List</InformationCardHeaderTextTitle>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            <FlexSpaceBetweenContainer>
              <SemiBold style={{marginTop: '30px'}}>Working Opportunity Tax Credit</SemiBold>
              <NotBoldBlack>
                WOTC Screening - {agreements?.wOTCScreening ? 'Complete' : 'NA'}
              </NotBoldBlack>
              <NotBoldBlack>
                WOTC Confirmation Number -{' '}
                <NotBoldGrey>{agreements?.wOTCConfirmationNumber}</NotBoldGrey>
              </NotBoldBlack>
              <SemiBold style={{marginTop: '30px'}}>Agreements</SemiBold>
              <Disclaimer style={{marginBottom: '25px'}}>
                Disclaimer (This is just a checklist of agreements, to reference the actual
                documents, please access Olliboard directly.)
              </Disclaimer>
              <AgreementStatus isChecked={agreements?.iIPP ?? false}>IIPP</AgreementStatus>
              <AgreementStatus isChecked={agreements?.employeeHandbook ?? false}>
                Employee Handbook Agreement
              </AgreementStatus>
              <AgreementStatus isChecked={agreements?.sexualHarassment ?? false}>
                Sexual Harassment Agreement
              </AgreementStatus>
              <AgreementStatus isChecked={agreements?.antiHarassmentPolicy ?? false}>
                Anti-Harassment Policy
              </AgreementStatus>
              <AgreementStatus isChecked={agreements?.covidSafety ?? false}>
                Covid Safety Agreement
              </AgreementStatus>
              <AgreementStatus isChecked={agreements?.safeWorkersPromise ?? false}>
                Safe Worker’s Promise Agreement
              </AgreementStatus>
              <AgreementStatus isChecked={agreements?.arbitrationPolicy ?? false} sx={{marginBottom: '30px'}}>
                Arbitration Policy Agreement{' '}
              </AgreementStatus>
            </FlexSpaceBetweenContainer>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
