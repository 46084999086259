import {createApi} from '@reduxjs/toolkit/query/react';
import type {
	TReqTemplatesPayload,
	TPagedReqTemplatesPayload,
	IReqTemplateCreate,
	ISuccessMessage,
} from '../../models';
import {baseQuery} from '../base-query';

export const reqTemplatesApi = createApi({
	reducerPath: 'reqTemplateApi',
	baseQuery: baseQuery,
	tagTypes: ['ReqTemplate'],
	endpoints: (builder) => ({
		getReqTemplates: builder.query<TReqTemplatesPayload, TPagedReqTemplatesPayload>({
			providesTags: ['ReqTemplate'],
			query: ({pageNumber, pageSize}) => ({
				url: `/ReqTemplate`,
				method: 'GET',
				params: {pageNumber, pageSize},
			}),
		}),
		createReqTemplate: builder.mutation<ISuccessMessage, IReqTemplateCreate>({
			invalidatesTags: ['ReqTemplate'],
			query: (body) => ({
				url: `/ReqTemplate`,
				method: 'POST',
				body: body,
			}),
		}),
	}),
});

export const {
	useGetReqTemplatesQuery,
	useCreateReqTemplateMutation,
} = reqTemplatesApi;

export default reqTemplatesApi.reducer;
