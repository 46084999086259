import {Tooltip} from '@mui/material'
import LoadingBoundary from '@ui/loader'
import {formatShift} from '@utils'
import React, {useEffect} from 'react'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {FlexSpaceBetweenContainer} from '../../msp/manage-job-orders/job-order-summary/job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import CalendarDayGraphic from './CalendarDayGraphic'
import {
  DashboardMainStyles,
  FooterCompanyContainer,
  FooterCompanyDescriptionText,
  FooterCompanyHeaderText,
  HeaderContainer,
  HeaderMarginFlexContainer,
  JobOrderCardCompanyContainer,
  JobOrderText,
  PositionNameAndJOCompanyContainer,
  PositionText,
  ShiftCompanyText,
} from './JobOrderCardStyles.styles'
import {FlexColumnContainer} from './MspCompanyDetailsSummaryCard'

interface IProps {
  currentlyfilled: number
  distributionDate: Date
  handleClick(indexOne: number): void
  index: number
  isDetails: boolean
  jobOrderNumber: string
  position: string
  targetJobOrder: number
  totalrequested: number
}

export default function JobOrderCompanyCard({
  currentlyfilled,
  distributionDate,
  handleClick,
  jobOrderNumber,
  index,
  position,
  targetJobOrder,
  totalrequested,
}: IProps) {
  const {
    data: queriedPositionData,
    isLoading,
    refetch: positionRefetch,
  } = useGetPositionByIdQuery({
    id: position ? position : '',
  })

  const progress = totalrequested ? Math.floor((currentlyfilled / totalrequested) * 100) : undefined

  useEffect(() => {
    positionRefetch()
  }, [positionRefetch])

  return (
    <LoadingBoundary isLoading={isLoading}>
      <DashboardMainStyles.Container>
        <JobOrderCardCompanyContainer
          style={{background: targetJobOrder === index ? '#03A9FC' : 'white'}}
          onClick={() => handleClick(index)}>
          <HeaderContainer style={{borderBottom: 'none'}}>
            <FlexSpaceBetweenContainer>
              <div>
                <HeaderMarginFlexContainer>
                  <PositionNameAndJOCompanyContainer
                    style={{color: targetJobOrder === index ? 'white' : 'black'}}>
                    <Tooltip title={`${queriedPositionData?.name}`}>
                      <PositionText>{`${queriedPositionData?.name}`}</PositionText>
                    </Tooltip>
                    <div>
                      <JobOrderText>{`Order #${jobOrderNumber}`}</JobOrderText>
                    </div>
                  </PositionNameAndJOCompanyContainer>
                  <ShiftCompanyText style={{color: targetJobOrder === index ? 'white' : 'black'}}>
                    {formatShift(queriedPositionData)}
                  </ShiftCompanyText>
                </HeaderMarginFlexContainer>
              </div>
              <div>
                {targetJobOrder === index ? (
                  <CalendarDayGraphic
                    date={new Date(distributionDate)}
                    colorSelected="white"
                    colorUnselected="white"
                    boxProps={{color: ' white'}}
                  />
                ) : (
                  <CalendarDayGraphic
                    date={new Date(distributionDate)}
                    colorSelected="black"
                    colorUnselected="black"
                    boxProps={{color: 'black'}}
                  />
                )}
              </div>
            </FlexSpaceBetweenContainer>
          </HeaderContainer>

          <div
            style={{
              marginTop: 5,
              border: targetJobOrder === index ? '1px solid white' : '1px solid black',
              width: '100%',
            }}
          />

          <FooterCompanyContainer>
            <FlexColumnContainer style={{marginRight: '42px'}}>
              <FooterCompanyHeaderText
                style={{color: targetJobOrder === index ? 'white' : 'black'}}>
                Overall Fill
              </FooterCompanyHeaderText>
              <FooterCompanyDescriptionText
                style={{
                  color: targetJobOrder === index ? 'white' : 'black',
                }}>{`${currentlyfilled}/${totalrequested}`}</FooterCompanyDescriptionText>
            </FlexColumnContainer>
            <FlexColumnContainer>
              <FooterCompanyHeaderText
                style={{color: targetJobOrder === index ? 'white' : 'black'}}>
                Fill Rate
              </FooterCompanyHeaderText>
              <FooterCompanyDescriptionText
                style={{
                  color: targetJobOrder === index ? 'white' : 'black',
                }}>{`${progress}%`}</FooterCompanyDescriptionText>
            </FlexColumnContainer>
          </FooterCompanyContainer>
        </JobOrderCardCompanyContainer>
      </DashboardMainStyles.Container>
    </LoadingBoundary>
  )
}
