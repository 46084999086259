import {createApi} from '@reduxjs/toolkit/query/react'
import type {ISuccessMessage} from '~/src/models'
import type {
  Activity,
  Applicant,
  ApplicantInterview,
  ApplicantsRequest,
  ApplicantsResponse,
  CreateApplicantInterviewRequestBody,
  ExclusionListRequest,
  UpdateApplicantInterviewRequestBody,
} from '../../models/applicant'
import {baseQuery} from '../base-query'

export const applicantsApi = createApi({
  reducerPath: 'ApplicantsApi',
  tagTypes: ['Applicants'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getApplicants: builder.query<ApplicantsResponse, ApplicantsRequest>({
      providesTags: ['Applicants'],
      query: ({clientsApplicantWorkedFor, ...rest}) => ({
        url: `/applicants/getApplicants`,
        method: 'POST',
        body: {
          clientsApplicantWorkedFor:
            clientsApplicantWorkedFor?.length === 0 ? undefined : clientsApplicantWorkedFor,
          ...rest,
        },
      }),
      keepUnusedDataFor: 1,
    }),

    getApplicant: builder.query<Applicant, {id: string; agencyId?: string}>({
      providesTags: ['Applicants'],
      query: ({id, agencyId}) => ({
        url: `/applicants/getApplicantById/${id}`,
        params: {agencyId},
        method: 'GET',
      }),
    }),

    updateApplicant: builder.mutation<Applicant, {applicant: Applicant}>({
      invalidatesTags: ['Applicants'],
      query: ({applicant}) => ({
        url: `/applicants/updateApplicant`,
        method: 'PUT',
        body: applicant,
      }),
    }),

    lockApplicant: builder.query<
      {id: string; clientId: string; status: string; date: Date},
      {id: string; clientId: string}
    >({
      providesTags: ['Applicants'],
      query: (params) => ({
        url: `/applicants/applicantLock`,
        method: 'GET',
        params: params,
      }),
    }),

    unLockApplicant: builder.query<
      {id: string; clientId: string; status: string; date: Date},
      {id: string; clientId: string}
    >({
      providesTags: ['Applicants'],
      query: (params) => ({
        url: `/applicants/applicantUnlock`,
        method: 'GET',
        params: params,
      }),
    }),

    addApplicantsActivity: builder.mutation<
      ISuccessMessage,
      {activity: Activity; agencyId?: string}
    >({
      invalidatesTags: ['Applicants'],
      query: ({activity, agencyId}) => ({
        url: `/applicants/addApplicantsActivity`,
        method: 'POST',
        body: activity,
        params: {agencyId},
      }),
    }),

    getApplicantsExclusionList: builder.query<Partial<Applicant>[], ExclusionListRequest>({
      providesTags: ['Applicants'],
      query: ({pageNumber, pageSize, applicantIds, agencyId}) => ({
        url: '/applicants/getApplicantExclusionList',
        method: 'POST',
        body: {
          pageNumber,
          pageSize,
          applicantIds,
        },
        params: {agencyId},
      }),
    }),

    getApplicantListByIds: builder.query<Applicant[], {applicantIds: string[]; agencyId?: string}>({
      providesTags: ['Applicants'],
      query: ({applicantIds, agencyId}) => ({
        url: '/applicants/getApplicantListByIds',
        method: 'POST',
        body: applicantIds,
        params: {agencyId},
      }),
    }),

    moveApplicantToNewAgency: builder.mutation<
      Applicant,
      {applicantId: string; newAgencyId: string; loggedInAgencyId?: string}
    >({
      invalidatesTags: ['Applicants'],
      query: ({applicantId, newAgencyId, loggedInAgencyId}) => ({
        url: '/applicants/moveApplicantToNewAgency',
        method: 'POST',
        params: {applicantId, agencyId: loggedInAgencyId},
        body: {newAgencyId},
      }),
    }),

    getApplicantInterview: builder.query<ApplicantInterview, string>({
      providesTags: ['Applicants'],
      query: (agencyId) => ({
        url: `/applicants/getApplicantInterview`,
        method: 'GET',
        params: {agencyId},
      }),
    }),

    createApplicantInterview: builder.mutation<
      ApplicantInterview,
      {agencyId?: string; body: CreateApplicantInterviewRequestBody}
    >({
      invalidatesTags: ['Applicants'],
      query: ({agencyId, body}) => ({
        url: `/applicants/createApplicantInterview`,
        method: 'POST',
        params: {agencyId},
        body: body,
      }),
    }),

    updateApplicantInterview: builder.mutation<
      ApplicantInterview,
      {agencyId?: string; body: UpdateApplicantInterviewRequestBody}
    >({
      invalidatesTags: ['Applicants'],
      query: ({agencyId, body}) => ({
        url: `/applicants/updateApplicantInterview`,
        method: 'PUT',
        params: {agencyId},
        body: body,
      }),
    }),

    uploadApplicantProfilePicture: builder.mutation<
      ApplicantInterview,
      {applicantId: string; body: File; agencyId?: string}
    >({
      invalidatesTags: ['Applicants'],
      query: ({applicantId, body, agencyId}) => {
        const formData = new FormData()
        formData.append('file', body)
        return {
          url: `/applicants/uploadProfilePicture/${applicantId}`,
          method: 'PUT',
          params: {applicantId, agencyId},
          body: formData,
          formData: true,
        }
      },
    }),

    deleteApplicantProfilePicture: builder.mutation<
      ApplicantInterview,
      {applicantId: string; agencyId?: string}
    >({
      invalidatesTags: ['Applicants'],
      query: ({applicantId, agencyId}) => {
        return {
          url: `/applicants/deleteProfilePicture`,
          method: 'PUT',
          params: {applicantId, agencyId},
        }
      },
    }),

    updateApplicantFlag: builder.mutation<
      {applicantId: string; flag: string; agencyId?: string},
      {applicantId: string; flag: string; agencyId?: string}
    >({
      invalidatesTags: ['Applicants'],
      query: ({applicantId, flag, agencyId}) => ({
        url: `/applicants/updateApplicantFlag`,
        method: 'PUT',
        params: {applicantId, flag, agencyId},
        body: {},
      }),
    }),
  }),
})

export const {
  useLazyGetApplicantsQuery,
  useLazyGetApplicantQuery,
  useUpdateApplicantMutation,
  useAddApplicantsActivityMutation,
  useGetApplicantsExclusionListQuery,
  useLazyGetApplicantListByIdsQuery,
  useMoveApplicantToNewAgencyMutation,
  useLazyLockApplicantQuery,
  useLazyUnLockApplicantQuery,
  useGetApplicantInterviewQuery,
  useCreateApplicantInterviewMutation,
  useUpdateApplicantInterviewMutation,
  useUploadApplicantProfilePictureMutation,
  useDeleteApplicantProfilePictureMutation,
  useUpdateApplicantFlagMutation,
} = applicantsApi
