import styled from 'styled-components'
import {colors, font, resets} from '~/src/config/theme'

export const TableInfo = {
  Container: styled.div`
    position: relative;
    flex: 1;
    min-width: 900px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  HeaderLeft: styled.div`
    column-gap: 28px;
    display: flex;
    font-size: ${font.sizes.small};
  `,
  HeaderRight: styled.div`
    column-gap: 15px;
    display: flex;
    font-size: ${font.sizes.smaller};
  `,
  IconButton: styled.button`
    ${resets.common}
    align-items: center;
    column-gap: 5px;
    display: flex;
    font-size: inherit;
    margin-right: 4%;

    svg {
      background-color: ${colors.primary};
      border-radius: 50%;
      color: ${colors.white};
      height: 15px;
      padding: 4px;
      width: 15px;
    }
  `,
  BlueText: styled.a`
    color: ${colors.primary};
    font-weight: 700;
  `,
  Body: styled.div`
    margin-top: 40px;
  `,
  TopExtension: styled.div`
    background-color: ${colors.white};
    display: flex;
    justify-content: space-between;
    height: 51px;
    align-items: center;
    border-bottom: 1px solid #e7e7ed;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `,
  MembersCount: styled.div`
    color: ${colors.primary};
    font-weight: 700;
    margin-left: 5%;
  `,
}
