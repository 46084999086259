/* eslint-disable complexity */
import {SvgIcon} from '@mui/material'
import CandidateInventory from '@ui/candidate-list/CandidateInventory'
import type {TProcessedCandidate} from '@ui/candidate-list/CandidateList'
import CandidateListContainer from '@ui/candidate-list/CandidateListContainer'
import CandidateInformation from '@ui/card/CandidateInformation'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import socketIO from 'socket.io-client'
import {ReactComponent as SearchIcon} from '~/src/assets/images/material-symbols_person-search-outline.svg'
import {ReactComponent as RedCircleX} from '~/src/assets/images/RedCircleX.svg'
import type {Applicant, ApplicantsRequest} from '~/src/models/applicant'
import type {IJobOrder} from '~/src/models/jobOrder'
import type {IPosition} from '~/src/models/position'
import type {IPrefill} from '~/src/models/prefill'
import {useGetAgenciesQuery} from '~/src/store/apis/agency-api'
import {
  useLazyGetApplicantListByIdsQuery,
  useLazyGetApplicantQuery,
  useLazyGetApplicantsQuery,
} from '~/src/store/apis/applicants-api'
import {
  useLazyGetAllDataCleanupsQuery,
  useLazyGetApplicantsByDataCleanupIdQuery,
  useLazyGetCleanedApplicantsByDataCleanupIdQuery,
} from '~/src/store/apis/data-cleanup-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {selectApplicantsState} from '~/src/store/slices/applicants-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import type {IFiltersToPass} from '../bucket/BucketList'
import SelectCandidatesMenu from '../candidates/SelectCandidatesMenu'
import FilterDialog, {IFilterDialogFormData} from './FilterDialog'
import {
  AllCandidatesButtonBox,
  AllCandidatesContainer,
  AllCandidatesHeaderContainer,
  AllCandidatesHeaderText,
  CandidateDetailsContainer,
  CandidateInformationContainer,
} from './styles/CandidateContainerStyles'

interface UrlParams {
  cardId?: string
}

interface IProps {
  isCandidatesOnly?: boolean
  showCandidateDetails?: boolean
  addedCandidates?: Partial<Applicant>[]
  excludedCandidateIds?: string[]
  isFilledOrReady?: boolean
  isMsp?: boolean
  isDisabled?: boolean
  isCandidateDirectory?: boolean
  isCapped?: boolean
  isCleanupDisabled?: boolean
  isDataCleanup?: boolean
  jobOrderData?: IJobOrder
  positionData?: IPosition
  bucketListFilter?: string
  bucketListCleanUpFilter?: string
  bucketFilters?: IFiltersToPass
  setBucketListCleanUpFilter?: (filterDate: string) => void
  focusedValue?: number
  setFocusedValue?: (value: number) => void
  handleRemoveCandidate?: (candidateId: string) => void
  handleAddCandidate?: (status: string, candidate: TProcessedCandidate) => void
  handleDeclineCandidate?: (candidateId: string, notes: string) => void
  handleSkipCandidate?: (notes: string, candidateId: string) => void
  handleFillJobOrder?: () => void
  handleConfirmContact?: () => void
  handleConfirmAvailability?: () => void
  handleConfirmSkills?: () => void
  resetConfirmation?: () => void
  onCleanCandidate?: () => Promise<void>
  setDataCleanupCandidateSelected?: () => void
  isPrefill?: boolean
  prefillData?: IPrefill
}

function CandidatesContainer({
  isCandidatesOnly = false,
  showCandidateDetails = true,
  addedCandidates = [],
  excludedCandidateIds,
  isFilledOrReady,
  isMsp,
  isDisabled,
  isCandidateDirectory,
  isCapped = false,
  isCleanupDisabled = true,
  isDataCleanup = false,
  jobOrderData,
  positionData,
  bucketListFilter = 'All Candidates',
  bucketListCleanUpFilter,
  bucketFilters,
  setBucketListCleanUpFilter = () => {},
  focusedValue,
  setFocusedValue = () => {},
  handleRemoveCandidate,
  handleAddCandidate,
  handleDeclineCandidate,
  handleSkipCandidate,
  handleFillJobOrder,
  handleConfirmContact = () => {},
  handleConfirmAvailability = () => {},
  handleConfirmSkills = () => {},
  resetConfirmation = () => {},
  onCleanCandidate,
  setDataCleanupCandidateSelected,
  isPrefill,
  prefillData,
}: IProps) {
  const params: UrlParams = useParams()
  const cleanupId = params.cardId
  const {currentUser} = useCurrentUser()
  const [candidates, setCandidates] = useState<Applicant[]>()
  const [totalCandidates, setTotalCandidates] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [agencyCandidatesQueryParam, setAgencyCandidatesQueryParam] = useState<
    'currentAgency' | 'allAgencies'
  >('currentAgency')
  const [filterArgs, setFilterArgs] = useState<{firstName: string; lastName: string; ssn: string}>({
    firstName: '',
    lastName: '',
    ssn: '',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [cleanedCandidates, setCleanedCandidates] = useState<Applicant[]>([])
  const [candidateSelectedId, setCandidateSelectedId] = useState('')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const isGeneralAdmin = currentUser?.roles.includes('general_admin')

  const newIsLoading = useContext(LoadingContext)

  // Job orders, prefill, and candidates
  const [getApplicants] = useLazyGetApplicantsQuery()

  // Data Cleanup
  const [getCleanedApplicants] = useLazyGetCleanedApplicantsByDataCleanupIdQuery()
  const [getApplicantsByCleanupId] = useLazyGetApplicantsByDataCleanupIdQuery()
  const [
    getApplicantsFromIds,
    {error, isLoading, data: dataCleanupDataFromids, isSuccess: isDataCleanupCandidatesSuccess},
  ] = useLazyGetApplicantListByIdsQuery()
  const [getAllDataCleanups, {data: dataCleanupAllData}] = useLazyGetAllDataCleanupsQuery()
  const {allApplicants} = useAppSelector(selectApplicantsState)
  const [getApplicant] = useLazyGetApplicantQuery()

  const pageIndexes = {start: 0, end: 9}
  const isFiltersActive =
    filterArgs.firstName.length > 0 || filterArgs.lastName.length > 0 || filterArgs.ssn.length > 0

  if (currentPage > 1) {
    pageIndexes.start = currentPage * 10 - 10
    pageIndexes.end = currentPage * 10 - 1
  }

  useGetAgenciesQuery({
    pageNumber: 1,
    pageSize: 999999999,
  })

  const getDataCleanupCandidates = useCallback(
    async (applicantIds: {applicantIds: string[]; count: number}, ids?: string[]) => {
      if (cleanupId || ids) {
        if (applicantIds.applicantIds.length) {
          const applicants = await getApplicantsFromIds({
            applicantIds: applicantIds.applicantIds,
            agencyId: loggedInAgencyId,
          }).unwrap()
          setCandidates(applicants)
          if (setDataCleanupCandidateSelected)
            setDataCleanupCandidateSelected(applicants[0]?.id ?? '')
        } else if (ids?.length) {
          const applicants = await getApplicantsFromIds({
            applicantIds: ids,
            agencyId: loggedInAgencyId,
          }).unwrap()
          setCandidates(applicants)
          if (setDataCleanupCandidateSelected)
            setDataCleanupCandidateSelected(applicants[0]?.id ?? '')
        } else {
          setCandidates([])
        }
      }
    },
    [cleanupId, getApplicantsFromIds, loggedInAgencyId, setDataCleanupCandidateSelected],
  )

  const getCleanedCandidates = useCallback(
    async (cleanedCandidateIds: {cleanedApplicantIds: string[]; count: number}) => {
      const cleaned = await getApplicantsFromIds({
        applicantIds: cleanedCandidateIds.cleanedApplicantIds,
        agencyId: loggedInAgencyId,
      }).unwrap()
      setCleanedCandidates(cleaned)
    },
    [getApplicantsFromIds, loggedInAgencyId],
  )

  const applicantQueryArgs = useMemo(() => {
    const queryArgs: ApplicantsRequest = {
      pageNumber: currentPage,
      pageSize: 10,
      cleanUpDate: bucketListCleanUpFilter,
      excludedApplicantIds: isPrefill
        ? prefillData && prefillData.candidateList.length > 0
          ? prefillData.candidateList.map((candidate) => candidate.candidateId)
          : undefined
        : excludedCandidateIds && excludedCandidateIds.length > 0
        ? excludedCandidateIds
        : undefined,
      isBlacklisted: false,
      firstName: filterArgs.firstName === '' ? undefined : filterArgs.firstName,
      lastName: filterArgs.lastName === '' ? undefined : filterArgs.lastName,
      last4SSN: filterArgs.ssn === '' ? undefined : filterArgs.ssn,
      agencyId: loggedInAgencyId,
    }
    if (isGeneralAdmin && agencyCandidatesQueryParam === 'allAgencies') {
      delete queryArgs.agencyId
    }
    if (isPrefill && bucketFilters && prefillData) {
      queryArgs.milesFromWorksite = bucketFilters.selectedDistance
      queryArgs.clientsApplicantWorkedFor = bucketFilters.previouslyWorkedAtFilters
      queryArgs.filterByFlags = bucketFilters.filterByFlags
      queryArgs.skills = bucketFilters.skills
      queryArgs.worksiteLocation = prefillData.worksiteLocation
    } else if (jobOrderData && positionData && bucketFilters) {
      queryArgs.milesFromWorksite = bucketFilters.selectedDistance
      queryArgs.clientsApplicantWorkedFor = bucketFilters.previouslyWorkedAtFilters
      queryArgs.filterByFlags = bucketFilters.filterByFlags
      queryArgs.skills = bucketFilters.skills
      queryArgs.worksiteLocation = positionData.facilityAddress
    }
    return queryArgs
  }, [
    agencyCandidatesQueryParam,
    bucketFilters,
    bucketListCleanUpFilter,
    currentPage,
    excludedCandidateIds,
    filterArgs.firstName,
    filterArgs.lastName,
    filterArgs.ssn,
    isGeneralAdmin,
    isPrefill,
    jobOrderData,
    loggedInAgencyId,
    positionData,
    prefillData,
  ])

  const getAllApplicants = useCallback(async () => {
    if (isDataCleanup) {
      const allDataCleanups = await getAllDataCleanups(loggedInAgencyId).unwrap()
      const cleanedCandidateIds = await getCleanedApplicants({
        id: cleanupId as string,
        startIndex: 0,
        endIndex: 100,
        agencyId: loggedInAgencyId,
      }).unwrap()
      const applicantIdsByCleanupId = await getApplicantsByCleanupId(
        {
          id: cleanupId as string,
          startIndex: pageIndexes.start,
          endIndex: pageIndexes.end,
          agencyId: loggedInAgencyId,
          __: new Date().toString(),
        },
        false,
      ).unwrap()
      const totalDataCleanupCandidates = allDataCleanups.dataCleanups.find(
        (cleanup) => cleanup.id === cleanupId,
      )?.totalApplicants
      setTotalCandidates(totalDataCleanupCandidates ?? 0)
      void getDataCleanupCandidates(applicantIdsByCleanupId)
      void getCleanedCandidates(cleanedCandidateIds)
    } else if (
      (isPrefill && applicantQueryArgs.worksiteLocation && prefillData && bucketFilters) ||
      !isPrefill
    ) {
      const allCandidates = await getApplicants(applicantQueryArgs).unwrap()

      setCandidates(allCandidates.applicants)
      setTotalCandidates(allCandidates.totalApplicants ?? 0)
      setCandidateSelectedId(allCandidates.applicants[0]?.id ?? '')
    }
  }, [
    applicantQueryArgs,
    bucketFilters,
    cleanupId,
    getAllDataCleanups,
    getApplicants,
    getApplicantsByCleanupId,
    getCleanedApplicants,
    getCleanedCandidates,
    getDataCleanupCandidates,
    isDataCleanup,
    isPrefill,
    loggedInAgencyId,
    pageIndexes.end,
    pageIndexes.start,
    prefillData,
  ])

  useEffect(() => {
    void getAllApplicants()
  }, [
    applicantQueryArgs,
    getAllApplicants,
    getDataCleanupCandidates,
    getCleanedCandidates,
    isDataCleanup,
  ])

  const handleFilterSubmit = (formikValues: IFilterDialogFormData) => {
    setFilterArgs({
      firstName: formikValues.firstName,
      lastName: formikValues.lastName,
      ssn: formikValues.ssn,
    })
    setIsOpen(false)
  }

  const handleFilterCancel = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    void getApplicant({
      id: candidateSelectedId,
      agencyId: candidates?.find((applicant) => applicant.id === candidateSelectedId)?.agencyId,
    })
  }, [candidateSelectedId, candidates, getApplicant])

  useEffect(() => {
    const refreshEverything = async () => {
      void getAllApplicants()
    }

    const socket = socketIO.connect(process.env.REACT_BACKEND_URL)

    socket.on('lock', () => {
      void refreshEverything()
    })

    socket.on('unlock', () => {
      void refreshEverything()
    })

    // CLEAN UP THE EFFECT
    return () => {
      socket.off('locked')
      socket.off('unlocked')
      socket.disconnect()
    }
  }, [])

  return (
    <CandidateDetailsContainer>
      <FilterDialog
        isOpen={isOpen}
        firstName={filterArgs.firstName}
        lastName={filterArgs.lastName}
        ssn={filterArgs.ssn}
        handleCancel={handleFilterCancel}
        handleSubmit={handleFilterSubmit}
      />
      {showCandidateDetails ? (
        <>
          <AllCandidatesContainer>
            <AllCandidatesHeaderContainer>
              {isGeneralAdmin ? (
                <SelectCandidatesMenu
                  handleClick={(selection) => {
                    setAgencyCandidatesQueryParam(selection)
                    // reset page on selection
                    setCurrentPage(1)
                  }}
                />
              ) : (
                <AllCandidatesHeaderText>All Candidates</AllCandidatesHeaderText>
              )}

              <AllCandidatesButtonBox
                style={{
                  position: 'relative',
                  alignItems: 'center',
                  height: '30px',
                  width: '37px',
                }}>
                <SvgIcon
                  data-testid="open-search-dialog"
                  aria-label="open-search-dialog"
                  component={SearchIcon}
                  onClick={() => {
                    setIsOpen(true)
                  }}
                  sx={{visibility: isDataCleanup ? 'hidden' : 'visible', cursor: 'pointer'}}
                />
                <RedCircleX
                  data-testid="clear-search-results"
                  aria-label="clear-search-results"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    visibility: isFiltersActive ? 'visible' : 'hidden',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setFilterArgs({firstName: '', lastName: '', ssn: ''})
                  }}
                />
              </AllCandidatesButtonBox>
            </AllCandidatesHeaderContainer>
            {candidates?.length === 0 ? (
              <div style={{padding: '12px 14px'}}>No Candidates Match This Filter.</div>
            ) : candidates?.length && (jobOrderData || isCandidatesOnly) ? (
              <>
                <CandidateListContainer
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  candidates={isDataCleanup ? candidates : allApplicants?.applicants}
                  isDataCleanup={isDataCleanup}
                  onAddCandidate={handleAddCandidate}
                  onCandidateDeclined={handleDeclineCandidate}
                  onCandidateSkipped={handleSkipCandidate}
                  setCandidateSelected={setCandidateSelectedId}
                  setDataCleanupCandidateSelected={setDataCleanupCandidateSelected}
                  isCleanupDisabled={isCleanupDisabled}
                  onCleanCandidate={async () => {
                    if (onCleanCandidate) {
                      await onCleanCandidate()
                      void getAllApplicants()
                    }
                  }}
                  isCapped={isCapped}
                  isCandidateDirectory={isCandidateDirectory}
                  resetConfirmation={resetConfirmation}
                  jobOrder={jobOrderData}
                />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <StyledPagination
                    style={{
                      paddingLeft: '0px',
                      paddingTop: '0px',
                      paddingBottom: '20px',
                      maxWidth: '230px',
                    }}
                    page={currentPage}
                    totalRecords={totalCandidates}
                    recordsPerPage={10}
                    onChange={setCurrentPage}
                  />
                </div>
              </>
            ) : (
              <div />
            )}
          </AllCandidatesContainer>

          <CandidateInformationContainer>
            {candidates?.length === 0 ? (
              <div>No Candidates Match This Filter.</div>
            ) : (
              <CandidateInformation
                candidates={isDataCleanup ? candidates : allApplicants?.applicants}
                candidateSelected={candidateSelectedId}
                isDataCleanup={isDataCleanup}
                onConfirmContact={handleConfirmContact}
                onConfirmAvailability={handleConfirmAvailability}
                onConfirmSkills={handleConfirmSkills}
                refetchCandidates={getAllApplicants}
              />
            )}
          </CandidateInformationContainer>
        </>
      ) : (
        <>
          <CandidateInventory
            isDataCleanup={isDataCleanup}
            removeCandidate={handleRemoveCandidate}
            candidates={
              isDataCleanup
                ? cleanedCandidates.length > 0
                  ? cleanedCandidates
                  : []
                : addedCandidates
            }
            agencyId={loggedInAgencyId}
            isFilledOrReady={isFilledOrReady}
            isDisabled={isDisabled}
            onSubmit={handleFillJobOrder}
            isMsp={isMsp}
            isPrefill={isPrefill}
            jobOrderStatus={jobOrderData?.status}
          />
        </>
      )}
    </CandidateDetailsContainer>
  )
}

export default CandidatesContainer
