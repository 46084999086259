import styled, {css} from 'styled-components'
import {colors, font} from '~/src/config/theme'
import type {TButtonVariants} from '@ui/button/typings'

const disabled = css`
  background-color: transparent;
  border-color: ${colors.disabled};
  color: ${colors.disabled};
`

const primary = css`
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  color: ${colors.white};
`

const primaryClear = css`
  background-color: transparent;
  border-color: ${colors.primary};
  color: ${colors.primary};
`

const secondary = css`
  background-color: ${colors.secondary};
  border-color: ${colors.secondary};
  color: ${colors.white};
`

const secondaryClear = css`
  background-color: transparent;
  border-color: ${colors.secondary};
  color: ${colors.secondary};
`

const clearNoBorder = css`
  background-color: transparent;
  border-color: transparent;
`

const white = css`
  background-color: ${colors.white};
  border-color: ${colors.white};
`

export default {
  Container: styled.button(
    (props: {variant: TButtonVariants}) => css`
      border: 1px solid;
      border-radius: 4px;
      cursor: pointer;
      font-size: ${font.sizes.small};
      font-weight: inherit;
      height: 39px;
      line-height: 20px;
      min-width: 103px;
      overflow: hidden;
      padding: 0 21.2496px;
      text-overflow: ellipsis;
      white-space: nowrap;

      ${props.variant === 'primary' && primary};
      ${props.variant === 'primary-clear' && primaryClear};
      ${props.variant === 'secondary' && secondary};
      ${props.variant === 'secondary-clear' && secondaryClear};
      ${props.variant === 'clear-no-border' && clearNoBorder};
      ${props.variant === 'white' && white};
      ${props.variant === 'disabled' && disabled};

      &:disabled {
        ${disabled};
      }
    `,
  ),
}
