import React from 'react'
import Box from '@mui/material/Box'
import Icon from '@ui/icons'
import styled from 'styled-components'

interface IProps {
  totalUsers: number
}
const styles = {
  Filter: styled.label`
    cursor: pointer;
  `,
}

export default function PrependedHeader({totalUsers}: IProps) {
  const {Filter} = styles
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 65,
        width: '100%',
        alignItems: 'center',
        padding: '10px 20px 10px 25px',
        borderBottom: '1px solid #CDCED9',
      }}>
      <div style={{fontSize: '15px', fontWeight: 500}}>All Members ({totalUsers})</div>
      <Filter>
        <Icon icon="filter" />
        Filter
      </Filter>
    </Box>
  )
}
