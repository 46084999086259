const cssBaseline: Readonly<string> = `


  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    font-family: Open Sans;
  }

  body {
    background-color: #f5f6f8;
    color: #242424;
    overflow-y: hidden;
  }

  #root {
    min-height: 100vh;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
  }

    li {
    list-style-type: none;
  }

`

export default cssBaseline
