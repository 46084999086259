import LoadingBoundary from '@ui/loader'
import AddPrefillCard from '@ui/Prefill/AddPrefillCard'
import PrefillCard from '@ui/Prefill/PrefillCard'
import {push} from 'connected-react-router'
import React, {ReactElement, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import type {IPrefill} from '~/src/models/prefill'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetPrefillsQuery} from '~/src/store/apis/prefill-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import {DataCleanupContainer} from '../../msp/manage-job-orders/job-order-summary/job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

const ContentContainer = styled('div')({
  maxWidth: '100%',
  width: '100%',
})

const PrefillLabel = styled('div')({
  fontWeight: 600,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '24px',
})

const FlexSpaceBetweenContainer = styled('div')({
  display: 'flex',
  maxWidth: '1089px',
  width: '1089px',
  justifyContent: 'space-between',
  marginTop: 50,
})

interface IPrefillProps {
  prefillData: IPrefill
  isMsp: boolean
  sx: object
}

export function PrefillFunctionCard({prefillData, isMsp, sx}: IPrefillProps) {
  const dispatch = useDispatch()

  const {id} = prefillData

  const handleRedirectToCandidates = (value: boolean) => {
    switch (isMsp) {
      case true:
        if (value) {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}/prefill/${id}?showDetails=true`))
        } else {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}/prefill/${id}?showDetails=false`))
        }
        break
      default:
        if (value) {
          dispatch(push(`${BASE_ROUTES.AGENCY}/prefill/${id}?showDetails=true`))
        } else {
          dispatch(push(`${BASE_ROUTES.AGENCY}/prefill/${id}?showDetails=false`))
        }
    }
  }

  return (
    <PrefillCard
      prefillData={prefillData}
      isInPrefill={false}
      isMsp={isMsp}
      onSummaryToggle={handleRedirectToCandidates}
      sx={{...sx}}
    />
  )
}

interface IProps {
  isMsp?: boolean
}

export default function PrefillDashboard({isMsp}: IProps): ReactElement {
  const location = useLocation()
  const [, pathBase] = location.pathname.split('/')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {
    data: prefills,
    refetch,
    error,
    isLoading,
    isFetching,
  } = useGetPrefillsQuery(loggedInAgencyId)

  const dispatch = useAppDispatch()

  useEffect(() => {
    void refetch()
  }, [refetch])

  return (
    <LoadingBoundary isLoading={isLoading || isFetching}>
      <ContentContainer>
        <FlexSpaceBetweenContainer>
          <PrefillLabel>Prefill</PrefillLabel>
        </FlexSpaceBetweenContainer>
        <DataCleanupContainer style={{width: '100%', maxWidth: '100%', marginTop: 30}}>
          {prefills && prefills.length > 0 ? (
            prefills.map((prefill) => {
              return (
                <PrefillFunctionCard
                  prefillData={prefill}
                  isMsp={isMsp as boolean}
                  key={prefill.id}
                  sx={{marginRight: '23px', marginBottom: '23px'}}
                />
              )
            })
          ) : (
            <></>
          )}
          <AddPrefillCard
            sx={{marginRight: '23px', marginBottom: '23px'}}
            onClick={() => {
              dispatch(push(`/${pathBase}${SIDEBAR_ROUTES.PREFILL_ADD}`))
            }}
          />
        </DataCleanupContainer>
      </ContentContainer>
    </LoadingBoundary>
  )
}
