import React from 'react'
import {ListItem, ListItemText} from './Dialogs/styles/AddDocumentDialogStyles'
import {ReactComponent as PaperDocument} from '../../../assets/images/PaperDocumentSvg.svg'
import {ReactComponent as TrashIcon} from '../../../assets/images/TrashCan.svg'

interface IProps {
  fileName: string
  handleDelete: string
}

export default function AddFilesList({fileName, handleDelete}: IProps) {
  return (
    <ListItem>
      <PaperDocument
        data-testid="user-myInfo-paperDocumentIcon"
        aria-label="user-myInfo-paperDocumentIcon"
        height={24}
        width={24}
        style={{cursor: 'pointer'}}
      />
      <ListItemText
        data-testid={`user-myInfo-fileName-${fileName}`}
        aria-label={`user-myInfo-fileName-${fileName}`}>
        {fileName}
      </ListItemText>
      <TrashIcon
        data-testid="user-myInfo-trashCanIcon-button"
        aria-label="user-myInfo-trashCanIcon-button"
        onClick={handleDelete}
        height={18}
        width={18}
        style={{cursor: 'pointer'}}
      />
    </ListItem>
  )
}
