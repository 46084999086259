import {createSlice} from '@reduxjs/toolkit'
import type {TAgenciesPayload, TAgencyNameAndId} from '~/src/models/agency'
import type {MemberAccess} from '~/src/models/memberAccess'
import type {TRootState} from '..'
import {agencyApi} from '../apis/agency-api'
import {memberAccessApi} from '../apis/member-access-api'

export interface IAgencyState {
  allAgencies?: TAgenciesPayload
  memberAccess?: MemberAccess
  agencyNamesAndIds: TAgencyNameAndId[]
}

const initialState: IAgencyState = {
  allAgencies: undefined,
  memberAccess: undefined,
  agencyNamesAndIds: [],
}

const agencySlice = createSlice({
  name: 'agencySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(agencyApi.endpoints.getAgencies.matchFulfilled, (state, {payload}) => {
        state.allAgencies = payload
      })
      .addMatcher(
        memberAccessApi.endpoints.getMemberAccessByMemberId.matchFulfilled,
        (state, {payload}) => {
          state.memberAccess = payload
        },
      )
      .addMatcher(
        memberAccessApi.endpoints.getGaAgencyNamesAndIds.matchFulfilled,
        (state, {payload}) => {
          state.agencyNamesAndIds = payload
        },
      )
  },
})

export const selectAgencyState = (state: TRootState): TRootState['agency'] => state.agency
export default agencySlice.reducer
