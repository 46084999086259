import {styled} from '@mui/material/styles'
import ErrorBoundary from '@ui/error'
import React from 'react'
import ViewWrapper from '~/src/components/common/ViewWrapper'
import CardLink from '~/src/components/platform/onboarding/PlatformWidget'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import AddAgencyForm from './AddAgencyForm'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 36,
})

const TopRow = styled('div')({
  columnGap: 30,
  display: 'flex',
})
export type TActivePlatform = 'Company' | 'Agency'

export default function OnboardingAgency() {
  const [activePlatform, setactivePlatform] = React.useState<TActivePlatform>('Company')
  return (
    <ViewWrapper customHeader={<Title pageTitle={`Onboarding ${activePlatform}`} />}>
      <Container>
        <TopRow>
          <CardLink
            icon="double-building"
            topText="Onboard Agency"
            bottomText="104"
            data-testid="msp-nav-button"
            isActive={true}
            onClick={() => setactivePlatform('Agency')}
          />
        </TopRow>

        <ErrorBoundary>
          <AddAgencyForm activePlatform={activePlatform} />
        </ErrorBoundary>
      </Container>
    </ViewWrapper>
  )
}
