import styled from 'styled-components'
import {colors, font} from '~/src/config/theme'

export default {
  Container: styled.div`
    padding: 10px;
    color: ${colors.red};
  `,
  Message: styled.span`
    border-left: 0.125rem solid ${colors.red};
    display: inline-block;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding-left: 0.1875rem;
  `,
}
