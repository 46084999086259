import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

const Svg = styled.svg``

export default function AddIcon(props: HTMLAttributes<unknown>): JSX.Element {
  return (
    <Svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.13332 7.85549C1.50125 7.85549 0.988866 8.36788 0.988867 8.99994C0.988867 9.632 1.50126 10.1444 2.13332 10.1444L7.85544 10.1444L7.85545 15.8667C7.85545 16.4988 8.36784 17.0112 8.9999 17.0112C9.63196 17.0112 10.1444 16.4988 10.1444 15.8667L10.1443 10.1444L15.8667 10.1444C16.4988 10.1444 17.0112 9.63201 17.0112 8.99995C17.0112 8.36789 16.4988 7.8555 15.8667 7.8555L10.1443 7.85549L10.1443 2.13332C10.1443 1.50125 9.63195 0.988866 8.99989 0.988866C8.36783 0.988865 7.85544 1.50125 7.85544 2.13332L7.85544 7.85549L2.13332 7.85549Z"
        fill="currentColor"
      />
    </Svg>
  )
}
