import type {IAddress} from './address'
import type {IBaseAddress} from './baseAddress'
import type {IDepartment} from './department'

export type TType = 'Company' | 'MSP' | 'Recruiter'

export enum ECompanyStatus {
  'Pending',
  'Active',
  'Rejected',
  'Disabled',
  'Needs Review',
  'Failed',
}
export interface ICompensationCode {
  id: string
  code: string
  state: string
  // markUpRate?: number
  // carryOverRate?: number
}

export interface ICompanyTypeMaps {
  nameToId: Record<string, string>
  idToName: Record<string, string>
}

// Model from backend server
export interface ICompanyBase {
  id: string | undefined
  addresses: IAddress[]
  billingAddress: IBaseAddress
  adminEmail: string
  adminFirstName: string
  adminLastName: string
  agencyId: string
  companyWebsite: string | null
  compensationCodes: ICompensationCode[]
  departments: IDepartment[]
  name: string | undefined
  noEmployees: number
  phoneNumber: string
  status: ECompanyStatus
  typeId: string
  createdDate: Date
  modifiedDate: Date
  versionKey: number
}

export interface AllData {
  id?: string
  addresses?: IAddress[]
  billingAddress?: IBaseAddress
  adminEmail?: string
  adminFirstName?: string
  adminLastName?: string
  agencyId?: string
  compensationCodes?: ICompensationCode[]
  departments?: IDepartment[]
  name?: string
  noEmployees?: number
  phoneNumber?: string
  status?: ECompanyStatus
  typeId?: string
  createdDate?: Date
  modifiedDate?: Date
  versionKey?: number

  address: IAddress

  website: string
}

export interface TCompaniesPayload {
  companies: ICompanyBase[]
  totalCompanies: number
}

export interface AllDataPayload {
  alldata: AllData[]
  totalallData: number
}
export interface TCompaniesPayloadName {
  name: string
}

// prettier-ignore
export type TCreateCompanyPayload = Pick<
	ICompanyBase,
	| 'name' | 'noEmployees' | 'phoneNumber'  | 'adminFirstName'
	|'typeId'|  'adminEmail' |	'addresses'		| 'adminLastName' | 'billingAddress'
> & {departments?: IDepartment[]}

export type TUpdateDepartmentsPayload = Omit<ICompanyBase, 'departments'> & {
  departments: Omit<IDepartment, 'id'>[]
}

export type TUpdateStatusPayload = Pick<ICompanyBase, 'id' | 'status'>

export type TPagedCompaniesPayload = {
  pageNumber?: number
  pageSize?: number
  id?: string
  agencyId?: string
}
