import {Grid} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import HeaderContainer from '@ui/header-container/HeaderContainer'
import Mui from '@ui/mui'
import {push} from 'connected-react-router'
import {Formik} from 'formik'
import _ from 'lodash'
import {nanoid} from 'nanoid/non-secure'
import React, {useCallback, useMemo} from 'react'
import * as Yup from 'yup'
import 'yup-phone'
import type {IAddress, IAgencyBase, ICompanyBase} from '~/src/models'
import type {IBaseAddress} from '~/src/models/baseAddress'
import {useLazyFindCompanyQuery, useUpdateCompanyMutation} from '~/src/store/apis/company-api'
import {useAppDispatch} from '~/src/store/store-hooks'
import AddressAutoComplete from '../../platform/onboarding/AddressAutoComplete'
import CompanyAddressList from '../../platform/onboarding/CompanyAddressList'
import CompensationCodeList from '../../platform/onboarding/CompensationCodeList'
import FormikTextField from '../../ui/mui-text-field/FormikTextField'
import {Button, Form, Red} from '../styles/EditCompanyStyles.styles'

const RightColumn = styled('div')({
  textAlign: 'left',
  justifyContent: 'left',
})
const ImageSubText = styled('div')({
  marginBottom: 20,
  textAlign: 'left',
  color: 'grey',
})

const ImageBox = styled(Box)({
  width: '200px',
  height: '50px',
  maxWidth: '200px',
  maxHeight: '50px',
  backgroundColor: 'rgba(137, 196, 244, 0.5)',
  border: '1px, solid, lightBlues',
  marginBottom: 20,
})

const CompanyLogo = styled('div')({
  marginBottom: 20,
  textAlign: 'left',
  paddingLeft: '80px',
})

export type TActivePlatform = 'Company' | 'Agency'

export const createCompensationCodeItem = () => ({
  id: nanoid(),
  code: '',
  state: '',
})

export interface IFormValues {
  addresses: IAddress[] | undefined
  noEmployees: number | undefined
  billingAddress: IBaseAddress | undefined
  adminEmail: string | undefined
  adminFirstName: string | undefined
  adminLastName: string | undefined
  companyName: string | undefined
  phoneNumber: string | undefined
  companyWebsite: string | undefined
  agencyId: string | undefined
}

interface IProps {
  activePlatform: TActivePlatform
  initialEditValues: ICompanyBase | undefined
  agency: IAgencyBase | undefined
}

export default function EditCompany({activePlatform, initialEditValues, agency}: IProps) {
  const dispatch = useAppDispatch()
  const [findCompany] = useLazyFindCompanyQuery()
  const [updateCompany] = useUpdateCompanyMutation()

  const agencyName = agency ? agency?.name : ''

  const initialValues: IFormValues = {
    companyName: initialEditValues?.name,
    noEmployees: initialEditValues?.noEmployees,
    phoneNumber: initialEditValues?.phoneNumber,
    companyWebsite: initialEditValues?.companyWebsite,
    addresses: initialEditValues?.addresses.map((newAddresses) => {
      return newAddresses
    }),

    billingAddress: {
      line1: initialEditValues?.billingAddress.line1,
      city: initialEditValues?.billingAddress.city,
      state: initialEditValues?.billingAddress.state,
      zipCode: initialEditValues?.billingAddress.zipCode,
      display: initialEditValues?.billingAddress.display,
    },
    adminFirstName: initialEditValues?.adminFirstName,
    adminLastName: initialEditValues?.adminLastName,
    adminEmail: initialEditValues?.adminEmail,
    compensationCodes: initialEditValues?.compensationCodes.length
      ? initialEditValues.compensationCodes
      : [createCompensationCodeItem()],
    agencyId: initialEditValues?.agencyId,
  }

  const handleSubmit = async (values: IFormValues) => {
    await updateCompany({
      ...values,
      compensationCodes: initialEditValues?.compensationCodes,
      departments: [],
      name: values?.companyName,
      noEmployees: Number(values.noEmployees),
      versionKey: initialEditValues?.versionKey,
      id: initialEditValues?.id,
      typeId: initialEditValues?.typeId,
    }).unwrap()

    dispatch(
      push('/company-admin/dashboard', {
        state: {openSnackbar: true, name: values.companyName, type: 'company'},
      }),
    )
  }

  const validateCompanyName = useCallback(
    async function (value: string | undefined, resolve: (value: boolean) => void) {
      if (initialEditValues?.name === value) {
        return resolve(true)
      } else {
        try {
          const alreadyExists = await findCompany(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findCompany],
  )

  const debouncedValidateCompanyName = useMemo(
    function () {
      return _.debounce(validateCompanyName, 500)
    },
    [validateCompanyName],
  )

  //TODO uncomment when we have backend to support images
  // const [imageToResize, setImageToResize] = useState(undefined)
  // const [resizedImage, setResizedImage] = useState(undefined)
  // const onSelectFile = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setImageToResize(event.target.files[0])
  //   }
  // }

  return (
    <HeaderContainer header={<div style={{fontWeight: 'bold'}}>{`Edit ${activePlatform}`}</div>}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object({
          adminEmail: Yup.string().email('Email address is invalid.').required('Required'),
          adminFirstName: Yup.string().required('Required'),
          adminLastName: Yup.string().required('Required'),
          phoneNumber: Yup.string()
            .required('Required')
            .phone('US', true, 'Phone number is invalid.'),
          companyWebsite: Yup.string().nullable(),
          addresses: Yup.array().of(
            Yup.object({
              name: Yup.string().required('Required'),
              isOnsite: Yup.boolean().nullable().required('Required'),
              address: Yup.object({
                line1: Yup.string(),
                city: Yup.string(),
                state: Yup.string(),
                zipCode: Yup.string(),
                display: Yup.string().required('Required'),
                location: Yup.object({
                  type: Yup.string(),
                  coordinates: Yup.array().of(Yup.number())
                }).required('Required')
              }),
            }),
          ),
          billingAddress: Yup.object({
            line1: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
            display: Yup.string().required('Required'),
          }),

          companyName: Yup.string()
            .test(
              'already-exists',
              'Company name is already taken.',
              (value) => new Promise((resolve) => debouncedValidateCompanyName(value, resolve)),
            )
            .required('Required'),
        })}>
        {({isSubmitting, setFieldValue, values, handleChange}) => {
          return (
            <>
              <Form>
                <Grid container columnSpacing={2} rowSpacing={4}>
                  <Grid
                    aria-label="company name"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="companyName"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    {activePlatform} Name<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="company name"
                      name="companyName"
                      inputProps={{maxLength: 50}}
                    />
                  </Grid>

                  <Grid
                    aria-label="phone number"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="phoneNumber"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    {activePlatform} Phone<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="phone number"
                      name="phoneNumber"
                      placeholder="1 123 456 7890"
                      type="tel"
                    />
                  </Grid>
                  <Grid
                    aria-label="company website"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="companyWebsite"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    {activePlatform} Website:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="company website"
                      name="companyWebsite"
                      placeholder="CompanyName.com"
                    />
                  </Grid>
                  <Grid container item xs={12} pb={2}>
                    <Grid item xs={3} />
                    <Grid
                      aria-label="divider"
                      item
                      xs={9}
                      component={Mui.Divider}
                      variant="fullWidth"
                    />
                  </Grid>
                  <Grid container item xs={12} columnSpacing={2}>
                    <CompanyAddressList
                      values={values}
                      initialEditValues={initialEditValues}
                      setFieldValue={setFieldValue}
                      activePlatform={activePlatform}
                    />
                  </Grid>
                  <Grid container item xs={12} pb={2}>
                    <Grid item xs={3} />
                    <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                  </Grid>
                  <Grid container item xs={12} columnSpacing={2}>
                    <CompensationCodeList values={values} />
                  </Grid>
                  <Grid container item xs={12} pb={2}>
                    <Grid item xs={3} />
                    <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                  </Grid>
                  <Grid
                    aria-label="assigned agency"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="billingAddress"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    Assigned Agency<Red>*</Red>:
                  </Grid>

                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="assigned agency"
                      inputProps={{
                        readOnly: true,
                      }}
                      name="agencyId"
                      id="agencyId"
                      value={agencyName}
                    />
                  </Grid>

                  <Grid container item xs={12} pb={2}>
                    <Grid item xs={3} />
                    <Grid
                      aria-label="divider"
                      item
                      xs={9}
                      component={Mui.Divider}
                      variant="fullWidth"
                    />
                  </Grid>

                  <Grid
                    aria-label="billing address"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="billingAddress"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    Billing Address<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <AddressAutoComplete
                      address={values.billingAddress}
                      initialEditValues={initialEditValues}
                      setFieldValue={setFieldValue}
                      activePlatform={activePlatform}
                      name="billingAddress"
                    />
                  </Grid>
                  <Grid container item xs={12} pb={2}>
                    <Grid item xs={3} />
                    <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                  </Grid>
                  <Grid
                    aria-label="admin first name"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="adminFirstName"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    Admin First Name<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="admin first name"
                      name="adminFirstName"
                      placeholder="Sample First Name"
                      inputProps={{maxLength: 50}}
                    />
                  </Grid>
                  <Grid
                    aria-label="admin last name"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="adminLastName"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    Admin Last Name<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="admin last name"
                      name="adminLastName"
                      placeholder="Sample Last Name"
                      inputProps={{maxLength: 50}}
                    />
                  </Grid>
                  <Grid
                    aria-label="admin email"
                    item
                    xs={3}
                    sx={{pr: '20px'}}
                    component="label"
                    htmlFor="adminEmail"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center">
                    Admin Email<Red>*</Red>:
                  </Grid>
                  <Grid item xs={9}>
                    <FormikTextField
                      aria-label="admin email"
                      name="adminEmail"
                      placeholder="sample.email@company.com"
                    />
                  </Grid>

                  <Grid item xs={3} sx={{pr: '20px'}} justifyContent="end" />
                  <Grid item xs={9} mt={6}>
                    <Button
                      aria-label="cancel"
                      variant="outlined"
                      color="secondary"
                      type="reset"
                      disabled={isSubmitting}
                      sx={{mr: 'auto'}}
                      onClick={() => {
                        dispatch(push('/company-admin/dashboard'))
                      }}>
                      Cancel
                    </Button>
                    <Button
                      aria-label="publish"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      sx={{ml: 2}}>
                      Publish
                    </Button>
                  </Grid>
                </Grid>
                {/* //TODO uncomment when we have backend to support images */}
                {/* <RightColumn>
                  <CompanyLogo>Company Logo: {imageToResize?.name}</CompanyLogo>
                  <div style={{marginLeft: '80px'}}>
                    <ImageBox>
                      {resizedImage && <img width="200" height="50" src={resizedImage} />}
                    </ImageBox>

                    <input
                      accept="image/*"
                      style={{display: 'none'}}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={onSelectFile}
                    />
                    <ImageSubText>
                      *images will try to be resized to 200px (W) x 50px (H) on upload.*
                    </ImageSubText>
                    <label htmlFor="contained-button-file">
                      <Fab component="span">
                        <div>
                          <ImageResizerComponent
                            imageToResize={imageToResize}
                            onImageResized={(resizedImage) => setResizedImage(resizedImage)}
                          />
                        </div>

                        <AddPhotoAlternateRoundedIcon />
                      </Fab>
                    </label>
                  </div>
                </RightColumn> */}
              </Form>
            </>
          )
        }}
      </Formik>
    </HeaderContainer>
  )
}

EditCompany.defaultProps = {
  title: '',
}
