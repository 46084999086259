import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import MspAvailabilityDayBubble from '@ui/day-bubble/MspAvailabilityDayBubble'
import {format} from 'date-fns'
import React, {useState} from 'react'
import {ReactComponent as PencilIcon} from '~/src/assets/images/PencilIcon.svg'
import type {Applicant, Availability} from '~/src/models/applicant'
import {
  AvailabilityContainer,
  AvailabilityDateText,
  AvailabilityOverviewText,
  AvailabiltyMarginBottom,
  DashboardMainStyles,
  DayBoxContainer,
  DayEditInformationContainer,
  FlexColumnContainer,
  FlexSpaceBetweenContainer,
  FlexWithoutPaddingContainer,
  InformationAvailabilityCardEditContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  Red,
  ShiftButtonSelectionContainer,
  ShiftSelectBlueText,
  ShiftSelectionContainer,
  ToggleContainer,
  UnAvailabilityContainer,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import AvailabilityShift from './AvailabilityShift'

interface IProps {
  allValues: Applicant
  handleCancel(): void
  handleClick(value: string): void
  handleSubmit(values: Applicant): Promise<void>
  setFieldValue(field: any, value: any): void
  values: Availability
  isDataCleanup: boolean
}

export default function MspCandidateAvailabilityEdit({
  allValues,
  handleCancel,
  handleClick,
  handleSubmit,
  setFieldValue,
  values,
  isDataCleanup,
}: IProps) {
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const [shiftFocus, setShiftFocus] = useState('0')
  const [availableContainer, setAvailableContainer] = useState(true)
  const handleShiftClick = (newShift: string) => {
    return setShiftFocus(newShift)
  }

  const todaysDate = format(new Date(), 'MM/dd/yyyy')

  const handleToggle = (value: boolean) => {
    setAvailableContainer(value)
    if (value) {
      setFieldValue('availability.overAllAvailability', true)
    } else {
      setShiftFocus('0')
      setFieldValue(`availability`, {
        startDate: todaysDate,
        endDate: todaysDate,
        overAllAvailability: false,
        shifts: [
          {shift: 1, shiftAvailability: [false, false, false, false, false, false, false]},
          {shift: 2, shiftAvailability: [false, false, false, false, false, false, false]},
          {shift: 3, shiftAvailability: [false, false, false, false, false, false, false]},
        ],
      })
    }
  }

  const handleBubbleClick = (day: string) => {
    const targetDayIndex = days.findIndex((x) => x === day)
    switch (shiftFocus) {
      case '0':
        if (values.shifts[0]?.shiftAvailability[targetDayIndex] === false) {
          return setFieldValue(`availability.shifts[0].shiftAvailability[${targetDayIndex}]`, true)
        } else {
          return setFieldValue(`availability.shifts[0].shiftAvailability[${targetDayIndex}]`, false)
        }

        break
      case '1':
        if (values.shifts[1]?.shiftAvailability[targetDayIndex] === false) {
          return setFieldValue(`availability.shifts[1].shiftAvailability[${targetDayIndex}]`, true)
        } else {
          return setFieldValue(`availability.shifts[1].shiftAvailability[${targetDayIndex}]`, false)
        }

        break
      case '2':
        if (values.shifts[2]?.shiftAvailability[targetDayIndex] === false) {
          return setFieldValue(`availability.shifts[2].shiftAvailability[${targetDayIndex}]`, true)
        } else {
          return setFieldValue(`availability.shifts[2].shiftAvailability[${targetDayIndex}]`, false)
        }

        break
      default:
        return <div />
    }
  }

  const handleSave = async () => {
    if (availableContainer) {
      setFieldValue('availability.overAllAvailability', true)
      await handleSubmit(allValues)
      handleClick('saveChanges')
    } else {
      await handleSubmit(allValues)
      handleClick('saveChanges')
    }
  }

  const handleToggleAll = () => {
    setFieldValue(`availability`, {
      startDate: todaysDate,
      endDate: todaysDate,
      overAllAvailability: true,
      shifts: [
        {shift: 1, shiftAvailability: [true, true, true, true, true, true, true]},
        {shift: 2, shiftAvailability: [true, true, true, true, true, true, true]},
        {shift: 3, shiftAvailability: [true, true, true, true, true, true, true]},
      ],
    })
  }

  const maxStartDate = values?.endDate && new Date(values.endDate)
  const minEndDate = values?.startDate && new Date(values.startDate)

  return (
    <>
      <>
        <DashboardMainStyles>
          <InformationAvailabilityCardEditContainer $isAvailable={availableContainer}>
            <InformationCardHeaderContainer>
              <InformationCardHeaderPadding>
                <InformationCardHeaderTextTitle aria-label="Availability Information">
                  Availability Information
                </InformationCardHeaderTextTitle>

                <FlexSpaceBetweenContainer>
                  {isDataCleanup ? null : (
                    <>
                      <InformationCardHeaderTextEdit
                        style={{marginRight: 10}}
                        onClick={handleCancel}>
                        Cancel
                      </InformationCardHeaderTextEdit>
                      <InformationCardHeaderTextEdit onClick={handleSave}>
                        Save
                      </InformationCardHeaderTextEdit>
                    </>
                  )}
                </FlexSpaceBetweenContainer>
              </InformationCardHeaderPadding>
            </InformationCardHeaderContainer>

            <div>
              <div style={{borderBottom: availableContainer ? '1px solid #ebeff2' : 'none'}}>
                <InformationCardDetailsContainer>
                  <FlexSpaceBetweenContainer style={{alignItems: 'center'}}>
                    <>
                      <FlexWithoutPaddingContainer style={{marginTop: 5, marginBottom: 5}}>
                        <FlexColumnContainer style={{display: availableContainer ? '' : 'none'}}>
                          <AvailabilityDateText>
                            Start Date <Red>*</Red>
                          </AvailabilityDateText>
                          <div style={{width: '150px'}}>
                            {' '}
                            <FormikDatePicker
                              aria-label="startDatePicker"
                              data-testid="startDatePicker"
                              sx={{width: '100%'}}
                              name="availability.startDate"
                              initialValue={values.startDate}
                              onChange={(va: Date) => setFieldValue(values.startDate, va)}
                              placeholder="Start Date"
                              maxDate={
                                values.endDate === ''
                                  ? undefined
                                  : maxStartDate?.setDate(maxStartDate?.getDate())
                              }
                            />
                          </div>
                        </FlexColumnContainer>

                        <FlexColumnContainer
                          style={{marginLeft: 20, display: availableContainer ? '' : 'none'}}>
                          <AvailabilityDateText>End Date</AvailabilityDateText>
                          <div style={{width: '150px'}}>
                            <FormikDatePicker
                              aria-label="endDatePicker"
                              data-testid="endDatePicer"
                              sx={{width: '100%'}}
                              placeholder="End Date"
                              name="availability.endDate"
                              minDate={minEndDate?.setDate(minEndDate?.getDate())}
                              initialValue={values.endDate}
                              onClose={(va: any) => setFieldValue(values.endDate, va)}
                            />
                          </div>
                        </FlexColumnContainer>
                      </FlexWithoutPaddingContainer>
                      <div style={{height: availableContainer ? '32px' : '75px'}} />
                    </>
                    <FlexColumnContainer>
                      <ToggleContainer>
                        <AvailabilityContainer
                          aria-label="Available"
                          $isAvailable={availableContainer}
                          onClick={() => handleToggle(true)}>
                          Available
                        </AvailabilityContainer>
                        <UnAvailabilityContainer
                          aria-label="Unavailable"
                          $isAvailable={!!availableContainer}
                          onClick={() => handleToggle(false)}>
                          Unavailable
                        </UnAvailabilityContainer>
                      </ToggleContainer>
                    </FlexColumnContainer>
                  </FlexSpaceBetweenContainer>
                </InformationCardDetailsContainer>
              </div>
              <DayEditInformationContainer style={{display: availableContainer ? '' : 'none'}}>
                <ShiftSelectionContainer>
                  <ShiftSelectBlueText aria-label="Select a Shift To Edit">
                    Select a Shift to Edit
                  </ShiftSelectBlueText>
                  <ShiftButtonSelectionContainer
                    style={{textWrap: 'nowrap', justifyContent: 'center', marginLeft: '0px'}}
                    aria-label="AllDaysAndAllShifts"
                    $shiftFocus={shiftFocus === '3'}
                    onClick={() => {
                      handleToggleAll()
                      handleShiftClick('3')
                    }}>
                    All Days & All Shifts
                  </ShiftButtonSelectionContainer>
                  <ShiftButtonSelectionContainer
                    aria-label="Shift 1 Focus"
                    $shiftFocus={shiftFocus === '0'}
                    onClick={() => handleShiftClick('0')}>
                    <span style={{marginLeft: 15}}>Shift 1</span>
                    {shiftFocus === '0' && (
                      <span>
                        <PencilIcon
                          style={{marginLeft: 10, marginTop: 3}}
                          aria-label="white-PencilIcon"
                        />
                      </span>
                    )}
                  </ShiftButtonSelectionContainer>
                  <ShiftButtonSelectionContainer
                    aria-label="Shift 2 Focus"
                    $shiftFocus={shiftFocus === '1'}
                    onClick={() => handleShiftClick('1')}>
                    <span style={{marginLeft: 15}}>Shift 2</span>
                    {shiftFocus === '1' && (
                      <span>
                        <PencilIcon
                          style={{marginLeft: 10, marginTop: 3}}
                          aria-label="white-PencilIcon"
                        />
                      </span>
                    )}
                  </ShiftButtonSelectionContainer>
                  <ShiftButtonSelectionContainer
                    aria-label="Shift 3 Focus"
                    $shiftFocus={shiftFocus === '2'}
                    onClick={() => handleShiftClick('2')}>
                    <span style={{marginLeft: 15}}>Shift 3</span>
                    {shiftFocus === '2' && (
                      <span>
                        <PencilIcon
                          style={{marginLeft: 10, marginTop: 3}}
                          aria-label="white-PencilIcon"
                        />
                      </span>
                    )}
                  </ShiftButtonSelectionContainer>
                </ShiftSelectionContainer>
                <div>
                  <AvailabilityOverviewText aria-label="Shift Selection">
                    Select The Available Days
                  </AvailabilityOverviewText>
                  <DayBoxContainer>
                    <FlexColumnContainer style={{marginTop: 20}}>
                      <FlexSpaceBetweenContainer>
                        <AvailabiltyMarginBottom
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                          }}>
                          {days.map((day: string, index: number) => {
                            return (
                              <>
                                <MspAvailabilityDayBubble
                                  availableContainer={true}
                                  day={day}
                                  index={index}
                                  handleBubbleClick={handleBubbleClick}
                                  values={values}
                                />
                              </>
                            )
                          })}
                        </AvailabiltyMarginBottom>
                      </FlexSpaceBetweenContainer>
                      <AvailabiltyMarginBottom>
                        <AvailabilityShift aria-label="Shift 1 " shift={values.shifts[0]} />
                      </AvailabiltyMarginBottom>
                      <AvailabiltyMarginBottom>
                        <AvailabilityShift aria-label="Shift 2 " shift={values.shifts[1]} />
                      </AvailabiltyMarginBottom>
                      <AvailabiltyMarginBottom>
                        <AvailabilityShift aria-label="Shift 3 " shift={values.shifts[2]} />
                      </AvailabiltyMarginBottom>
                    </FlexColumnContainer>
                  </DayBoxContainer>
                </div>
              </DayEditInformationContainer>
            </div>
          </InformationAvailabilityCardEditContainer>
        </DashboardMainStyles>
      </>
    </>
  )
}
