import React from 'react'
import type {IButtonIcon} from './typings'
import S from '@ui/button-icon/styles/button-icon.styles'

export function ButtonIcon({icon, ...rest}: IButtonIcon) {
  return (
    <S.Container {...rest}>
      <S.Icon icon={icon} />
    </S.Container>
  )
}

ButtonIcon.defaultProps = {
  variant: 'none',
  textColor: 'inherit',
  shape: 'square',
  className: '',
  sizeVariant: 'medium',
  onClick: function () {
    console.error('No event handler')
  },
}
