import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import {styled, useTheme} from '@mui/material/styles'
import React from 'react'

const CustomizedButton = styled(Button)`
  &.MuiButton-root {
    font-size: '16px';
    color: #000000;
    font-weight: 600;
    background-color: #ffffff;
    border-radius: 16px;
    height: 82px;
    width: 260px;
  }
` as typeof Button

interface IProps {
  onClick(): void
}

export default function CreateNewOrderButton({onClick}: IProps) {
  const theme = useTheme()
  return (
    <CustomizedButton
      variant="contained"
      onClick={onClick}
      startIcon={
        <AddCircleIcon
          style={{
            color: theme.palette.primary.main,
            position: 'relative',
            left: '-10px',
            height: '42px',
            width: '42px',
          }}
        />
      }>
      Create New Order
    </CustomizedButton>
  )
}
