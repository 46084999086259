import {createApi} from '@reduxjs/toolkit/query/react'
import type {
  TAgenciesPayload,
  IAgencyBase,
  TPagedAgenciesPayload,
  ISuccessMessage,
  TUpdateStatusPayload,
  TAgencyNameAndId,
} from '../../models'
import {baseQuery} from '../base-query'

export const agencyApi = createApi({
  reducerPath: 'agencyApi',
  baseQuery: baseQuery,
  tagTypes: ['Agency'],
  endpoints: (builder) => ({
    getAgencies: builder.query<TAgenciesPayload, TPagedAgenciesPayload>({
      providesTags: ['Agency'],
      query: ({pageNumber, pageSize}) => ({
        url: `/Agency/GetAgencies`,
        method: 'GET',
        params: {pageNumber, pageSize},
      }),
    }),

    getAgenciesByMspStatus: builder.query<IAgencyBase[], string>({
      providesTags: ['Agency'],
      query: (isMsp: string) => ({
        url: `/Agency/GetAgenciesByMspStatus/${isMsp}`,
        method: 'GET',
      }),
    }),

    getAgencyNames: builder.query<string[], TPagedAgenciesPayload>({
      providesTags: ['Agency'],
      query: ({pageNumber, pageSize, agencyId}) => ({
        url: `/Agency/GetAgencyNames/Names`,
        method: 'GET',
        params: {pageNumber, pageSize, agencyId},
      }),
      transformResponse: ({agencies}) => {
        return agencies.map((agency: IAgencyBase) => agency.name)
      },
    }),

    getOneAgency: builder.query<IAgencyBase, string>({
      providesTags: ['Agency'],
      query: (agencyId) => ({
        url: `/Agency/GetAgency/${agencyId}`,
        method: 'GET',
      }),
      transformResponse: (agency: IAgencyBase) => {
        return agency
      },
    }),

    findAgency: builder.query<boolean, string>({
      providesTags: ['Agency'],
      query: (name) => ({
        url: `/Agency/FindAgencyName/Find/${name}`,
        method: 'GET',
      }),
    }),

    getAgencyNamesByIds: builder.query<TAgencyNameAndId[], string[]>({
      providesTags: ['Agency'],
      query: (body) => ({
        url: `/agency/getAgencyNamesByIds`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (agencyData: TAgencyNameAndId[]) => {
        return agencyData.sort((a, b) => a.name.localeCompare(b.name))
      },
    }),

    createAgency: builder.mutation<ISuccessMessage, Partial<IAgencyBase>>({
      invalidatesTags: ['Agency'],
      query: (body) => ({
        url: `/Agency/OnboardAgency`,
        method: 'POST',
        body: body,
      }),
    }),

    updateAgencyStatus: builder.mutation<ISuccessMessage, TUpdateStatusPayload>({
      invalidatesTags: ['Agency'],
      query: ({id, status}) => ({
        url: `/Agency/UpdateAgencyStatus/${id}/Status/${status}`,
        method: 'PUT',
      }),
    }),

    updateAgency: builder.mutation<ISuccessMessage, Partial<IAgencyBase>>({
      invalidatesTags: ['Agency'],
      query: (body) => ({
        url: `/Agency/UpdateAgency`,
        method: 'PUT',
        body: body,
      }),
    }),

    deleteAgency: builder.mutation<ISuccessMessage, string>({
      invalidatesTags: ['Agency'],
      query: (id) => ({
        url: `/Agency/DeleteAgency`,
        method: 'DELETE',
        params: {id},
      }),
    }),
  }),
})

export const {
  useGetAgenciesQuery,
  useLazyGetAgenciesQuery,
  useGetAgenciesByMspStatusQuery,
  useGetAgencyNamesQuery,
  useUpdateAgencyStatusMutation,
  useDeleteAgencyMutation,
  useGetOneAgencyQuery,
  useGetAgencyNamesByIdsQuery,
  useLazyGetAgencyNamesByIdsQuery,
  useCreateAgencyMutation,
  useUpdateAgencyMutation,
  useLazyGetOneAgencyQuery,
  usePrefetch,
  useLazyFindAgencyQuery,
} = agencyApi
