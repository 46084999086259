import React from 'react'
import {styled} from '@mui/material/styles'
import Radio from '@mui/material/Radio'
import CheckboxChecked from '@ui/icons/CheckboxChecked'
import CheckboxUncheckedIcon from '@ui/icons/CheckboxUnchecked'

const CustomizedRadio = styled(Radio)``

export function StyledRadio({...props}) {
  return (
    <CustomizedRadio
      icon={<CheckboxUncheckedIcon />}
      checkedIcon={<CheckboxChecked />}
      {...props}
    />
  )
}
