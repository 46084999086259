import HeaderContainer from '@ui/header-container/HeaderContainer'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import type {IInitialPositionValues} from '~/src/models/position'
import Title from '../../layout/Title'
import PositionForm from '../../common/positions/PositionForm'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
interface IParams {
  orderId: string
}

export default function EditPosition(): React.ReactElement {
  const {orderId}: IParams = useParams()
  const {currentUser} = useCurrentUser()
  const {
    data: addressUser,
    refetch: fetchUser,
    error: getCompanyError,
    isLoading: loadingUser,
    isFetching: fetchingUser,
    isSuccess: companySuccess,
  } = useGetOneCompanyQuery(currentUser?.companyId)
  const {
    data: positionData,
    refetch: positionRefetch,
    isLoading: positionDataLoading,
  } = useGetPositionByIdQuery({id: orderId}, {skip: !companySuccess})
  const initialValues = positionData
    ? ({
        id: positionData.id,
        createdBy: positionData.createdBy,
        createdDate: positionData.createdDate,
        versionKey: positionData.versionKey,
        onsiteFacilityName: positionData.facilityName,
        onsiteFacilityAddress: positionData.facilityAddress.display,
        position: positionData.name,
        departmentName: positionData.departmentName,
        workersCompCode: positionData.compensationCode,
        startTime: positionData.startTime,
        endTime: positionData.endTime,
        manager: positionData.manager,
        jobDescription: positionData.jobDescription,
        onsiteFacilityId: positionData.onsiteFacilityId,
      } as IInitialPositionValues)
    : {}

  useEffect(() => {
    void fetchUser()
    if (companySuccess) {
      void positionRefetch()
    }
  }, [fetchUser, positionRefetch, companySuccess])

  return (
    <>
      <LoadingBoundary isLoading={positionDataLoading}>
        <Title pageTitle="Edit Position" />
        <HeaderContainer header={<div style={{fontWeight: 'bold'}}>Edit Position</div>}>
          <PositionForm initialValues={initialValues} isEdit={true} />
        </HeaderContainer>
      </LoadingBoundary>
    </>
  )
}
