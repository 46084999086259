import PersonIcon from '@mui/icons-material/Person'
import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import React from 'react'
import type {ApplicantInterview} from '~/src/models/applicant'
import {TNameAndId, employmentTypesLabelMap} from './CommonInterviewComponents'
import type {IUserAndEmail} from '~/src/models'

const Container = styled(Box)<{$isLast: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: ${(props) => (props.$isLast ? 'none' : '1px solid #c4c4c4')};
`

const Top = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Company = styled('div')`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`

const ViewInterviewButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  min-width: 102px;
  height: 23px;
  left: 616px;
  border-radius: 9px;
  background: #03a9fc;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
`

const BasicText = styled('span')`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #707070;
`

const DateText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8a8a8a;
`

const InterviewerText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

interface IProps {
  interview: ApplicantInterview
  isLast?: boolean
  companyNamesAndIds?: TNameAndId[]
  usersAndEmails?: IUserAndEmail[]
  onClick: (interview: ApplicantInterview) => void
}

const HistoryItem = ({
  interview,
  isLast = false,
  companyNamesAndIds,
  usersAndEmails,
  onClick,
}: IProps) => {
  const companyNamesJoined = interview.company
    .map((companyId) => companyNamesAndIds?.find((item) => item.id === companyId)?.name)
    .join(', ')

  const empTypeJoined = interview.employmentType
    .map((empId) => employmentTypesLabelMap.get(empId))
    .join(', ')

  const userAndEmail = usersAndEmails?.find(
    (item) => item.emailAddress === (interview.modifiedBy ?? interview.createdBy),
  )
  return (
    <Container $isLast={isLast}>
      <Top>
        <Company>{companyNamesJoined}</Company>
        <ViewInterviewButton onClick={() => onClick(interview)}>View Interview</ViewInterviewButton>
      </Top>
      <BasicText
        style={{
          position: 'relative',
          top: '-2px',
        }}>
        {interview.position.join(', ')}
      </BasicText>
      <BasicText style={{marginTop: '3px'}}>{empTypeJoined}</BasicText>
      <BasicText style={{marginTop: '15px'}}>{`Notes: ${interview.notes}`}</BasicText>
      <Box sx={{display: 'flex', marginTop: '15px', marginBottom: '10px', alignItems: 'center'}}>
        <DateText>{`${new Date(
          interview.modifiedDate ?? interview.createdDate,
        ).toLocaleString()} |`}</DateText>
        <PersonIcon sx={{color: '#8a8a8a', width: '12px', marginLeft: '2px', marginRight: '4px'}} />
        <InterviewerText>{`${userAndEmail?.firstName} ${userAndEmail?.lastName}`}</InterviewerText>
      </Box>
    </Container>
  )
}

export default React.memo(HistoryItem)
