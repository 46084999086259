import {Select, MenuItem, SelectChangeEvent} from '@mui/material'
import React from 'react'
import {DeclineCandidateReason} from '~/src/models/jobOrder'

interface DeclineReasonsProps {
  notes: string
  onSelectNotes: (event: SelectChangeEvent<string>) => void
}

const DeclineReasons = ({notes, onSelectNotes}: DeclineReasonsProps) => {
  const {SKILLS, LICENSE_OR_CERTIFICATION, AVAILABILITY, WORKING_ELSEWHERE, NOT_INTERESTED} =
    DeclineCandidateReason

  return (
    <Select value={notes} onChange={onSelectNotes} displayEmpty sx={{textAlign: 'left'}}>
      <MenuItem value="">
        <em>Select a Reason</em>
      </MenuItem>
      <MenuItem value={SKILLS}>{SKILLS}</MenuItem>
      <MenuItem value={LICENSE_OR_CERTIFICATION}>{LICENSE_OR_CERTIFICATION}</MenuItem>
      <MenuItem value={AVAILABILITY}>{AVAILABILITY}</MenuItem>
      <MenuItem value={WORKING_ELSEWHERE}>{WORKING_ELSEWHERE}</MenuItem>
      <MenuItem value={NOT_INTERESTED}>{NOT_INTERESTED}</MenuItem>
    </Select>
  )
}

export default DeclineReasons
