import {Grid, MenuItem, Select, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React, {useState} from 'react'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetServiceOrderTemplatesQuery} from '~/src/store/apis/service-order-api'
import {selectServiceOrderState} from '~/src/store/slices/service-order-slice'
import {useAppSelector} from '~/src/store/store-hooks'

interface HeaderProps {
  setFieldValue?: (event: any) => void
  values: string | number | boolean | object | null
  companyId: string | number | boolean | object | null
  onCompanySelection?: (event: any) => void
  selectedCompany: string | number | boolean | object | null
  setSelectedCompany?: (event: any) => void
  initialValues: string | number | boolean | object | null
  isEdit: boolean
}

function MenuRow(props: any): JSX.Element {
  const {data: companyData} = useGetCompanyNameByIdQuery({
    id: props.i,
  })

  return (
    <MenuItem
      value={props.i}
      onClick={(e) => {
        return (
          props.handleChange(companyData),
          props.setOpen(false),
          props.setFieldValue('companyId', props.i)
        )
      }}
      key={props.index}>
      {companyData}
    </MenuItem>
  )
}

export default function PrependedTemplateAddHeader({
  values,
  isEdit,
  setFieldValue,
  companyId,
  onCompanySelection,
  selectedCompany,
  initialValues,
  setSelectedCompany,
}: HeaderProps) {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [open, setOpen] = React.useState(false)
  const {currentServiceOrderPageNumber} = useAppSelector(selectServiceOrderState)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {
    data: dataService,
    refetch,
    error,
    isLoading,
    isFetching,
  } = useGetServiceOrderTemplatesQuery({
    pageNumber: currentServiceOrderPageNumber,
    pageSize: 100,
    agencyId: loggedInAgencyId,
  })

  const uniqueCompanyNames = companyId
    ? [...new Set(companyId?.map((i) => i))]
    : [...new Set(companyId?.map((i) => i))]
  const handleChange = (e: any) => {
    setSelectedCompany(e)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        paddingLeft: '10px',
        marginRight: '50px',
        marginTop: '30px',
      }}>
      <>
        <Grid container>
          <Grid
            item
            xs
            sx={{
              textAlign: 'right',
              fontSize: '14.5px',
              fontWeight: 500,
              padding: '10px',
              marginLeft: -47,
            }}>
            Template Name:
          </Grid>
          <Grid item xs>
            <FormikTextField sx={{maxWidth: '462px', minWidth: '462px'}} name="name" />
          </Grid>

          <Grid
            item
            xs
            sx={{
              textAlign: 'right',
              fontSize: '16px',
              fontWeight: 500,
              padding: '10px',
              marginLeft: -56,
            }}>
            Client:
          </Grid>
          {isEdit ? (
            <Grid item xs>
              <TextField
                sx={{maxWidth: '492px', minWidth: '492px'}}
                select
                name="companyId"
                defaultValue={initialValues?.companyName}
                inputProps={{readOnly: true}}>
                <MenuItem value={initialValues?.companyName}>{initialValues?.companyName}</MenuItem>
              </TextField>
            </Grid>
          ) : (
            <Grid item xs>
              <Select
                name="companyId"
                onClose={() => {
                  setOpen(false)
                }}
                onOpen={() => {
                  setOpen(true)
                }}
                open={open}
                sx={{maxWidth: '492px', minWidth: '492px'}}
                value={selectedCompany ?? 'none'}
                onChange={onCompanySelection}>
                <MenuItem value="Select Company">Select Company</MenuItem>

                {uniqueCompanyNames.map((i, index) => {
                  return (
                    <MenuRow
                      value={selectedCompany ? selectedCompany : ''}
                      setOpen={setOpen}
                      handleChange={handleChange}
                      key={index}
                      setFieldValue={setFieldValue}
                      values={values}
                      i={i}>
                      {selectedCompany}
                    </MenuRow>
                  )
                })}
              </Select>
            </Grid>
          )}
        </Grid>
      </>
    </Box>
  )
}
