import {Avatar, Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import {css, styled} from '@mui/material/styles'
import EditVariantIcon from '@ui/icons/EditVariant'
import {push} from 'connected-react-router'
import {
  differenceInDays,
  differenceInHours,
  getDate,
  getMonth,
  getYear,
  isFuture,
  setDate,
  setMonth,
  setYear,
  startOfDay,
} from 'date-fns'
import React, {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {ReactComponent as FormatListBulletedIcon} from '~/src/assets/images/FormatListIcon.svg'
import {ReactComponent as SearchIcon} from '~/src/assets/images/SearchIcon.svg'
import type {IPrefill} from '~/src/models/prefill'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes/constants'
import {setPrefillToEdit} from '~/src/store/slices/prefill-slice'
import CalendarDayGraphic from '../card/CalendarDayGraphic'

const Container = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    height: 274px;
    width: 260px;
    border-radius: 16px;
    padding: 15px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
  `,
) as typeof Box

const FirstRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const Ratio = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
) as typeof Box

const BigNumbers = styled('div')<{isBlue?: boolean; isSelected?: boolean}>(
  ({theme, isBlue = false, isSelected}) => css`
    font: normal 700 36px 'Open Sans';
    color: ${isSelected ? 'white' : isBlue ? theme.palette.primary.main : theme.palette.grey[700]};
  `,
)

const SecondRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const DemoText = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 700 18px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
)

const FilterText = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 600 14px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
)

const ThirdRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.grey[100]};
  `,
) as typeof Box

const SmallVertContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
) as typeof Box

const SmallLabel = styled('div')<{isSelected?: boolean}>(
  ({theme, isSelected}) => css`
    font: normal 400 12px 'Open Sans';
    color: ${isSelected ? 'white' : theme.palette.grey[700]};
  `,
)

const SmallValue = styled('div')<{isBlue?: boolean; isSelected?: boolean}>(
  ({theme, isBlue = false, isSelected}) => css`
    font: normal 700 20px 'Open Sans';
    color: ${isSelected ? 'white' : isBlue ? theme.palette.primary.main : theme.palette.grey[700]};
  `,
)

const SmallHorizContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  `,
) as typeof Box

const FourthRow = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  `,
) as typeof Box

const IconLeftContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${theme.palette.grey[100]};
    height: 30px;
  `,
) as typeof Box

const IconRightContainer = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
  `,
) as typeof Box

type TLink = 'search' | 'list'

interface IProps {
  prefillData: IPrefill
  isInPrefill: boolean
  isSelected?: boolean
  isMsp: boolean
  sx?: object
  onSummaryToggle?: (value: boolean) => void
  showList?: boolean
  activeLink?: TLink
  setActiveLink?: (link: TLink) => void
}

const PrefillCard = function PrefillCard({
  prefillData,
  isInPrefill,
  isSelected,
  isMsp,
  sx,
  onSummaryToggle,
  activeLink,
  setActiveLink,
}: IProps) {
  const dispatch = useDispatch()
  const {params} = useRouteMatch()
  const routerLocation = useLocation()

  const [, pathBase] = routerLocation.pathname.split('/')
  const {
    id,
    companyName,
    dueDate,
    title,
    active,
    filledQuantity,
    requestedQuantity,
    worksiteLocation,
  } = prefillData
  const location = worksiteLocation?.display

  const percentFilled =
    requestedQuantity === 0 ? 0 : Math.floor((filledQuantity / requestedQuantity) * 100)

  const startOfDistributionDate = startOfDay(new Date(dueDate))
  const year = getYear(startOfDistributionDate)
  const month = getMonth(startOfDistributionDate)
  const day = getDate(startOfDistributionDate)
  const startTime = new Date(dueDate)
  let intermediateResult = setYear(startTime, year)
  intermediateResult = setMonth(intermediateResult, month)
  const exactStartTime = setDate(intermediateResult, day)
  const currentTime = new Date()
  let daysLeft = 0
  let hoursLeft = 0

  if (isFuture(exactStartTime)) {
    daysLeft = differenceInDays(exactStartTime, currentTime)
    const totalHoursRemaining = differenceInHours(exactStartTime, currentTime)
    hoursLeft = totalHoursRemaining % 24
  }

  const redirect = (link: TLink | undefined) => {
    const baseAgencyRoute = isMsp ? BASE_ROUTES.AGENCY_ADMIN : BASE_ROUTES.AGENCY
    dispatch(
      push(`${baseAgencyRoute}/prefill/${encodeURIComponent(id)}?showDetails=${link !== 'list'}`),
    )
  }

  const redirectIfNotSelected = (isSearch: boolean) => {
    if (id !== params.prefillCardId) {
      redirect(isSearch ? 'search' : 'list')
    }
  }

  const searchIconColor = useMemo(() => {
    if (isSelected) {
      return activeLink === 'search' ? '#424242' : 'white'
    }
    return '#858585'
  }, [activeLink])

  const listIconColor = useMemo(() => {
    if (isSelected) {
      return activeLink === 'list' ? '#424242' : 'white'
    }
    return '#858585'
  }, [activeLink])

  return (
    <Container sx={{...sx, background: isSelected ? '#03A9FC' : '', cursor: 'pointer'}}>
      <Box
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          const target = event.target as HTMLButtonElement
          if (
            target.id === 'edit-data-cleanup' ||
            target.id === 'edit-data-cleanup-icon' ||
            target.id === 'edit-variant-icon'
          ) {
            event.preventDefault()
          } else {
            redirect(activeLink)
          }
        }}>
        <FirstRow>
          <Ratio>
            <BigNumbers style={{color: isSelected ? 'black' : ''}}>{filledQuantity}</BigNumbers>
            <BigNumbers style={{color: isSelected ? 'black' : ''}}>/</BigNumbers>
            <BigNumbers isSelected={isSelected} isBlue={true}>
              {requestedQuantity}
            </BigNumbers>
          </Ratio>
          <CalendarDayGraphic
            date={new Date(dueDate)}
            colorUnselected={isSelected ? 'white' : '#828282'}
          />
        </FirstRow>
        <SecondRow>
          <Tooltip title={title}>
            <DemoText isSelected={isSelected}>{title}</DemoText>
          </Tooltip>
          <Tooltip title={companyName ? `${companyName} - ${location}` : location}>
            <FilterText isSelected={isSelected}>
              {companyName ? `${companyName} - ${location}` : location}
            </FilterText>
          </Tooltip>
        </SecondRow>
        <ThirdRow>
          <SmallVertContainer>
            <SmallLabel isSelected={isSelected}>Time Left</SmallLabel>
            <SmallValue isSelected={isSelected} isBlue={true}>
              {`${daysLeft}D:${hoursLeft}H`}{' '}
            </SmallValue>
          </SmallVertContainer>
          <SmallVertContainer>
            <SmallLabel isSelected={isSelected}>Fill Rate</SmallLabel>
            <SmallValue isSelected={isSelected}>{`${percentFilled}%`}</SmallValue>
          </SmallVertContainer>
          <SmallHorizContainer>
            <Avatar
              sx={{maxHeight: '25px', maxWidth: '25px'}}
              alt="Remy Sharp"
              src="/static/images/avatar/2.jpg"
            />

            {/* <AdditionalAvatarText>+4</AdditionalAvatarText> */}
          </SmallHorizContainer>
        </ThirdRow>
      </Box>
      <FourthRow>
        {isInPrefill ? (
          <div />
        ) : (
          <IconLeftContainer>
            <IconButton
              id="edit-data-cleanup"
              onClick={() => {
                dispatch(setPrefillToEdit(prefillData))
                dispatch(push(`/${pathBase}${SIDEBAR_ROUTES.PREFILL_EDIT}`))
              }}>
              <EditVariantIcon id="edit-data-cleanup-icon" />
            </IconButton>
          </IconLeftContainer>
        )}

        <IconRightContainer>
          {onSummaryToggle && (
            <>
              <SearchIcon
                aria-label="prefill-search-icon"
                data-testid="prefill-search-icon"
                fill={searchIconColor}
                stroke={searchIconColor}
                onClick={() => {
                  onSummaryToggle(true)
                  setActiveLink?.('search')
                  redirectIfNotSelected(true)
                }}
                style={{
                  cursor: 'pointer',
                  marginRight: '7px',
                }}
              />
              <FormatListBulletedIcon
                aria-label="prefill-list-icon"
                data-testid="prefill-list-icon"
                stroke={listIconColor}
                onClick={() => {
                  onSummaryToggle(false)
                  setActiveLink?.('list')
                  redirectIfNotSelected(false)
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            </>
          )}
        </IconRightContainer>
      </FourthRow>
    </Container>
  )
}

export default React.memo(PrefillCard)
