import {Box, Typography} from '@mui/material'
import styled from 'styled-components'

export const HeaderBox = styled(Box)`
  display: flex;
  background: linear-gradient(180deg, #03a9fc 0%, #03cffc 100%);
  color: #fff;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  height: 138px;
  padding-left: 18px;
  padding-right: 18px;
  align-items: center;
  justify-content: space-between;
`

export const TopHeaderBox = styled(Box)`
  display: flex;
  background: #ffffff;
  border-radius: 9px 9px 0px 0px;
  color: black;
  width: 100%;
  height: 76px;
  padding-left: 18px;
  padding-right: 18px;
  align-items: center;
  justify-content: space-between;
`

export const HeaderText = styled(Typography)`
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`

export const CandidateHeaderText = styled('div')`
  font-weight: 600;
  font-size: 15px;
  color: #333333;
`

export const CardContainer = styled(Box)`
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 10px;
  width: 100%;
  margin-top: -25px;
  padding-left: 18px;
  padding-right: 18px;
`

export const Wrapper = styled(Box)`
  width: 100%;
`

export const CandidateName = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
`
