import {Box} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import React from 'react'

import S from './styles/Greeting.styles'

const Title = styled('div')(
  ({theme}) => css`
    text-align: center;
    font-size: ${theme.typography.h2.fontSize};
    margin-top: 50px;

    ${theme.breakpoints.up('lg')} {
      text-align: left;
    }
  `,
)

const Footer = styled('div')`
  color: inherit;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 38px;
  text-decoration: none;
  & > * {
    color: inherit;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 38px;
    text-decoration: none;
  }
`

interface IProps {
  customHeader?: React.ReactNode
  children?: React.ReactNode
  customFooter?: React.ReactNode
}

export default function Greeting({children, customHeader, customFooter, ...props}: IProps) {
  const defaultHeader = () => <S.Title>Welcome</S.Title>

  const defaultBody = () => (
    <div>
      <S.Logo icon="brand-logo" />
      <Box sx={{typography: 'h5', fontWeight: 'bold'}}>JobSource</Box>
      <Box sx={{typography: 'h5', fontWeight: 'normal', marginTop: '30px'}}>
        Connect With the World
        <br /> Around You
      </Box>
    </div>
  )

  const defaultFooter = () => <div />

  return (
    <S.Container {...props} sx={{boxShadow: 6}} data-testid="greeting">
      <Title>{customHeader}</Title>
      {children ?? defaultBody()}
      <Footer>{customFooter}</Footer>
    </S.Container>
  )
}
