import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function LoaderIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='21'
			height='22'
			viewBox='0 0 21 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.6384 10.3378C20.6257 10.0641 20.5047 9.80668 20.3021 9.62216C20.0996 9.43763 19.832 9.34113 19.5583 9.35388C19.2846 9.36664 19.0271 9.4876 18.8426 9.69016C18.6581 9.89272 18.5616 10.1603 18.5743 10.434C18.6612 12.2813 18.125 14.1042 17.0516 15.6101C15.9782 17.1161 14.43 18.2177 12.6554 18.7382C10.8809 19.2587 8.98287 19.1679 7.26612 18.4803C5.54937 17.7927 4.11342 16.5483 3.18875 14.9467C2.26409 13.3451 1.90435 11.4793 2.16727 9.6488C2.43018 7.81827 3.30051 6.12915 4.63855 4.85257C5.9766 3.576 7.70475 2.78601 9.54562 2.60941C11.3865 2.43281 13.2333 2.87983 14.7897 3.87874C14.9039 3.95197 15.0314 4.00199 15.1649 4.02594C15.2985 4.04989 15.4354 4.0473 15.568 4.01833C15.7005 3.98935 15.8261 3.93455 15.9374 3.85705C16.0488 3.77956 16.1438 3.68089 16.217 3.56667C16.2903 3.45246 16.3403 3.32494 16.3642 3.19139C16.3882 3.05785 16.3856 2.92089 16.3566 2.78835C16.3276 2.6558 16.2728 2.53026 16.1954 2.4189C16.1179 2.30753 16.0192 2.21252 15.905 2.13928C13.9594 0.891338 11.651 0.333185 9.35021 0.5544C7.04941 0.775615 4.88964 1.76337 3.21749 3.35915C1.54534 4.95493 0.457778 7.0662 0.129329 9.35415C-0.19912 11.6421 0.250593 13.9741 1.40629 15.9758C2.562 17.9775 4.35668 19.533 6.50232 20.3925C8.64797 21.252 11.0202 21.3658 13.2382 20.7156C15.4563 20.0653 17.3916 18.6888 18.7336 16.8069C20.0755 14.9249 20.7464 12.6467 20.6384 10.3378V10.3378Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
