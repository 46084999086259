import {useState, useEffect} from 'react';
import {media} from './media';

interface IUseMediaQueries {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
}

const getMediaObj = (): IUseMediaQueries => ({
	isMobile: window.matchMedia(`${media.mobile.min} and ${media.mobile.max}`).matches,
	isTablet: window.matchMedia(`${media.tablet.min} and ${media.tablet.max}`).matches,
	isDesktop: window.matchMedia(`${media.desktop.min}`).matches,
});

/*
 *	Usage:
 *
 * const ReactComponent = () => {
 *		const {isMobile} = useMediaQueries();
 *
 *		return (
 *			<div style={{width: isMobile ? 350 : 1080}}>Hello World</div>
 *		)
 *	}
 */
export function useMediaQueries(): IUseMediaQueries {
	const [state, setState] = useState(getMediaObj());

	function setMediaQueries() {
		setState(getMediaObj());
	}

	useEffect(() => {
		window.addEventListener('resize', setMediaQueries);
		return () => window.removeEventListener('resize', setMediaQueries);
	}, []);

	return state;
}
