import {Button} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import type {SerializedError} from '@reduxjs/toolkit'
import Checkbox from '@ui/checkbox/FormikCheckbox'
import ErrorBox from '@ui/error/ErrorBox'
import LoadingBoundary from '@ui/loader'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import PasswordFormikTextField from '@ui/mui-text-field/PasswordFormikTextField'
import SignInContainer from '@ui/sign-in-container/SignInContainer'
import {push} from 'connected-react-router'
import {Form, Formik} from 'formik'
import queryString from 'query-string'
import React, {useCallback, useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {errorText} from '~/src/components/auth/constants/helper-text'
import type {FirstSigninResponse, IRole, IUserBase, SigninResponse} from '~/src/models'
import {BASE_ROUTES} from '~/src/routes/constants'
import {useLazyGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useSigninMutation} from '~/src/store/apis/auth-api'
import {useLazyGetGaAgencyNamesAndIdsQuery} from '~/src/store/apis/member-access-api'
import {useLazyGetRoleQuery} from '~/src/store/apis/role-api'
import {useLazyGetUserByEmailQuery} from '~/src/store/apis/user-api'
import {updateLoggedInAgencyId, updateUserSignIn, updateCurrentUser} from '~/src/store/slices/root-slice'
import {useAppDispatch} from '~/src/store/store-hooks'
import {isNullOrUndefined} from '~/src/utils'
import ExpiredPasswordDialog from './ExpiredPasswordDialog'

const FormTitle = styled('div')(
  () => css`
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
  `,
)

const FieldContainer = styled('div')(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(4)};
    margin-top: ${theme.spacing(5)};
  `,
)

const ForgotPWLinkContainer = styled('div')(
  () => css`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
)

const ForgotPWLink = styled(NavLink)(
  ({theme}) => css`
    color: ${theme.palette.text.primary};
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    text-decoration: none;
  `,
)

const routeMap = new Map<string, string>([
  ['platformadmin', BASE_ROUTES.PLATFORM_ADMIN],
  ['companyadmin', BASE_ROUTES.COMPANY_ADMIN],
  ['agencyadmin', BASE_ROUTES.AGENCY_ADMIN],
  ['agencyuser', BASE_ROUTES.AGENCY],
])

const defaultError: SerializedError = {
  message: errorText.serverError,
}

interface IState {
  state: {
    email: string
    password: string
  }
}

export default function SigninPage() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const locationState = location.state as IState | undefined
  const {search} = useLocation()
  const tempEmail = locationState ? locationState.state.email : undefined
  const tempPassword = locationState ? locationState.state.password : undefined
  const [getUserByEmail, {error: detailsError, isLoading: isLoadingEmail}] =
    useLazyGetUserByEmailQuery()
  const [getRole, {error: rolesError}] = useLazyGetRoleQuery()
  const [getAgency, {error: agencyError}] = useLazyGetOneAgencyQuery()
  const [signin, {error: signinError}] = useSigninMutation()
  const [isLoading, setIsLoading] = useState(false)
  const serverError = rolesError ?? detailsError ? defaultError : null
  const paramValues = queryString.parse(search)
  const [expiredPasswordDialog, setExpiredPasswordDialog] = useState(false)
  const [getGaAgencyNamesAndIds] = useLazyGetGaAgencyNamesAndIdsQuery()

  const fetchUserDetails = useCallback(
    async function (props: SigninResponse | FirstSigninResponse) {
      if ('email_verified' in props) {
        setIsLoading(false)
        dispatch(
          push(BASE_ROUTES.PASSWORD_RESET, {
            state: {password: props.oldPassword, email: props.email, params: paramValues},
          }),
        )
        return undefined
      } else {
        const user = await getUserByEmail(props.emailAddress).unwrap()
        dispatch(updateCurrentUser(user))
        return user
      }
    },
    [dispatch, getUserByEmail, paramValues],
  )

  interface IRoleData {
    role: IRole | undefined
    isNonMspAgency: boolean
  }

  const checkIfMoreThanOneRole = useCallback(
    async function (user?: IUserBase): Promise<{roleData: IRoleData; user: IUserBase} | undefined> {
      if (isNullOrUndefined(user)) {
        return
      }

      if (user.roles.length > 1) {
        dispatch(push(BASE_ROUTES.ROLE_SELECT))
        return
      }

      const theRole = await getRole(user.roles[0] as string).unwrap()

      const retVal: IRoleData = {role: theRole.role, isNonMspAgency: false}

      if (user.roles[0] === 'agencyadmin') {
        const agency = await getAgency(user.agencyId).unwrap()
        const agencyMSP = agency.isMsp
        retVal.isNonMspAgency = !agencyMSP
      }

      return {roleData: retVal, user: user}
    },
    [dispatch, getAgency, getRole],
  )

  const goToDashboard = useCallback(
    async function (res?: {roleData: IRoleData; user: IUserBase}) {
      const {roleData, user} = res ?? {roleData: undefined, user: undefined}
      if (isNullOrUndefined(res) || isNullOrUndefined(roleData) || isNullOrUndefined(user)) return
      window.localStorage.setItem('cognitoRole', JSON.stringify(roleData))
      dispatch(updateUserSignIn({data: true}))

      if (roleData.role?.name === 'general_admin') {
        const namesAndIds = await getGaAgencyNamesAndIds(user.id)
        const agency = await getAgency(
          namesAndIds.data ? (namesAndIds.data[0]?.id as string) : '',
        ).unwrap()
        dispatch(updateLoggedInAgencyId(agency.id))
        if (agency.isMsp) {
          dispatch(push(routeMap.get('agencyadmin') as string))
        } else {
          dispatch(push(routeMap.get('agencyuser') as string))
        }
      } else {
        dispatch(updateLoggedInAgencyId(user.agencyId))
        if (roleData.isNonMspAgency) {
          dispatch(push(routeMap.get('agencyuser') as string))
        } else {
          dispatch(push(routeMap.get(roleData.role?.name as string) as string))
        }
      }
    },
    [dispatch, getAgency, getGaAgencyNamesAndIds],
  )

  const handleSubmit = useCallback(
    async function (emailAddress: string, password: string) {
      try {
        setIsLoading(true)
        await signin({emailAddress: emailAddress, password: password})
          .unwrap()
          .then(fetchUserDetails)
          .then(checkIfMoreThanOneRole)
          .then(goToDashboard)
      } catch (error: unknown) {
        console.log('error', error)
        setIsLoading(false)
        dispatch(push(BASE_ROUTES.SIGNIN))
      }
    },
    [signin, fetchUserDetails, checkIfMoreThanOneRole, goToDashboard, dispatch],
  )

  useEffect(() => {
    if (!isLoading) {
      if (tempEmail && tempPassword) {
        void handleSubmit(tempEmail, tempPassword)
      } else if (Object.keys(paramValues).length > 0) {
        void handleSubmit(paramValues.emailAddress as string, paramValues.password as string)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, tempEmail, tempPassword])

  return (
    <LoadingBoundary isLoading={isLoading}>
      <SignInContainer
        customHeader="Welcome Back"
        customFooter={
          <NavLink to={BASE_ROUTES.CREATE_ACCOUNT_PORTAL}>
            <span>
              Don't have an account yet? <u>Create Account</u>
            </span>
          </NavLink>
        }>
        <ExpiredPasswordDialog
          isModalOpen={expiredPasswordDialog}
          onModalClose={() => {
            setExpiredPasswordDialog(false)
          }}
        />
        <FormTitle>Sign In</FormTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            email: '',
            password: '',
            rememberMe: false,
          }}
          validationSchema={Yup.object({
            password: Yup.string().required(errorText.passwordRequired),
            email: Yup.string().email(errorText.emailInvalid).required(errorText.emailRequired),
          })}
          onSubmit={async function (values: {email: string; password: string}) {
            void handleSubmit(values.email, values.password)
          }}>
          {({isSubmitting}) => (
            <Form>
              <FieldContainer>
                <ErrorBox>{signinError}</ErrorBox>
                <FormikTextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  label="Email"
                  type="email"
                />
                <PasswordFormikTextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="password"
                  label="Password"
                  type="password"
                />
              </FieldContainer>
              <ForgotPWLinkContainer>
                <Checkbox name="rememberMe" label="Remember Me" disabled={isSubmitting} />
                {/* <ForgotPWLink to={BASE_ROUTES.PASSWORD_RESET}>Forgot Password?</ForgotPWLink> */}
              </ForgotPWLinkContainer>
              <Button
                fullWidth
                sx={{mt: 5}}
                disabled={isSubmitting}
                variant="contained"
                type="submit">
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </SignInContainer>
    </LoadingBoundary>
  )
}
