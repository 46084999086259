import React from 'react'

import S from './styles/widget.styles'

interface IProps {
  children: React.ReactNode
}

export function Widget({children, ...props}: IProps) {
  return <S.Container {...props}>{children}</S.Container>
}

Widget.defaultProps = {
  variant: 'custom',
}
