/* eslint-disable @typescript-eslint/no-explicit-any */
import type {SerializedError} from '@reduxjs/toolkit';
import type {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

type TOptionalConstructor =
	| BigIntConstructor
	| BooleanConstructor
	| FunctionConstructor
	| NumberConstructor
	| ObjectConstructor
	| StringConstructor
	| SymbolConstructor
	| null;

export function isNullOrUndefined(arg: any): arg is null | undefined {
	return arg === null || arg === undefined;
}

export function getConstructor(arg: any): TOptionalConstructor {
	return isNullOrUndefined(arg) ? null : (arg as {constructor: TOptionalConstructor}).constructor;
}

export function isObject(arg: any): arg is Record<string, unknown> {
	return getConstructor(arg) === Object;
}

export function isArray(arg: any): arg is never[] {
	return Array.isArray(arg);
}

export function isString(arg: any): arg is string {
	return getConstructor(arg) === String;
}

export function isNumber(arg: any): arg is number {
	return getConstructor(arg) === Number;
}

export function isEmptyObject(arg: any): arg is Record<string, unknown> {
	return isObject(arg) && Object.keys(arg).length === 0;
}

export function isEmptyArray(arg: any): arg is never[] {
	return isArray(arg) && (arg as Array<unknown>).length === 0;
}

export function isEmptyString(arg: any): arg is string {
	return isString(arg) && arg.length === 0;
}

export function isEmpty(arg: any): arg is Record<any, never> | never[] | null | undefined | never {
	return isEmptyObject(arg) || isEmptyArray(arg) || isEmptyString(arg) || isNullOrUndefined(arg);
}

export function isPromise<T>(arg: any): arg is never {
	return !isNullOrUndefined(arg) && typeof (arg as Promise<T>).then === 'function';
}

export function isSerializedError(error: unknown): error is SerializedError {
  return isObject(error) && 'name' in error
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return isObject(error) && 'status' in error
}
