export const errorMessages = {
  companyExists: `Company name already exists. Please choose another.`,
  required: `Please fill out this field.`,
  alreadyTaken: `Sorry, that name has already been taken. Please try another name.`,
  emailRequired: `Please enter an email address.`,
  passwordRequired: `Please enter a password.`,
  confirmPasswordRequired: `Please confirm your password.`,
  tooSimilarToOldPassword: `Your password is too similar to your other information.`,
  weakPassword: `Your password is too weak.`,
	passwordsDontMatch: `Your passwords don't match.`,
}
