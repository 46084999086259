import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQuery} from '../base-query'

import type {
  IAddGeneralAdminRequest,
  IPaginationArgs,
  ISuccessMessage,
  IUpdateGeneralAdminRequest,
  IUserBase,
  IUserAndEmail,
  TAddMemberPayload,
  TEditMemberPayload,
  TOnboardPayload,
  TUsersPayload,
} from '../../models'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUsersByCompanyId: builder.query<IUserBase[], string>({
      providesTags: ['User'],
      query: (companyId) => ({
        url: `/User/UsersByCompanyId/${companyId}`,
        method: 'GET',
      }),
    }),

    getUsersByCompanyIdPagination: builder.query<
      TUsersPayload,
      IPaginationArgs & {companyId: string}
    >({
      providesTags: ['User'],
      query: ({pageNumber, pageSize, companyId}) => ({
        url: `/User/UsersByCompanyIdPagination`,
        method: 'GET',
        params: {pageNumber, pageSize, companyId},
      }),
    }),

    getUsersSortedByName: builder.query<
      TUsersPayload,
      IPaginationArgs & {
        role?: string
        matchingRoles?: boolean
        agencyId?: string
      }
    >({
      providesTags: ['User'],
      query: ({pageNumber, pageSize, role, matchingRoles, agencyId}) => ({
        url: `/User`,
        method: 'GET',
        params: {pageNumber, pageSize, role, matchingRoles, agencyId, application: 'MSP'},
      }),
      // TODO: instead of doing this just add a temporary full name prop to the user.
      transformResponse: ({users, totalUsers}) => {
        return {
          totalUsers: totalUsers,
          users: users.sort((a: IUserBase, b: IUserBase) =>
            `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1,
          ),
        }
      },
    }),

    getUserByEmail: builder.query<IUserBase | undefined, string>({
      providesTags: ['User'],
      query: (email) => ({
        url: `/user/userByEmail/${email}`,
        method: 'GET',
      }),
    }),

    getUsersByEmails: builder.query<IUserAndEmail[], string[]>({
      providesTags: ['User'],
      query: (emails) => ({
        url: `/user/usersByEmails`,
        method: 'POST',
        body: emails,
      }),
    }),

    createUser: builder.mutation<ISuccessMessage, TOnboardPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/OnboardUser`,
        method: 'POST',
        body: body,
      }),
    }),

    addPlatformAdmin: builder.mutation<ISuccessMessage, TAddMemberPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/OnboardPlatformAdmin`,
        method: 'POST',
        body: body,
      }),
    }),

    addCompanyAdmin: builder.mutation<ISuccessMessage, TAddMemberPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/OnboardCompanyAdmin`,
        method: 'POST',
        body: body,
      }),
    }),

    addAgencyAdmin: builder.mutation<ISuccessMessage, TAddMemberPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/OnboardAgencyAdmin`,
        method: 'POST',
        body: body,
      }),
    }),

    addGeneralAdmin: builder.mutation<IUserBase, IAddGeneralAdminRequest>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/OnboardGeneralAdmin`,
        method: 'POST',
        body: body,
      }),
    }),

    updateGeneralAdmin: builder.mutation<ISuccessMessage, IUpdateGeneralAdminRequest>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User/UpdateGeneralAdmin`,
        method: 'PUT',
        body: body,
      }),
    }),

    updateMember: builder.mutation<ISuccessMessage, TEditMemberPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User`,
        method: 'PUT',
        body: body,
      }),
    }),

    updateUser: builder.mutation<ISuccessMessage, TOnboardPayload>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `/User`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
})

export const {
  useCreateUserMutation,
  useGetUsersByCompanyIdPaginationQuery,
  useGetUsersByCompanyIdQuery,
  useGetUsersSortedByNameQuery,
  useGetUsersByEmailsQuery,
  useLazyGetUserByEmailQuery,
  useLazyGetUsersByCompanyIdPaginationQuery,
  useLazyGetUsersByCompanyIdQuery,
  useUpdateUserMutation,
  useAddPlatformAdminMutation,
  useAddGeneralAdminMutation,
  useUpdateGeneralAdminMutation,
  useAddCompanyAdminMutation,
  useAddAgencyAdminMutation,
  useUpdateMemberMutation,
} = userApi
