import {Grid, MenuItem} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import HeaderContainer from '@ui/header-container/HeaderContainer'
import Mui from '@ui/mui'
import {push} from 'connected-react-router'
import {Formik, Form as FormikForm} from 'formik'
import _ from 'lodash'
import {nanoid} from 'nanoid/non-secure'
import React, {useCallback, useMemo, useState} from 'react'
import * as Yup from 'yup'
import 'yup-phone'
import type {IAddress, ICompanyBase} from '~/src/models'
import type {IBaseAddress} from '~/src/models/baseAddress'
import type {ICompensationCode} from '~/src/models/company'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetAgenciesByMspStatusQuery} from '~/src/store/apis/agency-api'
import {useCreateCompanyMutation, useUpdateCompanyMutation} from '~/src/store/apis/company-api'
import {useLazyGetUserByEmailQuery} from '~/src/store/apis/user-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import FormikTextField from '../../ui/mui-text-field/FormikTextField'
import AddressAutoComplete from './AddressAutoComplete'
import CompanyAddressList from './CompanyAddressList'
import CompensationCodeList from './CompensationCodeList'
import createAddressItem from './helpers/createAddress'

export type TActivePlatform = 'Company' | 'Agency'

const RightColumn = styled('div')({
  textAlign: 'left',
  justifyContent: 'left',
})
const ImageSubText = styled('div')({
  marginBottom: 20,
  textAlign: 'left',
  color: 'grey',
})

const ImageBox = styled(Box)({
  width: '200px',
  height: '50px',
  maxWidth: '200px',
  maxHeight: '50px',
  backgroundColor: 'rgba(137, 196, 244, 0.5)',
  border: '1px, solid, lightBlues',
  marginBottom: 20,
})

const CompanyLogo = styled('div')({
  marginBottom: 20,
  textAlign: 'left',
  paddingLeft: '80px',
})

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const Form = styled(FormikForm)({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '7fr 3fr',
})

export const createCompensationCodeItem = () => ({
  id: nanoid(),
  code: '',
  state: '',
  // markUpRate: undefined,
  // carryOverRate: undefined,
})

export interface IFormValues {
  addresses: IAddress[]
  noEmployees: number
  billingAddress: IBaseAddress
  adminEmail: string
  adminFirstName: string
  adminLastName: string
  companyName: string
  compensationCodes: ICompensationCode[]
  phoneNumber: string
  companyWebsite: string | null
  agencyId: string
}

interface IProps {
  activePlatform: TActivePlatform
  initialEditValues?: ICompanyBase
  agency: any
}

export default function AddCompanyForm({activePlatform, initialEditValues, agency}: IProps) {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const {companyTypeMaps} = useAppSelector((state) => state.root)
  const dispatch = useAppDispatch()
  const [findEmail] = useLazyGetUserByEmailQuery()

  const nonMspAgencies = useGetAgenciesByMspStatusQuery('true')

  const multipleAddresses = initialEditValues?.addresses?.map((i) => {
    return {
      id: i.id,
      name: i.name,
      isOnsite: Boolean(i.isOnsite),
      address: {
        line1: i.address.line1,
        city: i.address.city,
        state: i.address.state,
        zipCode: i.address.zipCode,
        display: i.address.display,
        location: Yup.object({
          type: Yup.string(),
          coordinates: Yup.array().of(Yup.number()),
        }).required('Required'),
      },
    }
  })

  const agencyName = agency ? agency?.name : ''

  // const formatCompCodeArrayForFormik = (
  //   compArray: ICompensationCode[],
  // ): IPreFormattedFormikCompCodeItem[] => {
  //   const retArray = compArray.map((compItem) => {
  //     const retItem = {
  //       ...compItem,
  //       markUpRate: `${compItem.markUpRate?.toString()}%`,
  //     } as IPreFormattedFormikCompCodeItem
  //     if (compItem.carryOverRate) {
  //       retItem.carryOverRate = `${compItem.carryOverRate.toString()}%`
  //     }
  //     return retItem
  //   })
  //   return retArray
  // }

  const initialValues: IFormValues = initialEditValues
    ? {
        companyName: initialEditValues.name,
        noEmployees: initialEditValues.noEmployees,
        phoneNumber: initialEditValues.phoneNumber,
        companyWebsite: initialEditValues.companyWebsite,
        addresses: multipleAddresses,

        billingAddress: {
          line1: initialEditValues.billingAddress.line1,
          city: initialEditValues.billingAddress.city,
          state: initialEditValues.billingAddress.state,
          zipCode: initialEditValues.billingAddress.zipCode,
          display: initialEditValues.billingAddress.display,
        },
        adminFirstName: initialEditValues.adminFirstName,
        adminLastName: initialEditValues.adminLastName,
        adminEmail: initialEditValues.adminEmail,
        compensationCodes:
          initialEditValues?.compensationCodes.length > 0
            ? initialEditValues.compensationCodes // formatCompCodeArrayForFormik(initialEditValues.compensationCodes)
            : [createCompensationCodeItem()],
        agencyId: initialEditValues.agencyId,
      }
    : {
        companyName: '',
        noEmployees: 1,
        phoneNumber: '',
        companyWebsite: null,
        addresses: [createAddressItem()],
        billingAddress: {
          line1: '',
          city: '',
          state: '',
          zipCode: '',
          display: '',
        },
        adminFirstName: '',
        adminLastName: '',
        adminEmail: '',
        compensationCodes: [createCompensationCodeItem()],
        agencyId: '',
      }
  const [agencyChange, setAgencyChange] = useState<string>(
    agency ? agency.id : initialValues?.agencyId,
  )

  // interface IPreFormattedFormikCompCodeItem {
  //   id: string
  //   code: string
  //   state: string
  //   markUpRate: string
  //   carryOverRate?: string
  // }

  // const formatCompCodeArrayForJson = (
  //   compArray: IPreFormattedFormikCompCodeItem[],
  // ): ICompensationCode[] => {
  //   const retArray = compArray.map((compItem) => {
  //     const retItem = {
  //       ...compItem,
  //       markUpRate: Number(compItem.markUpRate.slice(0, -1)),
  //     } as ICompensationCode
  //     if (compItem.carryOverRate) {
  //       retItem.carryOverRate = Number(compItem.carryOverRate.slice(0, -1))
  //     }
  //     return retItem
  //   })
  //   return retArray
  // }

  const [addCompany, {error}] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()
  const handleSubmit = async (values: IFormValues) => {
    if (initialEditValues) {
      await updateCompany({
        ...values,
        companyWebsite: values?.companyWebsite?.length > 0 ? values.companyWebsite : null,
        departments: [],
        name: values.companyName,
        noEmployees: Number(values.noEmployees),
        versionKey: initialEditValues.versionKey,
        id: initialEditValues.id,
        typeId: companyTypeMaps ? companyTypeMaps.nameToId[activePlatform] : '',
        // compensationCodes: formatCompCodeArrayForJson(
        //   values.compensationCodes as unknown as IPreFormattedFormikCompCodeItem[],
        // ),
      }).unwrap()
      dispatch(
        push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_COMPANIES}`, {
          state: {openSnackbar: true, name: values.companyName, type: 'company'},
        }),
      )
    } else {
      await addCompany({
        ...values,
        companyWebsite: values?.companyWebsite?.length > 0 ? values.companyWebsite : null,
        departments: [],
        name: values.companyName,
        noEmployees: Number(values.noEmployees),
        typeId: companyTypeMaps ? companyTypeMaps.nameToId[activePlatform] : '',
        // compensationCodes: formatCompCodeArrayForJson(
        //   values.compensationCodes as unknown as IPreFormattedFormikCompCodeItem[],
        // ),
      }).unwrap()
      dispatch(push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_COMPANIES}`))
    }
  }

  const validateEmail = useCallback(
    async function (
      value: string | undefined,
      resolve: (value: boolean) => void,
      initialEditValues?: ICompanyBase,
    ) {
      if (initialEditValues?.adminEmail === value) {
        resolve(true)
      } else {
        try {
          const alreadyExists = await findEmail(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  //TODO uncomment when we have backend to support images
  // const [imageToResize, setImageToResize] = useState(undefined)
  // const [resizedImage, setResizedImage] = useState(undefined)

  // const onSelectFile = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setImageToResize(event.target.files[0])
  //   }
  // }
  const handleCancel = () => {
    dispatch(push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_COMPANIES}`))
  }

  return (
    <HeaderContainer
      header={
        <div style={{fontWeight: 'bold'}}>
          {initialEditValues ? 'Edit Company' : 'Add New Company'}
        </div>
      }>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object({
          adminEmail: Yup.string()
            .email('Email address is invalid.')
            .test(
              'already-exists',
              'This email is already in use',
              (value) =>
                new Promise((resolve) => debouncedValidateEmail(value, resolve, initialEditValues)),
            )
            .required('Required'),
          adminFirstName: Yup.string().required('Required'),
          adminLastName: Yup.string().required('Required'),
          phoneNumber: Yup.string()
            .required('Required')
            .phone('US', true, 'Phone number is invalid.'),
          companyWebsite: Yup.string().nullable(),
          compensationCodes: Yup.array().of(
            Yup.object({
              code: Yup.string().required('Required'),
              state: Yup.string().required('Required'),
              // markUpRate: Yup.string()
              //   .matches(/[0-9]%/, 'Invalid')
              //   .required('Required'),
              // carryOverRate: Yup.string().matches(/[0-9]%/, 'Invalid'),
            }),
          ),
          agencyId: Yup.string().required('Required'),
          addresses: Yup.array().of(
            Yup.object({
              name: Yup.string().required('Required'),
              isOnsite: Yup.boolean().nullable().required('Required'),
              address: Yup.object({
                line1: Yup.string(),
                city: Yup.string(),
                state: Yup.string(),
                zipCode: Yup.string(),
                display: Yup.string().required('Required'),
                location: Yup.object({
                  type: Yup.string(),
                  coordinates: Yup.array().of(Yup.number().required('Required')),
                }),
              }),
            }),
          ),
          billingAddress: Yup.object({
            line1: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
            display: Yup.string().required('Required'),
          }),

          companyName: Yup.string().required('Required'),
        })}>
        {({isSubmitting, setFieldValue, values, handleChange}) => {
          return (
            <Form>
              <Grid container columnSpacing={2} rowSpacing={4}>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="companyName"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  {activePlatform} Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="companyName" inputProps={{maxLength: 50}} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="phoneNumber"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  {activePlatform} Phone<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="phoneNumber" placeholder="1 123 456 7890" type="tel" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="companyWebsite"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  {activePlatform} Website:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="companyWebsite" placeholder="CompanyName.com" />
                </Grid>
                <Grid container item xs={12} pb={2}>
                  <Grid item xs={3} />
                  <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                  <CompanyAddressList
                    values={values}
                    initialEditValues={initialEditValues}
                    setFieldValue={setFieldValue}
                    activePlatform={activePlatform}
                  />
                </Grid>
                <Grid container item xs={12} pb={2}>
                  <Grid item xs={3} />
                  <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                  <CompensationCodeList values={values} />
                </Grid>
                <Grid container item xs={12} pb={2}>
                  <Grid item xs={3} />
                  <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="billingAddress"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Assigned Agency<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    select
                    name="agencyId"
                    id="agencyId"
                    defaultValue={agencyChange}
                    value={agencyChange ? agencyChange : agencyName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAgencyChange(e.target.value)
                      handleChange(e)
                    }}>
                    {nonMspAgencies.data?.map((companyRow, indexCompany) => {
                      return (
                        <MenuItem value={companyRow.id} key={indexCompany}>
                          {companyRow.name}
                        </MenuItem>
                      )
                    })}
                  </FormikTextField>
                </Grid>

                <Grid container item xs={12} pb={2}>
                  <Grid item xs={3} />
                  <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="billingAddress"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Billing Address<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <AddressAutoComplete
                    address={values.billingAddress}
                    initialEditValues={initialEditValues}
                    setFieldValue={setFieldValue}
                    activePlatform={activePlatform}
                    name="billingAddress"
                  />
                </Grid>
                <Grid container item xs={12} pb={2}>
                  <Grid item xs={3} />
                  <Grid item xs={9} component={Mui.Divider} variant="fullWidth" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminFirstName"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin First Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    name="adminFirstName"
                    placeholder="Sample First Name"
                    inputProps={{maxLength: 50}}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminLastName"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin Last Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    name="adminLastName"
                    placeholder="Sample Last Name"
                    inputProps={{maxLength: 50}}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminEmail"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin Email<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="adminEmail" placeholder="sample.email@company.com" />
                </Grid>
                <Grid item xs={3} sx={{pr: '20px'}} justifyContent="end" />
                <Grid item xs={9} mt={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting}
                    sx={{mr: 'auto'}}
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ml: 2}}>
                    Publish
                  </Button>
                </Grid>
              </Grid>
              {/* //TODO uncomment when we have backend to support images */}
              {/* <RightColumn>
                <CompanyLogo>Company Logo: {imageToResize?.name}</CompanyLogo>
                <div style={{marginLeft: '80px'}}>
                  <ImageBox>
                    {resizedImage && <img width="200" height="50" src={resizedImage} />}
                  </ImageBox>

                  <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onSelectFile}
                  />
                  <ImageSubText>
                    *images will try to be resized to 200px (W) x 50px (H) on upload.*
                  </ImageSubText>
                  <label htmlFor="contained-button-file">
                    <Fab component="span">
                      <div>
                        <ImageResizerComponent
                          imageToResize={imageToResize}
                          onImageResized={(resizedImage) => setResizedImage(resizedImage)}
                        />
                      </div>

                      <AddPhotoAlternateRoundedIcon />
                    </Fab>
                  </label>
                </div>
              </RightColumn> */}
            </Form>
          )
        }}
      </Formik>
    </HeaderContainer>
  )
}

AddCompanyForm.defaultProps = {
  title: '',
}
