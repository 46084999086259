import Box from '@mui/material/Box'
import {css, styled} from '@mui/material/styles'
import {formatShift} from '@utils'
import {push} from 'connected-react-router'
import React from 'react'
import {useDispatch} from 'react-redux'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import ClickableDateGroup from './ClickableDateGroup'

const PositionText = styled('div')(
  ({theme}) => css`
    color: #707070;
    color: ${theme.palette.common.white};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

const SecondaryText = styled('div')(
  ({theme}) => css`
    color: #707070;
    font-size: 18px;
    font-weight: 700;
    color: ${theme.palette.common.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

const AdditionalDetailsText = styled('div')(
  ({theme}) => css`
    font: normal 400 11.5px 'Open Sans';
    color: ${theme.palette.common.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

const StatusBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    background: #fafafa;
    border-radius: 10px;
    font: normal 600 15px 'Open Sans';
    color: ${theme.palette.grey[900]};
    padding: 7px;
    cursor: pointer;
  `,
)

const ContainerBox = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  height: '274px',
  width: '260px',
  borderRadius: '16px',
  padding: '15px',
  marginRight: '23px',
  marginBottom: '23px',
  boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, 0.05)',
})) as typeof Box

interface IProps {
  currentUserAgencyId?: string
  currentServiceOrderPageNumber?: number
  jobOrderId?: string | undefined
  isCompany?: boolean
  agencyStatus?: string
  isdashboard?: boolean
  serviceOrder: IServiceOrder
  callback?: (date: Date) => void
  sx?: object
}

export default function ServiceOrderCardMsp({
  currentUserAgencyId,
  currentServiceOrderPageNumber,
  jobOrderId,
  agencyStatus,
  isdashboard = false,
  serviceOrder,
  callback = () => {},
  sx,
}: IProps) {
  const dispatch = useDispatch()
  const {
    orders: positions,
    id: serviceOrderId,
    orderNumber: serviceOrderNumber,
    mspStatus: status,
    companyId,
    createdBy,
    createdDate,
    agencyId,
  } = serviceOrder
  const {data: positionData} = useGetPositionByIdQuery({
    id: positions[0]?.positionId as string,
  })
  const {data: companyName} = useGetCompanyNameByIdQuery({id: companyId})
  const shift = formatShift(positionData)
  const isMsp = currentUserAgencyId === agencyId

  const redirectToServiceOrderDetails = () => {
    if (isdashboard && isMsp) {
      dispatch(
        push(`/agency-admin/manage-orders/pending/${serviceOrderId}`, {
          serviceOrderId: serviceOrderId,
          currentPageNumber: currentServiceOrderPageNumber,
          compName: companyName,
          currentUserAgencyId: currentUserAgencyId,
        }),
      )
    } else if (!isdashboard && isMsp) {
      dispatch(
        push(`/agency-admin/manage-orders/${serviceOrderId}`, {
          serviceOrderId: serviceOrderId,
          currentPageNumber: currentServiceOrderPageNumber,
          compName: companyName,
          currentUserAgencyId: currentUserAgencyId,
        }),
      )
    } else {
      dispatch(
        push(`/agency/manage-orders/${serviceOrderId}`, {
          currentPageNumber: currentServiceOrderPageNumber,
          compName: companyName,
        }),
      )
    }
  }

  const redirectToJobOrderDetails = () => {
    dispatch(push(`/agency-admin/manage-job-orders/${serviceOrderId}`))
  }

  const redirectToPendingOrder = () => {
    dispatch(push(`/agency-admin/manage-orders/pending/${serviceOrderId}`))
  }

  const redirectToJobOrderCandidates = () => {
    if (jobOrderId) {
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${
            SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
          }/${encodeURIComponent(jobOrderId)}?showDetails=false`,
        ),
      )
    }
  }

  const redirectToJobOrderSummary = () => {
    if (jobOrderId) {
      dispatch(
        push(
          `${BASE_ROUTES.AGENCY_ADMIN}${
            SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
          }/${encodeURIComponent(jobOrderId)}?showDetails=true`,
        ),
      )
    }
  }

  const positionDates = positions[0]?.orderDistribution.orderQuantityDistributions.map(
    (item) => new Date(item.date),
  ) as Date[]

  const handleDateGroupCallback = (index: number) => {
    callback(positionDates[index] as Date)
  }

  const projectAgencyButtonDisplay = () => {
    switch (agencyStatus) {
      case 'New Request':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            {agencyStatus}
          </StatusBox>
        )

      case 'Confirming':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            {agencyStatus}
          </StatusBox>
        )
      case 'Ready To Confirm':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            Confirming
          </StatusBox>
        )

      case 'Confirmed':
        return (
          <StatusBox
            style={isdashboard ? {background: '#FFF', border: '1px solid #333333'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            {agencyStatus}
          </StatusBox>
        )
      case 'Filling Order':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToJobOrderSummary}>
            {agencyStatus}
          </StatusBox>
        )

      default:
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            Confirmed
          </StatusBox>
        )
    }
  }

  const projectButtonDisplay = () => {
    switch (status) {
      case 'New Order':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            {status}
          </StatusBox>
        )
      case 'Preparing Pledges':
        return (
          <StatusBox
            style={isdashboard ? {background: '#FFB946'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            {status}
          </StatusBox>
        )
      case 'Ready To Distribute':
        return (
          <StatusBox
            style={isdashboard ? {background: '#FFB946'} : undefined}
            onClick={redirectToServiceOrderDetails}>
            Preparing Pledges
          </StatusBox>
        )
      case 'Waiting Confirmation':
        return (
          <StatusBox
            style={{background: isdashboard ? '#FFF' : undefined, border: '1px solid #333333'}}
            onClick={redirectToServiceOrderDetails}>
            {status}
          </StatusBox>
        )
      case 'Pledges Received':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToPendingOrder}>
            Pledges Received
          </StatusBox>
        )
      case 'Configuring Job Order':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToJobOrderDetails}>
            Configuring <span style={{marginLeft: 7}}>Job Order</span>
          </StatusBox>
        )
      case 'Filling Order':
        if (jobOrderId) {
          return (
            <StatusBox
              style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
              onClick={redirectToJobOrderSummary}>
              {status}
            </StatusBox>
          )
        } else {
          return (
            <StatusBox
              style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
              onClick={redirectToJobOrderDetails}>
              {status}
            </StatusBox>
          )
        }
      case 'Filled':
        return (
          <StatusBox
            style={isdashboard ? {background: '#03A9FC', color: 'white'} : undefined}
            onClick={redirectToJobOrderCandidates}>
            {status}
          </StatusBox>
        )

      default:
        return null
    }
  }

  return (
    <>
      <div
        style={{
          position: isdashboard ? 'relative' : undefined,
        }}>
        <ContainerBox sx={{backgroundColor: isdashboard ? 'white' : '', ...sx}}>
          <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <PositionText style={{color: isdashboard ? '#707070' : ''}}>
              {positionData?.name}
            </PositionText>
            <SecondaryText style={{color: isdashboard ? '#707070' : ''}}>{shift}</SecondaryText>
            <SecondaryText
              style={{
                color: isdashboard ? '#707070' : '',
              }}>{`Service Order #${serviceOrderNumber}`}</SecondaryText>
            <AdditionalDetailsText
              style={{color: isdashboard ? '#424242' : '', marginBottom: isMsp ? '' : 40}}>
              Created Date: {new Date(createdDate).toLocaleDateString('En-US')}
            </AdditionalDetailsText>
            {isMsp ? (
              <>
                <AdditionalDetailsText style={{color: isdashboard ? '#424242' : ''}}>
                  Created By: {createdBy}
                </AdditionalDetailsText>
                <AdditionalDetailsText style={{color: isdashboard ? '#424242' : ''}}>
                  Client: {companyName}
                </AdditionalDetailsText>
                <AdditionalDetailsText style={{color: isdashboard ? '#424242' : ''}}>
                  Manager: {positionData?.manager}
                </AdditionalDetailsText>
              </>
            ) : (
              <>
                <div />
              </>
            )}
          </Box>
          <ClickableDateGroup
            count={4}
            dateArray={positionDates}
            callback={handleDateGroupCallback}
            isdashboard={isdashboard}
            sx={{position: 'relative', left: '-32px'}}
          />
          {agencyStatus ? projectAgencyButtonDisplay() : projectButtonDisplay()}
        </ContainerBox>
      </div>
    </>
  )
}
