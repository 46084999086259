import {createSlice, current} from '@reduxjs/toolkit'
import type {TRootState} from '..'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  companyCalendar: [],
  agencyCalendar: [],
}

const calendarSlice = createSlice({
  name: 'calendarSlice',
  initialState,
  reducers: {
    initializeCalendar: (state, {payload}) => {
      state = initialState
    },
    updateCalendar: (state, {payload}) => {
      const {data, index} = payload
      if (index || index === 0) {
        const currentState = [...state.companyCalendar]
        currentState[index] = data
        state.companyCalendar = currentState
      } else {
        state.companyCalendar = data
      }
    },
    initializeAgencyCalendar: (state, {payload}) => {
      state.agencyCalendar = payload
    },
    updateAgencyCalendar: (state, {payload}) => {
      state.agencyCalendar.calendarWidgets[payload.widgetIndex].agencies[payload.rowIndex].days[
        payload.dayIndex
      ].count = payload.value
    },
    updateAgencies: (state, {payload}) => {
      const payloadIds = payload.map((agency) => agency.id)
      const reduxIds = state.agencyCalendar.calendarWidgets[0].agencies.map((agency) => agency.id)
      const idsToAdd = payloadIds.filter((x) => !reduxIds.includes(x))
      const idsToRemove = reduxIds.filter((x) => !payloadIds.includes(x))

      idsToAdd.forEach((idToAdd) => {
        const agencyToAdd = payload.find((agency) => {
          return agency.id === idToAdd
        })
        state.agencyCalendar.calendarWidgets.forEach((widget) => {
          widget.agencies.push({
            name: agencyToAdd.name,
            id: agencyToAdd.id,
            days: widget.dateArray.map((date) => ({
              date: date,
              count: null,
              pledged: null,
            })),
          })
        })
      })

      idsToRemove.forEach((idToRemove) => {
        state.agencyCalendar.calendarWidgets.forEach((widget) => {
          const index = current(widget.agencies).findIndex((agency) => {
            return agency.id === idToRemove
          })
          widget.agencies.splice(index, 1)
        })
      })
    },
  },

  extraReducers: (builder) => {},
})

export const {
  initializeCalendar,
  updateCalendar,
  initializeAgencyCalendar,
  updateAgencyCalendar,
  updateAgencies,
} = calendarSlice.actions

export const selectCalendarState = (state: TRootState): TRootState['calendar'] => state.calendar
export default calendarSlice.reducer
