/* eslint-disable complexity */
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import InfoIcon from '@mui/icons-material/Info'
import {Button, Grid, InputAdornment, styled} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import {useTheme} from '@mui/material/styles'
import Checkbox from '@ui/checkbox/FormikCheckbox'
import {FormikSelect, Icon, MenuItem} from '@ui/components'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import Mui from '@ui/mui'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {formatShift, formatShiftAlternate} from '@utils'
import type {FormikValues} from 'formik'
import React, {Suspense, lazy, useEffect, useState} from 'react'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useGetPositionQuery} from '~/src/store/apis/position-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppSelector} from '~/src/store/store-hooks'
import S from '../../positions/styles/AddDepartmentForm.styles'
import SelectPositionDialog from '../Dialogs/SelectPositionDialog'
const CalendarOrderDialog = lazy(() => import('../Calendar/CalendarOrderDialog'))

// const MarkUpAdornment = styled(Box)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-left: 1px solid rgba(0, 0, 0, 0.23);
//   height: 38px;
//   width: 120px;
// `

interface IProps {
  errors: any
  values: FormikValues
  initialValues?: IInitialServiceOrderValues
  isEdit: boolean
  index: number
  onModalClose(): void
  onOpenOrderModal(): void
  modalOpen: boolean
  currentPageNumber: number
  setFieldValue: (field: string, value: unknown) => void
  handleChange(): void
  formikRef: string | number | boolean | object | null
  isTemplate: boolean
}

export default function AddPositionItem(props: IProps) {
  const {
    index,
    setFieldValue,
    handleChange,
    formikRef,
    values,
    isEdit,
    initialValues,
    errors,
    isTemplate,
    isMsp,
  } = props
  const theme = useTheme()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [orderModalVisible, setOrderModalVisible] = useState(false)
  const [calendarModalVisible, setCalendarModalVisible] = useState(false)
  const [selectedPositionName, setSelectedPositionName] = useState<string>(
    initialValues?.position?.positionName,
  )
  const [selectedPositionId, setSelectedPositionId] = useState<string>()
  const [isMultiDayOrder, setIsMultiDayOrder] = useState(
    values?.orders[0]?.distributionStartDate !== values?.orders[0]?.distributionEndDate,
  )
  const [removePositionVisible, setRemovePositionVisible] = useState(false)
  const startDate = formikRef.current?.values?.orders[index]?.distributionStartDate
  const endDate = isMultiDayOrder
    ? formikRef.current?.values?.orders[index]?.distributionEndDate
    : formikRef.current?.values?.orders[index]?.distributionStartDate
  const {currentUser} = useCurrentUser()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: dataPosition, refetch: refetchPosition} = useGetPositionQuery({
    pageNumber: currPageIdx,
    pageSize: 10000,
    agencyId: loggedInAgencyId,
  })
  const usedPositions = values.orders.map((order) => {
    if (order.positionId !== null) return order.positionId
  })

  const getFilteredPositions = () => {
    if (selectedPositionName) {
      return dataPosition?.positionModel
        .filter((position) => position.name === selectedPositionName)
        .filter((position) => !usedPositions.includes(position.id))
    }
    return []
  }

  const filtered = dataPosition?.positionModel.filter(
    (position) => !usedPositions.includes(position.id),
  )

  const uniquePositionNames = [...new Set(filtered?.map((i) => i.name))]

  const {data: currentCompany} = useGetOneCompanyQuery(currentUser.companyId)

  const handleDialog = (positionId: string) => {
    setSelectedPositionId(positionId)
    setFieldValue(`orders.${index}.positionId`, positionId)
  }

  const selectedPosition = selectedPositionId
    ? dataPosition?.positionModel.find((item) => item.id === selectedPositionId)
    : undefined

  const getCalendarParams = () => {
    const retObj = {
      startDate: '',
      endDate: '',
      company: '',
      position: '',
      shift: '',
    }
    if (selectedPosition) {
      retObj.shift = formatShift(selectedPosition)
      retObj.position = selectedPosition.name
    }

    retObj.startDate = values.orders[index].distributionStartDate
    retObj.endDate = values.orders[index].distributionEndDate

    if (currentCompany) {
      retObj.company = currentCompany.name
    }

    return retObj
  }

  const handleOpenCalendar = () => {
    setCalendarModalVisible(true)
  }

  const isCalendarDisabled =
    !values.orders[index].distributionStartDate || !values.orders[index].distributionEndDate

  const getTotalCandidates = (total: number) => {
    setFieldValue(`orders.${index}.quantity`, total)
  }

  const handleCloseCalendar = (total: number) => {
    setCalendarModalVisible(false)
    getTotalCandidates(total)
  }

  const handleMultiDayOrderCheck = () => {
    setFieldValue(`orders.${index}.quantity`, '')
    setIsMultiDayOrder(!isMultiDayOrder)
    if (!isMultiDayOrder) {
      setFieldValue(`orders.${index}.quantity`, '')
      setFieldValue(
        `orders.${index}.distributionEndDate`,
        values.orders[index].distributionStartDate,
      )
    }
  }

  const handleStartDateSelect = () => {
    if (!isMultiDayOrder) {
      setFieldValue(
        `orders.${index}.distributionEndDate`,
        values.orders[index].distributionStartDate,
      )
    }
  }

  useEffect(() => {
    if (initialValues?.position?.positionName) {
      setSelectedPositionName(initialValues.position.positionName)
    }
  }, [initialValues])

  // const markUp = currentCompany?.compensationCodes[0]?.markUpRate ?? 0
  // const payRate = values.orders[index].payRate.length > 0 ? Number(values.orders[index].payRate) : 0
  // const billRateUnrounded = (markUp / 100 + 1) * payRate
  // const billRate =
  //   values.orders[index].payRate.length > 0
  //     ? (Math.round((billRateUnrounded + Number.EPSILON) * 100) / 100).toFixed(2)
  //     : ''

  const today = new Date(values.orders[index]?.distributionStartDate)
  return (
    <Box
      sx={{
        m: 5,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      {dataPosition?.positionModel && (
        <SelectPositionDialog
          modalOpen={orderModalVisible}
          setFieldValue={setFieldValue}
          filteredData={getFilteredPositions()}
          companyDepartments={currentCompany?.departments ? currentCompany.departments : []}
          companyAddresses={currentCompany?.addresses ? currentCompany.addresses : []}
          onSelectUpdate={handleDialog}
          onModalClose={() => {
            setOrderModalVisible(false)
          }}
        />
      )}
      <Suspense fallback={<div />}>
        {isMultiDayOrder ? (
          <CalendarOrderDialog
            {...getCalendarParams()}
            values={values}
            selectedPosition={selectedPosition}
            isModalOpen={calendarModalVisible}
            onModalClose={handleCloseCalendar}
            orderIndex={index}
            setFieldValue={setFieldValue}
            index={index}
            orderDistributions={values.orders[index].orderDistribution.orderQuantityDistributions}
          />
        ) : null}
      </Suspense>
      <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs>
          <Box sx={{marginLeft: '75px', marginRight: '50px', marginTop: '30px'}}>
            <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
              <Grid
                item
                xs={2}
                justifyContent="right"
                component="label"
                htmlFor="orderType"
                display="flex"
                alignItems="center"
                sx={{marginRight: '-20px'}}>
                Order <span style={{paddingLeft: '3px'}}>Type</span> <S.Red>*</S.Red>
              </Grid>
              <Grid item xs>
                <FormikSelect
                  InputProps={{
                    readOnly: isMsp,
                  }}
                  defaultValue={initialValues.orders[index].orderType ?? undefined}
                  sx={{width: '100%'}}
                  id="orderType"
                  name={`orders.${index}.orderType`}>
                  <MenuItem value="DirectPlacement">Direct Placement</MenuItem>
                  <MenuItem value="Temp">Temp</MenuItem>
                  <MenuItem value="TempToHire">Temp To Hire</MenuItem>
                  <MenuItem value="Project">Project</MenuItem>
                </FormikSelect>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs paddingBottom={4}>
          <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
            <Grid
              item
              xs
              justifyContent="flex-end"
              component="label"
              htmlFor="position"
              display="flex"
              alignItems="center">
              {index !== 0 && (
                <S.IconButton onClick={() => setRemovePositionVisible(true)}>
                  <Icon icon="close" />
                </S.IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container paddingBottom={1} item xs={24}>
        <Grid item xs={12} />
        <Grid item xs={12} component={Mui.Divider} variant="fullWidth" />
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs>
          <Box sx={{marginLeft: '75px', marginRight: '50px'}}>
            <Grid container direction="column">
              <Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
                <Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="positionId"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Position<S.Red>*</S.Red>
                </Grid>

                <Grid xs item>
                  <FormikTextField
                    select
                    InputProps={{
                      readOnly: isMsp,
                    }}
                    id="positionId"
                    name={`orders.${index}.positionId`}
                    sx={{width: '100%'}}
                    inputProps={{
                      MenuProps: {
                        style: {maxHeight: 300},
                        id: 'id-menu',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                      },
                    }}
                    defaultValue={initialValues?.position.positionName}
                    value={selectedPositionName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedPositionName(e.target.value)
                      handleChange(e)
                    }}>
                    <div
                      style={{
                        backgroundColor: 'white',

                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#707070',
                        marginLeft: 15,
                        width: '310px',
                      }}>
                      <div>Positions Category</div>
                    </div>
                    <div
                      style={{
                        borderTop: 'solid 1px black',
                      }}
                    />
                    <MenuItem
                      style={{display: 'none'}}
                      value={
                        selectedPositionName ? selectedPositionName : values?.position?.positionName
                      }>
                      {selectedPositionName ? selectedPositionName : values?.position?.positionName}
                    </MenuItem>
                    {uniquePositionNames.map((positionRow, indexPosition) => {
                      return (
                        <MenuItem
                          value={positionRow}
                          onClick={setOrderModalVisible}
                          key={indexPosition}>
                          {positionRow}
                        </MenuItem>
                      )
                    })}
                  </FormikTextField>
                </Grid>
              </Grid>
              <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="shift"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Shift
                </Grid>
                <Grid xs={10} item>
                  <FormikTextField
                    defaultValue={
                      initialValues?.position?.positionStartTime
                        ? formatShiftAlternate(
                            initialValues.position.positionStartTime,
                            initialValues.position.positionEndTime,
                          )
                        : undefined
                    }
                    value={
                      selectedPosition
                        ? formatShift(selectedPosition)
                        : initialValues?.position?.positionStartTime
                        ? formatShiftAlternate(
                            initialValues.position.positionStartTime,
                            initialValues.position.positionEndTime,
                          )
                        : ''
                    }
                    name={`orders.${index}.shift`}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder="Shift"
                  />
                </Grid>
              </Grid>

              <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="onSiteFacility"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Facility
                </Grid>
                <Grid item xs={10}>
                  <FormikTextField
                    name={`orders.${index}.onSiteFacility`}
                    defaultValue={
                      initialValues?.facility ? initialValues?.facility[0]?.name : undefined
                    }
                    value={
                      selectedPosition
                        ? currentCompany?.addresses.find(
                            (item) => item.id === selectedPosition.onsiteFacilityId,
                          )?.name
                        : initialValues.position.positionStartTime
                        ? initialValues?.facility[0]?.name
                        : ''
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    placeholder="Facility"
                  />
                </Grid>
              </Grid>
              <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="departmentName"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Department
                </Grid>
                <Grid item xs={10}>
                  <FormikTextField
                    name={`orders.${index}.departmentName`}
                    defaultValue={
                      isEdit
                        ? currentCompany?.departments.find(
                            (item) => item.id === initialValues?.department[index]?.id,
                          )?.name
                        : initialValues?.department && initialValues?.department[0].name
                        ? initialValues?.department[0].name
                        : ''
                    }
                    value={
                      selectedPosition
                        ? selectedPosition?.departmentName
                        : isEdit && index < initialValues.orders.length
                        ? currentCompany?.departments.find(
                            (item) => item.id === initialValues?.department[index]?.id,
                          )?.name
                        : isTemplate && initialValues?.departmentName[0]?.name
                        ? initialValues?.departmentName[0]?.name
                        : ''
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    placeholder="Department Name"
                  />
                </Grid>
              </Grid>
              <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={2}>
                <Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="payRate"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Pay<span style={{paddingLeft: '3px'}}>Rate</span>
                  <S.Red>*</S.Red>
                </Grid>
                <Grid item xs={10}>
                  <FormikTextField
                    name={`orders.${index}.payRate`}
                    defaultValue={
                      isEdit && values.orders.length < 2
                        ? initialValues?.orders[index].payRate
                        : index === 0 && isEdit
                        ? initialValues?.orders[index].payRate
                        : undefined
                    }
                    placeholder="23/hr"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <MarkUpAdornment>{`Mark-up ${currentCompany?.compensationCodes[0]?.markUpRate}%`}</MarkUpAdornment>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
                {/* <Grid item xs={2} />
                <Grid
                  xs={10}
                  item
                  component="label"
                  sx={{
                    color: '#333',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                  }}>
                  {`Bill Rate: ${billRate}`}
                </Grid> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>
          <Box style={{marginTop: 32}}>
            <Grid container direction="column">
              <Box sx={{marginLeft: '40px', paddingRight: '50px'}}>
                <Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
                  <Grid item xs={2} />
                  <Grid
                    xs={10}
                    component="label"
                    sx={{
                      paddingLeft: '10px',
                      color: '#707070',
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}>
                    <InfoIcon
                      sx={{
                        width: '12px',
                        height: '12px',
                        marginRight: '5px',
                        fill: '#03A9FC',
                        position: 'relative',
                        top: '2px',
                      }}
                    />
                    The assignment start and effective date will be the same as the selected
                    distribution date.
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    component="label"
                    htmlFor="distributionStartDate"
                    id="distributionStartDate"
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    sx={{marginRight: '-20px'}}>
                    Distribution <br />
                    Start Date<S.Red>*</S.Red>
                  </Grid>
                  <Grid item xs>
                    <FormikDatePicker
                      name={`orders.${index}.distributionStartDate`}
                      data-testid="datePicker"
                      initialValue={startDate}
                      minDate={new Date()}
                      onClose={handleStartDateSelect}
                    />
                  </Grid>
                  {isMultiDayOrder && (
                    <>
                      <Grid
                        item
                        xs={2}
                        display="flex"
                        justifyContent="right"
                        alignItems="center"
                        id="distributionEndDate"
                        aria-label="distributionEndDate">
                        Distribution <br />
                        End Date<S.Red>*</S.Red>
                      </Grid>
                      <Grid item xs sx={{marginLeft: -3}}>
                        <FormikDatePicker
                          name={`orders.${index}.distributionEndDate`}
                          data-testid="datePickerEndDate"
                          minDate={today.setDate(today.getDate())}
                          initialValue={endDate}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box
                  sx={{
                    marginBottom: '30px',
                    marginLeft: '10px',
                  }}>
                  <Checkbox
                    checked={isMultiDayOrder}
                    name="checkbox"
                    label="Multiple Start Dates"
                    onChange={handleMultiDayOrderCheck}
                  />
                </Box>

                {isMultiDayOrder && (
                  <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                    <Grid
                      item
                      xs={2}
                      justifyContent="right"
                      component="label"
                      htmlFor="candidateDistribution"
                      display="flex"
                      alignItems="center"
                      sx={{marginRight: '-20px'}}>
                      Candidate Distribution <S.Red>*</S.Red>
                    </Grid>
                    <Grid item xs>
                      <Button
                        disabled={isCalendarDisabled}
                        sx={{width: '100%'}}
                        variant="outlined"
                        onClick={handleOpenCalendar}
                        endIcon={<CalendarTodayIcon />}>
                        Open Calendar
                      </Button>
                    </Grid>
                  </Grid>
                )}

                <Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                  <Grid
                    item
                    xs={2}
                    justifyContent="right"
                    component="label"
                    htmlFor="quantity"
                    display="flex"
                    alignItems="center"
                    sx={{marginRight: '-20px'}}>
                    Quantity
                  </Grid>
                  <Grid item xs>
                    <FormikTextField
                      type="number"
                      InputProps={{
                        readOnly: isMultiDayOrder,
                      }}
                      name={`orders.${index}.quantity`}
                      defaultValue={values.orders[index].quantity}
                    />
                  </Grid>
                </Grid>
                <Grid container paddingBottom={4} paddingTop={1} columnSpacing={4} rowSpacing={4}>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent="right"
                    component="label"
                    htmlFor="note"
                    sx={{marginRight: '-20px'}}>
                    Notes
                  </Grid>
                  <Grid item xs>
                    <FormikTextField
                      id="outlined-multiline-flexible"
                      defaultValue={isEdit ? initialValues?.orders.note : undefined}
                      multiline
                      rows={4}
                      name={`orders.${index}.note`}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={24}>
        <Grid item xs={12} />
        <Grid item xs={12} component={Mui.Divider} variant="fullWidth" />
      </Grid>
      <Grid container spacing={2} columns={16}>
        <Grid item xs>
          <Grid
            container
            sx={{marginLeft: 5}}
            paddingBottom={4}
            paddingTop={4}
            columnSpacing={4}
            rowSpacing={4}>
            <Grid item xs>
              <Grid item xs component="label" htmlFor="position" display="flex" alignItems="center">
                <span style={{color: '#8A8A8A'}}> Work Location</span>
              </Grid>
              <Grid xs item>
                {selectedPosition
                  ? currentCompany?.addresses.find(
                      (item) => item.id === selectedPosition.onsiteFacilityId,
                    )?.address.display
                  : initialValues?.position?.positionStartTime
                  ? initialValues.facility[0].address
                  : undefined}
              </Grid>
            </Grid>
            <Grid item xs />
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
            <Grid item xs>
              <Grid item xs component="label" htmlFor="position" display="flex" alignItems="center">
                <span style={{color: '#8A8A8A'}}>Reporting Manager</span>
              </Grid>
              {selectedPosition
                ? selectedPosition.manager
                : initialValues?.position
                ? initialValues.position.positionManager
                : undefined}
            </Grid>
            <Grid item xs>
              <Grid item xs component="label" htmlFor="position" display="flex" alignItems="center">
                <span style={{color: '#8A8A8A'}}> Workers Compensation Code</span>
              </Grid>
              {selectedPosition
                ? currentCompany?.compensationCodes.find(
                    (item) => item.id === selectedPosition.compensationCodeId,
                  )?.code
                : isEdit
                ? currentCompany?.compensationCodes.find(
                    (item) => item.id === initialValues.position.positionCompensationCodeName,
                  )?.code
                : initialValues?.position
                ? initialValues.position.positionCompensationCodeName
                : undefined}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
