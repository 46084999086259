import React from 'react'
import Box from '@mui/material/Box'

export function FormTitle({children}: {children: React.ReactNode}) {
  return <Box sx={{typography: 'h2'}}>{children}</Box>
}

export function FormSubtitle({children}: {children: React.ReactNode}) {
  return <Box sx={{typography: 'subtitle1'}}>{children}</Box>
}
