import {FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import Icon from '@ui/icons'
import FormikTextField from '@ui/text-field/StandardFormikField'
import React from 'react'
import type {IAgencyBase, ICompanyBase} from '~/src/models'
import {FormikYesNo} from '../../ui/radio/FormikYesNo'
import S from './CompanyForm.styles'

interface IProps {
  activePlatform: string
  index: number
  remove: (index: number) => void
  setFieldValue: (field: string, value: unknown) => void
  addresses: string | number | boolean | null
  initialEditValues: IAgencyBase | ICompanyBase | undefined
}

export default function CompanyAddressItem(props: IProps) {
  const {index, remove, setFieldValue, addresses} = props
  const testId = `company-address-index-${index}`

  return (
    <S.Grid.Container
      item
      container
      xs={12}
      columnSpacing={2}
      rowSpacing={4}
      key={index}
      data-testid={testId}>
      <S.Grid.Label item xs={3}>
        Location Name<S.Red>*</S.Red>:
      </S.Grid.Label>

      <S.Grid.Field item xs={9}>
        <FormikTextField
          name={`addresses.${index}.name`}
          placeholder="Headquarters"
          inputProps={{maxLength: 50}}
        />
      </S.Grid.Field>

      <S.Grid.Label item xs={3}>
        Location Address<S.Red>*</S.Red>:
      </S.Grid.Label>

      <S.Grid.Field item xs={9}>
        <FormikGooglePlacesAutocomplete
          name={`addresses.${index}.address`}
          initialEditValues={props.initialEditValues}
          setFieldValue={setFieldValue}
          value={addresses}
          address={false}
        />
        {index !== 0 && (
          <S.ButtonContainer>
            <S.CloseButton color="error" onClick={() => remove(index)}>
              <Icon icon="close" />
            </S.CloseButton>
          </S.ButtonContainer>
        )}
      </S.Grid.Field>

      <S.Grid.Label item xs={3}>
        Onsite Facility<S.Red>*</S.Red>:
      </S.Grid.Label>

      <S.Grid.Field item xs={9}>
        <div>Is this location an On-Site Facility?</div>
        <FormikYesNo
          value={Boolean(props.addresses.map((i) => i.isOnsite))}
          name={`addresses.${index}.isOnsite`}
        />
      </S.Grid.Field>
    </S.Grid.Container>
  )
}
