import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function UploadIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='15'
			height='18'
			viewBox='0 0 15 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1 9V15.4C1 15.8243 1.16857 16.2313 1.46863 16.5314C1.76869 16.8314 2.17565 17 2.6 17H12.2C12.6243 17 13.0313 16.8314 13.3314 16.5314C13.6314 16.2313 13.8 15.8243 13.8 15.4V9'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.6002 4.2L7.4002 1L4.2002 4.2'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.40039 1V11.4'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</Svg>
	);
}
