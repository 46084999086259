import {Alert, Button, Snackbar} from '@mui/material'
import Box from '@mui/material/Box'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {goBack, push} from 'connected-react-router'
import {Form as FormikForm, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {AgencyDistributions} from '~/src/models/order'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetAgenciesQuery, useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {
  useGetSingleServiceOrderQuery,
  usePledgeAgencyOrderMutation,
  useUpdateAgencyStatusToConfirmingMutation,
} from '~/src/store/apis/service-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import NonMspAgencyOrderDetailsTable from './NonMspAgencyOrderDetailsTable'

const NonMspAgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  TableInfo: styled.div`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

interface IParams {
  orderId: string
}

export default function NonMspAgencyOrderDetails() {
  const [pledgeDialog, setPledgeDialog] = useState(false)
  const [successDialog, setSuccessDialog] = useState(false)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [pledgeAgencyOrder] = usePledgeAgencyOrderMutation()
  const dispatch = useAppDispatch()
  const {orderId}: IParams = useParams()
  const [open, setOpen] = useState(false)
  const [updateAgencyStatusToConfirming] = useUpdateAgencyStatusToConfirmingMutation()

  const {data: currentAgency} = useGetOneAgencyQuery(loggedInAgencyId)
  const {
    data: matchServiceOrder,
    error: serviceOrderError,
    isLoading: serviceOrderLoading,
    isSuccess: serviceOrderIsSuccess,
  } = useGetSingleServiceOrderQuery({
    id: orderId,
  })

  const {data: allAgencies, refetch} = useGetAgenciesQuery({
    pageNumber: 1,
    pageSize: 999999999,
  })

  const agencyStatus = matchServiceOrder
    ? matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.find((item) => {
        return item.agencyId === loggedInAgencyId
      })?.agencyStatus
    : ''

  const findAgency: AgencyDistributions | undefined = matchServiceOrder
    ? matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.find((item) => {
        return item.agencyId === loggedInAgencyId
      })
    : undefined

  let needed = 0

  findAgency?.agencyQuantityDistributions?.forEach((l) => {
    needed += l.requested
  })

  let totalPledged = 0
  findAgency?.agencyQuantityDistributions?.forEach((t) => {
    if (t?.pledged) {
      return (totalPledged += t.pledged)
    } else {
      return 0
    }
  })

  const handleSubmit = async () => {
    await pledgeAgencyOrder({
      serviceOrderId: matchServiceOrder?.id as string,
      agencyId: loggedInAgencyId,
    })
      .unwrap()
      .then(() => {
        return setSuccessDialog(true)
      })
      .catch((error) => console.log(error))
  }
  const statusArray = matchServiceOrder
    ? matchServiceOrder.orders
        .map((order) => order.orderDistribution.agencyDistributions?.map((l) => l.agencyStatus))
        .flat()
    : []
  const isEditable = statusArray.includes('New Request')
  useEffect(() => {
    void refetch()
    if (isEditable && matchServiceOrder) {
      void updateAgencyStatusToConfirming({
        serviceOrderId: matchServiceOrder.id,
        agencyId: loggedInAgencyId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isEditable, updateAgencyStatusToConfirming])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={matchServiceOrder as IServiceOrder}>
      {({isSubmitting}) => {
        return (
          <FormikForm>
            <NonMspAgencyOrderDetailsStyles.Container>
              <BasicSuccessDialog
                isOpen={pledgeDialog && successDialog}
                header="Order Successfully Pledged"
                bigBlurb="Order Successfully Pledged"
                smallBlurb="Your Service Order has been pledged. Please wait until the MSP reviews and commits."
                handleSubmit={() => {
                  dispatch(push(`${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`))
                  setPledgeDialog(false)
                  setSuccessDialog(false)
                }}
              />
              <BasicConfirmationDialog
                isOpen={pledgeDialog && !successDialog}
                header="Pledge will be sent to MSP"
                bigBlurb="Please Confirm Pledge"
                smallBlurb="Are you ready to Pledge?"
                cancelButtonLabel="Back"
                handleCancel={() => {
                  dispatch(push(`${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`))
                  setPledgeDialog(false)
                  setSuccessDialog(false)
                }}
                handleSubmit={handleSubmit}
              />
              {open ? (
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    You have satisfied 100% of the requests.
                  </Alert>
                </Snackbar>
              ) : (
                <div />
              )}
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    marginRight: '15px',
                    marginBottom: '4px',
                  }}>
                  <h2>Manage Order Details</h2>
                </div>
              </Box>
              <ErrorBoundary error={serviceOrderError}>
                <LoadingBoundary isLoading={serviceOrderLoading}>
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 20px 10px 16px',
                        fontWeight: '600',
                        backgroundColor: '#FFFFFF',
                        fontStyle: 'normal',
                        fontSize: '15px',
                        lineHeight: '20px',
                        marginTop: '63px',
                      }}>
                      <div style={{display: 'flex', justifyItems: 'center'}}>
                        <NonMspAgencyOrderDetailsStyles.TableInfo>
                          <div style={{margin: 10}}>
                            Service Order:{' '}
                            <span style={{color: '#03a9fc'}}>
                              #{matchServiceOrder?.orderNumber}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 10,
                              marginBottom: 20,
                              paddingTop: 10,
                            }}>
                            Needed: {needed} | Pledged: {isNaN(totalPledged) ? 0 : totalPledged}
                          </div>
                        </NonMspAgencyOrderDetailsStyles.TableInfo>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            dispatch(goBack())
                          }}
                          variant="outlined"
                          color="secondary"
                          style={{marginRight: '18px'}}>
                          Back
                        </Button>
                        {matchServiceOrder &&
                          (['Ready To Confirm'].includes(agencyStatus as string) ? (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={() => setPledgeDialog(true)}>
                              Confirm
                            </Button>
                          ) : (
                            <Button variant="contained" color="primary" disabled>
                              Confirm
                            </Button>
                          ))}
                      </div>
                    </Box>
                    {matchServiceOrder && (
                      <NonMspAgencyOrderDetailsTable
                        currentAgency={currentAgency}
                        allAgencies={allAgencies}
                        serviceOrder={matchServiceOrder}
                        setOpen={setOpen}
                        needed={needed}
                      />
                    )}
                  </>
                </LoadingBoundary>
              </ErrorBoundary>
            </NonMspAgencyOrderDetailsStyles.Container>
          </FormikForm>
        )
      }}
    </Formik>
  )
}
