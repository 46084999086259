import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import {css, styled} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import type {AllDataCleanups, IDataCleanup} from '~/src/models/dataCleanup'
import DataCleanupCard from './DataCleanupCard'
import {getFilterDays} from './getFilterDays'

const ContainerBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1;
    margin: 1;
  `,
)

const LeftButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    left: 5px;
  `,
)

const LeftButtonBox2 = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;
    min-height: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(217, 217, 217, 0.4);
    margin-right: -35px;
    left: 30px;
  `,
)

const RightButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;
    right: 5px;
  `,
)

const RightButtonBox2 = styled(Box)(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    width: 56px;
    position: relative;

    min-height: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(217, 217, 217, 0.4);
    margin-left: -90px;
    right: 85px;
  `,
)
interface IProps {
  cleanupId: string
  count: number
  dateArray: AllDataCleanups
  isMsp: boolean
  sx?: object
  onToggleInventory: (value: boolean) => void
  showList?: boolean
}

export default function ClickableDataCleanup({
  cleanupId,
  count,
  dateArray,
  isMsp,
  sx,
  onToggleInventory,
  showList
}: IProps) {
  const [activeLink, setActiveLink] = useState<'search' | 'list'>(showList ? 'list' : 'search')
  const [display, setDisplay] = useState(false)
  const showButton = (e) => {
    e.preventDefault()
    setDisplay(true)
  }

  const hideButton = (e) => {
    e.preventDefault()
    setDisplay(false)
  }
  const [leftButtonVisible, setLeftButtonVisible] = useState(false)
  const [rightButtonVisible, setRightButtonVisible] = useState(false)
  const [index, setIndex] = useState(0)
  const length = dateArray.count
  const initialSelectArray = new Array(length).fill(false)
  initialSelectArray[0] = true

  const indexedDateArray = dateArray.dataCleanups.map((item: IDataCleanup, itemIndex) => {
    return {
      id: item.id,
      agencyId: item.agencyId,
      title: item.title,
      minCleanedDate: item.minCleanDate,
      maxCleanedDate: item.maxCleanDate,
      active: item.active,
      createdBy: item.createdBy,
      totalApplicants: item.totalApplicants,
      totalCleanedApplicants: item.totalCleanedApplicants,
      createdDate: item.createdDate,
      modifiedDate: item.modifiedDate,
      cleanupRange: item.cleanRange,
    }
  })

  const selectedData: IDataCleanup[] = dateArray.dataCleanups.filter((i) => {
    return i.id === cleanupId
  })
  const filteredDateArray = indexedDateArray.filter((i) => i.id !== cleanupId)
  const slicedList = filteredDateArray.slice(index, index + count)
  const inc = () => {
    setIndex(index + 1)
  }

  const dec = () => {
    setIndex(index - 1)
  }

  useEffect(() => {
    setRightButtonVisible(length - index > count)
    setLeftButtonVisible(index > 0)
  }, [count, index, length])

  const filter = getFilterDays(selectedData[0].cleanRange)
  return (
    <ContainerBox
      onMouseEnter={(e) => showButton(e)}
      onMouseLeave={(e) => hideButton(e)}
      sx={{
        ...sx,
        justifyContent: 'space-between',
      }}>
      {selectedData[0] ? (
        <DataCleanupCard
          date={new Date(selectedData[0].createdDate)}
          denominator={selectedData[0].totalApplicants}
          filter={filter}
          isInDataCleanup={true}
          isSelected={true}
          isMsp={isMsp}
          numerator={selectedData[0].totalCleanedApplicants}
          onToggleInventory={onToggleInventory}
          sx={{minWidth: '260px'}}
          title={selectedData[0].title}
          cardId={selectedData[0].id}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
      ) : null}

      {leftButtonVisible && display ? (
        <LeftButtonBox2>
          <IconButton
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            color="secondary"
            onClick={dec}>
            <ArrowBackIosNewIcon
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </IconButton>
        </LeftButtonBox2>
      ) : (
        <div style={{marginLeft: 21}} />
      )}

      <Stack direction="row">
        {slicedList.map((item: IDataCleanup, i: number) => {
          const date = new Date(item.createdDate)
          const days = getFilterDays(item.cleanRange)
          return (
            <DataCleanupCard
              key={i}
              cardId={item.id}
              title={item.title}
              isSelected={false}
              isInDataCleanup={true}
              isMsp={isMsp}
              sx={{marginRight: '25px', minWidth: '260px'}}
              filter={days}
              onToggleInventory={onToggleInventory}
              date={date}
              numerator={item.totalCleanedApplicants}
              denominator={item.totalApplicants}
              activeLink={activeLink}
              setActiveLink={setActiveLink}
            />
          )
        })}
      </Stack>
      <RightButtonBox>
        {rightButtonVisible && display ? (
          <RightButtonBox2>
            <IconButton
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              color="secondary"
              onClick={inc}
              disableFocusRipple>
              <ArrowForwardIosIcon
                sx={{
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              />
            </IconButton>
          </RightButtonBox2>
        ) : (
          <div />
        )}
      </RightButtonBox>
    </ContainerBox>
  )
}
