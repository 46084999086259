import React from 'react'
import Mui from '@ui/mui'
import S from './TextField.styles'
import type {ITextField} from './typings'

export default function TextFieldBase({slots = {}, outerProps, inputProps, ...rest}: ITextField) {
  const {Input, TopLeft, TopRight, Bottom} = slots

  // Compose using slots
  return (
    <Mui.FormControl {...outerProps} fullWidth={rest.fullWidth} error={rest.error}>
      <S.Flex>
        {TopLeft ?? null}
        {TopRight ?? null}
      </S.Flex>

      {Input ?? (
        <Mui.OutlinedInput
          {...rest}
          id={rest.id ?? rest.name}
          inputProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              ...inputProps?.style,
            },
            ...inputProps,
          }}
        />
      )}

      {Bottom ?? null}
    </Mui.FormControl>
  )
}

TextFieldBase.defaultProps = {
  type: 'text',
  fullWidth: true,
  onChange: function () {
    throw Error('onChange is not implemented')
  },
}
