import {push} from 'connected-react-router'
import React, {useEffect} from 'react'

import ErrorBoundary from '@ui/error'
import Layout from '../components/layout/Layout'
import Dropdown, {useDropdown} from '../components/ui/dropdown-nums'
import {cognitoToDisplay} from '../models/role'
import {BASE_ROUTES} from '../routes/constants'
import {useLazyGetRoleQuery} from '../store/apis/role-api'
import {useCurrentUser} from '../store/slice-hooks'
import {useAppDispatch} from '../store/store-hooks'
import {isEmpty} from '../utils/type-validators'
import S from '../components/auth/styles/Form.styles'
import SubmitButton from '../components/auth/SubmitButton'

const routeMap = {
  platformadmin: BASE_ROUTES.PLATFORM_ADMIN,
  companyadmin: BASE_ROUTES.COMPANY_ADMIN,
  agencyadmin: BASE_ROUTES.AGENCY_ADMIN,
  agency: BASE_ROUTES.AGENCY,
} as const

export default function RoleSelectPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const {currentUser} = useCurrentUser()
  const {getDropdownProps, optionIdx} = useDropdown()
  const [getRole, {error, isLoading, isSuccess, data}] = useLazyGetRoleQuery()

  useEffect(() => {
    if (isSuccess && !isEmpty(data)) {
      window.localStorage.setItem('cognitoRole', JSON.stringify(data.role))
      dispatch(push(routeMap[data.role.name]))
    }
  }, [data, dispatch, isSuccess])

  return (
    <ErrorBoundary error={error}>
      <Layout>
        <S.MaxWidth>
          <S.InnerPaper elevation={6}>
            <S.Greeting data-testid="greeting" />
            <S.InnerBox>
              <S.Container
                onSubmit={async (e: React.FormEvent) => {
                  e.preventDefault()
                  if (optionIdx >= 0) {
                    await getRole(currentUser.roles[optionIdx] as string).unwrap()
                  }
                }}>
                <Dropdown
                  {...getDropdownProps({
                    style: {width: '100%'},
                    optionIdx: optionIdx,
                    placeholder: '-- Select Role --',
                    'data-testid': 'role-select-dropdown',
                    options: currentUser.roles.map(cognitoToDisplay),
                  })}
                />
                <SubmitButton disabled={isLoading}>Select Role</SubmitButton>
              </S.Container>
            </S.InnerBox>
          </S.InnerPaper>
        </S.MaxWidth>
      </Layout>
    </ErrorBoundary>
  )
}
