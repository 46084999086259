import React from 'react'
import {useColor, TColor} from '@utils'

export default function Checked({color}: {color?: TColor}) {
  const calculatedColor = useColor(color)

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={calculatedColor} />
      <rect width="24" height="24" rx="4" stroke="#D4D4D4" />
      <path
        d="M8.71996 12.0001C8.5323 11.8313 8.28778 11.7397 8.03543 11.7437C7.78308 11.7477 7.54158 11.847 7.35936 12.0216C7.17715 12.1962 7.0677 12.4333 7.05297 12.6852C7.03825 12.9372 7.11933 13.1854 7.27996 13.3801L9.49996 15.7101C9.5929 15.8076 9.70459 15.8853 9.82832 15.9386C9.95204 15.9918 10.0853 16.0195 10.22 16.0201C10.3539 16.0208 10.4867 15.9947 10.6104 15.9432C10.7341 15.8916 10.8462 15.8158 10.94 15.7201L17.72 8.72006C17.8119 8.62551 17.8843 8.51378 17.933 8.39124C17.9818 8.26871 18.0059 8.13778 18.004 8.00592C18.0022 7.87406 17.9744 7.74386 17.9222 7.62275C17.87 7.50163 17.7945 7.39199 17.7 7.30006C17.6054 7.20814 17.4937 7.13573 17.3711 7.08699C17.2486 7.03824 17.1177 7.01411 16.9858 7.01597C16.854 7.01783 16.7238 7.04564 16.6026 7.09781C16.4815 7.14999 16.3719 7.22551 16.28 7.32006L10.23 13.5801L8.71996 12.0001Z"
        fill="white"
      />
    </svg>
  )
}
