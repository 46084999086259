import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import {FlexSpaceBetweenContainer} from '@ui/day-bubble/MspAvailabilityDayBubbleStyles.styles'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React from 'react'
import type {Applicant, Assignment} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  FlexColumnContainer,
  FlexContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  handleClick(value: string, index: number, isCancel: boolean): void
  index: number
  oldApplicant: Applicant
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Assignment
}

export default function MspCandidateAssignHistEdit({
  handleSubmit,
  handleClick,
  index,
  oldApplicant,
  setFieldValue,
  values,
}: IProps) {
  return (
    <>
      <DashboardMainStyles>
        <InformationCardContainer>
          <FlexSpaceBetweenContainer>
            <InformationCardHeaderContainer>
              <InformationCardHeaderPadding>
                <InformationCardHeaderTextTitle>Assignment Edit</InformationCardHeaderTextTitle>
              </InformationCardHeaderPadding>
            </InformationCardHeaderContainer>
            <FlexSpaceBetweenContainer style={{width: '85px', marginRight: '20px'}}>
              <InformationCardHeaderTextEdit
                onClick={() => handleClick('saveChanges', index, true)}>
                Cancel
              </InformationCardHeaderTextEdit>
              <InformationCardHeaderTextEdit
                onClick={() => handleClick('saveChanges', index, false)}>
                Save
              </InformationCardHeaderTextEdit>
            </FlexSpaceBetweenContainer>
          </FlexSpaceBetweenContainer>
          <InformationCardDetailsContainer>
            <InformationCardProfileExperienceJobText>
              <FlexContainer>
                <FlexColumnContainer>
                  Job Title
                  <FormikTextField
                    name={`assignments.${index}.jobTitle`}
                    data-testid="assignmentsJobTitle"
                    type="text"
                    label=""
                    value={values.jobTitle}
                    sx={{
                      maxWidth: '210px',
                      minWidth: '210px',
                      height: '28px',
                      maxHeight: '28px',
                      marginRight: '19px',
                    }}
                  />
                </FlexColumnContainer>
                <FlexColumnContainer>
                  Customer Name
                  <FormikTextField
                    name={`assignments.${index}.customerName`}
                    data-testid="assignmentsCustomerName"
                    type="text"
                    label=""
                    value={values.customerName}
                    sx={{
                      maxWidth: '210px',
                      minWidth: '210px',
                      height: '28px',
                      maxHeight: '28px',
                      marginRight: '19px',
                    }}
                  />
                </FlexColumnContainer>
                <FlexColumnContainer>
                  Assignment ID
                  <FormikTextField
                    name={`assignments.${index}.assignmentID`}
                    data-testid="assignmentId"
                    type="text"
                    label=""
                    value={values.assignmentID}
                    sx={{
                      maxWidth: '195px',
                      minWidth: '195px',
                      height: '28px',
                      maxHeight: '28px',
                    }}
                  />
                </FlexColumnContainer>
              </FlexContainer>
            </InformationCardProfileExperienceJobText>

            <InformationCardProfileExperienceJobText>
              <FlexContainer>
                <FlexColumnContainer>
                  Start Date
                  <div style={{width: '210px'}}>
                    <FormikDatePicker
                      name={`assignments.${index}.originalStartDate`}
                      data-testid="assignmentsStartDate"
                      initialValue={values.originalStartDate}
                      sx={{
                        width: '100%',
                      }}
                      onClose={(va: Date) => setFieldValue(values.originalStartDate, va)}
                    />
                  </div>
                </FlexColumnContainer>
                <FlexColumnContainer style={{marginLeft: 20}}>
                  End Date
                  <div style={{width: '210px'}}>
                    <FormikDatePicker
                      name={`assignments.${index}.endDate`}
                      data-testid="assignmentsEndDate"
                      initialValue={values?.endDate?.length > 0 ? values.endDate : null}
                      sx={{
                        width: '100%',
                      }}
                      onClose={(va: Date) => setFieldValue(values.endDate, va)}
                    />
                  </div>
                </FlexColumnContainer>
              </FlexContainer>
            </InformationCardProfileExperienceJobText>
            <InformationCardExperianceBorder index={false}>
              <InformationCardProfileHeaderText>
                Description
                <FormikTextField
                  name={`assignments.${index}.description`}
                  data-testid="assignmentsDescription"
                  id="outlined-multiline-flexible"
                  multiline
                  rows={2}
                  type="text"
                  label=""
                  value={values.description}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 20,
                    },
                  }}
                  sx={{
                    marginBottom: '15px',
                    maxWidth: '650px',
                    minWidth: '650px',
                  }}
                />
              </InformationCardProfileHeaderText>
            </InformationCardExperianceBorder>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStyles>
    </>
  )
}
