import type {TextFieldProps} from '@mui/material'
import {FieldInputProps, useField} from 'formik'
import React from 'react'
import StyledTextField from './StyledTextField'
import MuiIcon from '@mui/material/Icon'
import Icon from '@ui/icons'

export type IFormikTextFieldProps = TextFieldProps & {name: string}

export default function FormikTextField({
  name,
  children,
  onChange = () => {},
  ...restTextFieldProps
}: IFormikTextFieldProps) {
  const [field, meta] = useField(name)
  const {onChange: fieldOnChange, ...restFieldProps} = field

  const configTextField: TextFieldProps & FieldInputProps<string> = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e)
      fieldOnChange(e)
    },
    ...restFieldProps,
    ...restTextFieldProps,
    fullWidth: true,
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <StyledTextField
      SelectProps={
        restTextFieldProps?.select && {
          IconComponent: (props) => (
            <MuiIcon>
              <Icon
                // ...props lets the arrow icon naturally flip
                {...props}
                style={{
                  color: 'rgba(0,0,0,0.23)',
                  marginRight: '10px',
                  top: 'unset',
                }}
                icon="arrowhead"
              />
            </MuiIcon>
          ),
        }
      }
      {...configTextField}>
      {children}
    </StyledTextField>
  )
}
