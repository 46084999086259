import {Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditVariantIcon from '@ui/icons/EditVariant'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import {formatShiftShowMinutes} from '@utils'
import React from 'react'
import {NavLink} from 'react-router-dom'
import type {ICompanyBase} from '~/src/models'
import type {IPosition, TPositionPayload} from '~/src/models/position'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import PrependedPositionHeader from './PrependedPositionHeader'

const DataContainer = styled('div')({
  display: 'block',
  width: '20ch',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

interface PositionProps {
  data: TPositionPayload | undefined
  addressUser: ICompanyBase | undefined
  currentPageNumber: number
  setTargetPosition(s: IPosition): void
  onPaginationChange(value: number): void
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface Data {
  id: string
  name: string
  startTime: string
  endTime: string
  department: string
  location: string
  industry: string
  date: string
  edit: string
  shift: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: true,
    label: 'Department',
    align: 'left',
  },
  {
    id: 'location',
    numeric: true,
    disablePadding: true,
    label: 'Location',
    align: 'left',
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Created By',
    align: 'left',
  },
  {
    id: 'editedBy',
    numeric: false,
    disablePadding: true,
    label: 'Edited By',
    align: 'left',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'center',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{height: 65}}>
        <StyledTableCell />
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function PositionUserTable({
  data,
  addressUser,
  currentPageNumber,
  onPaginationChange,
}: PositionProps) {
  const [selected, setSelected] = React.useState<readonly string[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.positionModel.map((n) => n.id)
      setSelected(newSelecteds as string[])
      return
    }
    setSelected([])
  }

  const isSelected = (id: string) => selected.includes(id)

  return (
    <Box
      sx={{
        width: '100%',
        mb: 10,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <PrependedPositionHeader numSelected={selected.length} />
      <TableContainer>
        <Table
          sx={{minWidth: 750, height: 65}}
          aria-labelledby="tableTitle"
          size="small"
          padding="normal">
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.positionModel.length}
          />
          <TableBody>
            {data.positionModel.map((row, index) => {
              const isItemSelected = isSelected(row.id as string)
              const labelId = `enhanced-table-checkbox-${index}`
              const department = addressUser.departments.find((item) => row.departmentId == item.id)
              const facility = addressUser.addresses.find((item) => row.onsiteFacilityId == item.id)
              const compensationCode = addressUser.compensationCodes.find(
                (item) => row.compensationCodeId == item.id,
              )
              const shift = formatShiftShowMinutes(row)

              return (
                <TableRow
                  hover
                  sx={{height: 65}}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}>
                  <StyledTableCell />
                  <StyledTableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="normal"
                    sx={{maxWidth: '200px'}}>
                    <Tooltip title={row.name}>
                      <DataContainer> {row.name}</DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                    <Tooltip title={shift}>
                      <DataContainer>{shift}</DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Tooltip title={department === undefined ? 'Invalid Id' : department?.name}>
                      <DataContainer>
                        {department === undefined ? 'Invalid Id' : department?.name}
                      </DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                    <Tooltip title={`${facility?.address.city}, ${facility?.address.state}`}>
                      <DataContainer>{`${facility?.address.city}, ${facility?.address.state}`}</DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Tooltip
                      title={
                        <>
                          {row.createdBy} <br />
                          <span style={{fontWeight: 300}}>
                            {new Date(row.createdDate).toLocaleDateString('En-US') ?? '---'}
                          </span>
                        </>
                      }>
                      <DataContainer>
                        {row.createdBy ?? '---'} <br />
                        <span style={{fontWeight: 300}}>
                          {new Date(row.createdDate).toLocaleDateString('En-US') ?? '---'}
                        </span>
                      </DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Tooltip
                      title={
                        <>
                          {row.modifiedBy} <br />
                          <span style={{fontWeight: 300}}>
                            {/*  eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                            {row.modifiedDate === null
                              ? '---'
                              : new Date(row.modifiedDate).toLocaleDateString('En-US')}
                          </span>
                        </>
                      }>
                      <DataContainer>
                        {row.modifiedBy ?? '---'} <br />
                        <span style={{fontWeight: 300}}>
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {row.modifiedDate === null
                            ? '---'
                            : new Date(row.modifiedDate).toLocaleDateString('En-US')}
                        </span>
                      </DataContainer>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton>
                      <NavLink
                        data-testid="positions-edit"
                        to={{
                          pathname: `${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.EDIT_POSITION}/${row.id}`,
                        }}>
                        <EditVariantIcon />
                      </NavLink>
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={currentPageNumber}
        totalRecords={data.totalPositions}
        recordsPerPage={10}
        onChange={onPaginationChange}
      />
    </Box>
  )
}
