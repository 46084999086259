import {createApi} from '@reduxjs/toolkit/query/react';
import type {IRole} from '../../models';
import {baseQuery} from '../base-query';

export const roleApi = createApi({
	reducerPath: 'roleApi',
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getRole: builder.query<{role: IRole}, string>({
			query: (role) => ({
				url: `/Role/${role}`,
				method: 'GET',
			}),
			transformResponse: (role: IRole) => ({role}),
		}),
	}),
});

export const {useGetRoleQuery, useLazyGetRoleQuery} = roleApi;
export default roleApi.reducer;
