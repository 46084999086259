import Mui from '@ui/mui'
import {FormikValues} from 'formik'
import React, {useState} from 'react'
import AddPositionItem from './AddPositionItem'
import type {IInitialServiceOrderValues} from '~/src/models/serviceOrder'

interface IProps {
  initialValues?: IInitialServiceOrderValues
  values?: FormikValues
  isEdit: boolean
  isTemplate?: boolean
  handleChange: string | number | boolean | object | null
  onOpenOrderModal(): void
  modalOpen: boolean
  currentPageNumber: number
  setFieldValue: (field: string, value: unknown) => void
  formikRef: string | number | boolean | object | null
  errors: string | number | boolean | object | null
  index: number
  isMsp?: boolean
}

export default function AddPositionOrder({
  initialValues,
  values,
  isEdit,
  index,
  setFieldValue,
  handleChange,
  onOpenOrderModal,
  formikRef,
  errors,
  isTemplate,
  isMsp,
}: IProps): React.ReactElement {
  const positions = values ?? {}
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [orderModalVisible, setOrderModalVisible] = useState(false)

  return (
    <>
      <AddPositionItem
        index={index}
        handleChange={handleChange}
        values={values}
        initialValues={initialValues}
        isEdit={isEdit}
        isMsp={isMsp}
        setFieldValue={setFieldValue}
        onModalClose={() => {
          setOrderModalVisible(false)
        }}
        onOpenOrderModal={onOpenOrderModal}
        currentPageNumber={currPageIdx}
        modalOpen={false}
        formikRef={formikRef}
        errors={errors}
        isTemplate={isTemplate}
      />

      <Mui.Grid
        item
        xs
        component="label"
        htmlFor="position"
        display="flex"
        justifyContent="center"
        alignItems="center"
      />
    </>
  )
}
