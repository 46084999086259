export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    const newUrl = url.includes('nodeqa') ? `${url}?cache=${new Date().getTime()}` : url
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = newUrl
  })

const TO_RADIANS = Math.PI / 180

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-pixelCrop
 */
export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: {x: number; y: number; width: number; height: number},
  scale: number,
  rotate = 0,
  fileName?: string,
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = pixelCrop.x
  const cropY = pixelCrop.y

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  // ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      if (blob) {
        blob.name = fileName
        resolve(blob)
      }
    }, 'image/png')
  })
}
