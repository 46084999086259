import styled from 'styled-components'
import {colors, font} from '~/src/config/theme'
import Card from '~/src/components/common/styles/CardStyles'

const TabBlockElements = {
  Container: styled.div`
    position: relative;
  `,
  TabList: styled.ul`
    column-gap: 3.64px;
    display: flex;
  `,
  Tab: styled.li`
    a {
      background-color: ${colors.white};
      border: 1px solid #f0f0f0;
      border-radius: 8px 8px 0 0;
      color: #333;
      display: inline-block;
      font-size: ${font.sizes.smaller};
      font-weight: 700;
      height: 60px;
      letter-spacing: 0.10000000149011612px;
      line-height: 60px;
      text-align: center;
      text-decoration: none;
      width: 181.97px;
    }
  `,
  Main: styled(Card.Container)`
    box-shadow: 0 0 26px rgba(35, 35, 35, 0.1);
    padding: 40px;
    position: relative;
    top: -5px;
  `,
}

export default TabBlockElements
