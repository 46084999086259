import ServiceOrderCardMsp from '@ui/card/ServiceOrderCardMsp'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import Title from '../../../layout/Title'
import {Alert, Box, Snackbar} from '@mui/material'
import {useLocation} from 'react-router-dom'

const MspAgencyJobOrderMainContainer = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  Spacing: styled.div`
    margin-bottom: 20px;
    margin-right: 20px;
  `,
}

interface IState {
  openSnackbar: boolean | undefined
  orderNumber: number | undefined
}

export default function AgencyPendingOrders(): JSX.Element {
  const location = useLocation()
  const locationState = location.state as IState | undefined
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [open, setOpen] = useState(locationState?.openSnackbar ? locationState.openSnackbar : false)

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: currPageIdx,
    pageSize: 12,
    filterBy: 'PendingOrders',
    agencyId: loggedInAgencyId,
  })

  const path = [...location.pathname.split('/')]

  const handlePaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') {
      setOpen(false)
    }
  }

  useEffect(() => {
    void refetch()
  }, [currPageIdx, refetch])
  const totalOrders = data?.totalServiceOrders

  return (
    <MspAgencyJobOrderMainContainer.Container>
      <Title pageTitle="Pending Service Orders" />
      {locationState?.openSnackbar && (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! Service Order {locationState.orderNumber} has been Created!
          </Alert>
        </Snackbar>
      )}
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: 1200,
              borderRadius: 1,
            }}>
            {data?.serviceOrdersModel.map((serviceOrder: IServiceOrder, index: number) => {
              return (
                <div key={index}>
                  <ServiceOrderCardMsp
                    serviceOrder={serviceOrder}
                    currentUserAgencyId={loggedInAgencyId}
                    currentServiceOrderPageNumber={1}
                    isdashboard={true}
                    key={index}
                  />
                </div>
              )
            })}
          </Box>
          <StyledPagination
            page={currPageIdx}
            totalRecords={totalOrders}
            recordsPerPage={12}
            onChange={handlePaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </MspAgencyJobOrderMainContainer.Container>
  )
}
