import styled, {css} from 'styled-components'
import {colors, font, resets} from '~/src/config/theme'
import Icon from '@ui/icons'

const ManageCompaniesModal = {
  Container: styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  `,
  Overlay: styled.div`
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    position: absolute;
    width: 100%;
  `,
  Inner: styled('div')`
    background-color: ${colors.white};
    border-radius: 4px;
    width: 546px;
    z-index: 1;
  `,
  Body: styled.form`
    flex: 1;
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  `,
  CheckImage: styled.img`
    height: 75px;
    width: 75px;
  `,
  Title: styled.h3`
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 50px;
    margin-top: 25px;
  `,
  Subtitle: styled.p`
    color: #8a8a8a;
    font-size: ${font.sizes.smaller};
    line-height: 19px;
  `,
  SmallText: styled.p`
    font-size: ${font.sizes.small};
    margin-bottom: 30px;
  `,
  FieldGroup: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 22px;
  `,
  Field: styled.div`
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    height: 40px;
    margin: 0;
    padding: 0 24px;
    position: relative;
    width: 100%;
  `,
  Icon: styled(Icon)`
    height: 12px;
    margin-right: 24px;
    position: relative;
    width: 12px;
  `,
  Input: styled.input`
    ${resets.common}
    flex: 1;
    height: 100%;
    position: relative;
  `,
  PasswordField: styled.div`
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin: 0;
    padding: 0 24px;
    position: relative;
    width: 100%;
  `,
  PasswordIcon: styled(Icon)`
    color: #b3b3b3;
    cursor: pointer;
    height: 16px;
    width: 16px;
  `,
  PasswordInput: styled.input`
    ${resets.common}
    flex: 1;
    height: 100%;
  `,
  ButtonGroup: styled.div`
    margin-top: 66px;
    position: relative;
    display: flex;
    justify-content: flex-end;

    button {
      position: relative;
    }

    button + button {
      margin-left: 17px;
    }
  `,
  PasswordRequirements: styled.div`
    font-size: ${font.sizes.small};
    line-height: 20px;
    margin-top: 30px;

    li {
      color: #8a8a8a;
    }
  `,
  Header: styled('div')(
    ({variant}: {variant?: string}) => css`
      align-items: center;
      background-color: #fafafa;
      border-bottom: 1px solid #e5e5e5;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      display: flex;
      height: 115px;
      justify-content: space-between;
      padding: 0 45px;

      ${variant === 'primary' &&
      css`
        h5 span {
          color: ${colors.primary};
        }
      `}

      ${variant === 'red' &&
      css`
        h5 span {
          color: ${colors.red};
        }
      `}

			${variant === 'orange' &&
      css`
        h5 span {
          color: ${colors.orange};
        }
      `}
    `,
  ),
}

export default ManageCompaniesModal
