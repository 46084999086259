import LocationOnIcon from '@mui/icons-material/LocationOn'
import {Alert, Snackbar} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import {css, styled} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import {flagSwitch} from '@ui/flags/FlagSwitch'
import {StyledCheckbox} from '@ui/styled-checkbox/StyledCheckbox'
import React, {useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {useGetAgencyNamesByIdsQuery} from '~/src/store/apis/agency-api'
import {
  useMoveApplicantToNewAgencyMutation,
  useUpdateApplicantMutation,
} from '~/src/store/apis/applicants-api'
import {selectAgencyState} from '~/src/store/slices/agency-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import {colors} from '../../../../../config/theme'
import MoveCandidateDialog from './MoveCandidateDialog'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

export const CandidateText = styled('div')`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  color: #8a8a8a;
`

const CandidateMoveContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const SubText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2;
`

export const MoveText = styled('span')`
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  color: #03a9fc;
  &:hover {
    cursor: pointer;
  }
  margin-left: 8px;
  margin-right: 55px;
`

const CustomizedFormControlLabel = styled(FormControlLabel)(
  (props: {$isSelected: boolean}) => css`
    & .MuiFormControlLabel-label {
      color: ${props.$isSelected ? 'red' : '#333333'};
      size: 14px;
      white-space: nowrap;
    }
    width: 10px;
  `,
)

const useStyles = makeStyles({
  locationIcon: {
    marginLeft: -1,
    color: colors.primary,
    maxHeight: '25px',
    maxWidth: '25px',
    verticalAlign: 'middle',
  },
})
interface IProps {
  isAssignmentHistory?: boolean
  singleApplicant: Applicant
  candidateLoadingStatuses?: {isLoading: boolean; isSuccess: boolean; isFetching: boolean}
  refetchCandidates?: () => void
  oldApplicant: Applicant
}

export default function JobOrderCandidateNameHeader({
  isAssignmentHistory,
  singleApplicant,
  candidateLoadingStatuses,
  refetchCandidates = () => {},
  oldApplicant,
}: IProps) {
  const classes = useStyles()
  const [isChecked, setIsChecked] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAlreadyOpened, setIsAlreadyOpened] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [agencyName, setAgencyName] = useState('')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [updateApplicant, {error: updateApplicantError}] = useUpdateApplicantMutation()
  const [moveApplicant] = useMoveApplicantToNewAgencyMutation()

  const {
    data,
    isLoading: agencyIsLoading,
    error,
    refetch: agencyRefetch,
  } = useGetAgencyNamesByIdsQuery([oldApplicant.agencyId])

  const {allAgencies} = useAppSelector(selectAgencyState)

  const handleMove = async (agencyId: string) => {
    await moveApplicant({applicantId: oldApplicant.id, newAgencyId: agencyId, loggedInAgencyId})
      .unwrap()
      .then(() => {
        refetchCandidates()
      })
    const agencyFound = allAgencies?.agencies.find((item) => item.id === agencyId)
    setAgencyName(agencyFound?.name as string)
    setIsSnackbarOpen(true)
  }

  return (
    <Container>
      <MoveCandidateDialog
        allAgencies={allAgencies?.agencies.filter((item) => item.id !== oldApplicant.agencyId)}
        modalOpen={isDialogOpen}
        onMove={(agencyId) => {
          setIsDialogOpen(false)
          void handleMove(agencyId)
        }}
        onCancel={() => {
          setIsDialogOpen(false)
        }}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}>
        <Alert
          onClose={() => {
            setIsSnackbarOpen(false)
          }}
          severity="success"
          sx={{width: '100%'}}>
          The Candidate has successfully been moved to {agencyName}
        </Alert>
      </Snackbar>
      <CandidateText style={{display: 'flex', alignItems: 'center'}}>
        <span>{flagSwitch(oldApplicant.flag, true, false)}</span>
        {oldApplicant.firstName} {oldApplicant.lastName} -{' '}
        {oldApplicant.ssn.slice(oldApplicant.ssn.length - 4, oldApplicant.ssn.length)}
      </CandidateText>
      <SubText>
        <span style={{fontWeight: 600}}>Industry</span>{' '}
        {oldApplicant.industryChoice ? ` - ${oldApplicant.industryChoice}` : ''}
      </SubText>
      <CandidateMoveContainer>
        <SubText>
          <span style={{fontWeight: 600}}>{oldApplicant.firstName}</span> at{' '}
          {oldApplicant.assignments?.[isAssignmentHistory ? 1 : 0]?.jobTitle}
        </SubText>
        <SubText>
          <span style={{fontWeight: 600}}>Branch: </span> {data ? data[0]?.name : ''}{' '}
          <MoveText
            onClick={() => {
              setIsDialogOpen(true)
            }}>
            Move
          </MoveText>
        </SubText>
      </CandidateMoveContainer>
      <SubText style={{marginBottom: '25px'}}>
        <LocationOnIcon className={classes.locationIcon} /> {oldApplicant.address.display}
      </SubText>
    </Container>
  )
}
