import MoreVertIcon from '@mui/icons-material/MoreVert'
import {Tooltip} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, {useState} from 'react'
import {ReactComponent as FolderIcon} from '../../../assets/images/FolderIcon.svg'
import {
  FolderContainer,
  FolderItemText,
  FolderNameText,
  ThreeDotSetting,
} from './styles/documentStyles'

interface IProps {
  documentId: string
  folderInfo: {name: string; files: []}
  folderInfoIndex: number
  handleSelectedFolder: (fileArray: []) => void
  setCreateFolderDialog: (value: boolean) => void
  setSelectedEditFolder: React.Dispatch<React.SetStateAction<undefined>>
  setIsEdit: (value: boolean) => void
}

export default function DocumentFolders({
  documentId,
  folderInfo,
  folderInfoIndex,
  handleSelectedFolder,
  setCreateFolderDialog,
  setSelectedEditFolder,
  setIsEdit,
}: IProps) {
  const [threeDotStyle, setThreeDotStyle] = useState({display: 'none'})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsEdit(true)
    setSelectedEditFolder(folderInfo)
    setCreateFolderDialog(true)
  }

  return (
    <FolderContainer
      onClick={(e) => {
        return e.stopPropagation(), handleSelectedFolder(folderInfo)
      }}
      key={folderInfoIndex}
      onMouseEnter={(e) => {
        setThreeDotStyle({display: 'flex'})
      }}
      onMouseLeave={(e) => {
        setThreeDotStyle({display: 'none'})
      }}>
      <ThreeDotSetting style={threeDotStyle}>
        <IconButton
          aria-label="openFolderMenu-button"
          data-testid="openFolderMenu-button"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </ThreeDotSetting>
      {/* keeps div from jumping */}
      <div style={{height: threeDotStyle.display === 'none' ? 40 : 0}} />
      <FolderIcon style={{height: '69px', width: '84px'}} />
      <Tooltip placement="right-end" title={folderInfo.name}>
        <FolderNameText>{folderInfo.name}</FolderNameText>
      </Tooltip>
      <FolderItemText>{folderInfo.files.length} Items</FolderItemText>
    </FolderContainer>
  )
}
