import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SearchIcon from '@mui/icons-material/Search'
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material'
import Badge from '@mui/material/Badge'
import UpdateCandidatePictureDialog from '@ui/dialog/UploadCandidatePictureDialog'
import React, {useState} from 'react'
import {ReactComponent as AvatarEditIcon} from '~/src/assets/images/AvatarEditIcon.svg'
import type {Candidate} from '~/src/models/jobOrder'
import {
  CandidateHeaderText,
  CandidateName,
  CardContainer,
  HeaderBox,
  TopHeaderBox,
  Wrapper,
} from './styles/CompanyCandidateInventoryStyles'
interface IProps {
  candidateList: Candidate[]
}

export default function CompanyCandidateInventory({candidateList}: IProps): JSX.Element {
  const [searchBox, setSearchBox] = useState('')
  const [openUploadDialog, setOpenUploadDialog] = useState(false)

  const [filteredCandidates, setFilteredCandidates] = useState<Candidate[]>()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBox(e.target.value)
    if (e.target.value === '') {
      setFilteredCandidates([])
    } else {
      const filteredData = candidateList.filter((item) => {
        const newObject = {
          firstName: item.firstName,
          lastName: item.lastName,
          middleName: item.middleName,
        }
        return Object.values(newObject)
          .join('')
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      })

      setFilteredCandidates(filteredData)
    }
  }

  const handleDialogOpen = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  const handleDialogClose = () => {
    setOpenUploadDialog(!openUploadDialog)
  }

  const options = {
    InputProps: {
      disableUnderline: true,
      startAdornment: (
        <SearchIcon
          sx={{
            color: '#8A8A8A',
            marginRight: 2,
            marginLeft: 1,
            '&::placeholder': {
              textAlign: 'center',
            },
          }}
        />
      ),
    },
  }
  return (
    <Wrapper>
      <UpdateCandidatePictureDialog
        isOpen={openUploadDialog}
        handleClose={handleDialogClose}
        agencyId=""
        candidateId={''}
      />
      <TopHeaderBox>
        <CandidateHeaderText>Candidate Delivery List</CandidateHeaderText>
        <Box>
          <IconButton>
            <FileDownloadIcon sx={{color: ' #707070'}} />
          </IconButton>
        </Box>
      </TopHeaderBox>

      <HeaderBox>
        <Box sx={{marginTop: -3}}>
          <TextField
            variant="standard"
            name="searchCandidate"
            data-testid="searchCandidate"
            type="text"
            label=""
            value={searchBox}
            onChange={handleChange}
            sx={{
              width: '421px',
              height: '35px',
              border: '1px solid #EBEBEB',
              borderRadius: '9px',
              background: 'white',
            }}
            {...options}
            placeholder="Search Candidate"
          />
        </Box>
      </HeaderBox>
      <CardContainer>
        {searchBox.length > 0
          ? filteredCandidates?.map((candidate: Candidate, index: number) => {
              const candidateName =
                candidate.middleName === undefined
                  ? `${candidate.firstName} ${candidate.lastName}`
                  : `${candidate.firstName} ${candidate.middleName} ${candidate.lastName}`

              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    minHeight: '54px',
                    maxHeight: '54px',
                    padding: '9px 6px 4px 8px',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)',
                  }}>
                  <ListItemButton
                    alignItems="flex-start"
                    sx={{
                      padding: '0',
                      minHeight: '49px',
                      maxHeight: '49px',
                      borderRadius: '8px',
                      '&:hover': {
                        //you want this to be the same as the backgroundColor above
                        backgroundColor: '#FFF',
                      },
                    }}>
                    <ListItemAvatar sx={{margin: '0', justifyContent: 'right'}}>
                      <Badge
                        sx={{cursor: 'pointer'}}
                        onClick={handleDialogOpen}
                        overlap="circular"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={<AvatarEditIcon alt="edit-profile-icon" />}>
                        <Avatar
                          sx={{backgroundColor: 'blue', margin: '0'}}
                          src={candidate?.profilePictureName}
                        />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      style={{margin: 'auto'}}
                      primary={<CandidateName>{candidateName}</CandidateName>}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })
          : candidateList.map((candidate1: Candidate, index2: number) => {
              const candidateName1 =
                candidate1.middleName === undefined
                  ? `${candidate1.firstName} ${candidate1.lastName}`
                  : `${candidate1.firstName} ${candidate1.middleName} ${candidate1.lastName}`
              return (
                <ListItem
                  key={index2}
                  disablePadding
                  sx={{
                    minHeight: '54px',
                    maxHeight: '54px',
                    padding: '9px 6px 4px 8px',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: '#FFF',
                    },
                  }}>
                  <ListItemButton
                    alignItems="flex-start"
                    sx={{
                      padding: '0',
                      minHeight: '49px',
                      maxHeight: '49px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#FFF',
                      },
                    }}>
                    <ListItemAvatar sx={{margin: '0', justifyContent: 'right'}}>
                      <Badge
                        sx={{cursor: 'pointer'}}
                        onClick={handleDialogOpen}
                        overlap="circular"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={<AvatarEditIcon alt="edit-profile-icon" />}>
                        <Avatar
                          sx={{backgroundColor: 'blue', margin: '0'}}
                          src={candidate1?.profilePictureName}
                        />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      style={{margin: 'auto'}}
                      primary={<CandidateName>{candidateName1}</CandidateName>}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
      </CardContainer>
    </Wrapper>
  )
}
