import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function BrandTextIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 84 13' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M22.1458 5.09279C23.1215 3.80162 24.4068 3.50366 25.8557 3.76657C27.3631 4.04116 28.3504 4.97011 28.7944 6.41903C29.2268 7.82705 29.1625 9.22922 28.4088 10.5321C27.737 11.6889 26.6678 12.2439 25.3474 12.3315C24.3542 12.3958 23.4428 12.1972 22.6716 11.5194C22.4905 11.3617 22.3444 11.1747 22.1516 10.9411C22.1516 11.3559 22.1458 11.7181 22.1575 12.0803C22.1633 12.2381 22.1166 12.2848 21.9588 12.279C21.3921 12.2673 20.8312 12.2673 20.2645 12.279C20.0776 12.2848 20.0542 12.2089 20.0542 12.0511C20.06 8.26522 20.06 4.47934 20.0542 0.693457C20.0542 0.5065 20.1068 0.465604 20.2879 0.465604C20.8429 0.477288 21.398 0.477288 21.953 0.465604C22.1341 0.459761 22.1575 0.52403 22.1575 0.687618C22.1458 2.13069 22.1458 3.57961 22.1458 5.09279ZM24.5353 5.44334C23.1331 5.44334 22.1458 6.50082 22.1458 8.00232C22.1458 9.48629 23.1332 10.5379 24.5236 10.5438C25.92 10.5496 26.9132 9.48045 26.9074 7.98479C26.9132 6.49498 25.92 5.44334 24.5353 5.44334Z'
				fill='#232323'
			/>
			<path
				d='M30.7873 8.74434C31.4066 9.276 32.0726 9.7025 32.8205 9.99462C33.6968 10.3335 34.5907 10.5204 35.5255 10.2867C36.1857 10.1232 36.5654 9.69081 36.5421 9.12409C36.5245 8.68591 36.2792 8.3938 35.9228 8.16595C35.4495 7.86798 34.912 7.72193 34.3804 7.57003C33.6676 7.3597 32.949 7.16105 32.2654 6.84555C30.0979 5.85234 30.2206 3.39269 31.3014 2.26511C31.8973 1.63997 32.651 1.30111 33.4806 1.15505C35.2918 0.833719 36.9802 1.18426 38.5694 2.08399C38.7447 2.18331 38.7739 2.2651 38.6862 2.44622C38.4409 2.93114 38.213 3.42191 37.9968 3.91852C37.9326 4.07042 37.88 4.07041 37.7398 3.99445C36.8167 3.48032 35.8468 3.08889 34.7718 3.01878C34.3804 2.99541 33.9948 3.01294 33.6209 3.14732C32.8146 3.44528 32.6686 4.32748 33.3346 4.87666C33.6968 5.17463 34.135 5.32652 34.5732 5.47258C35.3853 5.74134 36.2207 5.93414 37.0036 6.29637C37.7807 6.6586 38.4467 7.14352 38.7213 7.99651C39.2237 9.57396 38.6687 11.2975 36.7115 11.9986C34.9412 12.6295 33.206 12.4134 31.5234 11.6422C30.9859 11.3968 30.4893 11.0755 30.0336 10.6899C29.9577 10.6256 29.8759 10.573 29.946 10.4445C30.2206 9.88361 30.4952 9.32274 30.7873 8.74434Z'
				fill='#232323'
			/>
			<path
				d='M80.8216 8.74434C79.8576 8.74434 78.8994 8.75019 77.9354 8.7385C77.7251 8.7385 77.69 8.78523 77.7601 8.98388C78.1457 10.1465 79.1448 10.7249 80.3366 10.6431C81.0786 10.5905 81.733 10.3218 82.2763 9.80766C82.3698 9.72002 82.4282 9.70249 82.5217 9.80181C82.8255 10.129 83.1293 10.4503 83.4448 10.76C83.5967 10.9119 83.4507 10.9703 83.3806 11.0346C82.732 11.6772 81.9492 12.0628 81.0553 12.2323C80.103 12.4075 79.1623 12.39 78.2451 12.0628C76.9188 11.5896 76.0717 10.6665 75.7211 9.31105C75.3998 8.0783 75.4875 6.87477 76.1126 5.75302C76.8546 4.40927 78.0698 3.79582 79.5596 3.69065C80.2432 3.63807 80.9267 3.70818 81.5811 3.94187C82.9248 4.42095 83.6084 5.43169 83.883 6.77545C83.9998 7.35969 83.9998 7.94976 83.9998 8.53985C83.9998 8.72096 83.9297 8.75018 83.772 8.74434C82.7846 8.74434 81.8031 8.74434 80.8216 8.74434ZM79.8634 7.34216C80.5295 7.34216 81.1955 7.33632 81.8615 7.34801C82.0134 7.34801 82.0718 7.3188 82.0543 7.14937C81.9667 6.40738 81.6278 5.82898 80.9326 5.52518C79.6706 4.98183 78.0639 5.43169 77.6784 7.14352C77.6375 7.31879 77.6842 7.34216 77.8419 7.34216C78.5138 7.33632 79.1857 7.34216 79.8634 7.34216Z'
				fill='#232323'
			/>
			<path
				d='M13.6745 12.3256C12.8682 12.3432 12.097 12.1913 11.3843 11.8115C9.99962 11.0754 9.3219 9.87767 9.21673 8.34696C9.1291 7.09668 9.4329 5.94572 10.3151 5.0051C10.9578 4.32153 11.764 3.94179 12.6754 3.77236C13.6862 3.59124 14.6852 3.63213 15.6434 4.02358C17.2676 4.68961 18.0738 5.96326 18.1965 7.68093C18.2608 8.56898 18.1089 9.4278 17.6649 10.2107C16.8995 11.5661 15.6784 12.1796 14.1711 12.314C14.0075 12.3432 13.8381 12.3256 13.6745 12.3256ZM11.3083 8.08404C11.2908 8.23594 11.3317 8.42874 11.3667 8.6157C11.5479 9.52128 12.0328 10.1932 12.95 10.4619C13.8615 10.7365 14.7086 10.5904 15.398 9.89519C15.953 9.33432 16.1108 8.6274 16.0932 7.86204C16.0524 6.19696 14.6093 5.12194 13.0026 5.56596C11.9685 5.85224 11.3083 6.82208 11.3083 8.08404Z'
				fill='#232323'
			/>
			<path
				d='M44.1314 12.3432C43.553 12.3432 42.665 12.1446 41.8704 11.6187C40.5617 10.7541 40.0359 9.49209 40.03 7.96138C40.0242 5.47836 41.7828 3.76653 44.3184 3.68473C45.481 3.64968 46.5677 3.88338 47.485 4.61952C48.7002 5.58936 49.1091 6.90391 49.004 8.40541C48.9222 9.58558 48.4665 10.6022 47.5317 11.3617C46.6553 12.0803 45.6212 12.349 44.1314 12.3432ZM42.145 8.04902C42.145 9.7141 43.3076 10.7541 44.9494 10.5613C46.147 10.421 47.0176 9.20582 46.9066 7.83285C46.7663 6.11518 45.4284 5.1395 43.8159 5.57184C42.7877 5.84643 42.145 6.79874 42.145 8.04902Z'
				fill='#232323'
			/>
			<path
				d='M56.5817 10.8476C56.1318 11.5078 55.5651 11.9927 54.7997 12.1855C53.6546 12.4776 52.5387 12.4484 51.5747 11.6597C50.8912 11.0988 50.5757 10.3218 50.5581 9.46878C50.5231 7.62257 50.5465 5.77637 50.5406 3.93016C50.5406 3.75489 50.6107 3.7432 50.7509 3.7432C51.306 3.74904 51.861 3.75489 52.416 3.7432C52.6205 3.73736 52.6556 3.81331 52.6556 3.99442C52.6497 5.47255 52.6497 6.9507 52.6497 8.42883C52.6497 9.48046 53.1113 10.1348 53.976 10.3276C54.9458 10.5438 55.9098 10.0705 56.3188 9.18249C56.5057 8.77352 56.5758 8.34119 56.5758 7.89717C56.5817 6.59431 56.5817 5.29144 56.57 3.99442C56.57 3.80746 56.6109 3.74321 56.8095 3.74905C57.3529 3.76073 57.9021 3.76073 58.4454 3.74905C58.6148 3.74905 58.6908 3.77242 58.6849 3.97106C58.6791 6.66442 58.6791 9.36361 58.6849 12.057C58.6849 12.2498 58.6207 12.2848 58.4454 12.2848C57.8904 12.2731 57.3354 12.2731 56.7803 12.2848C56.6109 12.2906 56.5583 12.2381 56.5641 12.0686C56.5875 11.6538 56.5817 11.2507 56.5817 10.8476Z'
				fill='#232323'
			/>
			<path
				d='M1.5575 9.07152C1.97231 9.5097 2.42217 9.87778 2.97136 10.1056C3.35696 10.2634 3.75424 10.3627 4.18658 10.2984C4.85846 10.1933 5.28496 9.77261 5.37259 9.10657C5.39596 8.91377 5.41349 8.72098 5.41349 8.52818C5.41349 6.79298 5.40765 5.05778 5.41933 3.32259C5.41933 3.08889 5.34922 3.03632 5.12721 3.03632C4.02884 3.048 2.92462 3.03632 1.8204 3.048C1.59255 3.048 1.53997 2.98373 1.55165 2.76756C1.56918 2.28848 1.56334 1.8094 1.55165 1.32448C1.55165 1.18427 1.58671 1.13168 1.73277 1.13168C3.61987 1.13753 5.50697 1.13753 7.39991 1.13168C7.54597 1.13168 7.58686 1.17843 7.58686 1.31864C7.58102 3.86594 7.61023 6.41323 7.57518 8.96636C7.55181 10.3335 7.02599 11.4786 5.6764 12.0453C4.16905 12.6821 2.27027 12.4017 1.01999 11.1865C0.529232 10.7133 0.529232 10.7132 0.891461 10.1348C1.10179 9.7843 1.3238 9.43375 1.5575 9.07152Z'
				fill='#232323'
			/>
			<path
				d='M73.5246 6.45992C73.0221 5.93994 72.4321 5.64198 71.7251 5.5485C70.2411 5.34402 69.0785 6.28465 68.985 7.77446C68.9091 9.00137 69.4583 10.0063 70.4047 10.3802C71.3687 10.7599 72.6833 10.4795 73.4019 9.72584C73.5421 9.57978 73.6239 9.54472 73.7875 9.69662C74.0855 9.97121 74.4009 10.2283 74.7281 10.4737C74.8859 10.5905 74.8683 10.6665 74.7632 10.795C74.1848 11.5136 73.4486 11.9693 72.5547 12.1796C71.4622 12.4367 70.3697 12.4309 69.3239 11.9869C68.1963 11.5136 67.4426 10.6782 67.0921 9.50383C66.724 8.27692 66.7766 7.0617 67.3492 5.9049C67.9685 4.65463 69.0201 3.96522 70.3814 3.74905C71.509 3.56794 72.6073 3.70814 73.6298 4.25149C74.0329 4.46766 74.3776 4.75979 74.6814 5.09865C74.769 5.20381 74.7924 5.26223 74.6755 5.36739C74.2841 5.72962 73.9102 6.09185 73.5246 6.45992Z'
				fill='#232323'
			/>
			<path
				d='M63.335 5.28562C63.5278 5.02855 63.668 4.80653 63.8491 4.61957C64.4626 3.98859 65.2162 3.70817 66.0868 3.67895C66.2094 3.67311 66.227 3.71984 66.227 3.81917C66.227 4.39172 66.2211 4.96428 66.227 5.53684C66.227 5.69458 66.1452 5.68875 66.04 5.68875C65.5843 5.6829 65.1344 5.74132 64.7138 5.93412C63.7848 6.36061 63.3525 7.12013 63.335 8.11334C63.3174 9.4162 63.3291 10.7191 63.335 12.0219C63.335 12.2206 63.2999 12.2907 63.0779 12.2848C62.5346 12.2673 61.9854 12.2731 61.442 12.2848C61.2843 12.2848 61.2375 12.2439 61.2375 12.0862C61.2434 9.38114 61.2434 6.67026 61.2375 3.96523C61.2375 3.7958 61.2843 3.74906 61.4537 3.74906C62.0088 3.76074 62.5638 3.76074 63.1188 3.74906C63.2999 3.74322 63.335 3.80749 63.3291 3.97107C63.3291 4.3742 63.335 4.78901 63.335 5.28562Z'
				fill='#232323'
			/>
			<path
				d='M13.6982 1.13163C14.3175 1.13163 14.9368 1.14332 15.5561 1.12579C15.7723 1.11995 15.819 1.19006 15.8073 1.3887C15.7898 1.73924 15.7956 2.08979 15.8073 2.44033C15.8132 2.59224 15.7781 2.65066 15.6145 2.65066C14.335 2.64482 13.0614 2.64482 11.7819 2.65066C11.6475 2.65066 11.5833 2.62729 11.5891 2.46955C11.6008 2.08395 11.6008 1.6925 11.5891 1.30691C11.5833 1.14916 11.6534 1.13163 11.7878 1.13163C12.4246 1.13747 13.0614 1.13163 13.6982 1.13163Z'
				fill='#232323'
			/>
		</Svg>
	);
}
