import React, {useEffect, useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateAvailabilityDisplay from '../CandidateProfileDisplayandEdit/MspCandidateAvailabilityDisplay'
import MspCandidateAvailabilityEdit from '../CandidateProfileDisplayandEdit/MspCandidateAvailabilityEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateProfileAvailability({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
}: IProps) {
  const [editProfileAvailabilityState, setEditProfileAvailabilityState] = useState('saveChanges')

  const handleCancel = () => {
    resetForm({values: oldApplicant})
    setEditProfileAvailabilityState('saveChanges')
  }

  const handleClick = (value: string) => {
    if (value === 'edit') {
      if (values.availability.overAllAvailability) {
        setEditProfileAvailabilityState('edit')
      } else {
        setFieldValue('availability.overAllAvailability', true)
        setEditProfileAvailabilityState('edit')
      }
    } else {
      resetForm()
      setEditProfileAvailabilityState('saveChanges')
    }
  }

  const projectAvailability = () => {
    switch (editProfileAvailabilityState) {
      case 'edit':
        return (
          <MspCandidateAvailabilityEdit
            allValues={values}
            handleCancel={handleCancel}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            values={values.availability}
            isDataCleanup={false}
          />
        )
      case 'saveChanges':
        return (
          <MspCandidateAvailabilityDisplay handleClick={handleClick} values={values.availability} />
        )

      default:
        return (
          <MspCandidateAvailabilityDisplay handleClick={handleClick} values={values.availability} />
        )
    }
  }

  useEffect(() => {
    setEditProfileAvailabilityState('saveChanges')
  }, [oldApplicant.id])

  return (
    <>
      <DashboardMainStyles>{projectAvailability()}</DashboardMainStyles>
    </>
  )
}
