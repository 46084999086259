import type {FormikErrors} from 'formik'
import React, {useEffect, useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateProfileContactEdit from '../CandidateProfileDisplayandEdit/MspCandidateDetailsProfileContactEdit'
import MspCandidateProfileContactDisplay from '../CandidateProfileDisplayandEdit/MspCandidateProfileContactDisplay'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm: any
  setFieldValue(field: string, value: string, shouldValidate?: boolean | undefined): void
  values: Applicant
  errors: FormikErrors<Applicant>
}

export default function MspCandidateProfileContact({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
  errors,
}: IProps) {
  const [editProfileContactState, setEditProfileContactState] = useState('saveChanges')

  const handleClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileContactState('edit')
    } else {
      await handleSubmit(values)
    }
  }

  const handleCancel = () => {
    resetForm({values: oldApplicant})
    setEditProfileContactState('saveChanges')
  }

  useEffect(() => {
    setEditProfileContactState('saveChanges')
  }, [oldApplicant.id])

  const projectContact = () => {
    switch (editProfileContactState) {
      case 'edit':
        return (
          <MspCandidateProfileContactEdit
            handleCancel={handleCancel}
            handleClick={handleClick}
            oldApplicant={oldApplicant}
            setFieldValue={setFieldValue}
            values={values}
          />
        )
      case 'saveChanges':
        return <MspCandidateProfileContactDisplay handleClick={handleClick} values={values} />

      default:
        return <MspCandidateProfileContactDisplay handleClick={handleClick} values={values} />
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectContact()}</DashboardMainStyles>
    </>
  )
}
