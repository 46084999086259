import React from 'react'
import {useTheme} from '@mui/material/styles'
import {useColor, TColor} from '@utils'

export default function Indeterminate({color}: {color?: TColor}) {
  const theme = useTheme()
  const calculatedColor = useColor(color)

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={calculatedColor} />
      <rect x="6" y="11" width="12" height="2" rx="1" fill={theme.palette.common.white} />
    </svg>
  )
}
