import CloseIcon from '@mui/icons-material/Close'
import {Button} from '@mui/material'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import type {FormikErrors} from 'formik'
import React from 'react'
import {Red} from '~/src/components/company/styles/EditCompanyStyles.styles'
import type {Applicant} from '~/src/models/applicant'
import {
  AddAssignmentCardContainer,
  AddAssignmentContainer,
  AddAssignmentText,
  CloseIconVerticalAlignContainer,
  DashboardMainStyles,
  EventsCloseIconContainer,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceEvenlyContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
  MarginBottom15Px,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  resetForm(obj: Applicant): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateEventsEmptyForm({
  errors,
  handleSubmit,
  resetForm,
  setFieldValue,
  values,
}: IProps) {
  const assignmentError = values.newEvents.assignmentID
    ? values.newEvents.assignmentID.length > 0
    : false
  const companyError = values.newEvents.company ? values.newEvents.company.length > 0 : false
  const positionError = values.newEvents.position ? values.newEvents.position.length > 0 : false
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const startDateError = values.newEvents.startDate ? values.newEvents.startDate !== '' : false
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const endDateError = values.newEvents.endDate ? values.newEvents.endDate !== '' : false
  const eventNameError = values.newEvents.eventName ? values.newEvents.eventName.length > 0 : false
  const handleClick = async () => {
    if (
      assignmentError &&
      companyError &&
      positionError &&
      startDateError &&
      endDateError &&
      eventNameError
    ) {
      const newEventData = values.events.push(values.newEvents)
      const finalData = {...newEventData, ...values}
      await handleSubmit(finalData)
    }
  }

  const maxStartDate = new Date(values.newEvents.endDate)
  const minEndDate = new Date(values.newEvents.startDate)
  const addEventButtonSwitch = () => {
    if (
      assignmentError &&
      companyError &&
      positionError &&
      startDateError &&
      endDateError &&
      eventNameError
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          sx={{width: 154, fontWeight: 'normal'}}
          onClick={handleClick}
          aria-label="add new activity button"
          data-testid="activityAddNewNote">
          Add Event
        </Button>
      )
    } else {
      return (
        <AddAssignmentContainer style={{cursor: 'default'}}>
          <FlexSpaceEvenlyContainer>
            <AddAssignmentText>Add Event</AddAssignmentText>
          </FlexSpaceEvenlyContainer>
        </AddAssignmentContainer>
      )
    }
  }
  return (
    <>
      <DashboardMainStyles>
        <InformationCardHeaderPadding>
          <InformationCardHeaderTextTitle>Enter new Event</InformationCardHeaderTextTitle>
        </InformationCardHeaderPadding>
        <InformationCardContainer>
          <FlexContainer>
            <InformationCardDetailsContainer>
              <MarginBottom15Px>
                <InformationCardProfileExperienceJobText>
                  <FlexContainer>
                    <FlexColumnContainer>
                      <span style={{display: 'flex'}}>
                        Position <Red>*</Red>
                      </span>
                      <FormikTextField
                        name="newEvents.position"
                        type="text"
                        label=""
                        data-testid="newEventsPosition"
                        value={values.newEvents.position ? values.newEvents.position : ''}
                        sx={{
                          maxWidth: '210px',
                          minWidth: '210px',
                          height: '28px',
                          maxHeight: '28px',
                          marginRight: '19px',
                        }}
                      />
                    </FlexColumnContainer>
                    <FlexColumnContainer>
                      <span style={{display: 'flex'}}>
                        Company<Red>*</Red>
                      </span>
                      <FormikTextField
                        name="newEvents.company"
                        data-testid="newEventsCompany"
                        type="text"
                        label=""
                        value={values.newEvents.company ? values.newEvents.company : ''}
                        sx={{
                          maxWidth: '210px',
                          minWidth: '210px',
                          height: '28px',
                          maxHeight: '28px',
                          marginRight: '19px',
                        }}
                      />
                    </FlexColumnContainer>
                    <FlexColumnContainer>
                      <span style={{display: 'flex'}}>
                        Assignment ID<Red>*</Red>
                      </span>
                      <FormikTextField
                        name="newEvents.assignmentID"
                        data-testid="newEventsEmptyAssignmentID"
                        type="text"
                        label=""
                        value={values.newEvents.assignmentID ? values.newEvents.assignmentID : ''}
                        sx={{
                          maxWidth: '195px',
                          minWidth: '195px',
                          height: '28px',
                          maxHeight: '28px',
                        }}
                      />
                    </FlexColumnContainer>
                  </FlexContainer>
                </InformationCardProfileExperienceJobText>
                <InformationCardProfileExperienceJobText>
                  <FlexContainer>
                    <FlexColumnContainer>
                      <span style={{display: 'flex'}}>
                        Start Date<Red>*</Red>
                      </span>
                      <div style={{width: '210px'}}>
                        <FormikDatePicker
                          name="newEvents.startDate"
                          data-testid="newEventsEmptyStartDate"
                          type="text"
                          label=""
                          initialValue={values.newEvents.startDate}
                          sx={{
                            width: '100%',
                          }}
                          maxDate={
                            values.newEvents.endDate === '' ||
                            values.newEvents.endDate === undefined
                              ? undefined
                              : maxStartDate.setDate(maxStartDate.getDate())
                          }
                          onClose={(va: Date) => setFieldValue(values.newEvents.startDate, va)}
                        />
                      </div>
                    </FlexColumnContainer>
                    <FlexColumnContainer style={{marginLeft: 20}}>
                      <span style={{display: 'flex'}}>
                        End Date<Red>*</Red>
                      </span>
                      <div style={{width: '210px'}}>
                        <FormikDatePicker
                          name="newEvents.endDate"
                          data-testid="newEventsEmptyEndDate"
                          type="text"
                          label=""
                          initialValue={values.newEvents.endDate}
                          sx={{
                            width: '100%',
                          }}
                          minDate={minEndDate.setDate(minEndDate.getDate())}
                          onClose={(va: Date) => setFieldValue(values.newEvents.endDate, va)}
                        />
                      </div>
                    </FlexColumnContainer>
                  </FlexContainer>
                </InformationCardProfileExperienceJobText>
                <InformationCardProfileExperienceJobText>
                  <FlexContainer>
                    <FlexColumnContainer>
                      <span style={{display: 'flex'}}>
                        Event Name<Red>*</Red>
                      </span>
                      <FormikTextField
                        name="newEvents.eventName"
                        data-testid="newEventsEmptyEventName"
                        type="text"
                        label=""
                        value={values.newEvents.eventName ? values.newEvents.eventName : ''}
                        sx={{
                          maxWidth: '439px',
                          minWidth: '439px',
                          height: '28px',
                          maxHeight: '28px',
                          marginRight: '19px',
                        }}
                      />
                    </FlexColumnContainer>
                  </FlexContainer>
                </InformationCardProfileExperienceJobText>
                <InformationCardProfileHeaderText>
                  Description
                  <FormikTextField
                    name="newEvents.eventDescription"
                    data-testid="newEventsEmptyFormEventDescription"
                    id="outlined-multiline-flexible"
                    multiline
                    rows={2}
                    type="text"
                    label=""
                    value={
                      values.newEvents.eventDescription ? values.newEvents.eventDescription : ''
                    }
                    inputProps={{
                      style: {
                        paddingTop: 5,
                        paddingBottom: 20,
                      },
                    }}
                    sx={{
                      marginBottom: '15px',
                      maxWidth: '650px',
                      minWidth: '650px',
                    }}
                  />
                </InformationCardProfileHeaderText>
              </MarginBottom15Px>
            </InformationCardDetailsContainer>
            <EventsCloseIconContainer>
              <CloseIconVerticalAlignContainer
                onClick={() => resetForm(values.newEvents)}
                style={{marginTop: '170px', cursor: 'pointer'}}>
                <CloseIcon />
              </CloseIconVerticalAlignContainer>
            </EventsCloseIconContainer>
          </FlexContainer>
        </InformationCardContainer>
        <AddAssignmentCardContainer>{addEventButtonSwitch()}</AddAssignmentCardContainer>
      </DashboardMainStyles>
    </>
  )
}
