import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker'
import {timezoneDateFunction} from '@utils/TimezoneFunction'
import {useField} from 'formik'
import React, {useEffect} from 'react'
import StyledTextField from '../mui-text-field/StyledTextField'

export default function FormikDatePicker({
  name,
  initialValue,
  maxDate,
  minDate,
  onClose,
  onChange = () => {},
  error = false,
  disabled = false,
  showIcon,
  placeholder = 'mm/dd/yyyy',
  sx,
  ...other
}: {
  name: string
  initialValue?: Date
  maxDate?: Date
  minDate?: Date
  onClose?: (va: Date) => void
  onChange?: (va: Date | null) => void
  error?: boolean
  disabled?: boolean
  showIcon?: boolean
  placeholder?: string
  sx?: object
}) {
  const [field, meta, helpers] = useField(name)

  const configTextField: any = {
    ...field,
    ...other,
    fullWidth: true,
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  useEffect(() => {
    if (initialValue) {
      helpers.setValue(initialValue)
    }
    // Adding helpers to the dependancy array causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        maxDate={maxDate}
        minDate={minDate}
        value={timezoneDateFunction(field.value)}
        onChange={(newValue) => {
          helpers.setValue(newValue)
          onChange(newValue)
        }}
        inputFormat="MM/dd/yyyy"
        renderInput={(params) => (
          <StyledTextField
            sx={{width: '100%', ...sx}}
            placeholder={placeholder}
            {...params}
            {...configTextField}
            error={error || (meta.touched && Boolean(meta.error))}
            InputProps={{
              endAdornment: (
                <CalendarTodayIcon sx={{color: 'rgba(0,0,0,0.54)', cursor: 'default'}} />
              ),
            }}
          />
        )}
        disabled={disabled}
        {...other}
      />
    </LocalizationProvider>
  )
}
