import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import {styled, useTheme} from '@mui/material/styles'
import type {TProcessedCandidate} from '@ui/candidate-list/CandidateList'
import LoadingBoundary from '@ui/loader'
import {formatShiftShowMinutes} from '@utils'
import React, {useEffect, useState} from 'react'
import type {IJobOrder} from '~/src/models/jobOrder'
import type {IPosition} from '~/src/models/position'
import {useLazyGetJobOrdersByJobOrderOrderIdsQuery} from '~/src/store/apis/job-order-api'
import {useLazyGetPositionsByIdsQuery} from '~/src/store/apis/position-api'
import {useAppSelector} from '~/src/store/store-hooks'

const StyledButton = styled(Button)({
  width: 120,
  fontWeight: 'normal',
})

const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

const BodyBox = styled(Box)({
  maxWidth: 1600,
  m: 5,
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
})

const InnerBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const LargeBold = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '28px',
  fontWeight: 600,
})

const SmallBold = styled('div')({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
})

const SmallGrey = styled('div')({
  color: '#8A8A8A',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
})

const BottomBold = styled('div')({
  fontSize: '24px',
  fontWeight: 600,
  color: '#424242',
})

const Divider = styled('div')({
  borderBottom: '1px solid #EBEBEB',
  width: '100%',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '30px 5px 0px 5px',
  justifyContent: 'center',
})

const ShadowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '650px',
  height: '149px',
  boxShadow: '2px 5px 5px 0px #0000001A',
  border: '1px solid #F1F1F1',
  borderRadius: '9px',
  padding: '20px',
})

type TSide = 'left' | 'right'

const isVisibile = (side: TSide, index: number, length: number): boolean => {
  if (side === 'left') {
    return length > 1 && index > 0
  } else {
    return length > 1 && index < length - 1
  }
}

type TDisplayData = {
  jobOrder: IJobOrder
  position: IPosition
}

interface IProps {
  isOpen: boolean
  candidateData?: TProcessedCandidate
  handleSubmit(): void
  handleCancel(): void
}

export default function AnotherAssignmentDialog({
  isOpen,
  candidateData,
  handleSubmit,
  handleCancel,
}: IProps): React.ReactElement {
  const theme = useTheme()
  const [index, setIndex] = useState<number>(0)
  const [displayData, setDisplayData] = useState<TDisplayData[] | undefined>(undefined)
  const [getJobOrdersByJobOrderOrderIds] = useLazyGetJobOrdersByJobOrderOrderIdsQuery()
  const [getPositionsByIds] = useLazyGetPositionsByIdsQuery()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  useEffect(() => {
    const init = async () => {
      const jobOrders = await getJobOrdersByJobOrderOrderIds({
        agencyId: loggedInAgencyId,
        ids: candidateData?.conflictingJobOrderIds ?? [],
      }).unwrap()
      const positions = await getPositionsByIds(
        jobOrders.map((jobOrder) => jobOrder.positionId),
      ).unwrap()
      setDisplayData(
        jobOrders.map((jobOrder) => {
          return {
            jobOrder: jobOrder,
            position: positions.find((position) => position.id === jobOrder.positionId),
          } as TDisplayData
        }),
      )
    }
    if (candidateData) {
      void init()
    }
  }, [candidateData, getJobOrdersByJobOrderOrderIds, getPositionsByIds, loggedInAgencyId])

  return (
    <Dialog
      fullWidth
      open={isOpen}
      PaperProps={{
        sx: {minWidth: theme.breakpoints.values.md, width: '1600px', paddingBottom: '30px'},
      }}>
      <HeaderContainer>
        <HeaderText>Confirmation Needed</HeaderText>
        <IconButton
          aria-label="close"
          size="large"
          onClick={handleCancel}
          style={{position: 'relative', right: '-10px'}}>
          <CloseIcon style={{color: theme.palette.grey[900]}} />
        </IconButton>
      </HeaderContainer>
      <BodyBox>
        <InnerBox>
          <LargeBold style={{marginBottom: '15px', marginTop: '15px'}}>
            Candidate is on Assignment(s)
          </LargeBold>

          <ShadowBox>
            <ArrowBackIosNewRoundedIcon
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1)
                }
              }}
              sx={{
                cursor: 'pointer',
                color: '#03A9FC',
                visibility: isVisibile('left', index, displayData?.length ?? 0)
                  ? 'visible'
                  : 'hidden',
              }}
              fontSize="large"
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <LoadingBoundary isLoading={displayData === undefined} positioned="relative">
                {displayData && (
                  <>
                    <SmallBold>{`Job Order #${displayData[index]?.jobOrder?.orderNumber}`}</SmallBold>
                    <SmallBold style={{marginTop: '5px'}}>{`${
                      displayData[index]?.position?.name
                    }, ${formatShiftShowMinutes(displayData[index]?.position)} at ${
                      displayData[index]?.position?.companyName
                    }`}</SmallBold>
                    <SmallGrey style={{marginTop: '25px', marginBottom: '5px'}}>
                      Work Location
                    </SmallGrey>
                    <SmallBold>{displayData[index]?.position?.facilityAddress.display}</SmallBold>
                    <SmallGrey
                      sx={{
                        marginTop: '5px',
                        visibility: displayData.length > 1 ? 'visible' : 'hidden',
                      }}>{`${index + 1}/${displayData.length}`}</SmallGrey>
                  </>
                )}
              </LoadingBoundary>
            </Box>
            <ArrowForwardIosRoundedIcon
              onClick={() => {
                if (displayData && index < displayData.length - 1) {
                  setIndex(index + 1)
                }
              }}
              sx={{
                cursor: 'pointer',
                color: '#03A9FC',
                visibility: isVisibile('right', index, displayData?.length ?? 0)
                  ? 'visible'
                  : 'hidden',
              }}
              fontSize="large"
            />
          </ShadowBox>
          <BottomBold style={{marginTop: '30px'}}>Are you sure you want to</BottomBold>
          <BottomBold style={{marginTop: '5px'}}>add candidate to new job order?</BottomBold>
          <Divider style={{marginTop: '20px'}} />
          <ButtonBox>
            <StyledButton
              style={{marginRight: '50px'}}
              onClick={handleCancel}
              variant="outlined"
              color="secondary"
              type="button">
              Back
            </StyledButton>
            <StyledButton onClick={handleSubmit} variant="contained" color="primary" type="button">
              Confirm
            </StyledButton>
          </ButtonBox>
        </InnerBox>
      </BodyBox>
    </Dialog>
  )
}
