import {
    AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession
} from 'amazon-cognito-identity-js';

interface ICredentials {
	email: string;
	password?: string;
}

export const UserPool = new CognitoUserPool({
	UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string,
	ClientId: process.env.REACT_APP_AWS_CLIENT_ID as string,
});

export function getCurrentSession(): Promise<CognitoUserSession | null> {
	return new Promise((resolve, reject) => {
		UserPool.getCurrentUser()?.getSession((error: unknown, session: CognitoUserSession | null) => {
			if (error) reject(error);
			else resolve(session);
		});
		resolve(null);
	});
}

export function getCognitoInfo(
	props: ICredentials,
): {
	cognitoUser: CognitoUser;
	authenticationDetails: AuthenticationDetails;
	attributeList: CognitoUserAttribute[];
} {
	const cognitoUser = new CognitoUser({
		Username: props.email,
		Pool: UserPool,
	});

	const authenticationDetails = new AuthenticationDetails({
		Username: props.email,
		Password: props.password,
	});

	const attributeList = new CognitoUserAttribute({
		Name: 'name',
		Value: props.email,
	});

	return {cognitoUser, authenticationDetails, attributeList: [attributeList]};
}
