import {createApi} from '@reduxjs/toolkit/query/react'
import type {ISuccessMessage} from '~/src/models'
import type {IOrder} from '~/src/models/order'
import type {
  IServiceOrder,
  TOrderPayload,
  TPagedServiceOrderPayload,
  TPositionOrderDistributionPayload,
  TTemplateOrderPayload,
} from '~/src/models/serviceOrder'
import {baseQuery} from '../base-query'

export const serviceOrderApi = createApi({
  reducerPath: 'ServiceOrderApi',
  tagTypes: ['ServiceOrder'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getServiceOrder: builder.query<TOrderPayload, TPagedServiceOrderPayload>({
      providesTags: ['ServiceOrder'],
      query: ({pageNumber, pageSize, filterBy, agencyId}) => ({
        url: `/api/ServiceOrder/GetServiceOrders`,
        method: 'GET',
        params: {pageNumber, pageSize, filterBy, agencyId},
      }),
    }),

    getSingleServiceOrder: builder.query<IServiceOrder, {id: string}>({
      providesTags: ['ServiceOrder'],
      query: ({id}) => ({
        url: `/api/serviceorder/${id}`,
        method: 'GET',
      }),
    }),

    getServiceOrderTemplates: builder.query<TTemplateOrderPayload, TPagedServiceOrderPayload>({
      providesTags: ['ServiceOrder'],
      query: ({pageNumber, pageSize, agencyId}) => ({
        url: `/api/ServiceOrderTemplate/GetServiceOrderTemplates`,
        method: 'GET',
        params: {pageNumber, pageSize, agencyId},
      }),
    }),

    getServiceOrderTemplateById: builder.query<TOrderPayload, {id: string; agencyId?: string}>({
      providesTags: ['ServiceOrder'],
      query: ({id, agencyId}) => ({
        url: `/api/serviceordertemplate/getServiceOrderTemplate/${id}`,
        method: 'GET',
        params: {agencyId},
      }),
    }),

    getServiceOrdersByAgencyId: builder.query<TOrderPayload, TPagedServiceOrderPayload>({
      providesTags: ['ServiceOrder'],
      query: ({pageNumber, pageSize, agencyId}) => ({
        url: `/api/ServiceOrder/GetServiceOrdersByAgencyId`,
        method: 'GET',
        params: {pageNumber, pageSize, agencyId},
      }),
    }),

    createServiceOrder: builder.mutation<IServiceOrder, Partial<IServiceOrder>>({
      invalidatesTags: ['ServiceOrder'],
      query: (body) => ({
        url: `/api/ServiceOrder/CreateServiceOrder`,
        method: 'POST',
        body: body,
      }),
    }),

    createServiceOrderTemplate: builder.mutation<
      ISuccessMessage,
      {template: Partial<IServiceOrder>; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: ({template, agencyId}) => ({
        url: `/api/ServiceOrderTemplate/CreateServiceOrderTemplate`,
        method: 'POST',
        body: template,
        params: {agencyId},
      }),
    }),

    createServiceOrderFromTemplate: builder.mutation<
      ISuccessMessage,
      {template: Partial<IServiceOrder>; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: ({template, agencyId}) => ({
        url: `/api/ServiceOrder/CreateServiceOrderFromTemplate`,
        method: 'POST',
        body: template,
        params: {agencyId},
      }),
    }),

    updateServiceOrderTemplate: builder.mutation<
      ISuccessMessage,
      {template: IServiceOrder; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: ({template, agencyId}) => ({
        url: `/api/serviceordertemplate/updateServiceOrderTemplate`,
        method: 'PUT',
        body: template,
        params: {agencyId},
      }),
    }),

    updateServiceOrder: builder.mutation<IServiceOrder, IOrder[]>({
      invalidatesTags: ['ServiceOrder'],
      query: (body) => ({
        url: `/api/serviceorder`,
        method: 'PUT',
        body: body,
      }),
    }),

    updatePositionOrderDistribution: builder.mutation<
      ISuccessMessage,
      TPositionOrderDistributionPayload
    >({
      invalidatesTags: ['ServiceOrder'],
      query: ({serviceOrderId, positionId, agencyId, body}) => ({
        url: `/api/ServiceOrder/UpdatePositionOrderDistribution`,
        method: 'PUT',
        body: body,
        params: {serviceOrderId, positionId, agencyId},
      }),
    }),

    updateMspStatusToPreparingPledges: builder.mutation<
      ISuccessMessage,
      {serviceOrderId: string; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: (params) => ({
        url: `/api/ServiceOrder/updateMspStatusToPreparingPledges`,
        method: 'PUT',
        params: params,
      }),
    }),

    updateMspStatusToConfiguringJobOrder: builder.mutation<
      ISuccessMessage,
      {serviceOrderId: string; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: (params) => ({
        url: `/api/serviceorder/updateMspStatusToConfiguringJobOrder`,
        method: 'PUT',
        params: params,
      }),
    }),

    updateAgencyStatusToConfirming: builder.mutation<
      ISuccessMessage,
      {serviceOrderId: string; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: (params) => ({
        url: `/api/serviceorder/updateAgencyStatusToConfirming`,
        method: 'PUT',
        params: params,
      }),
    }),

    submitServiceOrder: builder.mutation<ISuccessMessage, {serviceOrderId: string}>({
      invalidatesTags: ['ServiceOrder'],
      query: ({serviceOrderId}) => ({
        url: `/api/ServiceOrder/SubmitServiceOrder`,
        method: 'PUT',
        params: {serviceOrderId},
      }),
    }),

    pledgeAgencyOrder: builder.mutation<
      ISuccessMessage,
      {serviceOrderId: string; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: (params) => ({
        url: `/api/ServiceOrder/PledgeAgencyOrder`,
        method: 'PUT',
        params: params,
      }),
    }),

    acceptServiceOrder: builder.mutation<ISuccessMessage, IServiceOrder>({
      invalidatesTags: ['ServiceOrder'],
      query: ({serviceOrderId}) => ({
        url: `/api/ServiceOrder/AcceptServiceOrder`,
        method: 'PUT',
        params: {serviceOrderId},
      }),
    }),

    distributeServiceOrder: builder.mutation<
      ISuccessMessage,
      {serviceOrderId: string; agencyId?: string}
    >({
      invalidatesTags: ['ServiceOrder'],
      query: (params) => ({
        url: `/api/ServiceOrder/DistributeServiceOrder`,
        method: 'PUT',
        params: params,
      }),
    }),
  }),
})

export const {
  useGetServiceOrderQuery,
  useGetServiceOrderTemplatesQuery,
  useGetServiceOrderTemplateByIdQuery,
  useGetSingleServiceOrderQuery,
  useLazyGetSingleServiceOrderQuery,
  useCreateServiceOrderFromTemplateMutation,
  useCreateServiceOrderTemplateMutation,
  useUpdateServiceOrderTemplateMutation,
  useLazyGetServiceOrderQuery,
  useGetServiceOrdersByAgencyIdQuery,
  useCreateServiceOrderMutation,
  useSubmitServiceOrderMutation,
  useDistributeServiceOrderMutation,
  usePledgeAgencyOrderMutation,
  useAcceptServiceOrderMutation,
  useUpdateServiceOrderMutation,
  useUpdatePositionOrderDistributionMutation,
  useUpdateMspStatusToPreparingPledgesMutation,
  useUpdateMspStatusToConfiguringJobOrderMutation,
  useUpdateAgencyStatusToConfirmingMutation,
} = serviceOrderApi
