import {Tooltip} from '@mui/material'
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress'
import LoadingBoundary from '@ui/loader'
import {formatShift} from '@utils/format-shift'
import {push} from 'connected-react-router'
import {
  differenceInDays,
  differenceInHours,
  getDate,
  getMonth,
  getYear,
  isFuture,
  setDate,
  setMonth,
  setYear,
  startOfDay,
} from 'date-fns'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {ReactComponent as FormatListBulletedIcon} from '~/src/assets/images/FormatListIcon.svg'
import {ReactComponent as SearchIcon} from '~/src/assets/images/SearchIcon.svg'
import type {IJobOrder} from '~/src/models/jobOrder'
import type {IPosition} from '~/src/models/position'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useLazyGetSingleServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {updateJobOrderId} from '~/src/store/slices/job-order-slice'
import {useAppSelector} from '~/src/store/store-hooks'
import CalendarDayGraphic from './CalendarDayGraphic'
import {
  BodyLabelText,
  CircularProgressWithLabelContainer,
  CircularProgressWithLabelSubContainer,
  ClientText,
  DashboardMainStyles,
  FilledText,
  FooterContainer,
  HeaderContainer,
  HeaderMarginFlexContainer,
  HeaderMarginFlexSpaceBetweenContainer,
  InnerIconContainer,
  InnerSubIconContainer,
  JobOrderCardContainer,
  JobOrderText,
  MidSectionContainer,
  PercentLeftText,
  PositionNameAndJOContainer,
  PositionText,
  RatioText,
  ShiftText,
  SubFooterContainer,
  SubFooterContainer2,
  SubMidSectionContainer,
  SubMidSectionContainer2,
  TimeLeftText,
} from './JobOrderCardStyles.styles'

function CircularProgressWithLabel(
  props: CircularProgressProps & {value: number; currentlyfilled: number; totalrequested: number},
) {
  return (
    <CircularProgressWithLabelContainer>
      <CircularProgress
        sx={{
          minWidth: 118,
          minHeight: 118,
          color: '#03A9FC',
          borderRadius: '50%',
          boxShadow: 'inset 0 0 1px 9px #707070',
        }}
        variant="determinate"
        {...props}
      />
      <CircularProgressWithLabelSubContainer>
        <InnerIconContainer>
          <InnerSubIconContainer>
            <FilledText>Filled</FilledText>

            <RatioText>
              <span
                style={{color: '#03A9FC', marginRight: '2px'}}>{`${props.currentlyfilled} `}</span>
              {`/${props.totalrequested}`}
            </RatioText>
          </InnerSubIconContainer>
        </InnerIconContainer>
      </CircularProgressWithLabelSubContainer>
    </CircularProgressWithLabelContainer>
  )
}

interface IProps {
  jobOrder?: IJobOrder
  distributionDateOverride?: Date
  currentlyfilled: number
  onListIconClick?: (value: boolean) => void
  position?: IPosition
  totalrequested: number
  serviceOrder?: IServiceOrder
  style?: object
  onCardClick?: (id: string) => void
  isSuccess?: boolean
  enableButtonColor?: boolean
  showList?: boolean
}

export default function JobOrderCard({
  jobOrder,
  distributionDateOverride,
  currentlyfilled,
  onListIconClick,
  position,
  totalrequested,
  serviceOrder,
  style,
  onCardClick,
  isSuccess,
  enableButtonColor = false,
  showList = false,
}: IProps) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [activeLink, setActiveLink] = useState<'search' | 'list'>(showList ? 'list' : 'search')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {
    date: jobOrderDate,
    orderNumber: jobOrderNumber,
    positionId,
    serviceOrderId,
    id,
  } = jobOrder ?? {}

  const {
    data: queriedPositionData,
    isLoading,
    refetch: positionRefetch,
  } = useGetPositionByIdQuery({
    id: positionId as string,
  })

  const date = (distributionDateOverride ?? jobOrderDate) as Date
  const positionData = position ? position : queriedPositionData

  const {data: companyName, refetch: companyRefetch} = useGetCompanyNameByIdQuery({
    id: positionData?.companyId,
  })

  const [unpassedServiceOrder, setServiceOrderToUse] = useState<IServiceOrder | undefined>(
    undefined,
  )

  const [getServiceOrder] = useLazyGetSingleServiceOrderQuery()

  const handleCardClick = () => {
    if (onCardClick) {
      onCardClick(id as string)
    } else {
      dispatch(updateJobOrderId({jobOrderId: id}))

      const isMsp =
        loggedInAgencyId === (serviceOrder ? serviceOrder.agencyId : unpassedServiceOrder?.agencyId)

      if (isMsp) {
        dispatch(
          push(
            `${BASE_ROUTES.AGENCY_ADMIN}${
              SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
            }/${encodeURIComponent(id as string)}`,
          ),
        )
      } else {
        dispatch(
          push(
            `${BASE_ROUTES.AGENCY}${SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY}/${encodeURIComponent(
              id as string,
            )}`,
          ),
        )
      }
    }
  }

  const progress = totalrequested ? Math.floor((currentlyfilled / totalrequested) * 100) : undefined

  const startOfDistributionDate = startOfDay(new Date(date))
  const year = getYear(startOfDistributionDate)
  const month = getMonth(startOfDistributionDate)
  const day = getDate(startOfDistributionDate)

  const startTime = new Date(positionData?.startTime as string)
  let intermediateResult = setYear(startTime, year)
  intermediateResult = setMonth(intermediateResult, month)
  const exactStartTime = setDate(intermediateResult, day)
  const currentTime = new Date()

  let daysLeft = 0
  let hoursLeft = 0

  if (isFuture(exactStartTime)) {
    daysLeft = differenceInDays(exactStartTime, currentTime)
    const totalHoursRemaining = differenceInHours(exactStartTime, currentTime)
    hoursLeft = totalHoursRemaining % 24
  }

  useEffect(() => {
    const callgetServiceOrderQuery = async (serviceOrderIdFromJobOrder: string) => {
      const serviceOrderFromInside = await getServiceOrder({
        id: serviceOrderIdFromJobOrder,
      }).unwrap()
      setServiceOrderToUse(serviceOrderFromInside)
    }
    if (!serviceOrder) {
      void callgetServiceOrderQuery(serviceOrderId as string)
    }
  }, [getServiceOrder, serviceOrder, serviceOrderId, companyName, queriedPositionData, isSuccess])

  const shiftText = formatShift(positionData)

  //keeps circle full if theres more candidates than requested
  const adjustedProgress = progress && progress > 100 ? 100 : progress

  return (
    <LoadingBoundary isLoading={isLoading}>
      <DashboardMainStyles.Container
        style={{
          flexBasis: '',
          // for summary page spacing
          marginRight: location.pathname.includes('summary') ? '0px' : '23px',
        }}>
        <JobOrderCardContainer style={{...style}} onClick={handleCardClick}>
          <HeaderContainer>
            <HeaderMarginFlexSpaceBetweenContainer>
              <HeaderMarginFlexContainer>
                <Tooltip title={`Order #${jobOrderNumber}`}>
                  <JobOrderText
                    aria-label={`Order #${jobOrderNumber}`}>{`Order #${jobOrderNumber}`}</JobOrderText>
                </Tooltip>
                <PositionNameAndJOContainer>
                  <Tooltip title={`${positionData?.name}`}>
                    <PositionText
                      aria-label={`${positionData?.name}`}>{`${positionData?.name}`}</PositionText>
                  </Tooltip>
                </PositionNameAndJOContainer>
                <ShiftText aria-label={shiftText}>{shiftText}</ShiftText>
                <ClientText aria-label={companyName}>{companyName}</ClientText>
              </HeaderMarginFlexContainer>
              <HeaderMarginFlexContainer>
                <CalendarDayGraphic
                  aria-label={`Calendar Date ${new Date(date)}`}
                  date={new Date(date)}
                  colorUnselected="#828282"
                />
              </HeaderMarginFlexContainer>
            </HeaderMarginFlexSpaceBetweenContainer>
          </HeaderContainer>
          <MidSectionContainer>
            <>
              <SubMidSectionContainer>
                <CircularProgressWithLabel
                  value={adjustedProgress as number}
                  totalrequested={totalrequested}
                  currentlyfilled={currentlyfilled}
                />
              </SubMidSectionContainer>
              <SubMidSectionContainer2>
                <BodyLabelText>Time Left</BodyLabelText>
                <TimeLeftText>{`${daysLeft}D:${hoursLeft}H`}</TimeLeftText>
                <BodyLabelText>Fill Rate</BodyLabelText>
                <PercentLeftText>{`${progress ?? '-'}%`}</PercentLeftText>
              </SubMidSectionContainer2>
            </>
          </MidSectionContainer>

          <FooterContainer style={{paddingBottom: '0px'}}>
            <SubFooterContainer>
              <div />
            </SubFooterContainer>
            <SubFooterContainer2>
              <SearchIcon
                aria-label="job-order-search-icon"
                data-testid="job-order-search-icon"
                fill={activeLink === 'search' && enableButtonColor ? '#03a9fc' : '#858585'}
                stroke={activeLink === 'search' && enableButtonColor ? '#03a9fc' : '#858585'}
                onClick={() => {
                  setActiveLink('search')
                  onListIconClick?.(true)
                }}
                style={{
                  cursor: 'pointer',
                  marginRight: '7px',
                }}
              />

              <FormatListBulletedIcon
                aria-label="job-order-list-icon"
                data-testid="job-order-list-icon"
                stroke={activeLink === 'list' && enableButtonColor ? '#03a9fc' : '#858585'}
                onClick={(e) => {
                  setActiveLink('list')
                  e.stopPropagation()
                  onListIconClick?.(false)
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
              {/* //TODO uncomment when we want this svg. its in the figma. File called PersonCall.svg */}
              {/* <PersonCall /> */}
            </SubFooterContainer2>
          </FooterContainer>
        </JobOrderCardContainer>
      </DashboardMainStyles.Container>
    </LoadingBoundary>
  )
}
