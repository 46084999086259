import styled from 'styled-components'

export const BodyContainer = styled('div')`
  display: flex;
  margin-top: 37px;
  height: 170px;
`

export const TextFieldContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TextFieldLabelText = styled('div')`
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
`

export const PrivateFolderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
`
