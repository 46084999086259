import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function AppleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='social / Apple'>
				<g id='Apple'>
					<rect id='Rectangle' width='48' height='48' rx='24' fill='#888888' />
					<path
						id='Vector'
						d='M24.1905 16.7447C23.0055 16.7447 21.1712 15.3974 19.2394 15.4461C16.6909 15.4785 14.3533 16.9233 13.0385 19.2121C10.3925 23.806 12.3567 30.5914 14.9377 34.325C16.2039 36.1431 17.6973 38.1884 19.6777 38.1235C21.577 38.0423 22.2912 36.8898 24.5963 36.8898C26.8852 36.8898 27.5345 38.1235 29.5474 38.0748C31.5927 38.0423 32.8914 36.2242 34.1413 34.3899C35.586 32.2796 36.1866 30.2343 36.2191 30.1206C36.1704 30.1044 32.242 28.5947 32.1933 24.0495C32.1609 20.251 35.2938 18.4329 35.4399 18.3518C33.6543 15.7383 30.9109 15.4461 29.9532 15.3811C27.4533 15.1863 25.3593 16.7447 24.1905 16.7447ZM28.4111 12.9137C29.4662 11.6476 30.1642 9.87816 29.9694 8.125C28.4598 8.18993 26.6417 9.13144 25.5541 10.3976C24.5801 11.5177 23.736 13.3195 23.9632 15.0402C25.6352 15.1701 27.3559 14.1799 28.4111 12.9137Z'
						fill='white'
					/>
				</g>
			</g>
		</Svg>
	);
}
