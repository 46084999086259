import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

const Svg = styled.svg``

export default function CloseIcon(props: HTMLAttributes<unknown>): JSX.Element {
  return (
    <Svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.335202 10.0462C-0.111734 10.4931 -0.111734 11.2177 0.335202 11.6647C0.782139 12.1116 1.50677 12.1116 1.9537 11.6647L6.00004 7.61833L10.0465 11.6648C10.4935 12.1118 11.2181 12.1118 11.665 11.6648C12.112 11.2179 12.112 10.4933 11.665 10.0463L7.61854 5.99983L11.6647 1.95367C12.1116 1.50673 12.1116 0.782107 11.6647 0.335172C11.2178 -0.111764 10.4931 -0.111765 10.0462 0.335171L6.00004 4.38133L1.95403 0.335325C1.5071 -0.111611 0.78247 -0.11161 0.335534 0.335326C-0.111403 0.782261 -0.111403 1.50689 0.335533 1.95382L4.38154 5.99983L0.335202 10.0462Z"
        fill="currentColor"
      />
    </Svg>
  )
}
