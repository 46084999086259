import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React from 'react'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useAppSelector} from '~/src/store/store-hooks'
import EditAgencyForm from '../../common/manage-profile/EditAgency'
import Title from '../../layout/Title'
import type {TActivePlatform} from '../../platform/onboarding/AddCompanyForm'

export default function ManageAgency() {
  const [activePlatform] = React.useState<TActivePlatform>('Company')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, error, isLoading, refetch} = useGetOneAgencyQuery(loggedInAgencyId)

  return (
    <>
      <Title pageTitle="Manage Agency" />
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading}>
          {data ? <EditAgencyForm activePlatform={activePlatform} initialEditValues={data} /> : ''}
        </LoadingBoundary>
      </ErrorBoundary>
    </>
  )
}
