import type {FormikErrors} from 'formik'
import React, {useEffect, useState} from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateEmergencyContactEdit from '../CandidateProfileDisplayandEdit/MspCandidateEmergencyContactEdit'
import MspCandidateEmergencyContactDisplay from '../CandidateProfileDisplayandEdit/MspCandidateEmergencyContactDisplay'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm: any
  setFieldValue(field: string, value: string, shouldValidate?: boolean | undefined): void
  values: Applicant
  errors: FormikErrors<Applicant>
}

export default function MspCandidateEmergencyContact({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
  errors,
}: IProps) {
  const [editProfileEmergencyState, setEditProfileEmergencyState] = useState('saveChanges')
  const handleSaveOrClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileEmergencyState('edit')
    } else if (errors.emergencyPhone) {
      return
    } else {
      await handleSubmit(values)
      setEditProfileEmergencyState('saveChanges')
    }
  }

  const handleCancel = () => {
    resetForm({values: oldApplicant})
    setEditProfileEmergencyState('saveChanges')
  }

  useEffect(() => {
    setEditProfileEmergencyState('saveChanges')
  }, [oldApplicant.id])

  const projectContact = () => {
    switch (editProfileEmergencyState) {
      case 'edit':
        return (
          <MspCandidateEmergencyContactEdit
            handleCancel={handleCancel}
            handleSave={handleSaveOrClick}
            oldApplicant={oldApplicant}
            setFieldValue={setFieldValue}
            applicant={values}
          />
        )
      case 'saveChanges':
        return (
          <MspCandidateEmergencyContactDisplay handleClick={handleSaveOrClick} applicant={values} />
        )

      default:
        return (
          <MspCandidateEmergencyContactDisplay handleClick={handleSaveOrClick} applicant={values} />
        )
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectContact()}</DashboardMainStyles>
    </>
  )
}
