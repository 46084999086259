import Mui from '@ui/mui'
import {FieldArray, FormikValues} from 'formik'
import React from 'react'
import type {IAgencyBase, ICompanyBase} from '~/src/models'
import AddressFieldItem from './CompanyAddressItem'
import createAddressItem from './helpers/createAddress'

interface IProps {
  values?: FormikValues
  activePlatform: string
  setFieldValue: (field: string, value: unknown) => void
  initialEditValues: IAgencyBase | ICompanyBase | undefined
}

export default function CompanyAddressList(props: IProps) {
  const {addresses} = props.values ?? {}

  return (
    <FieldArray name="addresses">
      {({push, remove}) => (
        <React.Fragment>
          {addresses.length > 0 &&
            addresses.map((address: {id: string}, index: number) => (
              <AddressFieldItem
                key={address.id}
                addresses={addresses}
                initialEditValues={props.initialEditValues}
                index={index}
                remove={remove}
                setFieldValue={props.setFieldValue}
                activePlatform={props.activePlatform}
              />
            ))}

          <Mui.Grid item xs={3} />

          <Mui.Grid item xs={9}>
            <Mui.Button
              variant="text"
              size="small"
              sx={{color: 'primary.dark', p: 0, mt: 4}}
              onClick={() => push(createAddressItem())}>
              + Add Another Location
            </Mui.Button>
          </Mui.Grid>
        </React.Fragment>
      )}
    </FieldArray>
  )
}
