import {Alert, Button, Snackbar} from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import BasicSuccessDialog from '@ui/dialog/BasicSuccessDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import {Formik, Form as FormikForm} from 'formik'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetAgenciesQuery, useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {
  useDistributeServiceOrderMutation,
  useGetSingleServiceOrderQuery,
  useUpdateMspStatusToPreparingPledgesMutation,
} from '~/src/store/apis/service-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import NonMspAgencyOrderDetailsTable from '../../../nonmsp/manage-orders/nonmsp-agency-tables/NonMspAgencyOrderDetailsTable'
import AgencyOrderDetailsTable from './AgencyOrderDetailsTable'

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

interface IPopupSwitchProps {
  serviceOrderId: string
  successDialog: boolean
  setSuccessDialog: (value: boolean) => void
  distributeDialog: boolean
  setDistributeDialog: (value: boolean) => void
  commitDialog: boolean
  setCommitDialog: (value: boolean) => void
  pledgeDialog: boolean
  setPledgeDialog: (value: boolean) => void
  distributeToSelfDialog: boolean
  setDistributeToSelfDialog: (value: boolean) => void
}

const PopupSwitch = ({
  serviceOrderId,
  successDialog,
  setSuccessDialog,
  distributeDialog,
  setDistributeDialog,
  commitDialog,
  setCommitDialog,
  pledgeDialog,
  setPledgeDialog,
  distributeToSelfDialog,
  setDistributeToSelfDialog,
}: IPopupSwitchProps) => {
  const dispatch = useAppDispatch()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [distributeServiceOrder] = useDistributeServiceOrderMutation()
  return (
    <>
      {/* Distribute */}
      <BasicSuccessDialog
        isOpen={distributeDialog && successDialog}
        header="Order Successfully Distributed"
        bigBlurb="Order Successfully Distributed"
        smallBlurb="Your Service Order has been distributed. Please wait until the Agency reviews and pledges."
        handleSubmit={() => {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`))
          setDistributeDialog(false)
          setSuccessDialog(false)
        }}
      />
      <BasicConfirmationDialog
        isOpen={distributeDialog && !successDialog}
        header="Order will be Sent to Recruiter(s)"
        bigBlurb="Please Confirm Distribution"
        smallBlurb="Are you ready to Distribute?"
        cancelButtonLabel="Back"
        handleCancel={() => {
          setDistributeDialog(false)
        }}
        handleSubmit={async () => {
          void (await distributeServiceOrder({
            serviceOrderId: serviceOrderId,
            agencyId: loggedInAgencyId,
          }))
          setSuccessDialog(true)
        }}
      />

      {/* Commit */}
      <BasicConfirmationDialog
        isOpen={commitDialog && !successDialog}
        header="Ready to Configure Job Orders"
        bigBlurb="Please Confirm Service Order to Job Order Transition"
        bigBlurbStyle={{fontSize: '22px'}}
        smallBlurb="Are you ready to transition this service order to a job order?"
        cancelButtonLabel="Back"
        handleCancel={() => {
          setCommitDialog(false)
        }}
        handleSubmit={async () => {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}/manage-job-orders/${serviceOrderId}`))
          setCommitDialog(false)
        }}
      />

      {/* Pledge */}
      <BasicSuccessDialog
        isOpen={pledgeDialog && successDialog}
        header="Order Successfully Pledged"
        bigBlurb="Order Successfully Pledged"
        smallBlurb="Your Service Order has been pledged. Please wait until the MSP reviews and commits."
        handleSubmit={() => {
          setPledgeDialog(false)
          setSuccessDialog(false)
        }}
      />
      <BasicConfirmationDialog
        isOpen={pledgeDialog && !successDialog}
        header="Pledge will be sent to MSP"
        bigBlurb="Please Confirm Pledge"
        smallBlurb="Are you ready to Pledge?"
        cancelButtonLabel="Back"
        handleCancel={() => {
          setPledgeDialog(false)
        }}
        handleSubmit={async () => {
          setSuccessDialog(true)
        }}
      />

      {/* Distribute to self */}
      <BasicConfirmationDialog
        isOpen={distributeToSelfDialog}
        header="Order will transition to Job Orders"
        bigBlurb="Ready to Configure Job Orders"
        smallBlurb="Are you ready to transition the Service Order to a Job Order?"
        cancelButtonLabel="Back"
        handleCancel={() => {
          setDistributeToSelfDialog(false)
        }}
        handleSubmit={async () => {
          dispatch(push(`/agency-admin/manage-job-orders/${serviceOrderId}`))
          setDistributeToSelfDialog(false)
        }}
      />
    </>
  )
}

interface IParams {
  serviceOrderId: string
}

export default function AgencyOrderDetails() {
  const [distributeDialog, setDistributeDialog] = useState(false)
  const [distributeToSelfDialog, setDistributeToSelfDialog] = useState(false)
  const [pledgeDialog, setPledgeDialog] = useState(false)
  const [commitDialog, setCommitDialog] = useState(false)
  const [successDialog, setSuccessDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: currentAgency} = useGetOneAgencyQuery(loggedInAgencyId)
  const {serviceOrderId}: IParams = useParams()
  const history = useHistory()

  const {
    data: matchServiceOrder,
    refetch: matchServiceOrderRefetch,
    isLoading,
  } = useGetSingleServiceOrderQuery({id: serviceOrderId})
  const {data: companyName} = useGetCompanyNameByIdQuery({id: matchServiceOrder?.companyId})
  const [updateMspStatusToPreparingPledgesMutation] = useUpdateMspStatusToPreparingPledgesMutation()

  useEffect(() => {
    const updateMspStateOnOpen = () => {
      void updateMspStatusToPreparingPledgesMutation({
        serviceOrderId: serviceOrderId,
        agencyId: loggedInAgencyId,
      })
    }
    updateMspStateOnOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMspStatusToPreparingPledgesMutation])

  const {
    data: allAgencies,
    isFetching,
    refetch,
  } = useGetAgenciesQuery({
    pageNumber: 1,
    pageSize: 999999999,
  })

  let nonMspNeeded = 0

  const needed =
    matchServiceOrder?.agencyId === loggedInAgencyId
      ? matchServiceOrder?.orders
          .map((order) => Number(order.quantity))
          .reduce((prev, curr) => prev + curr, 0)
      : matchServiceOrder?.orders.forEach((i) => {
          i.orderDistribution.agencyDistributions?.forEach((t) => {
            t.agencyQuantityDistributions?.forEach((l) => {
              nonMspNeeded += l.requested
            })
          })
        })

  const pledged = matchServiceOrder?.orders
    .map((order) => order.orderDistribution.agencyDistributions)
    ?.flat()
    ?.map((agencyQuantityDistribution) => agencyQuantityDistribution?.agencyQuantityDistributions)
    .flat()
    .map((distribution) => {
      if (distribution?.pledged) {
        return distribution.pledged
      } else {
        return 0
      }
    })
    ?.reduce((prev, curr) => prev + curr, 0)

  const requested = matchServiceOrder?.orders
    .map((order) => order.orderDistribution.agencyDistributions)
    .flat()
    .map((agencyQuantityDistribution) => agencyQuantityDistribution?.agencyQuantityDistributions)
    .flat()
    .map((distribution) => {
      if (distribution?.requested) {
        return distribution.requested
      } else {
        return 0
      }
    })
    .reduce((prev: number, curr: number) => prev + curr, 0)

  const handleSubmit = async () => {
    setSuccessDialog(true)
  }

  const isPledgeToSelfOnly =
    matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.length === 1 &&
    matchServiceOrder.orders[0]?.orderDistribution.agencyDistributions[0]?.agencyId ===
      loggedInAgencyId

  useEffect(() => {
    void refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, pledged])
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={matchServiceOrder ? matchServiceOrder : []}>
      {({isSubmitting}) => {
        return (
          <FormikForm>
            <PopupSwitch
              serviceOrderId={serviceOrderId}
              successDialog={successDialog}
              setSuccessDialog={setSuccessDialog}
              distributeDialog={distributeDialog}
              setDistributeDialog={setDistributeDialog}
              commitDialog={commitDialog}
              setCommitDialog={setCommitDialog}
              pledgeDialog={pledgeDialog}
              setPledgeDialog={setPledgeDialog}
              distributeToSelfDialog={distributeToSelfDialog}
              setDistributeToSelfDialog={setDistributeToSelfDialog}
            />
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              {open ? (
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                  You have satisfied 100% of the requests.
                </Alert>
              ) : (
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                  failure
                </Alert>
              )}
            </Snackbar>
            <AgencyOrderDetailsStyles.Container>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    marginRight: '15px',
                    marginBottom: '4px',
                  }}>
                  <h2>Manage Order Details</h2>
                </div>
              </Box>
              <ErrorBoundary>
                <LoadingBoundary isLoading={isLoading}>
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 20px 10px 16px',
                        fontWeight: '600',
                        backgroundColor: '#FFFFFF',
                        fontStyle: 'normal',
                        fontSize: '15px',
                        lineHeight: '20px',
                        marginTop: '63px',
                      }}>
                      <div style={{display: 'flex', justifyItems: 'center'}}>
                        <AgencyOrderDetailsStyles.TableInfo>
                          <div style={{margin: 10}}>
                            Service Order:{' '}
                            <span style={{color: '#03a9fc'}}>
                              #{matchServiceOrder?.orderNumber}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 10,
                              marginBottom: 20,
                              paddingTop: 10,
                            }}>
                            Needed:{' '}
                            {matchServiceOrder?.agencyId === loggedInAgencyId
                              ? needed
                              : nonMspNeeded}{' '}
                            | Requested: {requested ? requested : 0} | Pledged:{' '}
                            {pledged ? pledged : 0}
                          </div>
                        </AgencyOrderDetailsStyles.TableInfo>
                        {matchServiceOrder?.agencyId === loggedInAgencyId ? (
                          <AgencyOrderDetailsStyles.TableInfo>
                            <div style={{margin: 10}}>Company Name: {companyName}</div>
                          </AgencyOrderDetailsStyles.TableInfo>
                        ) : null}
                      </div>
                      <div>
                        <Button
                          onClick={history.goBack}
                          variant="outlined"
                          color="secondary"
                          style={{marginRight: '18px'}}>
                          Back
                        </Button>
                        {matchServiceOrder?.agencyId === loggedInAgencyId ? (
                          matchServiceOrder?.mspStatus === 'Ready To Distribute' ? (
                            <Tooltip title="Ready To Distribute.">
                              <span>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setDistributeDialog(true)}>
                                  Distribute Pledges
                                </Button>
                              </span>
                            </Tooltip>
                          ) : matchServiceOrder?.mspStatus === 'Pledges Received' &&
                            isPledgeToSelfOnly ? (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={() => setDistributeToSelfDialog(true)}>
                              Distribute Job Order
                            </Button>
                          ) : matchServiceOrder?.mspStatus === 'Pledges Received' ? (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={() => setCommitDialog(true)}>
                              Configure
                            </Button>
                          ) : null
                        ) : matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.[0]
                            ?.agencyStatus === 'Confirming' ? (
                          <Button disabled={isSubmitting} onClick={() => setPledgeDialog(true)}>
                            Pledge
                          </Button>
                        ) : matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.[0]
                            ?.agencyStatus === 'New Order' ? (
                          <Tooltip title="New Order.">
                            <span>
                              <Button variant="contained" color="primary" disabled>
                                Pledge
                              </Button>
                            </span>
                          </Tooltip>
                        ) : matchServiceOrder?.orders[0]?.orderDistribution.agencyDistributions?.[0]
                            ?.agencyStatus === 'Confirmed' ? (
                          <Tooltip title="This order has been pledged.">
                            <span>
                              <Button variant="contained" color="primary" disabled>
                                Pledge
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <Button variant="contained" color="primary" disabled>
                            Pledge
                          </Button>
                        )}
                      </div>
                    </Box>
                    {matchServiceOrder && matchServiceOrder.agencyId === loggedInAgencyId ? (
                      <AgencyOrderDetailsTable
                        currentAgency={currentAgency}
                        allAgencies={allAgencies}
                        serviceOrder={matchServiceOrder}
                        companyName={companyName}
                        setOpen={setOpen}
                        needed={needed}
                      />
                    ) : matchServiceOrder ? (
                      <NonMspAgencyOrderDetailsTable
                        currentAgency={currentAgency}
                        allAgencies={allAgencies}
                        serviceOrder={matchServiceOrder}
                        setOpen={setOpen}
                        needed={needed}
                      />
                    ) : null}
                  </>
                </LoadingBoundary>
              </ErrorBoundary>
            </AgencyOrderDetailsStyles.Container>
          </FormikForm>
        )
      }}
    </Formik>
  )
}
