import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import type {TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'
import PrependedTemplateTotalHeader from '../../msp/manage-templates/PrependedTemplateTotalHeader'

const CreateButton = styled.button`
  border: 1px solid #03a9fc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #005599;
  width: 96px;
  height: 29px;
`

interface TemplateTableProps {
  data: TServiceOrderTemplatePayload
  currentPageNumber: number
  onPaginationChange(value: number): void
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Template Name',
    align: 'left',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: true,
    label: 'Created By',
    align: 'left',
  },

  {
    id: 'editedBy',
    numeric: true,
    disablePadding: true,
    label: 'Edited By',
    align: 'left',
  },

  {
    id: 'createOrder',
    numeric: true,
    disablePadding: true,
    label: 'Create Order',
    align: 'center',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{height: 65}}>
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function ManageTemplatesTable({
  data,
  currentPageNumber,
  onPaginationChange,
}: TemplateTableProps) {
  const getEditedByValue = (date: string) => {
    return date === '0001-01-01T00:00:00Z' || date === undefined || date === null
      ? '---'
      : new Date(date).toLocaleDateString()
  }

  return (
    <>
      {data.serviceOrderTemplatesModel && (
        <Box
          sx={{
            width: '100%',
            mb: 10,
            backgroundColor: '#FFFFFF',
            border: '1px solid #CDCED9',
            borderRadius: '4px',
          }}>
          <PrependedTemplateTotalHeader data={data} />
          <TableContainer>
            <Table
              sx={{minWidth: 750, height: 65}}
              aria-labelledby="tableTitle"
              size="small"
              padding="normal">
              <EnhancedTableHead />
              <TableBody>
                {data.serviceOrderTemplatesModel.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{textDecoration: 'none', height: 65}}
                      datatest-id="table-row"
                      hover
                      tabIndex={-1}>
                      <StyledTableCell />
                      <StyledTableCell
                        component="th"
                        textDecoration="none"
                        scope="row"
                        padding="normal"
                        sx={{maxWidth: '200px'}}>
                        {row.name}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.createdBy}
                        <br />
                        <span style={{fontWeight: 300}}>{getEditedByValue(row.createdDate)}</span>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.modifiedBy ? row.modifiedBy : '---'}
                        <br />
                        <span style={{fontWeight: 300}}>{getEditedByValue(row.modifiedDate)}</span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <NavLink
                          to={{
                            pathname: 'manage-orders/add-order',
                            state: {...row, isTemplate: true, isMsp: false},
                          }}>
                          <CreateButton value={row.id}>Create Order</CreateButton>
                        </NavLink>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledPagination
            page={currentPageNumber}
            totalRecords={data?.totalServiceOrderTemplates}
            recordsPerPage={10}
            onChange={onPaginationChange}
          />
        </Box>
      )}
    </>
  )
}
