import {Box, Dialog, FormControl, IconButton, SelectChangeEvent, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import CloseIcon from '@ui/icons/Close'
import React, {useState} from 'react'
import styled from 'styled-components'
import DeclineReasons from './DeclineReasons'
import SkipReasons from './SkipReasons'

interface DeclineCandidateProps {
  open: boolean
  varient: 'skip' | 'decline' | 'skipCleanup'
  onCloseDeclineModal: () => void
  onSkipOrDeclineCandidate: (
    notes: string,
    skipOrDecline: 'skip' | 'decline' | 'skipCleanup',
  ) => void
}

const TitleBox = styled(Box)`
  display: flex;
  padding: 20px 56px;
  margin-bottom: 53px;
  justify-content: space-between;
`
const TitleText = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1f1f1f;
`

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const SkipOrDeclineCandidateDialog = ({
  open,
  onCloseDeclineModal,
  onSkipOrDeclineCandidate,
  varient,
}: DeclineCandidateProps) => {
  const [notes, setNotes] = useState('')

  const handleSelectNotes = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string
    setNotes(value)
  }

  const handleSubmit = () => {
    onSkipOrDeclineCandidate(notes, varient)
    setNotes('')
  }

  const titleText =
    varient === 'decline' ? 'Candidate will be Declined' : 'Candidate will be Skipped'

  return (
    <Dialog open={open} onClose={onCloseDeclineModal} fullWidth={true} maxWidth="md">
      <TitleBox>
        <TitleText>{titleText}</TitleText>
        <IconButton onClick={onCloseDeclineModal}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <Content>
        <DialogContentText sx={{fontWeight: '600', fontSize: '28px', lineHeight: '34px'}}>
          {varient === 'decline' ? 'Decline Candidate?' : 'Skip Candidate?'}
        </DialogContentText>
        <DialogContentText
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '19px',
            marginTop: '30px',
            color: '#8A8A8A',
          }}>
          Are you sure you want to {varient} this candidate?
          <br />
          Please select a reason from down below.
        </DialogContentText>
        <FormControl sx={{minWidth: '390px', marginTop: '37px'}}>
          {varient === 'decline' ? (
            <DeclineReasons notes={notes} onSelectNotes={handleSelectNotes} />
          ) : (
            <SkipReasons notes={notes} onSelectNotes={handleSelectNotes} varient={varient} />
          )}
        </FormControl>
      </Content>

      <Box sx={{margin: '100px auto 60px auto'}}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCloseDeclineModal}
          sx={{marginRight: '32px'}}>
          Cancel
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleSubmit} disabled={!notes}>
          {varient === 'decline' ? 'Decline' : 'Skip'}
        </Button>
      </Box>
    </Dialog>
  )
}

export default SkipOrDeclineCandidateDialog
