/* eslint-disable complexity */
import {Button, Grid, IconButton as MuiIconButton, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import {emphasize, styled, useTheme} from '@mui/material/styles'
import {FormikSelect, Icon, MenuItem} from '@ui/components'
import Mui from '@ui/mui'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import type {FormikValues} from 'formik'
import React, {useEffect, useState} from 'react'
import type {TServiceOrderTemplatePayload} from '~/src/models/serviceOrder'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useGetPositionQuery} from '~/src/store/apis/position-api'
import SelectOrderDialog from '../../company/manage-orders/Dialogs/SelectPositionDialog'
import {formatShift} from '@utils'
import {useAppSelector} from '~/src/store/store-hooks'

interface IProps {
  errors: any
  values: FormikValues
  initialValues?: TServiceOrderTemplatePayload
  remove: any
  index: number
  onModalClose(): void
  onOpenOrderModal(): void
  modalOpen: boolean
  currentPageNumber: number
  setFieldValue: (field: string, value: unknown) => void
  handleChange: string | number | boolean | object | null
}

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const IconButton = styled(MuiIconButton)(({theme}) => ({
  borderRadius: 4,
  color: '#C4C4C4',
  height: 38,
  width: 38,

  '& svg': {
    color: emphasize(theme.palette.secondary.main, 0.2),
  },
}))

export default function AddTemplateItem(props: IProps) {
  const {index, setFieldValue, handleChange, values, initialValues, errors, remove} = props
  const theme = useTheme()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [orderModalVisible, setOrderModalVisible] = useState(false)
  const [selectedPositionName, setSelectedPositionName] = useState<string>()
  const [selectedPositionId, setSelectedPositionId] = useState<string>()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data: dataPosition, refetch: refetchPosition} = useGetPositionQuery({
    pageNumber: currPageIdx,
    pageSize: 10000,
    agencyId: loggedInAgencyId,
  })

  const handleDialog = (positionId: string) => {
    setSelectedPositionId(positionId)
    setFieldValue(`orders.${index}.positionId`, positionId)
  }

  const filteredPositionFromClient = values
    ? [
        ...new Set(
          dataPosition?.positionModel.filter((id) => {
            if (id.companyId === values.companyId) {
              return id.name
            }
          }),
        ),
      ]
    : []
  const filteredPositions = selectedPositionName
    ? dataPosition?.positionModel.filter((item) => item.name === selectedPositionName)
    : initialValues?.test && index < values.position.length
    ? dataPosition?.positionModel.filter(
        (item) => item.name === values.position[index].positionName,
      )
    : []

  const selectedPosition = selectedPositionId
    ? dataPosition?.positionModel.find((item) => item.id === selectedPositionId)
    : undefined
  const {data: currentCompany, refetch} = useGetOneCompanyQuery(
    selectedPosition ? selectedPosition.companyId : '',
  )

  useEffect(() => {
    void refetch()
    void refetchPosition()
  }, [])
  return (
    <Box
      sx={{
        m: 5,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <SelectOrderDialog
        modalOpen={orderModalVisible}
        setFieldValue={setFieldValue}
        filteredData={filteredPositions ? filteredPositions : []}
        companyDepartments={currentCompany?.departments ? currentCompany.departments : []}
        companyAddresses={currentCompany?.addresses ? currentCompany.addresses : []}
        onSelectUpdate={handleDialog}
        onModalClose={() => {
          setOrderModalVisible(false)
        }}
      />

      <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs>
          <Box sx={{marginLeft: '75px', marginRight: '50px', marginTop: '30px'}}>
            <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
              <Mui.Grid
                item
                xs={2}
                justifyContent="right"
                component="label"
                htmlFor="orderType"
                display="flex"
                alignItems="center"
                sx={{marginRight: '-20px'}}>
                Order <span style={{paddingLeft: '3px'}}>Type</span> <Red>*</Red>
              </Mui.Grid>
              <Mui.Grid item xs>
                <FormikSelect
                  sx={{width: '100%'}}
                  id="orderType"
                  name={`orders.${index}.orderType`}>
                  <MenuItem value="DirectPlacement">Direct Placement</MenuItem>
                  <MenuItem value="Temp">Temp</MenuItem>
                  <MenuItem value="TempToHire">Temp To Hire</MenuItem>
                  <MenuItem value="Project">Project</MenuItem>
                </FormikSelect>
              </Mui.Grid>
            </Mui.Grid>
          </Box>
        </Grid>

        <Grid item xs paddingBottom={4}>
          <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
            <Mui.Grid
              item
              xs
              justifyContent="flex-end"
              component="label"
              htmlFor="position"
              display="flex"
              alignItems="center">
              {index !== 0 && (
                <IconButton onClick={() => remove(index)}>
                  <Icon icon="close" />
                </IconButton>
              )}
            </Mui.Grid>
          </Mui.Grid>
        </Grid>
      </Grid>

      <Mui.Grid container paddingBottom={1} item xs={24}>
        <Mui.Grid item xs={12} />
        <Mui.Grid item xs={12} component={Mui.Divider} variant="fullWidth" />
      </Mui.Grid>
      <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs>
          <Box sx={{marginLeft: '75px', marginRight: '50px'}}>
            <Mui.Grid container direction="column">
              <Mui.Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
                <Mui.Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="positionId"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Position<Red>*</Red>
                </Mui.Grid>

                <Mui.Grid xs item>
                  <FormikTextField
                    select
                    id="positionId"
                    disabled={values?.companyId === ''}
                    name={`orders.${index}.positionId`}
                    sx={{width: '100%'}}
                    inputProps={{
                      MenuProps: {
                        style: {maxHeight: 300},
                        id: 'id-menu',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                      },
                    }}
                    value={
                      selectedPositionName
                        ? selectedPositionName
                        : values?.orders.map((i) => i.positionName).toString()
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedPositionName(e.target.value)
                      handleChange(e)
                    }}>
                    <div
                      style={{
                        backgroundColor: 'white',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#707070',
                        marginLeft: 15,
                        width: '310px',
                      }}>
                      <div>Positions Category</div>
                    </div>
                    <div
                      style={{
                        borderTop: 'solid 1px black',
                      }}
                    />
                    {filteredPositionFromClient
                      ? filteredPositionFromClient.map((pRow, indexPosition) => {
                          return (
                            <MenuItem
                              value={pRow.name}
                              onClick={setOrderModalVisible}
                              key={indexPosition}>
                              {pRow.name}
                            </MenuItem>
                          )
                        })
                      : ''}
                  </FormikTextField>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Mui.Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="shift"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Shift
                </Mui.Grid>
                <Mui.Grid xs={8} item>
                  <FormikTextField
                    value={
                      selectedPosition
                        ? formatShift(selectedPosition)
                        : initialValues.name === ''
                        ? ''
                        : `${new Date(
                            values?.orders?.map((i) => i?.positionStartTime).toString(),
                          ).toLocaleTimeString('En-US', {
                            hour12: true,
                            hour: 'numeric',
                          })} - ${new Date(
                            values?.orders?.map((i) => i?.positionEndTime).toString(),
                          ).toLocaleTimeString('En-US', {
                            hour12: true,
                            hour: 'numeric',
                          })}`
                    }
                    name={`orders.${index}.shift`}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder="Shift"
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Mui.Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="onSiteFacility"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Facility
                </Mui.Grid>
                <Mui.Grid item xs={8}>
                  <FormikTextField
                    name={`orders.${index}.onSiteFacility`}
                    value={
                      selectedPosition
                        ? selectedPosition?.facilityName
                        : values.orders.map((i) => i.facility).toString()
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    placeholder="Facility"
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                <Mui.Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="departmentName"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Department
                </Mui.Grid>
                <Mui.Grid item xs={8}>
                  <FormikTextField
                    value={
                      selectedPosition
                        ? selectedPosition?.departmentName
                        : values.orders.map((i) => i.department).toString()
                    }
                    name={`orders.${index}.departmentName`}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      input: {
                        border: 'solid white 1px',
                        background: `${theme.palette.grey[200]}`,
                      },
                    }}
                    placeholder="Company Name"
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
                <Mui.Grid
                  item
                  xs={2}
                  justifyContent="right"
                  component="label"
                  htmlFor="payRate"
                  display="flex"
                  alignItems="center"
                  sx={{marginRight: '-20px'}}>
                  Pay<span style={{paddingLeft: '3px'}}>Rate</span>
                  <Red>*</Red>
                </Mui.Grid>
                <Mui.Grid item xs>
                  <FormikTextField name={`orders.${index}.payRate`} placeholder="23/hr" />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>
          <Box>
            <Mui.Grid container direction="column">
              <Box
                sx={{
                  marginLeft: '-15px',
                  marginRight: '50px',
                }}>
                <Mui.Grid container paddingBottom={4} paddingTop={4} rowSpacing={4}>
                  <Grid item xs>
                    <Mui.Grid
                      item
                      xs
                      component="label"
                      htmlFor="distributionStartDate"
                      id="distributionStartDate"
                      display="flex"
                      alignItems="center"
                      sx={{marginLeft: '42px'}}>
                      Distribution <br />
                      Start Date
                      <Mui.Grid xs item sx={{paddingLeft: '15px'}}>
                        <TextField
                          disabled
                          sx={{
                            width: '492px',
                            maxWidth: '492px',
                            input: {
                              border: 'solid white 1px',
                              background: `${theme.palette.grey[200]}`,
                            },
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Grid>
                </Mui.Grid>
              </Box>

              <Box sx={{marginLeft: '40px', paddingRight: '50px'}}>
                <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                  <Mui.Grid
                    item
                    xs={2}
                    justifyContent="right"
                    component="label"
                    htmlFor="candidateDistribution"
                    display="flex"
                    alignItems="center"
                    sx={{marginRight: '-20px'}}>
                    Candidate Distribution
                  </Mui.Grid>
                  <Mui.Grid item xs>
                    <Button disabled sx={{width: '100%', background: '#EBEBEB'}} variant="outlined">
                      Open Calendar
                    </Button>
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.Grid container paddingBottom={4} columnSpacing={4} rowSpacing={4}>
                  <Mui.Grid
                    item
                    xs={2}
                    justifyContent="right"
                    component="label"
                    htmlFor="quantity"
                    display="flex"
                    alignItems="center"
                    sx={{marginRight: '-20px'}}>
                    Quantity
                  </Mui.Grid>
                  <Mui.Grid item xs>
                    <FormikTextField
                      disabled
                      sx={{
                        input: {
                          paddingLeft: '10px',
                          border: 'solid white 1px',
                          background: `${theme.palette.grey[200]}`,
                        },
                      }}
                      name={`orders.${index}.quantity`}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid
                  container
                  paddingBottom={4}
                  paddingTop={1}
                  columnSpacing={4}
                  rowSpacing={4}>
                  <Mui.Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent="right"
                    component="label"
                    htmlFor="note"
                    sx={{marginRight: '-20px'}}>
                    Notes
                  </Mui.Grid>
                  <Mui.Grid item xs>
                    <FormikTextField
                      id="outlined-multiline-flexible"
                      multiline
                      rows={4}
                      name={`orders.${index}.note`}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Box>
            </Mui.Grid>
          </Box>
        </Grid>
      </Grid>
      <Mui.Grid container item xs={24}>
        <Mui.Grid item xs={12} />
        <Mui.Grid item xs={12} component={Mui.Divider} variant="fullWidth" />
      </Mui.Grid>
      <Grid container spacing={2} columns={16}>
        <Grid item xs>
          <Mui.Grid
            container
            sx={{marginLeft: 5}}
            paddingBottom={4}
            paddingTop={4}
            columnSpacing={4}
            rowSpacing={4}>
            <Grid item xs>
              <Mui.Grid
                item
                xs
                component="label"
                htmlFor="position"
                display="flex"
                alignItems="center">
                <span style={{color: '#8A8A8A'}}> Work Location</span>
              </Mui.Grid>
              <Mui.Grid xs item>
                {selectedPosition
                  ? selectedPosition?.facilityAddress?.display
                  : initialValues?.orders?.map((i) => i?.facilityAddress?.display).toString()}
              </Mui.Grid>
            </Grid>
            <Grid item xs />
          </Mui.Grid>
        </Grid>
        <Grid item xs>
          <Mui.Grid container paddingBottom={4} paddingTop={4} columnSpacing={4} rowSpacing={4}>
            <Grid item xs>
              <Mui.Grid
                item
                xs
                component="label"
                htmlFor="position"
                display="flex"
                alignItems="center">
                <span style={{color: '#8A8A8A'}}>Reporting Manager</span>
              </Mui.Grid>
              {selectedPosition
                ? selectedPosition.manager
                : values?.orders?.map((i) => i.positionManager).toString()}
            </Grid>
            <Grid item xs>
              <Mui.Grid
                item
                xs
                component="label"
                htmlFor="position"
                display="flex"
                alignItems="center">
                <span style={{color: '#8A8A8A'}}> Workers Compensation Code</span>
              </Mui.Grid>
              {selectedPosition
                ? currentCompany?.compensationCodes.find(
                    (item) => item.id === selectedPosition.compensationCodeId,
                  )?.code
                : initialValues?.orders?.map((i) => i.positionCompCode).toString()}
            </Grid>
          </Mui.Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
