import styled from 'styled-components'
import Button from '@mui/material/Button'

export const DocumentContainerMain = styled('div')`
  min-height: 600px;
  width: 781px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`

//Manually set heigh and width in outter div to make this more reusuable
export const DocumentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
`

export const HeaderContainer = styled('div')`
  height: 48px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`

export const UploadButtonHeaderContainer = styled('div')`
  height: 82px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`

export const HeaderContainerMargin = styled('div')`
  display: flex;
  margin-left: 24px;
  margin-top: 14px;
  margin-bottom: 14px;
`

export const HeaderText = styled('div')`
  color: #000;
  font-size: 15px;
  font-weight: 600;
`

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 82px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`

export const ButtonContainerMargin = styled('div')`
  display: flex;
  width: 98%;
  justify-content: space-between;
  margin-left: 24px;
`

export const ButtonContainerSpacebetween = styled('div')`
  display: flex;
  width: 42%;
  justify-content: space-between;
`

export const StyledButton = styled(Button)({
  width: '150px',
  height: '36px',
  fontWeight: 'normal',
  borderRadius: '4px',
}) as typeof Button

export const StyledDownloadButton = styled(Button)({
  width: '64px',
  height: '36px',
  fontWeight: 'normal',
  borderRadius: '4px',
}) as typeof Button

export const BodyFolderContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 530px;
  margin: 24px;
  overflow: scroll;
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

export const FolderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 200px;
  min-width: 180px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`

export const FolderNameText = styled('div')`
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 4px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
`

export const FolderItemText = styled('div')`
  color: #707070;
  font-size: 14px;
  font-weight: 400;
`

export const ThreeDotSetting = styled('div')`
  display: flex;
  justify-content: end;
  width: 100%;
  min-height: 40px;
`
