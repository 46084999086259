import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import {MenuItem} from '@mui/material'
import {FormikAutocomplete} from '@ui/components'
import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {FieldArray} from 'formik'
import React from 'react'
import {stateArray} from '~/src/models'
import type {Certification} from '~/src/models/applicant'
import {CertificationType} from '~/src/models/CandidateForm'
import {
  AddExperienceCardContainer,
  AddExperienceContainer,
  AddExperienceText,
  CloseIconContainer,
  CloseIconVerticalAlignContainer,
  DashboardMainStylesMaxWidth,
  FlexColumnContainer,
  FlexContainer,
  FlexSpaceBetweenContainer,
  FlexSpaceEvenlyContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  Red,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import {createNewCertificationItem} from '../../../msp-job-order-information/JobOrderSummaryProfile'

interface IProps {
  handleCancel(): void
  handleClick(value: string): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Certification[]
}

export default function MspCandidateProfileCertsEdit({
  handleCancel,
  handleClick,
  setFieldValue,
  values,
}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer style={{marginBottom: 0}}>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>
                Certifications / Licenses
              </InformationCardHeaderTextTitle>
              <FlexSpaceBetweenContainer>
                <InformationCardHeaderTextEdit style={{marginRight: 10}} onClick={handleCancel}>
                  Cancel
                </InformationCardHeaderTextEdit>
                <InformationCardHeaderTextEdit onClick={() => handleClick('saveChanges')}>
                  Save
                </InformationCardHeaderTextEdit>
              </FlexSpaceBetweenContainer>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <FieldArray name="certifications">
            {({push, remove}) => (
              <React.Fragment>
                {values.map((newCertification: Certification, index: number) => {
                  return (
                    <>
                      <FlexContainer
                        style={{
                          width: '757px',
                          height: '206px',
                          borderRadius: '6px',
                          background: '#FFF',
                          boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
                        }}>
                        <InformationCardDetailsContainer>
                          <InformationCardProfileExperienceJobText>
                            <FlexContainer>
                              <FlexColumnContainer>
                                <span>
                                  Certification/Licence Type <Red>*</Red>
                                </span>
                                <FormikTextField
                                  select
                                  aria-label="certification-CertificationType"
                                  name={`certifications.${index}.certificationName`}
                                  data-testid="certificationCertName"
                                  value={newCertification?.certificationName}
                                  sx={{
                                    maxWidth: '210px',
                                    minWidth: '210px',
                                    height: '28px',
                                    maxHeight: '28px',
                                    marginRight: '19px',
                                  }}>
                                  {CertificationType.map((certInfo, certInfoIndex) => {
                                    return (
                                      <MenuItem
                                        key={certInfoIndex}
                                        aria-label={`candidate-graduationType-${certInfo}-Value`}
                                        data-testid={`candidate-graduationType-${certInfo}-Value`}
                                        value={certInfo}>
                                        {`${certInfo}`}
                                      </MenuItem>
                                    )
                                  })}
                                </FormikTextField>
                              </FlexColumnContainer>
                              <FlexColumnContainer>
                                <span>
                                  Establishment <Red>*</Red>
                                </span>
                                <FormikTextField
                                  aria-label="certification-establishment"
                                  name={`certifications.${index}.establishment`}
                                  data-testid="certificationCEstablishment"
                                  type="text"
                                  label=""
                                  value={newCertification.establishment}
                                  sx={{
                                    maxWidth: '210px',
                                    minWidth: '210px',
                                    height: '28px',
                                    maxHeight: '28px',
                                    marginRight: '19px',
                                  }}
                                />
                              </FlexColumnContainer>
                            </FlexContainer>
                          </InformationCardProfileExperienceJobText>

                          <InformationCardProfileExperienceJobText style={{marginTop: '30px'}}>
                            <FlexContainer>
                              <FlexColumnContainer style={{marginRight: '20px'}}>
                                <span>
                                  State <Red>*</Red>
                                </span>
                                <div style={{width: 210}}>
                                  <FormikAutocomplete
                                    name={`certifications.${index}.state`}
                                    options={stateArray}
                                    placeholder="CA"
                                  />
                                </div>
                              </FlexColumnContainer>
                              <FlexColumnContainer>
                                <span>Expiration Date</span>
                                <div style={{width: '210px'}}>
                                  <FormikDatePicker
                                    aria-label="certication-expirationDate"
                                    name={`certifications.${index}.expirationDate`}
                                    data-testid="certificationStartDate"
                                    initialValue={newCertification?.expirationDate}
                                    sx={{
                                      width: '100%',
                                    }}
                                    onClose={(va: Date) =>
                                      setFieldValue(newCertification?.expirationDate, va)
                                    }
                                  />
                                </div>
                              </FlexColumnContainer>
                              <FlexColumnContainer style={{width: '210px'}} />
                            </FlexContainer>
                          </InformationCardProfileExperienceJobText>
                        </InformationCardDetailsContainer>
                        <CloseIconContainer
                          aria-label="certification-remove-certification"
                          style={{height: '206px'}}
                          onClick={() => remove(index)}>
                          <CloseIconVerticalAlignContainer>
                            <CloseIcon />
                          </CloseIconVerticalAlignContainer>
                        </CloseIconContainer>
                      </FlexContainer>
                    </>
                  )
                })}
                <AddExperienceCardContainer>
                  <AddExperienceContainer
                    aria-label="add-new-certification-button"
                    onClick={() => push(createNewCertificationItem())}>
                    <FlexSpaceEvenlyContainer>
                      <AddExperienceText>
                        <AddCircleIcon sx={{maxHeight: '20px', maxWidth: '20px'}} />
                      </AddExperienceText>
                      <AddExperienceText>Add New Certification</AddExperienceText>
                    </FlexSpaceEvenlyContainer>
                  </AddExperienceContainer>
                </AddExperienceCardContainer>
              </React.Fragment>
            )}
          </FieldArray>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
