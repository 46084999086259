/* eslint-disable complexity */
import React from 'react'
import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import type {StyledComponentInnerAttrs, AnyStyledComponent} from 'styled-components'

import AddIcon from './Add'
import AddThickIcon from './AddThick'
import AmazonIcon from './Amazon'
import AppleIcon from './Apple'
import ArrowIcon from './Arrow'
import ArrowDownCircleIcon from './ArrowDownCircle'
import ArrowheadIcon from './Arrowhead'
import ArrowRightCircleIcon from './ArrowRightCircle'
import BellIcon from './Bell'
import BrandLogoIcon from './BrandLogo'
import BrandTextIcon from './BrandText'
import BriefcaseIcon from './Briefcase'
import BuildingIcon from './Building'
import CapIcon from './Cap'
import ChatIcon from './Chat'
import CheckboxCheckedIcon from './CheckboxChecked'
import CheckboxUncheckedIcon from './CheckboxUnchecked'
import CheckIcon from './Check'
import CloseIcon from './Close'
import CodeIcon from './code'
import CodeSandboxIcon from './codesandbox'
import DashboardIcon from './Dashboard'
import DealsIcon from './Deals'
import DeleteIcon from './Delete'
import DepartmentsIcon from './Departments'
import DocumentIcon from './Document'
import DoubleBuildingIcon from './DoubleBuilding'
import EditIcon from './Edit'
import EditVariantIcon from './EditVariant'
import EllipsisIcon from './Ellipsis'
import EllipsisVIcon from './EllipsisV'
import EmailIcon from './Email'
import ErrorIcon from './Error'
import EyesIcon from './Eyes'
import EyesClose from './EyesClose'
import FacebookIcon from './Facebook'
import FilterIcon from './Filter'
import GoogleIcon from './Google'
import GroupIcon from './Group'
import HandshakeIcon from './Handshake'
import LoaderIcon from './Loader'
import MenuIcon from './Menu'
import ModuleIcon from './Module'
import ModulesIcon from './Modules'
import PaperclipIcon from './Paperclip'
import PenIcon from './Pen'
import PeopleIcon from './People'
import PeopleSearchIcon from './PeopleSearch'
import PersonIcon from './Person'
import PersonCircleIcon from './PersonCircle'
import PhoneIcon from './Phone'
import SearchIcon from './Search'
import TasksIcon from './Tasks'
import ToggleIcon from './Toggle'
import UploadIcon from './Upload'

import type {TIcon} from './types/icon'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAttributes extends StyledComponentInnerAttrs<AnyStyledComponent> {
  icon: TIcon | string
  outerProps?: object
}

function IconInner({icon, ...attributes}: Omit<IAttributes, 'outerProps'>) {
  switch (icon) {
    case 'add':
      return <AddIcon {...attributes} />
    case 'add-thick':
      return <AddThickIcon {...attributes} />
    case 'amazon':
      return <AmazonIcon {...attributes} />
    case 'apple':
      return <AppleIcon {...attributes} />
    case 'arrow':
      return <ArrowIcon {...attributes} />
    case 'arrowhead':
      return <ArrowheadIcon {...attributes} />
    case 'arrow-down-circle':
      return <ArrowDownCircleIcon {...attributes} />
    case 'arrow-right-circle':
      return <ArrowRightCircleIcon {...attributes} />
    case 'bell':
      return <BellIcon {...attributes} />
    case 'brand-logo':
      return <BrandLogoIcon {...attributes} />
    case 'brand-text':
      return <BrandTextIcon {...attributes} />
    case 'briefcase':
      return <BriefcaseIcon {...attributes} />
    case 'building':
      return <BuildingIcon {...attributes} />
    case 'cap':
      return <CapIcon {...attributes} />
    case 'chat':
      return <ChatIcon {...attributes} />
    case 'check':
      return <CheckIcon {...attributes} />
    case 'checkbox-checked':
      return <CheckboxCheckedIcon {...attributes} />
    case 'checkbox-unchecked':
      return <CheckboxUncheckedIcon {...attributes} />
    case 'close':
      return <CloseIcon {...attributes} />
    case 'code':
      return <CodeIcon {...attributes} />
    case 'codesandbox':
      return <CodeSandboxIcon {...attributes} />
    case 'dashboard':
      return <DashboardIcon {...attributes} />
    case 'deals':
      return <DealsIcon {...attributes} />
    case 'delete':
      return <DeleteIcon {...attributes} />
    case 'departments':
      return <DepartmentsIcon {...attributes} />
    case 'document':
      return <DocumentIcon {...attributes} />
    case 'double-building':
      return <DoubleBuildingIcon {...attributes} />
    case 'edit':
      return <EditIcon {...attributes} />
    case 'edit-variant':
      return <EditVariantIcon {...attributes} />
    case 'ellipsis':
      return <EllipsisIcon {...attributes} />
    case 'ellipsis-v':
      return <EllipsisVIcon {...attributes} />
    case 'email':
      return <EmailIcon {...attributes} />
    case 'error':
      return <ErrorIcon {...attributes} />
    case 'eyes':
      return <EyesIcon {...attributes} />
    case 'eyes-close':
      return <EyesClose {...attributes} />
    case 'facebook':
      return <FacebookIcon {...attributes} />
    case 'filter':
      return <FilterIcon {...attributes} />
    case 'google':
      return <GoogleIcon {...attributes} />
    case 'group':
      return <GroupIcon {...attributes} />
    case 'handshake':
      return <HandshakeIcon {...attributes} />
    case 'loader':
      return <LoaderIcon {...attributes} />
    case 'menu':
      return <MenuIcon {...attributes} />
    case 'module':
      return <ModuleIcon {...attributes} />
    case 'modules':
      return <ModulesIcon {...attributes} />
    case 'paperclip':
      return <PaperclipIcon {...attributes} />
    case 'pen':
      return <PenIcon {...attributes} />
    case 'people-search':
      return <PeopleSearchIcon {...attributes} />
    case 'person':
      return <PersonIcon {...attributes} />
    case 'person-circle':
      return <PersonCircleIcon {...attributes} />
    case 'people':
      return <PeopleIcon {...attributes} />
    case 'phone':
      return <PhoneIcon {...attributes} />
    case 'search':
      return <SearchIcon {...attributes} />
    case 'tasks':
      return <TasksIcon {...attributes} />
    case 'toggle':
      return <ToggleIcon {...attributes} />
    case 'upload':
      return <UploadIcon {...attributes} />
    default:
      throw Error(`unsupported type: ${icon}`)
  }
}

const IconOuter = styled('div')`
  display: flex;
  position: relative;
`

export default function Icon({as, outerProps,...innerProps}: IAttributes) {
  return outerProps ? (
    <IconOuter as={as} {...outerProps}>
      <IconInner {...innerProps} />
    </IconOuter>
  ) : (
    <IconInner as={as} {...innerProps} />
  )
}

Icon.muiName = Mui.Icon.muiName
