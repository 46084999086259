import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {IconButton, Menu, MenuItem, Tooltip, Typography} from '@mui/material'
import {push} from 'connected-react-router'
import React, {useCallback, useState} from 'react'
import styled, {css} from 'styled-components'
import {BASE_ROUTES} from '~/src/routes/constants'
import {useLazyGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {selectAgencyState} from '~/src/store/slices/agency-slice'
import {updateLoggedInAgencyId} from '~/src/store/slices/root-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'

const StyledMenuItem = styled(MenuItem)(
  ({theme}) => css`
    width: 100%;
    &:hover .itemText {
      color: ${theme.palette.primary.main};
    }
  `,
)

const StyledText = styled(Typography)<{$selected: boolean}>(
  ({theme, $selected}) => css`
    left: 9.62%;
    right: 17.79%;
    top: 20.27%;
    bottom: 71.17%;
    font-style: normal;
    :400 ;
    font-weight: ${$selected ? 600 : 400};
    font-size: 14px;
    line-height: 19px;
    color: ${$selected ? theme.palette.primary.main : '#383838'};
  `,
)

const StyledTitleText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: #334d6e;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface IUserMenuProps {
  sx?: object
}

const SelectAgencyMenu = ({sx = {}}: IUserMenuProps) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const dispatch = useAppDispatch()
  const {agencyNamesAndIds} = useAppSelector(selectAgencyState)
  const [getAgency, {error: agencyError}] = useLazyGetOneAgencyQuery()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const selectedName = agencyNamesAndIds.find((datum) => datum.id === loggedInAgencyId)?.name

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = useCallback(() => {
    setAnchor(null)
  }, [setAnchor])

  const handleClick = useCallback(
    async (id: string) => {
      const agency = await getAgency(id).unwrap()
      dispatch(updateLoggedInAgencyId(agency.id))
      setAnchor(null)
      if (agency.isMsp) {
        dispatch(push(BASE_ROUTES.AGENCY_ADMIN))
      } else {
        dispatch(push(BASE_ROUTES.AGENCY))
      }
    },
    [dispatch, getAgency],
  )

  return (
    <div style={{...sx}}>
      <Tooltip title="Select Agency">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <StyledTitleText>{selectedName}</StyledTitleText>
          <ArrowDropDownIcon sx={{color: '#8A8A8A'}} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={handleCloseUserMenu}>
        {agencyNamesAndIds.map((item) => {
          return (
            <StyledMenuItem
              key={item.id}
              onClick={() => {
                void handleClick(item.id)
              }}>
              <StyledText
                textAlign="center"
                className="itemText"
                $selected={item.id === loggedInAgencyId}>
                {item.name}
              </StyledText>
            </StyledMenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default SelectAgencyMenu
