import Box from '@mui/material/Box'
import JobOrderCard from '@ui/card/JobOrderCard'
import JobOrderDistributeOptionalCard from '@ui/card/JobOrderDistributeOptionalCard'
import ServiceOrderCardMsp from '@ui/card/ServiceOrderCardMsp'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {push} from 'connected-react-router'
import {format} from 'date-fns'
import {nanoid} from 'nanoid/non-secure'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import Title from '~/src/components/layout/Title'
import type {IServiceOrder} from '~/src/models/serviceOrder'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetCompanyNameByIdQuery} from '~/src/store/apis/company-api'
import {
  useGetJobOrdersByServiceOrderIdsQuery,
  useGetJobOrdersQuery,
} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const Container = styled.div`
  position: relative;
  flex: 1;
  margin-left: 15px;
`

const SubHeader = styled('div')`
  display: flex;
  margin-left: 40px;
  margin-bottom: 32px;
`

const SubHeaderText = styled('div')<{subHeaderState: boolean}>`
  font-size: 15px;
  font-weight: 600;
  border-bottom: ${(props) => (props.subHeaderState ? '2px solid #03a9fc' : '')};
  cursor: pointer;
`

interface ICardComboProps {
  serviceOrder: IServiceOrder
  agencyId: string
  currentServiceOrderPageNumber: number
  shift?: string
  sx?: object
}

function CardCombo({
  serviceOrder,
  agencyId,
  currentServiceOrderPageNumber,
  shift,
  sx,
}: ICardComboProps) {
  const dispatch = useDispatch()
  const [jobOrderIndex, setJobOrderIndex] = useState<number>(-1)
  const [dateIndex, setDateIndex] = useState<number>(0)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {
    data: jobOrderBatch,
    refetch,
    error,
    isLoading,
    isFetching,
    isSuccess: jobOrderSuccess,
  } = useGetJobOrdersByServiceOrderIdsQuery({
    serviceOrderIds: [serviceOrder.id],
    agencyId: loggedInAgencyId,
  })

  const jobOrders = jobOrderBatch ? jobOrderBatch[0]?.jobOrders : undefined

  const {
    data: positionData,
    isLoading: isPositionLoading,
    refetch: positionRefetch,
  } = useGetPositionByIdQuery(
    {
      id: serviceOrder.orders[0]?.positionId as string,
    },
    {skip: !jobOrderSuccess},
  )

  const {data: companyName} = useGetCompanyNameByIdQuery({
    id: positionData?.companyId,
  })

  const positionDates = serviceOrder.orders[0]?.orderDistribution.orderQuantityDistributions.map(
    (item) => new Date(item.date),
  ) as Date[]

  const dateCompare = (clickedDate: Date, jobOrderDateString: Date) => {
    const month = format(clickedDate, 'MMM')
    const day = format(clickedDate, 'dd')

    const elementDate = new Date(jobOrderDateString)
    const elementMonth = format(elementDate, 'MMM')
    const elementDay = format(elementDate, 'dd')

    return month === elementMonth && day === elementDay
  }

  const handleCallback = (date: Date) => {
    if (!jobOrders || jobOrders.length === 0) {
      setJobOrderIndex(-1)
    } else {
      const foundIndex = jobOrders.findIndex((jobOrder) => {
        return dateCompare(date, jobOrder.date)
      })
      setJobOrderIndex(foundIndex)
    }

    const foundDateIndex = positionDates.findIndex((positionDate) => {
      return dateCompare(date, positionDate)
    })
    setDateIndex(foundDateIndex)
  }

  const selectedJobOrder = jobOrders?.at(jobOrderIndex)

  const totalCandidatesNeeded = selectedJobOrder?.agencyDistributions
    .map((distribution, indexOne: number) => distribution.agencyCandidateDistributions.requested)
    .reduce((prev: number, curr: number) => prev + curr, 0)

  const totalCandidatesFilled = selectedJobOrder?.agencyDistributions
    .map((distribution, indexOne: number) => distribution.agencyCandidateDistributions.fulfilled)
    .reduce((prev: number, curr: number) => prev + curr, 0)

  useEffect(() => {
    void refetch()
  }, [refetch])

  const selectedJobOrderId = jobOrderIndex === -1 ? undefined : selectedJobOrder?.id

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        borderRadius: 1,
        marginBottom: '23px',
        ...sx,
      }}>
      <ErrorBoundary error={error}>
        <ServiceOrderCardMsp
          callback={handleCallback}
          currentUserAgencyId={agencyId}
          currentServiceOrderPageNumber={currentServiceOrderPageNumber}
          jobOrderId={selectedJobOrderId}
          serviceOrder={serviceOrder}
          sx={{borderRadius: '16px 16px 0px 0px', marginBottom: 0}}
        />
        {jobOrderIndex === -1 ? (
          <div style={{maxWidth: '260px'}}>
            <JobOrderDistributeOptionalCard
              key={nanoid()}
              position={positionData?.name}
              distributionDate={positionDates[dateIndex] as Date}
              client={companyName}
              shift={shift as string}
              matchedRow={false}
              style={{borderRadius: '0px 0px 16px 16px'}}
            />
          </div>
        ) : (
          <JobOrderCard
            jobOrder={selectedJobOrder}
            serviceOrder={serviceOrder}
            position={positionData}
            distributionDateOverride={positionDates[dateIndex] as Date}
            totalrequested={totalCandidatesNeeded as number}
            currentlyfilled={totalCandidatesFilled as number}
            style={{borderRadius: '0px 0px 16px 16px'}}
            onListIconClick={(e) => {
              if (selectedJobOrderId) {
                dispatch(
                  push(
                    `${BASE_ROUTES.AGENCY_ADMIN}${
                      SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
                    }/${encodeURIComponent(selectedJobOrderId)}?showDetails=false`,
                  ),
                )
              }
            }}
          />
        )}
      </ErrorBoundary>
    </Box>
  )
}

export default function AgencyManageOrdersMain(): JSX.Element {
  const dispatch = useDispatch()
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [jobOrderCurrPageIdx, setJobOrerCurrPageIdx] = useState(1)
  const [subHeaderState, setSubHeaderState] = useState('Service Orders')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: currPageIdx,
    pageSize: 12,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })

  const {
    data: jobOrderData,
    refetch: jobOrderRefetch,
    error: jobOrderError,
    isLoading: jobOrderIsLoading,
    isFetching: jobOrderIsFetching,
  } = useGetJobOrdersQuery({
    pageNumber: jobOrderCurrPageIdx,
    pageSize: 12,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })

  const handleSubHeaderChange = (value: string) => {
    return setSubHeaderState(value)
  }

  // two seperate pagination functions because the reusuable component woulda have to change to pass in a boolean for the different pages
  const handleServiceOrderPaginationChange = (pageNumber: number) => {
    setCurrPageIdx(pageNumber)
  }

  const handleJobOrderPaginationChange = (pageNumber: number) => {
    setJobOrerCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void refetch()
    void jobOrderRefetch()
  }, [currPageIdx, refetch, jobOrderRefetch, jobOrderCurrPageIdx])

  const totalServiceOrders = data?.totalServiceOrders
  const totalJobOrders = jobOrderData?.totalJobOrders

  const projectToggleSwitch = () => {
    switch (subHeaderState) {
      case 'Job Orders':
        return (
          <ErrorBoundary error={jobOrderError}>
            <LoadingBoundary isLoading={jobOrderIsLoading || jobOrderIsFetching}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: 1200,
                  borderRadius: 1,
                }}>
                {jobOrderData?.jobOrders.map((jobOrder, index2) => {
                  const totalCandidatesNeeded = jobOrder.agencyDistributions
                    .map((distribution) => distribution.agencyCandidateDistributions.requested)
                    .reduce((prev: number, curr: number) => prev + curr, 0)

                  const totalCandidatesFilled = jobOrder.agencyDistributions
                    .map((distribution) => distribution.agencyCandidateDistributions.fulfilled)
                    .reduce((prev: number, curr: number) => prev + curr, 0)
                  return (
                    <JobOrderCard
                      jobOrder={jobOrder}
                      currentlyfilled={totalCandidatesFilled}
                      key={index2}
                      totalrequested={totalCandidatesNeeded}
                      onListIconClick={() => {
                        dispatch(
                          push(
                            `${BASE_ROUTES.AGENCY_ADMIN}${
                              SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
                            }/${encodeURIComponent(jobOrder.id)}?showDetails=false`,
                          ),
                        )
                      }}
                      style={{marginBottom: '23px'}}
                    />
                  )
                })}
              </Box>
              <StyledPagination
                page={jobOrderCurrPageIdx}
                totalRecords={totalJobOrders}
                recordsPerPage={12}
                onChange={handleJobOrderPaginationChange}
              />
            </LoadingBoundary>
          </ErrorBoundary>
        )

      default:
        return (
          <ErrorBoundary error={error}>
            <LoadingBoundary isLoading={isLoading || isFetching}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: 1200,
                  borderRadius: 1,
                }}>
                {data?.serviceOrdersModel.map((row, index) => {
                  return (
                    <CardCombo
                      key={index}
                      serviceOrder={row}
                      agencyId={loggedInAgencyId}
                      currentServiceOrderPageNumber={currPageIdx}
                    />
                  )
                })}
              </Box>
              <StyledPagination
                page={currPageIdx}
                totalRecords={totalServiceOrders}
                recordsPerPage={12}
                onChange={handleServiceOrderPaginationChange}
              />
            </LoadingBoundary>
          </ErrorBoundary>
        )
    }
  }

  return (
    <Container>
      <Title pageTitle="Current Orders" />
      <SubHeader>
        <SubHeaderText
          style={{marginRight: '20px'}}
          subHeaderState={subHeaderState === 'Service Orders'}
          onClick={() => handleSubHeaderChange('Service Orders')}>
          Service Orders
        </SubHeaderText>
        <SubHeaderText
          subHeaderState={subHeaderState === 'Job Orders'}
          onClick={() => handleSubHeaderChange('Job Orders')}>
          Job Orders
        </SubHeaderText>
      </SubHeader>

      {projectToggleSwitch()}
    </Container>
  )
}
