import React from 'react'
import S from './PlatformWidget.styles'

interface IProps extends NAttributes.Anchor {
  to: string
  icon: string
  topText: string
  bottomText: string
  variant: 'black' | 'white'
  isActive?: boolean
}

export default function PlatformWidget({variant, icon, topText, bottomText, isActive, ...props}: IProps) {
  return (
    <S.Widget {...props} variant={isActive ? 'primary' : 'white'}>
      <S.Icon icon={icon} />
      <S.Text>{topText}</S.Text>
      <S.Count>{bottomText}</S.Count>
    </S.Widget>
  )
}

PlatformWidget.defaultProps = {
  onClick: function () {},
  variant: 'primary',
  topText: '',
  bottomText: '',
  icon: '',
  to: '',
}
