import {Alert, Snackbar} from '@mui/material'
import AddDataCleanupCard from '@ui/card/AddDataCleanupCard'
import CreateDataCleanupCard from '@ui/card/CreateDataCleanupCard'
import DataCleanupCard from '@ui/card/DataCleanupCard'
import {getFilterDays} from '@ui/card/getFilterDays'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {ReactElement, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {BASE_ROUTES} from '~/src/routes'
import {useGetAllDataCleanupsQuery} from '~/src/store/apis/data-cleanup-api'
import {useAppSelector} from '~/src/store/store-hooks'

const ContentContainer = styled('div')({
  maxWidth: '100%',
  width: '100%',
})

const DataCleanupContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '100%',
  width: '100%',
  justifyContent: 'flex-start',
})

const DataCleanupLabel = styled('div')({
  fontWeight: 600,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '24px',
})

const FlexSpaceBetweenContainer = styled('div')({
  display: 'flex',
  maxWidth: '100%',
  width: '100%',
  justifyContent: 'space-between',
})

interface IDataCardProps {
  denominator: number
  filter: number
  id: string | number
  isActive: boolean
  isMsp?: boolean
  numerator: number
  sx: object
  title: string
}

export function DataCard({
  title,
  filter,
  isActive,
  sx,
  id,
  numerator,
  denominator,
  isMsp,
}: IDataCardProps) {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = React.useState(false)

  const handleCallback = () => {
    setIsEdit(!isEdit)
  }

  const handleToggleInventory = (value: boolean) => {
    switch (isMsp) {
      case true:
        if (value) {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}/data-cleanup/${id}?showDetails=true`))
        } else {
          dispatch(push(`${BASE_ROUTES.AGENCY_ADMIN}/data-cleanup/${id}?showDetails=false`))
        }
        break
      default:
        if (value) {
          dispatch(push(`${BASE_ROUTES.AGENCY}/data-cleanup/${id}?showDetails=true`))
        } else {
          dispatch(push(`${BASE_ROUTES.AGENCY}/data-cleanup/${id}?showDetails=false`))
        }
    }
  }

  return (
    <>
      {/* boolean not needed until we need editing back */}
      {/* {isEdit ? (
        <EditDataCleanupCard
          cardId={id}
          sx={{...sx}}
          title={title}
          isActive={isActive}
          callback={handleCallback}
        />
      ) : (
        )} */}
      <DataCleanupCard
        callback={handleCallback}
        cardId={id}
        denominator={denominator}
        filter={filter}
        isInDataCleanup={false}
        isMsp={isMsp}
        numerator={numerator}
        onToggleInventory={handleToggleInventory}
        sx={{...sx}}
        title={title}
      />
    </>
  )
}

interface IPlusCardProps {
  sx: object
}

function PlusCard({sx}: IPlusCardProps) {
  const [isCreate, setIsCreate] = React.useState(false)
  const [dataCleanupSuccess, setDataCleanupSuccess] = useState(false)
  const [response, setResponse] = useState({
    id: '',
    totalApplicants: 0,
    applicantIds: 0,
  })
  const [open, setOpen] = useState(false)
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleCallback = (
    isSuccess: boolean,
    res: {id: string; totalApplicants: number; applicantIds: number},
    isNormalAdd?: boolean,
  ) => {
    if (isNormalAdd) {
      return setIsCreate(!isCreate)
    } else if (isSuccess) {
      setDataCleanupSuccess(true)
      setResponse(res)
      setOpen(true)
      setIsCreate(!isCreate)
    } else {
      setOpen(true)
      setDataCleanupSuccess(false)
    }
  }

  return (
    <>
      {dataCleanupSuccess ? (
        response.applicantIds === response.totalApplicants ? (
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              Success! The Data Cleanup was created with the full amount of applicants requested!
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              The Data Cleanup was created with {response.totalApplicants} applicants requested!
              There is only a total of {response.applicantIds} applicants identified from this
              filter.
            </Alert>
          </Snackbar>
        )
      ) : (
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
            Failure! The Data Cleanup was not made because there are 0 applicants that match this
            criteria!
          </Alert>
        </Snackbar>
      )}
      {isCreate ? (
        <CreateDataCleanupCard sx={{...sx}} callback={handleCallback} />
      ) : (
        <AddDataCleanupCard
          data-testid="addDataCleanupCard"
          sx={{...sx}}
          callback={handleCallback}
        />
      )}
    </>
  )
}
interface IProps {
  isMsp?: boolean
}

export default function DataCleanupDashboard({isMsp}: IProps): ReactElement {
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data, refetch, isLoading} = useGetAllDataCleanupsQuery(loggedInAgencyId)

  useEffect(() => {
    void refetch()
  }, [refetch])
  return (
    <>
      <LoadingBoundary isLoading={isLoading}>
        <ContentContainer>
          <FlexSpaceBetweenContainer>
            <DataCleanupLabel>Data Clean-up</DataCleanupLabel>
          </FlexSpaceBetweenContainer>
          <DataCleanupContainer style={{marginTop: 30}}>
            {data?.dataCleanups.map((cleanup) => {
              const filter = getFilterDays(cleanup.cleanRange)
              return (
                <DataCard
                  key={cleanup.id}
                  denominator={cleanup.totalApplicants}
                  filter={filter}
                  id={cleanup.id}
                  isActive={cleanup.active}
                  isMsp={isMsp}
                  numerator={cleanup.totalCleanedApplicants}
                  sx={{marginRight: '23px', marginBottom: '23px'}}
                  title={cleanup.title}
                />
              )
            })}
            {/* commenting out because we might add back in future */}
            {/* <PlusCard sx={{marginRight: '15px'}} /> */}
          </DataCleanupContainer>
        </ContentContainer>
      </LoadingBoundary>
    </>
  )
}
