import IconButton from '@mui/material/IconButton'
import EditVariantIcon from '@ui/icons/EditVariant'
import PrependedHeader from './PrependedHeader'
import {cognitoToDisplay, IUserBase, TUsersPayload} from '~/src/models'
import React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {StyledTableCell} from '@ui/table-cell/StyledTableCell'
import alertBox from '../../ui/alert-box/AlertBox'

interface IProps {
  data: TUsersPayload
  currentPageNumber: number
  onPaginationChange(value: number): void
  onOpenEditModal(user: IUserBase): void
}

interface Data {
  name: string
  email: string
  department: string
  role: string
  status: string
  date: string
  edit: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
  align: string
}

const headCells: readonly HeadCell[] = [
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Member Name',
    align: 'left',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email Address',
    align: 'left',
  },

  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
    align: 'left',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    align: 'left',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Edit Date',
    align: 'left',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: false,
    label: '',
    align: 'center',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{height: 65}}>
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id} align={headCell.align} padding="normal">
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function UserTable({
  data,
  currentPageNumber,
  onPaginationChange,
  onOpenEditModal,
}: IProps) {
  return (
    <Box
      sx={{
        width: '100%',
        mb: 10,
        backgroundColor: '#FFFFFF',
        border: '1px solid #CDCED9',
        borderRadius: '4px',
      }}>
      <PrependedHeader totalUsers={data.totalUsers} />
      <TableContainer>
        <Table
          sx={{minWidth: 750, height: 65}}
          aria-labelledby="tableTitle"
          size="small"
          padding="normal">
          <EnhancedTableHead />
          <TableBody>
            {data.users.map((user, index) => {
              try {
                return (
                  <TableRow
                    sx={{height: 65}}
                    hover
                    tabIndex={-1}
                    key={user.emailAddress}>
                    <StyledTableCell />
                    <StyledTableCell
                      component="th"
                      scope="row"
                      padding="normal"
                      sx={{maxWidth: '200px'}}>
                      {`${user.firstName} ${user.lastName}`}
                    </StyledTableCell>
                    <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                      {user.emailAddress}
                    </StyledTableCell>
                    <StyledTableCell sx={{maxWidth: '180px'}} align="left">
                      {user.roles.map(cognitoToDisplay).join(', ')}
                    </StyledTableCell>
                    <StyledTableCell align="left">{user.status}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(user.modifiedDate).toLocaleDateString('En-US')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        aria-label="delete"
                        sx={{color: '#858585'}}
                        onClick={() => {
                          onOpenEditModal(user)
                        }}>
                        <EditVariantIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                )
              } catch (error) {
                return alertBox(error, '')
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        page={currentPageNumber}
        totalRecords={data.totalUsers}
        recordsPerPage={10}
        onChange={onPaginationChange}
      />
    </Box>
  )
}
