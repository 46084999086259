import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function BuildingIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='40'
			height='50'
			viewBox='0 0 40 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.6668 11.667H15.0002C15.4604 11.667 15.8335 11.2939 15.8335 10.8337V7.50033C15.8335 7.04012 15.4604 6.66699 15.0002 6.66699H11.6668C11.2066 6.66699 10.8335 7.04012 10.8335 7.50033V10.8337C10.8335 11.2939 11.2066 11.667 11.6668 11.667ZM12.5002 8.33366H14.1668V10.0003H12.5002V8.33366Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 11.667H21.6667C22.1269 11.667 22.5 11.2939 22.5 10.8337V7.50033C22.5 7.04012 22.1269 6.66699 21.6667 6.66699H18.3333C17.8731 6.66699 17.5 7.04012 17.5 7.50033V10.8337C17.5 11.2939 17.8731 11.667 18.3333 11.667ZM19.1667 8.33366H20.8333V10.0003H19.1667V8.33366Z'
				fill='currentColor'
			/>
			<path
				d='M24.9998 11.667H28.3332C28.7934 11.667 29.1665 11.2939 29.1665 10.8337V7.50033C29.1665 7.04012 28.7934 6.66699 28.3332 6.66699H24.9998C24.5396 6.66699 24.1665 7.04012 24.1665 7.50033V10.8337C24.1665 11.2939 24.5396 11.667 24.9998 11.667ZM25.8332 8.33366H27.4998V10.0003H25.8332V8.33366Z'
				fill='currentColor'
			/>
			<path
				d='M11.6668 18.333H15.0002C15.4604 18.333 15.8335 17.9599 15.8335 17.4997V14.1663C15.8335 13.7061 15.4604 13.333 15.0002 13.333H11.6668C11.2066 13.333 10.8335 13.7061 10.8335 14.1663V17.4997C10.8335 17.9599 11.2066 18.333 11.6668 18.333ZM12.5002 14.9997H14.1668V16.6663H12.5002V14.9997Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 18.333H21.6667C22.1269 18.333 22.5 17.9599 22.5 17.4997V14.1663C22.5 13.7061 22.1269 13.333 21.6667 13.333H18.3333C17.8731 13.333 17.5 13.7061 17.5 14.1663V17.4997C17.5 17.9599 17.8731 18.333 18.3333 18.333ZM19.1667 14.9997H20.8333V16.6663H19.1667V14.9997Z'
				fill='currentColor'
			/>
			<path
				d='M24.9998 18.333H28.3332C28.7934 18.333 29.1665 17.9599 29.1665 17.4997V14.1663C29.1665 13.7061 28.7934 13.333 28.3332 13.333H24.9998C24.5396 13.333 24.1665 13.7061 24.1665 14.1663V17.4997C24.1665 17.9599 24.5396 18.333 24.9998 18.333ZM25.8332 14.9997H27.4998V16.6663H25.8332V14.9997Z'
				fill='currentColor'
			/>
			<path
				d='M11.6668 25H15.0002C15.4604 25 15.8335 24.6269 15.8335 24.1667V20.8333C15.8335 20.3731 15.4604 20 15.0002 20H11.6668C11.2066 20 10.8335 20.3731 10.8335 20.8333V24.1667C10.8335 24.6269 11.2066 25 11.6668 25ZM12.5002 21.6667H14.1668V23.3333H12.5002V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 25H21.6667C22.1269 25 22.5 24.6269 22.5 24.1667V20.8333C22.5 20.3731 22.1269 20 21.6667 20H18.3333C17.8731 20 17.5 20.3731 17.5 20.8333V24.1667C17.5 24.6269 17.8731 25 18.3333 25ZM19.1667 21.6667H20.8333V23.3333H19.1667V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M24.9998 25H28.3332C28.7934 25 29.1665 24.6269 29.1665 24.1667V20.8333C29.1665 20.3731 28.7934 20 28.3332 20H24.9998C24.5396 20 24.1665 20.3731 24.1665 20.8333V24.1667C24.1665 24.6269 24.5396 25 24.9998 25ZM25.8332 21.6667H27.4998V23.3333H25.8332V21.6667Z'
				fill='currentColor'
			/>
			<path
				d='M11.6668 31.667H15.0002C15.4604 31.667 15.8335 31.2939 15.8335 30.8337V27.5003C15.8335 27.0401 15.4604 26.667 15.0002 26.667H11.6668C11.2066 26.667 10.8335 27.0401 10.8335 27.5003V30.8337C10.8335 31.2939 11.2066 31.667 11.6668 31.667ZM12.5002 28.3337H14.1668V30.0003H12.5002V28.3337Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 31.667H21.6667C22.1269 31.667 22.5 31.2939 22.5 30.8337V27.5003C22.5 27.0401 22.1269 26.667 21.6667 26.667H18.3333C17.8731 26.667 17.5 27.0401 17.5 27.5003V30.8337C17.5 31.2939 17.8731 31.667 18.3333 31.667ZM19.1667 28.3337H20.8333V30.0003H19.1667V28.3337Z'
				fill='currentColor'
			/>
			<path
				d='M24.9998 31.667H28.3332C28.7934 31.667 29.1665 31.2939 29.1665 30.8337V27.5003C29.1665 27.0401 28.7934 26.667 28.3332 26.667H24.9998C24.5396 26.667 24.1665 27.0401 24.1665 27.5003V30.8337C24.1665 31.2939 24.5396 31.667 24.9998 31.667ZM25.8332 28.3337H27.4998V30.0003H25.8332V28.3337Z'
				fill='currentColor'
			/>
			<path
				d='M11.6668 38.333H15.0002C15.4604 38.333 15.8335 37.9599 15.8335 37.4997V34.1663C15.8335 33.7061 15.4604 33.333 15.0002 33.333H11.6668C11.2066 33.333 10.8335 33.7061 10.8335 34.1663V37.4997C10.8335 37.9599 11.2066 38.333 11.6668 38.333ZM12.5002 34.9997H14.1668V36.6663H12.5002V34.9997Z'
				fill='currentColor'
			/>
			<path
				d='M18.3333 38.333H21.6667C22.1269 38.333 22.5 37.9599 22.5 37.4997V34.1663C22.5 33.7061 22.1269 33.333 21.6667 33.333H18.3333C17.8731 33.333 17.5 33.7061 17.5 34.1663V37.4997C17.5 37.9599 17.8731 38.333 18.3333 38.333ZM19.1667 34.9997H20.8333V36.6663H19.1667V34.9997Z'
				fill='currentColor'
			/>
			<path
				d='M24.9998 38.333H28.3332C28.7934 38.333 29.1665 37.9599 29.1665 37.4997V34.1663C29.1665 33.7061 28.7934 33.333 28.3332 33.333H24.9998C24.5396 33.333 24.1665 33.7061 24.1665 34.1663V37.4997C24.1665 37.9599 24.5396 38.333 24.9998 38.333ZM25.8332 34.9997H27.4998V36.6663H25.8332V34.9997Z'
				fill='currentColor'
			/>
			<path
				d='M32.5 48.3333V5H33.3333C33.7935 5 34.1667 4.62687 34.1667 4.16667V0.833333C34.1667 0.373125 33.7935 0 33.3333 0H6.66667C6.20646 0 5.83333 0.373125 5.83333 0.833333V4.16667C5.83333 4.62687 6.20646 5 6.66667 5H7.5V48.3333H0V50H40V48.3333H32.5ZM7.5 1.66667H32.5V3.33333H7.5V1.66667ZM19.1667 48.3333H15.8333V41.6667H19.1667V48.3333ZM24.1667 48.3333H20.8333V41.6667H24.1667V48.3333ZM30.8333 48.3333H25.8333V40.8333C25.8333 40.3731 25.4602 40 25 40H15C14.5398 40 14.1667 40.3731 14.1667 40.8333V48.3333H9.16667V5H30.8333V48.3333Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
