import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {TRootState} from '..'
import {companyApi} from '../apis/company-api'

interface IState {
  departments: string | number | boolean | object | null
  dropdownSelected: string
}

const initialState: IState = {
  departments: null,
  dropdownSelected: '',
}

interface IHash {
  [key: string]: string
}

const reqTemplateSlice = createSlice({
  name: 'reqTemplateSlice',
  initialState,
  reducers: {
    selectDropdown: (state, {payload}: PayloadAction<string>) => {
      state.dropdownSelected = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(companyApi.endpoints.getDepartments.matchFulfilled, (state, {payload}) => {
      const nameToId: IHash = {}
      const idToName: IHash = {}
      payload.forEach((element) => {
        nameToId[element.name] = element.id
        idToName[element.id] = element.name
      })
      state.departments = {nameToId, idToName}
    })
  },
})

export const {selectDropdown} = reqTemplateSlice.actions
export const selectReqTemplateState = (state: TRootState): IState => state.reqTemplate

export default reqTemplateSlice.reducer
