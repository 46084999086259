import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import {styled, useTheme} from '@mui/material/styles'
import {formatShiftShowMinutes} from '@utils'
import React from 'react'
import type {ICompanyBase} from '~/src/models'
import type {IJobOrder} from '~/src/models/jobOrder'
import type {IPosition} from '~/src/models/position'

const StyledButton = styled(Button)({
  width: 120,
  fontWeight: 'normal',
})

const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

const BodyBox = styled(Box)({
  maxWidth: 1600,
  m: 5,
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
})

const InnerBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const LargeBold = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '28px',
  fontWeight: 600,
})

const SmallBold = styled('div')({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
})

const SmallGrey = styled('div')({
  color: '#8A8A8A',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
})

const Divider = styled('div')({
  borderBottom: '1px solid #EBEBEB',
  width: '100%',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '30px 5px 0px 5px',
  justifyContent: 'center',
})

interface IProps {
  isOpen: boolean
  position?: IPosition
  jobOrder?: IJobOrder
  handleSubmit(): void
  handleCancel(): void
}

export default function AddCandidateDialog({
  isOpen,
  position,
  jobOrder,
  handleSubmit,
  handleCancel,
}: IProps): React.ReactElement {
  const theme = useTheme()

  return (
    <Dialog
      fullWidth
      open={isOpen}
      PaperProps={{
        sx: {minWidth: theme.breakpoints.values.md, width: 1600, paddingBottom: '30px'},
      }}>
      <HeaderContainer>
        <HeaderText>Confirmation Needed</HeaderText>
        <IconButton
          aria-label="close"
          size="large"
          onClick={handleCancel}
          style={{position: 'relative', right: '-10px'}}>
          <CloseIcon style={{color: theme.palette.grey[900]}} />
        </IconButton>
      </HeaderContainer>
      <BodyBox>
        <InnerBox>
          <LargeBold style={{marginBottom: '30px', marginTop: '65px'}}>
            Add Candidate to this Job Order?
          </LargeBold>
          <SmallBold>{`Job Order #${jobOrder?.orderNumber}`}</SmallBold>
          <SmallBold style={{marginTop: '5px'}}>{`${position?.name}, ${formatShiftShowMinutes(
            position,
          )} at ${position?.companyName}`}</SmallBold>
          <SmallGrey style={{marginTop: '20px', marginBottom: '5px'}}>Work Location</SmallGrey>
          <SmallBold>{position?.facilityAddress.display}</SmallBold>
          <Divider style={{marginTop: '65px'}} />
          <ButtonBox>
            <StyledButton
              style={{marginRight: '50px'}}
              onClick={handleCancel}
              variant="outlined"
              color="secondary"
              type="button">
              Back
            </StyledButton>
            <StyledButton onClick={handleSubmit} variant="contained" color="primary" type="button">
              Confirm
            </StyledButton>
          </ButtonBox>
        </InnerBox>
      </BodyBox>
    </Dialog>
  )
}
