import {css, styled} from '@mui/material/styles'
import SignInContainer from '@ui/sign-in-container/SignInContainer'
import {push} from 'connected-react-router'
import React, {useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import PasswordResetForm from '~/src/components/auth/PasswordResetForm'
import type {TUserStatus} from '~/src/models'
import {BASE_ROUTES} from '~/src/routes/constants'
import {useResetMutation, useSigninMutation, useSignoutMutation} from '~/src/store/apis/auth-api'
import {selectRoot, updateCurrentUser} from '~/src/store/slices/root-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import {FormSubtitle} from '../../components/auth/Typography'
import {useLazyGetUserByEmailQuery, useUpdateMemberMutation} from '../../store/apis/user-api'
import EulaDialog from './EulaDialog'

const FormTitle = styled('h2')(
  ({theme}) => css`
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.h2.fontWeight};
    margin-top: 50px;
    margin-bottom: 25px;
  `,
)

const FormEmail = styled('div')(
  ({theme}) => css`
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 25px;
  `,
)

export default function PasswordResetPage() {
  const dispatch = useAppDispatch()
  const [resetPassword, {isSuccess, isError, isLoading}] = useResetMutation()
  const [eulaResponse, setEulasponse] = useState<boolean>(false)
  const [signin, {error: signinError}] = useSigninMutation()
  const [signout] = useSignoutMutation()
  const [editMember] = useUpdateMemberMutation()
  const {oldPassword, userAttributes} = useAppSelector(selectRoot)
  const [eulaModalVisible, setEulaModalVisible] = useState(true)
  const location = useLocation()
  const [userLoadThing, setUserLoadThing] = useState<boolean>(false)
  const [getUserByEmail, {error: detailsError, isLoading: isLoadingEmail, data: userData}] =
    useLazyGetUserByEmailQuery()

  interface IState {
    state: {
      password: string
      email: string
      params: string | number | boolean | object | null
    }
  }

  const locationState = location.state as IState

  const updateEulaResponse = (response: boolean) => setEulasponse(response)
  const handleSubmit = async (values: {password: string; confirm: string}) => {
    // Users may not reset password if they bypass EULA modal
    if (eulaResponse) {
      await resetPassword({
        oldPassword: oldPassword,
        password: values.password,
        email: locationState.state.email,
        email_verified: 'true',
      }).unwrap()

      await signin({emailAddress: locationState.state.email, password: values.password}).unwrap()
      const user = await getUserByEmail(locationState.state.email).unwrap()

      const member = {
        ...user,
        agreedToTermsDate: new Date().toISOString(),
        status: 'Active' as TUserStatus,
      }

      dispatch(updateCurrentUser(member))

      await editMember(member).unwrap()
      await signout({email: locationState.state.email})

      dispatch(
        push(BASE_ROUTES.PASSWORD_CONFIRMATION, {
          state: {
            password: values.password,
            email: locationState.state.email,
          },
        }),
      )
    }
  }

  return (
    <SignInContainer
      customFooter={
        <NavLink className="nav-link" to={BASE_ROUTES.SIGNIN}>
          <div>
            Back to <u>Sign In</u>
          </div>
        </NavLink>
      }>
      <FormTitle>Set Password</FormTitle>
      <FormEmail>Login Email: {userAttributes?.email}</FormEmail>
      <FormSubtitle>Enter your new password</FormSubtitle>
      <PasswordResetForm isError={isError} oldPassword={oldPassword} onSubmit={handleSubmit} />
      <EulaDialog
        handleClick={updateEulaResponse}
        isModalOpen={eulaModalVisible}
        onModalClose={() => {
          setEulaModalVisible(false)
        }}
      />
    </SignInContainer>
  )
}
