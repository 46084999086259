import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateProfileCertsDisplay from '../CandidateProfileDisplayandEdit/MspCandidateProfileCertsDisplay'
import MspCandidateProfileCertsEdit from '../CandidateProfileDisplayandEdit/MspCandidateProfileCertsEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateProfileCerts({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
}: IProps) {
  const dispatch = useDispatch()
  const [editProfileCertState, setEditProfileCertState] = useState('saveChanges')

  const handleClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileCertState('edit')
    } else {
      await handleSubmit(values)
      setEditProfileCertState('saveChanges')
    }
  }

  const handleCancel = () => {
    resetForm()
    setEditProfileCertState('saveChanges')
  }

  useEffect(() => {
    setEditProfileCertState('saveChanges')
  }, [oldApplicant.id])

  const projectCerts = () => {
    switch (editProfileCertState) {
      case 'edit':
        return (
          <MspCandidateProfileCertsEdit
            handleCancel={handleCancel}
            handleClick={handleClick}
            setFieldValue={setFieldValue}
            values={values.certifications}
          />
        )
      case 'saveChanges':
        return (
          <MspCandidateProfileCertsDisplay
            handleClick={handleClick}
            values={values.certifications}
          />
        )

      default:
        return (
          <MspCandidateProfileCertsDisplay
            handleClick={handleClick}
            values={values.certifications}
          />
        )
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectCerts()}</DashboardMainStyles>
    </>
  )
}
