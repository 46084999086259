import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EllipsisVIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='4'
			height='16'
			viewBox='0 0 4 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2 4.00098C3.10449 4.00098 4 3.10554 4 2.00098C4 0.896414 3.10449 0.000976562 2 0.000976562C0.895508 0.000976562 0 0.896414 0 2.00098C0 3.10554 0.895508 4.00098 2 4.00098Z'
				fill='currentColor'
			/>
			<path
				d='M4 7.98535C4 9.08991 3.10449 9.98535 2 9.98535C0.895508 9.98535 0 9.08991 0 7.98535C0 6.88079 0.895508 5.98535 2 5.98535C3.10449 5.98535 4 6.88079 4 7.98535Z'
				fill='currentColor'
			/>
			<path
				d='M4 14.001C4 15.1055 3.10449 16.001 2 16.001C0.895508 16.001 0 15.1055 0 14.001C0 12.8964 0.895508 12.001 2 12.001C3.10449 12.001 4 12.8964 4 14.001Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
