import {css, FlattenSimpleInterpolation} from 'styled-components';

export const media = {
	mobile: {
		min: '(min-width: 0px)',
		max: '(max-width: 414px)',
	},
	tablet: {
		min: '(min-width: 414.1px)',
		max: '(max-width: 1024px)',
	},
	desktop: {
		min: '(min-width: 1024.1px)',
	},
};

/*
 * Usage:
 *	${desktop(css`
 *		background-color: blue;
 *	`)}
 */
export function mobile(properties: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
	return css`
		@media ${media.mobile.min} {
			${properties};
		}
`;
}

export function tablet(properties: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
	return css`
		@media ${media.tablet.min} {
			${properties};
		}
`;
}

export function desktop(properties: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
	return css`
		@media ${media.desktop.min} {
			${properties};
		}
`;
}
