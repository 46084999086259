import React, {useCallback, useState} from 'react'
import type {INavMenuItem} from '../../layout/MenuItems'

export const useActiveLink = (navMenuItems: INavMenuItem[]) => {
  const [activeLink, setActiveLink] = useState({mainMenuId: 1, subMenuId: 0})
  const [subMenuItems, setSubMenuItems] = useState<INavMenuItem[]>([])

  const handleSetActive = useCallback(
    (event: React.ChangeEvent<HTMLElement>) => {
      const id = parseInt(event.target.id, 10)
      if (id < 10) {
        setActiveLink({subMenuId: 0, mainMenuId: id})
      }

      if (id >= 10) {
        setActiveLink({...activeLink, subMenuId: id})
      }
      setSubMenuItems([])
      const activeItem = navMenuItems.find((item) => item.id == id)

      if (activeItem?.subMenuItems?.length) {
        setSubMenuItems(activeItem.subMenuItems)
      } else {
        setSubMenuItems([])
      }
    },
    [navMenuItems, activeLink],
  )

  return {activeLink, handleSetActive, subMenuItems}
}
