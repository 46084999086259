import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {Box, Button} from '@mui/material'
import {styled} from '@mui/material/styles'
import BucketButton from '@ui/buttons/BucketButton'
import React, {useCallback, useState} from 'react'
import BucketSettings, {useBucketSearch} from './BucketSettings'
import {
  getClericalSkillOptions,
  getDriversCommercialSkillOptions,
  getGeneralSkillOptions,
  getLanguageSkillOptions,
  getManufacturingSkillOptions,
  getResturantSkillOptions,
  type ISkillOption,
} from '../../msp/manage-job-orders/job-order-summary/candidate-details/candidate-skills/SkillTypes'
import type {ISkillsByCategory} from '~/src/models/applicant'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1111px;
  width: 1111px;
  padding: 30px;
  background: #ffffff;
  border-radius: 8px;
`

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`

const BoldText = styled('div')`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
`
const SelectedFiltersContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  height: 40px;
  width: 120px;
  font-weight: normal;
`

const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IBucketHeaderProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

function BucketHeader({isOpen, setIsOpen}: IBucketHeaderProps) {
  return (
    <HeaderContainer>
      <BoldText
        onClick={() => {
          setIsOpen(!isOpen)
        }}>
        Bucket List
      </BoldText>
      {isOpen ? (
        <ArrowDropUpIcon
          sx={{color: '#8a8a8a', cursor: 'pointer'}}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      ) : (
        <ArrowDropDownIcon
          sx={{color: '#8a8a8a', cursor: 'pointer'}}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      )}
    </HeaderContainer>
  )
}

export type TFlag = 'Ready For Assignment' | 'Incomplete Application' | 'Do Not Use' | 'No Flag'

export interface IFiltersToPass {
  selectedDistance?: number
  previouslyWorkedAtFilters?: string[]
  skills?: ISkillsByCategory[]
  filterByFlags?: TFlag[]
}

export interface IGatherInput {
  selectedDistance?: number
  previouslyWorkedAtFilters?: string[]
  previouslyWorkedAtText?: string | null
  skillsFilters?: ISkillOption[]
  greenCheck: boolean
  yellowCheck: boolean
  redCheck: boolean
  noFlagCheck: boolean
}

export const serializeBucketData = ({
  selectedDistance,
  previouslyWorkedAtFilters,
  previouslyWorkedAtText,
  skillsFilters,
  greenCheck,
  yellowCheck,
  redCheck,
  noFlagCheck,
}: IGatherInput): IFiltersToPass => {
  const filterByFlags: TFlag[] = []
  if (greenCheck) {
    filterByFlags.push('Ready For Assignment')
  }
  if (yellowCheck) {
    filterByFlags.push('Incomplete Application')
  }
  if (redCheck) {
    filterByFlags.push('Do Not Use')
  }
  if (noFlagCheck) {
    filterByFlags.push('No Flag')
  }

  const skills: ISkillsByCategory[] = []
  skillsFilters?.forEach((item) => {
    const foundCategory = skills.find((skill) => skill.category === item.category)
    if (foundCategory) {
      foundCategory.categorySkills.push(item.value)
    } else {
      skills.push({category: item.category, categorySkills: [item.value]})
    }
  })

  let companies: string[] =
    previouslyWorkedAtFilters && previouslyWorkedAtFilters.length > 0
      ? previouslyWorkedAtFilters
      : []
  if (previouslyWorkedAtText && previouslyWorkedAtText.length > 0) {
    companies = [...companies, previouslyWorkedAtText]
  }

  const filters = {
    filterByFlags: filterByFlags.length > 0 ? filterByFlags : undefined,
    selectedDistance: selectedDistance,
    previouslyWorkedAtFilters: companies.length > 0 ? companies : undefined,
    skills: skills.length > 0 ? skills : undefined,
  }

  return filters
}

export const deserializeBucketData = ({
  selectedDistance,
  previouslyWorkedAtFilters,
  skills,
  filterByFlags,
}: IFiltersToPass): IGatherInput => {
  const allSkills = [
    ...getClericalSkillOptions(),
    ...getGeneralSkillOptions(),
    ...getManufacturingSkillOptions(),
    ...getResturantSkillOptions(),
    ...getDriversCommercialSkillOptions(),
    ...getLanguageSkillOptions(),
  ]
  const processedSkills: ISkillOption[] = []
  skills?.forEach((skillCategory) => {
    skillCategory.categorySkills.forEach((catSkill) => {
      processedSkills.push(
        allSkills.find(
          (allSkill) => allSkill.category === skillCategory.category && allSkill.value === catSkill,
        ) as ISkillOption,
      )
    })
  })
  return {
    selectedDistance: selectedDistance,
    previouslyWorkedAtFilters: previouslyWorkedAtFilters ?? [],
    skillsFilters: processedSkills,
    redCheck: filterByFlags?.find((flag) => flag === 'Do Not Use') !== undefined,
    yellowCheck: filterByFlags?.find((flag) => flag === 'Incomplete Application') !== undefined,
    greenCheck: filterByFlags?.find((flag) => flag === 'Ready For Assignment') !== undefined,
    noFlagCheck: filterByFlags?.find((flag) => flag === 'No Flag') !== undefined,
  }
}

export function useBucket() {
  const [redCheck, setRedCheck] = useState<boolean>(true)
  const [greenCheck, setGreenCheck] = useState<boolean>(true)
  const [yellowCheck, setYellowCheck] = useState<boolean>(true)
  const [noFlagCheck, setNoFlagCheck] = useState<boolean>(true)
  const [selectedDistance, setSelectedDistance] = useState<number | undefined>()
  const [allFiltersToPass, setAllFiltersToPass] = useState<IFiltersToPass | undefined>(undefined)

  const {
    text: previouslyWorkedAtText,
    setText: setPreviouslyWorkedAtText,
    filters: previouslyWorkedAtFilters,
    setFilters: setPreviouslyWorkedAtFilters,
    value: previouslyWorkedAtValue,
    setValue: setPreviouslyWorkedAtValue,
  } = useBucketSearch<string>('Search Company')

  const {
    text: skillsText,
    setText: setSkillsText,
    filters: skillsFilters,
    setFilters: setSkillsFilters,
    value: skillsValue,
    setValue: setSkillsValue,
  } = useBucketSearch<ISkillOption>('Search Skills')

  const setFiltersToPass = (): IFiltersToPass => {
    const filters = serializeBucketData({
      greenCheck,
      yellowCheck,
      redCheck,
      noFlagCheck,
      skillsFilters,
      previouslyWorkedAtFilters,
      previouslyWorkedAtText,
      selectedDistance,
    })
    setAllFiltersToPass(filters)

    return filters
  }

  const init = useCallback(
    (initData: IFiltersToPass) => {
      const deserializedData = deserializeBucketData(initData)
      const {
        redCheck: redCheckSub,
        greenCheck: greenCheckSub,
        yellowCheck: yellowCheckSub,
        noFlagCheck: noFlagCheckSub,
        skillsFilters: skillsFiltersSub,
        previouslyWorkedAtFilters: previouslyWorkedAtFiltersSub,
        selectedDistance: selectedDistanceSub,
      } = deserializedData
      setRedCheck(redCheckSub)
      setGreenCheck(greenCheckSub)
      setYellowCheck(yellowCheckSub)
      setNoFlagCheck(noFlagCheckSub)
      setSkillsFilters(skillsFiltersSub ?? [])
      setPreviouslyWorkedAtFilters(previouslyWorkedAtFiltersSub ?? [])
      setSelectedDistance(selectedDistanceSub)

      const filters = serializeBucketData({
        redCheck: redCheckSub,
        greenCheck: greenCheckSub,
        yellowCheck: yellowCheckSub,
        noFlagCheck: noFlagCheckSub,
        skillsFilters: skillsFiltersSub,
        previouslyWorkedAtFilters: previouslyWorkedAtFiltersSub,
        selectedDistance: selectedDistanceSub,
      })
      setAllFiltersToPass(filters)
    },
    [setPreviouslyWorkedAtFilters, setSkillsFilters],
  )

  return {
    redCheck,
    setRedCheck,
    greenCheck,
    setGreenCheck,
    yellowCheck,
    setYellowCheck,
    noFlagCheck,
    setNoFlagCheck,
    selectedDistance,
    setSelectedDistance,
    previouslyWorkedAtText,
    setPreviouslyWorkedAtText,
    previouslyWorkedAtFilters,
    setPreviouslyWorkedAtFilters,
    previouslyWorkedAtValue,
    setPreviouslyWorkedAtValue,
    skillsText,
    setSkillsText,
    skillsFilters,
    setSkillsFilters,
    skillsValue,
    setSkillsValue,
    setFiltersToPass,
    allFiltersToPass,
    setAllFiltersToPass,
    init,
  }
}

interface IProps {
  containerSx?: object
  selectedDistance?: number
  setSelectedDistance: (val?: number) => void
  previouslyWorkedAtFilters: string[]
  setPreviouslyWorkedAtFilters: (val: string[]) => void
  skillsFilters: ISkillOption[]
  setSkillsFilters: (val: ISkillOption[]) => void
  skillsText: string | null
  setSkillsText: (val: string | null) => void
  skillsValue: ISkillOption | null
  setSkillsValue: (val: ISkillOption | null) => void
  resetFilters: () => void
  setFiltersToPass: () => IFiltersToPass
  previouslyWorkedAtText: string | null
  setPreviouslyWorkedAtText: (val: string | null) => void
  previouslyWorkedAtValue: string | null
  setPreviouslyWorkedAtValue: (val: string | null) => void
  redCheck: boolean
  setRedCheck: (val: boolean) => void
  greenCheck: boolean
  setGreenCheck: (val: boolean) => void
  yellowCheck: boolean
  setYellowCheck: (val: boolean) => void
  noFlagCheck: boolean
  setNoFlagCheck: (val: boolean) => void
}

export default function BucketList({
  selectedDistance = undefined,
  setSelectedDistance,
  previouslyWorkedAtFilters,
  setPreviouslyWorkedAtFilters,
  previouslyWorkedAtValue,
  setPreviouslyWorkedAtValue,
  skillsFilters,
  setSkillsFilters,
  skillsText,
  setSkillsText,
  skillsValue,
  setSkillsValue,
  resetFilters,
  setFiltersToPass,
  previouslyWorkedAtText,
  setPreviouslyWorkedAtText,
  redCheck,
  setRedCheck,
  greenCheck,
  setGreenCheck,
  yellowCheck,
  setYellowCheck,
  noFlagCheck,
  setNoFlagCheck,
  containerSx = {},
}: IProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen ? (
        <Container sx={containerSx}>
          <BucketHeader isOpen={isOpen} setIsOpen={setIsOpen} />
          <SelectedFiltersContainer>
            <BucketButton isFocused={true} isHand={false}>
              Default Bucket
            </BucketButton>
          </SelectedFiltersContainer>
          <BucketSettings
            selectedDistance={selectedDistance}
            setSelectedDistance={setSelectedDistance}
            previouslyWorkedAtFilters={previouslyWorkedAtFilters}
            setPreviouslyWorkedAtFilters={setPreviouslyWorkedAtFilters}
            previouslyWorkedAtText={previouslyWorkedAtText}
            setPreviouslyWorkedAtText={setPreviouslyWorkedAtText}
            previouslyWorkedAtValue={previouslyWorkedAtValue}
            setPreviouslyWorkedAtValue={setPreviouslyWorkedAtValue}
            skillsFilters={skillsFilters}
            setSkillsFilters={setSkillsFilters}
            skillsText={skillsText}
            setSkillsText={setSkillsText}
            skillsValue={skillsValue}
            setSkillsValue={setSkillsValue}
            redCheck={redCheck}
            setRedCheck={setRedCheck}
            greenCheck={greenCheck}
            setGreenCheck={setGreenCheck}
            yellowCheck={yellowCheck}
            setYellowCheck={setYellowCheck}
            noFlagCheck={noFlagCheck}
            setNoFlagCheck={setNoFlagCheck}
            containerSx={{margin: '25px 0px'}}
          />
          <ButtonBox>
            <StyledButton
              variant="outlined"
              color="secondary"
              type="reset"
              sx={{mr: '20px'}}
              onClick={() => {
                resetFilters()
                setIsOpen(false)
              }}>
              Reset
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                setFiltersToPass()
                setIsOpen(false)
              }}>
              Save
            </StyledButton>
          </ButtonBox>
        </Container>
      ) : (
        <Container sx={containerSx}>
          <BucketHeader isOpen={isOpen} setIsOpen={setIsOpen} />
          <SelectedFiltersContainer>
            <BucketButton isFocused={true} isHand={false}>
              Default Bucket
            </BucketButton>
          </SelectedFiltersContainer>
        </Container>
      )}
    </>
  )
}
