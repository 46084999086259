export enum EJobCategory {
  'Information Technology',
  'Human Resources',
  'Accounting',
  'Customer Support',
  'Marketing',
  'Sales',
}

export enum EJobType {
  'Full - Time',
  'Part - Time',
}

export enum EJobLevel {
  'Intern',
  'Associate',
  'Entry Level',
  'Mid Level',
  'Senior',
}

export enum EJobExperience {
  'Zero To Two Years',
  'Two To Four Years',
  'Five To Ten Years',
  'Ten Plus Years',
}

export enum EReqTemplateStatus {
  Published,
  Disabled,
}

export interface IReqTemplateBase {
  title: string
  companyId: string
  departmentId: string
  jobCategory: EJobCategory
  jobType: EJobType
  jobLevel: EJobLevel
  isRemote: boolean
  location: string
  jobExperience: EJobExperience
  description: string
  necessarySkills: string[]
  salaryRangeLow: number
  salaryRangeHigh: number
  benefits: string[]
  status: EReqTemplateStatus
}

export interface IReqTemplateCreate extends IReqTemplateBase {
  status: number
  id: string
  createdDate: string
  modifiedDate: string
  versionKey: number
}

export type TReqTemplatesPayload = {
  reqTemplates: IReqTemplateCreate[]
  totalReqTemplates: number
}

export type TPagedReqTemplatesPayload = {pageNumber?: number; pageSize?: number}
