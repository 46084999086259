import {Alert, Snackbar} from '@mui/material'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {ReactElement, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import DataCleanupDashboard from '~/src/components/msp/dashboard/DataCleanupDashboard'
import JobOrderDashboard from '~/src/components/msp/dashboard/JobOrderDashboard'
import ServiceOrderDashboard from '~/src/components/msp/dashboard/ServiceOrderDashboard'
import {useGetJobOrdersQuery} from '~/src/store/apis/job-order-api'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import PrefillDashboard from '../../common/prefill/PrefillDashboard'
import styled from 'styled-components'

export const DefaultContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1135px;

  @media (min-width: 2000px) {
    width: 1500px;
  }
`

interface IState {
  state: {
    openSnackbar: boolean
    name: string
  }
}

export default function MspAgencyDashboard(): ReactElement {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const locationState = location.state as IState | undefined
  const [open, setOpen] = useState(
    locationState?.state.openSnackbar ? locationState.state.openSnackbar : false,
  )

  const {data, refetch, error, isLoading, isFetching} = useGetJobOrdersQuery({
    pageNumber: 1,
    pageSize: 4,
    agencyId: loggedInAgencyId,
  })

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    dispatch(push('/agency-admin/dashboard'))
  }

  useEffect(() => {
    void refetch()
  }, [refetch])

  return (
    <DefaultContainer>
      <LoadingBoundary isLoading={isLoading}>
        <Title pageTitle="Dashboard" />
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Success! The Msp Agency {locationState?.state.name} has been Edited!
          </Alert>
        </Snackbar>

        <DataCleanupDashboard isMsp />
        <PrefillDashboard isMsp />
        <JobOrderDashboard isMsp />
        <ServiceOrderDashboard isMsp />
      </LoadingBoundary>
    </DefaultContainer>
  )
}
