import React from 'react'
import Box from '@mui/material/Box'
import {styled, css} from '@mui/material/styles'
import {isFetchBaseQueryError, isSerializedError} from '~/src/utils'

const StyledErrorBox = styled(Box)(
  ({theme}) => css`
    color: ${theme.palette.error.dark};
    font-size: ${theme.typography.body2.fontSize};
    margin: ${theme.spacing(1)} 0;
  `,
)

export default function ErrorBox({children: error}: {children: React.ReactNode}) {
  if (isFetchBaseQueryError(error)) {
    return <StyledErrorBox>{JSON.stringify(error.data).replace(/"/g, '')}</StyledErrorBox>
  }

  if (isSerializedError(error)) {
    return <StyledErrorBox>{JSON.stringify(error.message).replace(/"/g, '')}</StyledErrorBox>
  }

  if (typeof error === 'string') {
    return <StyledErrorBox>{error}</StyledErrorBox>
  }

  return null
}
