/* eslint-disable complexity */
import Box from '@mui/material/Box'
import type {TProcessedCandidate} from '@ui/candidate-list/CandidateList'
import JobOrderCard from '@ui/card/JobOrderCard'
import MspCompanyDetailsSummaryCard from '@ui/card/MspCompanyDetailsSummaryCard'
import MspPositionBreakdownSummaryCard from '@ui/card/MspPositionBreakdownSummaryCard'
import BasicConfirmationDialog from '@ui/dialog/BasicConfirmationDialog'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {errorFunction} from '@utils/errorFunction'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import type {Applicant} from '~/src/models/applicant'
import type {AgencyDistribution} from '~/src/models/jobOrder'
import {
  useAddCandidateToJobOrderMutation,
  useDeclineCandidateFromJobOrderMutation,
  useFillJobOrderMutation,
  useGetJobOrderQuery,
  useRemoveCandidateFromJobOrderMutation,
  useSkipCandidateFromJobOrderMutation,
  useUpdateCandidateEndDateMutation,
} from '~/src/store/apis/job-order-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetSingleServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {LoadingContext} from '~/src/store/context/LoadingContext'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppSelector} from '~/src/store/store-hooks'
import {colors} from '../../../config/theme'
import ReusableSnackbar from '../../Snackbar/ReusableSnackbar'
import BucketList, {useBucket} from '../bucket/BucketList'
import CandidatesContainer from '../candidates/CandidatesContainer'
import AddCandidateDialog from './AddCandidateDialog'
import AnotherAssignmentDialog from './AnotherAssignmentDialog'
import EndAssignmentDialog, {IFormSubmitValues} from './EndAssignmentDialog'

const AgencyOrderDetailsStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
    margin: auto;
  `,
  TableInfo: styled.span`
    margin-right: 24px;
    padding: 8px 0;
  `,
  AddContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  `,
}

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
`

const JobOrderNumberContainer = styled('div')`
  font-size: 24px
  font-weight: 300;
  display: flex;
  margin: auto
  max-width: 1111px;
  width: 1111px;
  flex-irection: row;
`

const JobOrderCardContainer = styled('div')`
  display: flex;
  margin: auto;
  max-width: 1111px;
  width: 1111px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const CompanyDetailsContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const PositionBreakdownContainer = styled('div')`
  height: 287px;
  width: 410px;
  border-radius: 16px;
  background: ${colors.white};
  cursor: pointer;
`

export const DropDownContainer = styled('div')`
  width: 10.5em;
  margin: 0 auto;
`

export const DropDownHeader = styled('div')`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`

export const DropDownListContainer = styled('div')`
  position: absolute;
  max-width: 261px;
  min-width: 261px;
  border-radius: 10px;
  margin-left: -15px;
  z-index: 3;
`

export const DropDownList = styled('ul')`
  padding: 5px;
  background: #ffffff;
  box-sizing: border-box;
  color: black;
  border-radius: 8px;
  &:first-child {
    padding-top: 0.8em;
  }
`

export const ListItem = styled('li')`
  display: flex;
  list-style: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0.8em;
  cursor: pointer;
  &:hover {
    background: #ebebeb;
    border-radius: 8px;
  }
`

interface UrlParams {
  id: string
}

export default function JobOrdersDetailsSummaryMain() {
  const params: UrlParams = useParams()
  const jobOrderId = params.id
  const showDetails = new URLSearchParams(location.search).get('showDetails')
  const [isAddConfirmationDialogOpen, setIsAddConfirmationDialogOpen] = useState(false)
  const [isAnotherAssignmentDialogOpen, setIsAnotherAssignmentDialogOpen] = useState(false)
  const [candidateDataForAnotherAssignmentDialog, setCandidateDataForAnotherAssignmentDialog] =
    useState<TProcessedCandidate | undefined>(undefined)
  const [isEndAssignmentDialogOpen, setIsEndAssignmentDialogOpen] = useState(false)
  const [maxCandidatesConfirmationOpen, setMaxCandidatesConfirmationOpen] = useState(false)
  const [candidateIdToEnd, setCandidateIdToEnd] = useState('')
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const {currentUser} = useCurrentUser()
  const [bucketListFilter, setBucketListFilter] = useState('All Candidates')
  const [bucketListCleanUpFilter, setBucketListCleanUpFilter] = useState<string | undefined>(
    undefined,
  )
  const [focusedValue, setFocusedValue] = useState(0)
  const [addedCandidates, setAddedCandidates] = useState<Partial<Applicant>[]>([])
  const [statusAndIdForCandidateToAdd, setStatusAndIdForCandidateToAdd] = useState<{
    status?: string
    candidateId?: string
    agencyId?: string
  }>({})

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [customErrorMessage, setCustomErrorMessage] = useState('')

  const [newIsLoading, setNewIsLoading] = useState(false)

  const {
    data: jobOrderData,
    refetch: jobOrderRefetch,
    isLoading,
    isSuccess,
  } = useGetJobOrderQuery(
    {id: jobOrderId, agencyId: loggedInAgencyId},
    {refetchOnMountOrArgChange: true},
  )

  const {data: serviceOrder, refetch: serviceOrderRefetch} = useGetSingleServiceOrderQuery(
    {id: jobOrderData?.serviceOrderId as string},
    {skip: !isSuccess},
  )

  const isMsp = loggedInAgencyId === serviceOrder?.agencyId

  const {
    data: positionData,
    refetch: positionRefetch,
    isSuccess: isPositionSuccess,
  } = useGetPositionByIdQuery({
    id: jobOrderData ? jobOrderData.positionId : '',
  })

  const [addCandidate] = useAddCandidateToJobOrderMutation()
  const [removeCandidate] = useRemoveCandidateFromJobOrderMutation()
  const [declineCandidate] = useDeclineCandidateFromJobOrderMutation()
  const [skipCandidate] = useSkipCandidateFromJobOrderMutation()
  const [fillJobOrder] = useFillJobOrderMutation()
  const [updateCandidateEndDate] = useUpdateCandidateEndDateMutation()

  const {
    redCheck,
    setRedCheck,
    greenCheck,
    setGreenCheck,
    yellowCheck,
    setYellowCheck,
    noFlagCheck,
    setNoFlagCheck,
    selectedDistance,
    setSelectedDistance,
    previouslyWorkedAtText,
    setPreviouslyWorkedAtText,
    previouslyWorkedAtFilters,
    setPreviouslyWorkedAtFilters,
    previouslyWorkedAtValue,
    setPreviouslyWorkedAtValue,
    skillsText,
    setSkillsText,
    skillsFilters,
    setSkillsFilters,
    skillsValue,
    setSkillsValue,
    setFiltersToPass,
    allFiltersToPass,
    setAllFiltersToPass,
  } = useBucket()

  const distributionLists = jobOrderData?.agencyDistributions
  const agencyDistribution = distributionLists
    ? distributionLists.find((list) => list.agencyId === loggedInAgencyId)
    : undefined

  const applicantIds = agencyDistribution
    ? agencyDistribution.agencyCandidateDistributions.candidateList.map(
        (applicant: {candidateId: string}) => applicant.candidateId,
      )
    : undefined

  const excludedCandidateIds = applicantIds && applicantIds.length > 0 ? applicantIds : undefined
  let isFilledOrReady = false
  let isDisabled = false

  if (isMsp) {
    isFilledOrReady =
      jobOrderData?.agencyDistributions
        .map((item) => item.agencyStatus)
        .find((item) => item !== 'Filled') === undefined
    isDisabled = jobOrderData?.status === 'Filled'
  } else {
    const agencyStatus = jobOrderData?.agencyDistributions.find(
      (item) => item.agencyId === loggedInAgencyId,
    )?.agencyStatus
    isFilledOrReady = agencyStatus === 'Ready To Fill' || agencyStatus === 'Filled'
    isDisabled = agencyStatus === 'Filled'
  }

  const [showCandidateDetails, setShowCandidateDetails] = useState(showDetails !== 'false')

  const handleSummaryToggle = (value: boolean) => {
    setShowCandidateDetails(value)
  }

  const getDate = (date: string | undefined) => {
    if (date) {
      return new Date(date).toLocaleTimeString('En-US', {
        hour12: true,
        hour: 'numeric',
      })
    }
  }

  let totalCandidatesNeeded: number = 0
  let totalCandidatesFilled: number = 0
  if (jobOrderData) {
    totalCandidatesNeeded = jobOrderData.agencyDistributions
      .map((distribution) => distribution.agencyCandidateDistributions.requested)
      .reduce((prev: number, curr: number) => prev + curr, 0)

    totalCandidatesFilled = jobOrderData.agencyDistributions
      .map((distribution) => distribution.agencyCandidateDistributions.fulfilled)
      .reduce((prev: number, curr: number) => prev + curr, 0)
  }

  const totalCandidatesLeft =
    totalCandidatesNeeded - totalCandidatesFilled < 0
      ? 0
      : totalCandidatesNeeded - totalCandidatesFilled

  const handleRemoveCandidate = async (candidateId: string) => {
    if (jobOrderData) {
      if (jobOrderData.status === 'Filled') {
        setCandidateIdToEnd(candidateId)
        setIsEndAssignmentDialogOpen(true)
      } else {
        void (await removeCandidate({
          candidateId,
          jobOrderId: jobOrderData.id,
          agencyId: loggedInAgencyId,
        }).unwrap())
        // void (await jobOrderRefetch())
        setNewIsLoading(true)
      }
    }
  }

  const handleAddCandidate = async (status: string, candidate: TProcessedCandidate) => {
    setStatusAndIdForCandidateToAdd({
      status: status,
      candidateId: candidate.candidate.id,
      agencyId: candidate.candidate.agencyId,
    })
    if (candidate.conflictingJobOrderIds.length > 0) {
      setCandidateDataForAnotherAssignmentDialog(candidate)
      setIsAnotherAssignmentDialogOpen(true)
    } else {
      setIsAddConfirmationDialogOpen(true)
    }
  }

  const handleDeclineCandidate = async (candidateId: string, notes: string) => {
    void (await declineCandidate({
      candidateId,
      jobOrderId: jobOrderId,
      notes,
      agencyId: loggedInAgencyId,
    }).unwrap())
    // void (await jobOrderRefetch())
    setNewIsLoading(true)
  }

  const handleSkipCandidate = async (notes: string, candidateId: string) => {
    void (await skipCandidate({
      candidateId: candidateId,
      jobOrderId: jobOrderId,
      notes,
      agencyId: loggedInAgencyId,
    }).unwrap())
    // void jobOrderRefetch()
    setNewIsLoading(true)
  }

  const distribution = useMemo(() => {
    return jobOrderData?.agencyDistributions.find((distro) => distro.agencyId === loggedInAgencyId)
  }, [jobOrderData?.agencyDistributions, loggedInAgencyId])

  useEffect(() => {
    const isFilledMsp = jobOrderData && jobOrderData.status === 'Ready To Fill'
    const isFilledNonMsp =
      jobOrderData && (jobOrderData.status === 'Ready To Fill' || jobOrderData.status === 'Filled')

    if (isMsp) {
      setMaxCandidatesConfirmationOpen(isFilledMsp ?? false)
    } else if (
      isFilledNonMsp !== undefined &&
      !isFilledNonMsp &&
      serviceOrder &&
      distribution &&
      distribution.agencyCandidateDistributions.fulfilled >=
        distribution.agencyCandidateDistributions.requested
    ) {
      setMaxCandidatesConfirmationOpen(true)
    }
  }, [distribution, isMsp, jobOrderData, serviceOrder])

  const handleCloseAddCandidateConfirmationDialog = useCallback(async () => {
    setCustomErrorMessage('')
    setIsAddConfirmationDialogOpen(false)
    setIsAnotherAssignmentDialogOpen(false)
    void (await addCandidate({
      candidateId: statusAndIdForCandidateToAdd.candidateId as string,
      jobOrderId: jobOrderId,
      candidateStatus: statusAndIdForCandidateToAdd.status as string,
      notes: `Placed on Job Order: ${jobOrderData?.orderNumber} by ${currentUser?.firstName} ${
        currentUser?.lastName
      } - ${localStorage.getItem('companyName')}`,
      agencyId: statusAndIdForCandidateToAdd?.agencyId,
    })
      .unwrap()
      .then(() => setNewIsLoading(true))
      .catch((err) => errorFunction(err, setIsSnackbarOpen, setCustomErrorMessage)))
  }, [
    addCandidate,
    currentUser?.firstName,
    currentUser?.lastName,
    jobOrderData?.orderNumber,
    jobOrderId,
    jobOrderRefetch,
    loggedInAgencyId,
    statusAndIdForCandidateToAdd.candidateId,
    statusAndIdForCandidateToAdd.status,
  ])

  const addedIds: Partial<Applicant>[] = useMemo(() => {
    const retVal: Partial<Applicant>[] = []
    const distributions = jobOrderData?.agencyDistributions
    distributions?.forEach((distributionLowerScope: AgencyDistribution) => {
      distributionLowerScope.agencyCandidateDistributions.candidateList.forEach((candidate) => {
        if (candidate.candidateStatus !== 'Skipped' && candidate.candidateStatus !== 'Declined') {
          const updatedCandidate = {...candidate, agencyId: distributionLowerScope.agencyId}
          retVal.push(updatedCandidate as Partial<Applicant>)
        }
      })
    })
    return retVal
  }, [jobOrderData?.agencyDistributions])

  const getAddedCandidates = useCallback(async () => {
    setAddedCandidates(addedIds)
  }, [addedIds])

  const handleReviewMaxCandidates = useCallback(() => {
    setShowCandidateDetails(false)
    setMaxCandidatesConfirmationOpen(false)
  }, [])

  useEffect(() => {
    void getAddedCandidates()
  }, [getAddedCandidates, jobOrderData])

  const handleFillJobOrder = () => {
    void fillJobOrder({jobOrderId, agencyId: loggedInAgencyId})
    if (isMsp) {
      void serviceOrderRefetch()
    }
  }

  const resetFilters = useCallback(() => {
    setSelectedDistance(undefined)
    setPreviouslyWorkedAtFilters([])
    setSkillsFilters([])
    setRedCheck(true)
    setYellowCheck(true)
    setGreenCheck(true)
    setNoFlagCheck(true)
    setAllFiltersToPass({
      filterByFlags: [],
      selectedDistance: undefined,
      previouslyWorkedAtFilters: [],
      skills: [],
    })
  }, [
    setAllFiltersToPass,
    setGreenCheck,
    setNoFlagCheck,
    setPreviouslyWorkedAtFilters,
    setRedCheck,
    setSelectedDistance,
    setSkillsFilters,
    setYellowCheck,
  ])

  useEffect(() => {
    const fetchData = async () => {
      await jobOrderRefetch()
      setNewIsLoading(false)
    }

    void fetchData()
  }, [newIsLoading])

  return (
    <LoadingContext.Provider value={{setNewIsLoading: setNewIsLoading, newIsLoading: newIsLoading}}>
      <ReusableSnackbar
        customMessage={customErrorMessage}
        isOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
      />
      {newIsLoading ? (
        <LoadingBoundary isLoading={newIsLoading} />
      ) : (
        <>
          <AddCandidateDialog
            isOpen={isAddConfirmationDialogOpen}
            handleSubmit={handleCloseAddCandidateConfirmationDialog}
            handleCancel={() => {
              setIsAddConfirmationDialogOpen(false)
            }}
            position={positionData}
            jobOrder={jobOrderData}
          />
          <AnotherAssignmentDialog
            isOpen={isAnotherAssignmentDialogOpen}
            handleSubmit={handleCloseAddCandidateConfirmationDialog}
            handleCancel={() => {
              setIsAnotherAssignmentDialogOpen(false)
            }}
            candidateData={candidateDataForAnotherAssignmentDialog}
          />
          <EndAssignmentDialog
            isOpen={isEndAssignmentDialogOpen}
            distributionStartDate={serviceOrder?.orders[0]?.distributionStartDate as string}
            handleSubmit={({reason, endDate}: IFormSubmitValues) => {
              void updateCandidateEndDate({
                reason: reason as string,
                endDate: endDate as Date,
                id: jobOrderId,
                agencyId: loggedInAgencyId,
                candidateId: candidateIdToEnd,
              })
              setIsEndAssignmentDialogOpen(false)
            }}
            handleCancel={() => {
              setIsEndAssignmentDialogOpen(false)
            }}
          />
          <BasicConfirmationDialog
            isOpen={maxCandidatesConfirmationOpen && !isAddConfirmationDialogOpen}
            handleCancel={() => setMaxCandidatesConfirmationOpen(false)}
            handleSubmit={handleReviewMaxCandidates}
            header="Order Requirements Filled"
            bigBlurb="Job Order Fill Satisfied"
            smallBlurb="You have satisfied the fill requirements for this Job Order. Please review the candidates selected."
            confirmButtonLabel="Review"
            cancelButtonLabel="Back"
          />
          <AgencyOrderDetailsStyles.Container>
            <TitleContainer>
              <JobOrderNumberContainer>
                <h2 style={{marginBottom: 20}}>Job Order {jobOrderData?.orderNumber}</h2>
              </JobOrderNumberContainer>
            </TitleContainer>
            <ErrorBoundary>
              <LoadingBoundary>
                <>
                  <JobOrderCardContainer>
                    <div>
                      {jobOrderData ? (
                        <JobOrderCard
                          jobOrder={jobOrderData}
                          currentlyfilled={totalCandidatesFilled}
                          onListIconClick={handleSummaryToggle}
                          totalrequested={totalCandidatesNeeded}
                          enableButtonColor
                          showList={showDetails === 'false'}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                    <CompanyDetailsContainer>
                      {jobOrderData && positionData ? (
                        <MspCompanyDetailsSummaryCard
                          location={positionData.facilityAddress.display}
                          endTime={getDate(positionData.endTime)}
                          position={jobOrderData.positionId}
                          positionName={positionData.name}
                          startTime={getDate(positionData.startTime)}
                          manager={positionData.manager}
                        />
                      ) : (
                        <div />
                      )}
                    </CompanyDetailsContainer>
                    <PositionBreakdownContainer>
                      {jobOrderData && positionData ? (
                        <MspPositionBreakdownSummaryCard
                          JobOrderData={jobOrderData}
                          totalCandidatesNeeded={totalCandidatesNeeded}
                          totalCandidatesLeft={totalCandidatesLeft}
                          positionData={positionData}
                        />
                      ) : (
                        <div />
                      )}
                    </PositionBreakdownContainer>
                  </JobOrderCardContainer>
                  {showCandidateDetails && (
                    <BucketList
                      selectedDistance={selectedDistance}
                      setSelectedDistance={setSelectedDistance}
                      previouslyWorkedAtFilters={previouslyWorkedAtFilters}
                      setPreviouslyWorkedAtFilters={setPreviouslyWorkedAtFilters}
                      containerSx={{marginTop: '25px'}}
                      resetFilters={resetFilters}
                      setFiltersToPass={setFiltersToPass}
                      previouslyWorkedAtText={previouslyWorkedAtText}
                      setPreviouslyWorkedAtText={setPreviouslyWorkedAtText}
                      previouslyWorkedAtValue={previouslyWorkedAtValue}
                      setPreviouslyWorkedAtValue={setPreviouslyWorkedAtValue}
                      skillsText={skillsText}
                      setSkillsText={setSkillsText}
                      skillsFilters={skillsFilters}
                      setSkillsFilters={setSkillsFilters}
                      skillsValue={skillsValue}
                      setSkillsValue={setSkillsValue}
                      redCheck={redCheck}
                      setRedCheck={setRedCheck}
                      greenCheck={greenCheck}
                      setGreenCheck={setGreenCheck}
                      yellowCheck={yellowCheck}
                      setYellowCheck={setYellowCheck}
                      noFlagCheck={noFlagCheck}
                      setNoFlagCheck={setNoFlagCheck}
                    />
                  )}
                  <CandidatesContainer
                    showCandidateDetails={showCandidateDetails}
                    excludedCandidateIds={excludedCandidateIds}
                    isDisabled={isDisabled}
                    isMsp={isMsp}
                    isFilledOrReady={isFilledOrReady}
                    isCapped={totalCandidatesLeft === 0}
                    jobOrderData={jobOrderData}
                    positionData={positionData}
                    bucketListFilter={bucketListFilter}
                    handleAddCandidate={handleAddCandidate}
                    handleDeclineCandidate={handleDeclineCandidate}
                    handleSkipCandidate={handleSkipCandidate}
                    handleRemoveCandidate={handleRemoveCandidate}
                    handleFillJobOrder={handleFillJobOrder}
                    addedCandidates={addedCandidates}
                    bucketListCleanUpFilter={bucketListCleanUpFilter}
                    setBucketListCleanUpFilter={setBucketListCleanUpFilter}
                    focusedValue={focusedValue}
                    setFocusedValue={setFocusedValue}
                    bucketFilters={allFiltersToPass}
                  />
                </>
              </LoadingBoundary>
            </ErrorBoundary>
          </AgencyOrderDetailsStyles.Container>
        </>
      )}
    </LoadingContext.Provider>
  )
}
