import {Alert, Box, Button, Snackbar} from '@mui/material'
import {css, styled, useTheme} from '@mui/material/styles'
import CreateNewOrderButton from '@ui/buttons/CreateNewOrderButton'
import ServiceOrderCard from '@ui/card/ServiceOrderCard'
import CreateOrderDropdown from '@ui/dropdown/CreateOrderDropdown'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {push} from 'connected-react-router'
import React, {ReactElement, useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import ViewWrapper from '~/src/components/common/ViewWrapper'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useGetServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {updatePageNumber} from '~/src/store/slices/service-order-slice'
import {useAppDispatch, useAppSelector} from '~/src/store/store-hooks'
import Title from '../../layout/Title'
import IsMspAssignedDialog from './IsMspAssignedDialog'

const DashboardContainer = styled('div')(
  ({theme}) => css`
    position: relative;
    flex: 1;
  `,
)

const ContainerBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 100%;
    p: 1;
    borderradius: 1;
    flex-wrap: wrap;
  `,
)

const ButtonBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
  `,
)

const SeeAllOrdersBox = styled(Box)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  `,
)

const ServiceOrdersLabel = styled('div')(
  ({theme}) => css`
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
  `,
)

export default function CompanyAdminDashboard(): ReactElement {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const {currentUser, onSignIn, loggedInAgencyId} = useAppSelector((state) => state.root)
  const {data: companyData, isLoading: isCompanyLoading} = useGetOneCompanyQuery(
    currentUser?.companyId as string,
  )
  const theme = useTheme()

  const {data, refetch, error, isLoading, isFetching} = useGetServiceOrderQuery({
    pageNumber: 1,
    pageSize: 4,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })

  interface IState {
    state: {
      openSnackbar: boolean
      name: string
    }
  }

  const locationState = location.state as IState | undefined
  const [open, setOpen] = useState(
    locationState?.state.openSnackbar ? locationState.state.openSnackbar : false,
  )
  const [openNonMspDialog, setOpenNonMspDialog] = useState(onSignIn)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    dispatch(push('/company-admin/dashboard'))
  }
  useEffect(() => {
    dispatch(updatePageNumber(1))
    localStorage.setItem('currentServiceOrderPageNumber', 1)
  }, [])

  return (
    <DashboardContainer>
      <Title pageTitle="Dashboard" />
      {isCompanyLoading ? (
        <div />
      ) : onSignIn && companyData?.agencyId === undefined ? (
        <IsMspAssignedDialog
          openNonMspDialog={openNonMspDialog}
          modalOpen={true}
          onModalClose={() => {
            setOpenNonMspDialog(false)
          }}
        />
      ) : (
        <div />
      )}

      <ButtonBox>
        <CreateOrderDropdown CustomButton={CreateNewOrderButton as typeof Button} top="138px" />
      </ButtonBox>

      <ServiceOrdersLabel>Service Orders</ServiceOrdersLabel>

      <ErrorBoundary>
        <LoadingBoundary isLoading={isLoading || isFetching}>
          <ContainerBox>
            {data?.serviceOrdersModel.map((row, index) => {
              return (
                <ServiceOrderCard
                  agencyId={row.agencyId}
                  client={row.orderOwner}
                  companyId={row.companyId}
                  currentServiceOrderPageNumber={1}
                  filledRatioCount={0}
                  isCompany={true}
                  key={index}
                  position={row.orders}
                  serviceOrderNumber={row.orderNumber}
                  serviceOrderId={row.id}
                  status={row.companyStatus}
                  style={{marginRight: '20px'}}
                />
              )
            })}
          </ContainerBox>
          <SeeAllOrdersBox>
            <NavLink
              style={{fontSize: '15px'}}
              data-testid="to-manage-orders"
              to={{
                pathname: `${BASE_ROUTES.COMPANY_ADMIN}${SIDEBAR_ROUTES.SERVICE_ORDERS}`,
              }}>
              See All Orders
            </NavLink>
          </SeeAllOrdersBox>
        </LoadingBoundary>
      </ErrorBoundary>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
          Success! The Company {locationState?.state.name} has been Edited!
        </Alert>
      </Snackbar>
    </DashboardContainer>
  )
}
