import {format} from 'date-fns'
import React from 'react'
import type {Education} from '~/src/models/applicant'
import {
  DashboardMainStylesMaxWidth,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string): void
  values: Education[]
}

export default function MspCandidateProfileEducationDisplay({handleClick, values}: IProps) {
  return (
    <>
      <DashboardMainStylesMaxWidth>
        <InformationCardContainer>
          <InformationCardHeaderContainer>
            <InformationCardHeaderPadding>
              <InformationCardHeaderTextTitle>Education</InformationCardHeaderTextTitle>
              <InformationCardHeaderTextEdit onClick={() => handleClick('edit')}>
                Edit
              </InformationCardHeaderTextEdit>
            </InformationCardHeaderPadding>
          </InformationCardHeaderContainer>
          <InformationCardDetailsContainer>
            {values.map((edu: Education, index) => {
              return (
                <InformationCardExperianceBorder index={index === values.length - 1} key={index}>
                  <InformationCardHeaderTextTitle>{edu.degreeType}</InformationCardHeaderTextTitle>
                  <div style={{display: 'flex'}}>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '20px', marginTop: 5}}>
                      {edu.nameOfSchool}
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText
                      style={{marginRight: '20px', marginTop: 5}}>
                      {edu?.city?.length > 0
                        ? `${edu.city}, ${edu.state}`
                        : `${edu?.address?.city}, ${edu?.address?.state}`}
                    </InformationCardProfileExperienceJobText>
                    <InformationCardProfileExperienceJobText style={{marginTop: 5}}>
                      Graduation Date:
                      {edu?.graduationDate ? (
                        <span style={{marginLeft: 5}}>
                          {format(new Date(edu.graduationDate), 'MMMM yyyy')}
                        </span>
                      ) : undefined}
                    </InformationCardProfileExperienceJobText>
                  </div>
                  <InformationCardProfileHeaderText />
                </InformationCardExperianceBorder>
              )
            })}
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStylesMaxWidth>
    </>
  )
}
