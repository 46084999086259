import {push} from 'connected-react-router'
import React, {useCallback, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {updateCurrentUser} from '~/src/store/slices/root-slice'
import PrivateRoute from './components/common/PrivateRoute'
import {Loader} from './components/ui/loader'
import CompanyAdminPage from './pages/CompanyAdmin'
import AgencyAdminPage from './pages/MspAgencyAdmin'
import AgencyPage from './pages/NonMspAgencyAdmin'
import PlatformAdminPage from './pages/PlatformAdmin'
import RegistrationPage from './pages/Registration'
import RoleSelectPage from './pages/RoleSelect'
import PageNotFound, {NotAuthorized} from './pages/sign-in/404'
import CreateAccountPortal from './pages/sign-in/CreateAccountPortal'
import CreateAgency from './pages/sign-in/CreateAgency'
import CreateCompany from './pages/sign-in/CreateCompany'
import PasswordConfirmation from './pages/sign-in/PasswordConfirmation'
import PasswordResetPage from './pages/sign-in/PasswordReset'
import RegistrationSuccess from './pages/sign-in/RegistrationSuccess'
import SigninPage from './pages/sign-in/Signin'
import {BASE_ROUTES} from './routes/constants'
import {useLazyGetUserByEmailQuery} from './store/apis/user-api'
import {usePartialUser} from './store/slice-hooks'
import {redirect} from './store/slices/root-slice'
import {useAppDispatch, useAppSelector} from './store/store-hooks'
import {getCognitoUserInfo} from './store/thunks/auth-thunks'
import {isNullOrUndefined} from './utils/type-validators'

export default function App(): JSX.Element {
  const dispatch = useAppDispatch()
  const {redirectTo, appIsLoaded} = useAppSelector((state) => state.root)
  const [getUserByEmail] = useLazyGetUserByEmailQuery()
  const {partialUser} = usePartialUser()

  const fetchUserDetails = useCallback(
    async (_partialUser) => {
      const user = await getUserByEmail(_partialUser.emailAddress).unwrap()
      dispatch(updateCurrentUser(user))
    },
    [dispatch, getUserByEmail],
  )

  useEffect(() => {
    if (!isNullOrUndefined(partialUser)) {
      fetchUserDetails(partialUser).finally(() => {})
    }
  }, [fetchUserDetails, partialUser])

  useEffect(() => {
    // Find out why this is happening and type correctly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(getCognitoUserInfo() as any)
  }, [dispatch])

  useEffect(() => {
    if (!isNullOrUndefined(redirectTo)) {
      dispatch(push(redirectTo))
      dispatch(redirect())
    }
  }, [redirectTo, dispatch])

  if (!appIsLoaded) return <Loader page="mini" size="large" />

  return (
    <Switch>
      <Route path={BASE_ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
      <Route path={BASE_ROUTES.PASSWORD_CONFIRMATION} component={PasswordConfirmation} />
      <Route path={BASE_ROUTES.REGISTRATION} component={RegistrationPage} />
      <Route path={BASE_ROUTES.CREATE_ACCOUNT_PORTAL} component={CreateAccountPortal} />
      <Route exact path={BASE_ROUTES.REGISTRATION_SUCCESS} component={RegistrationSuccess} />
      <Route path={BASE_ROUTES.CREATE_COMPANY} component={CreateCompany} />
      <Route path={BASE_ROUTES.CREATE_AGENCY} component={CreateAgency} />
      <Route path={BASE_ROUTES.SIGNIN} component={SigninPage} />
      <PrivateRoute path={BASE_ROUTES.PLATFORM_ADMIN} component={PlatformAdminPage} />
      <PrivateRoute path={BASE_ROUTES.AGENCY} component={AgencyPage} />
      <PrivateRoute path={BASE_ROUTES.AGENCY_ADMIN} component={AgencyAdminPage} />
      <PrivateRoute path={BASE_ROUTES.COMPANY_ADMIN} component={CompanyAdminPage} />
      <PrivateRoute path={BASE_ROUTES.ROLE_SELECT} component={RoleSelectPage} />
      {/* These non matching routes need to be at the bottom */}
      <Redirect exact to={BASE_ROUTES.SIGNIN} from={BASE_ROUTES.ROOT} />
      <Route path={BASE_ROUTES.NOT_AUTHORIZED} component={NotAuthorized} />
      <Route path={BASE_ROUTES.NO_MATCH} component={PageNotFound} />
    </Switch>
  )
}
