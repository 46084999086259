import React from 'react'
import type {RouteChildrenProps} from 'react-router-dom'

export function NotAuthorized<T>(props: RouteChildrenProps<T, {message: string}>): JSX.Element {
  return <h3>{props.location.state.message || 'Not Authorized'}</h3>
}

export default function PageNotFound(): JSX.Element {
  return <h3>Page Not Found</h3>
}
