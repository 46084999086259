import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import type {Theme} from '@mui/material/styles'
import {useTheme} from '@mui/material/styles'
import {FormikSelect, FormikTextField, MenuItem} from '@ui/components'
import {errorFunction} from '@utils/errorFunction'
import {Form, Formik} from 'formik'
import React, {useCallback, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {displayToCognito} from '~/src/models'
import {
  useAddAgencyAdminMutation,
  useAddCompanyAdminMutation,
  useAddPlatformAdminMutation,
  useLazyGetUserByEmailQuery,
} from '~/src/store/apis/user-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppSelector} from '~/src/store/store-hooks'
import ReusableSnackbar from '../../Snackbar/ReusableSnackbar'
import {
  AbsoluteAdornment,
  Adornment,
  Blue,
  Body,
  Button,
  Footer,
  Header,
  IconButton,
  Title,
  useStyles,
} from './styles/MemberDialog.styles'

interface IProps {
  onModalClose(): void
  modalOpen: boolean
}

export default function AddMemberDialog({modalOpen, onModalClose}: IProps): React.ReactElement {
  const theme = useTheme()
  const location = useLocation()
  const classes = useStyles()
  const {currentUser} = useCurrentUser()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  //   msp and agency
  const [addAgencyAdmin] = useAddAgencyAdminMutation()
  //   company
  const [addCompanyAdmin] = useAddCompanyAdminMutation()
  //   platadmin
  const [addPlatformAdmin] = useAddPlatformAdminMutation()

  const isPlatAdmin = location.pathname.includes('platform-admin')
  const isCompany = location.pathname.includes('company-admin')

  const initialFormValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    role: '',
  }

  const [findEmail] = useLazyGetUserByEmailQuery()

  const validateEmail = useCallback(
    async function (value: string | undefined, resolve: (value: boolean) => void) {
      try {
        const alreadyExists = await findEmail(value ?? '').unwrap()
        resolve(!alreadyExists)
      } catch (e: unknown) {
        resolve(true)
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  function handleModalClose() {
    onModalClose()
  }

  const handleSubmit = async (values: {
    firstName: string
    lastName: string
    emailAddress: string
    role: unknown
  }) => {
    if (isPlatAdmin) {
      const member = {
        ...values,
        roles: [displayToCognito(values.role)],
      }

      await addPlatformAdmin(member)
        .unwrap()
        .then(() => handleModalClose())
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    } else if (isCompany) {
      const member = {
        ...values,
        companyId: currentUser.companyId,

        roles: [displayToCognito(values.role)],
      }

      await addCompanyAdmin(member)
        .unwrap()
        .then(() => handleModalClose())
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    } else {
      // covers both msp agency and agency and GAs
      const member = {
        ...values,
        roles: ['agencyadmin'],
        agencyId: loggedInAgencyId,
      }

      await addAgencyAdmin(member)
        .unwrap()
        .then(() => handleModalClose())
        .catch((err) => errorFunction(err, setIsSnackbarOpen))
    }
  }

  const displaySwitchValue = isPlatAdmin ? 'Platform' : isCompany ? 'Company' : ''

  const displaySwitch = (value: string) => {
    switch (value) {
      // plat admin
      case 'Platform':
        return ['Platform Administrator'].map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))
      // comapny
      case 'Company':
        return ['Company Administrator'].map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))
      // msp and agency and GAs
      default:
        return ['Agency Administrator'].map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))
    }
  }

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={handleModalClose}
      PaperProps={{sx: {minWidth: (theme: Theme) => theme.breakpoints.values.sm, maxWidth: 546}}}>
      <ReusableSnackbar isOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
      <Formik
        initialValues={initialFormValues}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('Required')
            .max(50, 'First Name must be less than 50 characters'),
          lastName: Yup.string()
            .required('Required')
            .max(50, 'Last Name must be less than 50 characters'),
          emailAddress: Yup.string()
            .email('Email address is invalid.')
            .test(
              'already-exists',
              'This email is already in use',
              (value) => new Promise((resolve) => debouncedValidateEmail(value, resolve)),
            )
            .required('Required'),
          role: Yup.string().required('Required'),
        })}
        onSubmit={handleSubmit}>
        {({isSubmitting}) => (
          <Form>
            <Header>
              <Title>
                <Blue>Add</Blue> User
              </Title>
              <IconButton onClick={handleModalClose}>
                <CloseIcon style={{color: theme.palette.grey[900]}} />
              </IconButton>
            </Header>

            <Body>
              <div>
                <b>Add</b> new member to JobSource.
              </div>
              <FormikTextField
                name="firstName"
                placeholder="First Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="lastName"
                placeholder="Last Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="emailAddress"
                placeholder="danyl.nava@example.com"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="email" />}
              />

              <FormikSelect
                name="role"
                placeholder="Role"
                inputProps={{className: classes.selectTextField}}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value?.length ? (
                      Array.isArray(value) ? (
                        value.join(', ')
                      ) : (
                        value
                      )
                    ) : (
                      <span style={{color: 'rgba(0, 0, 0, 0.23)'}}>Role</span>
                    ),
                }}
                InputProps={{
                  style: {paddingLeft: 0},
                  startAdornment: <AbsoluteAdornment icon="pen" />,
                }}>
                {displaySwitch(displaySwitchValue)}
              </FormikSelect>
            </Body>

            <Footer>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={handleModalClose}>
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                sx={{width: 'auto'}}>
                Add Member
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
