import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import {styled, useTheme} from '@mui/material/styles'
import React from 'react'

const CustomizedButton = styled(Button)`
  &.MuiButton-root {
    font-size: '15px';
    color: #333333;
    font-weight: 500;
  }
` as typeof Button

interface IProps {
  onClick?(): void
  label?: string
  children?: React.ReactNode
}

export default function AddButton({onClick, children, label, ...rest}: IProps) {
  const theme = useTheme()
  return (
    <CustomizedButton
      variant="text"
      startIcon={
        <AddCircleIcon
          style={{color: theme.palette.primary.main, position: 'relative', left: '4px'}}
        />
      }
      onClick={onClick}
      {...rest}>
      {label}
      {children}
    </CustomizedButton>
  )
}
