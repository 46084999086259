import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function TasksIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M2.50008 3.2H16.6667C16.7385 3.2 16.8001 3.2616 16.8001 3.33333V8.33333C16.8001 8.40507 16.7385 8.46667 16.6667 8.46667H2.50008C2.42835 8.46667 2.36675 8.40507 2.36675 8.33333V3.33333C2.36675 3.2616 2.42835 3.2 2.50008 3.2ZM2.50008 11.5333H16.6667C16.7385 11.5333 16.8001 11.5949 16.8001 11.6667V16.6667C16.8001 16.7384 16.7385 16.8 16.6667 16.8H2.50008C2.42835 16.8 2.36675 16.7384 2.36675 16.6667V11.6667C2.36675 11.5949 2.42835 11.5333 2.50008 11.5333Z'
				stroke='currentColor'
				strokeWidth='1.4'
			/>
		</Svg>
	);
}
