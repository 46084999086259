export const errorText = {
  confirmPasswordRequired: `Please confirm your password.`,
  emailInvalid: `Please enter a valid email address.`,
  emailRequired: `Please enter an email address.`,
  mustAcceptTerms: `You must accept the terms and conditions.`,
  mustContainLowercase: `Must contain at least one lowercase letter.`,
  mustContainNumber: `Must contain at least one number.`,
  mustContainSpecialCharacter: `Must contain at least one special character.`,
  mustContainUppercase: `Must contain at least one uppercase letter.`,
  passwordRequired: `Please enter your password.`,
  passwordsDontMatch: `Both passwords must match.`,
  passwordTooShort: `Password is too short.`,
  serverError: `An unknown error occurred processing your request.`,
  tooSimilarToOldPassword: `Your password is too similar to your other information.`,
  weakPassword: `Your password is too weak.`,
} as const
