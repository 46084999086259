import React from 'react'
import type {Shift} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  FlexSpaceBetweenContainer,
  ShiftText,
} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  shift: Shift | undefined
}
export default function AvailabilityShift({shift}: IProps) {
  return (
    <>
      <DashboardMainStyles>
        <FlexSpaceBetweenContainer style={{width: '405px'}}>
          {shift?.shiftAvailability.map((s: boolean, index2) => {
            if (s) {
              return <ShiftText key={index2}>Shift {shift?.shift}</ShiftText>
            } else {
              return <div key={index2} style={{marginBottom: 20, width: '42px'}} />
            }
          })}
        </FlexSpaceBetweenContainer>
      </DashboardMainStyles>
    </>
  )
}
