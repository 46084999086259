import FilterIcon from '@ui/icons/Filter'
import React from 'react'
import type {Activity, Applicant} from '~/src/models/applicant'
import {
  ActivityCardHeaderPadding,
  DashboardMainStyles,
  FilterIconStyle,
  InformationCardHeaderTextTitle,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import AddNoteContainer from './AddNoteContainer'

interface IProps {
  newActivity: Activity
  resetForm(obj: Applicant): void
  values: Applicant
  oldApplicant: Applicant
}

const MspCandidateActivityNotification = ({
  newActivity,
  resetForm,
  values,
  oldApplicant,
}: IProps) => {
  return (
    <>
      <DashboardMainStyles>
        <AddNoteContainer
          resetForm={resetForm}
          newActivity={newActivity}
          values={values}
          oldApplicant={oldApplicant}
        />

        <ActivityCardHeaderPadding>
          <InformationCardHeaderTextTitle>Activity History</InformationCardHeaderTextTitle>
          <FilterIconStyle>
            <FilterIcon />
          </FilterIconStyle>
        </ActivityCardHeaderPadding>
      </DashboardMainStyles>
    </>
  )
}

export default React.memo(MspCandidateActivityNotification)
