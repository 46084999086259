import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {IconButton, Menu, MenuItem, Tooltip, Typography} from '@mui/material'
import React, {useState} from 'react'
import styled, {css} from 'styled-components'

const StyledMenuItem = styled(MenuItem)(
  ({theme}) => css`
    width: 100%;
    &:hover .itemText {
      color: ${theme.palette.primary.main};
    }
  `,
)

const StyledText = styled(Typography)<{$selected: boolean}>(
  ({theme, $selected}) => css`
    left: 9.62%;
    right: 17.79%;
    top: 20.27%;
    bottom: 71.17%;
    font-style: normal;
    :400 ;
    font-weight: ${$selected ? 600 : 400};
    font-size: 14px;
    line-height: 19px;
    color: ${$selected ? theme.palette.primary.main : '#383838'};
  `,
)

const StyledTitleText = styled(Typography)`
  font-weight: 700;
  font-size: 15px;
  color: #03a9fc;
`
const StyledLightText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8a8a8a;
`

interface IUserMenuProps {
  handleClick?: (selection: 'currentAgency' | 'allAgencies') => void
  sx?: object
}

const SelectCandidatesMenu = ({handleClick = () => {}, sx}: IUserMenuProps) => {
  const [title, setTitle] = useState<'Current Agency' | 'All Agencies'>('Current Agency')
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  return (
    <div style={{...sx}}>
      <Tooltip title="Display Candidates">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <StyledTitleText>{title}</StyledTitleText>
          <ArrowDropDownIcon sx={{color: '#8A8A8A'}} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '35px'}}
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchor)}
        onClose={() => {
          setAnchor(null)
        }}>
        <MenuItem className="itemText">
          <StyledLightText>Display Candidates from</StyledLightText>
        </MenuItem>
        <StyledMenuItem
          onClick={() => {
            setTitle('Current Agency')
            void handleClick('currentAgency')
            setAnchor(null)
          }}>
          <StyledText textAlign="center" className="itemText" $selected={false}>
            Current Agency
          </StyledText>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            setTitle('All Agencies')
            void handleClick('allAgencies')
            setAnchor(null)
          }}>
          <StyledText textAlign="center" className="itemText" $selected={false}>
            All Agencies
          </StyledText>
        </StyledMenuItem>
      </Menu>
    </div>
  )
}

export default SelectCandidatesMenu
