import type {AutocompleteProps} from '@mui/material'
import MuiIcon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'
import Icon from '@ui/icons'
import React from 'react'
import StyledAutocomplete from './StyledAutocomplete'

export interface IStandardAutocompleteProps
  extends Omit<AutocompleteProps<string, false, true, true>, 'renderInput'> {
  name?: string
  popupIconId?: string
  onTextFieldChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  defaultValue?: string
  textValueOverride?: string
}

const DEFAULT_POPUP_ICON_ID = 'arrowhead'

export default function StandardAutocomplete({
  name,
  popupIconId = DEFAULT_POPUP_ICON_ID,
  placeholder,
  fullWidth = true,
  onTextFieldChange,
  error,
  helperText,
  defaultValue,
  textValueOverride,
  sx,
  ...props
}: IStandardAutocompleteProps) {
  const iconFlipLogic: object =
    popupIconId === DEFAULT_POPUP_ICON_ID
      ? {}
      : {'& .MuiAutocomplete-popupIndicator': {transform: 'none'}}
  return (
    <StyledAutocomplete
      sx={{...iconFlipLogic, ...sx}}
      isOptionEqualToValue={(option, value) => option === value}
      clearIcon={
        <MuiIcon>
          <Icon icon="close" />
        </MuiIcon>
      }
      popupIcon={
        <MuiIcon>
          <Icon icon={popupIconId} />
        </MuiIcon>
      }
      fullWidth={fullWidth}
      value={defaultValue}
      forcePopupIcon
      renderInput={(params) => {
        const valObj: {value?: string} = {}
        if (textValueOverride) {
          valObj.value = textValueOverride
        }

        return (
          <TextField
            {...params}
            {...valObj}
            error={error}
            fullWidth={fullWidth}
            name={name}
            placeholder={placeholder}
            onChange={onTextFieldChange}
            helperText={helperText}
          />
        )
      }}
      {...props}
    />
  )
}
