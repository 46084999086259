import Button from '@ui/button'
import IconButton from '@ui/button-icon'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {SyntheticEvent} from 'react'
import type {ICompanyBase} from '~/src/models'
import {useDeleteAgencyMutation, useUpdateAgencyStatusMutation} from '~/src/store/apis/agency-api'
import {
  useDeleteCompanyMutation,
  useUpdateCompanyStatusMutation,
} from '~/src/store/apis/company-api'
import type {TCompanyStatus} from './CompanyMain'
import S from './styles/update-status-modal.styles'

function HeaderText({status, isAgency}: {status: TCompanyStatus; isAgency: boolean}) {
  if (isAgency) {
    switch (status) {
      case 'Active':
        return (
          <h5>
            Set Agency as <span>Active</span>?
          </h5>
        )
      case 'Rejected':
        return (
          <h5>
            Set Agency as <span>Rejected</span>?
          </h5>
        )
      case 'Disabled':
        return (
          <h5>
            <span>Disable</span> Agency?
          </h5>
        )

      default:
        throw Error(`Unsupported status: ${status}`)
    }
  } else {
    switch (status) {
      case 'Active':
        return (
          <h5>
            Set Company as <span>Active</span>?
          </h5>
        )
      case 'Rejected':
        return (
          <h5>
            Set Company as <span>Rejected</span>?
          </h5>
        )
      case 'Disabled':
        return (
          <h5>
            <span>Disable</span> Company?
          </h5>
        )

      default:
        throw Error(`Unsupported status: ${status}`)
    }
  }
}

function BodyText({status, isAgency}: {status: TCompanyStatus; isAgency: boolean}) {
  const CompanyOrAgency = isAgency ? 'Agency' : 'Company'
  switch (status) {
    case 'Active':
      return (
        <S.SmallText>
          Are you sure you want to change the status to <b>Active</b>?
        </S.SmallText>
      )
    case 'Rejected':
      return (
        <S.SmallText>
          Are you sure you want to change the status to <b>Rejected</b>?
        </S.SmallText>
      )
    case 'Disabled':
      return (
        <S.SmallText>
          Are you sure you want to <b>Disable</b> this {CompanyOrAgency}?
        </S.SmallText>
      )

    default:
      throw Error(`Unsupported status: ${status}`)
  }
}

interface IProps {
  selectedStatus: TCompanyStatus
  targetCompany: ICompanyBase
  onModalClose(): void
  isAgency: boolean
}

const colors = ['orange', 'primary', 'red', 'red']

export default function UpdateCompanyStatusModal({
  onModalClose,
  selectedStatus,
  targetCompany,
  isAgency,
  ...props
}: IProps) {
  const [updateStatus, updateResult] = useUpdateCompanyStatusMutation()
  const [updateAgencyStatus, updateAgencyResult] = useUpdateAgencyStatusMutation()
  const [deleteCompany, deleteResult] = useDeleteCompanyMutation()
  const [deleteAgency, deleteAgencyResult] = useDeleteAgencyMutation()
  const confirm =
    (id: string, status: TCompanyStatus, isAgency: boolean) => async (e: SyntheticEvent) => {
      e.preventDefault()
      if (isAgency) {
        if (status === -1) {
          await deleteAgency(id).unwrap()
        } else {
          await updateAgencyStatus({id, status}).unwrap()
        }
      } else if (status === -1) {
        await deleteCompany(id).unwrap()
      } else {
        await updateStatus({id, status}).unwrap()
      }
      onModalClose()
    }
  return (
    <S.Container {...props} data-testid="manage-companies-modal">
      <S.Overlay />
      <S.Inner>
        <S.Header variant={colors[selectedStatus] ?? 'red'}>
          <HeaderText status={selectedStatus} isAgency={isAgency} />
          <IconButton
            data-testid="close-button"
            icon="close"
            variant="secondary"
            textColor="light"
            onClick={onModalClose}
          />
        </S.Header>

        <ErrorBoundary
          error={
            deleteResult.error ??
            updateResult.error ??
            updateAgencyResult.error ??
            deleteAgencyResult.error
          }>
          <S.Body onSubmit={confirm(targetCompany.id, selectedStatus, isAgency)}>
            <BodyText status={selectedStatus} isAgency={isAgency} />
            <S.FieldGroup>
              <S.Field>
                <S.Icon icon="person" />
                <S.Input placeholder="Company Name" readOnly defaultValue={targetCompany.name} />
              </S.Field>
              <S.Field>
                <S.Icon icon="email" />
                <S.Input
                  placeholder="Email Address"
                  readOnly
                  defaultValue={targetCompany.adminEmail}
                />
              </S.Field>
              <S.Field>
                <S.Icon icon="phone" />
                <S.Input
                  placeholder="Phone Number"
                  readOnly
                  defaultValue={targetCompany.phoneNumber}
                />
              </S.Field>
            </S.FieldGroup>

            <S.ButtonGroup>
              <LoadingBoundary
                isLoading={deleteResult.isLoading || updateResult.isLoading}
                size="small">
                <Button
                  type="submit"
                  data-testid="confirm-button"
                  variant="secondary-clear"
                  display={`Yes I'm Sure`}
                />
                <Button
                  data-testid="cancel-button"
                  variant="secondary-clear"
                  display="Cancel"
                  type="button"
                  onClick={onModalClose}
                />
              </LoadingBoundary>
            </S.ButtonGroup>
          </S.Body>
        </ErrorBoundary>
      </S.Inner>
    </S.Container>
  )
}
