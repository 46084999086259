import {createAsyncThunk} from '@reduxjs/toolkit'
import {getCurrentSession} from '../cognito'
/**
 * Ideally we'd have all our API stuff in API slices. But Cognito is
 * easier to deal with using thunks. And since they don't use our backend
 * URL, it makes more sense to have them separated from our other API reducers
 */
export const getCognitoUserInfo = createAsyncThunk(
  'authThunk/getCognitoUserInfo',
  async (_, {rejectWithValue}) => {
    const session = await getCurrentSession()

    if (session) {
      const {payload} = session.getIdToken()
      const token = session.getIdToken().getJwtToken()
     

      const data = {
        token: token,
        emailAddress: payload.email,
        roles: payload['cognito:groups'],
        companyId: payload['custom:CompanyId'],
        isPlatform: /true/i.test(payload['custom:IsPlatform']),
      }

      return data
    } else {
      return rejectWithValue({error: 'NO_SESSION_FOUND'})
    }
  },
)
