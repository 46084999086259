import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function PeopleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M16.625 2.50195C15.268 2.50195 14.1641 3.60593 14.1641 4.96289H11.7031H8.62695C8.62695 3.60593 7.52298 2.50195 6.16602 2.50195C4.80905 2.50195 3.70508 3.60593 3.70508 4.96289C3.70508 6.31985 4.80905 7.42383 6.16602 7.42383C7.07488 7.42383 7.86968 6.9282 8.29587 6.19336H14.4951C14.9213 6.9282 15.7161 7.42383 16.625 7.42383C17.982 7.42383 19.0859 6.31985 19.0859 4.96289C19.0859 3.60593 17.982 2.50195 16.625 2.50195ZM6.16602 6.19336C5.48754 6.19336 4.93555 5.64137 4.93555 4.96289C4.93555 4.28441 5.48754 3.73242 6.16602 3.73242C6.8445 3.73242 7.39648 4.28441 7.39648 4.96289C7.39648 5.64137 6.8445 6.19336 6.16602 6.19336ZM16.625 6.19336C15.9465 6.19336 15.3945 5.64137 15.3945 4.96289C15.3945 4.28441 15.9465 3.73242 16.625 3.73242C17.3035 3.73242 17.8555 4.28441 17.8555 4.96289C17.8555 5.64137 17.3035 6.19336 16.625 6.19336Z'
				fill='currentColor'
			/>
			<path
				d='M11.7031 0C10.3349 0 9.24219 1.14868 9.24219 2.50195C9.24219 3.85891 10.3462 4.96289 11.7031 4.96289C13.0601 4.96289 14.1641 3.85891 14.1641 2.50195C14.1641 1.14762 13.0699 0 11.7031 0ZM11.7031 3.73242C11.0246 3.73242 10.4727 3.18043 10.4727 2.50195C10.4727 1.81273 11.0361 1.23047 11.7031 1.23047C12.3701 1.23047 12.9336 1.81273 12.9336 2.50195C12.9336 3.18043 12.3816 3.73242 11.7031 3.73242Z'
				fill='currentColor'
			/>
			<path
				d='M17.8828 7.42383H15.4219H14.1914C13.1737 7.42383 12.3457 8.25181 12.3457 9.26953C12.3457 9.78202 12.3457 19.4121 12.3457 19.7695H11.1152V14.1914C11.1152 13.8516 10.8398 13.5762 10.5 13.5762C10.1602 13.5762 9.88477 13.8516 9.88477 14.1914V19.7695H8.6543C8.6543 19.3918 8.6543 9.77152 8.6543 9.26953C8.6543 8.25181 7.82631 7.42383 6.80859 7.42383H4.96289H3.11719C1.41652 7.42383 0 8.79929 0 10.5V12.9609C0 13.9812 0.865307 14.8066 1.88672 14.8066C2.10234 14.8066 2.30947 14.7695 2.50195 14.7012V20.3848C2.50195 20.7245 2.77741 21 3.11719 21C3.40298 21 17.6158 21 17.8828 21C18.2226 21 18.498 20.7245 18.498 20.3848V14.7012C18.6905 14.7695 18.8977 14.8066 19.1133 14.8066C20.1332 14.8066 21 13.9824 21 12.9609V10.5C21 8.79933 19.5835 7.42383 17.8828 7.42383ZM7.42383 19.7695H6.19336V15.4219C6.19336 15.0821 5.9179 14.8066 5.57812 14.8066C5.23835 14.8066 4.96289 15.0821 4.96289 15.4219V19.7695H3.73242V12.9609V10.5C3.73242 10.1602 3.45696 9.88477 3.11719 9.88477C2.77741 9.88477 2.50195 10.1602 2.50195 10.5V12.9609C2.50195 13.3002 2.22596 13.5762 1.88672 13.5762C1.53714 13.5762 1.23047 13.2887 1.23047 12.9609V10.5C1.23047 9.49951 2.09446 8.6543 3.11719 8.6543H6.80859C7.14783 8.6543 7.42383 8.93029 7.42383 9.26953C7.42383 9.78202 7.42383 19.4121 7.42383 19.7695ZM19.7695 12.9609C19.7695 13.2887 19.4629 13.5762 19.1133 13.5762C18.774 13.5762 18.498 13.3002 18.498 12.9609V10.5C18.498 10.1602 18.2226 9.88477 17.8828 9.88477C17.543 9.88477 17.2676 10.1602 17.2676 10.5V12.9609V19.7695H16.0371V15.4219C16.0371 15.0821 15.7616 14.8066 15.4219 14.8066C15.0821 14.8066 14.8066 15.0821 14.8066 15.4219V19.7695H13.5762C13.5762 19.3918 13.5762 9.77152 13.5762 9.26953C13.5762 8.93029 13.8522 8.6543 14.1914 8.6543H15.4219H17.8828C18.9055 8.6543 19.7695 9.49951 19.7695 10.5V12.9609Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
