import {SIDEBAR_ROUTES} from '~/src/routes'

//TODO - Review/Modify this for only whats needed in top nav and possibly sub menu.
export interface INavMenuItem {
  id: number
  link?: string
  text: string
  subMenuItems?: INavMenuItem[]
}

export const platformAdminList: INavMenuItem[] = [
  {
    id: 1,
    link: `/platform-admin${SIDEBAR_ROUTES.DASHBOARD}`,
    text: 'Dashboard',
  },
  {
    id: 2,
    link: `/platform-admin${SIDEBAR_ROUTES.MANAGE_COMPANIES}`,
    text: 'Manage Companies',
  },
  {
    id: 3,
    link: `/platform-admin${SIDEBAR_ROUTES.MANAGE_AGENCIES}`,
    text: 'Manage Agencies',
  },
  {
    id: 4,
    text: 'Users & Roles',
    subMenuItems: [
      {
        id: 10,
        link: `/platform-admin${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_USERS}`,
        text: 'General Users',
      },
      {
        id: 11,
        link: `/platform-admin${SIDEBAR_ROUTES.USERS_AND_ROLES}${SIDEBAR_ROUTES.GENERAL_ADMINS}`,
        text: 'General Admins',
      },
    ]
  },
  // {
  //   id: 5,
  //   link: `/platform-admin${SIDEBAR_ROUTES.IMPORT}`,
  //   text: 'Import Candidates',
  // },
]

export const agencyAdminList: INavMenuItem[] = [
  {
    id: 1,
    link: `/agency${SIDEBAR_ROUTES.DASHBOARD}`,
    text: 'Dashboard',
  },
  {
    id: 2,
    text: 'Manage Orders',
    subMenuItems: [
      {
        id: 10,
        link: `/agency${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`,
        text: 'Pending Orders',
      },
      {
        id: 11,
        link: `/agency${SIDEBAR_ROUTES.SERVICE_ORDERS}`,
        text: 'Current Orders',
      },
      {
        id: 13,
        link: `/agency${SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}`,
        text: 'Completed Orders',
      },
    ],
  },
  {
    id: 3,
    link: `/agency${SIDEBAR_ROUTES.USERS_AND_ROLES}`,
    text: 'Users & Roles',
  },
  {
    id: 4,
    link: `/agency${SIDEBAR_ROUTES.MANAGE_AGENCY}`,
    text: 'Manage Profile',
  },
  {
    id: 5,
    link: `/agency${SIDEBAR_ROUTES.CANDIDATES}`,
    text: 'Candidates',
  },
  // {
  //   id: 6,
  //   link: `/agency${SIDEBAR_ROUTES.IMPORT}`,
  //   text: 'Import Candidates',
  // },
]

export const companyAdminList: INavMenuItem[] = [
  {
    id: 1,
    link: `/company-admin${SIDEBAR_ROUTES.DASHBOARD}`,
    text: 'Dashboard',
  },
  {
    id: 2,
    link: `/company-admin${SIDEBAR_ROUTES.POSITIONS}`,
    text: 'Positions',
  },
  {
    id: 3,
    text: 'Manage Orders',
    subMenuItems: [
      {
        id: 10,
        link: `/company-admin${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`,
        text: 'Manage Templates',
      },
      {
        id: 11,
        link: `/company-admin${SIDEBAR_ROUTES.SERVICE_ORDERS}`,
        text: 'Service Orders',
      },
      {
        id: 12,
        link: `/company-admin${SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}`,
        text: 'Completed Orders',
      },
    ],
  },
  {
    id: 4,
    link: `/company-admin${SIDEBAR_ROUTES.USERS_AND_ROLES}`,
    text: 'Users & Roles',
  },
  {
    id: 5,
    link: `/company-admin${SIDEBAR_ROUTES.MANAGE_COMPANY}`,
    text: 'Manage Company',
  },
]

export const mspAgencyList: INavMenuItem[] = [
  {
    id: 1,
    link: `/agency-admin${SIDEBAR_ROUTES.DASHBOARD}`,
    text: 'Dashboard',
  },
  {
    id: 2,
    text: 'Client Services',
    subMenuItems: [
      {
        id: 10,
        link: `/agency-admin${SIDEBAR_ROUTES.CLIENT_POSITIONS}`,
        text: 'Manage Positions',
      },
      {
        id: 11,
        link: `/agency-admin${SIDEBAR_ROUTES.MANAGE_TEMPLATES}`,
        text: 'Client Templates',
      },
    ],
  },
  {
    id: 3,
    text: 'Manage Orders',
    subMenuItems: [
      {
        id: 10,
        link: `/agency-admin${SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}`,
        text: 'Pending Orders',
      },
      {
        id: 11,
        link: `/agency-admin${SIDEBAR_ROUTES.SERVICE_ORDERS}`,
        text: 'Current Orders',
      },
      {
        id: 13,
        link: `/agency-admin${SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}`,
        text: 'Completed Orders',
      },
    ],
  },
  {
    id: 4,
    link: `/agency-admin${SIDEBAR_ROUTES.USERS_AND_ROLES}`,
    text: 'Users & Roles',
  },
  {
    id: 5,
    link: `/agency-admin${SIDEBAR_ROUTES.MANAGE_MSP_AGENCY}`,
    text: 'Manage Profile',
  },
  {
    id: 6,
    link: `/agency-admin${SIDEBAR_ROUTES.CANDIDATES}`,
    text: 'Candidates',
  },
  // {
  //   id: 7,
  //   link: `/agency-admin${SIDEBAR_ROUTES.IMPORT}`,
  //   text: 'Import Candidates',
  // },
]
