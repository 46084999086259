import {colors} from '../../../config/theme'
import {Icon} from '@ui/components'
import styled from 'styled-components'

export const DashboardMainStyles = styled('div')`
  position: relative;
  flex-basis: 25%;
`
export const CandidateContainerMargin = styled('div')`
  margin-left: 19px;
  margin-top: 14px;
`

export const CompanyDetailsText = styled('div')`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
`

export const JobOrderDetailsText = styled('div')`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 25px;
`

export const HeaderText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
`

export const HeaderCandidateText = styled('div')`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  color: #8a8a8a;
`

export const HeaderLocationText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2;
`

export const DescriptionText = styled('div')`
  font-size: 14px;
  font-weight: 400;
`

export const FlexWithoutPaddingContainer = styled('div')`
  display: flex;
`

export const FlexContainer = styled('div')`
  display: flex;
  margin-bottom: 16px;
`

export const FlexSpaceBetweenContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const HeaderContainer = styled('div')`
  display: flex;
  width: 418px;
  max-width: 418px;
  max-height: 35px;
  height: 35px;
  justify-content: space-between;
  border: 1px solid #707070;
  border-radius: 9px;
  margin-bottom: 16px;
  margin-right: 11px;
`
export const InnerHeaderContainer = styled('div')`
  display: flex;
  width: 418px;
  max-width: 418px;
  max-height: 35px;
  height: 35px;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 8px;
  margin-right: 9px;
  margin-left: 9px;
`

export const DataCleanupContainer = styled('div')`
  display: flex;
  width: 205px;
  max-width: 205px;
  max-height: 35px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 9px;
  justify-content: space-between;
`

export const CloseIconContainer = styled('div')`
  width: 45px;
  height: 300px;
  text-align: center;
  background: #c2cfe0;
  border-radius: 0px 6px 6px 0px;
`

export const EventsCloseIconContainer = styled('div')`
  width: 45px;
  height: 384px;
  text-align: center;
  background: #c2cfe0;
  border-radius: 0px 6px 6px 0px;
`

export const CloseIconVerticalAlignContainer = styled('div')`
  margin-top: 125px;
`

export const InnerDataHeaderContainer = styled('div')`
  width: 205px;
  max-width: 205px;
  max-height: 35px;
  height: 35px;
  margin-top: 7px;
  margin-bottom: 8px;
  margin-right: 9px;
  margin-left: 9px;
`

export const InformationCardContainer = styled('div')`
  width: 780px;
  max-width: 780px;
  min-height: 137px;
  margin-top: 32px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`
export const InformationAvailabilityCardDisplayContainer = styled('div')`
  width: 780px;
  max-width: 780px;
  min-height: 219px;
  margin-top: 32px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`

export const InformationAvailabilityCardEditContainer = styled('div')`
  width: 780px;
  max-width: 780px;
  min-height: 318px;
  margin-top: 32px;
  box-shadow: 0px 4px 9px rgba(0 0 0 / 0.08);
  border-radius: 5px;
`

export const AvailabilityContainer = styled('button')`
  margin-top: 17px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-bottom: 15px;
  height: 28px;
  width: 103px;
  border-radius: 15px;
  background: #03a9fc;
  cursor: pointer;
`

export const ActivityBoxContainer = styled('div')`
  width: 800px;
  max-width: 800px;
  minheight: 137px;
  margin-top: 32px;
`

export const InformationCardHeaderContainer = styled('div')`
  border-bottom: 1px solid #ebeff2;
`

export const InformationCardHeaderPadding = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-left: 15px;
  margin-right: 50px;
`

export const ActivityCardHeaderPadding = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-right: 50px;
`

export const InformationCardHeaderTextTitle = styled('div')`
  margin-top: 18px;
  font-size: 14px;
  font-weight: 600;
`

export const InformationCardHeaderTextEdit = styled('div')`
  margin-top: 18px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`

export const AssignmentHistoryActiveContainer = styled('div')`
  display: flex;
  margin-top: 18px;
  background: ${colors.primary};
  border-radius: 9px;
  width: 56px;
  height: 23px;
  justify-content: center;
  margin-right: 5;
`

export const AssignmentHistoryActiveText = styled('div')`
  font-size: 12px;
  font-weight: 400;
  text-color: white;
  color: white;
  padding: 1;
`

export const FilterIconStyle = styled('div')`
  margin-top: 18px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #8a8a8a;
`

export const ActivityTextEdit = styled('div')`
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`

export const InformationCardProfileHeaderText = styled('div')`
  margin-top: 17px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const InformationCardProfileAvailabilityHeaderText = styled('div')`
  margin-top: 25px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const InformationActivityDateText = styled('span')`
  font-weight: 300;
  font-size: 12px;
  color: #8a8a8a;
`

export const InformationActivityIconAndManagerText = styled('span')`
  font-weight: 400;
  font-size: 13px;
  color: #333333;
`

export const InformationCardSkillEditText = styled('div')`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const SkillText = styled('div')`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
`

export const InformationCardProfileExperienceJobText = styled('div')`
  margin-top: 17px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
`

export const InformationCardProfileDetailsText = styled('div')`
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
`

export const InformationCardProfileAvailabilityStartTimeText = styled('div')`
  margin-top: 17px;
  font-size: 14px;
  font-weight: 600;
`

export const InformationCardDetailsContainer = styled('div')`
  margin-left: 33px;
  margin-right: 33px;
`

export const InformationCardExperianceBorder = styled('div')`
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 15px;
`

export const MarginBottom15Px = styled('div')`
  margin-bottom: 15px;
`

export const InformationMarginBottom = styled('div')`
  margin-bottom: 15px;
`

export const InformationCardDetailsTextTitle = styled('div')`
  font-size: 14px;
  font-weight: 600;
`

export const SkillContainerBox = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 84px;
  height: 46px;
  text-align: center;
  background: #e0e0e0;
  border-radius: 6px;
`

export const AddNewSkillContainerBox = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 152px;
  height: 46px;
  background: #c2cfe0;
  text-align: center;
  border-radius: 6px;
`

export const FlexColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const FlexSpaceEvenlyContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
`

export const AddExperienceContainer = styled('div')`
  width: 194px;
  height: 34px;
  background: #c2cfe0;
  boxshadow: 0px 4px 4px rgba(0; 0; 0; 0.08);
  border-radius: 6px;
  margin-bottom: 15px;
`

export const AddAssignmentContainer = styled('div')`
  width: 154px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 15px;
`

export const AddExperienceText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-evenly;
  margin-top: 7px;
`

export const AddAssignmentText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #b3b3b3;
  justify-content: space-evenly;
  margin-top: 9px;
`

export const AddSkillText = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-evenly;
`

export const AddSkillXIcon = styled('div')`
  font-weight: 400;
  font-size: 12px;
  color: #c4c4c4;
  justify-content: space-evenly;
`

export const AddExperienceCardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-left: 33px;
  margin-right: 33px;
`

export const AddAssignmentCardContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  cursor: pointer;
`

export const Red = styled('span')(({theme}) => ({
  color: colors.primary,
}))

export const DayBubbleContainer = styled('div')<{isAvailable: boolean}>`
  display: flex;
  height: 40px;
  width: 40px;
  background: ${(props) => (props.isAvailable ? '#03A9FC' : '')};
  color: ${(props) => (props.isAvailable ? 'white' : '#858585')};
  border: ${(props) => (props.isAvailable ? '#03A9FC' : '1px solid #858585')};
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
`

export const DayBubbleText = styled('div')`
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  top: 19%;
`
