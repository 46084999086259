import Box from '@mui/material/Box'
import JobOrderCard from '@ui/card/JobOrderCard'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import {StyledPagination} from '@ui/pagination/StyledPagination'
import {push} from 'connected-react-router'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import Title from '~/src/components/layout/Title'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {useGetJobOrdersQuery} from '~/src/store/apis/job-order-api'
import {useAppSelector} from '~/src/store/store-hooks'

const CompanyMainStyles = {
  Container: styled.div`
    position: relative;
    flex: 1;
    margin-left: 15px;
  `,
}

export default function NonMspAgencyManageOrdersMain(): JSX.Element {
  const [currPageIdx, setCurrPageIdx] = useState(1)
  const [jobOrderCurrPageIdx, setJobOrerCurrPageIdx] = useState(1)
  const {loggedInAgencyId} = useAppSelector((state) => state.root)
  const dispatch = useDispatch()

  const {
    data: jobOrderData,
    refetch: jobOrderRefetch,
    error: jobOrderError,
    isLoading: jobOrderIsLoading,
    isFetching: jobOrderIsFetching,
  } = useGetJobOrdersQuery({
    pageNumber: jobOrderCurrPageIdx,
    pageSize: 12,
    filterBy: 'CurrentOrders',
    agencyId: loggedInAgencyId,
  })

  const handleJobOrderPaginationChange = (pageNumber: number) => {
    setJobOrerCurrPageIdx(pageNumber)
  }

  useEffect(() => {
    void jobOrderRefetch()
  }, [currPageIdx, jobOrderRefetch, jobOrderCurrPageIdx])

  const totalJobOrders = jobOrderData?.totalJobOrders

  return (
    <CompanyMainStyles.Container>
      <Title pageTitle="Current Orders" />
      <ErrorBoundary error={jobOrderError}>
        <LoadingBoundary isLoading={jobOrderIsLoading || jobOrderIsFetching}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: 1200,
              borderRadius: 1,
            }}>
            {jobOrderData?.jobOrders.map((jobOrder, index2) => {
              const totalCandidatesNeeded = jobOrder.agencyDistributions
                .map((distribution) => distribution.agencyCandidateDistributions.requested)
                .reduce((prev: number, curr: number) => prev + curr, 0)

              const totalCandidatesFilled = jobOrder.agencyDistributions
                .map((distribution) => distribution.agencyCandidateDistributions.fulfilled)
                .reduce((prev: number, curr: number) => prev + curr, 0)
              return (
                <JobOrderCard
                  currentlyfilled={totalCandidatesFilled}
                  jobOrder={jobOrder}
                  key={index2}
                  onListIconClick={() => {
                    dispatch(
                      push(
                        `${BASE_ROUTES.AGENCY}${
                          SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY
                        }/${encodeURIComponent(jobOrder.id)}?showDetails=false`,
                      ),
                    )
                  }}
                  totalrequested={totalCandidatesNeeded}
                  style={{marginBottom: '20px'}}
                />
              )
            })}
          </Box>
          <StyledPagination
            page={jobOrderCurrPageIdx}
            totalRecords={totalJobOrders}
            recordsPerPage={12}
            onChange={handleJobOrderPaginationChange}
          />
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles.Container>
  )
}
