import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import {Button, Divider} from '@mui/material'
import {css, styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import {push} from 'connected-react-router'
import React from 'react'
import DowntownBg from '~/src/assets/images/downtown-bg.jpeg'
import DowntownPa from '~/src/assets/images/Paris.jpg'
import {BASE_ROUTES} from '../../routes'
import {useAppDispatch} from '../../store/store-hooks'
import Icon from '@ui/icons'

export type TActivePlatform = 'Company' | 'Agency'

const Outer = styled('div')`
  width: 100%;
  height: 100vh;
  flex: 1;
  overflow: hidden;
`
const Background = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownBg}) center 40% no-repeat;
  background-size: 135%;
`

const BackgroundPa = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(rgba(35, 35, 35, 0.64), rgba(35, 35, 35, 0.64)),
    url(${DowntownPa}) center 40% no-repeat;
  background-size: 135%;
`

const Border = styled('div')`
  width: 100%;
  border: 1px solid #cdced9;
  margin: 10px;
`

const MaxWidth = styled('div')(
  ({theme}) => css`
    overflow-y: auto;
    left: 50%;
    max-width: 50%;
    height: 800px;
    padding: 30px 9.866%;
    position: relative;
    top: 50%;

    transform: translate(-50%, -50%);

    ${theme.breakpoints.up('lg')} {
      padding: 0;
    }
  `,
)

const Header = styled('div')(
  ({theme}) => css`
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #cdced9;
    background: #fafafa;
  `,
)
const HeaderText = styled('div')(
  ({theme}) => css`
    font-size: 18px;
    text-align: left;
    margin: 25px;
    font-size: 36px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  `,
)

const FirstBody = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    text-align: center;
    margin-top: 75px;
    margin-bottom: 60px
  
    font-size: 36px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
  `,
)
const FirstBodyText = styled('div')(
  ({theme}) => css`
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
  `,
)

const SecondBody = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
  `,
)

const SecondBodyText = styled('div')(
  ({theme}) => css`
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
  `,
)

const ButtonBody = styled('div')(
  ({theme}) => css`
    margin-top: 325px;
    font-size: 28px;
    text-align: left;
    font-size: 36px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
  `,
)

const Logo = styled(Icon)`
  height: 50px;
  width: 50px;
  color: #03a9fc;
  vertical-align: middle;
  margin-right: 10px;
`

export default function RegistrationSuccess() {
  const dispatch = useAppDispatch()

  return (
    <Outer>
      {process.env.FR ? <BackgroundPa /> : <Background />}
      <MaxWidth>
        <>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #CDCED9',
              borderRadius: '4px',
            }}>
            <Header>
              <HeaderText>Welcome</HeaderText>
            </Header>
            <FirstBody>
              <FirstBodyText>
                <Logo icon="brand-logo" />
                JobSource
              </FirstBodyText>
              <br />
              <FirstBodyText>Welcome to Staffology!</FirstBodyText>
            </FirstBody>

            <SecondBody>
              <SecondBodyText>
                <br />
                <br />
                Your account has been created!
                <br />
                <br />
                Please check your email to complete the registration process.
              </SecondBodyText>
            </SecondBody>

            <ButtonBody>
              <Button
                variant="contained"
                sx={{marginLeft: 5, width: '120px'}}
                onClick={() => dispatch(push(BASE_ROUTES.SIGNIN))}>
                Done
              </Button>
            </ButtonBody>
          </Box>
        </>
      </MaxWidth>
    </Outer>
  )
}
