import {Avatar} from '@mui/material'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {ReactComponent as Warehouse} from '~/src/assets/images/company.svg'
import {ReactComponent as WalkingPerson} from '~/src/assets/images/employee.svg'
import {ReactComponent as BuildingIcon} from '~/src/assets/images/msp.svg'
import {ReactComponent as JobSourceIcon} from '~/src/assets/images/ted.svg'

const DashboardMainStyles = {
  Container: styled.div`
    position: relative;
  `,
}

const IconAndTimlineDiv = styled('div')`
  display: flex;
  align-content: center;
`

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  z-ndex: 2;
`

const CustomAvatar = styled(Avatar)<{reachedstatus: string}>`
  height: 30px;
  width: 30px;
  background-color: ${(props) => (props.reachedstatus === 'true' ? '#03A9FC' : 'grey')};
  z-index: 2;
`

const LinearProgressBox = styled(Box)`
  width: 180px;
  position: absolute;
  margin-left: 28px;
  margin-top: 14px;
`

interface IProps {
  status: string
}

export default function CompanySOTimeline({status}: IProps) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const statusPercentage = (newStatus: string) => {
      switch (newStatus) {
        case 'Submitting Order':
          return setProgress(0)
          break
        case 'Processing Order':
          return setProgress(12.5)
          break
        case 'Confirming Order':
          return setProgress(12.5)
          break
        case 'Reviewing Order':
          return setProgress(12.5)
          break
        case 'Filling Order':
          return setProgress(50)
          break
        case 'Order Filled':
          return setProgress(60)
          break
        case 'Ready To Fill':
          return setProgress(60)
          break
        case 'Filled':
          return setProgress(60)
          break
        // add the 100 status for warehouse in upcoming flow
        default:
          return setProgress(0)
          break
      }
    }
    return statusPercentage(status)
  })

  return (
    <>
      <DashboardMainStyles.Container>
        <Container>
          <IconAndTimlineDiv>
            <CustomAvatar reachedstatus={(progress >= 0).toString()}>
              <JobSourceIcon />
            </CustomAvatar>
            <LinearProgressBox>
              <LinearProgress variant="determinate" value={progress} />
            </LinearProgressBox>
          </IconAndTimlineDiv>
          <div>
            <CustomAvatar reachedstatus={(progress >= 35).toString()}>
              <BuildingIcon sx={{height: '15px', width: '15px'}} />
            </CustomAvatar>
          </div>
          <div>
            <CustomAvatar reachedstatus={(progress >= 60).toString()}>
              <WalkingPerson sx={{height: '15px', width: '15px'}} />
            </CustomAvatar>
          </div>
          <div>
            <CustomAvatar reachedstatus={(progress >= 80).toString()}>
              <Warehouse sx={{height: '15px', width: '15px'}} />
            </CustomAvatar>
          </div>
        </Container>
      </DashboardMainStyles.Container>
    </>
  )
}
