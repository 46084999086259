import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ModuleIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M1.50008 1.2H15.6667C15.7385 1.2 15.8001 1.2616 15.8001 1.33333V6.33333C15.8001 6.40507 15.7385 6.46667 15.6667 6.46667H1.50008C1.42835 6.46667 1.36675 6.40507 1.36675 6.33333V1.33333C1.36675 1.2616 1.42835 1.2 1.50008 1.2ZM1.50008 9.53333H15.6667C15.7385 9.53333 15.8001 9.59493 15.8001 9.66667V14.6667C15.8001 14.7384 15.7385 14.8 15.6667 14.8H1.50008C1.42835 14.8 1.36675 14.7384 1.36675 14.6667V9.66667C1.36675 9.59493 1.42835 9.53333 1.50008 9.53333Z'
				stroke='currentColor'
				strokeWidth='1.4'
			/>
		</Svg>
	);
}
