export const headerArray = [
  {stateValue: 'profile', displayValue: 'Profile'},
  {stateValue: 'skills', displayValue: 'Skills'},
  {stateValue: 'interview', displayValue: 'Interview'},
  {stateValue: 'activity', displayValue: 'Activity'},
  {stateValue: 'events', displayValue: 'Events'},
  {stateValue: 'assignmentHistory', displayValue: 'Assignment History'},
  {stateValue: 'agreements', displayValue: 'Agreements'},
  {stateValue: 'documents', displayValue: 'Documents'},
]
