import React from 'react'
import PageTitle from '~/src/components/common/PageTitle'

interface IProps {
  children?: React.ReactNode
  customHeader?: React.ReactNode
}

export default function ViewWrapper({children, customHeader}: IProps) {
  const defaultHeader = () => <PageTitle />

  return (
    <div>
      {customHeader ? customHeader : defaultHeader()}
      {children}
    </div>
  )
}
