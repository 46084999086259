import {Grid} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import HeaderContainer from '@ui/header-container/HeaderContainer'
import Mui from '@ui/mui'
import FormikSwitchToggle from '@ui/toggle-switch/FormikSwitchToggle'
import {push} from 'connected-react-router'
import {Form as FormikForm, Formik} from 'formik'
import _ from 'lodash'
import React, {useCallback, useMemo} from 'react'
import * as Yup from 'yup'
import 'yup-phone'
import type {IAddress, IAgencyBase} from '~/src/models'
import {BASE_ROUTES, SIDEBAR_ROUTES} from '~/src/routes'
import {
  useCreateAgencyMutation,
  useLazyFindAgencyQuery,
  useUpdateAgencyMutation,
} from '~/src/store/apis/agency-api'
import {useLazyGetUserByEmailQuery} from '~/src/store/apis/user-api'
import {useAppDispatch} from '~/src/store/store-hooks'
import FormikTextField from '../../ui/mui-text-field/FormikTextField'
import type {TActivePlatform} from './AddCompanyForm'
import CompanyAddressListField from './CompanyAddressListField'

const RightColumn = styled('div')({
  textAlign: 'left',
  justifyContent: 'left',
})

const ImageBox = styled(Box)({
  width: '200px',
  height: '50px',
  maxWidth: '200px',
  maxHeight: '50px',
  backgroundColor: 'rgba(137, 196, 244, 0.5)',
  border: '1px, solid, lightBlues',
  marginBottom: 20,
})

const CompanyLogo = styled('div')({
  marginBottom: 10,
  textAlign: 'left',
  paddingLeft: '80px',
})

const ImageSubText = styled('div')({
  marginBottom: 20,
  textAlign: 'left',
  color: 'grey',
})

const Button = styled(Mui.Button)({
  width: 120,
  fontWeight: 'normal',
})

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const Form = styled(FormikForm)({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '7fr 3fr',
})

export interface IFormValues {
  name: string
  phoneNumber: string
  website: string | null
  addresses: IAddress[]
  adminFirstName: string
  adminLastName: string
  adminEmail: string
  isMsp: boolean
}

interface IProps {
  activePlatform: TActivePlatform
  initialEditValues: IAgencyBase
}

export default function AddAgencyForm({activePlatform, initialEditValues}: IProps) {
  const dispatch = useAppDispatch<any>()
  const [findAgency] = useLazyFindAgencyQuery()
  const [addAgency, {error}] = useCreateAgencyMutation()
  const [updateAgency] = useUpdateAgencyMutation()
  const [findEmail] = useLazyGetUserByEmailQuery()

  const initialValues: IFormValues = initialEditValues
    ? {
        versionKey: initialEditValues.versionKey,
        id: initialEditValues.id,
        name: initialEditValues.name,
        phoneNumber: initialEditValues.phoneNumber,
        website: initialEditValues.website,
        address: {
          line1: initialEditValues.address?.line1,
          city: initialEditValues.address?.city,
          state: initialEditValues.address?.state,
          zipCode: initialEditValues.address?.zipCode,
          display: initialEditValues.address?.display,
        },
        adminFirstName: initialEditValues.adminFirstName,
        adminLastName: initialEditValues.adminLastName,
        adminEmail: initialEditValues.adminEmail,
        isMsp: initialEditValues.isMsp,
      }
    : {
        name: '',
        phoneNumber: '',
        website: null,
        address: {
          line1: '',
          city: '',
          state: '',
          zipCode: '',
          display: '',
        },
        adminFirstName: '',
        adminLastName: '',
        adminEmail: '',
        isMsp: true,
      }
  const validateAgencyName = useCallback(
    async function (value: string | undefined, resolve: (value: boolean) => void) {
      if (initialEditValues?.name === value) {
        resolve(true)
      } else {
        try {
          const alreadyExists = await findAgency(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findAgency],
  )

  const debouncedValidateCompanyName = useMemo(
    function () {
      return _.debounce(validateAgencyName, 500)
    },
    [validateAgencyName],
  )

  const validateEmail = useCallback(
    async function (
      value: string | undefined,
      resolve: (value: boolean) => void,
      initialEditValues: IAgencyBase,
    ) {
      if (initialEditValues?.adminEmail === value) {
        resolve(true)
      } else {
        try {
          const alreadyExists = await findEmail(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  const handleSubmit = async (values: IFormValues) => {
    if (initialEditValues?.name) {
      await updateAgency({
        ...values,
        website: values?.website?.length > 0 ? values.website : null,
      }).unwrap()
      dispatch(
        push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_AGENCIES}`, {
          state: {openSnackbar: true, name: values.name, type: 'agency'},
        }),
      )
    } else {
      await addAgency({
        ...values,
        website: values?.website?.length > 0 ? values.website : null,
      }).unwrap()
      dispatch(push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_AGENCIES}`))
    }
  }

  //TODO uncomment when we have backend to support images
  // const [imageToResize, setImageToResize] = useState(undefined)
  // const [resizedImage, setResizedImage] = useState(undefined)

  // const onSelectFile = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setImageToResize(event.target.files[0])
  //   }
  // }
  const handleCancel = () => {
    dispatch(push(`${BASE_ROUTES.PLATFORM_ADMIN}${SIDEBAR_ROUTES.MANAGE_AGENCIES}`))
  }
  return (
    <HeaderContainer
      header={
        <div style={{fontWeight: 'bold'}}>
          {initialEditValues ? 'Edit Agency' : 'Add New Agency'}
        </div>
      }>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object({
          adminEmail: Yup.string()
            .email('Email address is invalid.')
            .test(
              'already-exists',
              'This email is already in use',
              (value) =>
                new Promise((resolve) => debouncedValidateEmail(value, resolve, initialEditValues)),
            )
            .required('Required'),
          adminFirstName: Yup.string().required('Required'),
          adminLastName: Yup.string().required('Required'),
          phoneNumber: Yup.string()
            .required('Required')
            .phone('US', true, 'Phone number is invalid.'),
          website: Yup.string().nullable(),
          address: Yup.object({
            line1: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
            display: Yup.string().required('Required'),
          }),
          name: Yup.string()
            .test(
              'already-exists',
              'Agency name is already taken.',
              (value) => new Promise((resolve) => debouncedValidateCompanyName(value, resolve)),
            )
            .required('Required'),
          isMsp: Yup.bool().required('Required'),
        })}>
        {({isSubmitting, setFieldValue, values, errors}) => {
          return (
            <Form>
              <Grid container columnSpacing={2} rowSpacing={4}>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="name"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Agency Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="name" inputProps={{maxLength: 50}} label={''} type={''} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="phoneNumber"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Agency Phone<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="phoneNumber" inputProps={undefined} label={''} type={''} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="website"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Agency Website:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    name="website"
                    inputProps={{maxLength: 50}}
                    label={''}
                    type={''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="addresses"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Agency Address<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <CompanyAddressListField
                    values={values}
                    setFieldValue={setFieldValue}
                    activePlatform={activePlatform}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminFirstName"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin First Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    name="adminFirstName"
                    inputProps={{maxLength: 50}}
                    label={''}
                    type={''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminLastName"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin Last Name<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    name="adminLastName"
                    inputProps={{maxLength: 50}}
                    label={''}
                    type={''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="adminEmail"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  Admin Email<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField name="adminEmail" inputProps={undefined} label={''} type={''} />
                </Grid>
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}

                <Grid
                  item
                  xs={3}
                  sx={{pr: '20px'}}
                  component="label"
                  htmlFor="isMsp"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center">
                  MSP<Red>*</Red>:
                </Grid>
                <Grid item xs={9}>
                  <FormikSwitchToggle name="isMsp" />
                </Grid>

                <Grid item xs={3} sx={{pr: '20px'}} justifyContent="end" />
                <Grid item xs={9} mt={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting}
                    sx={{mr: 'auto'}}
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ml: 2}}>
                    Publish
                  </Button>
                </Grid>
              </Grid>
              {/* //TODO uncomment when we have backend to support images */}
              {/* <RightColumn>
                <CompanyLogo>Agency Logo: {imageToResize?.name}</CompanyLogo>
                <div style={{marginLeft: '80px'}}>
                  <ImageBox>
                    {resizedImage && <img width="200" height="50" src={resizedImage} />}
                  </ImageBox>

                  <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onSelectFile}
                  />
                  <ImageSubText>
                    *images will try to be resized to 200px (W) x 50px (H) on upload.*
                  </ImageSubText>
                  <label htmlFor="contained-button-file">
                    <Fab component="span">
                      <div>
                        <ImageResizerComponent
                          imageToResize={imageToResize}
                          onImageResized={(resizedImage) => setResizedImage(resizedImage)}
                        />
                      </div>
                      <AddPhotoAlternateRoundedIcon />
                    </Fab>
                  </label>
                </div>
              </RightColumn> */}
            </Form>
          )
        }}
      </Formik>
    </HeaderContainer>
  )
}
