import type {FormikErrors} from 'formik'
import React from 'react'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateActivityDisplay from './MspCandidateActivityDisplay'
import MspCandidateActivityNotification from './MspCandidateActivityNotification'

interface IProps {
  errors: FormikErrors<Applicant>
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(obj: Applicant): void
  setFieldValue(field: string, value: string, shouldValidate?: boolean | undefined): void
  values: Applicant
}

const MspCandidateActivityMain = ({
  errors,
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
}: IProps) => {
  return (
    <>
      <DashboardMainStyles>
        <MspCandidateActivityNotification
          errors={errors}
          newActivity={values.newActivity}
          resetForm={resetForm}
          values={values}
          oldApplicant={oldApplicant}
        />
        <MspCandidateActivityDisplay
          handleSubmit={handleSubmit}
          oldApplicant={oldApplicant}
          setFieldValue={setFieldValue}
          values={values}
        />
      </DashboardMainStyles>
    </>
  )
}

export default MspCandidateActivityMain
