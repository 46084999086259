import {menuItemClasses} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type {Theme} from '@mui/material/styles'

export const useMenuStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    border: `1px solid ${theme.palette.grey[300]}`,

    [`& .${menuItemClasses.selected}`]: {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))

export default useMenuStyles
