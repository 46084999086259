import {css, styled} from '@mui/material/styles'
import {Box} from '@mui/system'
import CompanyCandidateInventory from '@ui/candidate-list/CompanyCandidateInventory'
import JobOrderCompanyCard from '@ui/card/JobOrderCompanyCard'
import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Title from '~/src/components/layout/Title'
import type {Candidate, IJobOrder} from '~/src/models/jobOrder'
import {
  useGetJobOrderQuery,
  useGetJobOrdersByServiceOrderIdsQuery,
} from '~/src/store/apis/job-order-api'
import {selectServiceOrderState} from '~/src/store/slices/service-order-slice'
import {useAppSelector} from '~/src/store/store-hooks'

export const CompanyMainStyles = styled('div')(
  ({theme}) => css`
    position: relative;
    flex: 1;
    min-width: 1111px;
    max-width: 1111px;
    margin: auto;
  `,
)

export const getTimeString = (time: string) => {
  const newTime = new Date(time).toLocaleString([], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
  return newTime
}

interface IParams {
  orderId: string
}

export default function CompanyCandidateViewMain(): JSX.Element {
  const {currentServiceOrderCompletedPageNumber} = useAppSelector(selectServiceOrderState)
  const [targetJobOrder, setTargetJobOrder] = useState(0)
  const {orderId}: IParams = useParams()
  const {loggedInAgencyId} = useAppSelector((state) => state.root)

  const {data, refetch, error, isLoading, isFetching, isSuccess} =
    useGetJobOrdersByServiceOrderIdsQuery({serviceOrderIds: [orderId], agencyId: loggedInAgencyId})

  const jobOrders = data ? data[0]?.jobOrders : []
  //roberto recommended to use this endpoint for candidate info.
  const {data: jobOrderData, refetch: singleJobOrderRefetch} = useGetJobOrderQuery(
    {id: data ? jobOrders[targetJobOrder]?.id : '', agencyId: loggedInAgencyId},
    {skip: !isSuccess},
  )
  const candidateList: Candidate[] = []
  jobOrderData?.agencyDistributions.forEach((element) => {
    candidateList.push(...element.agencyCandidateDistributions.candidateList)
  })

  const handleClick = (targetIndex: number) => {
    setTargetJobOrder(targetIndex)
  }

  useEffect(() => {
    void refetch()
    if (isSuccess) {
      void singleJobOrderRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServiceOrderCompletedPageNumber])

  return (
    <CompanyMainStyles>
      <ErrorBoundary error={error}>
        <LoadingBoundary isLoading={isLoading}>
          <Title pageTitle="Candidate List" />
          <Box
            sx={{
              display: 'flex',
              overflow: 'scroll',
              maxWidth: '1111px',
              borderRadius: 1,
              marginBottom: 5,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {/* job order card */}
            {jobOrders?.map((row: IJobOrder, index: number) => {
              const totalCandidatesNeeded = row.agencyDistributions
                .map(
                  (distribution, indexOne: number) =>
                    distribution.agencyCandidateDistributions.requested,
                )
                .reduce((prev: number, curr: number) => prev + curr, 0)

              const totalCandidatesFilled = row.agencyDistributions
                .map(
                  (distribution, indexOne: number) =>
                    distribution.agencyCandidateDistributions.fulfilled,
                )
                .reduce((prev: number, curr: number) => prev + curr, 0)

              return (
                <JobOrderCompanyCard
                  currentlyfilled={totalCandidatesFilled}
                  distributionDate={new Date(row.date)}
                  handleClick={handleClick}
                  index={index}
                  isDetails={true}
                  jobOrderNumber={row.orderNumber}
                  key={index}
                  position={row.positionId}
                  totalrequested={totalCandidatesNeeded}
                  targetJobOrder={targetJobOrder}
                />
              )
            })}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: '1111px',
              borderRadius: 1,
            }}>
            <CompanyCandidateInventory candidateList={candidateList} />
          </Box>
        </LoadingBoundary>
      </ErrorBoundary>
    </CompanyMainStyles>
  )
}
