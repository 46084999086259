import {FormikGooglePlacesAutocomplete} from '@ui/autocomplete'
import React from 'react'
import S from './CompanyForm.styles'

interface IProps {
  activePlatform: string
  index: number
  remove: (index: number) => void
  setFieldValue: (field: string, value: unknown) => void
  address: string | number | boolean | null
}

export default function AddressField(props: IProps) {
  const {index, remove, activePlatform, setFieldValue} = props
  const testId = `company-address-index-${index}`

  return (
    <S.Grid.Container
      item
      container
      xs={12}
      columnSpacing={2}
      rowSpacing={4}
      key={index}
      data-testid={testId}>
      <S.Grid.Field item xs={12}>
        <FormikGooglePlacesAutocomplete
          name="address"
          value={props.address}
          setFieldValue={setFieldValue}
          address={true}
        />
      </S.Grid.Field>
    </S.Grid.Container>
  )
}
