import {styled} from '@mui/material/styles'
import _Icon from '@ui/icons'
import _Widget from '@ui/widget'

const Icon = styled(_Icon)({
  color: 'inherit',
  gridColumn: '1/2',
  gridRow: '1/3',
  height: 50,
  margin: 18,
  width: 50,
})

const Text = styled('div')(({theme}) => ({
  bottom: '0',
  color: 'inherit',
  fontSize: 15,
  fontWeight: '600',
  gridColumn: '2/3',
  gridRow: '1/2',
  position: 'absolute',
  ...theme.truncate,
}))

const Count = styled('div')(({theme}) => ({
  color: 'inherit',
  fontWeight: '400',
  gridColumn: '2/3',
  gridRow: '2/3',
  textAlign: 'left',
  ...theme.typography.h5,
}))

const Widget = styled(_Widget)<{variant: 'primary' | 'white'}>(({theme, variant}) => ({
  backgroundColor:
    variant === 'primary' ? theme.palette.primary.main : theme.palette.background.paper,
  borderRadius: '8px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  color: variant === 'primary' ? theme.palette.background.paper : theme.palette.primary.main,
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr 1fr',
  minWidth: '235px',
  position: 'relative',
  textDecoration: 'none',
  width: '255px',

  [`${Text}`]: {
    color: variant === 'primary' ? theme.palette.background.paper : theme.palette.grey.A700,
  },
}))

export default {
  Count,
  Icon,
  Text,
  Widget,
}
