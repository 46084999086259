import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import {styled, useTheme} from '@mui/material/styles'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {Formik, Form as FormikForm} from 'formik'
import React from 'react'
import * as Yup from 'yup'

const StyledButton = styled(Button)({
  width: '120px',
  fontWeight: 'normal',
}) as typeof Button

const HeaderContainer = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  paddingLeft: '60px',
  paddingRight: '60px',
})

const HeaderText = styled('div')({
  fontSize: '18px',
  fontWeight: 400,
})

const Body = styled(Box)({
  m: '5px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '30px 60px',
})

const TopText = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  color: '#8A8A8A',
  fontSize: '14px',
  fontWeight: 400,
})

const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '50px 5px 75px 5px',
  justifyContent: 'center',
})

export interface IFilterDialogFormData {
  firstName: string
  lastName: string
  ssn: string
}

interface IProps {
  isOpen: boolean
  firstName: string
  lastName: string
  ssn: string
  handleCancel(): void
  handleSubmit(formikValues: IFilterDialogFormData): void
}

const detectTouched = (formData: IFilterDialogFormData): boolean => {
  return formData.firstName.length > 0 || formData.lastName.length > 0 || formData.ssn.length > 0
}

export default function FilterDialog({
  isOpen,
  firstName,
  lastName,
  ssn,
  handleCancel,
  handleSubmit,
}: IProps): React.ReactElement {
  const theme = useTheme()
  let isTouched = false
  const initialFormValues = {
    firstName: firstName,
    lastName: lastName,
    ssn: ssn,
  } as IFilterDialogFormData

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {minWidth: 546, width: 546, height: 472},
      }}>
      <HeaderContainer>
        <HeaderText>Candidate Search</HeaderText>
        <IconButton
          aria-label="close"
          size="large"
          onClick={handleCancel}
          style={{position: 'relative', right: '-10px'}}>
          <CloseIcon style={{color: theme.palette.grey[900]}} />
        </IconButton>
      </HeaderContainer>
      <Body>
        <TopText>Please enter Candidate information:</TopText>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={Yup.object({
            firstName: Yup.string().nullable(),
            lastName: Yup.string().nullable(),
            ssn: Yup.number().typeError('Digits Only'),
          })}>
          {({isSubmitting, setFieldValue, values, handleChange, errors, getFieldMeta}) => {
            isTouched = detectTouched(values)
            return (
              <FormikForm>
                <div style={{width: '100%', marginTop: '20px'}}>
                  <FormikTextField
                    name="firstName"
                    placeholder="First Name (Optional)"
                    inputProps={{maxLength: 50}}
                  />
                </div>
                <div style={{width: '100%', marginTop: '20px'}}>
                  <FormikTextField
                    name="lastName"
                    placeholder="Last Name (Optional)"
                    inputProps={{maxLength: 50}}
                  />
                </div>
                <div style={{width: '100%', marginTop: '20px'}}>
                  <FormikTextField
                    name="ssn"
                    placeholder="Last 4 SSN (Optional)"
                    inputProps={{maxLength: 4}}
                  />
                </div>

                <ButtonBox>
                  <StyledButton
                    style={{marginRight: '50px'}}
                    onClick={handleCancel}
                    variant="outlined"
                    color="secondary"
                    type="button">
                    Cancel
                  </StyledButton>
                  <StyledButton variant="contained" color="primary" type="submit">
                    Search
                  </StyledButton>
                </ButtonBox>
              </FormikForm>
            )
          }}
        </Formik>
      </Body>
    </Dialog>
  )
}
