import type {IAccessItem} from '../models';
import type {ISidebarItem} from '.';

function hasEntity(s: ISidebarItem, a: IAccessItem) {
	return s.entityType === a.entityType;
}

function hasMinimumRequirements(s: ISidebarItem, a: IAccessItem) {
	return s.operations.every((op) => a.operations.includes(op));
}

export function sidebarFilter(accessList: IAccessItem[] | undefined, sidebarList: ISidebarItem[]) {
	return sidebarList.filter((sidebarItem) => {
		return accessList?.find((accessItem) => {
			return hasEntity(sidebarItem, accessItem) && hasMinimumRequirements(sidebarItem, accessItem);
		});
	});
}
