import FormikDatePicker from '@ui/date-time-pickers/FormikDatePicker'
import {FlexSpaceBetweenContainer} from '@ui/day-bubble/MspAvailabilityDayBubbleStyles.styles'
import FormikTextField from '@ui/mui-text-field/FormikTextField'
import React from 'react'
import {Red} from '~/src/components/company/styles/EditCompanyStyles.styles'
import type {EventsArray} from '~/src/models/applicant'
import {
  DashboardMainStyles,
  FlexColumnContainer,
  FlexContainer,
  InformationCardContainer,
  InformationCardDetailsContainer,
  InformationCardExperianceBorder,
  InformationCardHeaderContainer,
  InformationCardHeaderPadding,
  InformationCardHeaderTextEdit,
  InformationCardHeaderTextTitle,
  InformationCardProfileExperienceJobText,
  InformationCardProfileHeaderText,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  handleClick(value: string, index: number, isCancel: boolean): void
  index: number
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: EventsArray
}

export default function MspCandidateEventsEdit({
  handleClick,
  index,
  setFieldValue,
  values,
}: IProps) {
  const maxStartDate = new Date(values.endDate)
  const minEndDate = new Date(values.startDate)
  return (
    <>
      <DashboardMainStyles>
        <InformationCardContainer>
          <FlexSpaceBetweenContainer>
            <InformationCardHeaderContainer>
              <InformationCardHeaderPadding>
                <InformationCardHeaderTextTitle>Events Edit</InformationCardHeaderTextTitle>
              </InformationCardHeaderPadding>
            </InformationCardHeaderContainer>
            <FlexSpaceBetweenContainer style={{width: '85px', marginRight: '20px'}}>
              <InformationCardHeaderTextEdit
                onClick={() => handleClick('saveChanges', index, true)}>
                Cancel
              </InformationCardHeaderTextEdit>
              <InformationCardHeaderTextEdit
                onClick={() => handleClick('saveChanges', index, false)}>
                Save
              </InformationCardHeaderTextEdit>
            </FlexSpaceBetweenContainer>
          </FlexSpaceBetweenContainer>
          <InformationCardDetailsContainer>
            <InformationCardProfileExperienceJobText>
              <FlexContainer>
                <FlexColumnContainer>
                  <span style={{display: 'flex'}}>
                    Position <Red>*</Red>
                  </span>
                  <FormikTextField
                    name={`events.${index}.position`}
                    data-testid="eventsPosition"
                    type="text"
                    label=""
                    value={values.position}
                    sx={{
                      maxWidth: '210px',
                      minWidth: '210px',
                      height: '28px',
                      maxHeight: '28px',
                      marginRight: '19px',
                    }}
                  />
                </FlexColumnContainer>
                <FlexColumnContainer>
                  <span style={{display: 'flex'}}>
                    Company <Red>*</Red>
                  </span>
                  <FormikTextField
                    name={`events.${index}.company`}
                    data-testid="eventsCompany"
                    type="text"
                    label=""
                    value={values.company}
                    sx={{
                      maxWidth: '210px',
                      minWidth: '210px',
                      height: '28px',
                      maxHeight: '28px',
                      marginRight: '19px',
                    }}
                  />
                </FlexColumnContainer>
                <FlexColumnContainer>
                  <span style={{display: 'flex'}}>
                    Assignment ID <Red>*</Red>
                  </span>
                  <FormikTextField
                    name={`events.${index}.assignmentID`}
                    data-testid="assignmentID"
                    type="text"
                    label=""
                    value={values.assignmentID}
                    sx={{
                      maxWidth: '195px',
                      minWidth: '195px',
                      height: '28px',
                      maxHeight: '28px',
                    }}
                  />
                </FlexColumnContainer>
              </FlexContainer>
            </InformationCardProfileExperienceJobText>

            <InformationCardProfileExperienceJobText>
              <FlexContainer>
                <FlexColumnContainer>
                  <span style={{display: 'flex'}}>
                    Start Date <Red>*</Red>
                  </span>
                  <div style={{width: '210px'}}>
                    <FormikDatePicker
                      name={`events.${index}.startDate`}
                      data-testid="eventsStartDate"
                      type="text"
                      label=""
                      initialValue={values.startDate}
                      sx={{
                        width: '100%',
                      }}
                      maxDate={
                        values.endDate === '' || values.endDate === undefined
                          ? undefined
                          : maxStartDate.setDate(maxStartDate.getDate())
                      }
                      onClose={(va: Date) => setFieldValue(values.startDate, va)}
                    />
                  </div>
                </FlexColumnContainer>
                <FlexColumnContainer style={{marginLeft: 20}}>
                  <span style={{display: 'flex'}}>
                    End Date <Red>*</Red>
                  </span>
                  <div style={{width: '210px'}}>
                    <FormikDatePicker
                      name={`events.${index}.endDate`}
                      data-testid="eventsEndDate"
                      type="text"
                      label=""
                      initialValue={values.endDate}
                      sx={{
                        width: '100%',
                      }}
                      minDate={minEndDate.setDate(minEndDate.getDate())}
                      onClose={(va: Date) => setFieldValue(values.endDate, va)}
                    />
                  </div>
                </FlexColumnContainer>
              </FlexContainer>
            </InformationCardProfileExperienceJobText>
            <InformationCardProfileExperienceJobText>
              <FlexContainer>
                <FlexColumnContainer>
                  <span style={{display: 'flex'}}>
                    Event Name <Red>*</Red>
                  </span>
                  <FormikTextField
                    name={`events.${index}.eventName`}
                    data-testid="eventsEmptyEventName"
                    type="text"
                    label=""
                    value={values.eventName}
                    sx={{
                      maxWidth: '439px',
                      minWidth: '439px',
                      height: '28px',
                      maxHeight: '28px',
                      marginRight: '19px',
                    }}
                  />
                </FlexColumnContainer>
              </FlexContainer>
            </InformationCardProfileExperienceJobText>
            <InformationCardExperianceBorder index={false}>
              <InformationCardProfileHeaderText>
                Description
                <FormikTextField
                  name={`events.${index}.eventDescription`}
                  data-testid="eventsDescription"
                  id="outlined-multiline-flexible"
                  multiline
                  rows={2}
                  type="text"
                  label=""
                  value={values.eventDescription}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 20,
                    },
                  }}
                  sx={{
                    marginBottom: '15px',
                    maxWidth: '650px',
                    minWidth: '650px',
                  }}
                />
              </InformationCardProfileHeaderText>
            </InformationCardExperianceBorder>
          </InformationCardDetailsContainer>
        </InformationCardContainer>
      </DashboardMainStyles>
    </>
  )
}
