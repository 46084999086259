import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import type {Applicant} from '~/src/models/applicant'
import {DashboardMainStyles} from '../../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import MspCandidateProfileEducationDisplay from '../CandidateProfileDisplayandEdit/MspCandidateProfileEducationDisplay'
import MspCandidateProfileEducationEdit from '../CandidateProfileDisplayandEdit/MspCandidateProfileEducationEdit'

interface IProps {
  handleSubmit(values: Applicant): Promise<void>
  oldApplicant: Applicant
  resetForm(): void
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateProfileEducation({
  handleSubmit,
  oldApplicant,
  resetForm,
  setFieldValue,
  values,
}: IProps) {
  const dispatch = useDispatch()
  const [editProfileEducationState, setEditProfileEducationState] = useState('saveChanges')

  const handleClick = async (value: string) => {
    if (value === 'edit') {
      setEditProfileEducationState('edit')
    } else {
      await handleSubmit(values)
      setEditProfileEducationState('saveChanges')
    }
  }

  const handleCancel = () => {
    resetForm()
    setEditProfileEducationState('saveChanges')
  }

  useEffect(() => {
    setEditProfileEducationState('saveChanges')
  }, [oldApplicant.id])

  const projectEducation = () => {
    switch (editProfileEducationState) {
      case 'edit':
        return (
          <MspCandidateProfileEducationEdit
            handleCancel={handleCancel}
            handleClick={handleClick}
            setFieldValue={setFieldValue}
            values={values.education}
          />
        )
      case 'saveChanges':
        return (
          <MspCandidateProfileEducationDisplay
            handleClick={handleClick}
            values={values.education}
          />
        )

      default:
        return (
          <MspCandidateProfileEducationDisplay
            handleClick={handleClick}
            values={values.education}
          />
        )
    }
  }

  return (
    <>
      <DashboardMainStyles>{projectEducation()}</DashboardMainStyles>
    </>
  )
}
