import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EyesIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='22'
			height='23'
			viewBox='0 0 22 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.0003 8.625C11.7297 8.625 12.4291 8.9279 12.9449 9.46707C13.4606 10.0062 13.7503 10.7375 13.7503 11.5C13.7503 12.2625 13.4606 12.9938 12.9449 13.5329C12.4291 14.0721 11.7297 14.375 11.0003 14.375C10.271 14.375 9.57151 14.0721 9.05578 13.5329C8.54006 12.9938 8.25033 12.2625 8.25033 11.5C8.25033 10.7375 8.54006 10.0062 9.05578 9.46707C9.57151 8.9279 10.271 8.625 11.0003 8.625V8.625ZM11.0003 4.3125C15.5837 4.3125 19.4978 7.29292 21.0837 11.5C19.4978 15.7071 15.5837 18.6875 11.0003 18.6875C6.41699 18.6875 2.50283 15.7071 0.916992 11.5C2.50283 7.29292 6.41699 4.3125 11.0003 4.3125ZM2.91533 11.5C4.42783 14.72 7.55366 16.7708 11.0003 16.7708C14.447 16.7708 17.5728 14.72 19.0853 11.5C17.5728 8.28 14.447 6.22917 11.0003 6.22917C7.55366 6.22917 4.42783 8.28 2.91533 11.5Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
