import styled, {css} from 'styled-components'

import {colors, font, resets} from '~/src/config/theme'
import BorderImage from '~/src/assets/images/dashed-border.png'
import Form from '~/src/components/common/styles/FormStyles'
import TabBlockElements from './tab-block.styles'

const Tabs = {
  ...TabBlockElements,
}

export const TableInfo = {
  Container: styled.div`
    position: relative;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  HeaderLeft: styled.div`
    column-gap: 28px;
    display: flex;
    font-size: ${font.sizes.small};
  `,
  HeaderRight: styled.div`
    column-gap: 15px;
    display: flex;
    font-size: ${font.sizes.smaller};
  `,
  IconButton: styled.button`
    ${resets.common}
    align-items: center;
    column-gap: 5px;
    display: flex;
    font-size: inherit;

    svg {
      background-color: ${colors.primary};
      border-radius: 50%;
      color: ${colors.white};
      height: 13px;
      padding: 2px;
      width: 13px;
    }
  `,
  Filter: styled.label`
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    display: flex;
  `,
  BlueText: styled.a`
    color: ${colors.primary};
    font-weight: 700;
  `,
  Body: styled.div`
    margin-top: 40px;
  `,
}

export const Requirements = {
  Container: styled.div`
    td:nth-child(2) svg {
      left: 0;
      transform: translateY(-50%);
    }

    .parent-table {
      overflow: visible;
    }

    .child-table {
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;

      tr {
        background-color: transparent;
      }

      th:nth-child(3) {
        text-align: center;
      }

      td:first-child {
        border-bottom-color: transparent;
        position: relative;

        &::before {
          background-color: #e7e7ed;
          bottom: -1px;
          content: '';
          height: 1px;
          position: absolute;
          right: 0;
          width: 50%;
        }
      }

      .dropdown {
        width: 160px;
      }
    }
  `,
  Title: styled.div`
    column-gap: 15px;
    display: flex;
    font-size: ${font.sizes.small};
    margin-bottom: 32px;
  `,
}

export const TabsProfileInfo = {
  ...Form,
  Container: styled(Form.Body)`
    padding: 0;
  `,
  InputArea: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  `,
}

const docHeight = '114px'

const docWidth = '98px'

const faceBase = css`
  border-radius: 4px;
  bottom: 0;
  height: 107.08px;
  line-height: 107.08px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
`

export const Documents = {
  Container: styled.div``,
  Title: styled.p`
    margin-bottom: 40px;
  `,
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-bottom: 55px;
  `,
  Folder: styled.div`
    font-size: ${font.sizes.smaller};
    height: ${docHeight};
    position: relative;
    width: ${docWidth};

    &::before {
      background-color: #0b71a5;
      border-top-left-radius: 4px;
      border-top-right-radius: 8px;
      content: '';
      height: 14px;
      position: absolute;
      width: 50px;
    }

    &::after {
      background-color: #0b71a5;
      border-top-right-radius: 4px;
      content: '';
      height: 7px;
      position: absolute;
      top: 3px;
      width: 100%;
    }
  `,
  FolderFace: styled.div`
    ${faceBase}
    background-color: ${colors.primary};
  `,
  File: styled.div(
    (props: {fileType: string}) => css`
      background-color: #f0f0f0;
      border-radius: 4px;
      font-size: ${font.sizes.smaller};
      height: ${docHeight};
      position: relative;
      width: ${docWidth};

      &::before {
        align-items: center;
        background-color: ${colors.primary};
        border-radius: 0 4px 4px 0;
        color: ${colors.white};
        content: '${props.fileType}';
        display: flex;
        height: 14.66px;
        padding: 0 7px;
        position: absolute;
        top: 11.4px;
        width: 48.86px;
      }
    `,
  ),
  FileFace: styled.div`
    ${faceBase}
  `,
  AddFile: styled.div`
    align-items: center;
    border: dashed 2px;
    border-image: url(${BorderImage}) 2 round;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: ${font.sizes.tiny};
    height: ${docHeight};
    justify-content: center;
    width: ${docWidth};

    svg {
      background-color: #8a8a8a;
      border-radius: 50%;
      color: ${colors.white};
      height: 18px;
      margin-bottom: 10px;
      padding: 3px;
      width: 18px;
    }
  `,
}

export default Tabs
