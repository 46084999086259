import {Tooltip} from '@mui/material'
import React from 'react'
import {ReactComponent as DropdownArrow} from '~/src/assets/images/DropdownArrow.svg'
import {ReactComponent as PlusIcon} from '~/src/assets/images/SkillPlusIcon.svg'
import {ReactComponent as SkillXIcon} from '~/src/assets/images/SkillXIcon.svg'
import {SkillListHeaderAndBodyContainer} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'
import type {TCheckboxData} from './CandidateSkillsForm'
import {
  CategoryHeader,
  DropdownContainer,
  SkillContainerBox,
  SkillInnerBoxMargin,
  SkillInnerTextSpan,
} from './styles/SkillStyles.styles'

interface IProps {
  isEnabled: boolean
  isEdit: boolean
  setArray: React.Dispatch<React.SetStateAction<TCheckboxData[]>>
  setEnabled: (value: boolean) => void
  mainArray: unknown
  header: string
  updateChecked: (arr: TCheckboxData[], index: number) => TCheckboxData[]
}

export default function ReusableSkillSection({
  isEnabled,
  isEdit,
  setArray,
  setEnabled,
  mainArray,
  header,
  updateChecked,
}: IProps) {
  return (
    <>
      {isEnabled || isEdit ? (
        <SkillListHeaderAndBodyContainer>
          <div
            onKeyDown={() => {
              setEnabled(!isEnabled)
            }}
            style={{cursor: isEdit ? 'pointer' : '', display: 'flex', alignItems: 'center'}}
            aria-label="dropdown"
            onClick={() => {
              if (isEdit) {
                setEnabled(!isEnabled)
              }
            }}>
            <CategoryHeader>{header}</CategoryHeader>
            {isEdit ? <DropdownArrow /> : <div />}
          </div>

          <DropdownContainer isEnabled={isEnabled}>
            {mainArray.map((options, optionIndex) => {
              return (
                <SkillContainerBox
                  onClick={() => {
                    setArray(() => {
                      return updateChecked(mainArray, optionIndex)
                    })
                  }}
                  key={optionIndex}
                  displayBox={options?.isChecked || isEdit}
                  isSelected={options?.isChecked}>
                  <SkillInnerBoxMargin>
                    {!options?.isChecked && isEdit ? (
                      <PlusIcon style={{marginRight: 5}} />
                    ) : (
                      <div />
                    )}
                    <Tooltip title={`${options.label}`}>
                      <SkillInnerTextSpan>{options.label}</SkillInnerTextSpan>
                    </Tooltip>

                    {options?.isChecked && isEdit ? (
                      <SkillXIcon style={{marginLeft: 5}} />
                    ) : (
                      <div />
                    )}
                  </SkillInnerBoxMargin>
                </SkillContainerBox>
              )
            })}
          </DropdownContainer>
        </SkillListHeaderAndBodyContainer>
      ) : (
        <div />
      )}
    </>
  )
}
