import {css, styled} from '@mui/material/styles'
import SignInContainer from '@ui/sign-in-container/SignInContainer'
import {push} from 'connected-react-router'
import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {BASE_ROUTES} from '~/src/routes/constants'
import {useAppDispatch} from '~/src/store/store-hooks'
import CheckedBanner from '../../components/auth/CheckedImage'
import SubmitButton from '../../components/auth/SubmitButton'

const ConfirmationContainer = styled('div')(
  ({theme}) => css`
    justify-content: center;
    text-align: center;
  `,
)
const FormTitle = styled('h2')(
  ({theme}) => css`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 50px;
  `,
)

const FormSubtitle = styled('h3')(
  ({theme}) => css`
    font-size: 14px;
    color: lightgrey;
    padding-bottom: 75px;
  `,
)

const ButtonFooter = styled('div')(
  ({theme}) => css`
    padding-top: 75px;
  `,
)

interface IState {
  state: any
}

export default function PasswordConfirmation() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const locationState = location.state as IState

  const formSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(
      push(BASE_ROUTES.SIGNIN, {
        state: locationState.state,
      }),
    )
  }

  return (
    <SignInContainer
      customFooter={
        <NavLink className="nav-link" to={BASE_ROUTES.SIGNIN}>
          <div>
            Back to <u>Sign In</u>
          </div>
        </NavLink>
      }>
      <ConfirmationContainer>
        <FormTitle>Password Updated</FormTitle>
        <FormSubtitle>Your password has been updated!</FormSubtitle>
        <form onSubmit={(e) => formSubmit(e)}>
          <CheckedBanner />

          <ButtonFooter>
            <SubmitButton>Proceed to Dashboard</SubmitButton>
          </ButtonFooter>
        </form>
      </ConfirmationContainer>
    </SignInContainer>
  )
}
