import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import {css, styled} from '@mui/material/styles'
import Mui from '@ui/mui'
import React from 'react'
import {useAppDispatch} from '~/src/store/store-hooks'

const HeaderText = styled('div')(
  ({theme}) => css`
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 30px;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 1px solid grey;
  `,
)

const Button = styled(Mui.Button)({
  marginTop: 100,
  fontWeight: 'normal',
})

const Container = styled('div')({
  backgroundColor: '#FAFAFA',
  borderBottom: '1px solid #EBEBEB',
  fontSize: '18px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
})

const SubContainer = styled('div')({
  backgroundColor: '#E5E5E5',
  borderBottom: '1px solid black',
  fontSize: '18px',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
})

const OrderPlaced = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '28px',
  fontWeight: 600,
  marginTop: 50,
})

interface IProps {
  onModalClose(): void
  isModalOpen: boolean
}

export default function ExpiredPasswordDialog({onModalClose, isModalOpen}: IProps): JSX.Element {
  const dispatch = useAppDispatch()

  return (
    <Dialog
      fullWidth
      open={isModalOpen}
      onClose={onModalClose}
      PaperProps={{sx: {maxWidth: 900, height: 500}}}>
      <>
        <Container>
          <div style={{marginLeft: 40, padding: 20}}>Your Password Is Expired</div>
        </Container>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            marginTop: '100px',
          }}>
          <OrderPlaced>
            Your password has expired and need to contact your administrator for assistance.
          </OrderPlaced>
          <Grid sx={{m: 5}} item xs mt={6} display="flex" justifyContent="center">
            <Button onClick={onModalClose} color="primary" variant="contained" type="button">
              Back To Sign In
            </Button>
          </Grid>
        </Box>
      </>
    </Dialog>
  )
}
