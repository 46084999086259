import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function CapIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg {...props} viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/Svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.2471 0.907058L20.5824 4.54709H20.5784C20.7049 4.59622 20.8131 4.68216 20.8884 4.79339C20.9637 4.90463 21.0026 5.03583 20.9999 5.16939C20.9971 5.30296 20.9528 5.4325 20.873 5.54064C20.7931 5.64878 20.6815 5.73035 20.5531 5.7744L11.2177 9.0097C11.1467 9.03479 11.0718 9.04764 10.9963 9.04768C10.9209 9.04741 10.846 9.03457 10.775 9.0097L2.99861 6.31275V10.2815C2.99861 10.5421 2.89323 10.792 2.70566 10.9762C2.51808 11.1604 2.26367 11.2639 1.9984 11.2639C1.73312 11.2639 1.47871 11.1604 1.29114 10.9762C1.10356 10.792 0.998182 10.5421 0.998182 10.2815V5.48362C1.00448 5.41682 1.01835 5.35092 1.03952 5.28714C1.03565 5.2703 1.02788 5.25458 1.0201 5.23886C1.00914 5.2167 0.998182 5.19455 0.998182 5.16926C0.995449 5.03572 1.03435 4.90455 1.10968 4.79335C1.185 4.68214 1.29315 4.59621 1.41961 4.54709L10.755 0.907058C10.913 0.845434 11.089 0.845434 11.2471 0.907058ZM11.0003 10.4781L16.1094 8.70327H16.1081C16.2087 8.6685 16.3163 8.65793 16.4219 8.67244C16.5275 8.68696 16.628 8.72614 16.715 8.7867C16.802 8.84726 16.873 8.92744 16.9219 9.02049C16.9709 9.11355 16.9964 9.21677 16.9963 9.32151V12.5961C16.9961 12.7315 16.9532 12.8636 16.8734 12.9741C16.7936 13.0846 16.6809 13.1681 16.5508 13.213L12.55 14.6015C11.545 14.9473 10.4503 14.9473 9.44532 14.6015L5.44445 13.213C5.31437 13.1681 5.20168 13.0846 5.1219 12.9741C5.04213 12.8636 4.9992 12.7315 4.99902 12.5961V9.32151C4.99895 9.21677 5.02445 9.11355 5.07338 9.02049C5.12232 8.92744 5.19327 8.84726 5.28027 8.7867C5.36727 8.72614 5.4678 8.68696 5.5734 8.67244C5.67901 8.65793 5.78663 8.6685 5.88722 8.70327L11.0003 10.4781Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
