/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import type {ILoader} from './types';
import S from './styles/loader.styles'

export function Loader({page, ...props}: ILoader) {
  return <S.Loader {...props} className="sub" />
}

Loader.defaultProps = {
  'size': 'medium',
  'data-testid': 'loader',
  'positioned': 'absolute',
}
