import React from 'react'
import {FormControl, RadioGroup} from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'

import {StyledRadio} from './StyledRadio'
import {StyledFormControlLabel} from '../form-control-label/StyledFormControlLabel'
import {useField} from 'formik'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormikYesNo({name, ...otherProps}: any) {
  const [field, meta, helpers] = useField(name)

  return (
    <FormControl error={!!(meta.touched && meta.error)} variant="standard">
      <RadioGroup
        row
        value={field.value}
        name={name}
        onChange={(event) => {
          const value = event.target.value === 'true'
          helpers.setValue(value)
        }}>
        <StyledFormControlLabel value={true} control={<StyledRadio />} label="Yes" />
        <StyledFormControlLabel
          value={false}
          control={<StyledRadio sx={{paddingLeft: 2}} />}
          label="No"
        />
      </RadioGroup>
      <FormHelperText>{meta.touched ? meta.error : ''}</FormHelperText>
    </FormControl>
  )
}
