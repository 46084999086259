import {push} from 'connected-react-router'
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import styled, {css} from 'styled-components'
import Layout from '~/src/components/layout/Layout'
import UsersAndRoles from '~/src/components/msp/users-and-roles'
import {SIDEBAR_ROUTES} from '~/src/routes'
import {useSignoutMutation} from '~/src/store/apis/auth-api'
import {getCurrentSession} from '~/src/store/cognito'
import {useCurrentUser} from '~/src/store/slice-hooks'
import {useAppDispatch} from '~/src/store/store-hooks'
import {Candidates} from '../components/common/candidates/Candidates'
import {ImportWizardMain} from '../components/common/import-wizard/ImportWizardMain'
import JobOrdersDetailsSummaryMain from '../components/common/job-orders/JobOrderDetailsSummaryMain'
import AddOrEditPrefill from '../components/common/prefill/AddOrEditPrefill'
import PrefillMain from '../components/common/prefill/PrefillMain'
import ServiceOrderSummary from '../components/company/manage-orders/manage-order-tables/company-summary/ServiceOrderSummary'
import MspDataCleanupMain from '../components/msp/manage-data-cleanup/MspDataCleanupMain'
import JobOrderFromPrefill from '../components/msp/manage-job-orders/job-order-summary/JobOrderFromPrefill'
import AgencyDashboard from '../components/nonmsp/Dashboard/Dashboard'
import NonMspAgencyCompletedOrders from '../components/nonmsp/manage-orders/nonmsp-agency-tables/NonMspAgencyCompletedOrders'
import NonMspAgencyManageOrdersMain from '../components/nonmsp/manage-orders/nonmsp-agency-tables/NonMspAgencyManageOrdersMain'
import NonMspAgencyOrderDetails from '../components/nonmsp/manage-orders/nonmsp-agency-tables/NonMspAgencyOrderDetails'
import NonMspAgencyPendingOrders from '../components/nonmsp/manage-orders/nonmsp-agency-tables/NonMspAgencyPendingOrders'
import ManageAgency from '../components/nonmsp/manage-profile/ManageAgency'

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px);
  margin-top: 90px;
`

const Main = styled.div(
  () => css`
    display: flex;
    min-width: 1111px;
    justify-content: center;
    flex-direction: column;
    transition: all 400ms ease;
  `,
)

export default function NonMspAgencyAdminPage() {
  const {path, url} = useRouteMatch()
  const {currentUser} = useCurrentUser()
  const dispatch = useAppDispatch()
  const [signout] = useSignoutMutation()

  useEffect(() => {
    const fetchCurrentSession = async () => {
      const session = await getCurrentSession()
      if (!session?.isValid()) {
        // Logout and reroute
        await signout({email: currentUser?.emailAddress as string}).unwrap()
        dispatch(push('/signin'))
      }
    }
    const timeoutVar = setInterval(() => {
      void fetchCurrentSession()
    }, 30000)
    return () => {
      clearInterval(timeoutVar)
    }
  }, [currentUser?.emailAddress, dispatch, signout])

  return (
    <Layout variant="agency">
      <Container>
        <Main>
          <Switch>
            <Route path={path + SIDEBAR_ROUTES.DASHBOARD} component={AgencyDashboard} />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.PENDING_SERVICE_ORDERS}
              component={NonMspAgencyPendingOrders}
            />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.COMPLETED_SERVICE_ORDERS}
              component={NonMspAgencyCompletedOrders}
            />
            <Route
              exact
              path={`${path + SIDEBAR_ROUTES.SERVICE_ORDERS}/:orderId/:type/summary`}
              component={ServiceOrderSummary}
            />
            <Route
              exact
              path={path + SIDEBAR_ROUTES.SERVICE_ORDER_DETAILS}
              component={NonMspAgencyOrderDetails}
            />
            <Route
              path={path + SIDEBAR_ROUTES.SERVICE_ORDERS}
              component={NonMspAgencyManageOrdersMain}
            />
            <Route path={path + SIDEBAR_ROUTES.IMPORT} component={ImportWizardMain} />
            <Route exact path={path + SIDEBAR_ROUTES.CANDIDATES} component={Candidates} />
            <Route
              path={`${path + SIDEBAR_ROUTES.PREFILL_JOB_ORDER}/:jobOrderId/:prefillId`}
              component={JobOrderFromPrefill}
            />
            <Route
              path={`${path + SIDEBAR_ROUTES.JOB_ORDER_DETAILS_SUMMARY}/:id`}
              component={JobOrdersDetailsSummaryMain}
            />
            <Route path={path + SIDEBAR_ROUTES.PREFILL_ADD} component={AddOrEditPrefill} />
            <Route path={path + SIDEBAR_ROUTES.PREFILL_EDIT} component={AddOrEditPrefill} />
            <Route path={path + SIDEBAR_ROUTES.PREFILL} component={PrefillMain} />
            <Route path={path + SIDEBAR_ROUTES.DATA_CLEANUP} component={MspDataCleanupMain} />
            <Route path={path + SIDEBAR_ROUTES.USERS_AND_ROLES} component={UsersAndRoles} />
            <Route path={path + SIDEBAR_ROUTES.MANAGE_AGENCY} component={ManageAgency} />
            <Redirect exact from={path} to={url + SIDEBAR_ROUTES.DASHBOARD} />
          </Switch>
        </Main>
      </Container>
    </Layout>
  )
}
