import {AppBar, Container, Toolbar, Typography} from '@mui/material'
import {Box} from '@mui/system'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useGetOneAgencyQuery} from '~/src/store/apis/agency-api'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useCurrentUser} from '~/src/store/slice-hooks'
import type {INavMenuItem} from '../../layout/MenuItems'
import {JobSourceLogo, LogoContainer, LogoText} from './JobSourceIcon'
import NavMenuItem from './NavMenuItem'
import SubMenu from './SubMenu'
import {useActiveLink} from './useActiveLink'
import UserMenu from './UserMenu'
import SelectAgencyMenu from './SelectAgencyMenu'

interface NavMenuBarProps {
  items: INavMenuItem[]
}

const NavMenuBar = ({items}: NavMenuBarProps) => {
  const {activeLink, handleSetActive, subMenuItems} = useActiveLink(items)
  const {currentUser} = useCurrentUser()
  const {companyId, roles} = currentUser
  const company = useGetOneCompanyQuery(companyId)
  const location = useLocation()

  const foundItem = items.find((item) => {
    let val = false
    if (item.link) {
      val = location.pathname.search(new RegExp(item.link as string, 'i')) !== -1
    } else {
      const foundSubItem = item.subMenuItems.find((subItem) => {
        const subVal = location.pathname.search(new RegExp(subItem.link as string, 'i')) !== -1
        return subVal
      })
      val = foundSubItem !== undefined
    }
    return val
  })

  const agency = useGetOneAgencyQuery(company.currentData?.agencyId ?? '')

  const [isSubMenuOpen, setIsMenuOpen] = useState(false)
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e: React.ChangeEvent<HTMLElement>) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isSubMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isSubMenuOpen])

  const handleClick = (event: React.ChangeEvent<HTMLElement>) => {
    handleSetActive(event)
    setIsMenuOpen(true)
  }

  const handleClose = (event: React.ChangeEvent<HTMLElement>) => {
    handleSetActive(event)
    setIsMenuOpen(false)
  }

  return (
    <>
      <AppBar
        ref={ref}
        position="fixed"
        sx={{boxShadow: 'none', height: '70px', width: '100%'}}
        color="inherit">
        <Container
          style={{
            minWidth: '85%',
            padding: 0,
            margin: '0 auto',
          }}>
          <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
            <LogoContainer>
              {company.currentData?.name ? (
                <Typography>{company.currentData.name}</Typography>
              ) : (
                <>
                  <JobSourceLogo />
                  <LogoText />
                </>
              )}
            </LogoContainer>
            <Box sx={{display: 'flex'}}>
              {items?.map((page) => (
                <NavMenuItem
                  id={page.id.toString()}
                  key={page.id}
                  text={page.text}
                  link={page.link ?? location.pathname}
                  active={page.id === foundItem?.id}
                  handleSetActive={handleClick}
                />
              ))}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              {roles.includes('general_admin') && <SelectAgencyMenu sx={{marginRight: '20px'}} />}
              <UserMenu currentUser={currentUser} />
              {agency.currentData ? (
                <Box sx={{alignSelf: 'center', marginLeft: '69px'}}>
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '11px',
                      lineHeight: '13px',
                      color: '#8A8A8A',
                    }}>
                    Assigned MSP <br />
                    {agency.currentData.name}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Toolbar>
        </Container>
        {isSubMenuOpen && subMenuItems.length > 0 ? (
          <SubMenu
            items={subMenuItems}
            closeMenu={handleClose}
            activeSubLink={activeLink.subMenuId}
          />
        ) : null}
      </AppBar>
    </>
  )
}

export default NavMenuBar
