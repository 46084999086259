import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function ErrorIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.9996 14.2144C12.2294 14.2144 12.4499 14.1231 12.6124 13.9606C12.7749 13.7981 12.8662 13.5776 12.8662 13.3478V9.33444C12.8662 9.10459 12.7749 8.88415 12.6124 8.72161C12.4499 8.55908 12.2294 8.46777 11.9996 8.46777C11.7697 8.46777 11.5493 8.55908 11.3868 8.72161C11.2242 8.88415 11.1329 9.10459 11.1329 9.33444V13.3344C11.1311 13.4494 11.1523 13.5635 11.195 13.6702C11.2378 13.7769 11.3013 13.874 11.382 13.9559C11.4626 14.0378 11.5588 14.1028 11.6648 14.1472C11.7708 14.1916 11.8846 14.2145 11.9996 14.2144Z'
				fill='currentColor'
			/>
			<path
				d='M11.9668 17.1807C12.5191 17.1807 12.9668 16.7329 12.9668 16.1807C12.9668 15.6284 12.5191 15.1807 11.9668 15.1807C11.4145 15.1807 10.9668 15.6284 10.9668 16.1807C10.9668 16.7329 11.4145 17.1807 11.9668 17.1807Z'
				fill='currentColor'
			/>
			<path
				d='M20.2205 17.0273L13.7271 5.06734C13.5552 4.75148 13.3013 4.48782 12.9922 4.30411C12.683 4.1204 12.3301 4.02344 11.9705 4.02344C11.6108 4.02344 11.2579 4.1204 10.9487 4.30411C10.6396 4.48782 10.3857 4.75148 10.2138 5.06734L3.71379 17.0273C3.54365 17.3327 3.45653 17.6773 3.46111 18.0269C3.46568 18.3764 3.56179 18.7187 3.73985 19.0195C3.91792 19.3203 4.17171 19.5692 4.47595 19.7413C4.78018 19.9135 5.12422 20.0029 5.47378 20.0007H18.4605C18.8071 20.001 19.148 19.9112 19.4495 19.74C19.751 19.5689 20.0029 19.3224 20.1803 19.0245C20.3578 18.7267 20.4548 18.3879 20.4619 18.0412C20.4689 17.6946 20.3857 17.3521 20.2205 17.0473V17.0273ZM19.0338 18.3207C18.9748 18.4202 18.8909 18.5026 18.7904 18.5599C18.6898 18.6172 18.5761 18.6473 18.4605 18.6473H5.47378C5.3579 18.6477 5.24394 18.6178 5.14313 18.5606C5.04233 18.5034 4.95816 18.421 4.89894 18.3214C4.83971 18.2218 4.80748 18.1085 4.8054 17.9926C4.80333 17.8767 4.83149 17.7623 4.88712 17.6607L11.3805 5.70067C11.4376 5.59484 11.5223 5.50643 11.6255 5.44481C11.7288 5.38319 11.8469 5.35066 11.9671 5.35066C12.0874 5.35066 12.2054 5.38319 12.3087 5.44481C12.412 5.50643 12.4967 5.59484 12.5538 5.70067L19.0471 17.6607C19.1024 17.7624 19.1302 17.8767 19.1279 17.9924C19.1255 18.1082 19.0931 18.2213 19.0338 18.3207Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
