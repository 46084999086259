import {styled} from '@mui/material/styles'
import Mui from '@ui/mui'

const Red = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
}))

const CloseButton = styled(Mui.IconButton)({
  display: 'none',
  height: 28,
})

const ButtonContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  right: -28,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 28,

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
})

const Label = styled(Mui.Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textAlign: 'right',
  paddingRight: '20px',
})

const Field = styled(Mui.Grid)({
  position: 'relative',
})

const Container = styled(Mui.Grid)(({theme}) => ({
  position: 'relative',

  '& + &': {
    marginTop: theme.spacing(4),
  },

  [`&:hover ${CloseButton}`]: {
    display: 'flex',
  },
}))

const TextField = styled(Mui.TextField)({
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '-1.2rem',
    right: '0',
  },
})

export default {
  ButtonContainer,
  CloseButton,
  Grid: {
    Container,
    Field,
    Label,
  },
  Red,
  TextField,
}
