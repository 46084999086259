import React, {useCallback, useMemo} from 'react'
import {Formik, Form} from 'formik'
import Dialog from '@mui/material/Dialog'
import * as Yup from 'yup'
import type {Theme} from '@mui/material/styles'
import {Icon, FormikTextField, FormikSelect, MenuItem} from '@ui/components'
import {useUpdateMemberMutation, useLazyGetUserByEmailQuery} from '~/src/store/apis/user-api'
import {cognitoToDisplay, displayToCognito, IUserBase, userStatusesReadonly} from '~/src/models'
import {
  AbsoluteAdornment,
  Adornment,
  Blue,
  Body,
  Button,
  Footer,
  Header,
  IconButton,
  Title,
  useStyles,
} from '~/src/components/ui/dialog/styles/MemberDialog.styles'
interface IProps {
  onModalClose(): void
  targetUser?: IUserBase
  modalOpen: boolean
}

export default function EditMemberDialog({modalOpen, onModalClose, targetUser}: IProps) {
  const classes = useStyles()
  const [editMember] = useUpdateMemberMutation()
  const [error, setError] = React.useState<string>()

  const initialValues = {
    firstName: targetUser?.firstName ?? '',
    lastName: targetUser?.lastName ?? '',
    emailAddress: targetUser?.emailAddress ?? '',
    status: targetUser?.status ?? ('' as IUserBase['status']),
    role: targetUser?.roles ? cognitoToDisplay(targetUser.roles[0]) : '',
  }

  function handleModalClose() {
    onModalClose()
    setError(undefined)
  }

  const [findEmail] = useLazyGetUserByEmailQuery()

  const validateEmail = useCallback(
    async function (
      value: string | undefined,
      resolve: (value: boolean) => void,
      targetUser?: IUserBase,
    ) {
      if (targetUser?.emailAddress === value) {
        resolve(true)
      } else {
        try {
          const alreadyExists = await findEmail(value ?? '').unwrap()
          resolve(!alreadyExists)
        } catch (e: unknown) {
          resolve(true)
        }
      }
    },
    [findEmail],
  )

  const debouncedValidateEmail = useMemo(
    function () {
      return _.debounce(validateEmail, 500)
    },
    [validateEmail],
  )

  const validation = Yup.object({
    firstName: Yup.string()
      .required('Required')
      .max(50, 'First Name must be less than 50 characters'),
    lastName: Yup.string()
      .required('Required')
      .max(50, 'Last Name must be less than 50 characters'),
    emailAddress: Yup.string()
      .test(
        'already-exists',
        'This email is already in use',
        (value) => new Promise((resolve) => debouncedValidateEmail(value, resolve, targetUser)),
      )
      .email('Email address is invalid.')
      .required('Required'),
    status: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
  })

  const handleSubmit = useCallback(
    async (values) => {
      if (targetUser) {
        const member = {
          id: targetUser.id,
          ...values,
          roles: [displayToCognito(values.role)],
        }

        try {
          await editMember(member).unwrap()
          handleModalClose()
        } catch (e: unknown) {
          setError(JSON.stringify(e))
        }
      } else {
        setError('No target user specified.')
      }
    },
    [targetUser],
  )

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={handleModalClose}
      PaperProps={{sx: {minWidth: (theme: Theme) => theme.breakpoints.values.sm, maxWidth: 546}}}>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
        {({isSubmitting}) => (
          <Form>
            <Header>
              <Title>
                <Blue>Edit</Blue> User
              </Title>
              <IconButton onClick={handleModalClose}>
                <Icon icon="close" />
              </IconButton>
            </Header>

            <Body>
              <div>
                <b>Edit</b> user information:
              </div>
              <FormikTextField
                name="firstName"
                placeholder="First Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="lastName"
                placeholder="Last Name"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="person" />}
              />
              <FormikTextField
                name="emailAddress"
                placeholder="danyl.nava@example.com"
                inputProps={{className: classes.textField}}
                startAdornment={<Adornment icon="email" />}
              />

              <FormikSelect
                name="role"
                placeholder="Role"
                inputProps={{className: classes.selectTextField}}
                defaultValue={initialValues?.role}
                startAdornment={<AbsoluteAdornment icon="pen" />}>
                {[initialValues?.role].map((role: Key | undefined | null) => (
                  <MenuItem key={role} value={role} defaultValue={role}>
                    {role}
                  </MenuItem>
                ))}
              </FormikSelect>

              <FormikSelect
                name="status"
                placeholder="Status"
                inputProps={{className: classes.selectTextField}}
                startAdornment={<AbsoluteAdornment icon="loader" />}>
                {userStatusesReadonly.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Body>
            <Footer>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={handleModalClose}>
                Cancel
              </Button>

              <Button variant="outlined" color="primary" type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
