import {Box, BoxProps} from '@mui/material'
import {styled} from '@mui/material/styles'
import React, {PropsWithChildren} from 'react'

const StyledButton = styled(Box)<{$isFocused: boolean; $isHand: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 34px;
  border: ${(props) => (props.$isFocused ? '1px solid #03A9FC' : '1px solid #707070')};
  border-radius: 8px;
  color: ${(props) => (props.$isFocused ? 'white' : '#333333')};
  background: ${(props) => (props.$isFocused ? '#03A9FC' : '#FFFFFF')};
  cursor: ${(props) => (props.$isHand ? 'pointer' : 'default')};
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
`

interface IProps {
  isFocused: boolean
  isHand?: boolean
}

export default function BucketButton({
  isFocused,
  isHand = true,
  children,
  ...rest
}: PropsWithChildren<IProps & BoxProps>) {
  return (
    <StyledButton $isFocused={isFocused} $isHand={isHand} {...rest}>
      {children}
    </StyledButton>
  )
}
