import ErrorBoundary from '@ui/error'
import LoadingBoundary from '@ui/loader'
import React from 'react'
import {useParams} from 'react-router-dom'
import {useGetOneCompanyQuery} from '~/src/store/apis/company-api'
import {useGetPositionByIdQuery} from '~/src/store/apis/position-api'
import {useGetSingleServiceOrderQuery} from '~/src/store/apis/service-order-api'
import {useAppSelector} from '~/src/store/store-hooks'
import OrderForm from '../../../common/job-orders/OrderForm'
import Title from '../../../layout/Title'
interface IParams {
  orderId: string
  positionId: string
  orderIndex: string
}
interface IProps {
  onOpenOrderModal(): void
}

export default function EditServiceOrder({onOpenOrderModal}: IProps): React.ReactElement {
  const {currentUser} = useAppSelector((state) => state.root)
  const {orderId, positionId, orderIndex}: IParams = useParams()

  const {
    data: serviceOrder,
    error: serviceOrderError,
    isLoading: serviceOrderLoading,
    isSuccess: serviceOrderIsSuccess,
  } = useGetSingleServiceOrderQuery({
    id: orderId,
  })

  const {data: positionData} = useGetPositionByIdQuery(
    {
      id: positionId,
    },
    {skip: !serviceOrderIsSuccess},
  )

  const {data: companyData} = useGetOneCompanyQuery(currentUser?.companyId, {
    skip: !currentUser?.companyId,
  })

  const facility = companyData ? companyData.addresses : []
  const department = companyData ? companyData.departments : []

  const orderValues = serviceOrder
    ? serviceOrder.orders.map((i) => {
        return {
          orderType: i.orderType,
          positionId: i.positionId,
          payRate: i.payRate,
          quantity: i.quantity,
          distributionStartDate: i.distributionStartDate,
          distributionEndDate: i.distributionEndDate,
          candidateDistribution: i.candidateDistribution,
          note: i.note,
          orderDistribution: i.orderDistribution,
        }
      })
    : []

  const facilityValues = facility.map((i) => {
    return {
      name: i.name,
      address: i.address.display,
    }
  })

  const departmentValues = department.map((i) => {
    return {
      id: i.id,
      name: i.name,
    }
  })

  const initialValues =
    positionData && serviceOrder
      ? {
          id: serviceOrder.id,
          orderNumber: serviceOrder.orderNumber,
          position: {
            positionName: positionData.name,
            positionStartTime: positionData.startTime,
            positionEndTime: positionData.endTime,
            positionManager: positionData.manager,
            positionJobDescription: positionData.jobDescription,
            positionCompensationCodeName: positionData.compensationCode,
          },
          facility: facilityValues,
          department: departmentValues,
          orders: orderValues,
        }
      : {}

  return (
    <ErrorBoundary error={serviceOrderError}>
      <LoadingBoundary isLoading={serviceOrderLoading}>
        {serviceOrder && positionData ? (
          <>
            <Title pageTitle="Edit Service Order" />
            <OrderForm
              onOpenOrderModal={onOpenOrderModal}
              index={orderIndex}
              initialValues={initialValues}
              isEdit
            />
          </>
        ) : (
          <div />
        )}
      </LoadingBoundary>
    </ErrorBoundary>
  )
}
