import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function EditIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.80524 2.79226L13.2079 7.19484L4.83672 15.5659C4.54683 15.2763 4.38307 14.8838 4.38109 14.474C4.37912 14.0642 4.53907 13.6703 4.82615 13.3778L4.82439 13.3752C4.21683 13.9704 3.23945 13.9678 2.63629 13.3637C2.35104 13.0782 2.1882 12.6929 2.18229 12.2893C2.17637 11.8858 2.32784 11.4958 2.60459 11.2021L2.59755 11.195C1.98646 11.7674 1.03021 11.7586 0.434979 11.1633L8.80524 2.79226ZM15.4849 2.42772L13.5724 0.515242C13.242 0.185315 12.7942 0 12.3273 0C11.8604 0 11.4126 0.185315 11.0823 0.515242L9.68576 1.91174L14.0884 6.31432L15.4849 4.91782C16.1717 4.23102 16.1717 3.11541 15.4849 2.42772ZM0 13.3585V16H2.64157C2.64157 15.2994 2.36326 14.6275 1.86787 14.1321C1.37248 13.6368 0.700588 13.3585 0 13.3585Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
