import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import EditVariantIcon from '@ui/icons/EditVariant'
import { StyledPagination } from '@ui/pagination/StyledPagination'
import { formatShiftForDetailsTable } from '@utils/format-shift'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import type { IServiceOrder } from '~/src/models/serviceOrder'
import { BASE_ROUTES } from '~/src/routes'
import { useGetOneCompanyQuery } from '~/src/store/apis/company-api'
import { useGetPositionByIdQuery } from '~/src/store/apis/position-api'
import { useCurrentUser } from '~/src/store/slice-hooks'
import { StyledTableCell } from '../../../ui/table-cell/StyledTableCell'
import type { IFormUpdateValues } from './OrderDetails'

type OrderDetailsTableProps = {
  serviceOrder: IServiceOrder | undefined
  values: IFormUpdateValues
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: string
  sortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
    align: 'left',
    sortable: true,
  },
  {
    id: 'shift',
    numeric: true,
    disablePadding: true,
    label: 'Shift',
    align: 'left',
  },
  {
    id: 'Quantity',
    numeric: false,
    disablePadding: true,
    label: 'Quantity',
    align: 'left',
  },

  {
    id: 'distributionStart',
    numeric: false,
    disablePadding: true,
    label: 'Distribution Start',
    align: 'left',
  },
  {
    id: 'distributionEnd',
    numeric: true,
    disablePadding: true,
    label: 'Distribution End',
    align: 'left',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: true,
    label: '',
    align: 'left',
  },
]

export const createOrderItemTest = () => ({
  id: '',
  orderType: '',
  positionId: '',
  shift: '',
  onsiteFacility: '',
  departmentName: '',
  quantity: '',
  payRate: '',
  distributionStartDate: '',
  distributionEndDate: '',
  candidateDistribution: 'string',
  note: '',
  orderDistribution: '',
})

function PositionRow(props: string | number | boolean | any): JSX.Element {
  const {data: positionData} = useGetPositionByIdQuery({
    id: props.order.positionId,
  })

  const startDate = props.order.distributionStartDate
    ? new Date(props.order.distributionStartDate).toDateString()
    : null
  const endDate = props.order.distributionStartDate
    ? new Date(props.order.distributionEndDate).toDateString()
    : null

  let totalPledged = 0

  if (
    (props.order.orderDistribution.agencyDistributions &&
      props.serviceOrder.mspStatus === 'CommitmentAccepted') ||
    props.serviceOrder.mspStatus === 'Committed'
  ) {
    props.order.orderDistribution.agencyDistributions.forEach((agency) => {
      agency.agencyQuantityDistributions.forEach((dist) => {
        totalPledged += dist.pledged
      })
    })
  }

  if (totalPledged > props.order.quantity) {
    totalPledged = props.order.quantity
  }

  return (
    <>
      {positionData && (
        <>
          <TableRow key={props.index}>
            <StyledTableCell component="td" scope="row" padding="normal" sx={{maxWidth: '200px'}}>
              {positionData.name}
            </StyledTableCell>
            <StyledTableCell>{formatShiftForDetailsTable(positionData)}</StyledTableCell>
            <StyledTableCell>{props.order.quantity}</StyledTableCell>
            <StyledTableCell>
              {props.order.distributionStartDate ? startDate : 'test'}
            </StyledTableCell>
            <StyledTableCell>
              {props.order.distributionStartDate ? endDate : 'test'}
            </StyledTableCell>
            {props.serviceOrder?.companyStatus === 'Submitting Order' ? (
              <>
                <StyledTableCell align="center">
                  <NavLink
                    data-testid="order-details-edit"
                    to={{
                      pathname: `/company-admin/manage-orders/edit-service-order/${props.serviceOrder.id}/${positionData.id}/${props.index}`,
                    }}>
                    <IconButton sx={{color: '#858585'}}>
                      <EditVariantIcon />
                    </IconButton>
                  </NavLink>
                </StyledTableCell>
                <StyledTableCell />
              </>
            ) : (
              <>
                <StyledTableCell />
                <StyledTableCell />
              </>
            )}
          </TableRow>
        </>
      )}
    </>
  )
}

export default function OrderDetailsTable({serviceOrder, values}: OrderDetailsTableProps) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const theme = useTheme()
  const {currentUser} = useCurrentUser()
  const {data: currentCompany} = useGetOneCompanyQuery(currentUser.companyId)
  const findAgencyId = currentCompany?.agencyId
  const facility = currentCompany?.addresses
  const department = currentCompany?.departments

  const handlePaginationChange = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber)
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 10,
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey[200]}`,
        }}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size="medium" padding="normal">
            <TableHead style={{borderBottom: '1px solid #CDCED9'}}>
              <TableRow>
                {headCells.map((headCell: HeadCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding="normal"
                    style={{borderBottom: 'none'}}>
                    {headCell.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="left" padding="normal" style={{borderBottom: 'none'}}>
                  <IconButton
                    component={NavLink}
                    to={{
                      pathname: `${BASE_ROUTES.COMPANY_ADMIN}/manage-orders/${serviceOrder?.id}/company/summary`,
                    }}>
                    <FileDownloadIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceOrder?.orders?.map((order, index) => {
                return (
                  <PositionRow
                    key={index}
                    order={order}
                    index={index}
                    serviceOrder={serviceOrder}
                    facility={facility}
                    department={department}
                    agencyId={findAgencyId}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledPagination
          page={currentPageNumber}
          totalRecords={serviceOrder?.orders?.length}
          recordsPerPage={10}
          onChange={handlePaginationChange}
        />
      </Box>
    </>
  )
}
