// Uses this text field because imput props works instead of import FormikTextField from '@ui/mui-text-field/FormikTextField'
import {FormikTextField} from '@ui/components'
import PersonIcon from '@ui/icons/Person'
import React, {useEffect, useState} from 'react'
import type {IUserAndEmail} from '~/src/models'
import type {Activity, Applicant} from '~/src/models/applicant'
import {
  ActivityTextEdit,
  DashboardMainStyles,
  FlexColumnContainer,
  FlexSpaceBetweenContainer,
  InformationActivityDateText,
  InformationActivityIconAndManagerText,
  InformationCardProfileHeaderText,
} from '../../job-order-candidate-styles/JobOrderCandidateDetailsStyles.styles'

interface IProps {
  activity: Activity
  handleSubmit(values: Applicant): Promise<void>
  index: number
  oldApplicant: Applicant
  usersAndEmails?: IUserAndEmail[]
  setFieldValue(field: string, value: string, shouldValidate?: boolean | undefined): void
  values: Applicant
}

export default function MspCandidateActivitySingleEdit({
  activity,
  handleSubmit,
  oldApplicant,
  usersAndEmails,
  setFieldValue,
  values,
  index,
}: IProps) {
  const [editProfileActivityState, setEditProfileActivityState] = useState('saveChanges')
  const [targetActivity, setTargetActvity] = useState([-1])

  const handleClick = async (value: string, isCancel: boolean) => {
    if (value === 'saveChanges' && isCancel) {
      setFieldValue(`activities.${index}.notes`, oldApplicant?.activities[index].notes)

      setTargetActvity(targetActivity.filter((i) => i !== index))
      setEditProfileActivityState('saveChanges')
    } else if (value === 'edit') {
      setTargetActvity([...targetActivity, index])
      setEditProfileActivityState('edit')
    } else if (value === 'saveChanges' && !isCancel) {
      await handleSubmit(values)
      setEditProfileActivityState('saveChanges')
    }
  }

  useEffect(() => {
    setEditProfileActivityState('saveChanges')
  }, [oldApplicant.id])
  const formattedDate = new Date(activity.date).toLocaleString()
  const modifiedByFirstAndLastName = activity.systemActivity
    ? undefined
    : usersAndEmails?.find((item) => item.emailAddress === activity.modifiedBy)
  const modifiedBy = activity.systemActivity ? (
    'System'
  ) : (
    <>
      <PersonIcon />{' '}
      {`${modifiedByFirstAndLastName?.firstName} ${modifiedByFirstAndLastName?.lastName}`}
    </>
  )

  // for flag notes and system we want the time to match the formatted date so just replacing the date time string with the one formatted already
  const activityNoteIsFlag = activity?.notes?.includes('flag') && activity.systemActivity

  const adjustedFlagTimeNote =
    activity?.notes?.length > 0 && activityNoteIsFlag
      ? activity.notes.replace(/ on.*by/, ` on ${formattedDate} by`)
      : activity?.notes

  return (
    <>
      <DashboardMainStyles>
        <FlexSpaceBetweenContainer key={index}>
          <FlexColumnContainer>
            <InformationCardProfileHeaderText>
              <FormikTextField
                aria-label="activity type"
                name={`activities.${index}.notes`}
                data-testid="activityEditableDescription"
                id="outlined-multiline-flexible"
                multiline
                maxRows={5}
                type="text"
                label=""
                value={adjustedFlagTimeNote}
                inputProps={{
                  readOnly: editProfileActivityState === 'saveChanges',
                  style: {
                    textOverflow: '',
                    whiteSpace: 'break-spaces',
                  },
                }}
                sx={{
                  padding: '0px 10px 0px 10px',
                  maxWidth: '748px',
                  minWidth: '748px',
                }}
              />
            </InformationCardProfileHeaderText>
            <FlexSpaceBetweenContainer style={{marginTop: -10}}>
              <div>
                <InformationActivityDateText>{activity.activityType} |</InformationActivityDateText>

                <InformationActivityDateText>{formattedDate} |</InformationActivityDateText>
                <InformationActivityIconAndManagerText>
                  {modifiedBy}
                </InformationActivityIconAndManagerText>
              </div>
              {/* change to function */}
              {editProfileActivityState === 'edit' && targetActivity.includes(index) ? (
                <FlexSpaceBetweenContainer>
                  <ActivityTextEdit
                    style={{marginRight: 10}}
                    onClick={() => handleClick('saveChanges', true)}>
                    Cancel
                  </ActivityTextEdit>
                  <ActivityTextEdit onClick={() => handleClick('saveChanges', false)}>
                    Save
                  </ActivityTextEdit>
                </FlexSpaceBetweenContainer>
              ) : activity.systemActivity ? null : (
                <ActivityTextEdit onClick={() => handleClick('edit', false)}>Edit</ActivityTextEdit>
              )}
            </FlexSpaceBetweenContainer>
          </FlexColumnContainer>
        </FlexSpaceBetweenContainer>
      </DashboardMainStyles>
    </>
  )
}
