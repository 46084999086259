import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export default function BrandLogoIcon(props: HTMLAttributes<unknown>): JSX.Element {
	return (
		<Svg
			{...props}
			width='23'
			height='23'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/Svg'>
			<path
				d='M11.714 2.46263C6.50845 2.46263 2.24349 6.69254 2.23764 11.8631C2.2318 17.0979 6.46755 21.3512 11.6848 21.3512C16.9255 21.3512 21.1846 17.1329 21.1846 11.939C21.1904 6.69838 16.9547 2.46263 11.714 2.46263ZM0 12.5642C0 11.9916 0 11.419 0 10.8406C0.0467393 10.8231 0.029212 10.7764 0.0350544 10.7413C0.0817937 10.2389 0.14606 9.73645 0.257066 9.24568C1.10422 5.6117 3.18411 2.91834 6.51429 1.24157C9.09079 -0.0554437 11.8134 -0.330032 14.606 0.388585C18.0531 1.27663 20.6003 3.34484 22.2128 6.51727C23.3463 8.74323 23.6793 11.1152 23.2762 13.5807C22.8497 16.204 21.6637 18.4416 19.7123 20.2353C16.9956 22.73 13.7706 23.729 10.1249 23.2675C7.36728 22.9169 5.03616 21.6842 3.13737 19.6568C1.41971 17.8282 0.414811 15.649 0.0876361 13.1601C0.058424 12.9673 0.0759513 12.757 0 12.5642Z'
				fill='currentColor'
			/>
			<path
				d='M11.3695 11.2612C10.2769 11.2496 9.38305 11.2671 8.50084 11.4132C7.9166 11.5125 8.00424 11.4132 8.11524 11.9857C8.29052 12.8679 8.6469 13.6858 9.0734 14.4746C9.34215 14.9653 9.64011 15.4327 10.0316 15.8417C10.1075 15.9235 10.1718 15.9293 10.2653 15.8826C10.4697 15.7716 10.6742 15.6548 10.867 15.5262C11.7142 14.9653 12.4795 14.2993 13.2098 13.5924C13.7882 13.0315 14.3433 12.4414 14.834 11.8046C14.9684 11.6293 14.9626 11.6293 14.7522 11.5884C13.5662 11.3606 12.3744 11.2846 11.3695 11.2612ZM10.7619 8.42184C12.1524 8.42769 13.426 8.49779 14.688 8.69059C15.5176 8.81912 16.3472 8.97102 17.095 9.37999C17.7552 9.73638 18.1642 10.2739 18.1934 11.0509C18.2051 11.419 18.1058 11.7637 17.9655 12.1026C17.6734 12.7744 17.2411 13.3587 16.7795 13.9196C16.0083 14.8602 15.1437 15.719 14.2264 16.5136C13.3968 17.2322 12.5438 17.9099 11.5623 18.4065C10.9547 18.7162 10.3237 18.9207 9.63427 18.7863C9.09093 18.6811 8.64106 18.4007 8.23794 18.0326C7.62448 17.4834 7.16293 16.8174 6.75396 16.1163C6.28657 15.3217 5.91266 14.4863 5.62638 13.6099C5.35178 12.7686 5.15314 11.9098 5.22909 11.0159C5.29336 10.268 5.59717 9.6429 6.23399 9.2164C6.58453 8.98271 6.98182 8.8425 7.39663 8.74902C8.53006 8.48027 9.69854 8.43353 10.7619 8.42184Z'
				fill='currentColor'
			/>
			<path
				d='M11.5618 6.95549C11.9124 6.95549 12.2513 6.89706 12.5492 6.69842C12.7245 6.58157 12.7186 6.54068 12.5492 6.41215C12.3856 6.2953 12.1987 6.23103 12.0059 6.19597C11.5093 6.10834 11.0302 6.13171 10.592 6.4063C10.4109 6.51731 10.4167 6.58742 10.592 6.70427C10.8841 6.89707 11.2171 6.95549 11.5618 6.95549ZM11.5618 4.96323C12.1753 4.95738 12.7479 5.09176 13.262 5.44231C14.1033 6.02071 14.1091 7.11324 13.2561 7.67411C12.4265 8.2233 11.521 8.28756 10.5862 8.01881C10.1188 7.88444 9.71564 7.65658 9.43521 7.24177C9.09051 6.72764 9.1723 6.0616 9.62801 5.63511C10.1772 5.12098 10.8432 4.96323 11.5618 4.96323Z'
				fill='currentColor'
			/>
		</Svg>
	);
}
