import React, {useEffect} from 'react'
import {NavLink, Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'

import {colors} from '~/src/config/theme'
import {BASE_ROUTES} from '~/src/routes/constants'
import S from './styles/tabs.styles'
import GeneralUsers from '../users-and-roles/GeneralUsers'
import {client} from '~/src/utils'

const links = [{to: '/platform-users-and-roles', title: 'Users & Roles'}]

const routes = [{to: '/platform-users-and-roles', Component: GeneralUsers}]

export default function ManageCompaniesTabs(): JSX.Element {
  const {url, path, params} = useRouteMatch()
  const {companyName} = params as {companyName: string}
  const history = useHistory()

  useEffect(() => {
    async function checkCompany() {
      const resource = `${process.env.REACT_APP_BACKEND_URL}/Company/Find/${companyName}`
      const {data} = await client.get(resource)
      if (!data) history.push(BASE_ROUTES.NOT_AUTHORIZED)
    }

    checkCompany().finally(() => {})
  }, [companyName, history])

  return (
    <S.Container data-testid="company-details">
      <S.TabList>
        {links.map(({to, title}, i) => (
          <S.Tab key={i}>
            <NavLink
              data-testid={to.slice(1)}
              to={url + to}
              activeStyle={{
                backgroundColor: colors.primary,
                borderColor: colors.primary,
                color: colors.white,
              }}>
              {title}
            </NavLink>
          </S.Tab>
        ))}
      </S.TabList>

      <S.Main>
        <Switch>
          {routes.map(({to, Component}, i) => (
            <Route key={i} path={path + to}>
              <Component />
            </Route>
          ))}
          <Redirect exact from={path} to={`${path}/platform-users-and-roles`} />
        </Switch>
      </S.Main>
    </S.Container>
  )
}
