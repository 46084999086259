import CloseIcon from '@mui/icons-material/Close'
import {AutocompleteInputChangeReason, Box, TextField} from '@mui/material'
import {styled} from '@mui/material/styles'
import StandardAutocompleteGeneric from '@ui/autocomplete/StandardAutocompleteGeneric'
import BucketButton from '@ui/buttons/BucketButton'
import CheckboxAndLabel from '@ui/checkbox/CheckboxAndLabel'
import StyledTextField from '@ui/mui-text-field/StyledTextField'
import React, {PropsWithChildren, useState} from 'react'
import {useGetAllCompanyNamesQuery} from '~/src/store/apis/company-api'
import {
  ISkillOption,
  getClericalSkillOptions,
  getDriversCommercialSkillOptions,
  getGeneralSkillOptions,
  getLanguageSkillOptions,
  getManufacturingSkillOptions,
  getResturantSkillOptions,
} from '../../msp/manage-job-orders/job-order-summary/candidate-details/candidate-skills/SkillTypes'

const BucketBoldText = styled('div')`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`

const BucketSettingsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 1055px;
  overflow-y: scroll;
  border-radius: 9px;
  border: 1px solid #ebebeb;
  padding: 20px 30px;
`

const HorizontalContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

const LeftBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  min-width: 270px;
`

const RightBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 30px;
`

interface IDistanceSelectorProps {
  selectedDistance?: number
  setSelectedDistance: (val?: number) => void
  containerSx?: object
}

function DistanceSelector({
  selectedDistance,
  setSelectedDistance,
  containerSx = {},
}: IDistanceSelectorProps) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', ...containerSx}}>
      <BucketButton
        isFocused={selectedDistance === 5}
        sx={{borderRadius: '50px'}}
        onClick={() => {
          setSelectedDistance(selectedDistance === 5 ? undefined : 5)
        }}>
        5 Miles
      </BucketButton>
      <BucketButton
        isFocused={selectedDistance === 10}
        sx={{borderRadius: '50px'}}
        onClick={() => {
          setSelectedDistance(selectedDistance === 10 ? undefined : 10)
        }}>
        10 Miles
      </BucketButton>
      <BucketButton
        isFocused={selectedDistance === 25}
        sx={{borderRadius: '50px'}}
        onClick={() => {
          setSelectedDistance(selectedDistance === 25 ? undefined : 25)
        }}>
        25 Miles
      </BucketButton>
      <BucketButton
        isFocused={selectedDistance === 50}
        sx={{borderRadius: '50px'}}
        onClick={() => {
          setSelectedDistance(selectedDistance === 50 ? undefined : 50)
        }}>
        50 Miles
      </BucketButton>
    </Box>
  )
}

interface IClosableBucketCard<T> {
  callback: (val: string) => void
  stringGetter: (val: T) => string
  sx?: object
}

function ClosableBucketCard<T>({
  children,
  callback,
  stringGetter = (val: T) => val as string,
  sx,
}: PropsWithChildren<IClosableBucketCard<T>>) {
  return (
    <BucketButton
      isFocused
      onClick={() => {
        callback(stringGetter(children as T))
      }}
      sx={sx}>
      {stringGetter(children as T)} <CloseIcon sx={{marginLeft: '8px', color: '#fff'}} />
    </BucketButton>
  )
}

export type TUseBucketSearch<T> = Omit<
  IBucketSearchField<T>,
  | 'onChange'
  | 'isOptionEqualToValue'
  | 'freeSolo'
  | 'onKeyDown'
  | 'onBlur'
  | 'onInputChange'
> & {
  options: T[]
  setOptions: (val: T[]) => void
  value: T | null
  setValue: (val: T | null) => void
}

export function useBucketSearch<T>(placeholder: string): TUseBucketSearch<T> {
  const [text, setText] = useState<string | null>(null)
  const [filters, setFilters] = useState<T[]>([])
  const [options, setOptions] = useState<T[]>([])
  const [value, setValue] = useState<T | null>(null)

  return {
    text,
    setText,
    filters,
    setFilters,
    placeholder,
    options,
    setOptions,
    value,
    setValue,
  }
}

interface IBucketSearchField<T> {
  text: string | null
  setText: (val: string | null) => void
  filters: T[]
  setFilters: (val: T[]) => void
  options: T[]
  placeholder: string
  onChange: (event: React.SyntheticEvent<Element, Event>, newValue: string | T | null) => void
  value: T | null
  setValue: (val: T | null) => void
  isOptionEqualToValue: (option: T, value: T | null) => boolean
  freeSolo?: true
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLDivElement> & {
      defaultMuiPrevented?: boolean | undefined
    },
  ) => void
  onBlur?: React.FocusEventHandler<HTMLDivElement>
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void
}

function BucketSearchField<T>({
  text,
  setText,
  filters,
  setFilters,
  options,
  placeholder,
  onChange,
  value,
  setValue,
  isOptionEqualToValue,
  freeSolo,
  onKeyDown,
  onBlur,
  onInputChange,
}: IBucketSearchField<T>) {
  return (
    <StandardAutocompleteGeneric<T, false, false>
      options={options}
      popupIconId="search"
      inputValue={text ?? ''}
      value={value}
      textValueOverride={text ?? ''}
      disablePortal
      onChange={onChange}
      renderInput={(params) => {
        return <TextField {...params} placeholder={placeholder}/>
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      freeSolo={freeSolo}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onInputChange={onInputChange}
    />
  )
}

interface IProps {
  selectedDistance?: number
  setSelectedDistance: (val?: number) => void
  previouslyWorkedAtFilters: string[]
  setPreviouslyWorkedAtFilters: (val: string[]) => void
  previouslyWorkedAtText: string | null
  setPreviouslyWorkedAtText: (val: string | null) => void
  previouslyWorkedAtValue: string | null
  setPreviouslyWorkedAtValue: (val: string | null) => void
  skillsFilters: ISkillOption[]
  setSkillsFilters: (val: ISkillOption[]) => void
  skillsText: string | null
  setSkillsText: (val: string | null) => void
  skillsValue: ISkillOption | null
  setSkillsValue: (val: ISkillOption | null) => void
  redCheck: boolean
  setRedCheck: (val: boolean) => void
  greenCheck: boolean
  setGreenCheck: (val: boolean) => void
  yellowCheck: boolean
  setYellowCheck: (val: boolean) => void
  noFlagCheck: boolean
  setNoFlagCheck: (val: boolean) => void
  containerSx?: object
}

export default function BucketSettings({
  selectedDistance = undefined,
  setSelectedDistance,
  previouslyWorkedAtFilters,
  setPreviouslyWorkedAtFilters,
  previouslyWorkedAtText,
  setPreviouslyWorkedAtText,
  previouslyWorkedAtValue,
  setPreviouslyWorkedAtValue,
  skillsFilters,
  setSkillsFilters,
  skillsText,
  setSkillsText,
  skillsValue,
  setSkillsValue,
  redCheck,
  setRedCheck,
  greenCheck,
  setGreenCheck,
  yellowCheck,
  setYellowCheck,
  noFlagCheck,
  setNoFlagCheck,
  containerSx = {},
}: IProps) {
  const {data: companyNames} = useGetAllCompanyNamesQuery({pageNumber: 1, pageSize: 99999})
  const skillOptions = [
    ...getClericalSkillOptions(),
    ...getGeneralSkillOptions(),
    ...getManufacturingSkillOptions(),
    ...getResturantSkillOptions(),
    ...getDriversCommercialSkillOptions(),
    ...getLanguageSkillOptions(),
  ].sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })

  return (
    <BucketSettingsContainer sx={containerSx}>
      <HorizontalContainer>
        <LeftBox>
          <BucketBoldText>Bucket Name</BucketBoldText>
        </LeftBox>
        <RightBox>
          <BucketBoldText>Distance to Assignment</BucketBoldText>
        </RightBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '10px'}}>
        <LeftBox>
          <StyledTextField
            placeholder="Default Bucket"
            disabled
            sx={{
              width: '100%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#424242',
              },
            }}
          />
        </LeftBox>
        <RightBox>
          <DistanceSelector
            selectedDistance={selectedDistance}
            setSelectedDistance={setSelectedDistance}
          />
        </RightBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '25px'}}>
        <LeftBox>
          <BucketBoldText>Previously Assigned At</BucketBoldText>
        </LeftBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '10px'}}>
        <LeftBox sx={{paddingBottom: '5px'}}>
          <BucketSearchField<string>
            options={
              companyNames
                ? companyNames.filter(
                    (companyName) => !previouslyWorkedAtFilters.includes(companyName),
                  )
                : []
            }
            text={previouslyWorkedAtText}
            setText={setPreviouslyWorkedAtText}
            filters={previouslyWorkedAtFilters}
            setFilters={setPreviouslyWorkedAtFilters}
            value={previouslyWorkedAtValue}
            setValue={setPreviouslyWorkedAtValue}
            placeholder="Search Company"
            onChange={(event, newValue) => {
              setPreviouslyWorkedAtText(null)
              if (newValue && !previouslyWorkedAtFilters.find((item) => item === newValue)) {
                setPreviouslyWorkedAtFilters([...previouslyWorkedAtFilters, newValue])
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.defaultPrevented = true
                if (
                  previouslyWorkedAtText &&
                  previouslyWorkedAtText.length > 0 &&
                  !previouslyWorkedAtFilters.find((item) => item === previouslyWorkedAtText)
                ) {
                  setPreviouslyWorkedAtFilters([
                    ...previouslyWorkedAtFilters,
                    previouslyWorkedAtText,
                  ])
                }
                setPreviouslyWorkedAtText(null)
              }
            }}
            onBlur={() => {
              if (
                previouslyWorkedAtText &&
                previouslyWorkedAtText.length > 0 &&
                !previouslyWorkedAtFilters.find((item) => item === previouslyWorkedAtText)
              ) {
                setPreviouslyWorkedAtFilters([...previouslyWorkedAtFilters, previouslyWorkedAtText])
              }
              setPreviouslyWorkedAtText(null)
            }}
            onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
              setPreviouslyWorkedAtText(value)
            }}
            isOptionEqualToValue={(option: string, value) => {
              return option === value
            }}
          />
        </LeftBox>
        <RightBox>
          {previouslyWorkedAtFilters.map((filter) => {
            return (
              <ClosableBucketCard<string>
                key={filter}
                callback={(val) => {
                  setPreviouslyWorkedAtFilters(
                    previouslyWorkedAtFilters.filter((item) => item !== val),
                  )
                  setPreviouslyWorkedAtValue(null)
                }}
                stringGetter={(val: string) => val}
                sx={{marginBottom: '10px'}}>
                {filter}
              </ClosableBucketCard>
            )
          })}
        </RightBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '25px'}}>
        <LeftBox>
          <BucketBoldText>Skills</BucketBoldText>
        </LeftBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '10px'}}>
        <LeftBox sx={{paddingBottom: '5px'}}>
          <BucketSearchField<ISkillOption>
            options={skillOptions.filter(
              (skillOption) =>
                !skillsFilters.map((skill) => skill.label).includes(skillOption.label),
            )}
            text={skillsText}
            setText={setSkillsText}
            filters={skillsFilters}
            setFilters={setSkillsFilters}
            value={skillsValue}
            setValue={setSkillsValue}
            placeholder="Search Skills"
            onChange={(event, newValue) => {
              setSkillsText(null)
              if (
                newValue &&
                !skillsFilters.find((item) => item.label === (newValue as ISkillOption).label)
              ) {
                setSkillsFilters([...skillsFilters, newValue as ISkillOption])
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setSkillsText(null)
              }
            }}
            onBlur={() => {
              setSkillsText(null)
            }}
            onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
              setSkillsText(value)
            }}
            isOptionEqualToValue={(option1: ISkillOption, option2) => {
              return (
                option2 !== null && option1.category === option2.category && option1.label === option2.label
              )
            }}
          />
        </LeftBox>
        <RightBox>
          {skillsFilters.map((filter) => {
            return (
              <ClosableBucketCard<ISkillOption>
                key={filter.label}
                callback={(val) => {
                  setSkillsFilters(skillsFilters.filter((item) => item.label !== val))
                  setSkillsValue(null)
                }}
                stringGetter={(val) => val.label}
                sx={{marginBottom: '10px'}}>
                {filter}
              </ClosableBucketCard>
            )
          })}
        </RightBox>
      </HorizontalContainer>
      <HorizontalContainer sx={{marginTop: '25px'}}>
        <LeftBox>
          <BucketBoldText>Flagged Candidates</BucketBoldText>
        </LeftBox>
        <RightBox>
          <CheckboxAndLabel
            label="Red (Do not use)"
            checked={redCheck}
            onChange={() => {
              setRedCheck(!redCheck)
            }}
            containerProps={{marginRight: '20px'}}
            labelProps={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CheckboxAndLabel
            label="Green (Ready for Assignment)"
            checked={greenCheck}
            onChange={() => {
              setGreenCheck(!greenCheck)
            }}
            containerProps={{marginRight: '20px'}}
            labelProps={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CheckboxAndLabel
            label="Yellow (Incomplete Application)"
            checked={yellowCheck}
            onChange={() => {
              setYellowCheck(!yellowCheck)
            }}
            containerProps={{marginRight: '20px'}}
            labelProps={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
          <CheckboxAndLabel
            label="No Flag"
            checked={noFlagCheck}
            onChange={() => {
              setNoFlagCheck(!noFlagCheck)
            }}
            containerProps={{marginRight: '20px'}}
            labelProps={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
            }}
          />
        </RightBox>
      </HorizontalContainer>
    </BucketSettingsContainer>
  )
}
