import NavMenuBar from '@ui/nav-menu-bar/NavMenuBar'
import React, {HTMLAttributes, useCallback} from 'react'
import {agencyAdminList, companyAdminList, mspAgencyList, platformAdminList} from './MenuItems'
import S from './styles/LayoutStyles'

interface ILayout extends HTMLAttributes<unknown> {
  children: React.ReactNode
  variant: 'platform' | 'msp' | 'agency' | 'company'
}

export default function Layout({children, variant}: ILayout): JSX.Element {
  const getNavItems = useCallback(() => {
    switch (variant) {
      case 'platform':
        return platformAdminList
      case 'agency':
        return agencyAdminList
      case 'company':
        return companyAdminList
      case 'msp':
        return mspAgencyList
      default:
        return null
    }
  }, [variant])

  const items = getNavItems()

  return (
    <S.Container>
      <S.Main>
        <NavMenuBar items={items} />
        {children}
      </S.Main>
    </S.Container>
  )
}
